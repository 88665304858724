import { DateTime } from "luxon"

/**
 * @deprecated use toLocalizedDate instead
 * @param d
 */
export const toFrenchDate = (d: string) => DateTime.fromISO(d).setLocale("fr").toLocaleString()

const languagesToLocale: Record<string, string | undefined> = {
  en: "en-UK",
  fr: "fr-FR",
  es: "es-ES",
  de: "de-DE",
  it: "it-IT",
}

export const toLocalizedDate = (d: string, locale: string) =>
  DateTime.fromISO(d)
    .setLocale(languagesToLocale[locale] ?? "en-UK")
    .toLocaleString()

export const toLocalizedDateTime = (d: string, locale: string) =>
  [
    toLocalizedDate(d, locale),
    DateTime.fromISO(d)
      .setLocale(languagesToLocale[locale] ?? "en-UK")
      .toLocaleString(DateTime.TIME_24_SIMPLE),
  ].join(" - ")

export enum DateStyle {
  NUMERIC,
  TEXTUAL,
}

export const toInternationalDate = ({
  date,
  language,
  style = DateStyle.NUMERIC,
}: {
  date: string | Date
  language: string
  style?: DateStyle
}) => {
  const locale = getEuropeanLocale(language)

  const LuxonDateTime = date instanceof Date ? DateTime.fromJSDate(date) : DateTime.fromISO(date)
  const options = style === DateStyle.NUMERIC ? DateTime.DATE_SHORT : DateTime.DATE_MED

  return LuxonDateTime.setLocale(locale).toLocaleString(options)
}

const getEuropeanLocale = (language: string) => {
  if (language === "en") {
    return "en-UK"
  }

  if (language === "es") {
    return "es-ES"
  }

  if (language === "de") {
    return "de-DE"
  }

  if (language === "fr") {
    return "fr-FR"
  }

  if (language === "it") {
    return "it-IT"
  }

  return language
}
