import { getPaginatedData, getPaginationState } from "../../../../00_shared/utils/pagination"
import { GetAPBankTransfersSuccessResponse } from "../Balance.requests"
import { balanceOutgoingFakeData } from "./balanceOutgoingfakeData"

export const getBalanceOutgoingFakeData = (
  filters: {
    accounts?: string[]
    amountUnder?: number
    amountEqual?: number
    amountOver?: number
    dateFrom?: Date
    dateTo?: Date
  },
  pagination: { pageNumber: number; pageSize: number },
): GetAPBankTransfersSuccessResponse => {
  const filteredTransfers = balanceOutgoingFakeData.filter((transfer) => {
    const isAccountMatch =
      filters.accounts && filters.accounts.length > 0 ? filters.accounts.includes(transfer.accountLabel) : true
    const isAmountUnderMatch = filters.amountUnder ? transfer.amount < filters.amountUnder : true
    const isAmountEqualMatch = filters.amountEqual ? transfer.amount === filters.amountEqual : true
    const isAmountOverMatch = filters.amountOver ? transfer.amount > filters.amountOver : true
    const isDateFromMatch = filters.dateFrom ? new Date(transfer.date) >= filters.dateFrom : true
    const isDateToMatch = filters.dateTo ? new Date(transfer.date) <= filters.dateTo : true

    return (
      isAccountMatch &&
      isAmountUnderMatch &&
      isAmountEqualMatch &&
      isAmountOverMatch &&
      isDateFromMatch &&
      isDateToMatch
    )
  })

  return {
    apBankTransfers: getPaginatedData(filteredTransfers, pagination),
    pagination: getPaginationState(filteredTransfers.length, pagination),
    accountLabels: ["LEROY MERLIN", "AMAZON"],
  }
}
