import { useQuery } from "@apollo/client"
import { PaymentType } from "../../../../business/enums/Payment.enum"
import { TransactionFlowType, TransactionStatus } from "../../../../business/enums/Transaction.enum"
import { GET_TRANSACTION, TransactionParams, TransactionResponse } from "./transaction.requests"

export type Transaction = {
  id: string
  paymentId?: string
  amount: number
  initiatedAt?: string
  status: TransactionStatus
  type?: PaymentType
  flowType: TransactionFlowType
  orderId?: string
  merchantFee?: number
  merchantVAT?: number
  paidAmount: number
  url: string
  reserve?: number
  amountAddedToBalance?: number
  attachments: {
    id: string
    name: string
    path: string
  }[]
  installments: {
    id: string
    dueDate: string
  }[]
  customer?: {
    id: string
    siret: string
    contactEmail: string
    name: string
  }
}

export type Refunds = {
  total: number
  totalProcessing: number
  maxRefundableAmount: number
  feesHTRefunded: number
  feesVatRefunded: number
  refunds: {
    id: string
    amount: number
    createdAt: string
  }[]
}

export const useTransaction = (id?: string) => {
  const transactionId = id ?? ""

  const { data, error, loading, refetch } = useQuery<TransactionResponse, TransactionParams>(GET_TRANSACTION, {
    variables: {
      id: transactionId,
    },
    skip: transactionId === "",
  })

  return {
    loading,
    error,
    refetch,
    transaction: data?.merchantTransaction,
    refundsResponse: data?.transactionRefund,
  }
}
