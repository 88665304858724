export enum PaymentType {
  PAY_1X = "PAY_1X",
  PAY_NX = "PAY_NX",
  PAY_ND = "PAY_ND",
}

export enum PaymentStatus {
  NOT_STARTED = "NOT_STARTED",
  IN_PROGRESS = "IN_PROGRESS",
  REFUNDED = "REFUNDED",
  PARTIALLY_REFUNDED = "PARTIALLY_REFUNDED",
  FINISHED = "FINISHED",
  LATE = "LATE",
  DEFAULT = "DEFAULT",
}

export enum PaymentConfigurationScope {
  NONE = "NONE",
  PAY1X = "PAY1X",
  BNPL = "BNPL",
}

export enum BNPLProductType {
  LINK = "LINK",
  CHECKOUT = "CHECKOUT",
}
