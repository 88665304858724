import { Checkbox, Chip, Field, FieldSelect, FloatingBox, Typography } from "@hero/krypton"
import { useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import { centsToEuros, eurosToCents, toEuros } from "../../../../00_shared/utils/currency.converter"
import { titleCase } from "../../../../00_shared/utils/wording.converter"
import { useDashboardTranslation } from "../../../../01_technical/translations"
import { ChipBox, FiltersBox, StyledButton } from "../BalanceTables.components"

type MarketplaceFormData = {
  marketplaces: string[]
}

type AmountFormData = {
  amountFilter: "min" | "max" | "equal"
  amount?: number
}

type DateFormData = {
  createdBefore?: string
  createdAfter?: string
}

type IncomeFiltersSearchParams = {
  page: number
  marketplaces?: string[]
  amountMin?: number
  amountMax?: number
  amountEqual?: number
  createdBefore?: string
  createdAfter?: string
}

const getAmountFilterType = (amountMin?: number, amountMax?: number, amountEqual?: number) => {
  return amountMin ? "min" : amountMax ? "max" : amountEqual ? "equal" : "min"
}

const getAmountToEuros = (amount?: number) => {
  return amount ? centsToEuros(amount) : undefined
}

export const IncomeFilters = ({
  listMarketplaces,
  setSearchParams,
  currentParams,
}: {
  listMarketplaces: string[]
  setSearchParams: (filters: IncomeFiltersSearchParams) => void
  currentParams: IncomeFiltersSearchParams
}) => {
  const { marketplaces = [], amountMin, amountMax, amountEqual, createdBefore, createdAfter } = currentParams
  const marketplaceChipValue =
    !marketplaces || !marketplaces.length
      ? ""
      : marketplaces.length === 1
        ? titleCase(marketplaces[0])
        : `${titleCase(marketplaces[0])}, +${marketplaces.length - 1}`

  const { t } = useDashboardTranslation()
  const [anchorMarketplaceEl, setAnchorMarketplaceEl] = useState<HTMLElement | undefined>(undefined)
  const [anchorAmountEl, setAnchorAmountEl] = useState<HTMLElement | undefined>(undefined)
  const [anchorDateEl, setAnchorDateEl] = useState<HTMLElement | undefined>(undefined)
  const isMarketplaceOpen = Boolean(anchorMarketplaceEl)
  const isAmountOpen = Boolean(anchorAmountEl)
  const isDateOpen = Boolean(anchorDateEl)
  const amountChipValue = amountMax
    ? `< ${toEuros(amountMax)}`
    : amountMin
      ? `> ${toEuros(amountMin)}`
      : amountEqual
        ? `= ${toEuros(amountEqual)}`
        : ""

  const getDateChipValue = () => {
    if (createdBefore && !createdAfter) return `< ${createdBefore}`
    else if (!createdBefore && createdAfter) return `> ${createdAfter}`
    else if (createdBefore && createdAfter) return `Du ${createdAfter} au ${createdBefore}`
    return ""
  }

  const handleClick = (
    event: React.MouseEvent<HTMLElement>,
    filterType: "marketplace" | "date" | "amount" | "iban",
  ) => {
    setAnchorMarketplaceEl(undefined)
    setAnchorAmountEl(undefined)
    setAnchorDateEl(undefined)
    if (filterType === "marketplace") {
      setAnchorMarketplaceEl(anchorMarketplaceEl ? undefined : event.currentTarget)
    }
    if (filterType === "date") {
      setAnchorDateEl(anchorDateEl ? undefined : event.currentTarget)
    }
    if (filterType === "amount") {
      setAnchorAmountEl(anchorAmountEl ? undefined : event.currentTarget)
    }
  }

  const {
    handleSubmit: handleMarketplaceFormSubmit,
    register: registerMarketplaceForm,
    setValue: setMarketplaceFormValue,
  } = useForm<MarketplaceFormData>({
    defaultValues: { marketplaces },
  })

  const submitMarketplaces = async ({ marketplaces }: MarketplaceFormData) => {
    setSearchParams({ ...currentParams, marketplaces })
    setAnchorMarketplaceEl(undefined)
  }

  const {
    handleSubmit: handleAmountFormSubmit,
    register: registerAmountForm,
    setValue: setAmountValue,
  } = useForm<AmountFormData>({
    defaultValues: {
      amountFilter: getAmountFilterType(amountMin, amountMax, amountEqual),
      amount: getAmountToEuros(amountMin ?? amountMax ?? amountEqual),
    },
  })

  const {
    handleSubmit: handleDateFormSubmit,
    register: registerDateForm,
    setValue: setDateValue,
  } = useForm<DateFormData>({
    defaultValues: {
      createdBefore,
      createdAfter,
    },
  })

  const submitAmount = async ({ amountFilter, amount }: AmountFormData) => {
    setSearchParams({
      ...currentParams,
      amountMin: amount && amountFilter === "min" ? eurosToCents(amount) : undefined,
      amountMax: amount && amountFilter === "max" ? eurosToCents(amount) : undefined,
      amountEqual: amount && amountFilter === "equal" ? eurosToCents(amount) : undefined,
    })
    setAnchorAmountEl(undefined)
  }

  const submitDate = async ({ createdBefore, createdAfter }: DateFormData) => {
    setSearchParams({
      ...currentParams,
      createdBefore: createdBefore ? createdBefore : undefined,
      createdAfter: createdAfter ? createdAfter : undefined,
    })
    setAnchorDateEl(undefined)
  }

  useEffect(() => {
    setAmountValue("amount", getAmountToEuros(amountMin ?? amountMax ?? amountEqual))
    setAmountValue("amountFilter", getAmountFilterType(amountMin, amountMax, amountEqual))
    setMarketplaceFormValue("marketplaces", marketplaces || [])
    setDateValue("createdBefore", createdBefore)
    setDateValue("createdAfter", createdAfter)
  }, [
    setAmountValue,
    setDateValue,
    setMarketplaceFormValue,
    amountMin,
    amountMax,
    amountEqual,
    createdBefore,
    createdAfter,
    marketplaces,
  ])

  return (
    <FiltersBox>
      {/* Marketplace filter */}
      <ChipBox>
        <Chip
          label={t("ap.balance.filters.chip.marketplace")}
          onClick={(e: React.MouseEvent<HTMLElement>) => handleClick(e, "marketplace")}
          onClear={() => {
            submitMarketplaces({ marketplaces: [] })
          }}
          value={marketplaceChipValue}
        />
      </ChipBox>
      <FloatingBox
        anchorElement={anchorMarketplaceEl}
        onClose={() => setAnchorMarketplaceEl(undefined)}
        isOpen={isMarketplaceOpen}
      >
        <form method="dialog" onSubmit={handleMarketplaceFormSubmit(submitMarketplaces)}>
          <Typography as="h3" $variant="body-4-semibold">
            {t("ap.balance.filters.marketplaceLabel")}
          </Typography>
          {listMarketplaces.map((marketplace) => (
            <Checkbox
              {...registerMarketplaceForm("marketplaces")}
              key={marketplace}
              id={marketplace}
              text={titleCase(marketplace)}
              value={marketplace}
            />
          ))}
          <StyledButton<"button"> type="submit">{t("ap.balance.filters.apply")}</StyledButton>
        </form>
      </FloatingBox>
      {/* Amount filter */}
      <ChipBox>
        <Chip
          label={t("ap.balance.filters.chip.amount")}
          onClick={(e: React.MouseEvent<HTMLElement>) => handleClick(e, "amount")}
          onClear={() => {
            submitAmount({ amountFilter: "equal" })
          }}
          value={amountChipValue}
        />
      </ChipBox>
      <FloatingBox
        anchorElement={anchorAmountEl}
        onClose={() => setAnchorMarketplaceEl(undefined)}
        isOpen={isAmountOpen}
      >
        <form method="dialog" onSubmit={handleAmountFormSubmit(submitAmount)}>
          <Typography as="h3" $variant="body-4-semibold">
            {t("ap.balance.filters.amountLabel")}
          </Typography>
          <FieldSelect {...registerAmountForm("amountFilter")}>
            <option value="max">{t("ap.balance.filters.amount.lessThan")}</option>
            <option value="equal">{t("ap.balance.filters.amount.equal")}</option>
            <option value="min">{t("ap.balance.filters.amount.greaterThan")}</option>
          </FieldSelect>
          <Field step="0.01" type="number" {...registerAmountForm("amount")} aria-invalid placeholder="10" />
          <StyledButton<"button"> type="submit">{t("ap.balance.filters.apply")}</StyledButton>
        </form>
      </FloatingBox>

      {/* Date filter */}
      <ChipBox>
        <Chip
          label={t("ap.balance.filters.chip.date")}
          onClick={(e: React.MouseEvent<HTMLElement>) => handleClick(e, "date")}
          onClear={() => {
            submitDate({})
          }}
          value={getDateChipValue()}
        />
      </ChipBox>
      <FloatingBox anchorElement={anchorDateEl} onClose={() => setAnchorMarketplaceEl(undefined)} isOpen={isDateOpen}>
        <form method="dialog" onSubmit={handleDateFormSubmit(submitDate)}>
          <Typography as="h3" $variant="body-4-semibold">
            {t("ap.balance.filters.dateLabel")}
          </Typography>
          <Field fieldLabel={t("ap.balance.filters.date.from")} {...registerDateForm("createdAfter")} type="date" />
          <Field fieldLabel={t("ap.balance.filters.date.to")} {...registerDateForm("createdBefore")} type="date" />
          <StyledButton<"button"> type="submit">{t("ap.balance.filters.apply")}</StyledButton>
        </form>
      </FloatingBox>
    </FiltersBox>
  )
}
