import styled from "styled-components"

export const TableContainer = styled.div`
  box-shadow: ${({ theme }) => theme.shadows.light};
  border-radius: 1rem;
  border: 1px solid ${({ theme }) => theme.colors.grey.$200};
  display: flex;
  flex-direction: column;
  min-width: 0;
`
