import { Outlet } from "react-router-dom"
import styled from "styled-components"

const Container = styled.div`
  display: inline;
`

export const BeneficiariesRoot = () => {
  return (
    <Container>
      <Outlet />
    </Container>
  )
}
