import { Button, Invoices3Icon, PencilIcon, TrashIcon, Typography } from "@hero/krypton"
import { useCallback, useRef } from "react"
import styled from "styled-components"
import CenteredLoading from "../../../../../../00_shared/components/CenteredLoading"
import { useDashboardTranslation } from "../../../../../../01_technical/translations"
import { useCreateLinkFormContext } from "../../CreateLink.utils"
import { useCreateLinkUploadDocument } from "../useCreateLinkUploadDocument.hook"

const ComponentWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.grey.$100};
  padding: 2rem;
  position: absolute;
  height: 100%;
  width: 100%;
  display: flex;
  background-image: url("/patterns/dots.png");
  background-repeat: repeat;
`

const EmptyStateContainer = styled.label<{ $error: boolean }>`
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 0.5rem;
  border: 1px solid ${({ theme }) => theme.colors.grey.$200};
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  height: calc(100vh - 10rem);
  transition: border 0.1s ease-in-out;
  flex-direction: column;
`

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  min-width: 16rem;
`

const EmptyStateIcon = styled.div`
  height: 4.5rem;
  width: 4.5rem;
  border-radius: 100%;
  background-color: ${({ theme }) => theme.colors.grey.$100};
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid ${({ theme }) => theme.colors.grey.$300};
  position: relative;
`

const Center = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

const Document = styled.div`
  width: 100%;
  height: calc(100vh - 13rem);
`

const NonEmptyStateContainer = styled.label`
  height: calc(100vh - 10rem);
  flex: 1;
  display: flex;
`

const Separator = styled.div`
  height: 1px;
  background-color: ${({ theme }) => theme.colors.grey.$200};
  position: relative;
  margin: 0.75rem 0;

  & > p {
    position: absolute;
    background-color: ${({ theme }) => theme.colors.white};
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: ${({ theme }) => theme.typography["body-4-semibold"]};
    color: ${({ theme }) => theme.colors.grey.$500};
    padding: 0 0.5rem;
  }
`

const DocumentOptions = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  flex-direction: row;
  margin-bottom: 1rem;
`

const IconButton = styled(Button)`
  padding: 1rem 0.5rem;
  min-width: 0;
  background-color: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.grey.$300};

  &:hover {
    background-color: ${({ theme }) => theme.colors.grey.$200};
  }
`

const MainText = styled(Typography)<{ $error: boolean }>`
  color: ${({ theme, $error }) => ($error ? theme.colors.danger.$200 : theme.colors.grey.$600)};
`

export const UploadDocument = () => {
  const ref = useRef<HTMLInputElement>(null)
  const { t } = useDashboardTranslation()
  const { formState } = useCreateLinkFormContext()
  const { uploadFile, isUploading, documentFile, clearFile } = useCreateLinkUploadDocument()

  const onReset = useCallback(() => {
    clearFile()
    if (ref.current) {
      ref.current.value = ""
    }
  }, [clearFile])

  return (
    <ComponentWrapper>
      <input
        ref={ref}
        type="file"
        hidden
        name="dropzone-file"
        accept=".pdf,.png,.jpg,.jpeg"
        id="dropzone-file"
        style={{ visibility: "hidden" }}
        onChange={(e) => {
          if (e.target.files) {
            uploadFile(e.target.files[0])
          }
        }}
      />

      {documentFile && !isUploading && (
        <NonEmptyStateContainer htmlFor="dropzone-file">
          <Document>
            <DocumentOptions>
              <IconButton<"button">
                $variant="tertiary"
                size="small"
                onClick={() => {
                  ref.current?.click()
                }}
              >
                <PencilIcon />
              </IconButton>
              <IconButton<"button"> $variant="tertiary" size="small" onClick={onReset}>
                <TrashIcon />
              </IconButton>
            </DocumentOptions>

            <iframe
              src={`${URL.createObjectURL(documentFile)}#toolbar=0&navpanes=0`}
              title="document"
              width="100%"
              height="100%"
            />
          </Document>
        </NonEmptyStateContainer>
      )}

      {isUploading && (
        <NonEmptyStateContainer>
          <CenteredLoading />
        </NonEmptyStateContainer>
      )}

      {!isUploading && !documentFile && (
        <EmptyStateContainer htmlFor="dropzone-file" $error={Boolean(formState.errors.documentPath)}>
          <ContentContainer>
            <Center>
              <EmptyStateIcon>
                <div
                  style={{
                    position: "absolute",
                    height: "100%",
                    width: "100%",
                    background: "linear-gradient(0deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%)",
                    transform: "scale(1.1)",
                  }}
                ></div>
                <Invoices3Icon
                  style={{
                    position: "relative",
                    height: "2rem",
                    width: "2.2188rem",
                  }}
                />
              </EmptyStateIcon>
            </Center>
            <MainText
              style={{
                margin: "0rem 1rem",
                textAlign: "center",
              }}
              $error={!!formState.errors.documentPath}
            >
              {t("bnpl.link.wizard.steps.demand.uploadDocument.dropInvoiceHere")}
            </MainText>
            <Separator>
              <Typography>{t("bnpl.link.wizard.steps.demand.uploadDocument.or")}</Typography>
            </Separator>
            <Button
              isLoading={false}
              type="button"
              size="medium"
              onClick={() => {
                ref.current?.click()
              }}
            >
              {t("bnpl.link.wizard.steps.demand.uploadDocument.selectInvoice")}
            </Button>
          </ContentContainer>
        </EmptyStateContainer>
      )}
    </ComponentWrapper>
  )
}
