import { useHeroQuery } from "../../../../01_technical/requesting/useHeroQuery/useHeroQuery"
import { GET_AP_BANK_TRANSFERS_QUERY, GetAPBankTransfersSuccessResponse } from "../Balance.requests"
import { getBalanceOutgoingFakeData } from "./balanceOutgoingFakeData.utils"

export const useGetApBankTransfers = (
  filters: {
    accounts: string[]
    amountUnder?: number
    amountEqual?: number
    amountOver?: number
    dateFrom?: Date
    dateTo?: Date
  },
  pagination: { pageNumber: number; pageSize: number },
  config: { useDemoData: boolean } = { useDemoData: false },
) => {
  const { data, loading, error } = useHeroQuery<GetAPBankTransfersSuccessResponse>({
    gqlQuerySchema: GET_AP_BANK_TRANSFERS_QUERY,
    variables: {
      filters,
      pagination,
    },
    skip: config.useDemoData,
  })

  if (config.useDemoData) {
    return {
      data: getBalanceOutgoingFakeData(filters, pagination),
      error: undefined,
      loading: false,
    }
  }

  return { data, loading, error }
}
