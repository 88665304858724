import qs from "qs"
import { useCallback, useEffect, useRef } from "react"
import { useSearchParams } from "react-router-dom"

export const useQuerySearchParams = <T>() => {
  const [searchParams, setSearchParamsOfRouter] = useSearchParams()

  const isMountedRef = useRef(false)

  useEffect(() => {
    isMountedRef.current = true
    return () => {
      isMountedRef.current = false
    }
  }, [])

  const setSearchParam = useCallback(
    (filters: Partial<T>) => {
      // We need to know if the component is mounted before we set the url params
      // The set search param is called within a debounce, by the time it executes this function the component could be unmounted
      if (!isMountedRef.current) {
        return
      }

      setSearchParamsOfRouter(qs.stringify(filters, { arrayFormat: "comma" }), {
        replace: true,
      })
    },
    [setSearchParamsOfRouter],
  )
  return {
    getSearchParam: (param: keyof T) => searchParams.get(param as string) || "",
    setSearchParam,
  }
}
