import { useEffect, useRef, useState } from "react"

export const useCountdown = (max = 30) => {
  const [initialTime, setInitialTime] = useState(Date.now())
  const [timeLeft_s, setTimeLeft_s] = useState<number>(-1)

  const intervalRef = useRef<NodeJS.Timeout | null>(null)

  useEffect(() => {
    intervalRef.current = setInterval(() => {
      const ellapsed = Date.now() - initialTime
      const new_timeLeft_s = Math.floor((max * 1000 - ellapsed) / 1000)
      setTimeLeft_s(new_timeLeft_s)
    }, 500)

    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialTime])

  return {
    timeLeft_s,
    resetCounting: () => {
      setInitialTime(Date.now())
    },
  }
}
