import { Header, Typography } from "@hero/krypton"
import styled from "styled-components"
import { HEADER_COLORS } from "../../../env_variables"

const CustomHeader = styled(Header)`
  min-height: auto;
  border-bottom: 0;
  &::before {
    margin-bottom: 0;
  }
`

const EmptyBox = styled.div`
  height: 43.3rem;
  margin: 3rem;
  background-color: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.grey.$200};
  box-shadow: ${({ theme }) => theme.shadows.mid};
  border-radius: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const LeftChild = styled.div`
  margin-left: 6.25rem;
`

const StyledTypography = styled(Typography)`
  margin-top: 2.25rem;
  width: 23.75rem;
  color: ${({ theme }) => theme.colors.grey.$500};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

export const SetupStandingOrderInfoScreen = ({ message }: { message: string }) => {
  return (
    <>
      <CustomHeader $colors={HEADER_COLORS} />
      <EmptyBox>
        <LeftChild>
          <Typography as="h1" $variant="title-2-bold">
            Coruscant - Standing Order
          </Typography>
          <StyledTypography as="h2" $variant="body-4-regular">
            {message}
          </StyledTypography>
          <StyledTypography as="h2" $variant="body-4-regular">
            You can close this page.
          </StyledTypography>
        </LeftChild>
      </EmptyBox>
    </>
  )
}
