import { BOV2RouteProtector } from "../../../../App.router"
import { BillingScreen } from "./billing.screen"

export const billingRoute = {
  path: "billing",
  element: (
    <BOV2RouteProtector>
      <BillingScreen />
    </BOV2RouteProtector>
  ),
}
