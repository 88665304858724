import { theme, Typography } from "@hero/krypton"
import * as React from "react"
import styled from "styled-components"
import { Drawer as DrawerPrimitive } from "vaul"

const DrawerRoot = ({ shouldScaleBackground = true, ...props }) => (
  <DrawerPrimitive.Root shouldScaleBackground={shouldScaleBackground} {...props} />
)
DrawerRoot.displayName = "DrawerRoot"

const DrawerTrigger = DrawerPrimitive.Trigger
const DrawerPortal = DrawerPrimitive.Portal
// const DrawerClose = DrawerPrimitive.Close

const StyledOverlay = styled(DrawerPrimitive.Overlay)`
  position: fixed;
  inset: 0;
  z-index: 50;
`

const StyledContent = styled(DrawerPrimitive.Content)`
  position: fixed;
  inset-x: 0;
  bottom: 0;
  z-index: 50;
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  height: auto;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border: 1px solid ${({ theme }) => theme.colors.grey.$300};
  background-color: ${({ theme }) => theme.colors.background};
`

const Handle = styled.div`
  margin: 1rem auto;
  height: 4px;
  width: 100px;
  background-color: ${({ theme }) => theme.colors.grey.$300};
`

const DrawerContent = React.forwardRef<
  React.ElementRef<typeof DrawerPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof DrawerPrimitive.Content>
>(({ className, children, ...props }, ref) => (
  <DrawerPortal>
    <StyledOverlay />
    <StyledContent ref={ref} className={className} {...props}>
      <Handle />
      {children}
    </StyledContent>
  </DrawerPortal>
))
DrawerContent.displayName = "DrawerContent"

const DrawerHeader = styled.div`
  display: grid;
  gap: 1.5rem;
  padding: 1rem;
  text-align: center;

  @media (min-width: 640px) {
    text-align: left;
  }
`

const DrawerTitle = React.forwardRef<
  React.ElementRef<typeof DrawerPrimitive.Title>,
  React.ComponentPropsWithoutRef<typeof DrawerPrimitive.Title>
>(({ className, ...props }, ref) => (
  <Typography as={DrawerPrimitive.Title} ref={ref} $variant="title-2-bold" className={className} {...props} />
))
DrawerTitle.displayName = DrawerPrimitive.Title.displayName

const DrawerDescription = React.forwardRef<
  React.ElementRef<typeof DrawerPrimitive.Description>,
  React.ComponentPropsWithoutRef<typeof DrawerPrimitive.Description>
>(({ className, ...props }, ref) => (
  <Typography
    as={DrawerPrimitive.Description}
    ref={ref}
    $variant="body-4-regular"
    className={className}
    style={{ color: theme.colors.grey.$400 }}
    {...props}
  />
))
DrawerDescription.displayName = DrawerPrimitive.Description.displayName

export {
  DrawerRoot as Drawer,
  // DrawerClose,
  DrawerContent,
  // DrawerDescription,
  // DrawerFooter,
  DrawerHeader,
  // StyledOverlay as DrawerOverlay,
  // DrawerPortal,
  // DrawerTitle,
  DrawerTrigger,
}
