import { gql } from "@apollo/client"

export type RefundArgs = {
  paymentId: string
  amount: number
}

type RefundResponseOutput = {
  handled: boolean
}
export type RefundResponse = {
  askForRefund: RefundResponseOutput
}

export const REFUND = gql`
  mutation ($paymentId: String!, $amount: Int!) {
    askForRefund(paymentId: $paymentId, amount: $amount) {
      ... on AskForRefundOutput {
        handled
      }

      ... on AskForRefundErrors {
        errorCode
      }
    }
  }
`
