import { BOV2RouteProtector } from "../../../../App.router"
import { ExportScreen } from "./export.screen"

export const exportRoute = {
  path: "exports",
  element: (
    <BOV2RouteProtector>
      <ExportScreen />
    </BOV2RouteProtector>
  ),
}
