import { useQuery } from "@apollo/client"

import { GET_MERCHANT_QUERY, GetMerchantResponse } from "./cockpit.requests"
import { EmptyCockpit } from "./components/EmptyCockpit"
import { FilledCockpit } from "./components/FilledCockpit"

export const CockpitScreen: React.FC = () => {
  const { data } = useQuery<GetMerchantResponse>(GET_MERCHANT_QUERY)

  if (data && !data.getmerchant.canShowCockpit) {
    return <EmptyCockpit />
  }

  return <FilledCockpit />
}
