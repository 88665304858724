import { gql } from "@apollo/client"
import { use2faChallenge } from "../../../../../Auth/Challenge2fa/use2faChallenge.hooks"

const CREATE_BENEFICIARY = gql`
  mutation createBeneficiary($input: CreateBeneficiaryInput!, $constraints: CreateBeneficiaryConstraints) {
    createBeneficiary(input: $input, constraints: $constraints) {
      ... on CreateBeneficiaryOutput {
        success
        beneficiaryId
      }
      ... on GqlHeroError {
        errorCode
        message
      }

      ... on ValidationErrors {
        errorCode
        validationErrors {
          path
          validationError
        }
      }
    }
  }
`

type Beneficiary = {
  fullName: string
  iban: string
  label: string | null
  bankName?: string
}

type CreateBeneficiaryOutput = {
  success: boolean
  beneficiaryId: string
}

type CreateBeneficiaryInput = {
  input: Beneficiary
}

type CreateBeneficiaryConstraints = {
  constraints?: { isUniqueForMerchant?: boolean }
}

type createBeneficiaryArgs = CreateBeneficiaryInput & CreateBeneficiaryConstraints

export const useAddBeneficiary = () => {
  const {
    mutationWith2fa: addBeneficiary,
    mutationState: { data: mutationData, error, loading },
    isModalOpen,
  } = use2faChallenge<{ createBeneficiary: CreateBeneficiaryOutput }, createBeneficiaryArgs>(CREATE_BENEFICIARY)

  const mutate = async (input: Beneficiary) => {
    const result = await addBeneficiary({ input, constraints: { isUniqueForMerchant: true } })
    return result.data?.createBeneficiary
  }

  return { mutate, loading, error, mutationData, is2faModalOpen: isModalOpen }
}
