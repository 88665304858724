import { Typography, TypographyVariant } from "@hero/krypton"
import * as React from "react"
import styled, { css } from "styled-components"

interface CardTitleProps extends React.HTMLAttributes<HTMLHeadingElement> {
  size?: TypographyVariant
}

const titleSizeStyles = ($variant: TypographyVariant) => {
  return css`
    font-family: ${({ theme }) => theme.typography[$variant].fontFamily};
    font-size: ${({ theme }) => theme.typography[$variant].fontSize};
    line-height: ${({ theme }) => theme.typography[$variant].lineHeight};
    font-weight: ${({ theme }) => theme.typography[$variant].fontWeight};
    text-decoration: ${({ theme }) => theme.typography[$variant].decoration ?? "none"};
  `
}

const StyledCard = styled.div<{ $variant?: "default" | "plain" }>`
  border-radius: 8px;
  background-color: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.grey.$600};

  ${({ $variant }) =>
    $variant === "plain"
      ? css`
          border: none;
          box-shadow: none;
        `
      : css`
          border: 1px solid ${({ theme }) => theme.colors.grey.$200};
          box-shadow: ${({ theme }) => theme.shadows.light};
        `}
`

const StyledCardHeader = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding: 1.5rem;

  @media (max-width: 768px) {
    padding: 1rem;
  }
`

const StyledCardTitle = styled(Typography).attrs<CardTitleProps>(({ size = "body-4-regular" }) => ({
  $variant: size,
}))<CardTitleProps>`
  ${({ size = "body-4-regular" }) => titleSizeStyles(size)}
  color: ${({ theme }) => theme.colors.grey.$600};
`

const StyledCardDescription = styled(Typography).attrs(() => ({ as: "p", $variant: "body-3-regular" }))`
  font-size: 0.875rem;
  color: ${({ theme }) => theme.colors.grey.$500};
`

const StyledCardContent = styled.div`
  padding: 1.5rem;
  padding-top: 0;

  @media (max-width: 768px) {
    padding: 1rem;
  }
`

const StyledCardFooter = styled.div`
  display: flex;
  align-items: center;
  padding: 1.5rem;
  padding-top: 0;
`

interface CardProps extends React.HTMLAttributes<HTMLDivElement> {
  $variant?: "default" | "plain"
}

const Card = React.forwardRef<HTMLDivElement, CardProps>(({ $variant = "default", ...props }, ref) => (
  <StyledCard ref={ref} $variant={$variant} {...props} />
))
Card.displayName = "Card"

const CardHeader = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement>>(({ ...props }, ref) => (
  <StyledCardHeader ref={ref} {...props} />
))
CardHeader.displayName = "CardHeader"

const CardTitle = React.forwardRef<HTMLParagraphElement, CardTitleProps>(
  ({ size = "body-4-regular", ...props }, ref) => <StyledCardTitle ref={ref} size={size} {...props} />,
)
CardTitle.displayName = "CardTitle"

const CardDescription = React.forwardRef<HTMLParagraphElement, React.HTMLAttributes<HTMLParagraphElement>>(
  ({ ...props }, ref) => <StyledCardDescription ref={ref} {...props} />,
)
CardDescription.displayName = "CardDescription"

const CardContent = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement>>(({ ...props }, ref) => (
  <StyledCardContent ref={ref} {...props} />
))
CardContent.displayName = "CardContent"

const CardFooter = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement>>(({ ...props }, ref) => (
  <StyledCardFooter ref={ref} {...props} />
))
CardFooter.displayName = "CardFooter"

export { Card, CardContent, CardFooter, CardHeader, CardTitle }
