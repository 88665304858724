import { Button, Dropzone } from "@hero/krypton"
import styled from "styled-components"
import CenteredLoading from "../../../../00_shared/components/CenteredLoading"
import { useDashboardTranslation } from "../../../../01_technical/translations"
import { useUploadFile } from "../requests/useUploadFile"
import { useCwtApplicationFormContext } from "./ApplicationForm.utils"

const ComponentWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.grey.$100};
  padding: 2rem;
  position: absolute;
  height: 100%;
  width: 100%;
  background-image: url("/patterns/dots.png");
  background-repeat: repeat;
`

const NonEmptyStateContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 0.5rem;
  border: 1px solid ${({ theme }) => theme.colors.grey.$200};
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1.5rem;
  position: relative;
  height: 100%;
`

const Separator = styled.div`
  height: 1px;
  background-color: ${({ theme }) => theme.colors.grey.$300};
  position: relative;
  margin: 0.75rem 0;

  & > span {
    position: absolute;
    background-color: ${({ theme }) => theme.colors.grey.$100};
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 0 0.5rem;
  }

  [role="presentation"]:hover & > span,
  [role="presentation"]:focus & > span {
    background-color: ${({ theme }) => theme.colors.white};
  }
`

const DropzoneStyled = styled(Dropzone)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  width: 100%;
  max-width: none;
  height: 100%;
`

const UploadAnotherDocumentButton = styled(Button)`
  position: absolute;
  bottom: 2rem;
`

export const UploadDocument = () => {
  const { t } = useDashboardTranslation()
  const { setValue } = useCwtApplicationFormContext()

  const onUploadSuccess = (fp: string) => {
    setValue("filePath", fp)
  }

  const { uploadFile, isUploading, uploadedFilePath, uploadedFile, clearUploadedFile } = useUploadFile({
    onUploadSuccess,
  })

  return (
    <ComponentWrapper>
      {uploadedFilePath && uploadedFile && !isUploading && (
        <NonEmptyStateContainer>
          <iframe
            src={`${URL.createObjectURL(uploadedFile)}#toolbar=0&navpanes=0`}
            title="document"
            width="100%"
            height="100%"
          />
          <UploadAnotherDocumentButton
            isLoading={false}
            onClick={() => {
              setValue("filePath", "")
              clearUploadedFile()
            }}
          >
            {t("creditWireTransfer.uploadDocument.replaceInvoice")}
          </UploadAnotherDocumentButton>
        </NonEmptyStateContainer>
      )}

      {isUploading && (
        <NonEmptyStateContainer>
          <CenteredLoading />
        </NonEmptyStateContainer>
      )}

      {!isUploading && !uploadedFilePath && (
        <DropzoneStyled
          dropzoneOption={{
            accept: {
              "application/pdf": [".pdf"],
            },
            maxSize: 52428800,
            onDrop: (acceptedFiles: File[]) => {
              uploadFile(acceptedFiles[0])
            },
          }}
          inputProps={{}}
        >
          {t("creditWireTransfer.uploadDocument.dropInvoiceHere")}
          <br />
          <Separator>
            <span>{t("creditWireTransfer.uploadDocument.or")}</span>
          </Separator>
          {t("creditWireTransfer.uploadDocument.selectInvoice")}
        </DropzoneStyled>
      )}
    </ComponentWrapper>
  )
}
