import styled from "styled-components"
import { UploadDocument } from "./components/UploadDocument"
import { DemandForm } from "./components/DemandForm"

const ScreenContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`

const BodyWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
`

const BodyChildrenWrapper = styled.div`
  position: relative;
  flex: 1;
  max-height: 100%;
`

export const LinkCreateDemandStep = () => {
  return (
    <ScreenContainer>
      <BodyWrapper>
        <BodyChildrenWrapper>
          <UploadDocument />
        </BodyChildrenWrapper>
        <BodyChildrenWrapper>
          <DemandForm />
        </BodyChildrenWrapper>
      </BodyWrapper>
    </ScreenContainer>
  )
}
