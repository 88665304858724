import { AppsIcon, Button, Pagination } from "@hero/krypton"
import styled from "styled-components"

export const CustomDiv = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`

export const CustomTdImg = styled.img`
  width: 1.75rem;
  height: 1.75rem;
  border-radius: 100%;
  box-shadow: ${({ theme }) => theme.shadows.light};
`

export const NoLogoUrl = styled(AppsIcon)`
  width: 1.75rem;
  height: 1.75rem;
  border-radius: 100%;
  box-shadow: ${({ theme }) => theme.shadows.light};
`

export const EmptyTable = styled.td`
  text-align: center;
`

export const AccountBox = styled.div``

export const CustomPagination = styled(Pagination)`
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
`

export const FiltersBox = styled.div`
  padding: 1rem 3rem;
  display: flex;
  background-color: ${({ theme }) => theme.colors.white};
`

export const StyledButton = styled(Button)`
  width: 100%;
  min-width: 22rem;
`

export const ChipBox = styled.span`
  margin-right: 0.5rem;
`
