import { Typography } from "@hero/krypton"
import React, { useEffect } from "react"
import { useForm } from "react-hook-form"
import { useDashboardTranslation } from "../../../../../../../01_technical/translations"
import { FieldContainer } from "../../../../00_shared/components/ui/FieldContainer"
import { FormContainer } from "../../../../00_shared/components/ui/FormContainer"
import { ApplyButton } from "./ApplyButton"
import { FilterSection } from "./FilterSection"

interface DateForm {
  dateFrom?: string
  dateTo?: string
}

interface DateFilterProps {
  dateFrom?: string
  dateTo?: string
  setDateFrom: (date: string) => void
  setDateTo: (date: string) => void
  onApply: (data: DateForm) => void
  onClose?: () => void
  hideHeader?: boolean
  autoApply?: boolean
  showApplyButton?: boolean
}

export const DateFilter: React.FC<DateFilterProps> = ({
  dateFrom,
  dateTo,
  setDateFrom,
  setDateTo,
  onApply,
  onClose,
  hideHeader = false,
  autoApply = false,
  showApplyButton = true,
}) => {
  const { t } = useDashboardTranslation()
  const { handleSubmit, register, watch } = useForm<DateForm>({
    defaultValues: { dateFrom: dateFrom ?? undefined, dateTo: dateTo ?? undefined },
  })

  const watchedDateFrom = watch("dateFrom")
  const watchedDateTo = watch("dateTo")

  useEffect(() => {
    setDateFrom(watchedDateFrom || "")
  }, [watchedDateFrom, setDateFrom])

  useEffect(() => {
    setDateTo(watchedDateTo || "")
  }, [watchedDateTo, setDateTo])

  useEffect(() => {
    if (autoApply) {
      handleSubmit(submitDate)()
    }
  }, [watchedDateFrom, watchedDateTo]) // eslint-disable-line react-hooks/exhaustive-deps

  const submitDate = (data: DateForm) => {
    onApply(data)
    onClose && onClose()
  }

  return (
    <FilterSection>
      {!hideHeader && (
        <Typography as="h3" $variant="body-4-semibold">
          {t("accounts.transaction.list.filters.dateLabel")}
        </Typography>
      )}
      <FormContainer method="dialog" onSubmit={handleSubmit(submitDate)}>
        <FieldContainer
          type="date"
          fieldLabel={t("accounts.transaction.list.filters.date.from")}
          {...register("dateFrom")}
        />
        <FieldContainer
          type="date"
          fieldLabel={t("accounts.transaction.list.filters.date.to")}
          {...register("dateTo")}
        />
        {showApplyButton && !autoApply && (
          <ApplyButton type="submit">{t("accounts.transaction.list.filters.apply")}</ApplyButton>
        )}
      </FormContainer>
    </FilterSection>
  )
}
