import { Params } from "react-router-dom"
import { APPaymentDetails } from "./PaymentDetails.screen"

export const paymentDetailsRoute = {
  path: ":id",
  handle: {
    crumb: (params: Params<string>) => ({ label: params.id ?? "" }),
  },
  element: <APPaymentDetails />,
}
