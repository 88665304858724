import { useApolloClient } from "@apollo/client"
import { useEffect, useMemo, useRef } from "react"
import { useNavigate, useSearchParams } from "react-router-dom"
import { ONBOARDING_SIGNIN_URL } from "../../env_variables"
import { useMutationWith2fa } from "../Challenge2fa/useMutationWith2fa"
import {
  Autologin2faFunctionalError,
  AUTOLOGIN_DASHBOARD_REQUEST,
  AutologinArgs,
  AutologinResponse,
  AutologinSuccess,
  convertAutologin2faErrorToSignin2faError,
  Setup2faResponse,
} from "../Login/login.requests"
import { use2faSetup } from "../Setup2fa/setup2fa.hooks"

const isAutologinSuccess = (response: AutologinResponse): response is AutologinSuccess => {
  return (response as AutologinSuccess).autologin.token !== undefined
}

const is2faSetupRequired = (response: AutologinResponse): response is Autologin2faFunctionalError => {
  const autologin2faError = response as Autologin2faFunctionalError
  return (
    autologin2faError.autologin.errorCode === "FUNCTIONAL_ERROR" &&
    autologin2faError.autologin.message === "2FA_SETUP_REQUIRED"
  )
}

const is2faSetupInProgress = (response: AutologinResponse): response is Autologin2faFunctionalError => {
  const autologin2faError = response as Autologin2faFunctionalError
  return (
    autologin2faError.autologin.errorCode === "FUNCTIONAL_ERROR" &&
    autologin2faError.autologin.message === "2FA_SETUP_IN_PROGRESS"
  )
}

const isUnknownError = (data: AutologinResponse) => {
  return !isAutologinSuccess(data) && !is2faSetupRequired(data) && !is2faSetupInProgress(data)
}

export const Autologin = () => {
  const navigate = useNavigate()
  const client = useApolloClient()
  const [queryParams] = useSearchParams()
  const path = queryParams.get("path")
  const urlToRedirectTo = useMemo(() => path || "/", [path])
  const autologinToken = queryParams.get("autologinToken")
  const autologinEmail = queryParams.get("autologinEmail")
  const [autologin] = useMutationWith2fa<AutologinResponse, AutologinArgs>(AUTOLOGIN_DASHBOARD_REQUEST)
  const hasStarted = useRef(false)
  const [setup2fa] = use2faSetup<Setup2faResponse>()

  useEffect(() => {
    if (hasStarted.current || !autologinEmail || !autologinToken) {
      return
    }
    hasStarted.current = true

    const auto = async (email: string, autologinToken: string) => {
      try {
        autologin({
          variables: {
            email,
            autologinToken,
          },
          onError: (autologinError) => {
            console.error("Autologin error:", autologinError.message)
            const params = new URLSearchParams({ error: autologinError.message }).toString()
            window.location.href = `${ONBOARDING_SIGNIN_URL}?${params}`
          },
          onCompleted: async (data) => {
            if (isAutologinSuccess(data)) {
              const token = data.autologin.token
              localStorage.setItem("token", token)
              // Clear store cache to avoid graphql having cache from another session (AKA another merchant)
              await client.clearStore()
              navigate(urlToRedirectTo, { replace: true })
            }
            if (is2faSetupRequired(data)) {
              const signin2faError = convertAutologin2faErrorToSignin2faError(data)
              setup2fa({
                data: signin2faError,
                onCompleted: async (challenge) => {
                  const checkEnrollChallenge = challenge.check2FAEnrollChallenge
                  if ("status" in checkEnrollChallenge && checkEnrollChallenge.status === "SUCCESS") {
                    await autologin({
                      variables: {
                        email,
                        autologinToken,
                      },
                      onError: (autologinError) => {
                        console.error("Autologin error:", autologinError.message)
                        const params = new URLSearchParams({ error: autologinError.message }).toString()
                        window.location.href = `${ONBOARDING_SIGNIN_URL}?${params}`
                      },
                      onCompleted: async (data) => {
                        if (!isAutologinSuccess(data)) {
                          const params = new URLSearchParams({ error: "UNKNOWN_ERROR" }).toString()
                          window.location.href = `${ONBOARDING_SIGNIN_URL}?${params}`
                          return
                        }
                        const token = data.autologin.token
                        localStorage.setItem("token", token)
                        // Clear store cache to avoid graphql having cache from another session (AKA another merchant)
                        await client.clearStore()
                        navigate(urlToRedirectTo, { replace: true })
                      },
                    })
                  }
                },
                onError: (setupError) => {
                  const errorCode = setupError.check2FAEnrollChallenge.errorCode

                  const params = new URLSearchParams({ error: errorCode }).toString()
                  window.location.href = `${ONBOARDING_SIGNIN_URL}?${params}`
                },
              })
            }
            if (is2faSetupInProgress(data)) {
              const params = new URLSearchParams({ error: "2FA_SETUP_IN_PROGRESS" }).toString()
              window.location.href = `${ONBOARDING_SIGNIN_URL}?${params}`
            }
            if (isUnknownError(data)) {
              const params = new URLSearchParams({ error: "UNKNOWN_ERROR" }).toString()
              window.location.href = `${ONBOARDING_SIGNIN_URL}?${params}`
            }
          },
        })
      } catch (e) {
        const errorMessage = (e as Error).message
        console.error("Autologin error:", errorMessage)
        const params = new URLSearchParams({ error: errorMessage }).toString()
        window.location.href = `${ONBOARDING_SIGNIN_URL}?${params}`
      }
    }

    auto(autologinEmail, autologinToken)
  }, [autologin, autologinEmail, autologinToken, client, navigate, urlToRedirectTo, setup2fa])

  return <div></div>
}
