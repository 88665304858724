import { gql, useQuery } from "@apollo/client"
import { fakeMerchantTransactions } from "../demo/fakeMerchantTransaction"

export const GET_MERCHANT_KPIs = gql`
  query {
    merchantGetKPIs {
      totalCashedIn
      totalLateAndDefault
    }
    merchantGetMoney {
      merchantMinimumReserve
      totalPaymentsReserve
      holdAccountsBalance
      merchantFundingRate
      balance
    }
  }
`

type GetMerchantKPIsQueryType = {
  merchantGetKPIs: {
    totalCashedIn: number
    totalLateAndDefault: number
  }

  merchantGetMoney: {
    merchantMinimumReserve: number
    totalPaymentsReserve: number
    holdAccountsBalance: number
    merchantFundingRate: number
    balance: number
  }
}

export const useGetMerchantKPIs = ({ useDemoData }: { useDemoData: boolean }) => {
  const query = useQuery<GetMerchantKPIsQueryType>(GET_MERCHANT_KPIs)

  if (useDemoData) {
    return {
      data: getDemoData(),
      error: undefined,
      loading: false,
    }
  }

  return {
    data: query.data,
    error: query.error,
    loading: query.loading,
  }
}

const getDemoData = (): GetMerchantKPIsQueryType => {
  const totalCashedIn = fakeMerchantTransactions.reduce(
    (acc, curr) => (curr.status === "PAID" ? acc + curr.amount : acc),
    0,
  )

  const latePayment = fakeMerchantTransactions.reduce(
    (acc, curr) => (curr.status === "PAID" ? acc : acc + curr.amount),
    0,
  )

  const balance = Math.round(totalCashedIn * 0.2)

  const fakeData = {
    merchantGetKPIs: {
      totalCashedIn,
      totalLateAndDefault: latePayment,
    },
    merchantGetMoney: {
      merchantMinimumReserve: 0,
      totalPaymentsReserve: 0,
      holdAccountsBalance: 0,
      merchantFundingRate: 0,
      balance,
    },
  }

  return fakeData
}
