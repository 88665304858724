import { Field, Typography } from "@hero/krypton"
import styled from "styled-components"
import { useDashboardTranslation } from "../../../../01_technical/translations"
import { useCwtApplicationFormContext } from "./ApplicationForm.utils"

const Spacer = styled.div<{ $h: string }>`
  height: ${({ $h }) => $h};
`

const FieldRow = styled.div`
  display: flex;
  gap: 1.5rem;

  & > * {
    flex: 1;
  }
`

export const AmountFormSection = () => {
  const { t } = useDashboardTranslation()
  const { register, formState } = useCwtApplicationFormContext()

  return (
    <>
      <Typography $variant="title-2-bold">{t("creditWireTransfer.applicationForm.amountToFinance")}</Typography>

      <Spacer $h={"1rem"} />

      <FieldRow>
        <Field
          $fullWidth
          fieldLabel={t("creditWireTransfer.applicationForm.amount")}
          type="number"
          errorMessage={formState.errors?.amount?.message}
          step="0.01"
          {...register("amount", { valueAsNumber: true })}
        />

        <Field
          $fullWidth
          fieldLabel={t("creditWireTransfer.applicationForm.wireLabel")}
          type="text"
          {...register("label")}
          errorMessage={formState.errors?.label?.message}
        />
      </FieldRow>

      <Typography $variant="caption-2">{t("creditWireTransfer.applicationForm.financingDisclaimer")}</Typography>

      <Spacer $h={"1rem"} />

      <Typography $variant="caption-2">{t("creditWireTransfer.applicationForm.financingDisclaimer2")}</Typography>

      <Spacer $h={"1.5rem"} />
    </>
  )
}
