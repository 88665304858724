import styled from "styled-components"
import { Typography } from "@hero/krypton"

const PaymentInfoLine = styled.div`
  display: flex;
  margin-top: 0.25rem;
  flex-direction: row;
  justify-content: space-between;
`

const PaymentInfoLabel = styled(Typography)`
  color: ${({ theme }) => theme.colors.grey.$500};
`

export const PaymentInfo: React.FC<{ label: string; value: string }> = ({ label, value }) => (
  <PaymentInfoLine>
    <PaymentInfoLabel $variant="body-4-regular">{label}</PaymentInfoLabel>
    <Typography $variant="body-4-medium">{value}</Typography>
  </PaymentInfoLine>
)
