import { useHeroQuery } from "../../../../01_technical/requesting/useHeroQuery/useHeroQuery"
import { GET_INCOMING_TRANSFERS_QUERY, GetIncomingTransfersSuccessResponse } from "../Balance.requests"
import { getBalanceIncomingFakeData } from "./balanceIncomingFakeData.utils"

export const useGetIncomingTransfers = (
  filters: {
    marketplaceNames: string[]
    amountMin?: number
    amountMax?: number
    amountEqual?: number
    createdBefore?: Date
    createdAfter?: Date
  },
  pagination: { pageNumber: number; pageSize: number },
  config: { useDemoData: boolean } = { useDemoData: false },
) => {
  const { data, error, loading } = useHeroQuery<GetIncomingTransfersSuccessResponse>({
    gqlQuerySchema: GET_INCOMING_TRANSFERS_QUERY,
    variables: {
      pagination,
      filters,
    },
    skip: config.useDemoData,
  })

  if (config.useDemoData) {
    return {
      data: getBalanceIncomingFakeData(filters, pagination),
      error: undefined,
      loading: false,
    }
  }

  return {
    data,
    error,
    loading,
  }
}
