import { Outlet } from "react-router-dom"
import styled from "styled-components"
import { AccountDetailsTabs } from "./components/AccountDetailsTabs"

const Container = styled.div`
  gap: 1.5rem;
  flex: 1;
  display: flex;
  align-items: flex-start;

  @media (max-width: 768px) {
    display: block;
  }
`

export const AccountDetailsRoot = () => {
  return (
    <Container>
      <AccountDetailsTabs />
      <div style={{ flex: 1 }}>
        <Outlet />
      </div>
    </Container>
  )
}
