import { gql } from "@apollo/client"

export type MerchantSendPaymentLinkByMessageParameters = {
  paymentLinkId: string
}

export const MERCHANT_SEND_PAYMENT_LINK_BY_SMS_REQUEST = gql`
  mutation MerchantSendPaymentLinkBySms($paymentLinkId: String!) {
    merchantSendPaymentLinkBySms(paymentLinkId: $paymentLinkId)
  }
`

export const MERCHANT_SEND_PAYMENT_LINK_BY_WHATSAPP_REQUEST = gql`
  mutation MerchantSendPaymentLinkByWhatsapp($paymentLinkId: String!) {
    merchantSendPaymentLinkByWhatsapp(paymentLinkId: $paymentLinkId)
  }
`
