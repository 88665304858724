import { Typography } from "@hero/krypton"
import styled from "styled-components"
import { useDashboardTranslation } from "../../../01_technical/translations"
import { useBusinessAccountContext } from "../../BusinessAccount/businessAccount.context"

const Banner = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.colors.grey.$600};
  height: 3.75rem;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const AccountVerificationBanner = () => {
  const { isBannerActive } = useBusinessAccountContext()
  const { t } = useDashboardTranslation()

  return (
    <>
      {isBannerActive && (
        <Banner>
          <Typography $color="grey.$300" $variant="body-3-regular">
            {t("businessAccount.inactiveBanner")}
          </Typography>
        </Banner>
      )}
    </>
  )
}
