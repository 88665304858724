import { Badge, theme, Typography } from "@hero/krypton"
import { ReactNode } from "react"
import styled from "styled-components"
import { FlexContainer } from "../../../../../00_shared/components/Flex"
import { IssuanceType } from "../../../00_shared/business/IssuingCard"
import { useDashboardTranslation } from "../../../../../01_technical/translations"

const Cards = styled.div`
  display: flex;
  gap: 1rem;
`

const CardContent = styled.div<{ selected?: boolean }>`
  min-width: 0;
  overflow: hidden;
  padding: 5rem 2rem;
  flex: 1 1 0;
  width: 0;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0 2px 8px 0 #00000014;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: ${({ selected }) =>
    selected ? `1px solid ${theme.colors.black.$700}` : `1px solid ${theme.colors.grey.$200}`};
  position: relative;
  cursor: pointer;
`

const RadioInput = styled.input`
  appearance: none;
  display: inline-block;
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  border: 1px solid ${({ theme }) => theme.colors.grey.$400};
  cursor: pointer;
  position: absolute;
  top: 1rem;
  left: 1rem;

  &:checked::before {
    content: "";
    display: inline-block;
    width: 0.5rem;
    height: 0.5rem;
    background-color: ${({ theme }) => theme.colors.black.$700};
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`

const ComingSoon = styled.div`
  position: absolute;
  top: 1rem;
  left: 1rem;
`

interface RadioCardSelectProps {
  cards: {
    title: string
    description: string
    image: ReactNode
    value: IssuanceType
    comingSoon?: boolean
  }[]
  reverseOrdering: boolean
  selectedCard: IssuanceType
  onSelectCard: (index: number) => void
}

export const IssuingRadioCardSelect = ({
  cards,
  selectedCard,
  onSelectCard,
  reverseOrdering,
}: RadioCardSelectProps) => {
  const { t } = useDashboardTranslation()
  return (
    <Cards style={{ flexDirection: reverseOrdering ? "row-reverse" : "row" }}>
      {cards.map((card, index) => (
        <CardContent
          key={index}
          onClick={() => !card.comingSoon && onSelectCard(index)}
          selected={selectedCard === card.value}
        >
          {!card.comingSoon && (
            <RadioInput type="radio" checked={selectedCard === card.value} onChange={() => onSelectCard(index)} />
          )}
          {card.comingSoon && (
            <ComingSoon>
              <Badge $variant="marketing">{t("issuing.comingSoon")}</Badge>
            </ComingSoon>
          )}
          <FlexContainer $direction="column" $gap=".5rem" $align="center">
            <div>{card.image}</div>
            <FlexContainer $direction="column" $gap=".3rem" $wrap="nowrap" $align="center">
              <Typography $variant="body-3-medium">{card.title}</Typography>
              <Typography style={{ textAlign: "center" }} $variant="caption-2">
                {card.description}
              </Typography>
            </FlexContainer>
          </FlexContainer>
        </CardContent>
      ))}
    </Cards>
  )
}
