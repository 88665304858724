import { Button, Modal, Spinner, Typography } from "@hero/krypton"

import { useState } from "react"
import styled from "styled-components"
import { SupportContact } from "../../00_shared/components/SupportContact"
import { useCountdown } from "../../00_shared/hooks/useCountdown.hook"
import { useCommonTranslation, useDashboardTranslation } from "../../01_technical/translations"
import accessDeniedImage from "../../assets/access_denied.svg"
import { ChallengeType, useResend } from "../Resend/Resend.requests"

const StyledSpinner = styled(Spinner)``

const Title = styled(Typography)`
  margin-bottom: 0.25rem;
`

const LoadingWrapper = styled.div`
  margin-top: 1rem;
  display: flex;
  gap: 1rem;
  background-color: ${({ theme }) => theme.colors.white};
  align-items: center;
`

const ImageWrapper = styled.div`
  height: 6.25rem;
  width: 6.25rem;
  margin-bottom: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
`

const Wrapper = styled.div`
  padding: 18px;
`

interface Challenge2faModalProps {
  is2faModalOpen: boolean
  close2faModal: () => void
  phone: string
  challengeId: string
}
const RESEND_CHALLENGE_RETRIES_LIMIT = 4

export const Challenge2faModal = ({ is2faModalOpen, close2faModal, phone, challengeId }: Challenge2faModalProps) => {
  const { t } = useCommonTranslation()
  const { t: tDashboard } = useDashboardTranslation()
  const { timeLeft_s, resetCounting } = useCountdown()
  const [resend, { loading }] = useResend()
  const [retriedNumber, setRetriedNumber] = useState(0)

  const sendChallenge = async () => {
    await resend({
      variables: {
        details: {
          challengeId,
          type: ChallengeType.FOR_CHALLENGE_2FA,
        },
      },
    })
  }

  const isCounting = timeLeft_s > 0

  return (
    <Modal canCloseOutside={false} isOpen={is2faModalOpen} onClose={close2faModal} zIndex={300}>
      <Wrapper>
        <ImageWrapper>
          <img src={accessDeniedImage} alt="" />
        </ImageWrapper>
        <Title $variant="title-3-semibold">{t("auth.2fa.modal.title")}</Title>

        {phone && <Title $variant="title-3-inter-bold">{phone}</Title>}
        <Typography>{t("auth.2fa.modal.description")}</Typography>
        {retriedNumber > RESEND_CHALLENGE_RETRIES_LIMIT ? (
          <>
            <br />
            <Typography $variant="caption-1">
              {tDashboard("auth.2fa.enrollment.modal.resend.retriedTooManyTimes")}
            </Typography>
          </>
        ) : (
          <>
            <Button
              data-test-id="retry-2fa-button"
              disabled={isCounting}
              isLoading={loading}
              size="medium"
              style={{ marginTop: "1rem", marginBottom: "1rem", alignSelf: "center" }}
              onClick={async () => {
                setRetriedNumber((prevNumber) => prevNumber + 1)
                await sendChallenge()
                resetCounting()
              }}
            >
              {isCounting
                ? tDashboard("auth.2fa.enrollment.modal.resend.smsWithCountdown", { x: timeLeft_s })
                : tDashboard("auth.2fa.enrollment.modal.resend.sms")}
            </Button>
            <br />
            <LoadingWrapper>
              <StyledSpinner />
              <Typography $variant="caption-1">{t("auth.2fa.modal.redirect")}</Typography>
            </LoadingWrapper>
          </>
        )}
        <SupportContact />
      </Wrapper>
    </Modal>
  )
}
