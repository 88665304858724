import { ElementType } from "react"
import styled, { PolymorphicComponentProps } from "styled-components"

// Define Flex Container
const FlexContainerComponent = styled.div<{
  $direction?: "row" | "column"
  $justify?: "flex-start" | "center" | "flex-end" | "space-between" | "space-around" | "space-evenly"
  $align?: "stretch" | "flex-start" | "center" | "flex-end" | "baseline"
  $wrap?: "nowrap" | "wrap" | "wrap-reverse"
  $gap?: string
}>`
  display: flex;
  flex-direction: ${({ $direction }) => $direction || "row"};
  justify-content: ${({ $justify }) => $justify || "flex-start"};
  align-items: ${({ $align }) => $align || "stretch"};
  flex-wrap: ${({ $wrap }) => $wrap || "nowrap"};
  gap: ${({ $gap }) => $gap || "0"};
`

// Define Flex Item
const FlexItemComponent = styled.div<{
  $grow?: number
  $shrink?: number
  $basis?: string
  $alignSelf?: "auto" | "flex-start" | "center" | "flex-end" | "baseline" | "stretch"
}>`
  flex-grow: ${({ $grow }) => $grow || 0};
  flex-shrink: ${({ $shrink }) => $shrink || 1};
  flex-basis: ${({ $basis }) => $basis || "auto"};
  align-self: ${({ $alignSelf }) => $alignSelf || "auto"};
`

export type FlexProps<C extends ElementType> = PolymorphicComponentProps<
  "web",
  {
    $direction?: "row" | "column"
    $justify?: "flex-start" | "center" | "flex-end" | "space-between" | "space-around" | "space-evenly"
    $align?: "stretch" | "flex-start" | "center" | "flex-end" | "baseline"
    $wrap?: "nowrap" | "wrap" | "wrap-reverse"
    $gap?: string
  },
  C,
  C
>

export const FlexContainer = <C extends ElementType = "div">({
  as,
  $direction,
  $justify,
  $align,
  $wrap,
  $gap,
  ...props
}: FlexProps<C>) => {
  return (
    <FlexContainerComponent
      as={as}
      $direction={$direction}
      $justify={$justify}
      $align={$align}
      $wrap={$wrap}
      $gap={$gap}
      {...props}
    />
  )
}

export type FlexItemProps<C extends ElementType> = PolymorphicComponentProps<
  "web",
  {
    $grow?: number
    $shrink?: number
    $basis?: string
    $alignSelf?: "auto" | "flex-start" | "center" | "flex-end" | "baseline" | "stretch"
  },
  C,
  C
>

export const FlexItem = <C extends ElementType = "div">({
  as,
  $grow,
  $shrink,
  $basis,
  $alignSelf,
  ...props
}: FlexItemProps<C>) => {
  return (
    <FlexItemComponent as={as} $grow={$grow} $shrink={$shrink} $basis={$basis} $alignSelf={$alignSelf} {...props} />
  )
}
