import { gql, useLazyQuery } from "@apollo/client"
import { toaster } from "@hero/krypton"
import { useEffect } from "react"

type GetDownloadAttachmentUrlResponse = {
  downloadablePaymentLinkResourceSignedUrl: {
    signedUrl: string
  }
}

const GET_DOWNLOAD_ATTACHMENT_URL = gql`
  query getDownloadAttachmentUrl($paymentLinkAttachmentId: String!) {
    downloadablePaymentLinkResourceSignedUrl(paymentLinkAttachmentId: $paymentLinkAttachmentId) {
      signedUrl
    }
  }
`

export const useDownloadAttachment: () => (paymentLinkAttachmentId: string) => void = () => {
  const [fetchDownloadURL, { data, error }] = useLazyQuery<
    GetDownloadAttachmentUrlResponse,
    { paymentLinkAttachmentId: string }
  >(GET_DOWNLOAD_ATTACHMENT_URL, { fetchPolicy: "network-only" })

  useEffect(() => {
    if (data) {
      window.open(data.downloadablePaymentLinkResourceSignedUrl.signedUrl, "_blank")
    }
  }, [data])

  useEffect(() => {
    if (error) {
      console.error(error)
      toaster.error("Une erreur est survenue. Veuillez réessayer ultérieurement.")
    }
  }, [error])

  return (paymentLinkAttachmentId) => {
    fetchDownloadURL({
      variables: {
        paymentLinkAttachmentId,
      },
    })
  }
}
