import { Spinner, Textarea, Typography } from "@hero/krypton"
import { useEffect, useState } from "react"
import styled from "styled-components"
import { useDebounce } from "../../../../00_shared/hooks/useDebounce.hooks"
import { useDashboardTranslation } from "../../../../01_technical/translations"
import { useOperationDetails } from "../../pro-account/00_shared/hooks/useOperationDetails"
import { useUpdateOperationMetadata } from "../../pro-account/00_shared/hooks/useUpdateOperationMetadata"
import { LedgerMovementFlow } from "../business"

const NoteContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  position: relative;
  padding: 0.25rem;
`

const SavingIndicator = styled.div`
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: ${({ theme }) => theme.colors.primary.$300};
`

const SpinnerContainer = styled.div`
  width: 1rem;
  height: 1rem;
  display: flex;
  align-items: center;
`

export const TransactionNote = ({
  title,
  operationId,
  note: initialNote = null,
  ledgerMovementId,
  flow,
}: {
  title: string
  operationId: string
  ledgerMovementId: string
  flow: LedgerMovementFlow
  note?: string | null
}) => {
  const { t } = useDashboardTranslation()
  const [note, setNote] = useState(initialNote)
  const [isSaving, setIsSaving] = useState(false)
  const { updateOperationMetadata } = useUpdateOperationMetadata()
  const { refetch } = useOperationDetails({
    ledgerMovementId,
    flow,
    operationId,
  })
  const [debouncedNote] = useDebounce(note, 1000)

  useEffect(() => {
    const updateNote = async () => {
      if (debouncedNote !== initialNote) {
        const cleanedNote = typeof debouncedNote === "string" ? debouncedNote.trim() : null
        setIsSaving(true)
        await updateOperationMetadata({ operationId, metadata: { note: cleanedNote } })
        refetch()
        setIsSaving(false)
      }
    }

    updateNote()
  }, [debouncedNote, initialNote, updateOperationMetadata, operationId, refetch])

  const handleNoteChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setNote(event.target.value || null)
  }

  useEffect(() => {
    setNote(initialNote)
  }, [initialNote])

  return (
    <NoteContainer>
      <Typography $variant="body-4-medium">{title}</Typography>
      <Textarea
        forwardedAs={"textarea"}
        value={note || ""}
        onChange={handleNoteChange}
        placeholder={t("accounts.note.placeholder")}
        disabled={isSaving}
        $isInError={false} // Adjust this prop based on error state if needed
        $fullWidth={true} // Adjust width as per your layout needs
      />

      {isSaving && (
        <SavingIndicator>
          <SpinnerContainer>
            <Spinner />
          </SpinnerContainer>
          <Typography $variant="body-4-medium">{t("accounts.note.saving.text")}</Typography>
        </SavingIndicator>
      )}
    </NoteContainer>
  )
}
