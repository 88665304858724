import { TBody, THead, Table } from "@hero/krypton"
import styled from "styled-components"
import { useDashboardTranslation } from "../../../../01_technical/translations"
import { MrfInstallment } from "./ActiveMRF.request"
import { ActiveMRFInstallmentsTableRow } from "./ActiveMRFInstallmentsTableRow"

const Wrapper = styled.div`
  margin: 0rem 3rem;
  border-radius: 0.75rem;
  border: 1px solid ${({ theme }) => theme.colors.grey.$200};
  overflow: hidden;
`

const Th = styled.th`
  box-shadow: none !important;
  border-bottom: 1px solid ${({ theme }) => theme.colors.grey.$200};
`

interface ActiveMRFInstallmentsTableProps {
  installments: MrfInstallment[]
}

const ActiveMRFInstallmentsTable = ({ installments }: ActiveMRFInstallmentsTableProps) => {
  const { t } = useDashboardTranslation()

  return (
    <Wrapper>
      <Table>
        <THead>
          <tr>
            <Th>{t("mrf.details.installments.dueDate")}</Th>
            <Th>{t("mrf.details.installments.amount")}</Th>
            <Th>{t("mrf.details.installments.amountPaid")}</Th>
          </tr>
        </THead>
        <TBody $clickable>
          {installments.map((installment) => (
            <ActiveMRFInstallmentsTableRow installment={installment} key={installment.id} />
          ))}
        </TBody>
      </Table>
    </Wrapper>
  )
}

export default ActiveMRFInstallmentsTable
