export const forEnumOrElse = <E extends string | number, ReturnType>(
  enumValue: E,
  callbacks: Partial<Record<E, () => ReturnType | never>>,
  fallback: () => ReturnType,
) => {
  const cb = callbacks[enumValue]
  if (!cb) {
    return fallback()
  }
  return cb()
}
