import { useCommonTranslation } from "../translations"
import {
  ApiError,
  CommonApiErrorCodes,
  GqlHeroError,
  HeroApiError,
  RequestErrors,
  ValidationGraphQLError,
} from "./request-error.type"

// ! In case of edit, make sure to update the copy in the admin app as well !

export function getErrorWithCode(error: ApiError): HeroApiError {
  if (isValidationError(error)) {
    return new HeroApiError({
      code: error.errorCode,
      message: getErrorMessageFromValidationErrors(error.validationErrors),
    })
  }

  if (isGqlHeroError(error)) {
    return new HeroApiError({ code: error.errorCode, message: error.message })
  }

  if (error.errorCode) {
    return new HeroApiError({ code: error.errorCode, message: error.message || error.errorCode })
  }

  return new HeroApiError({ code: CommonApiErrorCodes.UNKNOWN_ERROR, message: "An error occured." })
}

/**
 * @public for testing
 */
export function getErrorMessageFromValidationErrors(errors: ValidationGraphQLError["validationErrors"]) {
  return errors.map(({ path, validationError }) => `${path.join(" & ")} => ${validationError}`).join(". ") + "."
}

export function isApiError<T>(data: T | RequestErrors): data is ApiError {
  return data && typeof data === "object" && "errorCode" in data
}

export const isGqlHeroError = (payload: unknown): payload is GqlHeroError => {
  return typeof payload === "object" && payload ? "message" in payload : false
}

export const isValidationError = (payload: unknown): payload is ValidationGraphQLError => {
  return typeof payload === "object" && payload ? "validationErrors" in payload : false
}
export function useAddTranslatedMessageToHeroApiError() {
  const { t } = useCommonTranslation()

  return (error: HeroApiError, additionnalTranslations?: Record<string, string>) => {
    error.translatedMessage =
      {
        [CommonApiErrorCodes.VALIDATION_ERRORS]: t("queryError.validationError"),
        [CommonApiErrorCodes.SERVER_OR_NETWORK_ERROR]: t("queryError.serverOrNetworkError"),
        [CommonApiErrorCodes.NOT_FOUND]: t("queryError.notFoundError"),
        [CommonApiErrorCodes.UNAUTHORIZED]: t("queryError.unauthorizedError"),
        [CommonApiErrorCodes.FORBIDDEN]: t("queryError.unauthorizedError"),
        [CommonApiErrorCodes.INTERNAL_SERVER_ERROR]: t("queryError.internalServerError"),
        [CommonApiErrorCodes.FUNCTIONAL_ERROR]: t("queryError.functionalError"),
        [CommonApiErrorCodes.INVALID_ARGUMENTS]: t("queryError.validationError"),
        ...additionnalTranslations,
      }[error.code] || t("queryError.unknownError")

    return error
  }
}
