import { TransferStatus } from "../../00_shared/business/enum/IncomingTransferStatus.enum"

export const balanceIncomingFakeData = [
  {
    swanTransactionId: "psptran-bc3b47be-aba1-4dea-b08b-6d4642a94945",
    amount: 787808,
    date: "2024-02-15T12:29:02.923Z",
    logoUrl:
      "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/AMAZON",
    marketplaceName: "AMAZON",
    status: TransferStatus.ALLOCATED,
    account: {
      number: "0037082",
      name: "Hero AP - AMAZON",
      __typename: "IncomingTransferAccountOutput",
    },
    __typename: "IncomingTransferOutput",
  },
  {
    swanTransactionId: "psptran-106692ad-fdcd-4366-ace4-38f7980e7e80",
    amount: 754532,
    date: "2024-02-12T12:29:10.172Z",
    logoUrl:
      "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/AMAZON",
    marketplaceName: "AMAZON",
    status: TransferStatus.ALLOCATED,
    account: {
      number: "0037082",
      name: "Hero AP - AMAZON",
      __typename: "IncomingTransferAccountOutput",
    },
    __typename: "IncomingTransferOutput",
  },
  {
    swanTransactionId: "psptran-2c60d939-d5c3-4016-8198-d3f55d5bc866",
    amount: 1739677,
    date: "2024-02-12T10:34:56.308Z",
    logoUrl:
      "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/AMAZON",
    marketplaceName: "AMAZON",
    status: TransferStatus.ALLOCATED,
    account: {
      number: "0037082",
      name: "Hero AP - AMAZON",
      __typename: "IncomingTransferAccountOutput",
    },
    __typename: "IncomingTransferOutput",
  },
  {
    swanTransactionId: "psptran-c93f0023-62e3-4f8e-9585-8778a92afade",
    amount: 1088012,
    date: "2024-02-07T10:34:04.257Z",
    logoUrl:
      "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/AMAZON",
    marketplaceName: "AMAZON",
    status: TransferStatus.ALLOCATED,
    account: {
      number: "0037082",
      name: "Hero AP - AMAZON",
      __typename: "IncomingTransferAccountOutput",
    },
    __typename: "IncomingTransferOutput",
  },
  {
    swanTransactionId: "psptran-bf5a1533-3ca2-49eb-8e6a-96cd9f31f745",
    amount: 776498,
    date: "2024-02-05T08:09:38.869Z",
    logoUrl:
      "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/AMAZON",
    marketplaceName: "AMAZON",
    status: TransferStatus.ALLOCATED,
    account: {
      number: "0037082",
      name: "Hero AP - AMAZON",
      __typename: "IncomingTransferAccountOutput",
    },
    __typename: "IncomingTransferOutput",
  },
  {
    swanTransactionId: "psptran-5df39a8b-00c2-44ed-b768-2c96dbeba286",
    amount: 1881846,
    date: "2024-02-02T08:09:20.472Z",
    logoUrl:
      "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/LEROY%20MERLIN",
    marketplaceName: "LEROY MERLIN",
    status: TransferStatus.ALLOCATED,
    account: {
      number: "0036654",
      name: "Hero AP - LEROY MERLIN",
      __typename: "IncomingTransferAccountOutput",
    },
    __typename: "IncomingTransferOutput",
  },
  {
    swanTransactionId: "psptran-8ceddc12-1093-49f6-89cb-f786287b28e8",
    amount: 656495,
    date: "2024-02-01T12:29:17.784Z",
    logoUrl:
      "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/AMAZON",
    marketplaceName: "AMAZON",
    status: TransferStatus.ALLOCATED,
    account: {
      number: "0037082",
      name: "Hero AP - AMAZON",
      __typename: "IncomingTransferAccountOutput",
    },
    __typename: "IncomingTransferOutput",
  },
  {
    swanTransactionId: "psptran-6c771b01-274f-4586-aa01-c77be6c15181",
    amount: 1191764,
    date: "2024-01-31T08:09:41.920Z",
    logoUrl:
      "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/AMAZON",
    marketplaceName: "AMAZON",
    status: TransferStatus.ALLOCATED,
    account: {
      number: "0037082",
      name: "Hero AP - AMAZON",
      __typename: "IncomingTransferAccountOutput",
    },
    __typename: "IncomingTransferOutput",
  },
  {
    swanTransactionId: "psptran-aef67961-d4c0-407d-981a-7e9e0cd2e4f5",
    amount: 1217357,
    date: "2024-01-26T10:34:22.512Z",
    logoUrl:
      "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/AMAZON",
    marketplaceName: "AMAZON",
    status: TransferStatus.ALLOCATED,
    account: {
      number: "0037082",
      name: "Hero AP - AMAZON",
      __typename: "IncomingTransferAccountOutput",
    },
    __typename: "IncomingTransferOutput",
  },
  {
    swanTransactionId: "psptran-86032fc5-4153-47da-950a-3e5b12764faf",
    amount: 483260,
    date: "2024-01-24T16:29:11.678Z",
    logoUrl:
      "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/AMAZON",
    marketplaceName: "AMAZON",
    status: TransferStatus.ALLOCATED,
    account: {
      number: "0037082",
      name: "Hero AP - AMAZON",
      __typename: "IncomingTransferAccountOutput",
    },
    __typename: "IncomingTransferOutput",
  },
  {
    swanTransactionId: "psptran-0cf15bd5-5660-4c2b-817f-3642bb31a7dd",
    amount: 2809717,
    date: "2024-01-23T08:09:13.946Z",
    logoUrl:
      "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/LEROY%20MERLIN",
    marketplaceName: "LEROY MERLIN",
    status: TransferStatus.ALLOCATED,
    account: {
      number: "0036654",
      name: "Hero AP - LEROY MERLIN",
      __typename: "IncomingTransferAccountOutput",
    },
    __typename: "IncomingTransferOutput",
  },
  {
    swanTransactionId: "psptran-ed8cbbfd-8134-4898-a1cd-0e98725d8935",
    amount: 905146,
    date: "2024-01-22T10:34:39.866Z",
    logoUrl:
      "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/AMAZON",
    marketplaceName: "AMAZON",
    status: TransferStatus.ALLOCATED,
    account: {
      number: "0037082",
      name: "Hero AP - AMAZON",
      __typename: "IncomingTransferAccountOutput",
    },
    __typename: "IncomingTransferOutput",
  },
  {
    swanTransactionId: "psptran-2d4317e8-340c-4e4e-9377-6b8a09b4cfeb",
    amount: 734037,
    date: "2024-01-19T10:34:10.617Z",
    logoUrl:
      "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/AMAZON",
    marketplaceName: "AMAZON",
    status: TransferStatus.ALLOCATED,
    account: {
      number: "0037082",
      name: "Hero AP - AMAZON",
      __typename: "IncomingTransferAccountOutput",
    },
    __typename: "IncomingTransferOutput",
  },
  {
    swanTransactionId: "psptran-f59ce899-d213-4b42-9de7-12f9f96553b7",
    amount: 1073085,
    date: "2024-01-18T08:09:09.313Z",
    logoUrl:
      "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/AMAZON",
    marketplaceName: "AMAZON",
    status: TransferStatus.ALLOCATED,
    account: {
      number: "0037082",
      name: "Hero AP - AMAZON",
      __typename: "IncomingTransferAccountOutput",
    },
    __typename: "IncomingTransferOutput",
  },
  {
    swanTransactionId: "psptran-b2e010c6-acf1-4d77-8543-6c668d0c6308",
    amount: 1186502,
    date: "2024-01-15T10:34:29.512Z",
    logoUrl:
      "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/AMAZON",
    marketplaceName: "AMAZON",
    status: TransferStatus.ALLOCATED,
    account: {
      number: "0037082",
      name: "Hero AP - AMAZON",
      __typename: "IncomingTransferAccountOutput",
    },
    __typename: "IncomingTransferOutput",
  },
  {
    swanTransactionId: "psptran-a1dca8b6-f0cb-46bf-8872-bdb2cc9f1f4e",
    amount: 873139,
    date: "2024-01-12T10:34:22.002Z",
    logoUrl:
      "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/AMAZON",
    marketplaceName: "AMAZON",
    status: TransferStatus.ALLOCATED,
    account: {
      number: "0037082",
      name: "Hero AP - AMAZON",
      __typename: "IncomingTransferAccountOutput",
    },
    __typename: "IncomingTransferOutput",
  },
  {
    swanTransactionId: "psptran-1938b9f1-54c2-4e66-bd99-c79221d39def",
    amount: 5374847,
    date: "2024-01-12T08:09:48.010Z",
    logoUrl:
      "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/LEROY%20MERLIN",
    marketplaceName: "LEROY MERLIN",
    status: TransferStatus.ALLOCATED,
    account: {
      number: "0036654",
      name: "Hero AP - LEROY MERLIN",
      __typename: "IncomingTransferAccountOutput",
    },
    __typename: "IncomingTransferOutput",
  },
  {
    swanTransactionId: "psptran-fa5cc25e-6561-4810-bd63-b5459ca5d452",
    amount: 346029,
    date: "2024-01-09T10:34:27.273Z",
    logoUrl:
      "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/AMAZON",
    marketplaceName: "AMAZON",
    status: TransferStatus.ALLOCATED,
    account: {
      number: "0037082",
      name: "Hero AP - AMAZON",
      __typename: "IncomingTransferAccountOutput",
    },
    __typename: "IncomingTransferOutput",
  },
  {
    swanTransactionId: "psptran-7837ef2a-9480-4a64-9650-0d8a16603c17",
    amount: 510006,
    date: "2024-01-08T10:34:28.527Z",
    logoUrl:
      "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/AMAZON",
    marketplaceName: "AMAZON",
    status: TransferStatus.ALLOCATED,
    account: {
      number: "0037082",
      name: "Hero AP - AMAZON",
      __typename: "IncomingTransferAccountOutput",
    },
    __typename: "IncomingTransferOutput",
  },
  {
    swanTransactionId: "psptran-6ac363eb-fdba-47b1-a682-4292978fe6ff",
    amount: 415055,
    date: "2024-01-08T10:34:27.259Z",
    logoUrl:
      "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/AMAZON",
    marketplaceName: "AMAZON",
    status: TransferStatus.ALLOCATED,
    account: {
      number: "0037082",
      name: "Hero AP - AMAZON",
      __typename: "IncomingTransferAccountOutput",
    },
    __typename: "IncomingTransferOutput",
  },
  {
    swanTransactionId: "psptran-ca8efad4-4e8d-434b-afee-c18cece57d8f",
    amount: 665391,
    date: "2024-01-05T10:34:38.244Z",
    logoUrl:
      "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/AMAZON",
    marketplaceName: "AMAZON",
    status: TransferStatus.ALLOCATED,
    account: {
      number: "0037082",
      name: "Hero AP - AMAZON",
      __typename: "IncomingTransferAccountOutput",
    },
    __typename: "IncomingTransferOutput",
  },
  {
    swanTransactionId: "psptran-35c71d34-5018-470c-bbd4-570e41ae959c",
    amount: 712755,
    date: "2024-01-03T10:34:21.021Z",
    logoUrl:
      "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/AMAZON",
    marketplaceName: "AMAZON",
    status: TransferStatus.ALLOCATED,
    account: {
      number: "0037082",
      name: "Hero AP - AMAZON",
      __typename: "IncomingTransferAccountOutput",
    },
    __typename: "IncomingTransferOutput",
  },
  {
    swanTransactionId: "psptran-cf4e7c41-119c-4af7-8708-811fdbf4ed50",
    amount: 4193166,
    date: "2024-01-03T08:09:35.373Z",
    logoUrl:
      "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/LEROY%20MERLIN",
    marketplaceName: "LEROY MERLIN",
    status: TransferStatus.ALLOCATED,
    account: {
      number: "0036654",
      name: "Hero AP - LEROY MERLIN",
      __typename: "IncomingTransferAccountOutput",
    },
    __typename: "IncomingTransferOutput",
  },
  {
    swanTransactionId: "psptran-9befde41-0fbb-491e-bffd-b430342819d8",
    amount: 493871,
    date: "2024-01-02T10:35:12.680Z",
    logoUrl:
      "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/AMAZON",
    marketplaceName: "AMAZON",
    status: TransferStatus.ALLOCATED,
    account: {
      number: "0037082",
      name: "Hero AP - AMAZON",
      __typename: "IncomingTransferAccountOutput",
    },
    __typename: "IncomingTransferOutput",
  },
  {
    swanTransactionId: "psptran-baf6c3e3-232c-449a-a4af-94a197692879",
    amount: 1073889,
    date: "2023-12-29T10:36:49.463Z",
    logoUrl:
      "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/AMAZON",
    marketplaceName: "AMAZON",
    status: TransferStatus.ALLOCATED,
    account: {
      number: "0037082",
      name: "Hero AP - AMAZON",
      __typename: "IncomingTransferAccountOutput",
    },
    __typename: "IncomingTransferOutput",
  },
  {
    swanTransactionId: "psptran-0064113b-3921-47f8-9118-fe259a7c7e07",
    amount: 1034426,
    date: "2023-12-27T10:34:45.060Z",
    logoUrl:
      "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/AMAZON",
    marketplaceName: "AMAZON",
    status: TransferStatus.ALLOCATED,
    account: {
      number: "0037082",
      name: "Hero AP - AMAZON",
      __typename: "IncomingTransferAccountOutput",
    },
    __typename: "IncomingTransferOutput",
  },
  {
    swanTransactionId: "psptran-a8bcc424-1d03-484d-8889-fcdc23e0921a",
    amount: 755620,
    date: "2023-12-27T10:34:23.635Z",
    logoUrl:
      "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/AMAZON",
    marketplaceName: "AMAZON",
    status: TransferStatus.ALLOCATED,
    account: {
      number: "0037082",
      name: "Hero AP - AMAZON",
      __typename: "IncomingTransferAccountOutput",
    },
    __typename: "IncomingTransferOutput",
  },
  {
    swanTransactionId: "psptran-ed43212b-6f2c-4aa8-879a-d2f18e61549b",
    amount: 659809,
    date: "2023-12-27T10:34:23.250Z",
    logoUrl:
      "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/AMAZON",
    marketplaceName: "AMAZON",
    status: TransferStatus.ALLOCATED,
    account: {
      number: "0037082",
      name: "Hero AP - AMAZON",
      __typename: "IncomingTransferAccountOutput",
    },
    __typename: "IncomingTransferOutput",
  },
  {
    swanTransactionId: "psptran-85948dde-030c-4c91-a981-e809d68f3c3b",
    amount: 3159236,
    date: "2023-12-22T08:09:20.551Z",
    logoUrl:
      "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/LEROY%20MERLIN",
    marketplaceName: "LEROY MERLIN",
    status: TransferStatus.ALLOCATED,
    account: {
      number: "0036654",
      name: "Hero AP - LEROY MERLIN",
      __typename: "IncomingTransferAccountOutput",
    },
    __typename: "IncomingTransferOutput",
  },
  {
    swanTransactionId: "psptran-83962d2a-b7d8-47f6-9422-e8d992cc620c",
    amount: 1093880,
    date: "2023-12-21T10:34:31.323Z",
    logoUrl:
      "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/AMAZON",
    marketplaceName: "AMAZON",
    status: TransferStatus.ALLOCATED,
    account: {
      number: "0037082",
      name: "Hero AP - AMAZON",
      __typename: "IncomingTransferAccountOutput",
    },
    __typename: "IncomingTransferOutput",
  },
  {
    swanTransactionId: "psptran-92631786-a8a8-40c1-b548-73263cdcba7d",
    amount: 840985,
    date: "2023-12-19T12:29:24.314Z",
    logoUrl:
      "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/AMAZON",
    marketplaceName: "AMAZON",
    status: TransferStatus.ALLOCATED,
    account: {
      number: "0037082",
      name: "Hero AP - AMAZON",
      __typename: "IncomingTransferAccountOutput",
    },
    __typename: "IncomingTransferOutput",
  },
  {
    swanTransactionId: "psptran-be285722-002f-41f2-a3b2-03538c79d39e",
    amount: 946834,
    date: "2023-12-18T08:09:57.485Z",
    logoUrl:
      "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/AMAZON",
    marketplaceName: "AMAZON",
    status: TransferStatus.ALLOCATED,
    account: {
      number: "0037082",
      name: "Hero AP - AMAZON",
      __typename: "IncomingTransferAccountOutput",
    },
    __typename: "IncomingTransferOutput",
  },
  {
    swanTransactionId: "psptran-5203c837-c2a8-4d75-8aec-93b5917a51d7",
    amount: 552320,
    date: "2023-12-14T12:29:21.902Z",
    logoUrl:
      "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/AMAZON",
    marketplaceName: "AMAZON",
    status: TransferStatus.ALLOCATED,
    account: {
      number: "0037082",
      name: "Hero AP - AMAZON",
      __typename: "IncomingTransferAccountOutput",
    },
    __typename: "IncomingTransferOutput",
  },
  {
    swanTransactionId: "psptran-43f9de3e-efbd-4c38-9a97-664b9f60ef89",
    amount: 1185346,
    date: "2023-12-14T08:10:06.863Z",
    logoUrl:
      "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/LEROY%20MERLIN",
    marketplaceName: "LEROY MERLIN",
    status: TransferStatus.ALLOCATED,
    account: {
      number: "0036654",
      name: "Hero AP - LEROY MERLIN",
      __typename: "IncomingTransferAccountOutput",
    },
    __typename: "IncomingTransferOutput",
  },
  {
    swanTransactionId: "psptran-377a908c-84fb-4aad-81db-6bcba240e3dd",
    amount: 767556,
    date: "2023-12-13T12:29:27.225Z",
    logoUrl:
      "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/AMAZON",
    marketplaceName: "AMAZON",
    status: TransferStatus.ALLOCATED,
    account: {
      number: "0037082",
      name: "Hero AP - AMAZON",
      __typename: "IncomingTransferAccountOutput",
    },
    __typename: "IncomingTransferOutput",
  },
  {
    swanTransactionId: "psptran-1af570f4-e04f-49ea-af06-bbb61e50a82a",
    amount: 1928035,
    date: "2023-12-11T10:34:38.609Z",
    logoUrl:
      "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/AMAZON",
    marketplaceName: "AMAZON",
    status: TransferStatus.ALLOCATED,
    account: {
      number: "0037082",
      name: "Hero AP - AMAZON",
      __typename: "IncomingTransferAccountOutput",
    },
    __typename: "IncomingTransferOutput",
  },
  {
    swanTransactionId: "psptran-b73d672d-53c3-4d0d-a2c5-eaf33ef1ccee",
    amount: 240065,
    date: "2023-12-07T08:09:56.722Z",
    logoUrl:
      "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/AMAZON",
    marketplaceName: "AMAZON",
    status: TransferStatus.ALLOCATED,
    account: {
      number: "0037082",
      name: "Hero AP - AMAZON",
      __typename: "IncomingTransferAccountOutput",
    },
    __typename: "IncomingTransferOutput",
  },
  {
    swanTransactionId: "psptran-9fea7823-ef44-4968-99a4-fa8c8b85c122",
    amount: 1961088,
    date: "2023-12-05T08:09:20.281Z",
    logoUrl:
      "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/LEROY%20MERLIN",
    marketplaceName: "LEROY MERLIN",
    status: TransferStatus.ALLOCATED,
    account: {
      number: "0036654",
      name: "Hero AP - LEROY MERLIN",
      __typename: "IncomingTransferAccountOutput",
    },
    __typename: "IncomingTransferOutput",
  },
  {
    swanTransactionId: "psptran-d4deca47-ca1e-4348-b918-e9639c59824a",
    amount: 679896,
    date: "2023-12-04T10:34:17.482Z",
    logoUrl:
      "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/AMAZON",
    marketplaceName: "AMAZON",
    status: TransferStatus.ALLOCATED,
    account: {
      number: "0037082",
      name: "Hero AP - AMAZON",
      __typename: "IncomingTransferAccountOutput",
    },
    __typename: "IncomingTransferOutput",
  },
  {
    swanTransactionId: "psptran-08201779-eb02-4d8a-961c-a3aa55718a41",
    amount: 1379440,
    date: "2023-12-01T10:34:11.266Z",
    logoUrl:
      "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/AMAZON",
    marketplaceName: "AMAZON",
    status: TransferStatus.ALLOCATED,
    account: {
      number: "0037082",
      name: "Hero AP - AMAZON",
      __typename: "IncomingTransferAccountOutput",
    },
    __typename: "IncomingTransferOutput",
  },
  {
    swanTransactionId: "psptran-36316d76-cbe1-4b93-9995-aa851bb457b2",
    amount: 1695770,
    date: "2023-11-27T10:36:18.445Z",
    logoUrl:
      "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/AMAZON",
    marketplaceName: "AMAZON",
    status: TransferStatus.ALLOCATED,
    account: {
      number: "0037082",
      name: "Hero AP - AMAZON",
      __typename: "IncomingTransferAccountOutput",
    },
    __typename: "IncomingTransferOutput",
  },
  {
    swanTransactionId: "psptran-cacb489e-14ef-4a33-a548-9258999a9c11",
    amount: 1785276,
    date: "2023-11-27T08:09:41.555Z",
    logoUrl:
      "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/LEROY%20MERLIN",
    marketplaceName: "LEROY MERLIN",
    status: TransferStatus.ALLOCATED,
    account: {
      number: "0036654",
      name: "Hero AP - LEROY MERLIN",
      __typename: "IncomingTransferAccountOutput",
    },
    __typename: "IncomingTransferOutput",
  },
  {
    swanTransactionId: "psptran-fd2708ac-13e0-47d8-a7b9-4f71a12b0c95",
    amount: 583048,
    date: "2023-11-23T12:29:09.429Z",
    logoUrl:
      "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/AMAZON",
    marketplaceName: "AMAZON",
    status: TransferStatus.ALLOCATED,
    account: {
      number: "0037082",
      name: "Hero AP - AMAZON",
      __typename: "IncomingTransferAccountOutput",
    },
    __typename: "IncomingTransferOutput",
  },
  {
    swanTransactionId: "psptran-46b10833-d513-47fe-8793-2aa4f0cdfa8e",
    amount: 682742,
    date: "2023-11-22T08:09:33.358Z",
    logoUrl:
      "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/AMAZON",
    marketplaceName: "AMAZON",
    status: TransferStatus.ALLOCATED,
    account: {
      number: "0037082",
      name: "Hero AP - AMAZON",
      __typename: "IncomingTransferAccountOutput",
    },
    __typename: "IncomingTransferOutput",
  },
  {
    swanTransactionId: "psptran-6443db87-803b-4a23-a23f-2ff9acca78cf",
    amount: 1116546,
    date: "2023-11-20T10:34:35.486Z",
    logoUrl:
      "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/AMAZON",
    marketplaceName: "AMAZON",
    status: TransferStatus.ALLOCATED,
    account: {
      number: "0037082",
      name: "Hero AP - AMAZON",
      __typename: "IncomingTransferAccountOutput",
    },
    __typename: "IncomingTransferOutput",
  },
  {
    swanTransactionId: "psptran-9079fa37-6be2-4d47-a418-0d036ece7f11",
    amount: 647600,
    date: "2023-11-16T16:29:12.573Z",
    logoUrl:
      "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/AMAZON",
    marketplaceName: "AMAZON",
    status: TransferStatus.ALLOCATED,
    account: {
      number: "0037082",
      name: "Hero AP - AMAZON",
      __typename: "IncomingTransferAccountOutput",
    },
    __typename: "IncomingTransferOutput",
  },
  {
    swanTransactionId: "psptran-6c55f7f5-07f0-4bfb-b0a8-8efe4e787387",
    amount: 937467,
    date: "2023-11-14T12:29:18.628Z",
    logoUrl:
      "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/AMAZON",
    marketplaceName: "AMAZON",
    status: TransferStatus.ALLOCATED,
    account: {
      number: "0037082",
      name: "Hero AP - AMAZON",
      __typename: "IncomingTransferAccountOutput",
    },
    __typename: "IncomingTransferOutput",
  },
  {
    swanTransactionId: "psptran-c1fa49d8-c9fa-4e80-bbba-5dec6fa75edf",
    amount: 2053140,
    date: "2023-11-14T08:09:24.102Z",
    logoUrl:
      "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/LEROY%20MERLIN",
    marketplaceName: "LEROY MERLIN",
    status: TransferStatus.ALLOCATED,
    account: {
      number: "0036654",
      name: "Hero AP - LEROY MERLIN",
      __typename: "IncomingTransferAccountOutput",
    },
    __typename: "IncomingTransferOutput",
  },
  {
    swanTransactionId: "psptran-4a9f39e3-4232-47e5-90d3-4589f622b003",
    amount: 1316441,
    date: "2023-11-13T08:09:43.128Z",
    logoUrl:
      "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/AMAZON",
    marketplaceName: "AMAZON",
    status: TransferStatus.ALLOCATED,
    account: {
      number: "0037082",
      name: "Hero AP - AMAZON",
      __typename: "IncomingTransferAccountOutput",
    },
    __typename: "IncomingTransferOutput",
  },
  {
    swanTransactionId: "psptran-f65fbca2-9c34-41f4-be82-5ab1f623114e",
    amount: 685514,
    date: "2023-11-08T16:29:12.179Z",
    logoUrl:
      "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/AMAZON",
    marketplaceName: "AMAZON",
    status: TransferStatus.ALLOCATED,
    account: {
      number: "0037082",
      name: "Hero AP - AMAZON",
      __typename: "IncomingTransferAccountOutput",
    },
    __typename: "IncomingTransferOutput",
  },
  {
    swanTransactionId: "psptran-5abb5d3a-574c-40a8-80f9-758e96ef10e2",
    amount: 989937,
    date: "2023-11-06T10:34:14.214Z",
    logoUrl:
      "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/AMAZON",
    marketplaceName: "AMAZON",
    status: TransferStatus.ALLOCATED,
    account: {
      number: "0037082",
      name: "Hero AP - AMAZON",
      __typename: "IncomingTransferAccountOutput",
    },
    __typename: "IncomingTransferOutput",
  },
  {
    swanTransactionId: "psptran-b56e0f28-89b7-45a4-9e39-9c1aeef2e855",
    amount: 2645740,
    date: "2023-11-06T08:10:28.289Z",
    logoUrl:
      "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/LEROY%20MERLIN",
    marketplaceName: "LEROY MERLIN",
    status: TransferStatus.ALLOCATED,
    account: {
      number: "0036654",
      name: "Hero AP - LEROY MERLIN",
      __typename: "IncomingTransferAccountOutput",
    },
    __typename: "IncomingTransferOutput",
  },
  {
    swanTransactionId: "psptran-37080b83-90f4-4c7e-ba4d-32b839d03c6d",
    amount: 1509377,
    date: "2023-10-24T07:09:39.805Z",
    logoUrl:
      "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/LEROY%20MERLIN",
    marketplaceName: "LEROY MERLIN",
    status: TransferStatus.ALLOCATED,
    account: {
      number: "0036654",
      name: "Hero AP - LEROY MERLIN",
      __typename: "IncomingTransferAccountOutput",
    },
    __typename: "IncomingTransferOutput",
  },
  {
    swanTransactionId: "psptran-8080ebb2-b525-4ef2-a323-285a45a9259a",
    amount: 2293075,
    date: "2023-10-12T07:09:28.974Z",
    logoUrl:
      "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/LEROY%20MERLIN",
    marketplaceName: "LEROY MERLIN",
    status: TransferStatus.ALLOCATED,
    account: {
      number: "0036654",
      name: "Hero AP - LEROY MERLIN",
      __typename: "IncomingTransferAccountOutput",
    },
    __typename: "IncomingTransferOutput",
  },
]
