import { BOV2RouteProtector } from "../../../App.router"
import { Balance } from "./Balance.screen"

export const balanceRoute = {
  path: "balance",
  element: (
    <BOV2RouteProtector>
      <Balance />
    </BOV2RouteProtector>
  ),
}
