import { Pagination, Spinner, Table, TBody, THead, Typography } from "@hero/krypton"
import { DateTime } from "luxon"
import { useNavigate } from "react-router-dom"
import styled from "styled-components"
import { ScrollArea } from "../../../../00_shared/components/ScrollArea"
import { useAmplitude } from "../../../../00_shared/hooks/useAmplitude.hook"
import { useDashboardTranslation } from "../../../../01_technical/translations"
import { IssuingTransaction } from "../../00_shared/business/IssuingTransaction"
import { IssuingCardTransactionLabel } from "./IssuingCardTransactionLabel"

const TableContainer = styled.div`
  box-shadow: ${({ theme }) => theme.shadows.light};
  border-radius: 1rem;
  border: 1px solid ${({ theme }) => theme.colors.grey.$200};
  border-top: none;
  display: grid;
  grid-template-rows: 1fr auto;
  overflow-y: hidden;
`

const StaticTable = styled(Table)`
  width: 100%;
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
  overflow: unset;
  border: none;

  thead tr th {
    position: sticky;
    top: 0;
    text-align: center;
    z-index: 10;
    background-color: ${({ theme }) => theme.colors.grey.$100};
    border-top: 1px solid ${({ theme }) => theme.colors.grey.$200};
  }

  thead tr th:first-child {
    text-align: start;
    border-top-left-radius: 1rem;
  }

  thead tr th:last-child {
    border-top-right-radius: 1rem;
  }

  tbody tr {
    cursor: pointer;
  }
`

const AmountContainer = styled(Typography).attrs({ $variant: "body-4-medium" })<{ $isNegative: boolean }>`
  color: ${({ theme, $isNegative }) => ($isNegative ? theme.colors.grey.$600 : theme.colors.success.$200)};
`

const CenteringTD = styled.td`
  text-align: center;
`

const PaginationContainer = styled(Pagination)`
  padding: 1rem 0;
`

interface IssuingCardsTableProps {
  transactions: IssuingTransaction[]
  pagination: { limit: number; total: number; currentPage: number; hasNext: boolean }
  isLoading: boolean
  onPageChange: (pageNumber: number) => void
}

export const IssuingCardTransactionsTable = ({
  transactions,
  pagination,
  isLoading,
  onPageChange,
}: IssuingCardsTableProps) => {
  const { i18n, t } = useDashboardTranslation()
  const navigate = useNavigate()
  const toLocaleDate = (date: string) => DateTime.fromISO(date).setLocale(i18n.language).toLocaleString()
  const { track } = useAmplitude()
  const totalPages = Math.ceil(pagination.total / pagination.limit)

  const handleClickTransaction = (transactionId: string) => {
    track("card_transaction_clicked")
    navigate(`./transactions/${transactionId}`)
  }

  return (
    <TableContainer>
      <ScrollArea height="100%">
        <StaticTable>
          <THead>
            <tr>
              <th>{t("issuing.cards.transactions.table.label", { defaultValue: "Transaction" })}</th>
              <th>{t("issuing.cards.transactions.table.amount", { defaultValue: "Montant" })}</th>
              <th>{t("issuing.cards.transactions.table.date", { defaultValue: "Date" })}</th>
            </tr>
          </THead>
          <TBody $clickable={!!transactions.length}>
            {isLoading && (
              <tr>
                <CenteringTD colSpan={5}>
                  <Spinner />
                </CenteringTD>
              </tr>
            )}
            {!transactions.length && !isLoading && (
              <tr>
                <CenteringTD colSpan={5}>{t("issuing.cards.transactions.table.noTransaction")}</CenteringTD>
              </tr>
            )}
            {transactions.map((transaction, id) => (
              <tr key={id} onClick={() => handleClickTransaction(transaction.id)}>
                <th>
                  <IssuingCardTransactionLabel transaction={transaction} />
                </th>
                <th>
                  <AmountContainer $isNegative={transaction.operationType !== "REFUND"}>
                    {transaction.amountEuros}
                  </AmountContainer>
                </th>
                <th>{toLocaleDate(transaction.createdAt)}</th>
              </tr>
            ))}
          </TBody>
        </StaticTable>
      </ScrollArea>
      <PaginationContainer
        currentPage={pagination.currentPage}
        totalPages={totalPages}
        onPageChange={(pageNumber: number) => onPageChange(pageNumber)}
      />
    </TableContainer>
  )
}
