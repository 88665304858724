import { Box2dIcon, FileIcon, PlugOnIcon, Typography } from "@hero/krypton"
import { Link, Outlet, useLocation } from "react-router-dom"
import styled from "styled-components"
import { useDashboardTranslation } from "../../../01_technical/translations"

const Container = styled.div`
  display: flex;
  flex-direction: row;
  padding: 1.5rem 3rem;
  flex: 1;
`

const LeftSection = styled.div`
  width: 16.25rem;
`

const NavContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0.5rem;
  border-radius: 0.5rem;
  border: 1px solid ${({ theme }) => theme.colors.grey.$200};
  box-shadow: ${({ theme }) => theme.shadows.light};
  margin-right: 1.5rem;
`

const RightSection = styled.div`
  display: flex;
  flex: 1;
  max-width: 48.25rem;
`

const LinkNav = styled(Link)<{ isSelected: boolean }>`
  padding: 0.25rem;
  border-radius: 0.5rem;
  margin-bottom: 0.25rem;
  background-color: ${({ theme, isSelected }) => (isSelected ? theme.colors.grey.$100 : "transparent")};

  &:hover {
    background-color: ${({ theme }) => theme.colors.grey.$100};
  }
`

const TextNav = styled(Typography)`
  display: flex;
  align-items: center;
`

export const Settings = () => {
  const location = useLocation()
  const { t } = useDashboardTranslation()

  return (
    <Container>
      <LeftSection>
        <NavContainer>
          <LinkNav isSelected={location.pathname === "/collection/settings/link"} to="/collection/settings/link">
            <TextNav $color="grey.$600" $variant="body-4-regular">
              <Box2dIcon height="20px" />
              {t("collection.settings.link")}
            </TextNav>
          </LinkNav>
          <LinkNav
            isSelected={
              location.pathname === "/collection/settings/checkout" ||
              location.pathname === "/collection/settings/plugins"
            }
            to="/collection/settings/checkout"
          >
            <TextNav $color="grey.$600" $variant="body-4-regular">
              <PlugOnIcon height="20px" />
              {t("collection.settings.checkout")}
            </TextNav>
          </LinkNav>
          <LinkNav
            isSelected={location.pathname === "/collection/settings/accounting"}
            to="/collection/settings/accounting"
          >
            <TextNav $color="grey.$600" $variant="body-4-regular">
              <FileIcon height="20px" />
              {t("collection.settings.accounting")}
            </TextNav>
          </LinkNav>
        </NavContainer>
      </LeftSection>
      <RightSection>
        <Outlet />
      </RightSection>
    </Container>
  )
}
