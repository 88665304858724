import { gql } from "@apollo/client"
import { GqlHeroError } from "../../../../../01_technical/requesting/request-error.type"

export const SWAN_AUTH_MUTATION = gql`
  mutation merchantSwanAuth($code: String!) {
    merchantSwanAuth(code: $code) {
      ... on GqlHeroError {
        errorCode
        message
      }

      ... on SwanAuthOutput {
        success
      }
    }
  }
`

export type SWAN_AUTH_MUTATION_RESPONSE = {
  merchantSwanAuth: SWAN_AUTH_MUTATION_RESPONSE_SUCCESS | GqlHeroError
}

export type SWAN_AUTH_MUTATION_RESPONSE_SUCCESS = {
  success: boolean
}

export type SWAN_AUTH_ARGS = {
  code: string
}

export const SCHEDULE_STANDING_ORDER = gql`
  mutation scheduleStandingOrder($merchantId: String!) {
    scheduleStandingOrder(merchantId: $merchantId) {
      ... on StandingOrder {
        consentUrl
      }

      ... on StandingOrderError {
        reason
      }

      ... on GqlHeroError {
        errorCode
      }
    }
  }
`

export type SCHEDULE_STANDING_ORDER_ARGS = {
  merchantId: string
}

export type SCHEDULE_STANDING_ORDER_ERROR_RESPONSE = {
  reason: string
}

export type SCHEDULE_STANDING_ORDER_RESPONSE_SUCCESS = {
  consentUrl: string
}

export type SCHEDULE_STANDING_ORDER_RESPONSE = {
  scheduleStandingOrder:
    | SCHEDULE_STANDING_ORDER_RESPONSE_SUCCESS
    | GqlHeroError
    | SCHEDULE_STANDING_ORDER_ERROR_RESPONSE
}

export const isScheduleStandingOrderSuccess = (
  standingOrderResult: SCHEDULE_STANDING_ORDER_RESPONSE_SUCCESS | SCHEDULE_STANDING_ORDER_ERROR_RESPONSE,
): standingOrderResult is SCHEDULE_STANDING_ORDER_RESPONSE_SUCCESS => {
  return !!(standingOrderResult as SCHEDULE_STANDING_ORDER_RESPONSE_SUCCESS).consentUrl
}

export const RECEIVE_STANDING_ORDER_CONFIRMATION = gql`
  mutation receiveStandingOrderConfirmation($standingOrderId: String!) {
    receiveStandingOrderConfirmation(standingOrderId: $standingOrderId) {
      ... on StandingOrderUpdated {
        id
        status
      }

      ... on GqlHeroError {
        errorCode
        message
      }
    }
  }
`

export type RECEIVE_STANDING_ORDER_CONFIRMATION_ARGS = {
  standingOrderId: string
}

export type RECEIVE_STANDING_ORDER_CONFIRMATION_RESPONSE_SUCCESS = {
  id: string
  status: "Canceled" | "Enabled" | "ConsentPending"
}

export type RECEIVE_STANDING_ORDER_CONFIRMATION_RESPONSE = {
  receiveStandingOrderConfirmation: RECEIVE_STANDING_ORDER_CONFIRMATION_RESPONSE_SUCCESS | GqlHeroError
}
