import { HeroApiError } from "../../../../../../01_technical/requesting/request-error.type"
import { useCommonTranslation } from "../../../../../../01_technical/translations"
import { UserLocale } from "../../../../../../business/enums/User.enum"
import { getProductTypeFromFlow, LedgerMovementFlow, ProductType } from "../../../../00_shared/business"
import { useOperationDetails } from "../../../00_shared/hooks/useOperationDetails"
import { formatDetails, FormattedDetails } from "../../utils"
import { useIssuingCardTransaction } from "./useIssuingCardOperationDetails"

interface UnifiedOperationDetailsProductSpecific {
  [ProductType.ISSUING]: {
    transactionId?: string
  }
}

interface UnifiedOperationDetails {
  formattedOperationDetails?: FormattedDetails
  loading: boolean
  error?: HeroApiError | null
  refetch?: () => void
  productSpecific?: UnifiedOperationDetailsProductSpecific
}

export const useUnifiedOperationDetails = (args: {
  operationId: string
  ledgerMovementId: string
  flow: LedgerMovementFlow
}): UnifiedOperationDetails => {
  const { t, i18n } = useCommonTranslation()
  const productType = getProductTypeFromFlow(args.flow)

  // Handle BUSINESS_ACCOUNT
  const {
    operationDetails: businessDetails,
    loading: businessLoading,
    error: businessError,
    refetch: businessRefetch,
  } = useOperationDetails({
    ledgerMovementId: args.ledgerMovementId,
    flow: args.flow,
    operationId: args.operationId,
  })

  // Handle ISSUING
  const {
    transaction: issuingTransaction,
    card: issuingCard,
    loadingTransaction: issuingLoadingTransaction,
    errorTransaction: issuingErrorTransaction,
    formatDetails: formatIssuingDetails,
  } = useIssuingCardTransaction(productType, args.ledgerMovementId)

  // Decide based on product type
  if (productType === ProductType.ISSUING) {
    return {
      formattedOperationDetails: issuingTransaction && issuingCard ? formatIssuingDetails(t) : undefined,
      loading: issuingLoadingTransaction,
      error: issuingErrorTransaction,
      productSpecific: {
        [ProductType.ISSUING]: {
          transactionId: issuingTransaction?.id,
        },
      },
    }
  }

  // Default to BUSINESS_ACCOUNT
  return {
    formattedOperationDetails: businessDetails
      ? formatDetails(businessDetails, t, UserLocale[i18n.language as keyof typeof UserLocale])
      : undefined,
    loading: businessLoading,
    error: businessError,
    refetch: businessRefetch,
  }
}
