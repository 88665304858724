import { DocumentNode, OperationVariables, useQuery, WatchQueryFetchPolicy } from "@apollo/client"
import { useMemo } from "react"
import { ApiError } from "../request-error.type"
import { useAddTranslatedMessageToHeroApiError } from "../request-error.utils"
import { getSimplifiedQueryResult } from "./getSimplifiedQueryResult"
import { UseHeroQueryReturnType } from "./useHeroQuery.type"

// ! In case of edit, make sure to update the copy in the admin app as well !

/**
 * @param gqlQuerySchema The GraphQL query schema
 * @param variables The variables of the query
 * @param translations An object with error codes as keys and translations as values
 */
export function useHeroQuery<SuccessData>({
  gqlQuerySchema,
  variables,
  translations,
  skip,
  fetchPolicy,
}: {
  gqlQuerySchema: DocumentNode
  variables?: OperationVariables
  translations?: Record<string, string>
  skip?: boolean
  fetchPolicy?: WatchQueryFetchPolicy
}): UseHeroQueryReturnType<SuccessData> {
  const addTranslatedMessageToHeroQueryError = useAddTranslatedMessageToHeroApiError()
  const queryResult = useQuery<Record<string, SuccessData | ApiError>>(gqlQuerySchema, {
    variables,
    skip,
    fetchPolicy,
  })

  const simplifiedQueryResult = useMemo(
    () =>
      getSimplifiedQueryResult({
        data: queryResult.data,
        loading: queryResult.loading,
        error: queryResult.error,
        refetch: queryResult.refetch,
        fetchMore: queryResult.fetchMore,
        skip: skip ?? false,
      }),
    [queryResult.data, queryResult.loading, queryResult.error, queryResult.refetch, queryResult.fetchMore, skip],
  )

  if (simplifiedQueryResult.error) {
    return {
      ...simplifiedQueryResult,
      error: addTranslatedMessageToHeroQueryError(simplifiedQueryResult.error, translations),
    }
  }

  return simplifiedQueryResult
}
