import { Outlet } from "react-router-dom"
import styled from "styled-components"
import { AccountVerificationBanner } from "../../../00_shared/components/AccountVerificationBanner"
import { IssuingCardHeader } from "./IssuingCardHeader"

const LayoutContainer = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: 768px) {
    height: calc(100vh - 0.125rem);
  }
  @media (max-width: 768px) {
    flex: 1;
  }
  background-color: ${({ theme }) => theme.colors.white};
`

const MainContent = styled.div`
  padding: 1.5rem 3rem;

  display: grid;
  min-height: 0;
  overflow-y: auto;

  @media (max-width: 768px) {
    padding: 0;
    display: flex;
    flex-direction: row;
    flex: 1;
  }
`

export const IssuingCardsLayout = () => {
  return (
    <LayoutContainer>
      <AccountVerificationBanner />

      <IssuingCardHeader />

      <MainContent>
        <Outlet />
      </MainContent>
    </LayoutContainer>
  )
}
