import OldCalculator from "../../../assets/collection/old_calc.png"
import { EmptyScreen } from "../00_shared/components/EmptyScreen"

export const EmptyScreenAll = () => {
  return (
    <EmptyScreen
      imgSrc={OldCalculator}
      titleKey="collection.all.empty.title"
      descriptionKey="collection.all.empty.description"
    />
  )
}
