import { gql } from "@apollo/client"

export enum APHistoryMovementType {
  BALANCE_BEFORE = "BALANCE_BEFORE",
  BALANCE_AFTER = "BALANCE_AFTER",
  BANK_TRANSFER = "BANK_TRANSFER",
  BANK_TRANSFER_CANCELED = "BANK_TRANSFER_CANCELED",
  BANK_TRANSFER_RETURNED = "BANK_TRANSFER_RETURNED",
  AP_CREDIT_GRANTED = "AP_CREDIT_GRANTED",
  AP_CREDIT_CANCELED = "AP_CREDIT_CANCELED",
  AP_CREDIT_FEES = "AP_CREDIT_FEES",
  AP_CREDIT_FEES_CANCELED = "AP_CREDIT_FEES_CANCELED",
  AP_CREDIT_REPAYMENT = "AP_CREDIT_REPAYMENT",
  SWAN_MOVEMENT_ALLOCATED = "SWAN_MOVEMENT_ALLOCATED",
  SWAN_MOVEMENT_REFUND = "SWAN_MOVEMENT_REFUND",
  MANUAL_BULK_FEES_COMMISSION = "MANUAL_BULK_FEES_COMMISSION",
  MANUAL_BULK_FEES_RETROCOMMISSION = "MANUAL_BULK_FEES_RETROCOMMISSION",
  MANUAL_MOVEMENT_ARBITRARY = "MANUAL_MOVEMENT_ARBITRARY",
  ACCOUNT_TO_ACCOUNT_TRANSFER_MOVEMENT_FROM = "ACCOUNT_TO_ACCOUNT_TRANSFER_MOVEMENT_FROM",
  ACCOUNT_TO_ACCOUNT_TRANSFER_MOVEMENT_TO = "ACCOUNT_TO_ACCOUNT_TRANSFER_MOVEMENT_TO",
  MARKETPLACE_RESERVE_GRANTED = "MARKETPLACE_RESERVE_GRANTED",
  MARKETPLACE_RESERVE_REPAYMENT = "MARKETPLACE_RESERVE_REPAYMENT",
  MARKETPLACE_RESERVE_FEE = "MARKETPLACE_RESERVE_FEE",
  MARKETPLACE_RESERVE_MANUAL_REPAYMENT = "MARKETPLACE_RESERVE_MANUAL_REPAYMENT",
  MARKETPLACE_RESERVE_MANUAL_FEE = "MARKETPLACE_RESERVE_MANUAL_FEE",
}

export type HistoryMovement = {
  id?: string
  label?: string
  type: APHistoryMovementType
  amount: number
  balanceAfter?: number
  createdAt: string
  isMovement: boolean
}

export type GetAPBankTransferDetailsSuccessResponse = {
  id: string
  marketplaceName: string
  amount: number
  createdAt: string
  iban: string
  account: {
    name: string
    number: string
  }
  historyMovements: HistoryMovement[]
}

export const GET_AP_BANK_TRANSFER_DETAILS_QUERY = gql`
  query ($id: String!) {
    getAPBankTransferDetails(id: $id) {
      ... on GetAPBankTransferDetailsOutput {
        id
        marketplaceName
        amount
        createdAt
        iban
        account {
          name
          number
        }
        historyMovements {
          id
          label
          type
          amount
          balanceAfter
          createdAt
        }
      }

      ... on ValidationErrors {
        validationErrors {
          path
          validationError
        }
      }

      ... on GqlHeroError {
        errorCode
        message
      }
    }
  }
`
