import { useMutation } from "@apollo/client"
import { ArrowRightIcon, Button, Field, Typography } from "@hero/krypton"
import { useEffect } from "react"
import { useForm } from "react-hook-form"
import { Trans } from "react-i18next"
import { useSearchParams } from "react-router-dom"
import styled from "styled-components"
import { useCommonTranslation } from "../../01_technical/translations"
import { ApiErrors } from "../../Legacy/components/ApiErrors"
import { FORGOT_PASSWORD, ForgotPasswordArgs, ForgotPasswordResponse } from "./ForgotPassword.request"

const Container = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const LoginBox = styled.form`
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  width: 26rem;
  margin-top: auto;
  display: flex;
`

const NoAccount = styled.div`
  margin-top: auto;
  display: flex;
`

const ActionButton = styled.div`
  width: 21.875rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const EmailSentContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

const EmailSent = styled(Typography)`
  background-color: ${({ theme }) => theme.colors.white};
  z-index: 1;
  padding: 3rem;
  border-radius: 0.5rem;
`

type ForgotPasswordForm = {
  email: string
}

export const ForgotPassword = () => {
  useEffect(() => {
    localStorage.removeItem("token")
  }, [])

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<ForgotPasswordForm>({})

  const [queryParams] = useSearchParams()

  const [passwordReset, { data, loading, error }] = useMutation<ForgotPasswordResponse, ForgotPasswordArgs>(
    FORGOT_PASSWORD,
  )

  useEffect(() => {
    const email = queryParams.get("email")
    if (email) {
      setValue("email", email)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const { t, unsafeT, i18n } = useCommonTranslation()

  const onSubmit = handleSubmit(async (formData) => {
    await passwordReset({
      variables: {
        email: formData.email,
        locale: i18n.language.toUpperCase(),
      },
    })
  })

  const emailSend = data?.sendPasswordResetLink

  return (
    <Container>
      {!emailSend && (
        <>
          <LoginBox onSubmit={onSubmit}>
            <Typography $variant="title-2-bold" as="h1">
              {t("auth.forgotPassword.title")}
            </Typography>

            <Field
              type="email"
              fieldLabel={t("auth.forgotPassword.emailLabel")}
              placeholder={t("auth.forgotPassword.emailPlaceholder")}
              // eslint-disable-next-line i18next/no-literal-string
              {...register("email", { required: t("requiredField") })}
              errorMessage={errors.email?.message}
            />

            <ActionButton>
              <Button isLoading={loading} type="submit" $fullWidth disabled={loading} rightIcon={ArrowRightIcon}>
                {t("auth.forgotPassword.submit")}
              </Button>
            </ActionButton>
            <ApiErrors err={error} />
          </LoginBox>
          <NoAccount>
            <Typography $variant="body-4-regular">
              <Trans i18nKey="auth.forgotPassword.link.tryForFree" t={unsafeT}>
                Vous n'avez pas de compte ?
                <Typography as="a" $variant="body-4-link" href="https://www.heropay.eu/essayez-gratuitement/">
                  Créez un compte&nbsp;!
                </Typography>
              </Trans>
            </Typography>
          </NoAccount>
        </>
      )}

      {emailSend && (
        <EmailSentContainer>
          <EmailSent $variant="title-3-inter-bold">{t("auth.forgotPassword.confirmation")}</EmailSent>
        </EmailSentContainer>
      )}
    </Container>
  )
}
