import styled from "styled-components"

export const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  padding: 0.175rem;
  background-color: ${({ theme }) => theme.colors.grey.$100};
  border: 1px solid ${({ theme }) => theme.colors.grey.$200};
`
