import { ContentCopyIcon, theme, toaster, Typography } from "@hero/krypton"
import { FC, useMemo } from "react"
import CopyToClipboard from "react-copy-to-clipboard"
import styled from "styled-components"
import { useCommonTranslation } from "../../../../../01_technical/translations"

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
`

const Content = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`

const CopyButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;

  &:hover {
    color: ${({ theme }) => theme.colors.primary.$200};
  }
`

export const AccountInformation: FC<{
  label: string
  value: string | JSX.Element
  canCopy?: boolean
}> = ({ label, value, canCopy }) => {
  const { t } = useCommonTranslation()

  const content = useMemo(
    () => (
      <Container>
        <Typography $variant="label-1" style={{ color: theme.colors.primary.$200, textTransform: "uppercase" }}>
          {label}
        </Typography>
        <Content>
          {canCopy && (
            <CopyButton>
              <ContentCopyIcon />
            </CopyButton>
          )}
          <Typography as="div" $variant="body-3-regular" style={{ color: theme.colors.grey.$600 }}>
            {value}
          </Typography>
        </Content>
      </Container>
    ),
    [canCopy, value, label],
  )

  if (canCopy && typeof value === "string") {
    return (
      /** eslint-disable-next-line @typescript-eslint/ban-ts-comment */
      /** @ts-expect-error CopyToClipboardType is wrong... */
      <CopyToClipboard
        text={value}
        onCopy={(_, result) => {
          if (result) {
            toaster.success(`${label} ${t("accounts.copy.success")}`, { autoClose: 1500 })
          } else {
            toaster.success(t("accounts.copy.error"), { autoClose: 1500 })
          }
        }}
      >
        {content}
      </CopyToClipboard>
    )
  }

  return content
}
