import { gql } from "@apollo/client"
import { useHeroQuery } from "../../../../01_technical/requesting/useHeroQuery/useHeroQuery"

const GET_AVAILABLE_PAYMENT_OPTIONS = gql`
  query getAvailablePaymentOptions {
    getAvailablePaymentOptions {
      ... on GetAvailablePaymentOptionsOutput {
        numberOfInstallmentsAllowed
      }
      ... on GqlHeroError {
        errorCode
      }
      ... on ValidationErrors {
        errorCode
      }
    }
  }
`
export const useGetAvailablePaymentOptions = () => {
  return useHeroQuery<{
    numberOfInstallmentsAllowed: number[]
  }>({
    gqlQuerySchema: GET_AVAILABLE_PAYMENT_OPTIONS,
    fetchPolicy: "network-only",
  })
}
