import { useMutation } from "@apollo/client"
import { Button, Header, toaster, Typography } from "@hero/krypton"
import { useEffect } from "react"
import { useNavigate } from "react-router-dom"
import styled from "styled-components"
import { useQuerySearchParams } from "../../../../../00_shared/hooks/useQuerySearchParams.hook"
import {
  HeroErrorDisplayer,
  unwrapGraphQLResponse,
} from "../../../../../01_technical/requesting/DEPRECATED_graphql.errors"
import { HEADER_COLORS, SWAN_AUTH_URL, SWAN_CLIENT_ID, SWAN_REDIRECT_URL } from "../../../../../env_variables"
import {
  isScheduleStandingOrderSuccess,
  RECEIVE_STANDING_ORDER_CONFIRMATION,
  RECEIVE_STANDING_ORDER_CONFIRMATION_ARGS,
  RECEIVE_STANDING_ORDER_CONFIRMATION_RESPONSE,
  RECEIVE_STANDING_ORDER_CONFIRMATION_RESPONSE_SUCCESS,
  SCHEDULE_STANDING_ORDER,
  SCHEDULE_STANDING_ORDER_ARGS,
  SCHEDULE_STANDING_ORDER_ERROR_RESPONSE,
  SCHEDULE_STANDING_ORDER_RESPONSE,
  SCHEDULE_STANDING_ORDER_RESPONSE_SUCCESS,
  SWAN_AUTH_ARGS,
  SWAN_AUTH_MUTATION,
  SWAN_AUTH_MUTATION_RESPONSE,
  SWAN_AUTH_MUTATION_RESPONSE_SUCCESS,
} from "./ScheduleStandingOrder.request"

const CustomHeader = styled(Header)`
  min-height: auto;
  border-bottom: 0;
  &::before {
    margin-bottom: 0;
  }
`

const EmptyBox = styled.div`
  height: 43.3rem;
  margin: 3rem;
  background-color: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.grey.$200};
  box-shadow: ${({ theme }) => theme.shadows.mid};
  border-radius: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const LeftChild = styled.div`
  margin-left: 6.25rem;
`

const StyledTypography = styled(Typography)`
  margin-top: 0.25rem;
  width: 23.75rem;
  height: 7rem;
  color: ${({ theme }) => theme.colors.grey.$500};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

const ButtonBox = styled.div``

const startExternalAuth = () => {
  window.location.href = `${SWAN_AUTH_URL}?response_type=code&client_id=${SWAN_CLIENT_ID}&redirect_uri=${SWAN_REDIRECT_URL}&scope=openid%20offline&state=heroclient`
}

const startScheduleStandingOrder = (consentUrl: string) => {
  window.location.href = consentUrl
}

const useSwanAuthMutation = () => {
  const [swanAuth, { loading, error, data }] = useMutation<SWAN_AUTH_MUTATION_RESPONSE, SWAN_AUTH_ARGS>(
    SWAN_AUTH_MUTATION,
  )

  const { error: swanAuthFunctionalError, data: swauAuthResult } =
    unwrapGraphQLResponse<SWAN_AUTH_MUTATION_RESPONSE_SUCCESS>(data?.merchantSwanAuth)

  return {
    swanAuth,
    swanAuthLoading: loading,
    swanAuthError: error,
    swanAuthFunctionalError,
    swauAuthResult,
  }
}

const useSchedulStandingOrder = () => {
  const [scheduleStandingOrder, { loading, error, data }] = useMutation<
    SCHEDULE_STANDING_ORDER_RESPONSE,
    SCHEDULE_STANDING_ORDER_ARGS
  >(SCHEDULE_STANDING_ORDER)

  const { error: functionalError, data: standingOrderResult } = unwrapGraphQLResponse<
    SCHEDULE_STANDING_ORDER_RESPONSE_SUCCESS | SCHEDULE_STANDING_ORDER_ERROR_RESPONSE
  >(data?.scheduleStandingOrder)

  return {
    scheduleStandingOrder,
    scheduleStandingOrderLoading: loading,
    scheduleStandingOrderError: error,
    scheduleStandingOrderFunctionalError: functionalError,
    standingOrderResult,
  }
}

const useReceiveStandingOrderConfirmation = () => {
  const [receiveStandingOrderConfirmation, { loading, error, data }] = useMutation<
    RECEIVE_STANDING_ORDER_CONFIRMATION_RESPONSE,
    RECEIVE_STANDING_ORDER_CONFIRMATION_ARGS
  >(RECEIVE_STANDING_ORDER_CONFIRMATION)

  const { error: functionalError, data: standingOrderResult } =
    unwrapGraphQLResponse<RECEIVE_STANDING_ORDER_CONFIRMATION_RESPONSE_SUCCESS>(data?.receiveStandingOrderConfirmation)

  return {
    receiveStandingOrderConfirmation,
    receiveStandingOrderConfirmationLoading: loading,
    receiveStandingOrderConfirmationError: error,
    receiveStandingOrderConfirmationFunctionalError: functionalError,
    standingOrderResult,
  }
}

export const ScheduleStandingOrder = ({
  merchantId,
  consentUrl,
}: {
  merchantId: string
  consentUrl: string | null
}) => {
  const { getSearchParam } = useQuerySearchParams<{ code: string; resourceId: string }>()

  const code = getSearchParam("code")

  const standingOrderSwanId = getSearchParam("resourceId")

  const { swanAuth, swanAuthLoading, swanAuthError, swauAuthResult, swanAuthFunctionalError } = useSwanAuthMutation()

  const {
    scheduleStandingOrder,
    scheduleStandingOrderError,
    standingOrderResult,
    scheduleStandingOrderFunctionalError,
    scheduleStandingOrderLoading,
  } = useSchedulStandingOrder()

  const {
    receiveStandingOrderConfirmation,
    receiveStandingOrderConfirmationError,
    receiveStandingOrderConfirmationFunctionalError,
    receiveStandingOrderConfirmationLoading,
    standingOrderResult: receiveStandingOrderConfirmationResult,
  } = useReceiveStandingOrderConfirmation()

  useEffect(() => {
    if (code) {
      swanAuth({ variables: { code } })
    }
  }, [code, swanAuth])

  useEffect(() => {
    if (!swauAuthResult) {
      return
    }

    if (swauAuthResult.success) {
      scheduleStandingOrder({ variables: { merchantId } })
    }
  }, [swauAuthResult, scheduleStandingOrder, merchantId])

  useEffect(() => {
    if (!standingOrderResult) {
      return
    }

    if (!isScheduleStandingOrderSuccess(standingOrderResult)) {
      toaster.error(standingOrderResult.reason)
    }
  }, [standingOrderResult])

  useEffect(() => {
    if (!standingOrderSwanId) {
      return
    }

    receiveStandingOrderConfirmation({ variables: { standingOrderId: standingOrderSwanId } })
  }, [receiveStandingOrderConfirmation, standingOrderSwanId])

  const finalConsentUrl =
    standingOrderResult && isScheduleStandingOrderSuccess(standingOrderResult)
      ? standingOrderResult.consentUrl
      : consentUrl

  const navigate = useNavigate()

  return (
    <>
      <CustomHeader $colors={HEADER_COLORS} />
      <EmptyBox>
        <LeftChild>
          <Typography as="h1" $variant="title-2-bold">
            Accelerated Payouts
          </Typography>
          <StyledTypography as="h2" $variant="body-4-regular">
            Pour activer vos Accelerated Payouts, vous devez autoriser Hero à récupérer les fonds de votre compte Swan.
          </StyledTypography>
          <StyledTypography as="h2" $variant="body-4-regular">
            {receiveStandingOrderConfirmationResult
              ? "Voilà, vous avez terminé ! Vous pouvez désormais accéder à votre cockpit !"
              : finalConsentUrl
                ? "Plus qu'une étape pour activer vos Accelerated Payouts : cliquez sur le bouton ci-dessous pour finaliser la configuration !"
                : "Première étape : cliquez sur le bouton ci-dessous pour vous connecter à Swan."}
          </StyledTypography>
          <ButtonBox>
            <Button<"a">
              onClick={() =>
                receiveStandingOrderConfirmationResult
                  ? navigate(0)
                  : finalConsentUrl
                    ? startScheduleStandingOrder(finalConsentUrl)
                    : startExternalAuth()
              }
              isLoading={swanAuthLoading || scheduleStandingOrderLoading || receiveStandingOrderConfirmationLoading}
              size="medium"
              as="a"
              target="_blank"
              rel="noopener noreferrer nofollow"
            >
              {receiveStandingOrderConfirmationResult
                ? "3. Accédez a votre cockpit"
                : finalConsentUrl
                  ? "2. Autoriser Hero à récupérer les fonds"
                  : "1. Se connecter à Swan"}
            </Button>
          </ButtonBox>
          <HeroErrorDisplayer
            err={scheduleStandingOrderError}
            data={scheduleStandingOrderFunctionalError}
            messages={{ UNAUTHORIZED: "Vous n'avez pas les droits nécessaires pour accéder à cette fonctionnalité." }}
          />

          <HeroErrorDisplayer
            err={swanAuthError}
            data={swanAuthFunctionalError}
            messages={{ UNAUTHORIZED: "Vous n'avez pas les droits nécessaires pour accéder à cette fonctionnalité." }}
          />

          <HeroErrorDisplayer
            err={receiveStandingOrderConfirmationError}
            data={receiveStandingOrderConfirmationFunctionalError}
            messages={{
              UNAUTHORIZED: "Vous n'avez pas les droits nécessaires pour accéder à cette fonctionnalité.",
              NOT_FOUND: "Le standing order n'a pas été trouvé.",
              INTERNAL_SERVER_ERROR: (message) => message.error?.message ?? "Une erreur est survenue.",
            }}
          />
        </LeftChild>
      </EmptyBox>
    </>
  )
}
