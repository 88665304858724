import { toaster } from "@hero/krypton"
import { useState } from "react"
import { useDashboardTranslation } from "../../../../01_technical/translations"

export const useUploadFile = ({ onUploadSuccess }: { onUploadSuccess?: (fp: string) => void } = {}) => {
  const { t } = useDashboardTranslation()
  const [isUploading, setIsUploading] = useState<boolean>(false)
  const [uploadedFilePath, setFilePath] = useState<string | undefined>(undefined)
  const [uploadedFile, setUploadedFile] = useState<File | undefined>(undefined)

  const uploadFile = async (signedUrl: string, file: File, path: string) => {
    try {
      if (file.type !== "application/pdf" && !file.type.startsWith("image/")) {
        return toaster.error(t("fileUpload.errorBadFileType"))
      }

      setIsUploading(true)
      setFilePath(undefined)

      const uploadResult = await fetch(signedUrl, {
        method: "PUT",
        headers: { "Content-Type": file.type, "Content-Length": file.size.toString() },
        body: file,
      })

      if (!uploadResult.ok) {
        throw new Error()
      }

      setFilePath(path)
      setUploadedFile(file)
      if (onUploadSuccess) {
        onUploadSuccess(path)
      }
      return path
    } catch (err: unknown) {
      toaster.error(t("fileUpload.error.failedUpload"))
      throw err
    } finally {
      setIsUploading(false)
    }
  }

  return { isUploading, uploadedFilePath, uploadFile, uploadedFile }
}
