import { FetchResult, gql, useApolloClient } from "@apollo/client"
import { useState } from "react"
import { SigninResponse as LoginSigninResponse, Setup2faError, Signin2faFunctionalError } from "../Login/login.requests"
import { useSetup2faContext } from "./Setup2fa.context"

const CHALLENGE_STATUS = gql`
  query check2FAEnrollChallenge($challengeId: String!) {
    check2FAEnrollChallenge(challengeId: $challengeId) {
      ... on Check2FAEnrollChallengeOutput {
        status
      }
      ... on SimpleApiError {
        errorCode
      }
    }
  }
`

const getDetails = (response: Signin2faFunctionalError): { challengeId: string; phone: string } => {
  return JSON.parse(response.signinDashboard.detail)
}

const is2faSetupRequired = (response: LoginSigninResponse): response is Signin2faFunctionalError => {
  const signin2faError = response as Signin2faFunctionalError
  return (
    signin2faError.signinDashboard.errorCode === "FUNCTIONAL_ERROR" &&
    signin2faError.signinDashboard.message === "2FA_SETUP_REQUIRED"
  )
}

const is2faSetupInProgress = (response: LoginSigninResponse): response is Signin2faFunctionalError => {
  const signin2faError = response as Signin2faFunctionalError
  return (
    signin2faError.signinDashboard.errorCode === "FUNCTIONAL_ERROR" &&
    signin2faError.signinDashboard.message === "2FA_SETUP_IN_PROGRESS"
  )
}

export const use2faSetup = <Setup2faResponse>() => {
  const { closeModal, openModal, timeRemaining, setTimeRemaining, setOnAfterClose, setPhone, setChallengeId } =
    useSetup2faContext()

  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<Setup2faError | null>(null)

  const client = useApolloClient()

  const setup2fa = async (args: {
    data: LoginSigninResponse
    onCompleted?: (data: Setup2faResponse) => void
    onError?: (error: Setup2faError) => void
  }): Promise<FetchResult<Setup2faResponse>> => {
    return new Promise(async (resolve, reject) => {
      setError(null)
      setLoading(true)

      let challengeId = null

      if (is2faSetupInProgress(args.data)) {
        setLoading(false)
        args.onError && args.onError(stringToSetup2faError("2FA_SETUP_IN_PROGRESS"))
        setError(stringToSetup2faError("2FA_SETUP_IN_PROGRESS"))
        return reject("2FA_SETUP_IN_PROGRESS")
      }

      if (!is2faSetupRequired(args.data)) {
        setLoading(false)
        args.onError && args.onError(stringToSetup2faError("2FA_SETUP_NOT_REQUIRED"))
        setError(stringToSetup2faError("2FA_SETUP_NOT_REQUIRED"))
        return reject("2FA_SETUP_NOT_REQUIRED")
      }

      const details = getDetails(args.data)
      challengeId = details.challengeId
      setPhone(details.phone)
      setChallengeId(challengeId)
      openModal()

      const polingInterval: NodeJS.Timer = setInterval(
        () =>
          checkIfChallengeIsDone({
            challengeId,
            resolve,
            reject,
            polingInterval,
            onCompleted: args?.onCompleted,
            onError: args?.onError,
          }),
        THREE_SECONDS_IN_MS,
      )

      setOnAfterClose(() => {
        clearInterval(polingInterval)
        stopTimer()
        closeModal()
        args.onError && args.onError(stringToSetup2faError("2FA_SETUP_ABORTED"))
        setError(stringToSetup2faError("2FA_SETUP_ABORTED"))
        return reject("2FA_SETUP_ABORTED")
      })

      startTimer()

      setTimeout(() => {
        clearInterval(polingInterval)
        stopTimer()
        closeModal()
        args.onError && args.onError(stringToSetup2faError("2FA_SETUP_FAILED"))
        setError(stringToSetup2faError("2FA_SETUP_FAILED"))
        return reject("2FA_SETUP_FAILED")
      }, FIVE_MINUTES_IN_MS)
    })
  }

  const checkIfChallengeIsDone = async ({
    challengeId,
    resolve,
    reject,
    polingInterval,
    onCompleted,
    onError,
  }: {
    challengeId: string
    resolve: (value: FetchResult<Setup2faResponse> | PromiseLike<FetchResult<Setup2faResponse>>) => void
    reject: (reason?: unknown) => void
    polingInterval: NodeJS.Timeout
    onCompleted?: (data: Setup2faResponse) => void
    onError?: (error: Setup2faError) => void
  }) => {
    const { data } = await client.query({
      query: CHALLENGE_STATUS,
      fetchPolicy: "network-only",
      variables: {
        challengeId,
      },
    })

    if (data.check2FAEnrollChallenge?.status === "SUCCESS") {
      try {
        clearInterval(polingInterval)
        closeModal()
        setLoading(false)
        setError(null)
        stopTimer()
        onCompleted && onCompleted(data)
        return resolve(data)
      } catch (e) {
        onError && onError(stringToSetup2faError("2FA_SETUP_FAILED"))
        setError(stringToSetup2faError("2FA_SETUP_FAILED"))
        return reject("2FA_SETUP_FAILED")
      }
    }

    if (data.check2FAEnrollChallenge?.status === "FAILED") {
      clearInterval(polingInterval)
      closeModal()
      onError && onError(stringToSetup2faError("2FA_SETUP_FAILED"))
      setError(stringToSetup2faError("2FA_SETUP_FAILED"))
      return reject("2FA_SETUP_FAILED")
    }
  }

  const startTimer = () => {
    setTimeRemaining(FIVE_MINUTES_IN_MS)

    const timerInterval = setInterval(() => {
      if (timeRemaining <= 0) {
        clearInterval(timerInterval)
        return
      }

      setTimeRemaining(timeRemaining - ONE_SECOND_IN_MS)
    }, ONE_SECOND_IN_MS)
  }

  const stopTimer = () => {
    setTimeRemaining(ZERO_SECONDS_IN_MS)
  }

  return [
    setup2fa,
    {
      loading,
      error,
    },
  ] as const
}

const ZERO_SECONDS_IN_MS = 0
const ONE_SECOND_IN_MS = 1000
const THREE_SECONDS_IN_MS = ONE_SECOND_IN_MS * 3
const FIVE_MINUTES_IN_MS = ONE_SECOND_IN_MS * 60 * 5

function stringToSetup2faError(e: unknown): Setup2faError {
  if (typeof e === "string") {
    return {
      check2FAEnrollChallenge: {
        errorCode: e,
      },
    }
  }

  return {
    check2FAEnrollChallenge: {
      errorCode: "UNKNOWN_ERROR",
    },
  }
}
