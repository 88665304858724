import { useMutation } from "@apollo/client"
import {
  BoltIcon,
  FloatingBox,
  Invoices2Icon,
  Menu as KryptonMenu,
  MenuButtonEntry,
  MenuList,
  MenuListButton,
  MenuListEntry,
  MenuProfileButton,
  PlugOnIcon,
  SettingsIcon,
} from "@hero/krypton"
import { useState } from "react"
import { Link, useLocation } from "react-router-dom"
import styled from "styled-components"
import { LanguageSelectMenuEntry } from "../00_shared/components/LanguageSelector"
import { useIsFFCWTActive } from "../00_shared/hooks/useFeatureFlag.hook"
import { unwrapGraphQLResponse } from "../01_technical/requesting/DEPRECATED_graphql.errors"
import { AvailableLanguage, EnabledLanguages, useDashboardTranslation } from "../01_technical/translations"
import { useAuthContext } from "../Auth/auth.context"
import heroLogo from "../assets/hero.svg"
import { UserLocale } from "../business/enums/User.enum"
import { BoSwitcher } from "./00_shared/components/BoSwitcher"
import { APBalanceMenuEntry } from "./AcceleratedPayout/Balance/Balance.menuEntry"
import { APMRFMenuEntry } from "./AcceleratedPayout/MarketplaceReserveFinancing/MarketplaceReserveFinancing.menuEntry"
import { APPaymentsMenuEntry } from "./AcceleratedPayout/Payments/Payments.menuEntry"
import { ApCockpitMenuEntry } from "./AcceleratedPayout/cockpit/cockpit.menuEntry"
import { ClientsMenuEntry } from "./CheckoutAndCash/Clients/clients.menuEntry"
import { CockpitMenuEntry } from "./CheckoutAndCash/Cockpit/cockpit.menuEntry"
import { CwtListMenuEntry } from "./CreditWireTransfer/List/List.menuEntry"
import { AccountingMenuEntry } from "./Tools/Accounting/accounting.menuEntry"
import { SettingsMenuEntry } from "./Tools/Settings/settings.menuEntry"
import {
  UPDATE_USER_LOCALE,
  UPDATE_USER_LOCALE_ARGS,
  UPDATE_USER_LOCALE_RESPONSE,
  UPDATE_USER_LOCALE_RESPONSE_SUCCESS,
  useIsCreditWireTransferActive,
} from "./merchant.menu.requests"
import { MerchantMenuEntry } from "./merchant.menu.type"

const LogoContainer = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;

  & img {
    width: 4rem;
    height: auto;
  }
`

const MenuContainer = styled(KryptonMenu)`
  position: fixed;
  overflow-y: auto;
  top: 0;
  left: 0;
`

const MenuListButtonTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  & .text {
    margin-left: 0.25rem;
  }
`

const ScaleDownIcon = styled.div`
  transform: scale(0.9);
`

const MenuListButtonWrapper = styled.div`
  margin: 0.25rem 0;
`

const fromCodeLangToUserLocale = (code: string): UserLocale => {
  switch (code) {
    case AvailableLanguage.EN:
      return UserLocale.EN
    case AvailableLanguage.DE:
      return UserLocale.DE
    case AvailableLanguage.FR:
      return UserLocale.FR
    case AvailableLanguage.ES:
      return UserLocale.ES
    case AvailableLanguage.IT:
      return UserLocale.IT
    default:
      throw new Error(`Unknown language code ${code}`)
  }
}

const useUpdateUserLocale = () => {
  const [updateUserLocale, { error, loading, data }] = useMutation<
    UPDATE_USER_LOCALE_RESPONSE,
    UPDATE_USER_LOCALE_ARGS
  >(UPDATE_USER_LOCALE)

  const { error: updateUserLocaleFunctionalError } = unwrapGraphQLResponse<UPDATE_USER_LOCALE_RESPONSE_SUCCESS>(
    data?.updateUserLocale,
  )

  return {
    updateUserLocale,
    updateUserLocaleLoading: loading,
    updateUserLocaleFunctionalError,
    updateUserLocaleTechnicalError: error,
  }
}

export const DEPRECATED_MENU = () => {
  const { t, unsafeT, i18n } = useDashboardTranslation()
  const { currentUser } = useAuthContext()
  const location = useLocation()
  const [anchorElement, setAnchorEl] = useState<HTMLButtonElement>()
  const isOpen = Boolean(anchorElement)
  const isCWTEnabled = useIsFFCWTActive()
  const { updateUserLocale } = useUpdateUserLocale()

  const handleClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setAnchorEl(anchorElement ? undefined : e.currentTarget)
  }

  const functionalityMenuEntries: MerchantMenuEntry[] = [CockpitMenuEntry, ClientsMenuEntry]

  const extraMenuEntries: MerchantMenuEntry[] = [SettingsMenuEntry, AccountingMenuEntry]

  const creditWireTransferMenuEntries: MerchantMenuEntry[] = [CwtListMenuEntry]

  const acceleratedPayoutsMenuEntries: MerchantMenuEntry[] = [
    ApCockpitMenuEntry,
    APPaymentsMenuEntry,
    APBalanceMenuEntry,
    APMRFMenuEntry,
  ]

  const setLang = (code: string) => {
    updateUserLocale({ variables: { locale: fromCodeLangToUserLocale(code) } })
    i18n.changeLanguage(code)
  }

  const { data } = useIsCreditWireTransferActive({ merchantId: currentUser.merchantId })
  const isCWTEnabledForTheMerchant = data?.isActivated

  return (
    <MenuContainer mode="dark">
      <MenuProfileButton mode="dark" onClick={handleClick} isOpen={isOpen}>
        {currentUser.merchantTradingName}
      </MenuProfileButton>
      <FloatingBox
        anchorElement={anchorElement}
        onClose={() => setAnchorEl(undefined)}
        isOpen={isOpen}
        // eslint-disable-next-line i18next/no-literal-string
        style={{ width: "14rem" }}
      >
        {EnabledLanguages.map((code) => (
          <LanguageSelectMenuEntry
            key={code}
            mode="dark"
            countryCode={code}
            onClick={() => setLang(code)}
            selected={i18n.language === code}
          />
        ))}
        <MenuButtonEntry<typeof Link> as={Link} to="/logout" color="danger" mode="dark">
          {t("menu.logout")}
        </MenuButtonEntry>
      </FloatingBox>
      <MenuList
        mode="dark"
        listName={t("menu.list.checkoutAndCash.title")}
        icon={<PlugOnIcon />}
        isInitiallyOpen
        data-test-id="menu-checkout-cash"
      >
        {functionalityMenuEntries
          .filter(({ visible }) => (typeof visible === "function" ? visible(currentUser) : visible))
          .map((menuEntryInfo) => {
            return (
              <MenuListEntry
                mode="dark"
                key={menuEntryInfo.key}
                as={Link}
                to={menuEntryInfo.to}
                aria-current={menuEntryInfo.selected(location) ? "page" : false}
                data-test-id={menuEntryInfo["data-test-id"]}
              >
                {unsafeT(menuEntryInfo.key)}
              </MenuListEntry>
            )
          })}
      </MenuList>

      {isCWTEnabled && isCWTEnabledForTheMerchant && (
        <MenuListButtonWrapper>
          <MenuListButton
            mode="dark"
            $variant="label-1"
            key={creditWireTransferMenuEntries[0].key}
            as={Link}
            to={creditWireTransferMenuEntries[0].to}
          >
            <MenuListButtonTitle>
              <ScaleDownIcon>
                <Invoices2Icon />
              </ScaleDownIcon>
              <span className="text">{unsafeT(creditWireTransferMenuEntries[0].key)}</span>
            </MenuListButtonTitle>
          </MenuListButton>
        </MenuListButtonWrapper>
      )}
      {
        <MenuList
          mode="dark"
          listName={t("menu.list.ap.title")}
          icon={<BoltIcon />}
          isInitiallyOpen
          data-test-id="menu-ap"
        >
          {acceleratedPayoutsMenuEntries
            .filter(({ visible }) => (typeof visible === "function" ? visible(currentUser) : visible))
            .map((menuEntryInfo) => {
              return (
                <MenuListEntry
                  mode="dark"
                  key={menuEntryInfo.key}
                  as={Link}
                  to={menuEntryInfo.to}
                  aria-current={menuEntryInfo.selected(location) ? "page" : false}
                  data-test-id={menuEntryInfo["data-test-id"]}
                >
                  {unsafeT(menuEntryInfo.key)}
                </MenuListEntry>
              )
            })}
        </MenuList>
      }

      <MenuList
        listName={t("menu.list.tools.title")}
        icon={<SettingsIcon />}
        isInitiallyOpen
        data-test-id="menu-tools"
        mode="dark"
      >
        {extraMenuEntries
          .filter(({ visible }) => (typeof visible === "function" ? visible(currentUser) : visible))
          .map((menuEntryInfo) => {
            return (
              <MenuListEntry
                mode="dark"
                key={menuEntryInfo.key}
                as={Link}
                to={menuEntryInfo.to}
                aria-current={menuEntryInfo.selected(location) ? "page" : false}
                data-test-id={menuEntryInfo["data-test-id"]}
              >
                {unsafeT(menuEntryInfo.key)}
              </MenuListEntry>
            )
          })}
      </MenuList>

      <LogoContainer>
        <BoSwitcher isReduced={false} />
        <img src={heroLogo} alt="" />
      </LogoContainer>
    </MenuContainer>
  )
}
