import { ApolloError } from "@apollo/client"
import { ApiError, CommonApiErrorCodes, HeroApiError } from "../request-error.type"
import { getErrorWithCode, isApiError } from "../request-error.utils"
import { SimplifiedMutationResult } from "./useHeroMutation.type"

// ! In case of edit, make sure to update the copy in the admin app as well !

export function getSimplifiedMutationResult<SuccessData>(query: {
  data?: Record<string, ApiError | SuccessData> | null
  loading?: boolean
  error?: ApolloError
  called: boolean
}): SimplifiedMutationResult<SuccessData> {
  const baseReturn = { data: null, error: null, initialError: null, loading: false as const, called: false as const }

  if (!query.called) {
    return baseReturn
  }

  if (query.loading) {
    return {
      ...baseReturn,
      called: true,
      loading: true,
    }
  }

  if (query.error) {
    return {
      ...baseReturn,
      called: true,
      error: new HeroApiError({
        code: CommonApiErrorCodes.SERVER_OR_NETWORK_ERROR,
        message: query.error.message,
      }),
      initialError: query.error,
    }
  }

  if (query.data) {
    const dataResponse = query.data[Object.keys(query.data)[0]]

    if (isApiError(dataResponse)) {
      return {
        ...baseReturn,
        called: true,
        error: getErrorWithCode(dataResponse),
        initialError: dataResponse,
      }
    }

    return {
      ...baseReturn,
      called: true,
      data: dataResponse,
    }
  }

  return {
    ...baseReturn,
    called: true,
    error: new HeroApiError({ code: CommonApiErrorCodes.UNKNOWN_ERROR, message: "An error occured." }),
  }
}
