import { TransactionFlowType, TransactionStatus } from "../../../../business/enums/Transaction.enum"
import { Transaction } from "../cockpit.requests"

export const fakeMerchantTransactions: Transaction[] = [
  {
    id: "pay-d75e418b-7bc5-4920-9898-81bc2c124ba7",
    customerEmail: "trevor85@yahoo.com",
    createdAt: "-1",
    orderID: "INVOICE-2322",
    flowType: TransactionFlowType.RECEIVABLE,
    amount: 296815,
    status: TransactionStatus.PAID,
  },
  {
    id: "pay-f1d417bb-b4e9-4d7d-811c-8f5882712d0e",
    customerEmail: "mozell.thompson@yahoo.com",
    createdAt: "-1",
    orderID: "INVOICE-2293",
    flowType: TransactionFlowType.OFFLINE,
    amount: 429463,
    status: TransactionStatus.LATE,
  },
  {
    id: "pay-b60ef286-01f1-455e-b2a6-79739964bb20",
    customerEmail: "rahsaan_wiza@gmail.com",
    createdAt: "-22",
    orderID: "INVOICE-7293",
    flowType: TransactionFlowType.RECEIVABLE,
    amount: 169592,
    status: TransactionStatus.PAID,
  },
  {
    id: "pay-422e1089-661e-4fbc-a2f3-8d1396612552",
    customerEmail: "hailey_crist27@yahoo.com",
    createdAt: "-3",
    orderID: "INVOICE-8625",
    flowType: TransactionFlowType.RECEIVABLE,
    amount: 945190,
    status: TransactionStatus.PAID,
  },
  {
    id: "pay-1bd41374-729c-45bf-a8f8-5a5d187790b3",
    customerEmail: "hector.boehm@yahoo.com",
    createdAt: "-17",
    orderID: "INVOICE-2353",
    flowType: TransactionFlowType.ONLINE,
    amount: 289957,
    status: TransactionStatus.PAID,
  },
  {
    id: "pay-d6e3ad94-41b8-402d-b1d1-053e58236767",
    customerEmail: "verda.little28@gmail.com",
    createdAt: "-22",
    orderID: "INVOICE-9724",
    flowType: TransactionFlowType.OFFLINE,
    amount: 64363,
    status: TransactionStatus.LATE,
  },
  {
    id: "pay-6294c837-9af0-49bc-812e-5f4a312f29a0",
    customerEmail: "santa_denesik@yahoo.com",
    createdAt: "-18",
    orderID: "INVOICE-6248",
    flowType: TransactionFlowType.OFFLINE,
    amount: 228974,
    status: TransactionStatus.PAID,
  },
  {
    id: "pay-b3e32522-6dc3-4ebc-abaa-975bbee5bfbe",
    customerEmail: "abe39@yahoo.com",
    createdAt: "-12",
    orderID: "INVOICE-8442",
    flowType: TransactionFlowType.RECEIVABLE,
    amount: 203577,
    status: TransactionStatus.PAID,
  },
  {
    id: "pay-cf2a29db-3a89-4a0f-b693-e59f6298bb44",
    customerEmail: "alice_thompson27@hotmail.com",
    createdAt: "-12",
    orderID: "INVOICE-9979",
    flowType: TransactionFlowType.OFFLINE,
    amount: 987042,
    status: TransactionStatus.PAID,
  },
  {
    id: "pay-c013c063-a297-4176-8843-1f375a55d065",
    customerEmail: "london.conn11@gmail.com",
    createdAt: "-11",
    orderID: "INVOICE-1416",
    flowType: TransactionFlowType.ONLINE,
    amount: 341801,
    status: TransactionStatus.PAID,
  },
  {
    id: "pay-72e3a8ac-b8bd-4b37-9eb5-15c200f3cf23",
    customerEmail: "wilber_batz5@yahoo.com",
    createdAt: "-10",
    orderID: "INVOICE-7992",
    flowType: TransactionFlowType.OFFLINE,
    amount: 158867,
    status: TransactionStatus.PAID,
  },
  {
    id: "pay-7a81e1b2-ccf7-4f84-b829-198b009a6293",
    customerEmail: "kadin51@gmail.com",
    createdAt: "-5",
    orderID: "INVOICE-4103",
    flowType: TransactionFlowType.RECEIVABLE,
    amount: 297416,
    status: TransactionStatus.PAID,
  },
  {
    id: "pay-fc2bbeff-9e57-4cc2-8a4f-724a18758cc3",
    customerEmail: "ezra.waelchi@yahoo.com",
    createdAt: "-3",
    orderID: "INVOICE-6656",
    flowType: TransactionFlowType.RECEIVABLE,
    amount: 210614,
    status: TransactionStatus.PAID,
  },
  {
    id: "pay-d97b20dd-53e7-48d0-92d9-e989fa2b4624",
    customerEmail: "katelyn.huels@gmail.com",
    createdAt: "-14",
    orderID: "INVOICE-5090",
    flowType: TransactionFlowType.RECEIVABLE,
    amount: 83584,
    status: TransactionStatus.PAID,
  },
  {
    id: "pay-c09ab3de-60fa-42da-8b83-5c40ff95a0c3",
    customerEmail: "charles_borer77@gmail.com",
    createdAt: "-20",
    orderID: "INVOICE-2309",
    flowType: TransactionFlowType.OFFLINE,
    amount: 59469,
    status: TransactionStatus.PAID,
  },
  {
    id: "pay-658f0d61-2563-4939-80e2-b0673bbf05c4",
    customerEmail: "elvera.berge81@gmail.com",
    createdAt: "-19",
    orderID: "INVOICE-3981",
    flowType: TransactionFlowType.ONLINE,
    amount: 878497,
    status: TransactionStatus.PAID,
  },
  {
    id: "pay-bd65ca2b-fcf9-4352-9f88-c24e807710b0",
    customerEmail: "kyler.hamill@hotmail.com",
    createdAt: "-7",
    orderID: "INVOICE-2582",
    flowType: TransactionFlowType.OFFLINE,
    amount: 936979,
    status: TransactionStatus.PAID,
  },
  {
    id: "pay-6c30b206-701a-41d9-a8ae-e48e3a15c733",
    customerEmail: "angelina17@gmail.com",
    createdAt: "-24",
    orderID: "INVOICE-6243",
    flowType: TransactionFlowType.ONLINE,
    amount: 152880,
    status: TransactionStatus.PAID,
  },
  {
    id: "pay-f515bea0-d2fc-4ebc-b2b2-f75b665e8768",
    customerEmail: "reina68@hotmail.com",
    createdAt: "-29",
    orderID: "INVOICE-9039",
    flowType: TransactionFlowType.RECEIVABLE,
    amount: 136330,
    status: TransactionStatus.PAID,
  },
  {
    id: "pay-ec83e28e-58de-4a07-941d-3af4c0faa317",
    customerEmail: "johnnie_bode@gmail.com",
    createdAt: "-27",
    orderID: "INVOICE-8828",
    flowType: TransactionFlowType.RECEIVABLE,
    amount: 327838,
    status: TransactionStatus.PAID,
  },
  {
    id: "pay-291f3fb9-e9b8-489e-be69-853aff6dba46",
    customerEmail: "laura1@yahoo.com",
    createdAt: "-23",
    orderID: "INVOICE-5755",
    flowType: TransactionFlowType.RECEIVABLE,
    amount: 38337,
    status: TransactionStatus.PAID,
  },
  {
    id: "pay-1a0ed0ab-c9a8-4917-954e-0e69bcb090cb",
    customerEmail: "jimmy.morar35@yahoo.com",
    createdAt: "-21",
    orderID: "INVOICE-8699",
    flowType: TransactionFlowType.OFFLINE,
    amount: 56569,
    status: TransactionStatus.PAID,
  },
  {
    id: "pay-0a31f5b7-a5a7-4f2c-8201-5dfef7e6164d",
    customerEmail: "trudie_windler79@yahoo.com",
    createdAt: "-28",
    orderID: "INVOICE-8078",
    flowType: TransactionFlowType.OFFLINE,
    amount: 303169,
    status: TransactionStatus.PAID,
  },
  {
    id: "pay-618abb94-6f81-4087-a60d-39a7b8cce997",
    customerEmail: "laney_nolan@hotmail.com",
    createdAt: "-16",
    orderID: "INVOICE-3292",
    flowType: TransactionFlowType.OFFLINE,
    amount: 354782,
    status: TransactionStatus.PAID,
  },
  {
    id: "pay-26001862-9056-4232-9e61-9ce16e813d75",
    customerEmail: "hailie79@yahoo.com",
    createdAt: "-17",
    orderID: "INVOICE-7729",
    flowType: TransactionFlowType.ONLINE,
    amount: 975171,
    status: TransactionStatus.PAID,
  },
  {
    id: "pay-916e5aff-cdbd-49f3-9668-55692388bf4b",
    customerEmail: "carmine_kessler98@gmail.com",
    createdAt: "-20",
    orderID: "INVOICE-9979",
    flowType: TransactionFlowType.OFFLINE,
    amount: 392620,
    status: TransactionStatus.PAID,
  },
  {
    id: "pay-f7355013-326b-4e80-bb8d-aeb86fcb7f53",
    customerEmail: "ludie_ohara37@hotmail.com",
    createdAt: "-28",
    orderID: "INVOICE-3793",
    flowType: TransactionFlowType.RECEIVABLE,
    amount: 789252,
    status: TransactionStatus.PAID,
  },
  {
    id: "pay-aa3d10b2-7274-48a0-8899-c6c543275cbc",
    customerEmail: "isabelle_borer@hotmail.com",
    createdAt: "-23",
    orderID: "INVOICE-9976",
    flowType: TransactionFlowType.RECEIVABLE,
    amount: 752668,
    status: TransactionStatus.PAID,
  },
  {
    id: "pay-f1eb68ff-1178-4c3e-ad77-8bdc2404bb87",
    customerEmail: "micheal_pacocha@yahoo.com",
    createdAt: "-17",
    orderID: "INVOICE-5645",
    flowType: TransactionFlowType.ONLINE,
    amount: 468911,
    status: TransactionStatus.PAID,
  },
  {
    id: "pay-f435ea76-7218-4230-9c20-5ba87981305c",
    customerEmail: "rowena51@yahoo.com",
    createdAt: "-6",
    orderID: "INVOICE-6673",
    flowType: TransactionFlowType.ONLINE,
    amount: 729867,
    status: TransactionStatus.PAID,
  },
]
