import { theme } from "@hero/krypton"
import { FC } from "react"
import styled from "styled-components"

interface TransferIndicatorProps {
  $isActive: boolean
}

const StyledDiv = styled.div<{ $isActive: boolean }>`
  background-color: ${({ $isActive }) => ($isActive ? theme.colors.primary.$200 : theme.colors.grey.$300)};
  border-color: ${({ $isActive }) => ($isActive ? theme.colors.primary.$200 : theme.colors.grey.$300)};
  border-radius: 4px;
  border-style: solid;
  width: 3px;
  height: 24px;
`

export const TransferIndicator: FC<TransferIndicatorProps> = ({ $isActive }) => {
  return <StyledDiv $isActive={$isActive} />
}
