const frenchEurosFormat = new Intl.NumberFormat("fr-FR", {
  style: "currency",
  currency: "EUR",
})

export const centsToEuros = (cents: number) => parseFloat((cents / 100).toFixed(2))

const centsToKEuros = (cents: number) => parseFloat((cents / 100000).toFixed(1))

export const eurosToCents = (euros: number) => Math.round(euros * 100)

export const toEuros = (cents: number) => frenchEurosFormat.format(centsToEuros(cents))

export const toKEuros = (cents: number) => {
  return cents < 1000_00 ? `${centsToEuros(cents)}€` : `${centsToKEuros(cents)}K€`
}

export const currencyFormatter = (amount: number) => frenchEurosFormat.format(amount)
