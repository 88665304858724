import { useState } from "react"

export const useFileDownload = () => {
  const [downloadState, setDownloadState] = useState<"idle" | "loading" | Error>("idle")

  const triggerFileDownload = (exportURL: string) => {
    setDownloadState("loading")

    fetch(exportURL, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token") as string}`,
      },
    })
      .then(async (response) => {
        const contentType = response.headers.get("content-type")

        if (!response.ok && contentType?.includes("text/html")) {
          throw new Error(await response.text())
        }

        if (!response.ok) {
          const errorResponse = await response.json()
          throw new Error(errorResponse.errorCode)
        }
        return Promise.all([response.blob(), response.headers.get("Content-Disposition")])
      })
      .then(([blob, contentDisposition]) => {
        const csv = URL.createObjectURL(blob)
        const filename = contentDisposition?.match(/filename="(?<filename>.*)"/)?.groups?.filename

        const a = document.createElement("a")
        document.body.appendChild(a)
        a.download = filename || "unknown"
        a.href = csv
        a.click()
      })
      .then(() => {
        setDownloadState("idle")
      })
      .catch((err) => {
        setDownloadState(err)
      })
  }

  return [
    triggerFileDownload,
    {
      loading: downloadState === "loading",
      error: downloadState instanceof Error ? downloadState : undefined,
    },
  ] as const
}
