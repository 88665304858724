import { useState } from "react"

export interface UseAmountFilterConfig {
  applyImmediately: boolean
}

export interface AmountState {
  amountUnder?: number
  amountEqual?: number
  amountOver?: number
  setAmountUnder: (amount: number) => void
  setAmountEqual: (amount: number) => void
  setAmountOver: (amount: number) => void
  reset: (localStateOnly?: boolean) => void
  apply: () => void
}

export const useAmountFilter = (
  config: UseAmountFilterConfig,
  updateUrlParams: (params: Partial<AmountState>) => void,
): AmountState => {
  const [amountUnder, setAmountUnderState] = useState<number | undefined>()
  const [amountEqual, setAmountEqualState] = useState<number | undefined>()
  const [amountOver, setAmountOverState] = useState<number | undefined>()

  const setAmountUnder = (amount: number) => {
    setAmountUnderState(amount)
    setAmountEqualState(undefined)
    setAmountOverState(undefined)
    if (config.applyImmediately) {
      updateUrlParams({ amountUnder: amount })
    }
  }

  const setAmountEqual = (amount: number) => {
    setAmountEqualState(amount)
    setAmountUnderState(undefined)
    setAmountOverState(undefined)
    if (config.applyImmediately) {
      updateUrlParams({ amountEqual: amount })
    }
  }

  const setAmountOver = (amount: number) => {
    setAmountOverState(amount)
    setAmountUnderState(undefined)
    setAmountEqualState(undefined)
    if (config.applyImmediately) {
      updateUrlParams({ amountOver: amount })
    }
  }

  const reset = (localStateOnly = false) => {
    setAmountUnderState(undefined)
    setAmountEqualState(undefined)
    setAmountOverState(undefined)
    if (!localStateOnly) {
      updateUrlParams({ amountUnder: undefined, amountEqual: undefined, amountOver: undefined })
    }
  }

  const apply = () => {
    updateUrlParams({ amountUnder, amountEqual, amountOver })
  }

  return {
    amountUnder,
    amountEqual,
    amountOver,
    setAmountUnder,
    setAmountEqual,
    setAmountOver,
    reset,
    apply,
  }
}
