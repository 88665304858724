import { Navigate } from "react-router-dom"

import { BOV3RouteProtector } from "../../../App.router"
import { AccountRoot } from "../pro-account/00_shared/components/AccountRoot"
import { AccountCreate } from "../pro-account/AccountCreate/AccountCreate.screen"
import { AccountDetailsRoot } from "../pro-account/AccountDetails/AccountDetails.root"
import { AccountDetailsBankDetails } from "../pro-account/AccountDetails/AccountDetailsBankDetails.screen"
import { AccountDetailsBankStatements } from "../pro-account/AccountDetails/AccountDetailsBankStatements.screen"
import { AccountDetailsSettings } from "../pro-account/AccountDetails/AccountDetailsSettings.screen"
import { AccountTopUp } from "../pro-account/AccountTopUp/AccountTopUp.screen"
import { AccountTransactionInformations } from "../pro-account/AccountTransactions/AccountTransactionInformations.screen"
import { AccountTransactions } from "../pro-account/AccountTransactions/AccountTransactions.screen"

export const accountRoutes = {
  path: "accounts",
  children: [
    {
      element: (
        <BOV3RouteProtector>
          <AccountRoot />
        </BOV3RouteProtector>
      ),
      children: [
        {
          index: true,
          element: <Navigate to="transactions" />,
        },
        {
          path: "create",
          element: <AccountCreate />,
        },
        {
          path: "transactions",
          element: <AccountTransactions />,
          children: [
            {
              path: ":transactionId",
              element: <AccountTransactionInformations />,
            },
          ],
        },
        {
          path: ":accountNb/transactions",
          element: <AccountTransactions />,
          children: [{ path: ":transactionId", element: <AccountTransactionInformations /> }],
        },
        {
          path: ":accountNb/details",
          element: <AccountDetailsRoot />,
          children: [
            {
              path: "bank-details",
              element: <AccountDetailsBankDetails />,
            },
            {
              path: "statements",
              element: <AccountDetailsBankStatements />,
            },
            {
              path: "settings",
              element: <AccountDetailsSettings />,
            },
          ],
        },
      ],
    },
    {
      path: ":accountNb/top-up",
      element: (
        <BOV3RouteProtector>
          <AccountTopUp />
        </BOV3RouteProtector>
      ),
    },
  ],
}
