import { useMutation } from "@apollo/client"
import { Button, CloseStrokeIcon, toaster, Typography } from "@hero/krypton"
import { useEffect } from "react"
import { Controller, useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import Modal from "react-modal"
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input/mobile"
import styled from "styled-components"
import { ApiErrors } from "../../../../../Legacy/components/ApiErrors"
import { FormatError } from "../../../../../Legacy/components/errors/Format.error"
import { INVITE_USER_MERCHANT_MUTATION } from "./inviteMerchantUser.requests"

const Form = styled.form`
  display: flex;
  flex-direction: column;
  background-color: white;
  margin: 0 auto;
  color: ${({ theme }) => theme.colors.grey.$600};
`

const Description = styled(Typography)`
  color: ${({ theme }) => theme.colors.grey.$500};
`

const FieldGroup = styled.fieldset`
  display: flex;
  justify-content: space-between;
  border: none;
  margin-top: 1.5rem;

  & label:not(:last-child) {
    margin-right: 1.25rem;
  }
`

const Label = styled(Typography).attrs(() => ({ as: "label", $variant: "body-4-medium" }))`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 1.25rem;
`

const ButtonContainer = styled.div`
  align-self: flex-end;
`

const Input = styled(Typography).attrs((props) => ({ as: "input", ...props }))`
  height: 2.625rem;
  border: 1px solid ${({ theme }) => theme.colors.grey.$200};
  box-shadow: ${({ theme }) => theme.shadows.light};
  border-radius: 0.5rem;
  padding: 0.25rem;
  color: ${({ theme }) => theme.colors.grey.$600};
`

const MODAL_STYLE: Modal.Styles = {
  overlay: {
    backgroundColor: "rgba(138, 138, 138, 0.75)",
  },
  content: {
    display: "flex",
    flexDirection: "column",
    border: "none",
    borderRadius: `1rem`,
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    padding: "1rem 2rem 2rem 2rem",
    width: "50%",
    height: "auto",
    maxHeight: "95vh",
  },
}

const FieldError = styled(Typography).attrs(() => ({ as: "strong" }))`
  height: 1.75rem;
  color: ${({ theme }) => theme.colors.danger.$300};
  overflow: hidden;
`

const PhoneInputComponent = styled(PhoneInput)`
  background-color: ${({ disabled }) => disabled && "#e9eaf0"};
  display: flex;
  flex-direction: row;

  height: 2.625rem;
  border: 1px solid ${({ theme }) => theme.colors.grey.$200};
  box-shadow: ${({ theme }) => theme.shadows.light};
  border-radius: 0.5rem;
  color: ${({ theme }) => theme.colors.grey.$600};

  & input[type="tel"] {
    border: none;
    height: 100%;
    width: 100%;
    padding: 0.25rem;

    :disabled {
      background: none;
    }
  }

  & .PhoneInputCountry {
    margin-left: 0.25rem;
    padding-left: 0.4rem;
  }
`

type InviteMerchantUserFormData = {
  firstname: string
  lastname: string
  email: string
  phone: string
}

type InviteMerchantUserModalProps = {
  isOpen: boolean
  onClose: () => void
  onNewUserCreated: () => void
}

export const InviteMerchantUserModal: React.FC<InviteMerchantUserModalProps> = ({
  isOpen,
  onClose,
  onNewUserCreated,
}) => {
  const { t } = useTranslation()
  const [inviteUserMerchant, { loading, error }] = useMutation(INVITE_USER_MERCHANT_MUTATION)

  const {
    register,
    handleSubmit,
    control,
    formState: { errors, isDirty },
  } = useForm<InviteMerchantUserFormData>({})
  const onSubmit = handleSubmit((data) => {
    inviteUserMerchant({
      variables: data,
    })
      .then(() => {
        toaster.success(t("merchant.tools.setting.merchantUser.inviteModal.toasterSuccess"), { position: "top-right" })
        onNewUserCreated()
        onClose()
      })
      .catch((e) => console.error(e))
  })

  const INVITE_USER_MERCHANT_MUTATION_ERROR_MAPPING = {
    email: t("merchant.tools.setting.merchantUser.inviteModal.email.error.invalid"),
    phone: t("merchant.tools.setting.merchantUser.inviteModal.phone.error.invalidServer"),
    firstname: t("merchant.tools.setting.merchantUser.inviteModal.firstname.error.invalid"),
    lastname: t("merchant.tools.setting.merchantUser.inviteModal.lastname.error.invalid"),
  } as Record<string, string>

  return (
    /** eslint-disable-next-line @typescript-eslint/ban-ts-comment */
    /** @ts-expect-error Modal type lib is wrong... Use the Krypton one por favor */
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      style={MODAL_STYLE}
      aria={{
        labelledby: "heading",
        describedby: "description",
      }}
    >
      <ButtonContainer>
        <Button
          isLoading={false}
          title={t("merchant.tools.setting.merchantUser.inviteModal.closeModalButtonTitle")}
          $variant="round"
          centerIcon={CloseStrokeIcon}
          onClick={onClose}
        />
      </ButtonContainer>
      <Form noValidate onSubmit={onSubmit}>
        <Typography $variant="title-2-bold" as="h2" id="heading">
          {t("merchant.tools.setting.merchantUser.inviteModal.title")}
        </Typography>
        <Description id="description">{t("merchant.tools.setting.merchantUser.inviteModal.description")}</Description>
        <FieldGroup>
          <Label>
            {t("merchant.tools.setting.merchantUser.inviteModal.firstname.label")}
            <Input
              data-test-id="invite-prenom"
              type="text"
              aria-invalid={!!errors.firstname}
              {...register("firstname", {
                required: t("merchant.tools.setting.merchantUser.inviteModal.firstname.error.required"),
              })}
            />
            <FieldError>{errors.firstname?.message}</FieldError>
          </Label>

          <Label>
            {t("merchant.tools.setting.merchantUser.inviteModal.lastname.label")}
            <Input
              data-test-id="invite-nom"
              type="text"
              aria-invalid={!!errors.lastname}
              {...register("lastname", {
                required: t("merchant.tools.setting.merchantUser.inviteModal.lastname.error.required"),
              })}
            />
            <FieldError>{errors.lastname?.message}</FieldError>
          </Label>
        </FieldGroup>
        <Label>
          {t("merchant.tools.setting.merchantUser.inviteModal.email.label")}

          <Input
            data-test-id="invite-email"
            type="email"
            aria-invalid={!!errors.email}
            {...register("email", {
              required: t("merchant.tools.setting.merchantUser.inviteModal.email.error.required"),
            })}
          />
          <FieldError>{errors.email?.message}</FieldError>
        </Label>
        <Label>
          {t("merchant.tools.setting.merchantUser.inviteModal.phone.label")}
          <Controller
            name="phone"
            control={control}
            rules={{
              validate: (value) => {
                return (
                  isValidPhoneNumber(value.toString()) ||
                  t("merchant.tools.setting.merchantUser.inviteModal.phone.error.invalid")
                )
              },
              required: t("merchant.tools.setting.merchantUser.inviteModal.phone.error.required"),
            }}
            render={({ field }) => {
              return <PhoneInputComponent data-test-id="invite-phone" defaultCountry="FR" {...field} />
            }}
          />
          <FieldError>{errors.phone?.message}</FieldError>
        </Label>
        <div>
          {error && (
            <ApiErrors
              err={error}
              strategies={{
                WRONG_FORMAT: (extensions) => {
                  return (
                    <FormatError extensions={extensions} errorMapping={INVITE_USER_MERCHANT_MUTATION_ERROR_MAPPING} />
                  )
                },
                FORBIDDEN: () => <> {t("merchant.tools.setting.merchantUser.inviteModal.errors.forbidden")}</>,
                USER_ALREADY_EXISTS: () => (
                  <> {t("merchant.tools.setting.merchantUser.inviteModal.errors.userAlreadyExists")}</>
                ),
                UNABLE_TO_SEND_INVITATION_EMAIL: () => {
                  useEffect(() => {
                    onClose()
                    toaster.error(t("merchant.tools.setting.merchantUser.inviteModal.errors.mailNotSended"), {
                      position: "top-right",
                    })
                  }, [])
                  return <></>
                },
              }}
            />
          )}
        </div>
        <ButtonContainer>
          <Button
            data-test-id="invite-submit"
            $variant="primary"
            size="medium"
            type="submit"
            isLoading={loading}
            disabled={!isDirty || loading}
          >
            {t("merchant.tools.setting.merchantUser.inviteModal.submit")}
          </Button>
        </ButtonContainer>
      </Form>
    </Modal>
  )
}
