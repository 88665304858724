import { toaster, Typography } from "@hero/krypton"
import { useEffect, useMemo, useState } from "react"
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom"
import styled from "styled-components"
import { SheetTitle } from "../../../00_shared/components/Sheet"
import { FreezeCardModal } from "../00_shared/components/FreezeCardModal"
import { IssuingCardBadge } from "../00_shared/components/IssuingCardBadge"
import { IssuingCardUsage } from "../00_shared/components/IssuingCardUsage"
import { IssuingCardWithActions } from "../00_shared/components/IssuingCardWithActions"
import { TerminateCardModal } from "../00_shared/components/TerminateCardModal"
import { IssuingCardTransactionsTable } from "./components/IssuingCardTransactionsTable"
import { useIssuingCardDetailsHook } from "./IssuingCardDetails.hook"

const Card = styled.div`
  min-height: 0;
  border-radius: 16px;
  border: 1px solid ${({ theme }) => theme.colors.grey.$200};
  display: grid;
  grid-template-rows: min-content minmax(5rem, auto);
  overflow-y: auto;
`

const CardHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  min-width: 0;
  border-radius: 16px 16px 0 0;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #f8f8f8 100%);
  padding: 2rem;
  border-bottom: 1px solid #e9eaf0;
`

const CardContent = styled.div`
  padding: 2rem;
  overflow-y: auto;
`

const Container = styled.div`
  display: grid;
  min-height: 0;
  min-width: 0;
  grid-template-columns: 25rem minmax(0, 1fr);
  gap: 1.5rem;
`

const InformationsTitle = styled(SheetTitle)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: calc(100% - 3rem);

  *:nth-child(1) {
    align-self: center;
  }

  *:nth-child(1) {
    :center;
  }
`

export const IssuingCardDetails = () => {
  const navigate = useNavigate()
  const { cardId } = useParams()
  const location = useLocation()
  const searchParams = useMemo(() => new URLSearchParams(location.search), [location.search])
  const { card, transactions, pagination, loading, error } = useIssuingCardDetailsHook(cardId)
  const [isFreezeCardModalOpen, setIsFreezeCardModalOpen] = useState(false)
  const [isTerminateCardModalOpen, setIsTerminateCardModalOpen] = useState(false)

  useEffect(() => {
    if (error) {
      toaster.error("Error fetching card details")
    }
  }, [error])

  if (!loading && !card) {
    navigate("../")
    return <></>
  }

  const onPageChange = (pageNumber: number) => {
    searchParams.set("page", pageNumber.toString())
    navigate({ search: searchParams.toString() })
  }

  return (
    <>
      {card && (
        <>
          <TerminateCardModal
            cardId={card.id}
            isModalOpen={isTerminateCardModalOpen}
            onClose={() => setIsTerminateCardModalOpen(false)}
          />
          <FreezeCardModal
            cardId={card.id}
            isModalOpen={isFreezeCardModalOpen}
            onClose={() => setIsFreezeCardModalOpen(false)}
          />
          <Container>
            <Card>
              <CardHeader>
                <InformationsTitle>
                  <Typography as="span" $variant="body-3-medium">
                    {card.cardholderName}
                  </Typography>
                  <span style={{ margin: "0 auto" }}>
                    <IssuingCardBadge card={card} />
                  </span>
                </InformationsTitle>
                <IssuingCardWithActions card={card} />
              </CardHeader>
              <CardContent>
                <IssuingCardUsage card={card} />
              </CardContent>
            </Card>
            <IssuingCardTransactionsTable
              transactions={transactions}
              pagination={pagination}
              isLoading={loading}
              onPageChange={onPageChange}
            />
            <Outlet />
          </Container>
        </>
      )}
    </>
  )
}
