import { Button, Checkbox, ErrorBlock, Field, FieldList, Typography } from "@hero/krypton"
import { ElementType } from "react"
import { Controller, useForm } from "react-hook-form"
import { Trans } from "react-i18next"
import { isValidPhoneNumber } from "react-phone-number-input/mobile"
import { Link, useNavigate, useSearchParams } from "react-router-dom"
import styled from "styled-components"
import { useCommonTranslation } from "../../01_technical/translations"
import { useAuthLayoutContext } from "../auth-layout.context"
import { useSignupMutation } from "./Signup.request"

const FormTitleContainer = styled.div`
  margin-bottom: 2rem;
`

const SignupBox = styled.form<{ isRegistered: boolean }>`
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  width: 26rem;
  margin-top: auto;
  display: ${({ isRegistered }) => (isRegistered ? "none" : "flex")};
  @media (max-width: 768px) {
    max-width: 22rem;
  }
`

const ButtonSubmit = styled<ElementType>(Button)`
  width: 100%;
`

const StyledLink = styled.a`
  text-decoration: underline;
  cursor: pointer;

  &:hover {
    text-decoration: none;
  }
`

const AlreadyConnected = styled.div<{ isRegistered: boolean }>`
  margin-top: auto;
  justify-content: space-between;
  min-width: 13.5rem;
  display: ${({ isRegistered }) => (isRegistered ? "none" : "flex")};
`

type SignupFormData = {
  email: string
  password: string
  phone: string
  merchantTradingName: string
  cgu: boolean
}

export const SignupScreen = () => {
  const navigate = useNavigate()
  const [signup, { loading, error: signupError }] = useSignupMutation()

  const [queryParams] = useSearchParams()
  const { triggerAnimation, animationStatus } = useAuthLayoutContext()

  const { t, unsafeT } = useCommonTranslation()

  const {
    register,
    handleSubmit,
    control,
    formState: { errors, isDirty },
  } = useForm<SignupFormData>({
    defaultValues: {
      email: queryParams.get("email") ?? undefined,
      phone: queryParams.get("phone") ?? undefined,
      merchantTradingName: queryParams.get("company") ?? undefined,
    },
  })

  const onSubmit = handleSubmit(async (formData) => {
    try {
      const result = await signup({
        variables: {
          email: formData.email,
          password: formData.password,
          phone: formData.phone,
          merchantTradingName: formData.merchantTradingName,
        },
      })
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      localStorage.setItem("token", result.token)
      await triggerAnimation()
      navigate("/", { replace: true })
    } catch (error: unknown) {
      console.error(error)
    }
  })

  return (
    <>
      <SignupBox onSubmit={onSubmit} noValidate isRegistered={animationStatus !== "not_started"}>
        <FormTitleContainer>
          <Typography $variant="title-2-bold" as="h2">
            {t("auth.signup.title")}
          </Typography>
        </FormTitleContainer>

        <Field
          data-test-id="merchant-id-field"
          type="email"
          fieldLabel={t("auth.signup.emailLabel")}
          placeholder={t("auth.signup.emailPlaceholder")}
          // eslint-disable-next-line i18next/no-literal-string
          {...register("email", { required: t("requiredField") })}
          errorMessage={errors.email?.message}
        />

        <Field
          type="text"
          fieldLabel={t("auth.signup.companyLabel")}
          placeholder={t("auth.signup.companyPlaceholder")}
          aria-invalid={!!errors.merchantTradingName}
          // eslint-disable-next-line i18next/no-literal-string
          {...register("merchantTradingName", { required: t("requiredField") })}
          errorMessage={errors.merchantTradingName?.message}
        />

        <Field
          fieldLabel={t("auth.signup.passwordLabel")}
          type="password"
          placeholder={t("auth.signup.passwordPlaceholder")}
          aria-invalid={!!errors.password}
          // eslint-disable-next-line i18next/no-literal-string
          {...register("password", { required: t("requiredField") })}
          errorMessage={errors.password?.message}
        />

        <Controller
          name="phone"
          control={control}
          rules={{
            required: t("requiredField"),
            validate: (value: string) => {
              return isValidPhoneNumber(value.toString()) || t("auth.signup.phoneError")
            },
          }}
          render={({ field }) => (
            <Field
              {...field}
              fieldLabel={t("auth.signup.phoneLabel")}
              type="tel"
              aria-invalid={!isDirty || !!errors.phone}
              defaultCountry="FR"
              errorMessage={errors.phone?.message}
            />
          )}
        />

        <FieldList fieldLabel="" aria-invalid={!!errors.cgu} errorMessage={errors.cgu?.message}>
          <Checkbox
            id="CGU_CHECKBOX"
            text={
              <div>
                <Trans i18nKey="auth.signup.cgu" t={unsafeT}>
                  J'accepte les{" "}
                  <StyledLink href="https://www.heropay.eu/conditions-generales-dutilisation" target="__blank">
                    Conditions Générales de Hero
                  </StyledLink>{" "}
                  et l'accord de protection des données.
                </Trans>
              </div>
            }
            // eslint-disable-next-line i18next/no-literal-string
            {...register("cgu", {
              required: t("auth.signup.cguRequired"),
            })}
          />
        </FieldList>

        {signupError && <ErrorBlock>{signupError.translatedMessage}</ErrorBlock>}

        <ButtonSubmit
          data-test-id="signup-create-account-button"
          type="submit"
          size="medium"
          disabled={!isDirty || loading}
          isLoading={loading}
        >
          {loading ? "..." : t("auth.signup.submit")}
        </ButtonSubmit>
      </SignupBox>
      <AlreadyConnected isRegistered={animationStatus !== "not_started"}>
        <Typography $variant="body-4-regular">{t("auth.signup.alreadyConnected.title")}</Typography>
        <Typography $variant="body-4-link">
          <Link to="/login">{t("auth.signup.alreadyConnected.link")}</Link>
        </Typography>
      </AlreadyConnected>
    </>
  )
}
