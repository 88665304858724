import { gql } from "@apollo/client"
import { useMemo } from "react"
import { useHeroQuery } from "../../../../../../01_technical/requesting/useHeroQuery/useHeroQuery"
import { useCommonTranslation } from "../../../../../../01_technical/translations"

const GET_MERCHANT_BUSINESS_ACCOUNTS_QUERY = gql`
  query GetMerchantBusinessAccounts {
    getMerchantBusinessAccounts {
      ... on GetMerchantBusinessAccountsOutput {
        businessAccounts {
          name
          balance
          ledgerId
          virtualIbanId
          virtualIban
          isMainAccount
          createdAt
        }
      }

      ... on SimpleApiError {
        errorCode
      }
    }
  }
`

type BusinessAccount = {
  name: string
  balance: number
  ledgerId: string
  virtualIbanId: string
  virtualIban?: string
  BIC: string
  isMainAccount: boolean
  createdAt: string
}

export const useBusinessAccounts = () => {
  const { data, loading, error, refetch } = useHeroQuery<{ businessAccounts: BusinessAccount[] }>({
    gqlQuerySchema: GET_MERCHANT_BUSINESS_ACCOUNTS_QUERY,
    fetchPolicy: "cache-and-network",
  })
  const { t } = useCommonTranslation()

  const updatedBusinessAccounts = useMemo(() => {
    if (data && data.businessAccounts.length) {
      const accounts = data.businessAccounts.map((account) => ({
        ...account,
        name: account.isMainAccount ? t("onboarding.businessAccount.name") : account.name,
      }))
      const mainAccount = accounts.find((account) => account.isMainAccount)
      const otherAccounts = accounts
        .filter((account) => !account.isMainAccount)
        .sort((a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime())

      return mainAccount ? [mainAccount, ...otherAccounts] : otherAccounts
    }
    return []
  }, [data, t])

  const totalBalance = useMemo(
    () =>
      updatedBusinessAccounts.length ? updatedBusinessAccounts.reduce((acc, account) => acc + account.balance, 0) : 0,
    [updatedBusinessAccounts],
  )

  return {
    businessAccounts: updatedBusinessAccounts,
    totalBalance,
    loading,
    error,
    refetch,
  }
}
