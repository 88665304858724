import { Badge, Button, Header, Typography } from "@hero/krypton"
import styled from "styled-components"
import { useDashboardTranslation } from "../../../01_technical/translations"
import mrfGetAccessSideImage from "../../../assets/accelerated_payouts/mrfGetAccessSideImage.png"
import { HEADER_COLORS } from "../../../env_variables"

const CustomHeader = styled(Header)`
  min-height: auto;
  border-bottom: 0;
  &::before {
    margin-bottom: 0;
  }
`

const BadgeBox = styled.div`
  margin-bottom: 0.875rem;
`

const EmptyBox = styled.div`
  height: 43.3rem;
  margin: 3rem;
  background-color: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.grey.$200};
  box-shadow: ${({ theme }) => theme.shadows.mid};
  border-radius: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const LeftChild = styled.div`
  margin-left: 6.25rem;
`

const StyledTypography = styled(Typography)`
  margin-top: 0.25rem;
  margin-bottom: 2.25rem;
  width: 23.75rem;
  height: 7rem;
  color: ${({ theme }) => theme.colors.grey.$500};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

const Sentence = styled.span``

const ButtonBox = styled.div``

const RightChild = styled.div`
  margin-right: 8.25rem;
`

const Image = styled.img``

export const NonActiveMRFScreen = () => {
  const { t } = useDashboardTranslation()
  return (
    <>
      <CustomHeader $colors={HEADER_COLORS}></CustomHeader>
      <EmptyBox>
        <LeftChild>
          <BadgeBox>
            <Badge $variant="marketing">{t("ap.getAccess.badge")}</Badge>
          </BadgeBox>
          <Typography as="h1" $variant="title-2-bold">
            {t("ap.mrf.getAccess.title")}
          </Typography>
          <StyledTypography as="h2" $variant="body-4-regular">
            <Sentence>{t("ap.mrf.getAccess.content.sentence1")}</Sentence>
            <Sentence>{t("ap.mrf.getAccess.content.sentence2")}</Sentence>
          </StyledTypography>
          <ButtonBox>
            <Button<"a">
              isLoading={false}
              size="medium"
              as="a"
              href="https://cal.mixmax.com/pierremarion/reserve"
              target="_blank"
              rel="noopener noreferrer nofollow"
            >
              {t("ap.mrf.getAccess.cta")}
            </Button>
          </ButtonBox>
        </LeftChild>
        <RightChild>
          <Image src={mrfGetAccessSideImage} alt={t("ap.mrf.getAccess.title")} />
        </RightChild>
      </EmptyBox>
    </>
  )
}
