import { APStatus } from "../../../../business/enums/AP.enum"
import { ListMerchantAPsSuccessResponse } from "../Payments.requests"

export const paymentsDemoData: ListMerchantAPsSuccessResponse = {
  acceleratedPayouts: [
    {
      id: "ap-dcd73b86-2e12-4702-b0d7-9de2387e8586",
      status: APStatus.IN_PROGRESS,
      createdAt: "2024-02-21T13:47:28.575Z",
      fundedAmount: 143867,
      marketplace: "LEROY MERLIN",
      marketplaceLogoUrl:
        "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/LEROY%20MERLIN",
    },
    {
      id: "ap-8c027340-a684-4401-b53f-1d4ce8407982",
      status: APStatus.IN_PROGRESS,
      createdAt: "2024-02-15T15:39:50.569Z",
      fundedAmount: 841725,
      marketplace: "LEROY MERLIN",
      marketplaceLogoUrl:
        "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/LEROY%20MERLIN",
    },
    {
      id: "ap-dd9f37df-e957-4049-aef5-d2094f514124",
      status: APStatus.IN_PROGRESS,
      createdAt: "2024-02-15T09:45:23.159Z",
      fundedAmount: 299254,
      marketplace: "AMAZON",
      marketplaceLogoUrl:
        "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/AMAZON",
    },
    {
      id: "ap-552c9913-d87d-4ae6-8f30-79e6d4586090",
      status: APStatus.IN_PROGRESS,
      createdAt: "2024-02-14T10:12:42.480Z",
      fundedAmount: 319687,
      marketplace: "AMAZON",
      marketplaceLogoUrl:
        "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/AMAZON",
    },
    {
      id: "ap-48ab5889-a107-4c1b-9a6f-aada83d82f39",
      status: APStatus.IN_PROGRESS,
      createdAt: "2024-02-13T09:40:13.549Z",
      fundedAmount: 743486,
      marketplace: "AMAZON",
      marketplaceLogoUrl:
        "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/AMAZON",
    },
    {
      id: "ap-8d5713c6-3861-42e1-a471-097993def800",
      status: APStatus.IN_PROGRESS,
      createdAt: "2024-02-13T09:40:13.549Z",
      fundedAmount: 69129,
      marketplace: "AMAZON",
      marketplaceLogoUrl:
        "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/AMAZON",
    },
    {
      id: "ap-4e9777d6-8aac-4e4c-b314-9e9bd4284473",
      status: APStatus.IN_PROGRESS,
      createdAt: "2024-02-12T11:46:09.555Z",
      fundedAmount: 17580,
      marketplace: "AMAZON",
      marketplaceLogoUrl:
        "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/AMAZON",
    },
    {
      id: "ap-9616200c-b651-4311-aea2-ff8de53fa11a",
      status: APStatus.IN_PROGRESS,
      createdAt: "2024-02-12T11:46:09.555Z",
      fundedAmount: 35898,
      marketplace: "AMAZON",
      marketplaceLogoUrl:
        "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/AMAZON",
    },
    {
      id: "ap-95cc6769-26dd-4f52-acfa-592fef7e96c6",
      status: APStatus.IN_PROGRESS,
      createdAt: "2024-02-12T11:46:09.555Z",
      fundedAmount: 277214,
      marketplace: "AMAZON",
      marketplaceLogoUrl:
        "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/AMAZON",
    },
    {
      id: "ap-bdfcf747-49c1-498a-94b2-d0c5905e4d18",
      status: APStatus.IN_PROGRESS,
      createdAt: "2024-02-09T10:24:10.031Z",
      fundedAmount: 259405,
      marketplace: "AMAZON",
      marketplaceLogoUrl:
        "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/AMAZON",
    },
    {
      id: "ap-5ed5d083-121f-4bee-abb7-5413799148b9",
      status: APStatus.FINISHED,
      createdAt: "2024-02-08T10:32:55.415Z",
      fundedAmount: 258452,
      marketplace: "AMAZON",
      marketplaceLogoUrl:
        "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/AMAZON",
    },
    {
      id: "ap-22c81772-7383-41f5-8671-89438d7e43f2",
      status: APStatus.IN_PROGRESS,
      createdAt: "2024-02-08T00:00:00.000Z",
      fundedAmount: 7148,
      marketplace: "LEROY MERLIN",
      marketplaceLogoUrl:
        "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/LEROY%20MERLIN",
    },
    {
      id: "ap-5a7665c1-ebf6-4067-95a8-5034eb527ab1",
      status: APStatus.FINISHED,
      createdAt: "2024-02-07T10:55:06.584Z",
      fundedAmount: 276083,
      marketplace: "AMAZON",
      marketplaceLogoUrl:
        "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/AMAZON",
    },
    {
      id: "ap-c3a2850d-e7b0-42ac-b640-e6276b22b68a",
      status: APStatus.IN_PROGRESS,
      createdAt: "2024-02-07T00:00:00.000Z",
      fundedAmount: 154442,
      marketplace: "LEROY MERLIN",
      marketplaceLogoUrl:
        "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/LEROY%20MERLIN",
    },
    {
      id: "ap-857e5038-1118-4a50-9fef-7b6e57ea81f4",
      status: APStatus.FINISHED,
      createdAt: "2024-02-06T10:20:13.898Z",
      fundedAmount: 373662,
      marketplace: "AMAZON",
      marketplaceLogoUrl:
        "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/AMAZON",
    },
    {
      id: "ap-dead21b8-230a-4bb6-9b99-c16b50f11baa",
      status: APStatus.IN_PROGRESS,
      createdAt: "2024-02-06T00:00:00.000Z",
      fundedAmount: 340883,
      marketplace: "LEROY MERLIN",
      marketplaceLogoUrl:
        "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/LEROY%20MERLIN",
    },
    {
      id: "ap-a12801d5-33e8-4794-9e1f-508395a3b3cc",
      status: APStatus.FINISHED,
      createdAt: "2024-02-05T12:55:56.675Z",
      fundedAmount: 256390,
      marketplace: "AMAZON",
      marketplaceLogoUrl:
        "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/AMAZON",
    },
    {
      id: "ap-8b8bf396-0316-47f2-8caa-d08aa7dada32",
      status: APStatus.FINISHED,
      createdAt: "2024-02-05T12:55:56.675Z",
      fundedAmount: 346234,
      marketplace: "AMAZON",
      marketplaceLogoUrl:
        "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/AMAZON",
    },
    {
      id: "ap-f4c775e3-da2a-40c0-b4f5-1794a499fdb0",
      status: APStatus.FINISHED,
      createdAt: "2024-02-05T12:55:56.675Z",
      fundedAmount: 286286,
      marketplace: "AMAZON",
      marketplaceLogoUrl:
        "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/AMAZON",
    },
    {
      id: "ap-18ce9eba-7f9e-4560-8eb0-e262f9b2ca4b",
      status: APStatus.IN_PROGRESS,
      createdAt: "2024-02-05T00:00:00.000Z",
      fundedAmount: 233660,
      marketplace: "LEROY MERLIN",
      marketplaceLogoUrl:
        "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/LEROY%20MERLIN",
    },
    {
      id: "ap-79c04f2f-4b89-4fa7-9d2b-c6ad375b923b",
      status: APStatus.FINISHED,
      createdAt: "2024-02-02T11:00:40.676Z",
      fundedAmount: 315571,
      marketplace: "AMAZON",
      marketplaceLogoUrl:
        "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/AMAZON",
    },
    {
      id: "ap-8c9e662f-7c14-42a8-b962-363df9a4ff52",
      status: APStatus.IN_PROGRESS,
      createdAt: "2024-02-02T00:00:00.000Z",
      fundedAmount: 183042,
      marketplace: "LEROY MERLIN",
      marketplaceLogoUrl:
        "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/LEROY%20MERLIN",
    },
    {
      id: "ap-9891fa58-3c92-4744-aec3-85860078cdf5",
      status: APStatus.FINISHED,
      createdAt: "2024-02-01T10:38:30.040Z",
      fundedAmount: 335059,
      marketplace: "AMAZON",
      marketplaceLogoUrl:
        "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/AMAZON",
    },
    {
      id: "ap-c77e1722-5adf-42fc-b321-4d3d908c064a",
      status: APStatus.IN_PROGRESS,
      createdAt: "2024-02-01T00:00:00.000Z",
      fundedAmount: 227530,
      marketplace: "LEROY MERLIN",
      marketplaceLogoUrl:
        "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/LEROY%20MERLIN",
    },
    {
      id: "ap-c1b0742a-73d5-458d-ae0f-83e405e82b30",
      status: APStatus.FINISHED,
      createdAt: "2024-01-31T11:09:46.388Z",
      fundedAmount: 318095,
      marketplace: "AMAZON",
      marketplaceLogoUrl:
        "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/AMAZON",
    },
    {
      id: "ap-82bfbd78-dd6d-4cb2-a501-8f6eb878173c",
      status: APStatus.IN_PROGRESS,
      createdAt: "2024-01-31T00:00:00.000Z",
      fundedAmount: 154694,
      marketplace: "LEROY MERLIN",
      marketplaceLogoUrl:
        "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/LEROY%20MERLIN",
    },
    {
      id: "ap-58ee0def-aa4b-434c-bf5c-22e6ecfafed5",
      status: APStatus.FINISHED,
      createdAt: "2024-01-30T11:03:46.731Z",
      fundedAmount: 692677,
      marketplace: "AMAZON",
      marketplaceLogoUrl:
        "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/AMAZON",
    },
    {
      id: "ap-07c8c19e-62a7-43a1-90d9-52a6830854b4",
      status: APStatus.FINISHED,
      createdAt: "2024-01-30T11:03:46.731Z",
      fundedAmount: 83488,
      marketplace: "AMAZON",
      marketplaceLogoUrl:
        "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/AMAZON",
    },
    {
      id: "ap-0c2c85d2-7f4f-4346-b1c4-c0271d754d47",
      status: APStatus.IN_PROGRESS,
      createdAt: "2024-01-30T00:00:00.000Z",
      fundedAmount: 325878,
      marketplace: "LEROY MERLIN",
      marketplaceLogoUrl:
        "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/LEROY%20MERLIN",
    },
    {
      id: "ap-c360c1bb-99fb-480d-ac84-8f20be7b5a59",
      status: APStatus.FINISHED,
      createdAt: "2024-01-29T11:11:33.819Z",
      fundedAmount: 8095,
      marketplace: "AMAZON",
      marketplaceLogoUrl:
        "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/AMAZON",
    },
    {
      id: "ap-a8a855e7-84e4-4369-a8f4-0dd39c664302",
      status: APStatus.FINISHED,
      createdAt: "2024-01-29T11:11:33.819Z",
      fundedAmount: 17507,
      marketplace: "AMAZON",
      marketplaceLogoUrl:
        "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/AMAZON",
    },
    {
      id: "ap-dbd89a35-6891-4464-8e68-4fb8b6fefb79",
      status: APStatus.FINISHED,
      createdAt: "2024-01-29T11:11:33.819Z",
      fundedAmount: 269294,
      marketplace: "AMAZON",
      marketplaceLogoUrl:
        "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/AMAZON",
    },
    {
      id: "ap-a09aa7f4-5fbf-431a-9db8-10909fbdc5cd",
      status: APStatus.IN_PROGRESS,
      createdAt: "2024-01-29T00:00:00.000Z",
      fundedAmount: 119351,
      marketplace: "LEROY MERLIN",
      marketplaceLogoUrl:
        "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/LEROY%20MERLIN",
    },
    {
      id: "ap-385b1093-d384-4df5-914c-98177cfd8a19",
      status: APStatus.FINISHED,
      createdAt: "2024-01-26T10:14:31.987Z",
      fundedAmount: 327769,
      marketplace: "AMAZON",
      marketplaceLogoUrl:
        "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/AMAZON",
    },
    {
      id: "ap-0cdd19da-9ae3-46ad-b696-dc863226a307",
      status: APStatus.IN_PROGRESS,
      createdAt: "2024-01-26T00:00:00.000Z",
      fundedAmount: 227499,
      marketplace: "LEROY MERLIN",
      marketplaceLogoUrl:
        "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/LEROY%20MERLIN",
    },
    {
      id: "ap-ad173e34-491b-47ba-8f8e-d7e9283bf1b8",
      status: APStatus.FINISHED,
      createdAt: "2024-01-25T10:59:02.280Z",
      fundedAmount: 294298,
      marketplace: "AMAZON",
      marketplaceLogoUrl:
        "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/AMAZON",
    },
    {
      id: "ap-b5e777f3-a0d4-4978-8224-e2adf622a159",
      status: APStatus.IN_PROGRESS,
      createdAt: "2024-01-25T00:00:00.000Z",
      fundedAmount: 164718,
      marketplace: "LEROY MERLIN",
      marketplaceLogoUrl:
        "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/LEROY%20MERLIN",
    },
    {
      id: "ap-8842db90-372e-49e2-a06c-985f11bb8092",
      status: APStatus.FINISHED,
      createdAt: "2024-01-24T10:40:22.233Z",
      fundedAmount: 302201,
      marketplace: "AMAZON",
      marketplaceLogoUrl:
        "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/AMAZON",
    },
    {
      id: "ap-1fda61fb-e386-4ff0-aa69-4c293f79e906",
      status: APStatus.IN_PROGRESS,
      createdAt: "2024-01-24T00:00:00.000Z",
      fundedAmount: 249351,
      marketplace: "LEROY MERLIN",
      marketplaceLogoUrl:
        "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/LEROY%20MERLIN",
    },
    {
      id: "ap-eab323bb-ab3f-48f4-b65e-1eeb771e6ce9",
      status: APStatus.FINISHED,
      createdAt: "2024-01-23T10:28:55.779Z",
      fundedAmount: 692013,
      marketplace: "AMAZON",
      marketplaceLogoUrl:
        "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/AMAZON",
    },
    {
      id: "ap-a4590f0f-9ed6-42fb-9a23-35ba0ab4af8c",
      status: APStatus.IN_PROGRESS,
      createdAt: "2024-01-23T00:00:00.000Z",
      fundedAmount: 170969,
      marketplace: "LEROY MERLIN",
      marketplaceLogoUrl:
        "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/LEROY%20MERLIN",
    },
    {
      id: "ap-4110cc0b-50db-42e0-b336-a49cb6816e1c",
      status: APStatus.FINISHED,
      createdAt: "2024-01-22T10:35:30.438Z",
      fundedAmount: 19264,
      marketplace: "AMAZON",
      marketplaceLogoUrl:
        "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/AMAZON",
    },
    {
      id: "ap-8b77eef6-2d6a-4528-aa12-6d52f0a35e84",
      status: APStatus.FINISHED,
      createdAt: "2024-01-22T10:35:30.438Z",
      fundedAmount: 35662,
      marketplace: "AMAZON",
      marketplaceLogoUrl:
        "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/AMAZON",
    },
    {
      id: "ap-be4119ba-4e2e-41cb-89f8-edae8dfbf908",
      status: APStatus.FINISHED,
      createdAt: "2024-01-22T10:35:30.438Z",
      fundedAmount: 228642,
      marketplace: "AMAZON",
      marketplaceLogoUrl:
        "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/AMAZON",
    },
    {
      id: "ap-19ae76e3-86bd-4480-a0bc-d3502749e2d4",
      status: APStatus.IN_PROGRESS,
      createdAt: "2024-01-22T00:00:00.000Z",
      fundedAmount: 180514,
      marketplace: "LEROY MERLIN",
      marketplaceLogoUrl:
        "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/LEROY%20MERLIN",
    },
    {
      id: "ap-2ed2c323-8404-43b5-b696-d69c16c25e5a",
      status: APStatus.FINISHED,
      createdAt: "2024-01-19T10:44:42.938Z",
      fundedAmount: 272710,
      marketplace: "AMAZON",
      marketplaceLogoUrl:
        "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/AMAZON",
    },
    {
      id: "ap-4b9d5844-6726-48a2-9cf8-2af78021d5f6",
      status: APStatus.IN_PROGRESS,
      createdAt: "2024-01-19T00:00:00.000Z",
      fundedAmount: 271557,
      marketplace: "LEROY MERLIN",
      marketplaceLogoUrl:
        "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/LEROY%20MERLIN",
    },
    {
      id: "ap-6e86f172-7d20-4c5e-b423-15c70bebe98d",
      status: APStatus.FINISHED,
      createdAt: "2024-01-18T10:11:08.491Z",
      fundedAmount: 291442,
      marketplace: "AMAZON",
      marketplaceLogoUrl:
        "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/AMAZON",
    },
    {
      id: "ap-501836f1-3253-4da5-a0b4-61c74b3221e9",
      status: APStatus.IN_PROGRESS,
      createdAt: "2024-01-18T00:00:00.000Z",
      fundedAmount: 181130,
      marketplace: "LEROY MERLIN",
      marketplaceLogoUrl:
        "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/LEROY%20MERLIN",
    },
    {
      id: "ap-d3498f8d-cdca-4d1c-adaf-1935bf1a0164",
      status: APStatus.FINISHED,
      createdAt: "2024-01-17T10:57:57.800Z",
      fundedAmount: 343506,
      marketplace: "AMAZON",
      marketplaceLogoUrl:
        "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/AMAZON",
    },
    {
      id: "ap-6126b029-f3a6-4a56-be76-24512c691203",
      status: APStatus.IN_PROGRESS,
      createdAt: "2024-01-17T00:00:00.000Z",
      fundedAmount: 372210,
      marketplace: "LEROY MERLIN",
      marketplaceLogoUrl:
        "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/LEROY%20MERLIN",
    },
    {
      id: "ap-4e8c5339-afa4-4a91-bf7a-47b563a1b328",
      status: APStatus.FINISHED,
      createdAt: "2024-01-16T11:14:32.920Z",
      fundedAmount: 687776,
      marketplace: "AMAZON",
      marketplaceLogoUrl:
        "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/AMAZON",
    },
    {
      id: "ap-faa27038-f46f-4ae8-b7a9-536fa7e713e4",
      status: APStatus.FINISHED,
      createdAt: "2024-01-16T11:14:32.920Z",
      fundedAmount: 37375,
      marketplace: "AMAZON",
      marketplaceLogoUrl:
        "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/AMAZON",
    },
    {
      id: "ap-f425fc89-021d-4f26-8a67-7e6d63982480",
      status: APStatus.IN_PROGRESS,
      createdAt: "2024-01-16T00:00:00.000Z",
      fundedAmount: 140198,
      marketplace: "LEROY MERLIN",
      marketplaceLogoUrl:
        "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/LEROY%20MERLIN",
    },
    {
      id: "ap-18cb3c1a-d7d5-40cd-b317-aa99f86aaeb0",
      status: APStatus.FINISHED,
      createdAt: "2024-01-15T11:36:35.926Z",
      fundedAmount: 13020,
      marketplace: "AMAZON",
      marketplaceLogoUrl:
        "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/AMAZON",
    },
    {
      id: "ap-644a1c50-e82c-4a3f-8a20-3319bb964d71",
      status: APStatus.FINISHED,
      createdAt: "2024-01-15T11:36:35.926Z",
      fundedAmount: 27685,
      marketplace: "AMAZON",
      marketplaceLogoUrl:
        "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/AMAZON",
    },
    {
      id: "ap-d0bb0a40-f096-4555-9478-4ad31270b323",
      status: APStatus.FINISHED,
      createdAt: "2024-01-15T11:36:35.926Z",
      fundedAmount: 277106,
      marketplace: "AMAZON",
      marketplaceLogoUrl:
        "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/AMAZON",
    },
    {
      id: "ap-3c50f441-e87a-4514-bf09-25dd77576b3a",
      status: APStatus.IN_PROGRESS,
      createdAt: "2024-01-15T00:00:00.000Z",
      fundedAmount: 367094,
      marketplace: "LEROY MERLIN",
      marketplaceLogoUrl:
        "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/LEROY%20MERLIN",
    },
    {
      id: "ap-e3a395a8-e61a-4c3f-b7db-7e7684e46440",
      status: APStatus.FINISHED,
      createdAt: "2024-01-12T10:14:04.051Z",
      fundedAmount: 371942,
      marketplace: "AMAZON",
      marketplaceLogoUrl:
        "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/AMAZON",
    },
    {
      id: "ap-46b43b6b-1e2b-438d-9b73-f374850338c6",
      status: APStatus.IN_PROGRESS,
      createdAt: "2024-01-12T00:00:00.000Z",
      fundedAmount: 227861,
      marketplace: "LEROY MERLIN",
      marketplaceLogoUrl:
        "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/LEROY%20MERLIN",
    },
    {
      id: "ap-37b127ac-50d0-4c9e-a202-bc70bdbbe03d",
      status: APStatus.FINISHED,
      createdAt: "2024-01-11T10:42:12.921Z",
      fundedAmount: 308570,
      marketplace: "AMAZON",
      marketplaceLogoUrl:
        "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/AMAZON",
    },
    {
      id: "ap-53d88822-ff4f-492c-9943-79a5e522c691",
      status: APStatus.IN_PROGRESS,
      createdAt: "2024-01-11T00:00:00.000Z",
      fundedAmount: 259502,
      marketplace: "LEROY MERLIN",
      marketplaceLogoUrl:
        "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/LEROY%20MERLIN",
    },
    {
      id: "ap-e90726cb-1a4a-499e-8955-b921c8e79ed4",
      status: APStatus.FINISHED,
      createdAt: "2024-01-10T10:27:10.481Z",
      fundedAmount: 270146,
      marketplace: "AMAZON",
      marketplaceLogoUrl:
        "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/AMAZON",
    },
    {
      id: "ap-60801cde-1e5c-4cdc-b2b9-963e556d07d6",
      status: APStatus.IN_PROGRESS,
      createdAt: "2024-01-10T00:00:00.000Z",
      fundedAmount: 248311,
      marketplace: "LEROY MERLIN",
      marketplaceLogoUrl:
        "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/LEROY%20MERLIN",
    },
    {
      id: "ap-9d0d577c-e71b-4e93-ad3b-da957c8dc6da",
      status: APStatus.FINISHED,
      createdAt: "2024-01-09T11:06:31.059Z",
      fundedAmount: 64155,
      marketplace: "AMAZON",
      marketplaceLogoUrl:
        "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/AMAZON",
    },
    {
      id: "ap-4dd7a315-e3e9-4d47-8494-78c0e6694e11",
      status: APStatus.FINISHED,
      createdAt: "2024-01-09T11:06:31.059Z",
      fundedAmount: 648749,
      marketplace: "AMAZON",
      marketplaceLogoUrl:
        "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/AMAZON",
    },
    {
      id: "ap-7e9d504f-e3bb-4792-a9e2-426aaf8ca135",
      status: APStatus.IN_PROGRESS,
      createdAt: "2024-01-09T00:00:00.000Z",
      fundedAmount: 231372,
      marketplace: "LEROY MERLIN",
      marketplaceLogoUrl:
        "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/LEROY%20MERLIN",
    },
    {
      id: "ap-4f6305bc-ff84-4ef6-8be1-f39cfc0af30d",
      status: APStatus.FINISHED,
      createdAt: "2024-01-08T10:05:55.465Z",
      fundedAmount: 20486,
      marketplace: "AMAZON",
      marketplaceLogoUrl:
        "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/AMAZON",
    },
    {
      id: "ap-df37c16d-7b45-4d94-9232-8ac2bd13fe02",
      status: APStatus.FINISHED,
      createdAt: "2024-01-08T10:05:55.465Z",
      fundedAmount: 18833,
      marketplace: "AMAZON",
      marketplaceLogoUrl:
        "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/AMAZON",
    },
    {
      id: "ap-dad3dc84-1345-4dd0-bd18-68d94c7f7650",
      status: APStatus.FINISHED,
      createdAt: "2024-01-08T10:05:55.465Z",
      fundedAmount: 333298,
      marketplace: "AMAZON",
      marketplaceLogoUrl:
        "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/AMAZON",
    },
    {
      id: "ap-cb82c46b-991b-40d5-bcc3-a1b085f087a6",
      status: APStatus.IN_PROGRESS,
      createdAt: "2024-01-08T00:00:00.000Z",
      fundedAmount: 316680,
      marketplace: "LEROY MERLIN",
      marketplaceLogoUrl:
        "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/LEROY%20MERLIN",
    },
    {
      id: "ap-ffa6c251-4308-46cf-925a-f2ad1d6cdf04",
      status: APStatus.FINISHED,
      createdAt: "2024-01-05T10:03:25.631Z",
      fundedAmount: 328206,
      marketplace: "AMAZON",
      marketplaceLogoUrl:
        "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/AMAZON",
    },
    {
      id: "ap-6899963d-acc4-4261-ae52-2a1fb1d67e02",
      status: APStatus.IN_PROGRESS,
      createdAt: "2024-01-05T00:00:00.000Z",
      fundedAmount: 294045,
      marketplace: "LEROY MERLIN",
      marketplaceLogoUrl:
        "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/LEROY%20MERLIN",
    },
    {
      id: "ap-654397a3-6212-4c45-8f27-c7be53737cd4",
      status: APStatus.FINISHED,
      createdAt: "2024-01-04T10:08:35.996Z",
      fundedAmount: 352044,
      marketplace: "AMAZON",
      marketplaceLogoUrl:
        "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/AMAZON",
    },
    {
      id: "ap-8aa5e50b-7722-4edc-ac66-5450e610b7c0",
      status: APStatus.FINISHED,
      createdAt: "2024-01-04T00:00:00.000Z",
      fundedAmount: 232632,
      marketplace: "LEROY MERLIN",
      marketplaceLogoUrl:
        "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/LEROY%20MERLIN",
    },
    {
      id: "ap-cbacc6e7-c815-4860-b213-f9faeaff126e",
      status: APStatus.FINISHED,
      createdAt: "2024-01-03T10:40:24.085Z",
      fundedAmount: 750946,
      marketplace: "AMAZON",
      marketplaceLogoUrl:
        "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/AMAZON",
    },
    {
      id: "ap-af8a124e-bc4a-43f3-b10e-596ddcdadf73",
      status: APStatus.FINISHED,
      createdAt: "2024-01-03T10:40:24.085Z",
      fundedAmount: 39395,
      marketplace: "AMAZON",
      marketplaceLogoUrl:
        "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/AMAZON",
    },
    {
      id: "ap-028c369f-37d3-4bb1-8a45-a58052924944",
      status: APStatus.FINISHED,
      createdAt: "2024-01-03T00:00:00.000Z",
      fundedAmount: 334451,
      marketplace: "LEROY MERLIN",
      marketplaceLogoUrl:
        "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/LEROY%20MERLIN",
    },
    {
      id: "ap-8887fede-4a5f-4e51-a0bc-07d1ee161c7e",
      status: APStatus.FINISHED,
      createdAt: "2024-01-02T11:23:50.715Z",
      fundedAmount: 10158,
      marketplace: "AMAZON",
      marketplaceLogoUrl:
        "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/AMAZON",
    },
    {
      id: "ap-bd716385-93d7-4b11-8fd0-06560f56cd36",
      status: APStatus.FINISHED,
      createdAt: "2024-01-02T11:23:50.715Z",
      fundedAmount: 227450,
      marketplace: "AMAZON",
      marketplaceLogoUrl:
        "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/AMAZON",
    },
    {
      id: "ap-cd2a4bbf-b904-4802-8b51-57dbc2eb0552",
      status: APStatus.FINISHED,
      createdAt: "2024-01-02T11:23:50.715Z",
      fundedAmount: 303331,
      marketplace: "AMAZON",
      marketplaceLogoUrl:
        "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/AMAZON",
    },
    {
      id: "ap-0ad1be80-5419-4e4a-b2aa-98190da355a7",
      status: APStatus.FINISHED,
      createdAt: "2024-01-02T00:00:00.000Z",
      fundedAmount: 289129,
      marketplace: "LEROY MERLIN",
      marketplaceLogoUrl:
        "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/LEROY%20MERLIN",
    },
    {
      id: "ap-cd1917f7-64be-4bb2-9986-bf345c7ec82d",
      status: APStatus.FINISHED,
      createdAt: "2023-12-29T11:16:46.916Z",
      fundedAmount: 326782,
      marketplace: "AMAZON",
      marketplaceLogoUrl:
        "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/AMAZON",
    },
    {
      id: "ap-851f2c42-b648-4d89-8ede-089bf554f0b7",
      status: APStatus.FINISHED,
      createdAt: "2023-12-29T00:00:00.000Z",
      fundedAmount: 43499,
      marketplace: "LEROY MERLIN",
      marketplaceLogoUrl:
        "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/LEROY%20MERLIN",
    },
    {
      id: "ap-a1319c44-7476-46af-a933-4f5d436dc652",
      status: APStatus.FINISHED,
      createdAt: "2023-12-28T11:00:31.935Z",
      fundedAmount: 321834,
      marketplace: "AMAZON",
      marketplaceLogoUrl:
        "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/AMAZON",
    },
    {
      id: "ap-ec318763-5d81-4093-b733-d5edb3f66cde",
      status: APStatus.FINISHED,
      createdAt: "2023-12-28T00:00:00.000Z",
      fundedAmount: 273786,
      marketplace: "LEROY MERLIN",
      marketplaceLogoUrl:
        "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/LEROY%20MERLIN",
    },
    {
      id: "ap-d9a8ccb3-851b-4d54-9bbb-be56ede2b2d6",
      status: APStatus.FINISHED,
      createdAt: "2023-12-27T10:10:46.938Z",
      fundedAmount: 635061,
      marketplace: "AMAZON",
      marketplaceLogoUrl:
        "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/AMAZON",
    },
    {
      id: "ap-25f618a8-76ce-4008-90bd-aefe739d4705",
      status: APStatus.FINISHED,
      createdAt: "2023-12-27T00:00:00.000Z",
      fundedAmount: 347294,
      marketplace: "LEROY MERLIN",
      marketplaceLogoUrl:
        "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/LEROY%20MERLIN",
    },
    {
      id: "ap-2d8d52eb-5a9f-4806-a934-35ea90246e14",
      status: APStatus.FINISHED,
      createdAt: "2023-12-26T11:03:42.729Z",
      fundedAmount: 15591,
      marketplace: "AMAZON",
      marketplaceLogoUrl:
        "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/AMAZON",
    },
    {
      id: "ap-1a5d6167-323c-4197-b977-17009c47f357",
      status: APStatus.FINISHED,
      createdAt: "2023-12-26T11:03:42.729Z",
      fundedAmount: 189710,
      marketplace: "AMAZON",
      marketplaceLogoUrl:
        "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/AMAZON",
    },
    {
      id: "ap-dd6c97f5-ad6e-47c3-8655-ca598aade900",
      status: APStatus.FINISHED,
      createdAt: "2023-12-26T11:03:42.729Z",
      fundedAmount: 253475,
      marketplace: "AMAZON",
      marketplaceLogoUrl:
        "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/AMAZON",
    },
    {
      id: "ap-0aa0a0eb-115d-4247-879b-fdbef1206927",
      status: APStatus.FINISHED,
      createdAt: "2023-12-26T00:00:00.000Z",
      fundedAmount: 361142,
      marketplace: "LEROY MERLIN",
      marketplaceLogoUrl:
        "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/LEROY%20MERLIN",
    },
    {
      id: "ap-b204a1ad-4fa3-4a08-8277-a9198a137d5a",
      status: APStatus.FINISHED,
      createdAt: "2023-12-22T11:08:46.154Z",
      fundedAmount: 305314,
      marketplace: "AMAZON",
      marketplaceLogoUrl:
        "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/AMAZON",
    },
    {
      id: "ap-ba761502-5e2a-49ee-bdc7-9142f987f5e3",
      status: APStatus.FINISHED,
      createdAt: "2023-12-22T00:00:00.000Z",
      fundedAmount: 369798,
      marketplace: "LEROY MERLIN",
      marketplaceLogoUrl:
        "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/LEROY%20MERLIN",
    },
    {
      id: "ap-6e0c9d51-87c4-47f7-9473-0f05d236b2d4",
      status: APStatus.FINISHED,
      createdAt: "2023-12-21T10:19:59.588Z",
      fundedAmount: 393808,
      marketplace: "AMAZON",
      marketplaceLogoUrl:
        "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/AMAZON",
    },
    {
      id: "ap-7abd866b-0832-4f43-8835-63dfe5d7939e",
      status: APStatus.FINISHED,
      createdAt: "2023-12-21T00:00:00.000Z",
      fundedAmount: 263658,
      marketplace: "LEROY MERLIN",
      marketplaceLogoUrl:
        "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/LEROY%20MERLIN",
    },
    {
      id: "ap-e8066c5a-731a-4d97-8e09-cdf712f97541",
      status: APStatus.FINISHED,
      createdAt: "2023-12-20T11:12:16.747Z",
      fundedAmount: 330453,
      marketplace: "AMAZON",
      marketplaceLogoUrl:
        "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/AMAZON",
    },
    {
      id: "ap-2889a6eb-6209-4674-bae5-2082f7f5f1f0",
      status: APStatus.FINISHED,
      createdAt: "2023-12-20T00:00:00.000Z",
      fundedAmount: 587110,
      marketplace: "LEROY MERLIN",
      marketplaceLogoUrl:
        "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/LEROY%20MERLIN",
    },
    {
      id: "ap-932beeb6-4b05-4f69-b184-bf47822466de",
      status: APStatus.FINISHED,
      createdAt: "2023-12-19T10:56:35.776Z",
      fundedAmount: 414587,
      marketplace: "AMAZON",
      marketplaceLogoUrl:
        "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/AMAZON",
    },
    {
      id: "ap-87e23b34-1fa2-48b4-bc1e-a7229a5e5a75",
      status: APStatus.FINISHED,
      createdAt: "2023-12-19T00:00:00.000Z",
      fundedAmount: 414913,
      marketplace: "LEROY MERLIN",
      marketplaceLogoUrl:
        "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/LEROY%20MERLIN",
    },
    {
      id: "ap-8e66813d-aaaa-4961-b2d6-48d21e7a2ca6",
      status: APStatus.FINISHED,
      createdAt: "2023-12-18T10:49:56.845Z",
      fundedAmount: 621587,
      marketplace: "AMAZON",
      marketplaceLogoUrl:
        "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/AMAZON",
    },
    {
      id: "ap-025181a4-641b-4b43-8059-a40ce54c7b85",
      status: APStatus.FINISHED,
      createdAt: "2023-12-18T10:49:56.845Z",
      fundedAmount: 26621,
      marketplace: "AMAZON",
      marketplaceLogoUrl:
        "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/AMAZON",
    },
    {
      id: "ap-2a1807c7-e819-4d48-9552-3b9c6c8aac03",
      status: APStatus.FINISHED,
      createdAt: "2023-12-18T10:49:56.845Z",
      fundedAmount: 368189,
      marketplace: "AMAZON",
      marketplaceLogoUrl:
        "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/AMAZON",
    },
    {
      id: "ap-68245ea6-9990-4abe-97f7-804b20502fd4",
      status: APStatus.FINISHED,
      createdAt: "2023-12-18T00:00:00.000Z",
      fundedAmount: 863191,
      marketplace: "LEROY MERLIN",
      marketplaceLogoUrl:
        "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/LEROY%20MERLIN",
    },
    {
      id: "ap-8d201cac-84e6-4170-9e96-0488bc13403c",
      status: APStatus.FINISHED,
      createdAt: "2023-12-15T10:33:12.913Z",
      fundedAmount: 294308,
      marketplace: "AMAZON",
      marketplaceLogoUrl:
        "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/AMAZON",
    },
    {
      id: "ap-c04cd1f6-bde9-40ce-862a-3707257e5791",
      status: APStatus.FINISHED,
      createdAt: "2023-12-15T00:00:00.000Z",
      fundedAmount: 623606,
      marketplace: "LEROY MERLIN",
      marketplaceLogoUrl:
        "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/LEROY%20MERLIN",
    },
    {
      id: "ap-27caa4ea-ddae-422a-9c1e-7b2b33c1d150",
      status: APStatus.FINISHED,
      createdAt: "2023-12-14T10:20:07.648Z",
      fundedAmount: 358395,
      marketplace: "AMAZON",
      marketplaceLogoUrl:
        "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/AMAZON",
    },
    {
      id: "ap-1d781427-23e3-4c65-8047-b0f7d2957b2b",
      status: APStatus.FINISHED,
      createdAt: "2023-12-14T00:00:00.000Z",
      fundedAmount: 480026,
      marketplace: "LEROY MERLIN",
      marketplaceLogoUrl:
        "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/LEROY%20MERLIN",
    },
    {
      id: "ap-5913752c-074f-404b-a09b-ef2593da4e6f",
      status: APStatus.FINISHED,
      createdAt: "2023-12-13T10:42:56.138Z",
      fundedAmount: 383091,
      marketplace: "AMAZON",
      marketplaceLogoUrl:
        "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/AMAZON",
    },
    {
      id: "ap-87b780f8-6f78-421d-9f9d-0a140bb0f022",
      status: APStatus.FINISHED,
      createdAt: "2023-12-13T00:00:00.000Z",
      fundedAmount: 425303,
      marketplace: "LEROY MERLIN",
      marketplaceLogoUrl:
        "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/LEROY%20MERLIN",
    },
    {
      id: "ap-2e060266-abb4-47e5-9f49-1c54a2a8822d",
      status: APStatus.FINISHED,
      createdAt: "2023-12-12T11:57:49.501Z",
      fundedAmount: 448109,
      marketplace: "AMAZON",
      marketplaceLogoUrl:
        "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/AMAZON",
    },
    {
      id: "ap-1a97575a-419c-4559-9c46-f64b3181544d",
      status: APStatus.FINISHED,
      createdAt: "2023-12-12T00:00:00.000Z",
      fundedAmount: 460816,
      marketplace: "LEROY MERLIN",
      marketplaceLogoUrl:
        "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/LEROY%20MERLIN",
    },
    {
      id: "ap-646ed9d1-e781-4d76-bca7-34fcb7730210",
      status: APStatus.FINISHED,
      createdAt: "2023-12-11T11:12:32.085Z",
      fundedAmount: 1087,
      marketplace: "AMAZON",
      marketplaceLogoUrl:
        "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/AMAZON",
    },
    {
      id: "ap-692bccbe-df8c-4b10-847c-270a28ef1004",
      status: APStatus.FINISHED,
      createdAt: "2023-12-11T11:12:32.085Z",
      fundedAmount: 27361,
      marketplace: "AMAZON",
      marketplaceLogoUrl:
        "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/AMAZON",
    },
    {
      id: "ap-84de92e8-30d2-4133-bb22-46124d1ce56f",
      status: APStatus.FINISHED,
      createdAt: "2023-12-11T11:12:32.085Z",
      fundedAmount: 567675,
      marketplace: "AMAZON",
      marketplaceLogoUrl:
        "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/AMAZON",
    },
    {
      id: "ap-7ddaa400-d7cb-48ed-a1b2-b1b5ce697325",
      status: APStatus.FINISHED,
      createdAt: "2023-12-11T11:12:32.085Z",
      fundedAmount: 283762,
      marketplace: "AMAZON",
      marketplaceLogoUrl:
        "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/AMAZON",
    },
    {
      id: "ap-99b9a53c-e0ac-4312-b24d-1dafd9caa860",
      status: APStatus.FINISHED,
      createdAt: "2023-12-11T00:00:00.000Z",
      fundedAmount: 589150,
      marketplace: "LEROY MERLIN",
      marketplaceLogoUrl:
        "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/LEROY%20MERLIN",
    },
    {
      id: "ap-26178e33-3e8c-4227-94b1-7344d8c77e01",
      status: APStatus.FINISHED,
      createdAt: "2023-12-08T10:55:41.169Z",
      fundedAmount: 369707,
      marketplace: "AMAZON",
      marketplaceLogoUrl:
        "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/AMAZON",
    },
    {
      id: "ap-197819b7-3b98-4148-afe0-26d7e993db8e",
      status: APStatus.FINISHED,
      createdAt: "2023-12-08T00:00:00.000Z",
      fundedAmount: 350314,
      marketplace: "LEROY MERLIN",
      marketplaceLogoUrl:
        "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/LEROY%20MERLIN",
    },
    {
      id: "ap-04cf6c6a-06cc-45c4-8481-d9fd3babaacc",
      status: APStatus.FINISHED,
      createdAt: "2023-12-07T11:05:36.764Z",
      fundedAmount: 341867,
      marketplace: "AMAZON",
      marketplaceLogoUrl:
        "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/AMAZON",
    },
    {
      id: "ap-39626faa-f8ad-4fed-9a5d-1ed48085091d",
      status: APStatus.FINISHED,
      createdAt: "2023-12-07T00:00:00.000Z",
      fundedAmount: 532182,
      marketplace: "LEROY MERLIN",
      marketplaceLogoUrl:
        "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/LEROY%20MERLIN",
    },
    {
      id: "ap-a0c51fd1-6686-4e9a-bed3-ef035452f8ff",
      status: APStatus.FINISHED,
      createdAt: "2023-12-06T10:10:53.406Z",
      fundedAmount: 322814,
      marketplace: "AMAZON",
      marketplaceLogoUrl:
        "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/AMAZON",
    },
    {
      id: "ap-248b8f17-f1bc-4dd1-94c0-d446c991b3ce",
      status: APStatus.FINISHED,
      createdAt: "2023-12-06T00:00:00.000Z",
      fundedAmount: 528663,
      marketplace: "LEROY MERLIN",
      marketplaceLogoUrl:
        "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/LEROY%20MERLIN",
    },
    {
      id: "ap-1faa922e-77e8-462b-898d-8276fb2a9483",
      status: APStatus.FINISHED,
      createdAt: "2023-12-05T10:56:11.224Z",
      fundedAmount: 417006,
      marketplace: "AMAZON",
      marketplaceLogoUrl:
        "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/AMAZON",
    },
    {
      id: "ap-cf589b00-9c03-4d16-a3a5-bba5405acfdf",
      status: APStatus.FINISHED,
      createdAt: "2023-12-05T00:00:00.000Z",
      fundedAmount: 503574,
      marketplace: "LEROY MERLIN",
      marketplaceLogoUrl:
        "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/LEROY%20MERLIN",
    },
    {
      id: "ap-62f949cf-79ab-4a88-9347-bc28e9832b68",
      status: APStatus.FINISHED,
      createdAt: "2023-12-04T10:41:57.169Z",
      fundedAmount: 589540,
      marketplace: "AMAZON",
      marketplaceLogoUrl:
        "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/AMAZON",
    },
    {
      id: "ap-4efe6baa-ab31-4f61-abb2-224b995e7a8d",
      status: APStatus.FINISHED,
      createdAt: "2023-12-04T10:41:57.169Z",
      fundedAmount: 23249,
      marketplace: "AMAZON",
      marketplaceLogoUrl:
        "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/AMAZON",
    },
    {
      id: "ap-1b867e3b-af7c-45c0-bca0-b839d8916fd5",
      status: APStatus.FINISHED,
      createdAt: "2023-12-04T10:41:57.169Z",
      fundedAmount: 261106,
      marketplace: "AMAZON",
      marketplaceLogoUrl:
        "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/AMAZON",
    },
    {
      id: "ap-daef88d9-73d5-43f4-9a9f-9185a2caf66c",
      status: APStatus.FINISHED,
      createdAt: "2023-12-04T00:00:00.000Z",
      fundedAmount: 454886,
      marketplace: "LEROY MERLIN",
      marketplaceLogoUrl:
        "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/LEROY%20MERLIN",
    },
    {
      id: "ap-f91152f9-019e-46d1-87fe-6d7636ae6fbd",
      status: APStatus.FINISHED,
      createdAt: "2023-12-01T11:07:36.763Z",
      fundedAmount: 327773,
      marketplace: "AMAZON",
      marketplaceLogoUrl:
        "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/AMAZON",
    },
    {
      id: "ap-848a55e4-e8fb-4276-b852-93c77bfd83b3",
      status: APStatus.FINISHED,
      createdAt: "2023-12-01T00:00:00.000Z",
      fundedAmount: 473295,
      marketplace: "LEROY MERLIN",
      marketplaceLogoUrl:
        "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/LEROY%20MERLIN",
    },
    {
      id: "ap-6814326a-8422-4282-801b-8400b71ecf0a",
      status: APStatus.FINISHED,
      createdAt: "2023-11-30T10:58:57.187Z",
      fundedAmount: 257698,
      marketplace: "AMAZON",
      marketplaceLogoUrl:
        "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/AMAZON",
    },
    {
      id: "ap-aaf83a1c-a702-4181-a397-26ab203f85bb",
      status: APStatus.FINISHED,
      createdAt: "2023-11-30T00:00:00.000Z",
      fundedAmount: 368614,
      marketplace: "LEROY MERLIN",
      marketplaceLogoUrl:
        "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/LEROY%20MERLIN",
    },
    {
      id: "ap-533c41e5-ab58-4db7-8e9d-0b363817446c",
      status: APStatus.FINISHED,
      createdAt: "2023-11-29T09:57:59.112Z",
      fundedAmount: 341281,
      marketplace: "AMAZON",
      marketplaceLogoUrl:
        "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/AMAZON",
    },
    {
      id: "ap-8ea0dedb-8480-42c1-9cc9-643ac5bfe0ef",
      status: APStatus.FINISHED,
      createdAt: "2023-11-29T00:00:00.000Z",
      fundedAmount: 388366,
      marketplace: "LEROY MERLIN",
      marketplaceLogoUrl:
        "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/LEROY%20MERLIN",
    },
    {
      id: "ap-0f6dd4ac-5091-464f-a700-bb4be2817d01",
      status: APStatus.FINISHED,
      createdAt: "2023-11-28T09:58:19.192Z",
      fundedAmount: 639660,
      marketplace: "AMAZON",
      marketplaceLogoUrl:
        "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/AMAZON",
    },
    {
      id: "ap-a7d93fd8-77e3-4c7c-89c6-4749d7487f6e",
      status: APStatus.FINISHED,
      createdAt: "2023-11-28T00:00:00.000Z",
      fundedAmount: 641720,
      marketplace: "LEROY MERLIN",
      marketplaceLogoUrl:
        "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/LEROY%20MERLIN",
    },
    {
      id: "ap-dbef6ae0-5789-4acc-9ba2-16ab4536e1cb",
      status: APStatus.FINISHED,
      createdAt: "2023-11-27T11:46:06.632Z",
      fundedAmount: 13458,
      marketplace: "AMAZON",
      marketplaceLogoUrl:
        "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/AMAZON",
    },
    {
      id: "ap-3cd55c32-26a2-4630-849f-214669893797",
      status: APStatus.FINISHED,
      createdAt: "2023-11-27T11:46:06.632Z",
      fundedAmount: 15003,
      marketplace: "AMAZON",
      marketplaceLogoUrl:
        "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/AMAZON",
    },
    {
      id: "ap-821b6221-9b13-4dc0-bbce-4f4e6908cbed",
      status: APStatus.FINISHED,
      createdAt: "2023-11-27T11:46:06.632Z",
      fundedAmount: 257107,
      marketplace: "AMAZON",
      marketplaceLogoUrl:
        "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/AMAZON",
    },
    {
      id: "ap-5d931a7b-734a-4605-9313-503667d883dc",
      status: APStatus.FINISHED,
      createdAt: "2023-11-27T00:00:00.000Z",
      fundedAmount: 626622,
      marketplace: "LEROY MERLIN",
      marketplaceLogoUrl:
        "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/LEROY%20MERLIN",
    },
    {
      id: "ap-c4c9993e-1c7e-407e-8dd7-99502a63f383",
      status: APStatus.FINISHED,
      createdAt: "2023-11-24T11:12:33.059Z",
      fundedAmount: 243092,
      marketplace: "AMAZON",
      marketplaceLogoUrl:
        "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/AMAZON",
    },
    {
      id: "ap-a09de6e2-785b-4ba1-bef4-ed7adabacfd7",
      status: APStatus.FINISHED,
      createdAt: "2023-11-24T00:00:00.000Z",
      fundedAmount: 352110,
      marketplace: "LEROY MERLIN",
      marketplaceLogoUrl:
        "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/LEROY%20MERLIN",
    },
    {
      id: "ap-9d7e0d88-539c-4799-ae40-32da126bdc57",
      status: APStatus.FINISHED,
      createdAt: "2023-11-23T10:54:59.815Z",
      fundedAmount: 266224,
      marketplace: "AMAZON",
      marketplaceLogoUrl:
        "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/AMAZON",
    },
    {
      id: "ap-f1bee167-51bf-4bb5-9198-fe85d3294ad8",
      status: APStatus.FINISHED,
      createdAt: "2023-11-23T00:00:00.000Z",
      fundedAmount: 250610,
      marketplace: "LEROY MERLIN",
      marketplaceLogoUrl:
        "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/LEROY%20MERLIN",
    },
    {
      id: "ap-d9be6a56-b76c-49c3-84e2-8dd40e56d8dc",
      status: APStatus.FINISHED,
      createdAt: "2023-11-22T10:22:39.908Z",
      fundedAmount: 350916,
      marketplace: "AMAZON",
      marketplaceLogoUrl:
        "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/AMAZON",
    },
    {
      id: "ap-5c263499-e3c5-422b-86b8-f3de463abadd",
      status: APStatus.FINISHED,
      createdAt: "2023-11-21T10:19:29.818Z",
      fundedAmount: 667538,
      marketplace: "AMAZON",
      marketplaceLogoUrl:
        "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/AMAZON",
    },
    {
      id: "ap-0f6af48e-4018-4576-b611-03891d0b1572",
      status: APStatus.FINISHED,
      createdAt: "2023-11-20T10:59:50.172Z",
      fundedAmount: 14742,
      marketplace: "AMAZON",
      marketplaceLogoUrl:
        "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/AMAZON",
    },
    {
      id: "ap-74fea667-4cd3-4f28-b722-b669b87ba982",
      status: APStatus.FINISHED,
      createdAt: "2023-11-20T10:59:50.172Z",
      fundedAmount: 28671,
      marketplace: "AMAZON",
      marketplaceLogoUrl:
        "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/AMAZON",
    },
    {
      id: "ap-fcce6ca0-1571-4a52-93ff-b4a9cf27913c",
      status: APStatus.FINISHED,
      createdAt: "2023-11-20T10:59:50.172Z",
      fundedAmount: 252879,
      marketplace: "AMAZON",
      marketplaceLogoUrl:
        "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/AMAZON",
    },
    {
      id: "ap-c2abecac-3cae-47fb-9997-57946dc8c0e2",
      status: APStatus.FINISHED,
      createdAt: "2023-11-20T00:00:00.000Z",
      fundedAmount: 1057424,
      marketplace: "LEROY MERLIN",
      marketplaceLogoUrl:
        "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/LEROY%20MERLIN",
    },
    {
      id: "ap-4f6e0036-c02e-4f5a-9b6e-9343a2835d33",
      status: APStatus.FINISHED,
      createdAt: "2023-11-17T10:07:00.490Z",
      fundedAmount: 247370,
      marketplace: "AMAZON",
      marketplaceLogoUrl:
        "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/AMAZON",
    },
    {
      id: "ap-0d2bda73-9ca0-4bb0-97aa-ddcbb02bd5d8",
      status: APStatus.FINISHED,
      createdAt: "2023-11-17T00:00:00.000Z",
      fundedAmount: 167867,
      marketplace: "LEROY MERLIN",
      marketplaceLogoUrl:
        "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/LEROY%20MERLIN",
    },
    {
      id: "ap-4018b296-023e-49ba-accb-b7b6cd99cdc4",
      status: APStatus.FINISHED,
      createdAt: "2023-11-16T10:33:17.007Z",
      fundedAmount: 278138,
      marketplace: "AMAZON",
      marketplaceLogoUrl:
        "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/AMAZON",
    },
    {
      id: "ap-0d91c3b9-41c3-4226-8e04-79d24c0b61b0",
      status: APStatus.FINISHED,
      createdAt: "2023-11-16T00:00:00.000Z",
      fundedAmount: 141498,
      marketplace: "LEROY MERLIN",
      marketplaceLogoUrl:
        "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/LEROY%20MERLIN",
    },
    {
      id: "ap-3afc84ce-ac41-432e-9896-80947e506612",
      status: APStatus.FINISHED,
      createdAt: "2023-11-15T10:14:09.325Z",
      fundedAmount: 272095,
      marketplace: "AMAZON",
      marketplaceLogoUrl:
        "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/AMAZON",
    },
    {
      id: "ap-303ca003-0617-4660-9067-1a844b5ef49e",
      status: APStatus.FINISHED,
      createdAt: "2023-11-15T00:00:00.000Z",
      fundedAmount: 174849,
      marketplace: "LEROY MERLIN",
      marketplaceLogoUrl:
        "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/LEROY%20MERLIN",
    },
    {
      id: "ap-f054bfd7-17ed-43e2-970f-7718f7e3ed83",
      status: APStatus.FINISHED,
      createdAt: "2023-11-14T11:21:00.262Z",
      fundedAmount: 770,
      marketplace: "AMAZON",
      marketplaceLogoUrl:
        "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/AMAZON",
    },
    {
      id: "ap-9cf35820-d1ee-4e8a-89fd-cc395c619111",
      status: APStatus.FINISHED,
      createdAt: "2023-11-14T11:21:00.262Z",
      fundedAmount: 641502,
      marketplace: "AMAZON",
      marketplaceLogoUrl:
        "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/AMAZON",
    },
    {
      id: "ap-f312f49d-c543-4b8e-a46f-4f5133cb1a6d",
      status: APStatus.FINISHED,
      createdAt: "2023-11-14T00:00:00.000Z",
      fundedAmount: 193188,
      marketplace: "LEROY MERLIN",
      marketplaceLogoUrl:
        "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/LEROY%20MERLIN",
    },
    {
      id: "ap-bf5a1991-44e8-4efd-ab23-091f76c9d454",
      status: APStatus.FINISHED,
      createdAt: "2023-11-13T12:44:43.492Z",
      fundedAmount: 16062,
      marketplace: "AMAZON",
      marketplaceLogoUrl:
        "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/AMAZON",
    },
    {
      id: "ap-6ad8e24c-e987-4bba-bd99-089bcd782a0f",
      status: APStatus.FINISHED,
      createdAt: "2023-11-13T12:44:43.492Z",
      fundedAmount: 20221,
      marketplace: "AMAZON",
      marketplaceLogoUrl:
        "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/AMAZON",
    },
    {
      id: "ap-264c9191-10b9-492c-853c-a6368c456288",
      status: APStatus.FINISHED,
      createdAt: "2023-11-13T12:44:43.492Z",
      fundedAmount: 268828,
      marketplace: "AMAZON",
      marketplaceLogoUrl:
        "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/AMAZON",
    },
    {
      id: "ap-96999e18-1b4b-42fa-8e9b-a8e2b955631f",
      status: APStatus.FINISHED,
      createdAt: "2023-11-13T00:00:00.000Z",
      fundedAmount: 108967,
      marketplace: "LEROY MERLIN",
      marketplaceLogoUrl:
        "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/LEROY%20MERLIN",
    },
    {
      id: "ap-55617803-5247-477b-82d4-a3c54b7957e0",
      status: APStatus.FINISHED,
      createdAt: "2023-11-10T11:11:29.949Z",
      fundedAmount: 299373,
      marketplace: "AMAZON",
      marketplaceLogoUrl:
        "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/AMAZON",
    },
    {
      id: "ap-d68153c0-b66f-4394-938c-7b7445659652",
      status: APStatus.FINISHED,
      createdAt: "2023-11-10T00:00:00.000Z",
      fundedAmount: 184135,
      marketplace: "LEROY MERLIN",
      marketplaceLogoUrl:
        "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/LEROY%20MERLIN",
    },
    {
      id: "ap-9d387698-5dff-444e-bbd0-ae4768461b5c",
      status: APStatus.FINISHED,
      createdAt: "2023-11-09T11:17:42.354Z",
      fundedAmount: 551602,
      marketplace: "AMAZON",
      marketplaceLogoUrl:
        "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/AMAZON",
    },
    {
      id: "ap-7520cc4e-45d8-4146-857a-3cd15a9f55d9",
      status: APStatus.FINISHED,
      createdAt: "2023-11-09T00:00:00.000Z",
      fundedAmount: 187908,
      marketplace: "LEROY MERLIN",
      marketplaceLogoUrl:
        "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/LEROY%20MERLIN",
    },
    {
      id: "ap-6b549d2b-794e-47d6-8f25-dc4a04d59eb1",
      status: APStatus.FINISHED,
      createdAt: "2023-11-08T11:13:09.795Z",
      fundedAmount: 29334,
      marketplace: "AMAZON",
      marketplaceLogoUrl:
        "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/AMAZON",
    },
    {
      id: "ap-b9aecdd5-918e-4e65-82bb-7c9ca168fddd",
      status: APStatus.FINISHED,
      createdAt: "2023-11-08T00:00:00.000Z",
      fundedAmount: 237110,
      marketplace: "LEROY MERLIN",
      marketplaceLogoUrl:
        "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/LEROY%20MERLIN",
    },
    {
      id: "ap-9f9d9737-96ac-43cb-a989-cc12ff1d0120",
      status: APStatus.FINISHED,
      createdAt: "2023-11-07T10:23:19.957Z",
      fundedAmount: 1448,
      marketplace: "AMAZON",
      marketplaceLogoUrl:
        "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/AMAZON",
    },
    {
      id: "ap-08a91264-c896-44ad-acbf-9979e0187130",
      status: APStatus.FINISHED,
      createdAt: "2023-11-07T10:23:19.957Z",
      fundedAmount: 674081,
      marketplace: "AMAZON",
      marketplaceLogoUrl:
        "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/AMAZON",
    },
    {
      id: "ap-1c09ede5-b8cc-49bf-9637-ac1631b114aa",
      status: APStatus.FINISHED,
      createdAt: "2023-11-07T00:00:00.000Z",
      fundedAmount: 267468,
      marketplace: "LEROY MERLIN",
      marketplaceLogoUrl:
        "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/LEROY%20MERLIN",
    },
    {
      id: "ap-71d13bb1-9688-484d-8a90-40dee628caac",
      status: APStatus.FINISHED,
      createdAt: "2023-11-06T00:00:00.000Z",
      fundedAmount: 366486,
      marketplace: "LEROY MERLIN",
      marketplaceLogoUrl:
        "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/LEROY%20MERLIN",
    },
    {
      id: "ap-e30e132e-45fb-43bf-8126-694afc540936",
      status: APStatus.FINISHED,
      createdAt: "2023-11-03T00:00:00.000Z",
      fundedAmount: 219404,
      marketplace: "LEROY MERLIN",
      marketplaceLogoUrl:
        "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/LEROY%20MERLIN",
    },
    {
      id: "ap-90fca4e2-5c8b-4304-acd8-cba7a9403503",
      status: APStatus.FINISHED,
      createdAt: "2023-11-02T00:00:00.000Z",
      fundedAmount: 217031,
      marketplace: "LEROY MERLIN",
      marketplaceLogoUrl:
        "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/LEROY%20MERLIN",
    },
    {
      id: "ap-d01b6612-a57c-4c9d-8308-2dc83d4f8c2a",
      status: APStatus.FINISHED,
      createdAt: "2023-10-31T00:00:00.000Z",
      fundedAmount: 245412,
      marketplace: "LEROY MERLIN",
      marketplaceLogoUrl:
        "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/LEROY%20MERLIN",
    },
    {
      id: "ap-d7af50b5-3cdf-491f-b4be-2d8bea6799d6",
      status: APStatus.FINISHED,
      createdAt: "2023-10-30T00:00:00.000Z",
      fundedAmount: 126830,
      marketplace: "LEROY MERLIN",
      marketplaceLogoUrl:
        "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/LEROY%20MERLIN",
    },
    {
      id: "ap-850f209c-3b48-4999-9bab-d1f2d9b60531",
      status: APStatus.FINISHED,
      createdAt: "2023-10-27T00:00:00.000Z",
      fundedAmount: 130974,
      marketplace: "LEROY MERLIN",
      marketplaceLogoUrl:
        "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/LEROY%20MERLIN",
    },
    {
      id: "ap-08484046-1f2e-4934-8b4a-62dfe0a84acd",
      status: APStatus.FINISHED,
      createdAt: "2023-10-26T00:00:00.000Z",
      fundedAmount: 46926,
      marketplace: "LEROY MERLIN",
      marketplaceLogoUrl:
        "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/LEROY%20MERLIN",
    },
    {
      id: "ap-4484487a-2b35-4b6f-99df-6715d7ce9289",
      status: APStatus.FINISHED,
      createdAt: "2023-10-24T00:00:00.000Z",
      fundedAmount: 53572,
      marketplace: "LEROY MERLIN",
      marketplaceLogoUrl:
        "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/LEROY%20MERLIN",
    },
    {
      id: "ap-1f59ed6e-ef2e-4201-8320-0753219ec130",
      status: APStatus.FINISHED,
      createdAt: "2023-10-23T00:00:00.000Z",
      fundedAmount: 268317,
      marketplace: "LEROY MERLIN",
      marketplaceLogoUrl:
        "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/LEROY%20MERLIN",
    },
    {
      id: "ap-eebb6cd7-ea69-42a4-b884-ecc7e514dfbb",
      status: APStatus.FINISHED,
      createdAt: "2023-10-20T00:00:00.000Z",
      fundedAmount: 155837,
      marketplace: "LEROY MERLIN",
      marketplaceLogoUrl:
        "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/LEROY%20MERLIN",
    },
    {
      id: "ap-564cf141-a667-41ef-8f42-ec67628936c4",
      status: APStatus.FINISHED,
      createdAt: "2023-10-19T00:00:00.000Z",
      fundedAmount: 221402,
      marketplace: "LEROY MERLIN",
      marketplaceLogoUrl:
        "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/LEROY%20MERLIN",
    },
    {
      id: "ap-10a8ac23-1c1d-44d9-a02b-5e821568653f",
      status: APStatus.FINISHED,
      createdAt: "2023-10-18T00:00:00.000Z",
      fundedAmount: 216421,
      marketplace: "LEROY MERLIN",
      marketplaceLogoUrl:
        "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/LEROY%20MERLIN",
    },
    {
      id: "ap-e87caa5e-e91c-4512-9b35-d35003031fdd",
      status: APStatus.FINISHED,
      createdAt: "2023-10-17T00:00:00.000Z",
      fundedAmount: 269903,
      marketplace: "LEROY MERLIN",
      marketplaceLogoUrl:
        "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/LEROY%20MERLIN",
    },
    {
      id: "ap-e887e769-db26-457b-8d34-c20aa222d312",
      status: APStatus.FINISHED,
      createdAt: "2023-10-16T00:00:00.000Z",
      fundedAmount: 267530,
      marketplace: "LEROY MERLIN",
      marketplaceLogoUrl:
        "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/LEROY%20MERLIN",
    },
    {
      id: "ap-64030813-be6a-4d8c-863d-3ddc1e06bf37",
      status: APStatus.FINISHED,
      createdAt: "2023-10-13T00:00:00.000Z",
      fundedAmount: 305980,
      marketplace: "LEROY MERLIN",
      marketplaceLogoUrl:
        "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/LEROY%20MERLIN",
    },
    {
      id: "ap-80f9f83b-3bcc-4e7e-9005-7159d9379f49",
      status: APStatus.FINISHED,
      createdAt: "2023-10-12T00:00:00.000Z",
      fundedAmount: 162471,
      marketplace: "LEROY MERLIN",
      marketplaceLogoUrl:
        "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/LEROY%20MERLIN",
    },
    {
      id: "ap-36e340f4-441a-4b20-bbdc-afe79dc27f42",
      status: APStatus.FINISHED,
      createdAt: "2023-10-11T00:00:00.000Z",
      fundedAmount: 120631,
      marketplace: "LEROY MERLIN",
      marketplaceLogoUrl:
        "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/LEROY%20MERLIN",
    },
    {
      id: "ap-be2ba07b-766d-4d16-a13c-46f870e9a260",
      status: APStatus.FINISHED,
      createdAt: "2023-10-10T00:00:00.000Z",
      fundedAmount: 337639,
      marketplace: "LEROY MERLIN",
      marketplaceLogoUrl:
        "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/LEROY%20MERLIN",
    },
    {
      id: "ap-aaf4a186-f0d5-45c9-aac6-a5465cb19a9d",
      status: APStatus.FINISHED,
      createdAt: "2023-10-09T00:00:00.000Z",
      fundedAmount: 167924,
      marketplace: "LEROY MERLIN",
      marketplaceLogoUrl:
        "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/LEROY%20MERLIN",
    },
    {
      id: "ap-b6994e63-42e9-4f32-9205-e31df1f3f760",
      status: APStatus.FINISHED,
      createdAt: "2023-10-06T00:00:00.000Z",
      fundedAmount: 166661,
      marketplace: "LEROY MERLIN",
      marketplaceLogoUrl:
        "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/LEROY%20MERLIN",
    },
    {
      id: "ap-c3521821-6c00-4bf0-a40e-5b71fbf7a74f",
      status: APStatus.FINISHED,
      createdAt: "2023-10-04T00:00:00.000Z",
      fundedAmount: 10890,
      marketplace: "LEROY MERLIN",
      marketplaceLogoUrl:
        "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/LEROY%20MERLIN",
    },
    {
      id: "ap-5df976b9-842d-407e-88c6-c945ee901fea",
      status: APStatus.FINISHED,
      createdAt: "2023-10-04T00:00:00.000Z",
      fundedAmount: 5356434,
      marketplace: "LEROY MERLIN",
      marketplaceLogoUrl:
        "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/LEROY%20MERLIN",
    },
  ],
  totalCount: 196,
  listMarketplaces: ["AMAZON", "LEROY MERLIN"],
}
