import { zodResolver } from "@hookform/resolvers/zod"
import { useForm, useFormContext } from "react-hook-form"
import * as z from "zod"
import {
  EmailSchema,
  PhoneSchema,
  StrictBankingString,
  StringSchema,
  UuidSchema,
} from "../../../../00_shared/utils/commonZodSchemas.utils"
import { CommonTFunction, useCommonTranslation } from "../../../../01_technical/translations"

const getApplicationFormSchema = (t: CommonTFunction) => {
  return z.object({
    amount: z
      .number({ message: t("formValidation.common.invalid") })
      .min(10, { message: t("formValidation.number.valueTooSmall", { min: 10 }) })
      .max(1000000, { message: t("formValidation.number.valueTooLarge", { max: 1000000 }) }),
    debtorIBAN: StringSchema(t),
    installmentsCount: z.number().int().positive().min(3).max(4),
    label: StrictBankingString(t),
    beneficiary: z.object({
      id: UuidSchema(t),
      email: EmailSchema(t),
      phone: PhoneSchema(t),
      siret: StringSchema(t),
      headquartersCountry: StringSchema(t),
    }),
    filePath: StringSchema(t),
  })
}

export const useCwtApplicationForm = () => {
  const { t } = useCommonTranslation()
  const applicationFormSchema = getApplicationFormSchema(t)
  type SchemaType = z.infer<typeof applicationFormSchema>
  return useForm<SchemaType>({
    resolver: zodResolver(applicationFormSchema),
    defaultValues: {
      amount: 0,
      debtorIBAN: "",
      installmentsCount: 3,
      label: "",
      beneficiary: {
        id: "",
        email: "",
        phone: "",
        siret: "",
      },
    },
  })
}

export type CwtApplicationFormValues = z.infer<ReturnType<typeof getApplicationFormSchema>>

export const useCwtApplicationFormContext = () => {
  const { t } = useCommonTranslation()
  const applicationFormSchema = getApplicationFormSchema(t)
  type SchemaType = z.infer<typeof applicationFormSchema>
  return useFormContext<SchemaType>()
}
