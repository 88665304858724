import styled from "styled-components"
import IconArrowDownRight from "../../00_shared/icons/arrow-down-right.png"
import IconArrowUpRight from "../../00_shared/icons/arrow-up-right.png"
import { IssuingTransaction } from "../../00_shared/business/IssuingTransaction"
import { Typography } from "@hero/krypton"

const Section = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`

const Badge = styled.div<{ type: "DEBIT" | "CREDIT" }>`
  width: 28px;
  height: 28px;
  background: ${({ type }) => (type === "DEBIT" ? "#F8F8F8" : "#FFE7EF")};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
`

interface IssuingCardTransactionLabelProps {
  transaction: IssuingTransaction
}

export const IssuingCardTransactionLabel = ({ transaction }: IssuingCardTransactionLabelProps) => {
  return (
    <Section>
      <Badge type={transaction.operationType !== "AUTHORIZATION" ? "CREDIT" : "DEBIT"}>
        {transaction.operationType !== "AUTHORIZATION" && (
          <img src={IconArrowUpRight} alt="Arrow up right" style={{ width: 20 }} />
        )}
        {transaction.operationType === "AUTHORIZATION" && (
          <img src={IconArrowDownRight} alt="Arrow down right" style={{ width: 20 }} />
        )}
      </Badge>
      <Typography $variant="body-4-regular">{transaction.label}</Typography>
    </Section>
  )
}
