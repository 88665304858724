import { Typography } from "@hero/krypton"
import { useNavigate } from "react-router-dom"
import styled from "styled-components"
import { Card } from "../../../../../00_shared/components/Card"
import { FlexContainer, FlexItem } from "../../../../../00_shared/components/Flex"
import { Beneficiary } from "../hooks/useBeneficiaries"

const ListContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 0.5rem 1rem;
`

const BeneficiaryItem = styled(Card)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem;
  background: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.black.$900};
  text-decoration: none;
  opacity: 1;
  cursor: pointer;
`

const BeneficiaryDetails = styled.div`
  display: flex;
  flex-direction: column;
`

const BeneficiaryLabel = styled(Typography).attrs({ $variant: "body-4-medium" })``

const SubBeneficiaryText = styled(Typography).attrs({ $variant: "caption-2" })`
  color: ${({ theme }) => theme.colors.grey.$500};
`

const BeneficiaryIBAN = styled(Typography).attrs({ $variant: "body-4-regular" })`
  color: ${({ theme }) => theme.colors.grey.$600};
`

interface BeneficiaryListProps {
  beneficiaries: Beneficiary[]
  isLoading: boolean
}

export const BeneficiariesList = ({ beneficiaries, isLoading }: BeneficiaryListProps) => {
  const navigate = useNavigate()

  const handleClickBeneficiary = (beneficiaryId: string) => {
    navigate(`./${beneficiaryId}`)
  }

  return (
    <ListContainer>
      {isLoading && (
        <FlexItem>
          <Typography>Loading...</Typography>
        </FlexItem>
      )}
      {!beneficiaries.length && !isLoading && (
        <FlexItem>
          <Typography>{"No beneficiaries found."}</Typography>
        </FlexItem>
      )}
      {beneficiaries.map((beneficiary) => (
        <BeneficiaryItem key={beneficiary.id} onClick={() => handleClickBeneficiary(beneficiary.id)}>
          <FlexContainer $align="center" $gap="1rem">
            <BeneficiaryDetails>
              <BeneficiaryLabel>{beneficiary.fullName}</BeneficiaryLabel>
              <SubBeneficiaryText>{beneficiary.bankName}</SubBeneficiaryText>
              <BeneficiaryIBAN>{beneficiary.iban}</BeneficiaryIBAN>
            </BeneficiaryDetails>
          </FlexContainer>
        </BeneficiaryItem>
      ))}
    </ListContainer>
  )
}
