import { ArrowDownRightIcon, ArrowUpRightIcon, Typography } from "@hero/krypton"
import { useNavigate } from "react-router-dom"
import styled from "styled-components"
import { FlexContainer, FlexItem } from "../../../../../../00_shared/components/Flex"
import { LedgerMovementFlow } from "../../../../00_shared/business"
import { IconContainer } from "../IconContainer"

const ListContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 0.5rem 1rem;
`

const TransactionItem = styled.div<{ $isNegative: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 0rem;
  background: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.black.$900};
  text-decoration: none;
  opacity: 1;
  cursor: pointer;
`

const TransactionDetails = styled.div`
  display: flex;
  flex-direction: column;
`

const TransactionLabel = styled(Typography).attrs({ $variant: "body-4-medium" })``

const SubTransactionText = styled(Typography).attrs({ $variant: "caption-2" })`
  color: ${({ theme }) => theme.colors.grey.$500};
`

const AmountContainer = styled(Typography).attrs({ $variant: "body-4-medium" })<{ $isNegative: boolean }>`
  color: ${({ theme, $isNegative }) => ($isNegative ? theme.colors.grey.$600 : theme.colors.success.$200)};
`

const TransactionDate = styled(Typography).attrs({ $variant: "caption-2" })`
  color: ${({ theme }) => theme.colors.grey.$500};
`

interface Operations {
  id: string
  creditor: string | undefined
  debtor: string | undefined
  label: string | undefined
  isNegative: boolean
  amount: string
  createdAt: string
  operationType: string
  isExternalTransfer: boolean
  flow: LedgerMovementFlow
  ledgerMovementId: string
}

interface TransactionTableProps {
  operations: Operations[]
  isLoading: boolean
  isAllAccountView: boolean
}

export const TransactionList = ({ operations, isLoading, isAllAccountView }: TransactionTableProps) => {
  const navigate = useNavigate()

  const handleClickTransaction = (args: {
    transactionId: string
    flow: LedgerMovementFlow
    ledgerMovementId: string
  }) => {
    const { transactionId, flow, ledgerMovementId } = args
    navigate(`./${transactionId}`, { state: { transactionId, flow, ledgerMovementId } })
  }

  const handleAllAccountOperationView = (operation: Operations) => {
    if (!operation.isExternalTransfer) {
      return (
        <>
          <TransactionLabel>{operation.isNegative ? operation.debtor : operation.creditor}</TransactionLabel>
          <SubTransactionText>{operation.label}</SubTransactionText>
        </>
      )
    }
    return (
      <>
        <TransactionLabel>{operation.label}</TransactionLabel>
        <SubTransactionText>{operation.isNegative ? operation.debtor : operation.creditor}</SubTransactionText>
      </>
    )
  }

  return (
    <ListContainer>
      {isLoading && (
        <FlexItem>
          <Typography>Loading...</Typography>
        </FlexItem>
      )}
      {!operations.length && !isLoading && (
        <FlexItem>
          <Typography>{"No transactions found."}</Typography>
        </FlexItem>
      )}
      {operations.map((operation) => (
        <TransactionItem
          key={operation.id}
          onClick={() => handleClickTransaction({ ...operation, transactionId: operation.id })}
          $isNegative={operation.isNegative}
        >
          <FlexContainer $align="center" $gap="1rem">
            <IconContainer>{operation.isNegative ? <ArrowDownRightIcon /> : <ArrowUpRightIcon />}</IconContainer>
            <TransactionDetails>
              {isAllAccountView ? (
                handleAllAccountOperationView(operation)
              ) : (
                <TransactionLabel>{operation.label}</TransactionLabel>
              )}
            </TransactionDetails>
          </FlexContainer>
          <FlexContainer $direction="column" $align="flex-end">
            <AmountContainer $isNegative={operation.isNegative}>{operation.amount}</AmountContainer>
            <TransactionDate>{operation.createdAt}</TransactionDate>
          </FlexContainer>
        </TransactionItem>
      ))}
    </ListContainer>
  )
}
