import styled from "styled-components"

const CurrentProgress = styled.progress`
  height: 0.125rem;
  display: block;
  -webkit-appearance: none;

  &::-webkit-progress-bar {
    background-color: ${({ theme }) => theme.colors.grey.$300};
    border-radius: 0.125rem;
  }

  &::-webkit-progress-value {
    background-color: ${({ theme }) => theme.colors.grey.$600};
    border-radius: 0.125rem;
  }

  &::-moz-progress-bar {
    background-color: ${({ theme }) => theme.colors.grey.$300};
    border-radius: 0.125rem;
  }

  &::-moz-progress-value {
    background-color: ${({ theme }) => theme.colors.grey.$600};
    border-radius: 0.125rem;
  }
`

export const ProgressBar: React.FC<{ current: number; max: number }> = ({ current, max }) => {
  return <CurrentProgress value={current} max={max}></CurrentProgress>
}
