import { AppsIcon } from "@hero/krypton"
import styled from "styled-components"

export const LogoMarketplace = styled.img`
  object-fit: cover;
  width: 100%;
  height: 1.5rem;
  max-width: 1.5rem;

  margin-right: 0.2rem;

  padding: 0.1rem;
  border-radius: 1rem;
  border: 1px solid ${({ theme }) => theme.colors.grey.$300};
`

export const NoLogoMarketplaceLogoIcon = styled(AppsIcon)`
  width: 1.55rem;
  height: 1.55rem;

  margin-right: 0.2rem;

  padding: 0.1rem;
  border-radius: 1rem;
  border: 1px solid ${({ theme }) => theme.colors.grey.$300};
`
