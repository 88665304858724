import { Typography } from "@hero/krypton"
import { useNavigate } from "react-router-dom"
import styled from "styled-components"
import { Card } from "../../../../../00_shared/components/Card"
import { FlexItem } from "../../../../../00_shared/components/Flex"
import { ExternalTransfer } from "../hooks/useExternalTransfers"

const CardContainer = styled(Card)`
  display: flex;
  flex-direction: column;
  width: 100%;
`

const CardTransactionHeader = styled.div`
  display: flex;
  gap: 1rem;
  justify-content: space-between;
  padding: 0.5rem 1rem;
  border-bottom: 1px solid ${({ theme }) => theme.colors.grey.$200};
`

const ListContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 0.5rem 1rem;
`

const TransactionItem = styled.div<{ $isNegative: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.black.$900};
  text-decoration: none;
  opacity: 1;
  cursor: pointer;
`

const DetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0.5rem 1rem;
`

const TransactionLabel = styled(Typography).attrs({ $variant: "body-4-medium" })``

const SubTransactionText = styled(Typography).attrs({ $variant: "caption-2" })`
  color: ${({ theme }) => theme.colors.grey.$500};
`

const AmountContainer = styled(Typography).attrs({ $variant: "body-4-medium" })<{ $isNegative: boolean }>`
  color: ${({ theme, $isNegative }) => ($isNegative ? theme.colors.grey.$600 : theme.colors.success.$200)};
`

const TransactionDate = styled(Typography).attrs({ $variant: "caption-2" })`
  color: ${({ theme }) => theme.colors.grey.$500};
`

interface TransferHistoryListProps {
  externalTransfers: ExternalTransfer[]
  isLoading: boolean
}

export const TransferHistoryList = ({ externalTransfers, isLoading }: TransferHistoryListProps) => {
  const navigate = useNavigate()

  const handleClickTransaction = (transactionId: string) => {
    navigate(`./${transactionId}`)
  }

  return (
    <ListContainer>
      {isLoading && (
        <FlexItem>
          <Typography>Loading...</Typography>
        </FlexItem>
      )}
      {!externalTransfers.length && !isLoading && (
        <FlexItem>
          <Typography>{"No transactions found."}</Typography>
        </FlexItem>
      )}
      {externalTransfers.map((transfer) => (
        <TransactionItem
          key={transfer.id}
          onClick={() => handleClickTransaction(transfer.id)}
          $isNegative={transfer.amount.startsWith("-")}
        >
          <CardContainer>
            <CardTransactionHeader>
              <TransactionLabel>{transfer.beneficiary}</TransactionLabel>
              <AmountContainer $isNegative={true}>{`${transfer.amount}`}</AmountContainer>
            </CardTransactionHeader>
            <DetailsContainer>
              <TransactionDate>{`Envoyé le : ${transfer.sentAt}`}</TransactionDate>
              <SubTransactionText>{`Référence : ${transfer.reference ?? "-"}`}</SubTransactionText>
            </DetailsContainer>
          </CardContainer>
        </TransactionItem>
      ))}
    </ListContainer>
  )
}
