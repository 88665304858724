import { InfoIcon, Spinner, Typography } from "@hero/krypton"
import { FC, useState } from "react"
import styled from "styled-components"
import { toKEuros } from "../../../../00_shared/utils/currency.converter"

const InfoTooltipBox = styled.div`
  position: absolute;
  top: 0.75rem;
  right: 0.75rem;
`
const IconTooltip = styled(InfoIcon)`
  width: 1.2rem;
  height: 1.2rem;
`

const InfoTooltipData = styled.div`
  min-width: 16rem;
  position: absolute;
  z-index: 1;
  left: 50%;
  transform: translateX(-50%);
  background-color: ${({ theme }) => theme.colors.grey.$600};
  border-radius: 0.25rem;
  padding: 0.5rem 0.75rem;
`

const CustomTypography = styled(Typography)`
  color: ${({ theme }) => theme.colors.white};
`

const AmountContainer = styled(Typography).attrs(() => ({ as: "div", $variant: "title-3-semibold" }))`
  display: flex;
  justify-content: center;
`

const CaptionKPITypography = styled(Typography).attrs(() => ({ as: "div", $variant: "caption-2" }))`
  color: ${(props) => props.theme.colors.grey.$500};
  display: flex;
  justify-content: center;
  text-align: center;
`

type KPIContainerProps = {
  amount?: number
  loading: boolean
  title: string
  tooltip: string
}

export const KPIContainer: FC<KPIContainerProps> = ({ title, tooltip, loading, amount }) => {
  const [tooltipOpen, setTooltipOpen] = useState(false)
  return (
    <div>
      <AmountContainer>
        <>{loading && <Spinner />}</>
        <>{amount !== undefined && amount !== null && toKEuros(amount)}</>
      </AmountContainer>
      <CaptionKPITypography $variant="caption-2">{title}</CaptionKPITypography>
      <InfoTooltipBox>
        <IconTooltip onMouseEnter={() => setTooltipOpen(true)} onMouseLeave={() => setTooltipOpen(false)} />
        {tooltipOpen && (
          <InfoTooltipData>
            <CustomTypography $variant="body-4-medium">{tooltip}</CustomTypography>
          </InfoTooltipData>
        )}
      </InfoTooltipBox>
    </div>
  )
}
