import { PaymentType } from "../../00_shared/enums/PaymentCore.enum"

export type FeeConfiguration = {
  id: string
  type: PaymentType
  installmentCount: number
  daysBeforeDueDate: number
}

export const hasBNPLFeeConfiguration = (feeConfigurations: FeeConfiguration[]) => {
  return feeConfigurations.some(({ type }) => [PaymentType.PAY_ND, PaymentType.PAY_NX].includes(type))
}

export const findP1XFeeConfigurations = (feeConfigurations: FeeConfiguration[]) => {
  return feeConfigurations.filter(({ type }) => type === PaymentType.PAY_1X)
}

export const validateDemand = (values: {
  amount: number
  reference: string
  paymentFeeConfigurationsIds: string[]
  customer: {
    isIndividual?: boolean
  }
}) => {
  if (values.amount < 0.1 || values.amount > 10000) {
    return false
  }

  if (values.reference.length < 1) {
    return false
  }

  if (!values.customer.isIndividual && !values.paymentFeeConfigurationsIds.length) {
    return false
  }

  return true
}
