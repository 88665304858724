import { gql } from "@apollo/client"
import { useHeroMutation } from "../../../../01_technical/requesting/useHeroMutation/useHeroMutation"

const FREEZE_CARD = gql`
  mutation FreezeCard($cardId: String!) {
    freezeIssuingCard(cardId: $cardId) {
      ... on FreezeIssuingCardOutput {
        success
      }
      ... on GqlHeroError {
        errorCode
        message
      }
    }
  }
`

interface FreezeCardMutationResult {
  success?: boolean
  message?: string
}

interface FreezeCardMutationVariables {
  cardId: string
}

export const useFreezeCard = () => {
  const [mutate, { data, loading, error }] = useHeroMutation<FreezeCardMutationResult, FreezeCardMutationVariables>({
    gqlQuerySchema: FREEZE_CARD,
  })

  const freezeCard = async (cardId: string): Promise<FreezeCardMutationResult | undefined> => {
    try {
      const response = await mutate({
        variables: {
          cardId,
        },
      })
      return response
    } catch (err) {}
  }

  return {
    freezeCard,
    data,
    loading,
    error,
  }
}
