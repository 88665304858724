import { Typography } from "@hero/krypton"
import styled from "styled-components"
import { useDashboardTranslation } from "../../../../01_technical/translations"

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1.5rem 3rem;
  flex: 1;
`

const ContentSection = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  border-radius: 1rem;
  background-color: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.grey.$200};
  box-shadow: ${({ theme }) => theme.shadows.mid};
  padding: 5rem;
`

const InnerSection = styled.div`
  max-width: 22.3rem;
  overflow-wrap: break-word;
  white-space: normal;
`

const TextSection = styled.div`
  margin: 2rem 0;
`

export const EmptyScreen = ({
  imgSrc,
  titleKey,
  descriptionKey,
  action,
}: {
  imgSrc: string
  titleKey: string
  descriptionKey: string
  action?: React.ReactNode
}) => {
  const { t } = useDashboardTranslation()

  return (
    <PageContainer>
      {/* {titlePageKey && <Title $variant="title-3-inter-bold">{t(titlePageKey)}</Title>} */}
      <ContentSection>
        <InnerSection>
          <img src={imgSrc} height="74px" alt="empty icon" />
          <TextSection>
            <Typography $variant="title-2-bold">{t(titleKey)}</Typography>
            <Typography $color="grey.$500" $variant="body-4-regular">
              {t(descriptionKey)}
            </Typography>
          </TextSection>
          {action}
        </InnerSection>
      </ContentSection>
      <div style={{ flex: 1 }} />
    </PageContainer>
  )
}
