import { ErrorBlock } from "@hero/krypton"
import { InactiveAccountError } from "./errors/InactiveAccount.error"
import { UnauthorizedError } from "./errors/Unauthorized.error"

/**
 * @deprecated use useHeroQuery instead
 */
export const ApiErrors = ({
  err,
  strategies,
}: {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  err: any
  strategies?: Record<string, (extensions?: { [key: string]: unknown }) => React.ReactNode>
}) => {
  strategies = {
    UNAUTHORIZED: () => <UnauthorizedError />,
    INACTIVE_ACCOUNT: () => <InactiveAccountError />,
    ...strategies,
  }

  if (!err) {
    return null
  }

  if (err.graphQLErrors) {
    if (err.graphQLErrors.length > 0) {
      const message = err.graphQLErrors[0].message
      const extensions = err.graphQLErrors[0].extensions
      return <ErrorBlock>{strategies?.[message]?.(extensions) ?? message}</ErrorBlock>
    }
  }

  if (err.networkError) {
    return (
      <ErrorBlock>
        {err.networkError.statusCode}
        {(err.networkError.result?.errors as [])?.map(({ message }: { message: string }, index) => {
          return <ErrorBlock key={index}>{message}</ErrorBlock>
        })}
      </ErrorBlock>
    )
  }

  if (err.message) {
    if (strategies?.[err.message]) {
      return <ErrorBlock>{strategies[err.message]()}</ErrorBlock>
    }

    return <ErrorBlock>{err.message}</ErrorBlock>
  }

  return <ErrorBlock>{JSON.stringify(err)}</ErrorBlock>
}
