import { Outlet } from "react-router-dom"
import { AccountLayout } from "./AccountLayout"

export const AccountRoot = () => {
  return (
    <AccountLayout>
      <Outlet />
    </AccountLayout>
  )
}
