import { ArrowLeftIcon, Button, Modal as DefaultModal, Field, toaster, Typography } from "@hero/krypton"
import { useState } from "react"
import styled from "styled-components"
import { useDashboardTranslation } from "../../../../01_technical/translations"
import { centsToEuros, eurosToCents } from "../../../../00_shared/utils/currency.converter"
import { useCardSetMonthlySpendingAmountLimitHook } from "../hooks/useCardSetMonthlySpendingAmountLimit.hook"
import { IssuingCard } from "../business/IssuingCard"
import { track } from "@amplitude/analytics-browser"

const Modal = styled(DefaultModal)`
  display: flex;
  flex-direction: column;
  width: 50%;
  min-height: 30%;
  max-height: unset;
  padding: 3rem:
`

const Container = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  align-items: start;
  gap: 2rem;
  padding: 0 1.5rem 1.5rem 1.5rem;
`

const ModalContent = styled.div`
  display: flex;
  width: 100%;
`

const ModalFooter = styled.div`
  display: flex;
  width: 100%;
  gap: 1rem;
  justify-content: flex-end;
`

const CurrencyFieldWrapper = styled.div`
  position: relative;
  width: 100%;

  &::before {
    content: "€";
    position: absolute;
    left: 10px;
    top: 49%;
    transform: translateY(-50%);
    color: ${({ theme }) => theme.colors.grey.$600};
    font-size: 1rem;
  }

  input {
    padding-left: 1.5rem;
  }
`

interface IssuingCardViewDetailsIframeProps {
  card: IssuingCard
  onClose: () => void
}

const MAX_SPENDING_LIMIT_AMOUNT = 50_000_00

export const IssuingCardEditMonthlySpendingLimitModal = ({ card, onClose }: IssuingCardViewDetailsIframeProps) => {
  const { t } = useDashboardTranslation()
  const [newAmount, setNewAmount] = useState<number>(card.monthlySpendingLimitAmount)

  const { setMonthlySpendingLimits, loading } = useCardSetMonthlySpendingAmountLimitHook()

  const handleNewAmountChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value.trim()

    const invalidValue = inputValue.match("[^0-9]")

    if (invalidValue) {
      return
    }

    const parsedValue = Number(event.target.value)

    if (!isNaN(parsedValue)) {
      const amountInCents = eurosToCents(parsedValue)
      setNewAmount(amountInCents)
    }
  }

  const updateLimits = async () => {
    if (newAmount > MAX_SPENDING_LIMIT_AMOUNT) {
      toaster.error(t("issuing.cards.editMonthlySpendingLimit.limitTooHigh"))
      return
    }

    track("card_validate_monthly_limit_clicked")

    await setMonthlySpendingLimits({
      variables: {
        cardId: card.id,
        monthlySpendingLimitAmount: newAmount,
      },
      onCompleted: (data) => {
        if ("errorCode" in data.setIssuingCardMonthlySpendingLimits) {
          toaster.error(t("issuing.cards.editMonthlySpendingLimit.error"))
          return
        }

        toaster.success(t("issuing.cards.editMonthlySpendingLimit.success"))
        onClose()
      },
      onError: () => {
        toaster.error(t("issuing.cards.editMonthlySpendingLimit.error"))
      },
    })
  }

  return (
    <Modal isOpen={true} onClose={onClose}>
      <Container>
        <div>
          <Typography $variant="title-2-bold">{t("issuing.cards.editMonthlySpendingLimit.title")}</Typography>
          <Typography $variant="body-4-regular">{t("issuing.cards.editMonthlySpendingLimit.description")}</Typography>
        </div>
        <ModalContent>
          <CurrencyFieldWrapper>
            <Field
              fieldLabel={t("issuing.cards.editMonthlySpendingLimit.inputFieldName")}
              onChange={handleNewAmountChange}
              placeholder={t("issuing.cards.editMonthlySpendingLimit.inputPlaceholder")}
              value={newAmount && centsToEuros(newAmount)}
              type="text"
              inputMode="decimal"
              pattern="[0-9]+([.,][0-9]{1,})?"
              $fullWidth
              errorMessage={undefined}
            />
          </CurrencyFieldWrapper>
        </ModalContent>
        <ModalFooter>
          <Button type="button" size="medium" $variant="underline" onClick={onClose}>
            <ArrowLeftIcon />
            {t("issuing.cards.editMonthlySpendingLimit.back")}
          </Button>
          <Button $variant="primary" size="medium" onClick={updateLimits} isLoading={loading}>
            {t("issuing.cards.editMonthlySpendingLimit.confirm")}
          </Button>
        </ModalFooter>
      </Container>
    </Modal>
  )
}
