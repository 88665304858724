/* eslint-disable i18next/no-literal-string */
import { Button, Field, Modal, toaster, Typography } from "@hero/krypton"
import { zodResolver } from "@hookform/resolvers/zod"
import { useForm } from "react-hook-form"
import styled from "styled-components"
import { z } from "zod"
import { IbanSchema, StringSchema } from "../../../../00_shared/utils/commonZodSchemas.utils"
import { HeroApiError } from "../../../../01_technical/requesting/request-error.type"
import { CommonTFunction, useCommonTranslation, useDashboardTranslation } from "../../../../01_technical/translations"
import addBeneficiary from "../images/add-beneficiary.svg"
import { useCreateBeneficiary } from "../requests/useCreateBeneficiary"

const useAddBeneficiaryForm = () => {
  const { t } = useCommonTranslation()
  const formSchema = getBeneficiaryDetailsSchema(t)
  type SchemaType = z.infer<typeof formSchema>
  return useForm<SchemaType>({
    resolver: zodResolver(formSchema),
  })
}

const getBeneficiaryDetailsSchema = (t: CommonTFunction) => {
  return z.object({
    fullName: StringSchema(t),
    iban: IbanSchema(t),
    label: StringSchema(t).optional().or(z.literal("")).nullable().default(null),
  })
}

export const AddBeneficiaryModal = ({
  isOpen,
  closeModal,
}: {
  isOpen: boolean
  closeModal: (beneficiaryId?: string) => void
}) => {
  const { t } = useDashboardTranslation()

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useAddBeneficiaryForm()

  const { mutate, loading } = useCreateBeneficiary()

  const onSubmit = handleSubmit(async (beneficiaryDetails) => {
    try {
      const response = await mutate({
        label: beneficiaryDetails.label,
        fullName: beneficiaryDetails.fullName,
        iban: beneficiaryDetails.iban,
      })

      if (response?.success) {
        toaster.success(t("creditWireTransfer.addBeneficiary.addedSuccessfully"))
        resetFormsAndCloseModal(response.beneficiaryId)
      }
    } catch (error: unknown) {
      if (error instanceof HeroApiError) {
        toaster.error(t("creditWireTransfer.addBeneficiary.creationFailed", { detail: error.translatedMessage }))
      } else {
        toaster.error(t("creditWireTransfer.addBeneficiary.creationFailedUnexpected"))
      }
    }
  })

  const resetFormsAndCloseModal = (beneficiaryId?: string) => {
    reset()
    closeModal(beneficiaryId)
  }

  return (
    <StyledModal isOpen={isOpen} onClose={resetFormsAndCloseModal}>
      <Image src={addBeneficiary} />

      <Spacer $h={"1.5rem"} />

      <Typography as="h2" $variant="title-2-bold">
        {t("creditWireTransfer.addBeneficiary.add")}
      </Typography>

      <Spacer $h={".5rem"} />

      <form name="beneficiaryDetails" onSubmit={onSubmit}>
        <Field
          $fullWidth
          fieldLabel={t("creditWireTransfer.addBeneficiary.labelInputLabel")}
          type="text"
          errorMessage={errors.label?.message}
          {...register("label")}
        />
        <Field
          $fullWidth
          fieldLabel={t("creditWireTransfer.addBeneficiary.nameInputLabel")}
          errorMessage={errors.fullName?.message}
          type="text"
          {...register("fullName")}
        />
        <IbanBicContainer>
          <IbanWrapper>
            <Field
              $fullWidth
              fieldLabel={t("creditWireTransfer.addBeneficiary.ibanInputLabel")}
              type="text"
              errorMessage={errors.iban?.message}
              {...register("iban")}
            />
          </IbanWrapper>
        </IbanBicContainer>
        <SaveButton isLoading={loading} type="submit" size="medium">
          {t("creditWireTransfer.addBeneficiary.saveAndSelect")}
        </SaveButton>
      </form>
    </StyledModal>
  )
}

const IbanBicContainer = styled.div`
  display: flex;
  gap: 1rem;
`

const IbanWrapper = styled.div`
  flex: 4;
`

const SaveButton = styled(Button)`
  width: 100%;
  margin-top: 1rem;
`

const StyledModal = styled(Modal)`
  width: 32rem;
  padding: 2rem;

  > h2 {
    margin-bottom: 1rem;
  }
`

const Spacer = styled.div<{ $h: string }>`
  height: ${({ $h }) => $h};
`

const Image = styled.img`
  width: 6rem;
  height: 6rem;
  margin-top: -2.5rem;
  display: block;
`
