import { MerchantMenuEntry } from "../../merchant.menu.type"

export const CockpitMenuEntry: MerchantMenuEntry = {
  to: "/checkout_cash/cockpit?pageNumber=1",
  key: "menu.list.checkoutAndCash.cockpit",
  visible: true,
  selected: (location) =>
    location.pathname.startsWith("/checkout_cash/cockpit") ||
    location.search.includes(`origin=${encodeURIComponent("/checkout_cash/cockpit")}`),
  "data-test-id": "menu-entry-cockpit",
}
