import { ArrowLeftIcon, Badge, Button, Separator, Typography } from "@hero/krypton"
import React from "react"
import styled from "styled-components"
import { Card, CardContent, CardHeader } from "../../../../../00_shared/components/Card"
import { FlexContainer } from "../../../../../00_shared/components/Flex"
import { toEuros } from "../../../../../00_shared/utils/currency.converter"
import { useCommonTranslation } from "../../../../../01_technical/translations"
import { useBusinessAccountContext } from "../../../businessAccount.context"
import { TransferType, useCreateTransferContext } from "../CreateTransferContext"
import { TransferSentScreen } from "./TransferSent.screen"
import { useConfirmTransfer } from "./useConfirmTransfer"

export const ConfirmTransfer: React.FC = () => {
  const { isBaActive } = useBusinessAccountContext()
  const { t } = useCommonTranslation()
  const { state, goToPreviousStep, clearState } = useCreateTransferContext()

  const { handleValidateTransfer, isTransferSent, loading, transactionCreatedId, transferReference, transferDetails } =
    useConfirmTransfer({
      state,
      clearState,
    })

  if (isTransferSent && transactionCreatedId) {
    return <TransferSentScreen transactionId={transactionCreatedId} transferDetails={transferDetails} />
  }

  return (
    <FlexContainer $direction="column" $gap="2rem">
      <Typography $variant="title-1-bold">{t("internalTransfer.validate.title")}</Typography>
      <Card>
        <CardHeaderContainer>
          {state.selectedTransferType === TransferType.BENEFICIARY && (
            <Typography $variant="body-3-regular">à {state.beneficiaryName}</Typography>
          )}
          {state.selectedTransferType === TransferType.ACCOUNT_TO_ACCOUNT && (
            <Typography $variant="body-3-regular" style={{ textAlign: "center" }}>
              à {state.accountToCreditName}
            </Typography>
          )}
          <Typography $variant="title-1-bold">{toEuros(state.amount)}</Typography>
        </CardHeaderContainer>
        <CardContentContainer>
          <FlexContainer $direction="column" $gap="1rem">
            <FlexContainer $justify="space-between">
              {state.selectedTransferType === TransferType.BENEFICIARY && (
                <>
                  <Label>{t("externalWire.details.beneficiary.label")}</Label>
                  <Typography $variant="body-4-medium">{state.beneficiaryIban}</Typography>
                </>
              )}
              {state.selectedTransferType === TransferType.ACCOUNT_TO_ACCOUNT && (
                <>
                  <Label>{t("externalWire.details.creditor.label")}</Label>
                  <Typography $variant="body-4-medium">{state.accountToCreditIban}</Typography>
                </>
              )}
            </FlexContainer>
            <Separator />
            <FlexContainer $justify="space-between">
              <Label>{t("externalWire.details.debtor")}</Label>
              <Typography $variant="body-4-medium">{state.accountToDebitName}</Typography>
            </FlexContainer>
            <Separator />

            <FlexContainer $justify="space-between">
              <Label>{t("externalWire.details.reference")}</Label>
              <Typography $variant="body-4-medium">{transferReference}</Typography>
            </FlexContainer>
            <Separator />

            <FlexContainer $justify="space-between">
              <Label>{t("externalWire.details.date")}</Label>
              <Typography $variant="body-4-medium">
                {new Date().toLocaleDateString("fr-FR", {
                  day: "numeric",
                  month: "short",
                  year: "numeric",
                })}
              </Typography>
            </FlexContainer>
            <Separator />

            <FlexContainer $justify="space-between">
              <Label>{t("externalWire.details.reception.label")}</Label>
              <Typography $variant="body-4-medium">
                <BadgeContainer>
                  <Badge $variant="marketing">{t("externalTransfer.label.instantTransfer")}</Badge>
                </BadgeContainer>
              </Typography>
            </FlexContainer>
          </FlexContainer>
        </CardContentContainer>
      </Card>
      <FlexContainer $direction="column" $align="center" $gap="2rem">
        <Button
          isLoading={loading}
          $variant="primary"
          size="medium"
          $fullWidth
          onClick={handleValidateTransfer}
          disabled={!isBaActive}
        >
          {t("transfer.details.action.send")}
        </Button>
        <Button type="button" size="medium" $variant="underline" onClick={goToPreviousStep}>
          <ArrowLeftIcon />
          {t("transfer.wizard.label.back")}
        </Button>
      </FlexContainer>
    </FlexContainer>
  )
}

const BadgeContainer = styled.div`
  > * {
    height: unset;
  }
`

const CardHeaderContainer = styled(CardHeader)`
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  border-bottom: 1px dashed ${({ theme }) => theme.colors.grey.$200};
  align-items: start;
  background: linear-gradient(
    180deg,
    ${({ theme }) => theme.colors.white} 0%,
    ${({ theme }) => theme.colors.grey.$100} 100%
  );
`

const CardContentContainer = styled(CardContent)`
  padding: 2rem;
`

const Label = styled(Typography).attrs(() => ({
  $variant: "body-4-regular",
}))`
  color: ${({ theme }) => theme.colors.grey.$500};
`
