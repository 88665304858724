import { Typography } from "@hero/krypton"
import styled from "styled-components"
import { FlexContainer } from "../../../../../../../00_shared/components/Flex"
import { useDashboardTranslation } from "../../../../../../../01_technical/translations"
import { DetailItem, DetailLabel, DetailValue } from "./DetailItem"

const DetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
`

const DetailsTitle = styled(Typography).attrs(() => ({
  $variant: "body-4-medium",
}))`
  color: ${({ theme }) => theme.colors.grey.$600};
`

interface TransactionDetailsProps {
  items: {
    label: string
    value: string
  }[]
}

export const TransactionDetails = ({ items }: TransactionDetailsProps) => {
  const { t } = useDashboardTranslation()
  return (
    <DetailsContainer>
      <DetailsTitle>{t("accounts.transactionInformations.details.title")}</DetailsTitle>
      <FlexContainer $direction="column" $gap="0.2rem">
        {items.map((item, index) => (
          <DetailItem key={index}>
            <DetailLabel>{item.label}</DetailLabel>
            <DetailValue>{item.value}</DetailValue>
          </DetailItem>
        ))}
      </FlexContainer>
    </DetailsContainer>
  )
}
