import { useQuery } from "@apollo/client"

import { unwrapGraphQLResponse } from "../../../../01_technical/requesting/DEPRECATED_graphql.errors"
import {
  GET_SWAN_ACCOUNT,
  GET_SWAN_ACCOUNT_RESPONSE,
  GET_SWAN_ACCOUNT_RESPONSE_SUCCESS,
} from "../../cockpit/cockpitWithNewKPI.requests"

export const useGetSwanAccount = () => {
  const { data, loading, error } = useQuery<GET_SWAN_ACCOUNT_RESPONSE>(GET_SWAN_ACCOUNT, {
    fetchPolicy: "cache-and-network",
  })

  const { error: getSwanAccountFunctionalError, data: swanAccount } =
    unwrapGraphQLResponse<GET_SWAN_ACCOUNT_RESPONSE_SUCCESS>(data?.getMerchantSwanAccount)

  return {
    swanAccount,
    getSwanAccountLoading: loading,
    getSwanAccountTechnicalError: error,
    getSwanAccountFunctionalError,
  }
}
