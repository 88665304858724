import { Button, Modal as DefaultModal, toaster, Typography } from "@hero/krypton"
import styled from "styled-components"
import { useAmplitude } from "../../../../00_shared/hooks/useAmplitude.hook"
import { useDashboardTranslation } from "../../../../01_technical/translations"
import { useIssuingCardInformationHook } from "../../IssuingCardList/IssuingCardInformations.hook"
import { useFreezeCard } from "../hooks/useFreezeCard.hook"
import { useTerminateCard } from "../hooks/useTerminateCard.hook"
import { useUnfreezeCard } from "../hooks/useUnfreezeCard.hook"

const Modal = styled(DefaultModal)`
  width: unset;
  max-height: unset;
  padding: 3rem:
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 2rem;
  padding: 0 1.5rem 1.5rem 1.5rem;
`

export interface ModalCardState {
  isOpen: boolean
  action: "freeze" | "unfreeze" | "terminate"
}

interface UnfreezeCardModalProps {
  cardId: string
  isOpen: ModalCardState["isOpen"]
  action: ModalCardState["action"]
  onClose: () => void
}

export const ActionCardModal = ({ cardId, isOpen, action, onClose }: UnfreezeCardModalProps) => {
  const { t } = useDashboardTranslation()
  const { freezeCard, loading: freezing } = useFreezeCard()
  const { unfreezeCard, loading: unfreezing } = useUnfreezeCard()
  const { terminateCard, loading: terminating } = useTerminateCard()
  const { track } = useAmplitude()
  const { refetch } = useIssuingCardInformationHook(cardId)

  const handleCardAction = async (action: ModalCardState["action"]) => {
    try {
      let result
      switch (action) {
        case "freeze":
          track("card_confirm_freeze_clicked")
          result = await freezeCard(cardId)
          break
        case "unfreeze":
          track("card_confirm_unfreeze_clicked")
          result = await unfreezeCard(cardId)
          break
        case "terminate":
          track("card_confirm_terminate_clicked")
          result = await terminateCard(cardId)
          break
      }
      refetch()
      if (result?.success) {
        toaster.success(t(`issuing.cards.${action}.success`))
      } else {
        toaster.error(t(`issuing.cards.${action}.error`))
      }
      onClose()
    } catch (e) {
      toaster.error(t("issuing.cards.action.error"))
    }
  }

  return (
    <Modal isOpen={isOpen} onClose={() => onClose()}>
      <Container>
        <div>
          {action === "freeze" && (
            <>
              <Typography $variant="title-2-bold">{t("issuing.cards.freezeCardModal.title")}</Typography>
              <Typography $variant="body-4-regular">{t("issuing.cards.freezeCardModal.description")}</Typography>
            </>
          )}
          {action === "unfreeze" && (
            <>
              <Typography $variant="title-2-bold">{t("issuing.cards.unfreezeCardModal.title")}</Typography>
              <Typography $variant="body-4-regular">{t("issuing.cards.unfreezeCardModal.description")}</Typography>
            </>
          )}
          {action === "terminate" && (
            <>
              <Typography $variant="title-2-bold">{t("issuing.cards.terminateCardModal.title")}</Typography>
              <Typography $variant="body-4-regular">{t("issuing.cards.terminateCardModal.description")}</Typography>
            </>
          )}
        </div>
        {action === "freeze" && (
          <Button
            style={{ marginLeft: "auto" }}
            size="medium"
            onClick={() => handleCardAction("freeze")}
            isLoading={freezing}
          >
            {t("issuing.cards.freezeCardModal.confirm")}
          </Button>
        )}
        {action === "unfreeze" && (
          <Button
            style={{ marginLeft: "auto" }}
            size="medium"
            onClick={() => handleCardAction("unfreeze")}
            isLoading={unfreezing}
          >
            {t("issuing.cards.unfreezeCardModal.confirm")}
          </Button>
        )}
        {action === "terminate" && (
          <Button
            style={{ marginLeft: "auto" }}
            size="medium"
            onClick={() => handleCardAction("terminate")}
            isLoading={terminating}
          >
            {t("issuing.cards.terminateCardModal.confirm")}
          </Button>
        )}
      </Container>
    </Modal>
  )
}
