import { gql } from "@apollo/client"
import { forEnumOrElse } from "../../../../../00_shared/utils/enum"
import { useHeroQuery } from "../../../../../01_technical/requesting/useHeroQuery/useHeroQuery"
import { LedgerMovementFlow, OperationType, ProductType } from "../../../00_shared/business"

const GET_BUSINESS_ACCOUNT_OPERATION_DETAILS = gql`
  query GetOperationDetails($operationId: String!) {
    getOperationDetails(operationId: $operationId) {
      ... on GetOperationDetailsOutput {
        id
        label
        debtorName
        creditorName
        amount
        createdAt
        reference
        operationType
        justificativePath
        note
        status {
          initiateAt
          processedAt
          payoutAt
        }
      }
      ... on SimpleApiError {
        errorCode
        message
      }
    }
  }
`

export interface OperationDetails {
  id: string
  label: string
  debtorName: string
  creditorName: string
  amount: number
  createdAt: string
  reference?: string
  operationType: OperationType
  justificativePath?: string
  note?: string
  status: {
    initiateAt?: Date
    processedAt?: Date
    payoutAt?: Date
  }
}

const isThisProductShouldDoTheCall = (flow: LedgerMovementFlow, product: ProductType) => {
  return forEnumOrElse(
    flow,
    {
      ISSUING_RESERVATION: () => {
        if (product === ProductType.ISSUING) {
          return true
        }
      },
    },
    () => {
      if (product === ProductType.BUSINESS_ACCOUNT) {
        return true
      }
    },
  )
}

export const useOperationDetails = (args: {
  operationId: string
  flow: LedgerMovementFlow
  ledgerMovementId: string
}) => {
  const { data, refetch, loading, error } = useHeroQuery<OperationDetails>({
    gqlQuerySchema: GET_BUSINESS_ACCOUNT_OPERATION_DETAILS,
    variables: {
      operationId: args.operationId,

      ledgerMovementId: args.ledgerMovementId,
    },
    fetchPolicy: "cache-and-network",
    skip: !args.operationId || !isThisProductShouldDoTheCall(args.flow, ProductType.BUSINESS_ACCOUNT),
  })

  /**** ISSUING ADD YOUR OWN ENDPOINT HERE.
   * Do Not Forget to add the ThisProductShouldDoTheCall(args.flow, ProductType.ISSUING) in skip
   *
   * */
  if (isThisProductShouldDoTheCall(args.flow, ProductType.ISSUING)) {
    return {
      operationDetails: {
        id: "SOON CARD DETAILS",
        label: "SOON CARD DETAILS",
        debtorName: "SOON CARD DETAILS",
        creditorName: "SOON CARD DETAILS",
        amount: 0,
        createdAt: "SOON CARD DETAILS",
        reference: "SOON CARD DETAILS",
        operationType: OperationType.ACCOUNT_TO_ACCOUNT_IN,
        justificativePath: undefined,
        note: undefined,
        status: {
          initiateAt: new Date(),
          processedAt: new Date(),
          payoutAt: new Date(),
        },
      },
      data: null,
      loading: false,
      error: null,
      refetch: () => {
        return
      },
    }
  }
  /**************************** ISSUING UPDATE ABOVE THIS LINE ****************************/

  return {
    operationDetails: data,
    data,
    loading,
    error,
    refetch,
  }
}
