import { createContext, useContext } from "react"

type AuthLayoutContextType = {
  animationStatus: "not_started" | "in_progress" | "done"
  triggerAnimation: () => Promise<void>
}

export const AuthLayoutContext = createContext<AuthLayoutContextType>({} as AuthLayoutContextType)

export const useAuthLayoutContext = () => useContext(AuthLayoutContext)
