import { Button, ChevronDownIcon, ChevronUpIcon, FloatingBox } from "@hero/krypton"
import { useCallback, useState } from "react"
import styled from "styled-components"
import {
  CountryFlag,
  languageKeyByCountryCode,
  LanguageSelectMenuEntry,
} from "../00_shared/components/LanguageSelector"
import useMediaQuery from "../00_shared/hooks/useMediaQuery.hook"
import { AvailableLanguage, EnabledLanguages, useCommonTranslation } from "../01_technical/translations"
import gradientBar from "../assets/gradient-bar.png"
import heroIllustration from "../assets/hero-illu.png"
import { AuthLayoutContext } from "./auth-layout.context"

const Main = styled.main`
  display: flex;
  height: 100%;
  width: 100%;
`

const LeftContainer = styled.div<{ $animationTriggered: boolean }>`
  display: flex;
  align-items: center;
  width: 50%;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 2.5rem;
  transition: width 1s;
  width: ${({ $animationTriggered }) => (!$animationTriggered ? "50%" : "0%")};
  .display-container {
    display: ${({ $animationTriggered }) => ($animationTriggered ? "none" : "flex")};
    flex: 1;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  .language-container {
    display: ${({ $animationTriggered }) => ($animationTriggered ? "none" : "flex")};
  }
  @media (max-width: 768px) {
    width: 100%;
  }
`

const RightContainer = styled.div<{ $animationTriggered: boolean }>`
  background: linear-gradient(180deg, #27292d, #000);
  width: 50%;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  @media (max-width: 768px) {
    display: none;
  }
  opacity: ${({ $animationTriggered }) => ($animationTriggered ? 0.6 : 1)};
  transition: opacity 1s;
`

const RightImageContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: auto;
  & img {
    width: 80%;
  }
`

const ColoredBar = styled.div`
  height: 1.5rem;
  margin-top: auto;
  & img {
    max-width: 100%;
    height: inherit;
  }
`

const MobileColoredBarV2 = styled.div`
  @media (max-width: 768px) {
    height: 1.5rem;
    & img {
      max-width: 100%;
      height: inherit;
    }
  }
  @media (min-width: 769px) {
    display: none;
  }
`

const ChangeLanguageContainer = styled.div`
  display: flex;
  align-self: flex-end;
  margin-top: 1rem;
  @media (min-width: 769px) {
    margin-right: 1rem;
    > dialog {
      width: 14rem;
    }
  }
  @media (max-width: 768px) {
    margin-right: 2rem;
    > button {
      min-width: 0;
    }
    > dialog {
      width: 8rem;
      > a {
        margin: 0;
      }
    }
    & span {
      text-align: end;
      > div {
        width: revert;
      }
    }
  }
`

export const AuthLayout = ({ children }: { children?: React.ReactNode }) => {
  const isMobile = useMediaQuery("(max-width: 768px)")
  const [animationStatus, setAnimationStatus] = useState<"not_started" | "in_progress" | "done">("not_started")

  const triggerAnimation = useCallback(() => {
    if (isMobile) {
      setAnimationStatus("done")
      return Promise.resolve()
    }
    setAnimationStatus("in_progress")
    return new Promise<void>((resolve) => {
      setTimeout(() => {
        setAnimationStatus("done")
        resolve()
      }, 1000)
    })
  }, [isMobile])

  const { t, i18n } = useCommonTranslation()

  const [anchorElement, setAnchorEl] = useState<HTMLButtonElement>()
  const isOpen = Boolean(anchorElement)

  const setLang = (code: string) => {
    i18n.changeLanguage(code)
  }

  const handleClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setAnchorEl(anchorElement ? undefined : e.currentTarget)
  }

  const handleLanguageClick = (code: AvailableLanguage) => {
    setLang(code)
    setAnchorEl(undefined)
  }

  return (
    <AuthLayoutContext.Provider
      value={{
        animationStatus,
        triggerAnimation,
      }}
    >
      <Main>
        <LeftContainer $animationTriggered={animationStatus !== "not_started"}>
          <MobileColoredBarV2>
            <img src={gradientBar} alt="" />
          </MobileColoredBarV2>
          <ChangeLanguageContainer className="language-container">
            <Button
              onClick={handleClick}
              $variant="tertiary"
              isLoading={false}
              rightIcon={isOpen ? ChevronUpIcon : ChevronDownIcon}
            >
              <CountryFlag countryCode={i18n.language as AvailableLanguage} />
              {isMobile ? "" : t(languageKeyByCountryCode[i18n.language as AvailableLanguage])}
            </Button>
            <FloatingBox anchorElement={anchorElement} onClose={() => setAnchorEl(undefined)} isOpen={isOpen}>
              {EnabledLanguages.map((code) => (
                <LanguageSelectMenuEntry
                  mode="dark"
                  key={code}
                  countryCode={code}
                  onClick={() => handleLanguageClick(code)}
                  selected={i18n.language === code}
                  displayLanguageName={!isMobile}
                />
              ))}
            </FloatingBox>
          </ChangeLanguageContainer>
          <div className="display-container">{children}</div>
        </LeftContainer>
        <RightContainer $animationTriggered={animationStatus !== "not_started"}>
          <RightImageContainer>
            <img src={heroIllustration} alt="" />
          </RightImageContainer>
          <ColoredBar>
            <img src={gradientBar} alt="" />
          </ColoredBar>
        </RightContainer>
      </Main>
    </AuthLayoutContext.Provider>
  )
}
