import { createContext, FC, ReactNode, useCallback, useContext, useState } from "react"
import { FormProvider } from "react-hook-form"
import { FeeConfiguration } from "./CreateLink.business"
import { useGetPaymentLink } from "./CreateLink.requests"
import { useCreateLinkForm } from "./CreateLink.utils"

type PaymentLink = {
  id: string
  isDraft: boolean
}

type CreateLinkContextType = {
  feeConfigurations: FeeConfiguration[]
  fetchPaymentLink: (id: string) => void
  paymentLink?: PaymentLink | null
  setPaymentLink: (link: PaymentLink) => void
  documentFile: File | undefined
  setDocumentFile: (file: File | undefined) => void
}

const CreateLinkContext = createContext<CreateLinkContextType>({} as CreateLinkContextType)

export const useCreateLinkContext = () => useContext(CreateLinkContext)

export const CreateLinkProvider: FC<{ children: ReactNode; feeConfigurations: FeeConfiguration[] }> = ({
  children,
  feeConfigurations,
}) => {
  const [paymentLink, setPaymentLink] = useState<PaymentLink | null>()
  const [documentFile, setDocumentFile] = useState<File | undefined>(undefined)

  const form = useCreateLinkForm({ paymentFeeConfigurationsIds: feeConfigurations.map(({ id }) => id) })

  const [getPaymentLink] = useGetPaymentLink()

  const fetchPaymentLink = useCallback(
    async (paymentLinkId: string) => {
      const { data } = await getPaymentLink({
        variables: {
          paymentLinkId,
        },
      })

      if (data && "id" in data.getPaymentLink) {
        setPaymentLink({
          id: data.getPaymentLink.id,
          isDraft: data.getPaymentLink.isDraft,
        })
        // restore form values
        form.setValue("customer.id", data.getPaymentLink.customer.id)
        form.setValue("customer.name", data.getPaymentLink.customer.name)
        form.setValue("customer.email", data.getPaymentLink.customer.email)
        form.setValue("customer.isIndividual", data.getPaymentLink.customer.isIndividual)
        form.setValue("informClientEmail", data.getPaymentLink.customer.email)
        return
      }

      setPaymentLink(null)
    },
    [form, getPaymentLink],
  )

  return (
    <CreateLinkContext.Provider
      value={{ feeConfigurations, fetchPaymentLink, paymentLink, setPaymentLink, documentFile, setDocumentFile }}
    >
      <FormProvider {...form}>{children}</FormProvider>
    </CreateLinkContext.Provider>
  )
}
