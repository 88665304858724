import { useEffect, useMemo } from "react"
import { useLocation, useSearchParams } from "react-router-dom"
import { AmountState, useAmountFilter, UseAmountFilterConfig } from "./useAmountFilter"
import { DateState, useDateFilter, UseDateFilterConfig } from "./useDateFilter"
import { MethodState, useMethodFilter, UseMethodFilterConfig } from "./useMethodFilter"
import { TypeState, useTypeFilter, UseTypeFilterConfig } from "./useTypeFilter"

export type SearchParams = {
  page: number
  amountUnder?: number
  amountOver?: number
  amountEqual?: number
  dateFrom?: string
  dateTo?: string
  transactionType?: string[]
  method?: string[]
}

interface UseTransactionFiltersConfig {
  amount: UseAmountFilterConfig
  date: UseDateFilterConfig
  method: UseMethodFilterConfig
  type: UseTypeFilterConfig
}

interface FiltersState {
  amountState: AmountState
  dateState: DateState
  methodState: MethodState
  typeState: TypeState
  clearFilters: () => void
  applyAllFilters: () => void
  hasActiveFilters: boolean
}

export const useTransactionFilters = (
  onParamsChange: (params: SearchParams) => void,
  config: UseTransactionFiltersConfig,
): FiltersState => {
  const location = useLocation()
  const [searchParams, setSearchParams] = useSearchParams()

  const updateUrlParams = (params: Partial<SearchParams>) => {
    const newParams = new URLSearchParams(location.search)
    Object.keys(params).forEach((key) => {
      const paramKey = key as keyof SearchParams
      const value = params[paramKey]
      if (value !== undefined) {
        if (Array.isArray(value)) {
          if (value.length > 0) {
            newParams.set(key, value.join(","))
          } else {
            newParams.delete(key)
          }
        } else {
          newParams.set(key, value.toString())
        }
      } else {
        newParams.delete(key)
      }
    })
    newParams.delete("page")
    setSearchParams(newParams.toString())
  }

  const amountState = useAmountFilter(config.amount, updateUrlParams)
  const dateState = useDateFilter(config.date, updateUrlParams)
  const methodState = useMethodFilter(config.method, updateUrlParams)
  const typeState = useTypeFilter(config.type, updateUrlParams)

  const hasActiveFilters = useMemo(
    () =>
      Boolean(
        searchParams.get("amountUnder") ||
          searchParams.get("amountEqual") ||
          searchParams.get("amountOver") ||
          searchParams.get("dateFrom") ||
          searchParams.get("dateTo") ||
          searchParams.getAll("method").length > 0 ||
          searchParams.getAll("transactionType").length > 0,
      ),
    [searchParams],
  )

  const clearFilters = () => {
    amountState.reset(true)
    dateState.reset(true)
    methodState.reset(true)
    typeState.reset(true)

    updateUrlParams({
      amountUnder: undefined,
      amountEqual: undefined,
      amountOver: undefined,
      dateFrom: undefined,
      dateTo: undefined,
      method: undefined,
      transactionType: undefined,
    })
  }

  const applyAllFilters = () => {
    updateUrlParams({
      amountUnder: amountState.amountUnder,
      amountEqual: amountState.amountEqual,
      amountOver: amountState.amountOver,
      dateFrom: dateState.dateFrom,
      dateTo: dateState.dateTo,
      method: methodState.methods,
      transactionType: typeState.types,
    })
  }

  useEffect(() => {
    const urlParams = {
      page: Number(searchParams.get("page")) || 1,
      amountUnder: searchParams.get("amountUnder") ? Number(searchParams.get("amountUnder")) : undefined,
      amountEqual: searchParams.get("amountEqual") ? Number(searchParams.get("amountEqual")) : undefined,
      amountOver: searchParams.get("amountOver") ? Number(searchParams.get("amountOver")) : undefined,
      dateFrom: searchParams.get("dateFrom") || undefined,
      dateTo: searchParams.get("dateTo") || undefined,
      method: searchParams.getAll("method").length ? searchParams.getAll("method") : undefined,
      transactionType: searchParams.getAll("transactionType").length
        ? searchParams.getAll("transactionType")
        : undefined,
    }
    onParamsChange(urlParams)
  }, [location.search, searchParams, onParamsChange])

  return {
    amountState,
    dateState,
    methodState,
    typeState,
    clearFilters,
    applyAllFilters,
    hasActiveFilters,
  }
}
