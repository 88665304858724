import { ApolloError } from "@apollo/client"
import { ErrorBlock } from "@hero/krypton"
import React, { ReactNode } from "react"
import { useDashboardTranslation } from "../translations"
import { GqlHeroError, ValidationGraphQLError } from "./request-error.type"
import { isGqlHeroError, isValidationError } from "./request-error.utils"

/**
 * @deprecated use useHeroQuery instead
 */
export const unwrapGraphQLResponse = <DataType,>(
  response: DataType | GqlHeroError | ValidationGraphQLError | undefined,
):
  | { error: GqlHeroError; data: undefined }
  | { error: ValidationGraphQLError; data: undefined }
  | { error: undefined; data: DataType }
  | { error: undefined; data: undefined } => {
  if (response === undefined) {
    return { error: undefined, data: undefined }
  }
  if (isGqlHeroError(response) || isValidationError(response)) {
    return {
      error: response,
      data: undefined,
    }
  } else if (isValidationError(response)) {
    return {
      error: response,
      data: undefined,
    }
  }
  return { error: undefined, data: response }
}

type Message = string | JSX.Element | ((props: { error: GqlHeroError & { error?: ApolloError } }) => ReactNode)

/**
 * @deprecated use useHeroQuery instead
 */
export const HeroErrorDisplayer: React.FC<{
  err: ApolloError | undefined
  data: unknown | ValidationGraphQLError | GqlHeroError | undefined
  messages?: Record<string, Message>
}> = ({ err, data, messages }) => {
  const { t } = useDashboardTranslation()

  const allMessages: Record<string, Message> = {
    TECHNICAL_ERROR: ({ error }) => t("errors.unexpected"),
    ...messages,
  }

  const error: (GqlHeroError & { error?: ApolloError }) | undefined = err
    ? {
        errorCode: "TECHNICAL_ERROR",
        message: err.message,
        error: err,
      }
    : isGqlHeroError(data)
      ? data
      : isValidationError(data)
        ? {
            errorCode: "VALIDATION_ERROR",
            message: data.validationErrors.map((validationError) => validationError).join(" "),
          }
        : undefined

  if (error) {
    const message: Message | undefined = allMessages[error.errorCode]

    if (message === undefined) {
      console.error(
        `An error is not displayed well for the user! Please fix the code for this error: ${error.errorCode} - ${error.message}`,
      )
      return (
        <ErrorBlock>
          Une erreur inattendue est survenue. Contactez-nous avec le message : "{error.errorCode}"
        </ErrorBlock>
      )
    }

    const displayedError = typeof message === "function" ? message({ error: error }) : message
    return <ErrorBlock>{displayedError}</ErrorBlock>
  }

  return null
}
