import { gql } from "@apollo/client"
import { GqlHeroError } from "../../../../01_technical/requesting/request-error.type"

enum HeroAccountType {
  "AP" = "AP",
  "CHECKOUT" = "CHECKOUT",
  "LINK" = "LINK",
  "CASH" = "CASH",
}

export type MerchantAccount = {
  id: string
  name: string
  balance: number
  accountType: HeroAccountType
}

type GetMerchantAccountsSuccess = {
  accounts: MerchantAccount[]
  hasBNPLMovements: boolean
  hasAPMovements: boolean
}

export type GetMerchantAccountsResponse = {
  merchantGetAccounts: GetMerchantAccountsSuccess | GqlHeroError
}

export const GET_MERCHANT_ACCOUNTS_QUERY = gql`
  query {
    merchantGetAccounts {
      ... on GetMerchantAccountsOutput {
        accounts {
          id
          name
          balance
          accountType
        }
        hasBNPLMovements
        hasAPMovements
      }

      ... on GqlHeroError {
        errorCode
        message
      }
    }
  }
`

export const SCHEDULE_AP_ACCOUNTING_SPREADSHEET_MUTATION = gql`
  mutation scheduleAPAccountingSpreadsheetGeneration($options: ScheduleAPAccountingSpreadsheetGenerationInput!) {
    scheduleAPAccountingSpreadsheetGeneration(options: $options) {
      ... on ScheduleAPAccountingSpreadsheetGenerationSuccess {
        success
      }

      ... on GqlHeroError {
        errorCode
        message
      }

      ... on ValidationErrors {
        validationErrors {
          validationError
        }
      }
    }
  }
`

export const SCHEDULE_BNPL_ACCOUNTING_SPREADSHEET_MUTATION = gql`
  mutation scheduleBNPLAccountingSpreadsheetGeneration($options: ScheduleBNPLAccountingSpreadsheetGenerationInput!) {
    scheduleBNPLAccountingSpreadsheetGeneration(options: $options) {
      ... on ScheduleBNPLAccountingSpreadsheetGenerationSuccess {
        success
      }

      ... on GqlHeroError {
        errorCode
        message
      }

      ... on ValidationErrors {
        validationErrors {
          validationError
        }
      }
    }
  }
`
