import { Header, Tab as KryptonTab, Tabs, Typography } from "@hero/krypton"
import styled from "styled-components"
import { useDashboardTranslation } from "../../../../01_technical/translations"
import { LogoMarketplace, NoLogoMarketplaceLogoIcon } from "../../00_shared/components/MarketplaceTabsLogos"

const HeaderContentWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`

interface ActiveMRFScreenHeaderProps {
  selectedTab: string
  setSelectedTab: (tab: string) => void
  mrfs: {
    id: string
    logoUrl?: string
    name: string
  }[]
  updatedAt?: Date
}

export const ActiveMRFScreenHeader = ({
  selectedTab,
  setSelectedTab,
  mrfs: marketplaces,
  updatedAt,
}: ActiveMRFScreenHeaderProps) => {
  const { t } = useDashboardTranslation()

  return (
    <Header>
      <HeaderContentWrapper>
        <Typography $variant="title-3-semibold">{t("ap.mrf.title")}</Typography>
      </HeaderContentWrapper>
      <Tabs>
        {marketplaces.map((marketplace) => (
          <Tab
            key={marketplace.id} //
            marketplace={marketplace}
            selectedTab={selectedTab}
            setSelectedTab={setSelectedTab}
          />
        ))}
      </Tabs>
    </Header>
  )
}

interface TabProps {
  selectedTab: string
  setSelectedTab: (tab: string) => void
  marketplace: {
    id: string
    logoUrl?: string
    name: string
  }
}

const Tab = ({ marketplace, selectedTab, setSelectedTab }: TabProps) => {
  return (
    <KryptonTab
      key={marketplace.id}
      $isSelected={selectedTab === marketplace.id}
      onClick={() => setSelectedTab(marketplace.id)}
    >
      {marketplace.logoUrl && <LogoMarketplace src={marketplace.logoUrl} alt={marketplace.name} />}
      {!marketplace.logoUrl && <NoLogoMarketplaceLogoIcon />}
      {marketplace.name}
    </KryptonTab>
  )
}
