import { useHeroQuery } from "../../../../01_technical/requesting/useHeroQuery/useHeroQuery"
import { APStatus } from "../../../../business/enums/AP.enum"
import { LIST_MERCHANT_AP_QUERY, ListMerchantAPsSuccessResponse } from "../Payments.requests"
import { getPaymentsDemoData } from "./paymentsDemoData.utils"

export const useGetListMerchantAps = (
  filters: {
    status: APStatus[]
    marketplaceNames?: string[]
    fundedAmountMin?: number
    fundedAmountMax?: number
    fundedAmountEqual?: number
    createdBefore?: Date
    createdAfter?: Date
  },
  pagination: { pageSize: number; pageNumber: number },
  config: { useDemoData: boolean },
) => {
  const { data, error, loading } = useHeroQuery<ListMerchantAPsSuccessResponse>({
    gqlQuerySchema: LIST_MERCHANT_AP_QUERY,
    variables: {
      filters,
      pagination,
    },
    skip: config.useDemoData,
  })

  if (config.useDemoData) {
    return {
      data: getPaymentsDemoData(filters, pagination),
      error: null,
      loading: false,
    }
  }

  return {
    data,
    error,
    loading,
  }
}
