import { Typography } from "@hero/krypton"
import { ReactNode } from "react"
import styled from "styled-components"
import { toEuros } from "../../../../../00_shared/utils/currency.converter"
import { toInternationalDate } from "../../../../../00_shared/utils/date.converter"
import { useDashboardTranslation } from "../../../../../01_technical/translations"

const History = styled.ul`
  position: relative;
  margin-top: 1.5rem;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  &:before {
    content: "";
    border: 1px dashed ${({ theme }) => theme.colors.grey.$200};
    position: absolute;
    left: 0.25rem;
    top: 0;
    height: 100%;
  }

  li:first-child:after {
    position: absolute;
    content: "";
    display: inline-block;
    height: calc(50% - 0.5rem);
    left: 0.2rem;
    border: 2px solid white;
  }

  > li:last-child:after {
    position: absolute;
    content: "";
    display: inline-block;
    height: calc(50% - 0.5rem);
    left: 0.2rem;
    border: 2px solid white;
    bottom: 0;
  }
`

const HistoryItem = styled.li`
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 0 0 0 1.5rem;
  line-height: 1.5rem;

  &:before {
    position: absolute;
    content: "";
    display: inline-block;
    width: 0.5rem;
    height: 0.5rem;
    background: ${({ theme }) => theme.colors.white};
    border: 1px solid ${({ theme }) => theme.colors.primary.$200};
    left: 0;
    top: 0;
    bottom: 0;
    margin: auto 0;
    border-radius: 10px;
    box-shadow: ${({ theme }) => theme.shadows.mid};
    z-index: 1;
  }
`

const HistoryItemContent = styled.span<{
  isHighlight?: boolean
  highlightColor?: "blue" | "red"
}>`
  background: ${({ theme, isHighlight, highlightColor }) =>
    isHighlight ? theme.colors[highlightColor === "red" ? "danger" : "info"].$100 : "inherit"};
  border: ${({ theme, highlightColor, isHighlight }) =>
    isHighlight ? `1px solid ${theme.colors[highlightColor === "red" ? "danger" : "info"].$200}}` : "none"};
  padding: ${({ isHighlight }) => (isHighlight ? "0.5rem" : "0")};
  border-radius: ${({ isHighlight }) => (isHighlight ? "0.5rem" : "0")};
`

const HistoryItemLabel = styled(Typography)<{
  hightlightColor?: "blue" | "red"
}>`
  color: ${({ theme, hightlightColor }) => theme.colors[hightlightColor === "red" ? "danger" : "info"].$200};
  text-transform: uppercase;
`

const Line = styled.span`
  display: flex;
  column-gap: 0.3rem;
`

const MovementAmount = styled(Typography)<{ amount: number }>`
  color: ${({ theme, amount }) => {
    if (amount > 0) {
      return theme.colors.primary.$200
    }
    if (amount < 0) {
      return theme.colors.danger.$200
    }
    return theme.colors.grey.$600
  }};
`

const isValidAmount = (amount?: number): amount is number => {
  return Number.isFinite(amount)
}

const TransferDetailsHistoryLine: React.FC<{
  amount: number
  label: string
  isMovement?: boolean
}> = ({ amount, label, isMovement }) => {
  return (
    <Line>
      <Typography $variant="body-4-medium">{label} : </Typography>
      {isMovement ? (
        <MovementAmount $variant="body-4-regular" amount={amount}>
          {amount > 0 && "+"}
          {toEuros(amount)}
        </MovementAmount>
      ) : (
        <Typography $variant="body-4-regular">{toEuros(amount)}</Typography>
      )}
    </Line>
  )
}

export const TransferDetailsHistoryItem: React.FC<{
  id?: string
  createdAt?: string
  label?: string
  amount?: number
  isMovement?: boolean
  balanceAfter?: number
  isHighlight?: boolean
  highlightColor?: "blue" | "red"
}> = ({ id, createdAt, label, amount, isHighlight, isMovement, balanceAfter, highlightColor }) => {
  const { t, i18n } = useDashboardTranslation()

  const date = createdAt && toInternationalDate({ date: createdAt, language: i18n.language })

  return (
    <HistoryItem>
      <HistoryItemContent highlightColor={highlightColor} isHighlight={isHighlight}>
        {isHighlight && (
          <HistoryItemLabel hightlightColor={highlightColor} $variant="label-1">
            {t("ap.payment.history.highlight")}
          </HistoryItemLabel>
        )}
        {isValidAmount(amount) && label && (
          <TransferDetailsHistoryLine label={label} amount={amount} isMovement={isMovement} />
        )}
        {isValidAmount(balanceAfter) && (
          <TransferDetailsHistoryLine label={t("ap.payment.history.balanceAfterOperation")} amount={balanceAfter} />
        )}
        {!!(date && id) && (
          <Typography $variant="caption-2">
            {/* eslint-disable-next-line i18next/no-literal-string */}
            {t("ap.payment.history.datePrefix")} {date} - ID : {id}
          </Typography>
        )}
      </HistoryItemContent>
    </HistoryItem>
  )
}

export const TransferDetailsHistory: React.FC<{ children: ReactNode }> = ({ children }) => {
  return <History>{children}</History>
}
