import { gql } from "@apollo/client"
import { GqlHeroError } from "../../../01_technical/requesting/request-error.type"

export type GET_MARKETPLACES_RESPONSE_SUCCESS = {
  marketplaces: {
    name: string
    accountBalance: number
    logoUrl: string | null
    accountId: string
  }[]
  totalAccountsBalance: number
}

export type GET_BAR_CHART_RESPONSE_SUCCESS = {
  apCaByMarketplaceByDay: BarChartDataType[]
  apFundedByMarketplaceByDay: BarChartDataType[]
  financingRateByMarketplaceByDay: BarChartDataType[]
}

export type GET_MARKETPLACES_RESPONSE = {
  merchantGetMarketplaces: GET_MARKETPLACES_RESPONSE_SUCCESS | GqlHeroError
}

export const GET_MARKETPLACES = gql`
  query merchantGetMarketplaces {
    merchantGetMarketplaces {
      ... on GqlHeroError {
        errorCode
        message
      }

      ... on MerchantGetMarketplacesOutput {
        marketplaces {
          name
          accountBalance
          logoUrl
          accountId
        }
        totalAccountsBalance
      }
    }
  }
`

export type GET_KPIS_RESPONSE_SUCCESS = {
  totalPendingApAmount: number
  totalApFundedAmount: number
  totalApBankTransferAmount: number
}

export type GET_KPIS_ARGS = {
  accountId?: string
}

export type GET_KPIS_RESPONSE = {
  merchantGetAcceleratedPayoutKPIs: GET_KPIS_RESPONSE_SUCCESS | GqlHeroError
}

export const GET_KPIS = gql`
  query merchantGetAcceleratedPayoutKPIs($accountId: String) {
    merchantGetAcceleratedPayoutKPIs(accountId: $accountId) {
      ... on GqlHeroError {
        errorCode
        message
      }

      ... on MerchantGetAcceleratedPayoutKPIsOutput {
        totalPendingApAmount
        totalApFundedAmount
        totalApBankTransferAmount
      }
    }
  }
`

export type GET_MRF_KPIS_ARGS = {
  accountId?: string
}

export type GET_MRF_KPIS_RESPONSE_SUCCESS = {
  dueAmount: number
  fundedAmount: number
}

export type GET_MRF_KPIS_RESPONSE = {
  merchantGetMarketplaceReserveFundingKPIs: GET_MRF_KPIS_RESPONSE_SUCCESS | GqlHeroError
}

export const GET_MRF_KPIS = gql`
  query merchantGetMarketplaceReserveFundingKPIs($accountId: String) {
    merchantGetMarketplaceReserveFundingKPIs(accountId: $accountId) {
      ... on GetMarketplaceReserveFundingKPIs {
        dueAmount
        fundedAmount
      }

      ... on GqlHeroError {
        errorCode
        message
      }
    }
  }
`

export type GET_BAR_CHART_ARGS = {
  startDate: Date
  endDate: Date
}

export type BarChartDataType = {
  createdDate: string
  dayTotal: number | null
  amountByMarketplace: AmountByMarketplace[]
}

export type AmountByMarketplace = {
  marketplace: string
  amount: number | null
}

export type GET_BAR_CHART_RESPONSE = {
  merchantGetApBarChartDataV2: GET_BAR_CHART_RESPONSE_SUCCESS | GqlHeroError
}

export const GET_BAR_CHART_DATA = gql`
  query merchantGetApBarChartData($startDate: DateTime!, $endDate: DateTime!) {
    merchantGetApBarChartDataV2(startDate: $startDate, endDate: $endDate) {
      ... on MerchantGetApBarChartDataOutputV2 {
        apCaByMarketplaceByDay {
          createdDate
          dayTotal
          amountByMarketplace {
            marketplace
            amount
          }
        }
        apFundedByMarketplaceByDay {
          createdDate
          dayTotal
          amountByMarketplace {
            marketplace
            amount
          }
        }
        financingRateByMarketplaceByDay {
          createdDate
          dayTotal
          amountByMarketplace {
            marketplace
            amount
          }
        }
      }
      ... on GqlHeroError {
        errorCode
        message
      }
    }
  }
`

export const GET_SWAN_ACCOUNT = gql`
  query getMerchantSwanAccount {
    getMerchantSwanAccount {
      ... on MerchantSwanAccount {
        id
        hasSwanStandingOrderEnabled
        standingOrderConsentUrl
        hasSwanAcccountFullyOnboarded
      }

      ... on GqlHeroError {
        errorCode
        message
      }
    }
  }
`

export type GET_SWAN_ACCOUNT_RESPONSE_SUCCESS = {
  id: string
  hasSwanStandingOrderEnabled: boolean
  standingOrderConsentUrl: string | null
  hasSwanAcccountFullyOnboarded: boolean
}

export type GET_SWAN_ACCOUNT_RESPONSE = {
  getMerchantSwanAccount: GET_SWAN_ACCOUNT_RESPONSE_SUCCESS | GqlHeroError
}
