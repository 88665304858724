import {
  ArrowLeftIcon,
  Button,
  Checkbox,
  ErrorBlock,
  Field,
  FieldTextarea,
  Separator,
  Spinner,
  Toggle,
  Typography,
} from "@hero/krypton"
import { useCallback, useEffect, useState } from "react"
import styled from "styled-components"
import { useDashboardTranslation } from "../../../../../../01_technical/translations"
import { Controller } from "react-hook-form"
import { useCreateLinkFormContext, useNavigateWithScrolling } from "../../CreateLink.utils"
import { useCreateLinkContext } from "../../CreateLinkContext"
import { PaymentType } from "../../../../00_shared/enums/PaymentCore.enum"
import { useParams } from "react-router-dom"
import { FeeConfiguration, hasBNPLFeeConfiguration, validateDemand } from "../../CreateLink.business"

const Container = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  overflow-y: scroll;
  background-color: ${({ theme }) => theme.colors.white};
  padding: 2rem 4rem;
`

const MissingPaymentFeeConfigurationError = styled(ErrorBlock)`
  margin: 0;
`

const Spacer = styled.div<{ $h: string }>`
  height: ${({ $h }) => $h};
`

const Divider = styled(Separator)`
  height: 1px;
`

const Option = styled.div``

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 1rem;
`

const FieldRow = styled.div``

const PaymentTypeHint = styled(Typography)`
  color: ${({ theme }) => theme.colors.grey.$500};
  margin: 0.5rem 0 1rem 0;
`

const Sections = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: 1rem;
  margin-bottom: 3rem;
`

const Section = styled.div`
  color: ${({ theme }) => theme.colors.grey.$600};
  border: 1px solid ${({ theme }) => theme.colors.grey.$200};
  outline: 0 solid ${({ theme }) => theme.colors.grey.$400};
  box-shadow: ${({ theme }) => theme.shadows.light};
  border-radius: 0.5rem;
  width: 100%;
`

const SectionHeader = styled.div<{ $isOpen: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.75rem;

  ${({ $isOpen, theme }) =>
    $isOpen &&
    `
        border-bottom: 1px solid ${theme.colors.grey.$200};
    `}
`

const SectionTitle = styled(Typography)`
  color: ${({ theme }) => theme.colors.grey.$600};
`

const SectionContent = styled.div<{ $isOpen: boolean }>`
  background-color: ${({ theme }) => theme.colors.grey.$100};
  padding: ${({ $isOpen }) => ($isOpen ? "1rem 0.75rem 0.25rem 0.75rem" : "0rem 0.75rem")};
  transition:
    max-height 0.2s ease-in-out,
    padding 0.2s ease-out;
  max-height: ${({ $isOpen }) => ($isOpen ? "1000px" : "0")};
`

const AmountFormSection = () => {
  const { t } = useDashboardTranslation()
  const { register, control, formState, watch } = useCreateLinkFormContext()
  const { feeConfigurations } = useCreateLinkContext()

  const isIndividual = watch("customer.isIndividual")

  const getFeeConfigurationLabel = useCallback(
    (feeConfiguration: FeeConfiguration) => {
      switch (feeConfiguration.type) {
        case PaymentType.PAY_1X:
          return t("bnpl.link.wizard.steps.demand.paymentTypes.p1x")
        case PaymentType.PAY_NX:
          return t("bnpl.link.wizard.steps.demand.paymentTypes.pnx", { n: feeConfiguration.installmentCount })
        case PaymentType.PAY_ND:
          return t("bnpl.link.wizard.steps.demand.paymentTypes.pnd", { d: feeConfiguration.daysBeforeDueDate })
      }
    },
    [t],
  )

  return (
    <>
      <Typography $variant="title-2-bold">{t("bnpl.link.wizard.steps.demand.title")}</Typography>

      <Spacer $h={"1rem"} />

      <FieldRow>
        <Controller
          control={control}
          name="amount"
          render={({ fieldState }) => (
            <Field
              $fullWidth
              fieldLabel={t("bnpl.link.wizard.steps.demand.amount")}
              placeholder={t("bnpl.link.wizard.steps.demand.amountPlaceholder")}
              type="number"
              step="0.01"
              errorMessage={fieldState.error?.message}
              {...register("amount", {
                required: t("formValidation.common.invalid"),
                min: {
                  message: t("formValidation.number.valueTooSmall", { min: 10 }),
                  value: 0.1,
                },
                valueAsNumber: true,
              })}
            />
          )}
        />
      </FieldRow>

      <FieldRow>
        <Field
          $fullWidth
          fieldLabel={t("bnpl.link.wizard.steps.demand.reference")}
          type="text"
          placeholder={t("bnpl.link.wizard.steps.demand.referencePlaceholder")}
          {...register("reference")}
          errorMessage={formState.errors?.reference?.message}
        />
      </FieldRow>

      {!isIndividual && (
        <Section>
          <SectionHeader $isOpen>
            <SectionTitle $variant="body-4-medium">{t("bnpl.link.wizard.steps.demand.paymentType")}</SectionTitle>
          </SectionHeader>

          <SectionContent $isOpen>
            {formState.errors?.paymentFeeConfigurationsIds?.message && (
              <MissingPaymentFeeConfigurationError>
                {t(formState.errors?.paymentFeeConfigurationsIds?.message)}
              </MissingPaymentFeeConfigurationError>
            )}

            {feeConfigurations.map((option, index) => (
              <Option key={index}>
                {index > 0 && <Divider />}
                <Checkbox
                  key={option.id}
                  id={option.id}
                  text={getFeeConfigurationLabel(option)}
                  value={option.id}
                  {...register("paymentFeeConfigurationsIds")}
                />
              </Option>
            ))}

            <PaymentTypeHint $variant="caption-2">
              {t("bnpl.link.wizard.steps.demand.paymentTypesHint")}
            </PaymentTypeHint>
          </SectionContent>
        </Section>
      )}
    </>
  )
}

const OptionsFormSection = () => {
  const { register, formState, setValue, getValues, watch } = useCreateLinkFormContext()
  const { t } = useDashboardTranslation()

  const [isClientSectionOpen, setIsClientSectionOpen] = useState(false)
  const [isNoteSectionOpen, setIsNoteSectionOpen] = useState(false)

  const informClientEmail = watch("informClientEmail")
  const note = watch("note")

  useEffect(() => {
    if (informClientEmail) {
      setIsClientSectionOpen(true)
    }
  }, [informClientEmail])

  useEffect(() => {
    if (note) {
      setIsNoteSectionOpen(true)
    }
  }, [note])

  useEffect(() => {
    const { customer, informClientEmail } = getValues()
    if (informClientEmail === "") {
      setValue("informClientEmail", customer.email)
    }
  }, [getValues, setValue])

  const onInformClientToggle = useCallback(() => {
    const { customer } = getValues()
    setValue("informClientEmail", !isClientSectionOpen ? customer.email : null)
    setIsClientSectionOpen(!isClientSectionOpen)
  }, [getValues, isClientSectionOpen, setValue])

  const onNoteSectionToggle = useCallback(() => {
    if (isNoteSectionOpen) {
      setValue("note", null)
    }
    setIsNoteSectionOpen(!isNoteSectionOpen)
  }, [isNoteSectionOpen, setValue])

  return (
    <>
      <Typography $variant="title-2-bold">{t("bnpl.link.wizard.steps.demand.options")}</Typography>

      <Sections>
        <Section>
          <SectionHeader $isOpen={isClientSectionOpen}>
            <SectionTitle $variant="body-4-medium">
              {t("bnpl.link.wizard.steps.demand.options.informClient")}
            </SectionTitle>

            <Toggle
              id="inform-client"
              aria-label="inform-client"
              checked={isClientSectionOpen}
              onClick={onInformClientToggle}
            />
          </SectionHeader>

          <SectionContent $isOpen={isClientSectionOpen}>
            {isClientSectionOpen ? (
              <Field
                $fullWidth
                fieldLabel={t("bnpl.link.wizard.steps.demand.options.email")}
                placeholder={t("bnpl.link.wizard.steps.demand.options.emailPlaceholder")}
                type="email"
                {...register("informClientEmail")}
                errorMessage={formState.errors?.informClientEmail?.message}
              />
            ) : (
              <input type="hidden" {...register("informClientEmail")} />
            )}
          </SectionContent>
        </Section>

        <Section>
          <SectionHeader $isOpen={isNoteSectionOpen}>
            <SectionTitle $variant="body-4-medium">{t("bnpl.link.wizard.steps.demand.options.addNote")}</SectionTitle>

            <Toggle id="add-note" aria-label="add-note" checked={isNoteSectionOpen} onClick={onNoteSectionToggle} />
          </SectionHeader>

          <SectionContent $isOpen={isNoteSectionOpen}>
            {isNoteSectionOpen && (
              <FieldTextarea
                fullWidth
                fieldLabel={t("bnpl.link.wizard.steps.demand.furtherInformation")}
                placeholder={t("bnpl.link.wizard.steps.demand.options.notePlaceholder")}
                type="text"
                {...register("note")}
                errorMessage={formState.errors?.customer?.email?.message}
              />
            )}
          </SectionContent>
        </Section>
      </Sections>
    </>
  )
}

export const DemandForm = () => {
  const { t } = useDashboardTranslation()
  const { formState, trigger, getValues, setError, clearErrors } = useCreateLinkFormContext()
  const { paymentLink, fetchPaymentLink, feeConfigurations } = useCreateLinkContext()
  const { id: paymentLinkId } = useParams()
  const navigate = useNavigateWithScrolling()

  const handleSubmit = useCallback(async () => {
    await trigger(["amount", "reference", "paymentFeeConfigurationsIds", "informClientEmail"])
    const values = getValues()

    const selectedFeeConfigurations = feeConfigurations.filter((feeConfiguration) =>
      values.paymentFeeConfigurationsIds.includes(feeConfiguration.id),
    )

    if (!validateDemand(values)) {
      return
    }

    if (values.customer.isIndividual === false) {
      if (!values.documentPath && hasBNPLFeeConfiguration(selectedFeeConfigurations)) {
        setError("documentPath", {
          type: "required",
        })
        return
      } else {
        clearErrors("documentPath")
      }
    }

    if (Object.keys(formState.errors).length > 0) {
      return
    }

    navigate(`/collection/new-link/${paymentLinkId}/confirmation`)
  }, [clearErrors, formState.errors, getValues, navigate, paymentLinkId, setError, trigger, feeConfigurations])

  const goToPreviousStep = useCallback(() => {
    navigate(`/collection/new-link/${paymentLinkId}/client`)
  }, [navigate, paymentLinkId])

  useEffect(() => {
    if (!paymentLink && paymentLinkId) {
      fetchPaymentLink(paymentLinkId)
    }
  }, [fetchPaymentLink, paymentLink, paymentLinkId])

  useEffect(() => {
    if (paymentLink?.isDraft === false) {
      navigate(`/collection/new-link/${paymentLinkId}/preview`, { replace: true })
    }
  }, [navigate, paymentLink, paymentLinkId])

  if (!paymentLink) {
    return <Spinner />
  }

  return (
    <Container>
      <AmountFormSection />

      <Spacer $h={"2rem"} />

      <OptionsFormSection />

      <Row>
        <Button isLoading={false} size="medium" onClick={handleSubmit} $fullWidth>
          {t("bnpl.link.wizard.navigation.next")}
        </Button>
      </Row>

      <Row>
        <Button type="button" size="medium" $variant="underline" onClick={goToPreviousStep}>
          <ArrowLeftIcon />
          {t("bnpl.link.wizard.navigation.previous")}
        </Button>
      </Row>
    </Container>
  )
}
