import {
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  InMemoryCache,
  NormalizedCacheObject,
  createHttpLink,
} from "@apollo/client"
import { setContext } from "@apollo/client/link/context"
import { onError } from "@apollo/client/link/error"
import { toaster } from "@hero/krypton"
import * as Sentry from "@sentry/react"
import { useEffect, useMemo, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { API_URI } from "../env_variables"
import { useCommonTranslation } from "./translations"

const httpLink = createHttpLink({ uri: `${API_URI}/graphql`, credentials: "include" })

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem("token")
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    },
  }
})

const getErrorLink = (onErrors: (isError: boolean) => void, t: ReturnType<typeof useCommonTranslation>["t"]) =>
  onError(({ graphQLErrors, networkError, operation, forward }) => {
    try {
      const error = new Error(JSON.stringify({ graphQLErrors, networkError }))
      error.name = "GraphQL Error"
      Sentry.captureException(error)
    } catch (e) {
      console.error("SENTRY_CANNOT_STRINGIFY_ERROR", e)
    }

    if (graphQLErrors || networkError) {
      if (
        graphQLErrors?.find(
          ({ message }) => message === "INVALID_TOKEN" || message === "INVALID_IDENTITY" || message === "NOT_LOGGED",
        ) ||
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        networkError?.result?.message === "INVALID_TOKEN" ||
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        networkError?.result?.message === "INVALID_IDENTITY" ||
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        networkError?.result?.message === "NOT_LOGGED"
      ) {
        onErrors(true)
        if (!localStorage.getItem("token")) {
          return
        }

        localStorage.removeItem("token")
        if (window.location.pathname.startsWith("/signup") || window.location.pathname.startsWith("/login")) {
          return
        }

        toaster.error(t("auth.expiredTokenError"), {
          autoClose: 2000,
        })
      }
    }
  })

const traceChallengeId = new ApolloLink((operation, forward) => {
  return forward(operation).map((response) => {
    if (!response.errors) {
      return response
    }

    const challengeId = operation.getContext().response.headers.get("x-2fa-challenge-id")
    const challengePhone = operation.getContext().response.headers.get("x-2fa-challenge-phone")

    if (!challengeId) {
      return response
    }

    response.errors.map((error) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      error.challengeId = challengeId
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      error.challengePhone = challengePhone

      return error
    })

    return response
  })
})

const getClient = (onErrors: (isError: boolean) => void, t: ReturnType<typeof useCommonTranslation>["t"]) => {
  const client: ApolloClient<NormalizedCacheObject> = new ApolloClient({
    link: ApolloLink.from([getErrorLink(onErrors, t), authLink, traceChallengeId, httpLink]),
    cache: new InMemoryCache({
      typePolicies: {
        User: {
          merge(existing, incoming) {
            return { ...existing, ...incoming }
          },
        },
        Admin: {
          merge(existing, incoming) {
            return { ...existing, ...incoming }
          },
        },
      },
    }),
    queryDeduplication: true,
  })

  return client
}

export const Connect = ({ children }: { children: JSX.Element }) => {
  const { lang } = useParams()
  const navigate = useNavigate()
  const [errors, setErrors] = useState<boolean>(false)
  const { t } = useCommonTranslation()

  const client = useMemo(() => {
    return getClient(setErrors, t)
  }, [t])

  useEffect(() => {
    if (errors) {
      navigate(`${lang ? `/${lang}` : ""}/login?path=${window.location.pathname}${window.location.search}`)
      setErrors(false)
    }
  }, [navigate, errors, lang])

  return (
    <>
      <ApolloProvider client={client}>{children}</ApolloProvider>
    </>
  )
}
