import { gql } from "@apollo/client"
import { ApiError } from "../../../../01_technical/requesting/request-error.type"

export const UPDATE_MERCHANT_USER = gql`
  mutation updateMerchantUser($email: String!, $firstname: String!, $lastname: String!, $phone: String!) {
    updateMerchantUser(user: { email: $email, firstname: $firstname, lastname: $lastname, phone: $phone }) {
      ... on MerchantUpdateUser {
        id
        email
        firstname
        lastname
        phone
      }

      ... on SimpleApiError {
        errorCode
      }
    }
  }
`
type UPDATE_MERCHANT_USER_RESPONSE_SUCCESS = {
  id: string
  email: string
  firstname: string
  lastname: string
  phone: string
}

export type UPDATE_MERCHANT_RESPONSE = {
  updateMerchantUser: UPDATE_MERCHANT_USER_RESPONSE_SUCCESS | ApiError
}

export type UPDATE_MERCHANT_VARIABLES = {
  email: string
  firstname: string
  lastname: string
  phone: string
}
