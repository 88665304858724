export class SwanAccountRules {
  static displayScheduleStandingOrder(
    swanAccount?: Readonly<{
      hasSwanAcccountFullyOnboarded: boolean
      hasSwanStandingOrderEnabled: boolean
    }>,
  ) {
    return swanAccount?.hasSwanAcccountFullyOnboarded === true && swanAccount?.hasSwanStandingOrderEnabled === false
  }
}
