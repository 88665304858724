import { gql } from "@apollo/client"

export type PaymentLinkDocumentUploadUrlResponse = {
  paymentLinkDocumentUploadUrl: {
    signedUrl: string
    path: string
  }
}

export const PAYMENT_LINK_DOCUMENT_UPLOAD_URL = gql`
  query paymentLinkDocumentUploadUrl {
    paymentLinkDocumentUploadUrl {
      signedUrl
      path
    }
  }
`
