import { gql } from "@apollo/client"
import { useHeroMutation } from "../../01_technical/requesting/useHeroMutation/useHeroMutation"
import { useCommonTranslation } from "../../01_technical/translations"

type Success = {
  token: string
}

type MerchantSignupMutationArgs = {
  email: string
  password: string
  phone: string
  merchantTradingName: string
}

const MERCHANT_SIGNUP_MUTATION = gql`
  mutation signup($email: String!, $password: String!, $phone: String!, $merchantTradingName: String!) {
    signup(user: { email: $email, password: $password, phone: $phone, merchantTradingName: $merchantTradingName }) {
      ... on SignupOutput {
        token
      }

      ... on ValidationErrors {
        errorCode
        validationErrors {
          path
          validationError
        }
      }

      ... on SimpleApiError {
        errorCode
      }
    }
  }
`

const getTranslations = (t: ReturnType<typeof useCommonTranslation>["t"]) => ({
  USER_ALREADY_EXISTS: t("auth.signup.error.userAlreadyExists"),
  PASSWORD_TOO_SHORT: t("auth.error.passwordTooShort"),
  PASSWORD_EMPTY: t("auth.error.passwordEmpty"),
  PASSWORD_MISSING_UPPERCASE: t("auth.error.passwordMissingUppercase"),
  PASSWORD_MISSING_LOWERCASE: t("auth.error.passwordMissingLowercase"),
  PASSWORD_MISSING_DIGIT: t("auth.error.passwordMissingDigit"),
  PASSWORD_MISSING_SPECIAL_CHAR: t("auth.error.passwordMissingSpecialChar"),
  WRONG_FORMAT: t("queryError.validationError"),
})

export const useSignupMutation = () => {
  const { t } = useCommonTranslation()
  return useHeroMutation<Success, MerchantSignupMutationArgs>({
    gqlQuerySchema: MERCHANT_SIGNUP_MUTATION,
    translations: getTranslations(t),
  })
}
