import { FieldInput, Typography } from "@hero/krypton"
import { useNavigate, useParams } from "react-router-dom"
import styled from "styled-components"
import { Dialog, DialogContent } from "../../../../00_shared/components/Dialog"
import { ScrollArea } from "../../../../00_shared/components/ScrollArea"
import { useDashboardTranslation } from "../../../../01_technical/translations"
import TopUpIcon from "../../00_shared/icons/top-up-icon.png"
import { AccountInformation } from "../00_shared/components/AccountInformation"
import { useBusinessAccount } from "../00_shared/hooks/useBusinessAccount"

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const FormContainer = styled.div`
  gap: 1rem;
  display: flex;
  flex-direction: column;
`
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const CardNumberInput = styled(FieldInput)`
  width: 100%;
`

const ContentContainer = styled.div`
  width: 30rem;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding-top: 10rem;

  @media (max-width: 768px) {
    width: 100%;
    margin: 0;
    padding: 1rem;
  }
`

const BankingDetailsContainer = styled.div`
  padding: 1rem;
  border: 1px solid ${({ theme }) => theme.colors.grey.$200};
  background: ${({ theme }) => theme.colors.grey.$200};
  border-radius: 0.5rem;
`

const AccountInformationContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const FieldContainer = styled.div`
  display: flex;
  gap: 1rem;
`
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const FieldInputContainer = styled(FieldInput)`
  @media (max-width: 768px) {
    width: 100%;
  }
`

export const AccountTopUp = () => {
  const { accountNb } = useParams<{ accountNb: string }>()
  const { businessAccount, merchantInfo } = useBusinessAccount(accountNb)
  const { t } = useDashboardTranslation()
  const navigate = useNavigate()

  const handleClickClose = () => {
    navigate(-1)
  }

  return (
    <Dialog open={true} onOpenChange={handleClickClose}>
      <DialogContent $disableAnimations={true} width="100%" height="100%">
        <ScrollArea>
          <ContentContainer>
            <img src={TopUpIcon} alt="Alimenter son compte" width={100} />
            <Typography $variant="title-2-bold">{t("accounts.topUp.title")}</Typography>
            <Typography $variant="body-4-regular" $color="grey.$500">
              {t("accounts.topUp.description")}
            </Typography>
            {/* <FormContainer>
              <CardNumberInput
                label={t("accounts.topUp.cardNumber")}
                placeholder={t("accounts.topUp.cardNumberPlaceholder")}
              />
              <FieldContainer>
                <FieldInputContainer label="MM/AA" placeholder="MM/AA" />
                <FieldInputContainer label="CVV" placeholder="CVV" />
              </FieldContainer>

              <Button isLoading={false} $variant="primary">
                {t("accounts.topUp.depositButton")}
              </Button>
            </FormContainer> */}
            {/*
            <Separator>
              <span>{t("accounts.topUp.or")}</span>
            </Separator> */}

            <BankingDetailsContainer>
              <Typography $variant="title-3-semibold">{t("accounts.topUp.bankDetails")}</Typography>
              <AccountInformationContainer>
                <AccountInformation label="IBAN" value={businessAccount?.VIban || ""} canCopy={true} />
                <AccountInformation label="BIC/SWIFT" value={businessAccount?.BIC || ""} canCopy />
                <AccountInformation
                  label={t("accounts.topUp.accountHolder")}
                  value={
                    <>
                      <p>{merchantInfo?.juridicalName}</p>
                      <p>{merchantInfo?.address?.line1}</p>
                      <p>{merchantInfo?.address?.line2}</p>
                      <p>
                        {merchantInfo?.address?.zipCode} {merchantInfo?.address?.city}
                      </p>
                    </>
                  }
                />
              </AccountInformationContainer>
            </BankingDetailsContainer>
          </ContentContainer>
        </ScrollArea>
      </DialogContent>
    </Dialog>
  )
}
