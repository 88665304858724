import * as Sentry from "@sentry/react"
import { useCallback } from "react"
import { GOOGLE_MAPS_API_KEY } from "../../env_variables"

interface FormattedAddress {
  streetNumber: string
  streetName: string
  city: string
  zipCode: string
  country: string
  fullAddress: string
}

interface AddressPrediction {
  placeId: string
  description: string
}

interface AutocompleteResponse {
  suggestions: {
    placePrediction: {
      placeId: string
      text: {
        text: string
      }
    }
  }[]
}

interface PlaceDetailsResponse {
  addressComponents: AddressComponent[]
  formattedAddress: string
}

interface AddressComponent {
  longText: string
  shortText: string
  types: string[]
}

interface GoogleApiError {
  error: {
    code: number
    message: string
    status: string
  }
}

interface UseGooglePlacesReturn {
  getAddressPredictions: (input: string, countries?: string[]) => Promise<AddressPrediction[]>
  getFormattedAddressFromPlaceId: (placeId: string) => Promise<FormattedAddress | null>
}

const GOOGLE_PLACES_BASE_URL = "https://places.googleapis.com/v1/places"

let hasShownApiKeyWarning = false

/**
 * Helper function to return no-op methods when GOOGLE_MAPS_API_KEY is missing.
 */
const createNoOpMethods = (): UseGooglePlacesReturn => {
  if (!hasShownApiKeyWarning) {
    console.warn("GOOGLE_MAPS_API_KEY is missing. Google Places API functions will not work.")
    hasShownApiKeyWarning = true
  }

  return {
    getAddressPredictions: async () => {
      console.warn("getAddressPredictions called, but GOOGLE_MAPS_API_KEY is missing.")
      return []
    },
    getFormattedAddressFromPlaceId: async () => {
      console.warn("getFormattedAddressFromPlaceId called, but GOOGLE_MAPS_API_KEY is missing.")
      return null
    },
  }
}

/**
 * A custom hook for interacting with Google Places API directly via HTTP endpoints.
 * Provides functionality for retrieving place predictions and detailed place information.
 *
 * Example usage is provided at the path `src/Merchant/IssuingCards/IssuingCardCreation/ConfigureCard/components/AddAddressModal.tsx`.
 *
 * API Endpoints:
 * - Autocomplete: `https://places.googleapis.com/v1/places:autocomplete`
 * - Place Details: `https://places.googleapis.com/v1/places/PLACE_ID`
 *
 * @returns `getAddressPredictions`: Function to get autocomplete predictions.
 * @returns `getFormattedAddressFromPlaceId`: Function to get detailed address information by placeId.
 */
export const useGooglePlaces = (): UseGooglePlacesReturn => {
  /**
   * Fetches place predictions based on user input, optionally filtered by country.
   *
   * @param input - The text input for which predictions are requested.
   * @param countries - An optional array of country codes to filter predictions by country.
   * @returns A promise that resolves to an array of `AddressPrediction` objects.
   */
  const getAddressPredictions = useCallback(
    async (input: string, countries?: string[]): Promise<AddressPrediction[]> => {
      try {
        const response = await fetch(`${GOOGLE_PLACES_BASE_URL}:autocomplete?key=${GOOGLE_MAPS_API_KEY}`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            input,
            includedRegionCodes: countries || undefined,
          }),
        })

        if (!response.ok) {
          const errorData: GoogleApiError = await response.json()
          Sentry.captureException(errorData)
          return []
        }

        const data: AutocompleteResponse = await response.json()

        return data.suggestions.map((suggestion) => ({
          placeId: suggestion.placePrediction.placeId,
          description: suggestion.placePrediction.text.text,
        }))
      } catch (error) {
        Sentry.captureException(error)
        return []
      }
    },
    [],
  )

  /**
   * Fetches detailed address information based on placeId using the Google Places API.
   *
   * @param placeId - The unique identifier for the place.
   * @returns A promise that resolves to a `FormattedAddress` object or null if not found.
   */
  const getFormattedAddressFromPlaceId = useCallback(async (placeId: string): Promise<FormattedAddress | null> => {
    try {
      const response = await fetch(
        `${GOOGLE_PLACES_BASE_URL}/${placeId}?key=${GOOGLE_MAPS_API_KEY}&fields=addressComponents,formattedAddress`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        },
      )

      if (!response.ok) {
        const errorData: GoogleApiError = await response.json()
        Sentry.captureException(errorData)
        return null
      }

      const data: PlaceDetailsResponse = await response.json()
      const { addressComponents } = data

      const streetNumber =
        addressComponents.find((component) => component.types.includes("street_number"))?.longText || ""
      const streetName = addressComponents.find((component) => component.types.includes("route"))?.longText || ""
      const city = addressComponents.find((component) => component.types.includes("locality"))?.longText || ""
      const zipCode = addressComponents.find((component) => component.types.includes("postal_code"))?.longText || ""
      const country = addressComponents.find((component) => component.types.includes("country"))?.shortText || ""
      const fullAddress = data.formattedAddress || ""

      return {
        streetNumber,
        streetName,
        city,
        zipCode,
        country,
        fullAddress,
      }
    } catch (error) {
      Sentry.captureException(error)
      return null
    }
  }, [])

  if (!GOOGLE_MAPS_API_KEY) {
    return createNoOpMethods()
  }

  return {
    getAddressPredictions,
    getFormattedAddressFromPlaceId,
  }
}
