import { useHeroMutation } from "../../../../../01_technical/requesting/useHeroMutation/useHeroMutation"
import { gql } from "@apollo/client"

export function useCompleteDraftPaymentLink() {
  return useHeroMutation<CompleteDraftPaymentLinkResponse, CompleteDraftPaymentLinkInput>({
    gqlQuerySchema: COMPLETE_DRAFT_PAYMENT_LINK,
  })
}

type CompleteDraftPaymentLinkInput = {
  input: {
    paymentLinkId: string
    amount: number
    reference: string
    documentPath: string | null
    comment: string | null
    paymentFeeConfigurationsIds: string[]
  }
}

type CompleteDraftPaymentLinkResponse = {
  success: boolean
}

const COMPLETE_DRAFT_PAYMENT_LINK = gql`
  mutation completeDraftPaymentLink($input: CompleteDraftPaymentLinkInput!) {
    completeDraftPaymentLink(input: $input) {
      ... on CompleteDraftPaymentLinkOutput {
        success
      }

      ... on GqlHeroError {
        errorCode
      }

      ... on ValidationErrors {
        validationErrors {
          path
          validationError
        }
      }
    }
  }
`
