import { useQuery } from "@apollo/client"
import { unwrapGraphQLResponse } from "../../../../01_technical/requesting/DEPRECATED_graphql.errors"
import {
  GET_MARKETPLACES,
  GET_MARKETPLACES_RESPONSE,
  GET_MARKETPLACES_RESPONSE_SUCCESS,
} from "../cockpitWithNewKPI.requests"

export const useGetMarketplaces = (config: { useDemoData: boolean }) => {
  const { data, loading, error } = useQuery<GET_MARKETPLACES_RESPONSE>(GET_MARKETPLACES, { skip: config.useDemoData })

  const { error: getMarketplacesFunctionalError, data: marketplaces } =
    unwrapGraphQLResponse<GET_MARKETPLACES_RESPONSE_SUCCESS>(data?.merchantGetMarketplaces)

  if (config.useDemoData) {
    return {
      marketplaces: [
        {
          name: "AMAZON",
          accountBalance: 0,
          logoUrl:
            "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/AMAZON",
          accountId: "account-41988044-aefe-49f5-b1dc-338eba376589",
        },
        {
          name: "LEROY MERLIN",
          accountBalance: 0,
          logoUrl:
            "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/LEROY%20MERLIN",
          accountId: "account-2be825a0-a9dd-4d1e-b86d-da20c83c580e",
        },
      ],
      totalAccountsBalance: 0,
      getMarketplacesLoading: false,
      getMarketplacesTechnicalError: null,
      getMarketplacesFunctionalError: null,
    }
  }

  return {
    marketplaces: marketplaces?.marketplaces,
    totalAccountsBalance: marketplaces?.totalAccountsBalance,
    getMarketplacesLoading: loading,
    getMarketplacesTechnicalError: error,
    getMarketplacesFunctionalError,
  }
}
