import { gql } from "@apollo/client"
import { useMutationWith2fa } from "../../../../Auth/Challenge2fa/useMutationWith2fa"

type IssuingCardGetDetailsIframeArgs = {
  cardId: string
}

type IssuingCardGetDetailsIframeSuccess = {
  getCardDetailsIframes: {
    token: string
    desktopIframe: string
  }
}

type IssuingCardGetDetailsIframeFunctionalError = {
  getCardDetailsIframes: {
    errorCode: string
    errorValue: string
  }
}

type IssuingCardGetDetailsIframe2faFunctionalError = {
  getCardDetailsIframes: {
    errorCode: string
    message: string
    detail: string
  }
}

type IssuingCardGetDetailsIframeResponse =
  | IssuingCardGetDetailsIframeSuccess
  | IssuingCardGetDetailsIframeFunctionalError
  | IssuingCardGetDetailsIframe2faFunctionalError

const ISSUING_CARD_GET_DETAILS_IFRAMES = gql`
  mutation getCardDetailsIframes($cardId: String!) {
    getCardDetailsIframes(cardId: $cardId) {
      ... on CardDetailsIframes {
        token
        desktopIframe
      }
      ... on SimpleApiError {
        errorCode
        message
      }
    }
  }
`

export const useCardDetailsIframeHook = () => {
  const [getCardDetailsIframes, { loading }] = useMutationWith2fa<
    IssuingCardGetDetailsIframeResponse,
    IssuingCardGetDetailsIframeArgs
  >(ISSUING_CARD_GET_DETAILS_IFRAMES)

  return {
    getCardDetailsIframes,
    loading,
  }
}
