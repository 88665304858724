import CenteredLoading from "../../../00_shared/components/CenteredLoading"
import { isGqlHeroError } from "../../../01_technical/requesting/request-error.utils"
import { HeroErrorDisplayer } from "../../../01_technical/requesting/DEPRECATED_graphql.errors"
import { useAuthContext } from "../../../Auth/auth.context"
import { useGetSwanAccount } from "../00_shared/hooks/useGetSwanAccount.hook"
import { SwanAccountRules } from "../00_shared/rules/SwanAccount.rules"
import { NonActiveAPScreen } from "../NonActiveAP.screen"
import { FilledAPCockpitWithNewKPI } from "./components/FilledAPCockpitWithNewKPI"
import { ScheduleStandingOrder } from "./components/ScheduleStandingOrder/ScheduleStandingOrder.screen"

export const Cockpit = () => {
  const { currentUser } = useAuthContext()
  const { swanAccount, getSwanAccountLoading, getSwanAccountFunctionalError, getSwanAccountTechnicalError } =
    useGetSwanAccount()

  if (getSwanAccountLoading) {
    return <CenteredLoading height="screen" />
  }

  const isFunctionalError =
    getSwanAccountFunctionalError &&
    isGqlHeroError(getSwanAccountFunctionalError) &&
    getSwanAccountFunctionalError.errorCode !== "NOT_FOUND"

  if (isFunctionalError || getSwanAccountTechnicalError) {
    return (
      <HeroErrorDisplayer
        err={getSwanAccountTechnicalError}
        data={getSwanAccountFunctionalError}
        messages={{
          UNAUTHORIZED: "Vous n'êtes pas autorisé à consulter ces informations.",
          FUNCTIONAL_ERROR: ({ error }) => error.message,
        }}
      />
    )
  }

  if (SwanAccountRules.displayScheduleStandingOrder(swanAccount)) {
    return (
      <ScheduleStandingOrder
        merchantId={currentUser.merchantId}
        consentUrl={swanAccount?.standingOrderConsentUrl ?? null}
      />
    )
  }

  if (currentUser && !currentUser.canShowAPPages) {
    return <NonActiveAPScreen />
  }

  return <FilledAPCockpitWithNewKPI />
}
