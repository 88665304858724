export const balanceOutgoingFakeData = [
  {
    id: "bktr-08ac5059-0007-4494-8c9e-49199cfe7d91",
    amount: 293643,
    date: "2024-02-15T11:00:00.000Z",
    iban: "6384",
    wasInstant: null,
    logoUrl:
      "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/AMAZON",
    accountLabel: "AMAZON",
    accountNumber: "0037082",
    __typename: "APBankTransfer",
  },
  {
    id: "bktr-e59def7e-9306-4644-a514-bdb3eb236638",
    amount: 313693,
    date: "2024-02-14T11:00:00.000Z",
    iban: "6384",
    wasInstant: null,
    logoUrl:
      "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/AMAZON",
    accountLabel: "AMAZON",
    accountNumber: "0037082",
    __typename: "APBankTransfer",
  },
  {
    id: "bktr-7f69ebb2-7a82-419a-8253-5ba95998e1c2",
    amount: 931170,
    date: "2024-02-13T11:00:00.000Z",
    iban: "6384",
    wasInstant: null,
    logoUrl:
      "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/AMAZON",
    accountLabel: "AMAZON",
    accountNumber: "0037082",
    __typename: "APBankTransfer",
  },
  {
    id: "bktr-5396f0e6-96d1-4db8-a631-d474d3b630cd",
    amount: 324491,
    date: "2024-02-12T11:00:00.000Z",
    iban: "6384",
    wasInstant: null,
    logoUrl:
      "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/AMAZON",
    accountLabel: "AMAZON",
    accountNumber: "0037082",
    __typename: "APBankTransfer",
  },
  {
    id: "bktr-02285fa3-6038-49e6-8d5c-9a275988c5e6",
    amount: 7072,
    date: "2024-02-09T11:16:01.379Z",
    iban: "6384",
    wasInstant: null,
    logoUrl:
      "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/LEROY%20MERLIN",
    accountLabel: "LEROY MERLIN",
    accountNumber: "0036654",
    __typename: "APBankTransfer",
  },
  {
    id: "bktr-59de8556-bfea-460b-85e0-05e56aae9df3",
    amount: 254541,
    date: "2024-02-09T11:00:00.000Z",
    iban: "6384",
    wasInstant: null,
    logoUrl:
      "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/AMAZON",
    accountLabel: "AMAZON",
    accountNumber: "0037082",
    __typename: "APBankTransfer",
  },
  {
    id: "bktr-740e5ad8-150c-4634-9ee8-baf9c2692ed9",
    amount: 152801,
    date: "2024-02-08T11:15:36.940Z",
    iban: "6384",
    wasInstant: null,
    logoUrl:
      "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/LEROY%20MERLIN",
    accountLabel: "LEROY MERLIN",
    accountNumber: "0036654",
    __typename: "APBankTransfer",
  },
  {
    id: "bktr-aadcda0a-7268-44ce-ba6c-9b81bdf5418f",
    amount: 391748,
    date: "2024-02-08T11:00:00.000Z",
    iban: "6384",
    wasInstant: null,
    logoUrl:
      "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/AMAZON",
    accountLabel: "AMAZON",
    accountNumber: "0037082",
    __typename: "APBankTransfer",
  },
  {
    id: "bktr-86ae70c5-7ed8-4691-94e9-172105f706ce",
    amount: 337261,
    date: "2024-02-07T11:49:21.450Z",
    iban: "6384",
    wasInstant: null,
    logoUrl:
      "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/LEROY%20MERLIN",
    accountLabel: "LEROY MERLIN",
    accountNumber: "0036654",
    __typename: "APBankTransfer",
  },
  {
    id: "bktr-5c3009d8-3118-4d64-97d2-6e24a1b0f338",
    amount: 270906,
    date: "2024-02-07T11:00:00.000Z",
    iban: "6384",
    wasInstant: null,
    logoUrl:
      "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/AMAZON",
    accountLabel: "AMAZON",
    accountNumber: "0037082",
    __typename: "APBankTransfer",
  },
  {
    id: "bktr-de150f8e-a83e-4d84-8f74-8a5561c9b83f",
    amount: 231177,
    date: "2024-02-06T12:22:22.744Z",
    iban: "6384",
    wasInstant: null,
    logoUrl:
      "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/LEROY%20MERLIN",
    accountLabel: "LEROY MERLIN",
    accountNumber: "0036654",
    __typename: "APBankTransfer",
  },
  {
    id: "bktr-4bb89623-b753-4428-a97c-2f09df8a72a1",
    amount: 412837,
    date: "2024-02-06T11:00:00.000Z",
    iban: "6384",
    wasInstant: null,
    logoUrl:
      "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/AMAZON",
    accountLabel: "AMAZON",
    accountNumber: "0037082",
    __typename: "APBankTransfer",
  },
  {
    id: "bktr-509185cc-14fc-4bb7-a609-f3d448e32e1f",
    amount: 542152,
    date: "2024-02-05T13:33:06.866Z",
    iban: "6384",
    wasInstant: null,
    logoUrl:
      "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/LEROY%20MERLIN",
    accountLabel: "LEROY MERLIN",
    accountNumber: "0036654",
    __typename: "APBankTransfer",
  },
  {
    id: "bktr-4d146ce1-9a07-48ce-83cf-93380b9d44fb",
    amount: 872243,
    date: "2024-02-05T11:00:00.000Z",
    iban: "6384",
    wasInstant: null,
    logoUrl:
      "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/AMAZON",
    accountLabel: "AMAZON",
    accountNumber: "0037082",
    __typename: "APBankTransfer",
  },
  {
    id: "bktr-4b1c055c-eb9c-4eb6-99f5-1d68b3c251b6",
    amount: 225112,
    date: "2024-02-02T13:06:11.488Z",
    iban: "6384",
    wasInstant: null,
    logoUrl:
      "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/LEROY%20MERLIN",
    accountLabel: "LEROY MERLIN",
    accountNumber: "0036654",
    __typename: "APBankTransfer",
  },
  {
    id: "bktr-b88ea447-27f7-49f4-840e-0343f087225b",
    amount: 682581,
    date: "2024-02-02T11:00:00.000Z",
    iban: "6384",
    wasInstant: null,
    logoUrl:
      "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/AMAZON",
    accountLabel: "AMAZON",
    accountNumber: "0037082",
    __typename: "APBankTransfer",
  },
  {
    id: "bktr-f2fb4908-e3af-4444-8ad5-ea53ae0e4ceb",
    amount: 153050,
    date: "2024-02-01T13:31:48.437Z",
    iban: "6384",
    wasInstant: null,
    logoUrl:
      "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/LEROY%20MERLIN",
    accountLabel: "LEROY MERLIN",
    accountNumber: "0036654",
    __typename: "APBankTransfer",
  },
  {
    id: "bktr-ba61c078-0bad-417f-827d-66dcd062d835",
    amount: 612883,
    date: "2024-02-01T11:00:00.000Z",
    iban: "6384",
    wasInstant: null,
    logoUrl:
      "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/AMAZON",
    accountLabel: "AMAZON",
    accountNumber: "0037082",
    __typename: "APBankTransfer",
  },
  {
    id: "bktr-43ad9679-0300-4308-b656-bc9b19c1a560",
    amount: 322416,
    date: "2024-01-31T11:49:33.904Z",
    iban: "6384",
    wasInstant: null,
    logoUrl:
      "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/LEROY%20MERLIN",
    accountLabel: "LEROY MERLIN",
    accountNumber: "0036654",
    __typename: "APBankTransfer",
  },
  {
    id: "bktr-a41e701c-3584-46de-a780-e0dbcc1d8c39",
    amount: 312131,
    date: "2024-01-31T11:00:00.000Z",
    iban: "6384",
    wasInstant: null,
    logoUrl:
      "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/AMAZON",
    accountLabel: "AMAZON",
    accountNumber: "0037082",
    __typename: "APBankTransfer",
  },
  {
    id: "bktr-be86bb03-682f-4d10-800b-0c0f173f2a4d",
    amount: 118083,
    date: "2024-01-30T11:43:22.702Z",
    iban: "6384",
    wasInstant: null,
    logoUrl:
      "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/LEROY%20MERLIN",
    accountLabel: "LEROY MERLIN",
    accountNumber: "0036654",
    __typename: "APBankTransfer",
  },
  {
    id: "bktr-069795ea-0ba2-4f66-ae49-629c326781ff",
    amount: 761612,
    date: "2024-01-30T11:00:00.000Z",
    iban: "6384",
    wasInstant: null,
    logoUrl:
      "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/AMAZON",
    accountLabel: "AMAZON",
    accountNumber: "0037082",
    __typename: "APBankTransfer",
  },
  {
    id: "bktr-9f1bedef-e119-47e7-8e10-f79418cf1002",
    amount: 225082,
    date: "2024-01-29T12:21:03.775Z",
    iban: "6384",
    wasInstant: null,
    logoUrl:
      "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/LEROY%20MERLIN",
    accountLabel: "LEROY MERLIN",
    accountNumber: "0036654",
    __typename: "APBankTransfer",
  },
  {
    id: "bktr-a48386b8-ccf4-4fce-a267-848244d680b2",
    amount: 463762,
    date: "2024-01-29T11:00:00.000Z",
    iban: "6384",
    wasInstant: null,
    logoUrl:
      "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/AMAZON",
    accountLabel: "AMAZON",
    accountNumber: "0037082",
    __typename: "APBankTransfer",
  },
  {
    id: "bktr-f0b5a9d2-2a99-4a45-bcb9-5f6e66a13616",
    amount: 162968,
    date: "2024-01-26T11:05:26.780Z",
    iban: "6384",
    wasInstant: null,
    logoUrl:
      "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/LEROY%20MERLIN",
    accountLabel: "LEROY MERLIN",
    accountNumber: "0036654",
    __typename: "APBankTransfer",
  },
  {
    id: "bktr-04e894c1-9b4a-4c7d-b46b-a74c97d318c2",
    amount: 432941,
    date: "2024-01-26T11:00:00.000Z",
    iban: "6384",
    wasInstant: null,
    logoUrl:
      "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/AMAZON",
    accountLabel: "AMAZON",
    accountNumber: "0037082",
    __typename: "APBankTransfer",
  },
  {
    id: "bktr-40e0976d-c796-4348-8528-f2e02ebe70bb",
    amount: 246702,
    date: "2024-01-25T11:08:43.396Z",
    iban: "6384",
    wasInstant: null,
    logoUrl:
      "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/LEROY%20MERLIN",
    accountLabel: "LEROY MERLIN",
    accountNumber: "0036654",
    __typename: "APBankTransfer",
  },
  {
    id: "bktr-e0615dd6-dc21-4e73-a738-8311f43e191e",
    amount: 288780,
    date: "2024-01-25T11:00:00.000Z",
    iban: "6384",
    wasInstant: null,
    logoUrl:
      "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/AMAZON",
    accountLabel: "AMAZON",
    accountNumber: "0037082",
    __typename: "APBankTransfer",
  },
  {
    id: "bktr-91f1cf4f-b219-4c13-9ce6-4eadc28c3f9e",
    amount: 982248,
    date: "2024-01-24T13:11:31.362Z",
    iban: "6384",
    wasInstant: null,
    logoUrl:
      "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/LEROY%20MERLIN",
    accountLabel: "LEROY MERLIN",
    accountNumber: "0036654",
    __typename: "APBankTransfer",
  },
  {
    id: "bktr-b1b4bb50-67c6-4508-9881-2fd7a6491580",
    amount: 296535,
    date: "2024-01-24T11:00:00.000Z",
    iban: "6384",
    wasInstant: null,
    logoUrl:
      "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/AMAZON",
    accountLabel: "AMAZON",
    accountNumber: "0037082",
    __typename: "APBankTransfer",
  },
  {
    id: "bktr-df053b38-3d91-4852-b205-d85c3acb9fa5",
    amount: 178596,
    date: "2024-01-23T12:44:35.248Z",
    iban: "6384",
    wasInstant: null,
    logoUrl:
      "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/LEROY%20MERLIN",
    accountLabel: "LEROY MERLIN",
    accountNumber: "0036654",
    __typename: "APBankTransfer",
  },
  {
    id: "bktr-a442e32d-e2dc-438b-9b60-141f810c64ca",
    amount: 1043054,
    date: "2024-01-23T11:00:00.000Z",
    iban: "6384",
    wasInstant: null,
    logoUrl:
      "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/AMAZON",
    accountLabel: "AMAZON",
    accountNumber: "0037082",
    __typename: "APBankTransfer",
  },
  {
    id: "bktr-26cfcf33-5cb9-4811-915b-70cf24804e97",
    amount: 268672,
    date: "2024-01-22T12:14:15.725Z",
    iban: "6384",
    wasInstant: null,
    logoUrl:
      "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/LEROY%20MERLIN",
    accountLabel: "LEROY MERLIN",
    accountNumber: "0036654",
    __typename: "APBankTransfer",
  },
  {
    id: "bktr-4bb55a62-05c1-4bfd-a15b-b978c249a54b",
    amount: 671646,
    date: "2024-01-22T11:00:00.000Z",
    iban: "6384",
    wasInstant: null,
    logoUrl:
      "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/AMAZON",
    accountLabel: "AMAZON",
    accountNumber: "0037082",
    __typename: "APBankTransfer",
  },
  {
    id: "bktr-21e36821-8500-4ebc-91db-293e98b486a8",
    amount: 179205,
    date: "2024-01-19T11:11:37.194Z",
    iban: "6384",
    wasInstant: null,
    logoUrl:
      "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/LEROY%20MERLIN",
    accountLabel: "LEROY MERLIN",
    accountNumber: "0036654",
    __typename: "APBankTransfer",
  },
  {
    id: "bktr-a8d1fd82-f86d-4a8c-b05a-dc9fd79fc026",
    amount: 267597,
    date: "2024-01-19T11:00:00.000Z",
    iban: "6384",
    wasInstant: null,
    logoUrl:
      "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/AMAZON",
    accountLabel: "AMAZON",
    accountNumber: "0037082",
    __typename: "APBankTransfer",
  },
  {
    id: "bktr-117a0a45-0c98-4739-bbba-299da515db16",
    amount: 368255,
    date: "2024-01-18T11:18:55.000Z",
    iban: "6384",
    wasInstant: null,
    logoUrl:
      "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/LEROY%20MERLIN",
    accountLabel: "LEROY MERLIN",
    accountNumber: "0036654",
    __typename: "APBankTransfer",
  },
  {
    id: "bktr-20885390-fd05-4133-a855-7449cb7aab13",
    amount: 285977,
    date: "2024-01-18T11:00:00.000Z",
    iban: "6384",
    wasInstant: null,
    logoUrl:
      "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/AMAZON",
    accountLabel: "AMAZON",
    accountNumber: "0037082",
    __typename: "APBankTransfer",
  },
  {
    id: "bktr-a3867730-bad9-4dff-b98c-136d4024ca92",
    amount: 138708,
    date: "2024-01-17T11:45:03.250Z",
    iban: "6384",
    wasInstant: null,
    logoUrl:
      "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/LEROY%20MERLIN",
    accountLabel: "LEROY MERLIN",
    accountNumber: "0036654",
    __typename: "APBankTransfer",
  },
  {
    id: "bktr-b0134b55-ac34-4b5d-a1a2-6cec4fc72460",
    amount: 337065,
    date: "2024-01-17T11:00:00.000Z",
    iban: "6384",
    wasInstant: null,
    logoUrl:
      "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/AMAZON",
    accountLabel: "AMAZON",
    accountNumber: "0037082",
    __typename: "APBankTransfer",
  },
  {
    id: "bktr-d3a187b9-b463-495d-b4d3-6ed3ef3bcfcf",
    amount: 363194,
    date: "2024-01-16T12:04:04.896Z",
    iban: "6384",
    wasInstant: null,
    logoUrl:
      "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/LEROY%20MERLIN",
    accountLabel: "LEROY MERLIN",
    accountNumber: "0036654",
    __typename: "APBankTransfer",
  },
  {
    id: "bktr-54e4637d-879d-4b78-8690-b66c75a65220",
    amount: 795066,
    date: "2024-01-16T11:00:00.000Z",
    iban: "6384",
    wasInstant: null,
    logoUrl:
      "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/AMAZON",
    accountLabel: "AMAZON",
    accountNumber: "0037082",
    __typename: "APBankTransfer",
  },
  {
    id: "bktr-63cb8d98-db71-4145-816b-e1d11e740b80",
    amount: 747036,
    date: "2024-01-15T12:39:09.204Z",
    iban: "6384",
    wasInstant: null,
    logoUrl:
      "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/LEROY%20MERLIN",
    accountLabel: "LEROY MERLIN",
    accountNumber: "0036654",
    __typename: "APBankTransfer",
  },
  {
    id: "bktr-4985f834-0867-479d-9fb1-0c4655c69638",
    amount: 604657,
    date: "2024-01-15T11:00:00.000Z",
    iban: "6384",
    wasInstant: null,
    logoUrl:
      "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/AMAZON",
    accountLabel: "AMAZON",
    accountNumber: "0037082",
    __typename: "APBankTransfer",
  },
  {
    id: "bktr-da5bd170-6155-4e3b-9666-ec83fb8cbf36",
    amount: 256745,
    date: "2024-01-12T11:10:30.892Z",
    iban: "6384",
    wasInstant: null,
    logoUrl:
      "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/LEROY%20MERLIN",
    accountLabel: "LEROY MERLIN",
    accountNumber: "0036654",
    __typename: "APBankTransfer",
  },
  {
    id: "bktr-23c81bb4-9b04-4713-9051-9896055521ea",
    amount: 364968,
    date: "2024-01-12T11:00:00.000Z",
    iban: "6384",
    wasInstant: null,
    logoUrl:
      "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/AMAZON",
    accountLabel: "AMAZON",
    accountNumber: "0037082",
    __typename: "APBankTransfer",
  },
  {
    id: "bktr-fdc34eae-fe3c-4c49-86bb-a2d1f2ec8c6c",
    amount: 302784,
    date: "2024-01-11T11:00:00.000Z",
    iban: "6384",
    wasInstant: null,
    logoUrl:
      "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/AMAZON",
    accountLabel: "AMAZON",
    accountNumber: "0037082",
    __typename: "APBankTransfer",
  },
  {
    id: "bktr-13348ffc-5e99-4aa8-ac5c-a773d24e55f7",
    amount: 245673,
    date: "2024-01-11T10:57:40.138Z",
    iban: "6384",
    wasInstant: null,
    logoUrl:
      "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/LEROY%20MERLIN",
    accountLabel: "LEROY MERLIN",
    accountNumber: "0036654",
    __typename: "APBankTransfer",
  },
  {
    id: "bktr-ee1b9a46-f234-4838-bdb4-e2066b8032ab",
    amount: 228914,
    date: "2024-01-10T12:58:34.184Z",
    iban: "6384",
    wasInstant: null,
    logoUrl:
      "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/LEROY%20MERLIN",
    accountLabel: "LEROY MERLIN",
    accountNumber: "0036654",
    __typename: "APBankTransfer",
  },
  {
    id: "bktr-907741f3-5ddf-4000-94b7-cd7a3b61511f",
    amount: 284328,
    date: "2024-01-10T11:00:00.000Z",
    iban: "6384",
    wasInstant: null,
    logoUrl:
      "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/AMAZON",
    accountLabel: "AMAZON",
    accountNumber: "0037082",
    __typename: "APBankTransfer",
  },
  {
    id: "bktr-f5d711a0-a293-4a70-b8f3-6e3004c73e34",
    amount: 313315,
    date: "2024-01-09T11:20:38.363Z",
    iban: "6384",
    wasInstant: null,
    logoUrl:
      "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/LEROY%20MERLIN",
    accountLabel: "LEROY MERLIN",
    accountNumber: "0036654",
    __typename: "APBankTransfer",
  },
  {
    id: "bktr-db7e606a-4c39-4ac3-96bd-d41bb7ebbefd",
    amount: 967703,
    date: "2024-01-09T11:00:00.000Z",
    iban: "6384",
    wasInstant: null,
    logoUrl:
      "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/AMAZON",
    accountLabel: "AMAZON",
    accountNumber: "0037082",
    __typename: "APBankTransfer",
  },
  {
    id: "bktr-482eb434-8850-4015-a16d-9400531570ad",
    amount: 290921,
    date: "2024-01-08T13:03:38.558Z",
    iban: "6384",
    wasInstant: null,
    logoUrl:
      "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/LEROY%20MERLIN",
    accountLabel: "LEROY MERLIN",
    accountNumber: "0036654",
    __typename: "APBankTransfer",
  },
  {
    id: "bktr-2dc355fc-5d76-40a2-bac3-18ba84f78c7a",
    amount: 461956,
    date: "2024-01-08T11:00:00.000Z",
    iban: "6384",
    wasInstant: null,
    logoUrl:
      "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/AMAZON",
    accountLabel: "AMAZON",
    accountNumber: "0037082",
    __typename: "APBankTransfer",
  },
  {
    id: "bktr-4dac4dfe-04d2-4840-848f-5a29dfb20136",
    amount: 230160,
    date: "2024-01-05T13:17:53.723Z",
    iban: "6384",
    wasInstant: null,
    logoUrl:
      "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/LEROY%20MERLIN",
    accountLabel: "LEROY MERLIN",
    accountNumber: "0036654",
    __typename: "APBankTransfer",
  },
  {
    id: "bktr-d94573ad-57e0-4f48-bc18-3b1433e4cdf7",
    amount: 322052,
    date: "2024-01-05T11:00:00.000Z",
    iban: "6384",
    wasInstant: null,
    logoUrl:
      "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/AMAZON",
    accountLabel: "AMAZON",
    accountNumber: "0037082",
    __typename: "APBankTransfer",
  },
  {
    id: "bktr-f859b862-3921-4d7c-a898-26b1367fe54f",
    amount: 1066986,
    date: "2024-01-04T12:26:09.714Z",
    iban: "6384",
    wasInstant: null,
    logoUrl:
      "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/LEROY%20MERLIN",
    accountLabel: "LEROY MERLIN",
    accountNumber: "0036654",
    __typename: "APBankTransfer",
  },
  {
    id: "bktr-7f23a329-cf5c-45c6-b728-2080f32f6b2d",
    amount: 370499,
    date: "2024-01-04T11:00:00.000Z",
    iban: "6384",
    wasInstant: null,
    logoUrl:
      "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/AMAZON",
    accountLabel: "AMAZON",
    accountNumber: "0037082",
    __typename: "APBankTransfer",
  },
  {
    id: "bktr-5df23747-a36b-4bfb-b303-8916f135ecfe",
    amount: 286057,
    date: "2024-01-03T13:09:23.069Z",
    iban: "6384",
    wasInstant: null,
    logoUrl:
      "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/LEROY%20MERLIN",
    accountLabel: "LEROY MERLIN",
    accountNumber: "0036654",
    __typename: "APBankTransfer",
  },
  {
    id: "bktr-c3116fb8-6717-4473-9fec-ecac08c3fb34",
    amount: 775522,
    date: "2024-01-03T11:00:00.000Z",
    iban: "6384",
    wasInstant: null,
    logoUrl:
      "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/AMAZON",
    accountLabel: "AMAZON",
    accountNumber: "0037082",
    __typename: "APBankTransfer",
  },
  {
    id: "bktr-e1be3d99-1e77-4bc3-ac8a-f31241729fd3",
    amount: 43037,
    date: "2024-01-02T12:49:59.947Z",
    iban: "6384",
    wasInstant: null,
    logoUrl:
      "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/LEROY%20MERLIN",
    accountLabel: "LEROY MERLIN",
    accountNumber: "0036654",
    __typename: "APBankTransfer",
  },
  {
    id: "bktr-e5a98750-be07-4b35-9ca9-8bc631cd730a",
    amount: 530797,
    date: "2024-01-02T11:00:00.000Z",
    iban: "6384",
    wasInstant: null,
    logoUrl:
      "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/AMAZON",
    accountLabel: "AMAZON",
    accountNumber: "0037082",
    __typename: "APBankTransfer",
  },
  {
    id: "bktr-116c2c8c-f0fc-4762-9325-6a9845cbaac2",
    amount: 270877,
    date: "2023-12-29T11:17:55.061Z",
    iban: "6384",
    wasInstant: null,
    logoUrl:
      "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/LEROY%20MERLIN",
    accountLabel: "LEROY MERLIN",
    accountNumber: "0036654",
    __typename: "APBankTransfer",
  },
  {
    id: "bktr-a6248921-abc3-4ff9-ba18-41ab89d85fa6",
    amount: 320655,
    date: "2023-12-29T11:00:00.000Z",
    iban: "6384",
    wasInstant: null,
    logoUrl:
      "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/AMAZON",
    accountLabel: "AMAZON",
    accountNumber: "0037082",
    __typename: "APBankTransfer",
  },
  {
    id: "bktr-aaeda6b1-d7a6-40d0-87f0-bdeead8bd04d",
    amount: 343604,
    date: "2023-12-28T11:03:07.005Z",
    iban: "6384",
    wasInstant: null,
    logoUrl:
      "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/LEROY%20MERLIN",
    accountLabel: "LEROY MERLIN",
    accountNumber: "0036654",
    __typename: "APBankTransfer",
  },
  {
    id: "bktr-278f9752-325d-440a-90e9-94a1e3ad002d",
    amount: 886942,
    date: "2023-12-28T11:00:00.000Z",
    iban: "6384",
    wasInstant: null,
    logoUrl:
      "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/AMAZON",
    accountLabel: "AMAZON",
    accountNumber: "0037082",
    __typename: "APBankTransfer",
  },
  {
    id: "bktr-944791ef-fb87-4c34-8d06-e9e6d8021466",
    amount: 357305,
    date: "2023-12-27T11:15:44.039Z",
    iban: "6384",
    wasInstant: null,
    logoUrl:
      "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/LEROY%20MERLIN",
    accountLabel: "LEROY MERLIN",
    accountNumber: "0036654",
    __typename: "APBankTransfer",
  },
  {
    id: "bktr-b78f6f13-efd9-4277-a723-13660c467868",
    amount: 623154,
    date: "2023-12-27T11:00:00.000Z",
    iban: "6384",
    wasInstant: null,
    logoUrl:
      "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/AMAZON",
    accountLabel: "AMAZON",
    accountNumber: "0037082",
    __typename: "APBankTransfer",
  },
  {
    id: "bktr-7fb4ede6-d051-4a58-bc7d-ff7331efbe77",
    amount: 1380747,
    date: "2023-12-26T11:12:19.189Z",
    iban: "6384",
    wasInstant: null,
    logoUrl:
      "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/LEROY%20MERLIN",
    accountLabel: "LEROY MERLIN",
    accountNumber: "0036654",
    __typename: "APBankTransfer",
  },
  {
    id: "bktr-dd459064-9c4b-46b7-b96a-47f1d0c53ee5",
    amount: 450174,
    date: "2023-12-26T11:00:00.000Z",
    iban: "6384",
    wasInstant: null,
    logoUrl:
      "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/AMAZON",
    accountLabel: "AMAZON",
    accountNumber: "0037082",
    __typename: "APBankTransfer",
  },
  {
    id: "bktr-483a7163-dbfc-4596-9f30-9e968c4a746d",
    amount: 260857,
    date: "2023-12-22T11:50:22.750Z",
    iban: "6384",
    wasInstant: null,
    logoUrl:
      "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/LEROY%20MERLIN",
    accountLabel: "LEROY MERLIN",
    accountNumber: "0036654",
    __typename: "APBankTransfer",
  },
  {
    id: "bktr-6d8f0866-85ec-432e-8d0b-b1c8e9799e52",
    amount: 542032,
    date: "2023-12-22T11:00:00.000Z",
    iban: "6384",
    wasInstant: null,
    logoUrl:
      "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/AMAZON",
    accountLabel: "AMAZON",
    accountNumber: "0037082",
    __typename: "APBankTransfer",
  },
  {
    id: "bktr-5f37e4c9-4bba-4978-8578-f41128bfcf46",
    amount: 580872,
    date: "2023-12-21T12:56:32.168Z",
    iban: "6384",
    wasInstant: null,
    logoUrl:
      "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/LEROY%20MERLIN",
    accountLabel: "LEROY MERLIN",
    accountNumber: "0036654",
    __typename: "APBankTransfer",
  },
  {
    id: "bktr-60122bb1-f5e1-4384-a3cc-92f5a132c0e5",
    amount: 386424,
    date: "2023-12-21T11:00:00.000Z",
    iban: "6384",
    wasInstant: null,
    logoUrl:
      "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/AMAZON",
    accountLabel: "AMAZON",
    accountNumber: "0037082",
    __typename: "APBankTransfer",
  },
  {
    id: "bktr-cc9650d7-a2b6-436b-ad33-4fd3a41b39cb",
    amount: 410505,
    date: "2023-12-20T12:53:49.725Z",
    iban: "6384",
    wasInstant: null,
    logoUrl:
      "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/LEROY%20MERLIN",
    accountLabel: "LEROY MERLIN",
    accountNumber: "0036654",
    __typename: "APBankTransfer",
  },
  {
    id: "bktr-f7bf954b-8eca-48fd-9737-8bb2afd76387",
    amount: 453668,
    date: "2023-12-20T11:00:00.000Z",
    iban: "6384",
    wasInstant: null,
    logoUrl:
      "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/AMAZON",
    accountLabel: "AMAZON",
    accountNumber: "0037082",
    __typename: "APBankTransfer",
  },
  {
    id: "bktr-f1a418e9-ef28-4932-bcd7-05ee1722ac5f",
    amount: 854020,
    date: "2023-12-19T12:00:39.989Z",
    iban: "6384",
    wasInstant: null,
    logoUrl:
      "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/LEROY%20MERLIN",
    accountLabel: "LEROY MERLIN",
    accountNumber: "0036654",
    __typename: "APBankTransfer",
  },
  {
    id: "bktr-e022998f-d3c1-4073-a800-3593c8565e41",
    amount: 585379,
    date: "2023-12-19T11:00:00.000Z",
    iban: "6384",
    wasInstant: null,
    logoUrl:
      "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/AMAZON",
    accountLabel: "AMAZON",
    accountNumber: "0037082",
    __typename: "APBankTransfer",
  },
  {
    id: "bktr-e8b8736b-f710-4d41-8b80-2d7235f12253",
    amount: 616980,
    date: "2023-12-18T12:21:06.934Z",
    iban: "6384",
    wasInstant: null,
    logoUrl:
      "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/LEROY%20MERLIN",
    accountLabel: "LEROY MERLIN",
    accountNumber: "0036654",
    __typename: "APBankTransfer",
  },
  {
    id: "bktr-c2506f87-b4e3-4bf1-bed9-bdaf5f018274",
    amount: 997339,
    date: "2023-12-18T11:00:00.000Z",
    iban: "6384",
    wasInstant: null,
    logoUrl:
      "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/AMAZON",
    accountLabel: "AMAZON",
    accountNumber: "0037082",
    __typename: "APBankTransfer",
  },

  {
    id: "bktr-0ce57d3c-b442-42f8-bc23-2fb0d7b65e77",
    amount: 115010,
    date: "2023-12-15T13:49:31.069Z",
    iban: "6384",
    wasInstant: null,
    logoUrl:
      "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/LEROY%20MERLIN",
    accountLabel: "LEROY MERLIN",
    accountNumber: "0036654",
    __typename: "APBankTransfer",
  },
  {
    id: "bktr-0b8e95c2-e8c0-41d4-b393-729d3e2f5ac0",
    amount: 406998,
    date: "2023-12-15T11:00:00.000Z",
    iban: "6384",
    wasInstant: null,
    logoUrl:
      "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/AMAZON",
    accountLabel: "AMAZON",
    accountNumber: "0037082",
    __typename: "APBankTransfer",
  },
  {
    id: "bktr-212a606e-72db-4b28-bb6b-1278abf5e26f",
    amount: 420784,
    date: "2023-12-14T12:21:52.155Z",
    iban: "6384",
    wasInstant: null,
    logoUrl:
      "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/LEROY%20MERLIN",
    accountLabel: "LEROY MERLIN",
    accountNumber: "0036654",
    __typename: "APBankTransfer",
  },
  {
    id: "bktr-e892e82f-de8f-4096-a5e4-1f24a20db47b",
    amount: 351675,
    date: "2023-12-14T11:00:00.000Z",
    iban: "6384",
    wasInstant: null,
    logoUrl:
      "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/AMAZON",
    accountLabel: "AMAZON",
    accountNumber: "0037082",
    __typename: "APBankTransfer",
  },
  {
    id: "bktr-dd205771-0e8f-42ff-8ee1-f3ad769c555c",
    amount: 455920,
    date: "2023-12-13T12:14:04.465Z",
    iban: "6384",
    wasInstant: null,
    logoUrl:
      "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/LEROY%20MERLIN",
    accountLabel: "LEROY MERLIN",
    accountNumber: "0036654",
    __typename: "APBankTransfer",
  },
  {
    id: "bktr-8fa60a51-7e12-4918-9fe1-c23499f56c5e",
    amount: 375908,
    date: "2023-12-13T11:00:00.000Z",
    iban: "6384",
    wasInstant: null,
    logoUrl:
      "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/AMAZON",
    accountLabel: "AMAZON",
    accountNumber: "0037082",
    __typename: "APBankTransfer",
  },
  {
    id: "bktr-c404eeaa-b312-43cb-97cc-ec41f9ff63c1",
    amount: 582890,
    date: "2023-12-12T12:57:03.846Z",
    iban: "6384",
    wasInstant: null,
    logoUrl:
      "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/LEROY%20MERLIN",
    accountLabel: "LEROY MERLIN",
    accountNumber: "0036654",
    __typename: "APBankTransfer",
  },
  {
    id: "bktr-da9fef42-fb0d-4902-9024-335362be4a85",
    amount: 600443,
    date: "2023-12-12T11:00:00.000Z",
    iban: "6384",
    wasInstant: null,
    logoUrl:
      "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/AMAZON",
    accountLabel: "AMAZON",
    accountNumber: "0037082",
    __typename: "APBankTransfer",
  },
  {
    id: "bktr-b4d9fe5f-0542-4455-aa63-6e6925a3e2a1",
    amount: 346592,
    date: "2023-12-11T13:03:36.289Z",
    iban: "6384",
    wasInstant: null,
    logoUrl:
      "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/LEROY%20MERLIN",
    accountLabel: "LEROY MERLIN",
    accountNumber: "0036654",
    __typename: "APBankTransfer",
  },
  {
    id: "bktr-e194c742-5d6e-4b13-869c-fd8e6c11824d",
    amount: 863387,
    date: "2023-12-11T11:00:00.000Z",
    iban: "6384",
    wasInstant: null,
    logoUrl:
      "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/AMAZON",
    accountLabel: "AMAZON",
    accountNumber: "0037082",
    __typename: "APBankTransfer",
  },
  {
    id: "bktr-077416b8-d2ad-4dc9-b718-d9784dd3f91a",
    amount: 526528,
    date: "2023-12-08T11:10:18.687Z",
    iban: "6384",
    wasInstant: null,
    logoUrl:
      "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/LEROY%20MERLIN",
    accountLabel: "LEROY MERLIN",
    accountNumber: "0036654",
    __typename: "APBankTransfer",
  },
  {
    id: "bktr-12c6aacf-f78f-48fc-8cb2-70ccb1757900",
    amount: 362775,
    date: "2023-12-08T11:00:00.000Z",
    iban: "6384",
    wasInstant: null,
    logoUrl:
      "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/AMAZON",
    accountLabel: "AMAZON",
    accountNumber: "0037082",
    __typename: "APBankTransfer",
  },
  {
    id: "bktr-a536cea5-2cee-41a1-a534-41af5346ae15",
    amount: 523046,
    date: "2023-12-07T11:26:55.209Z",
    iban: "6384",
    wasInstant: null,
    logoUrl:
      "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/LEROY%20MERLIN",
    accountLabel: "LEROY MERLIN",
    accountNumber: "0036654",
    __typename: "APBankTransfer",
  },
  {
    id: "bktr-22c1a86f-90f5-4275-9c06-2b9f503ccba1",
    amount: 575522,
    date: "2023-12-07T11:00:00.000Z",
    iban: "6384",
    wasInstant: null,
    logoUrl:
      "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/AMAZON",
    accountLabel: "AMAZON",
    accountNumber: "0037082",
    __typename: "APBankTransfer",
  },
  {
    id: "bktr-c934d74f-69b1-476b-b394-4ddd1b6102d1",
    amount: 498224,
    date: "2023-12-06T12:10:50.145Z",
    iban: "6384",
    wasInstant: null,
    logoUrl:
      "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/LEROY%20MERLIN",
    accountLabel: "LEROY MERLIN",
    accountNumber: "0036654",
    __typename: "APBankTransfer",
  },
  {
    id: "bktr-7761a0d7-7f07-4332-9f48-3b01843b0798",
    amount: 316761,
    date: "2023-12-06T11:00:00.000Z",
    iban: "6384",
    wasInstant: null,
    logoUrl:
      "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/AMAZON",
    accountLabel: "AMAZON",
    accountNumber: "0037082",
    __typename: "APBankTransfer",
  },
  {
    id: "bktr-1a9417f6-7216-4f69-af31-4df1dadc8f71",
    amount: 1471490,
    date: "2023-12-05T11:24:50.429Z",
    iban: "6384",
    wasInstant: null,
    logoUrl:
      "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/LEROY%20MERLIN",
    accountLabel: "LEROY MERLIN",
    accountNumber: "0036654",
    __typename: "APBankTransfer",
  },
  {
    id: "bktr-e479f1cb-1244-4eaa-b614-9a9a1da9a010",
    amount: 471943,
    date: "2023-12-05T11:00:00.000Z",
    iban: "6384",
    wasInstant: null,
    logoUrl:
      "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/AMAZON",
    accountLabel: "AMAZON",
    accountNumber: "0037082",
    __typename: "APBankTransfer",
  },
  {
    id: "bktr-37bb050b-0535-4c7f-8214-c6d9badb6b9f",
    amount: 468266,
    date: "2023-12-04T13:05:51.601Z",
    iban: "6384",
    wasInstant: null,
    logoUrl:
      "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/LEROY%20MERLIN",
    accountLabel: "LEROY MERLIN",
    accountNumber: "0036654",
    __typename: "APBankTransfer",
  },
  {
    id: "bktr-1fd21e54-6815-4b7f-a72d-57fa634e2fbf",
    amount: 1025749,
    date: "2023-12-04T11:00:00.000Z",
    iban: "6384",
    wasInstant: null,
    logoUrl:
      "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/AMAZON",
    accountLabel: "AMAZON",
    accountNumber: "0037082",
    __typename: "APBankTransfer",
  },
  {
    id: "bktr-a2cd74d1-e24e-442b-b63a-88ad710b69fc",
    amount: 364697,
    date: "2023-12-01T13:29:25.431Z",
    iban: "6384",
    wasInstant: null,
    logoUrl:
      "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/LEROY%20MERLIN",
    accountLabel: "LEROY MERLIN",
    accountNumber: "0036654",
    __typename: "APBankTransfer",
  },
  {
    id: "bktr-574c2ccc-1d85-469a-850a-1e4e58f9f2d3",
    amount: 321627,
    date: "2023-12-01T11:00:00.000Z",
    iban: "6384",
    wasInstant: null,
    logoUrl:
      "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/AMAZON",
    accountLabel: "AMAZON",
    accountNumber: "0037082",
    __typename: "APBankTransfer",
  },
  {
    id: "bktr-9aaf1cbc-291b-437c-bde1-29b0a15386f4",
    amount: 384240,
    date: "2023-11-30T13:15:48.884Z",
    iban: "6384",
    wasInstant: null,
    logoUrl:
      "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/LEROY%20MERLIN",
    accountLabel: "LEROY MERLIN",
    accountNumber: "0036654",
    __typename: "APBankTransfer",
  },
  {
    id: "bktr-cefdd0b4-ac68-4162-883e-9406bb079421",
    amount: 252866,
    date: "2023-11-30T11:00:00.000Z",
    iban: "6384",
    wasInstant: null,
    logoUrl:
      "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/AMAZON",
    accountLabel: "AMAZON",
    accountNumber: "0037082",
    __typename: "APBankTransfer",
  },
  {
    id: "bktr-c7ced494-6ffa-414e-8453-7ef85f256b29",
    amount: 634902,
    date: "2023-11-29T13:59:39.282Z",
    iban: "6384",
    wasInstant: null,
    logoUrl:
      "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/LEROY%20MERLIN",
    accountLabel: "LEROY MERLIN",
    accountNumber: "0036654",
    __typename: "APBankTransfer",
  },
  {
    id: "bktr-c8a83c51-d02e-4530-86bf-9f3d7a3ee635",
    amount: 334882,
    date: "2023-11-29T11:00:00.000Z",
    iban: "6384",
    wasInstant: null,
    logoUrl:
      "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/AMAZON",
    accountLabel: "AMAZON",
    accountNumber: "0037082",
    __typename: "APBankTransfer",
  },
  {
    id: "bktr-7cd73df8-cc63-49f1-83ed-85a2b113623b",
    amount: 619964,
    date: "2023-11-28T11:09:13.885Z",
    iban: "6384",
    wasInstant: null,
    logoUrl:
      "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/LEROY%20MERLIN",
    accountLabel: "LEROY MERLIN",
    accountNumber: "0036654",
    __typename: "APBankTransfer",
  },
  {
    id: "bktr-dc1845c4-c619-48dc-8714-96d7e8061e32",
    amount: 825820,
    date: "2023-11-28T11:00:00.000Z",
    iban: "6384",
    wasInstant: null,
    logoUrl:
      "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/AMAZON",
    accountLabel: "AMAZON",
    accountNumber: "0037082",
    __typename: "APBankTransfer",
  },
  {
    id: "bktr-37735cc4-85cd-4d24-ba32-d04948e06b2b",
    amount: 901267,
    date: "2023-11-27T12:47:30.850Z",
    iban: "6384",
    wasInstant: null,
    logoUrl:
      "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/LEROY%20MERLIN",
    accountLabel: "LEROY MERLIN",
    accountNumber: "0036654",
    __typename: "APBankTransfer",
  },
  {
    id: "bktr-d928b727-bbe8-421e-a00e-4f920c6314c3",
    amount: 280214,
    date: "2023-11-27T11:00:00.000Z",
    iban: "6384",
    wasInstant: null,
    logoUrl:
      "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/AMAZON",
    accountLabel: "AMAZON",
    accountNumber: "0037082",
    __typename: "APBankTransfer",
  },
  {
    id: "bktr-edc8d8ca-51a1-421e-87da-b04ebc07bdce",
    amount: 247947,
    date: "2023-11-24T12:18:49.904Z",
    iban: "6384",
    wasInstant: null,
    logoUrl:
      "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/LEROY%20MERLIN",
    accountLabel: "LEROY MERLIN",
    accountNumber: "0036654",
    __typename: "APBankTransfer",
  },
  {
    id: "bktr-443a0539-0322-4689-b840-0e7e0555b68c",
    amount: 522209,
    date: "2023-11-24T11:00:00.000Z",
    iban: "6384",
    wasInstant: null,
    logoUrl:
      "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/AMAZON",
    accountLabel: "AMAZON",
    accountNumber: "0037082",
    __typename: "APBankTransfer",
  },
  {
    id: "bktr-3387091a-c17d-4fbf-afed-3f3c71300c8c",
    amount: 1046189,
    date: "2023-11-23T13:24:25.657Z",
    iban: "6384",
    wasInstant: null,
    logoUrl:
      "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/LEROY%20MERLIN",
    accountLabel: "LEROY MERLIN",
    accountNumber: "0036654",
    __typename: "APBankTransfer",
  },
  {
    id: "bktr-44109d56-05c0-4f01-99be-8ec56b3ea765",
    amount: 261232,
    date: "2023-11-23T11:00:00.000Z",
    iban: "6384",
    wasInstant: null,
    logoUrl:
      "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/AMAZON",
    accountLabel: "AMAZON",
    accountNumber: "0037082",
    __typename: "APBankTransfer",
  },
  {
    id: "bktr-f56d42ea-90d5-40cd-b045-077e8a0e40c5",
    amount: 475476,
    date: "2023-11-22T11:00:00.000Z",
    iban: "6384",
    wasInstant: null,
    logoUrl:
      "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/AMAZON",
    accountLabel: "AMAZON",
    accountNumber: "0037082",
    __typename: "APBankTransfer",
  },
  {
    id: "bktr-eede3194-d010-488f-967f-74ef018415fb",
    amount: 1068153,
    date: "2023-11-21T11:00:00.000Z",
    iban: "6384",
    wasInstant: null,
    logoUrl:
      "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/AMAZON",
    accountLabel: "AMAZON",
    accountNumber: "0037082",
    __typename: "APBankTransfer",
  },
  {
    id: "bktr-050e6ba5-a184-41f9-abfc-14c831461be5",
    amount: 166083,
    date: "2023-11-20T12:44:13.095Z",
    iban: "6384",
    wasInstant: null,
    logoUrl:
      "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/LEROY%20MERLIN",
    accountLabel: "LEROY MERLIN",
    accountNumber: "0036654",
    __typename: "APBankTransfer",
  },
  {
    id: "bktr-4b050e1d-2613-49ad-9145-2131c4c900d5",
    amount: 290737,
    date: "2023-11-20T11:00:00.000Z",
    iban: "6384",
    wasInstant: null,
    logoUrl:
      "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/AMAZON",
    accountLabel: "AMAZON",
    accountNumber: "0037082",
    __typename: "APBankTransfer",
  },
  {
    id: "bktr-c641c75a-f2e6-4318-a753-755eddfa8126",
    amount: 139995,
    date: "2023-11-17T14:26:58.622Z",
    iban: "6384",
    wasInstant: null,
    logoUrl:
      "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/LEROY%20MERLIN",
    accountLabel: "LEROY MERLIN",
    accountNumber: "0036654",
    __typename: "APBankTransfer",
  },
  {
    id: "bktr-f729696e-8814-483b-b13a-7cc4d339286a",
    amount: 586354,
    date: "2023-11-17T11:00:00.000Z",
    iban: "6384",
    wasInstant: null,
    logoUrl:
      "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/AMAZON",
    accountLabel: "AMAZON",
    accountNumber: "0037082",
    __typename: "APBankTransfer",
  },
  {
    id: "bktr-f1d00e89-a688-4dbf-925e-796becd54be6",
    amount: 172991,
    date: "2023-11-16T11:18:00.347Z",
    iban: "6384",
    wasInstant: null,
    logoUrl:
      "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/LEROY%20MERLIN",
    accountLabel: "LEROY MERLIN",
    accountNumber: "0036654",
    __typename: "APBankTransfer",
  },
  {
    id: "bktr-33b058e5-4bff-48ed-81ba-366222949b6f",
    amount: 272923,
    date: "2023-11-16T11:00:00.000Z",
    iban: "6384",
    wasInstant: null,
    logoUrl:
      "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/AMAZON",
    accountLabel: "AMAZON",
    accountNumber: "0037082",
    __typename: "APBankTransfer",
  },
  {
    id: "bktr-37055dc2-2b3b-49eb-8094-0db9acc9c190",
    amount: 191135,
    date: "2023-11-15T11:59:53.999Z",
    iban: "6384",
    wasInstant: null,
    logoUrl:
      "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/LEROY%20MERLIN",
    accountLabel: "LEROY MERLIN",
    accountNumber: "0036654",
    __typename: "APBankTransfer",
  },
  {
    id: "bktr-e9098689-d30b-418d-8c77-e3fae034b86c",
    amount: 300839,
    date: "2023-11-15T11:00:00.000Z",
    iban: "6384",
    wasInstant: null,
    logoUrl:
      "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/AMAZON",
    accountLabel: "AMAZON",
    accountNumber: "0037082",
    __typename: "APBankTransfer",
  },
  {
    id: "bktr-9dbec999-0dd4-4a07-b98d-3406c82bf920",
    amount: 621223,
    date: "2023-11-14T12:35:44.615Z",
    iban: "6384",
    wasInstant: null,
    logoUrl:
      "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/LEROY%20MERLIN",
    accountLabel: "LEROY MERLIN",
    accountNumber: "0036654",
    __typename: "APBankTransfer",
  },
  {
    id: "bktr-a74bf3ba-f7ea-4c14-ba95-6c005fde50bd",
    amount: 630230,
    date: "2023-11-14T11:00:00.000Z",
    iban: "6384",
    wasInstant: null,
    logoUrl:
      "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/AMAZON",
    accountLabel: "AMAZON",
    accountNumber: "0037082",
    __typename: "APBankTransfer",
  },
  {
    id: "bktr-0bf419d8-feb6-4ab1-9d47-73c9322d3fe9",
    amount: 182179,
    date: "2023-11-13T13:03:35.376Z",
    iban: "6384",
    wasInstant: null,
    logoUrl:
      "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/LEROY%20MERLIN",
    accountLabel: "LEROY MERLIN",
    accountNumber: "0036654",
    __typename: "APBankTransfer",
  },
  {
    id: "bktr-78e793a7-ab3f-4903-91b8-4ca94ec50ed7",
    amount: 354383,
    date: "2023-11-13T11:00:00.000Z",
    iban: "6384",
    wasInstant: null,
    logoUrl:
      "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/AMAZON",
    accountLabel: "AMAZON",
    accountNumber: "0037082",
    __typename: "APBankTransfer",
  },
  {
    id: "bktr-d3b632f2-497a-4555-8957-ac7234e1a83d",
    amount: 185911,
    date: "2023-11-10T11:12:31.029Z",
    iban: "6384",
    wasInstant: null,
    logoUrl:
      "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/LEROY%20MERLIN",
    accountLabel: "LEROY MERLIN",
    accountNumber: "0036654",
    __typename: "APBankTransfer",
  },
  {
    id: "bktr-695265ad-de3b-41ea-957a-c3945514c125",
    amount: 293760,
    date: "2023-11-10T11:00:00.000Z",
    iban: "6384",
    wasInstant: null,
    logoUrl:
      "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/AMAZON",
    accountLabel: "AMAZON",
    accountNumber: "0037082",
    __typename: "APBankTransfer",
  },
  {
    id: "bktr-d0b3629c-ca94-42ef-b385-84f8b82d177c",
    amount: 234591,
    date: "2023-11-09T12:09:05.085Z",
    iban: "6384",
    wasInstant: null,
    logoUrl:
      "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/LEROY%20MERLIN",
    accountLabel: "LEROY MERLIN",
    accountNumber: "0036654",
    __typename: "APBankTransfer",
  },
  {
    id: "bktr-5546b367-39eb-4af3-94d2-39e7a898a2f8",
    amount: 617839,
    date: "2023-11-09T11:00:00.000Z",
    iban: "6384",
    wasInstant: null,
    logoUrl:
      "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/AMAZON",
    accountLabel: "AMAZON",
    accountNumber: "0037082",
    __typename: "APBankTransfer",
  },
  {
    id: "bktr-6ac317bd-4fd1-457f-b009-2ac2eab83208",
    amount: 264626,
    date: "2023-11-08T12:36:05.178Z",
    iban: "6384",
    wasInstant: null,
    logoUrl:
      "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/LEROY%20MERLIN",
    accountLabel: "LEROY MERLIN",
    accountNumber: "0036654",
    __typename: "APBankTransfer",
  },
  {
    id: "bktr-d5f18455-1c0a-46b8-af4b-e3a39f2ade91",
    amount: 28784,
    date: "2023-11-08T11:00:00.000Z",
    iban: "6384",
    wasInstant: null,
    logoUrl:
      "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/AMAZON",
    accountLabel: "AMAZON",
    accountNumber: "0037082",
    __typename: "APBankTransfer",
  },
  {
    id: "bktr-dddb25bd-1618-4935-b662-baf129a8fe9f",
    amount: 362592,
    date: "2023-11-07T12:28:32.736Z",
    iban: "6384",
    wasInstant: null,
    logoUrl:
      "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/LEROY%20MERLIN",
    accountLabel: "LEROY MERLIN",
    accountNumber: "0036654",
    __typename: "APBankTransfer",
  },
  {
    id: "bktr-2fc0fbdb-4ebb-4155-a4f4-5ce965e8217e",
    amount: 923638,
    date: "2023-11-07T11:00:00.000Z",
    iban: "6384",
    wasInstant: null,
    logoUrl:
      "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/AMAZON",
    accountLabel: "AMAZON",
    accountNumber: "0037082",
    __typename: "APBankTransfer",
  },
  {
    id: "bktr-40953e7c-2b59-406f-8947-69802add1e38",
    amount: 1308831,
    date: "2023-11-06T12:50:24.019Z",
    iban: "6384",
    wasInstant: null,
    logoUrl:
      "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/LEROY%20MERLIN",
    accountLabel: "LEROY MERLIN",
    accountNumber: "0036654",
    __typename: "APBankTransfer",
  },
  {
    id: "bktr-59274829-07bb-43fb-a39d-1c46464e520b",
    amount: 214725,
    date: "2023-11-03T13:39:45.873Z",
    iban: "6384",
    wasInstant: null,
    logoUrl:
      "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/LEROY%20MERLIN",
    accountLabel: "LEROY MERLIN",
    accountNumber: "0036654",
    __typename: "APBankTransfer",
  },
  {
    id: "bktr-247e8dd3-481b-481a-a190-52d753872bc2",
    amount: 242804,
    date: "2023-11-02T11:57:06.231Z",
    iban: "6384",
    wasInstant: null,
    logoUrl:
      "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/LEROY%20MERLIN",
    accountLabel: "LEROY MERLIN",
    accountNumber: "0036654",
    __typename: "APBankTransfer",
  },
  {
    id: "bktr-134aa5da-0a46-4d9a-8a72-368a5497b6a0",
    amount: 125482,
    date: "2023-10-31T12:25:34.314Z",
    iban: "6384",
    wasInstant: null,
    logoUrl:
      "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/LEROY%20MERLIN",
    accountLabel: "LEROY MERLIN",
    accountNumber: "0036654",
    __typename: "APBankTransfer",
  },
  {
    id: "bktr-f59d2c84-20ff-40e3-9219-36a5daf80e1e",
    amount: 129582,
    date: "2023-10-30T11:49:43.758Z",
    iban: "6384",
    wasInstant: null,
    logoUrl:
      "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/LEROY%20MERLIN",
    accountLabel: "LEROY MERLIN",
    accountNumber: "0036654",
    __typename: "APBankTransfer",
  },
  {
    id: "bktr-fac896c4-840d-47ae-ad4c-f91ae0fa03bf",
    amount: 46427,
    date: "2023-10-27T12:10:22.673Z",
    iban: "6384",
    wasInstant: null,
    logoUrl:
      "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/LEROY%20MERLIN",
    accountLabel: "LEROY MERLIN",
    accountNumber: "0036654",
    __typename: "APBankTransfer",
  },
  {
    id: "bktr-ee997ab2-5f9b-40b2-8b54-6d1b639ed481",
    amount: 53003,
    date: "2023-10-26T10:23:53.790Z",
    iban: "6384",
    wasInstant: null,
    logoUrl:
      "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/LEROY%20MERLIN",
    accountLabel: "LEROY MERLIN",
    accountNumber: "0036654",
    __typename: "APBankTransfer",
  },
  {
    id: "bktr-7285fc23-137f-4540-90c1-2e9de06d85a2",
    amount: 265466,
    date: "2023-10-24T11:48:48.874Z",
    iban: "6384",
    wasInstant: null,
    logoUrl:
      "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/LEROY%20MERLIN",
    accountLabel: "LEROY MERLIN",
    accountNumber: "0036654",
    __typename: "APBankTransfer",
  },
  {
    id: "bktr-35da7e23-86a2-499d-b709-68fc90de5da4",
    amount: 154181,
    date: "2023-10-23T11:58:25.594Z",
    iban: "6384",
    wasInstant: null,
    logoUrl:
      "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/LEROY%20MERLIN",
    accountLabel: "LEROY MERLIN",
    accountNumber: "0036654",
    __typename: "APBankTransfer",
  },
  {
    id: "bktr-a88e0512-aa48-4166-89c8-3e21339fdab6",
    amount: 219050,
    date: "2023-10-20T11:57:21.999Z",
    iban: "6384",
    wasInstant: null,
    logoUrl:
      "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/LEROY%20MERLIN",
    accountLabel: "LEROY MERLIN",
    accountNumber: "0036654",
    __typename: "APBankTransfer",
  },
  {
    id: "bktr-4e71b754-74ef-48e6-8205-c47a26f3d6b8",
    amount: 214122,
    date: "2023-10-19T10:53:55.167Z",
    iban: "6384",
    wasInstant: null,
    logoUrl:
      "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/LEROY%20MERLIN",
    accountLabel: "LEROY MERLIN",
    accountNumber: "0036654",
    __typename: "APBankTransfer",
  },
  {
    id: "bktr-b274fa60-d398-48fd-8b4b-b148dbd73d04",
    amount: 267035,
    date: "2023-10-18T12:11:53.942Z",
    iban: "6384",
    wasInstant: null,
    logoUrl:
      "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/LEROY%20MERLIN",
    accountLabel: "LEROY MERLIN",
    accountNumber: "0036654",
    __typename: "APBankTransfer",
  },
  {
    id: "bktr-54c1386d-e70a-4143-8a65-f8b9a12f3f30",
    amount: 264687,
    date: "2023-10-17T12:57:04.250Z",
    iban: "6384",
    wasInstant: null,
    logoUrl:
      "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/LEROY%20MERLIN",
    accountLabel: "LEROY MERLIN",
    accountNumber: "0036654",
    __typename: "APBankTransfer",
  },
  {
    id: "bktr-3ae6c1ad-92ca-4fe2-83a0-278f74b5f0ce",
    amount: 302729,
    date: "2023-10-16T12:03:56.188Z",
    iban: "6384",
    wasInstant: null,
    logoUrl:
      "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/LEROY%20MERLIN",
    accountLabel: "LEROY MERLIN",
    accountNumber: "0036654",
    __typename: "APBankTransfer",
  },
  {
    id: "bktr-1a08f058-b050-48ec-a26e-af6f77e60e1c",
    amount: 160745,
    date: "2023-10-13T13:46:11.247Z",
    iban: "6384",
    wasInstant: null,
    logoUrl:
      "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/LEROY%20MERLIN",
    accountLabel: "LEROY MERLIN",
    accountNumber: "0036654",
    __typename: "APBankTransfer",
  },
  {
    id: "bktr-6478d5da-3771-410f-8eb4-c66a8b27c199",
    amount: 119349,
    date: "2023-10-12T11:09:16.401Z",
    iban: "6384",
    wasInstant: null,
    logoUrl:
      "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/LEROY%20MERLIN",
    accountLabel: "LEROY MERLIN",
    accountNumber: "0036654",
    __typename: "APBankTransfer",
  },
  {
    id: "bktr-3820887f-d094-4a94-8b49-42a4eb890f4e",
    amount: 334052,
    date: "2023-10-11T10:53:13.563Z",
    iban: "6384",
    wasInstant: null,
    logoUrl:
      "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/LEROY%20MERLIN",
    accountLabel: "LEROY MERLIN",
    accountNumber: "0036654",
    __typename: "APBankTransfer",
  },
  {
    id: "bktr-eeea9f22-23c6-40df-a458-92d66df03104",
    amount: 166140,
    date: "2023-10-10T11:50:27.773Z",
    iban: "6384",
    wasInstant: null,
    logoUrl:
      "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/LEROY%20MERLIN",
    accountLabel: "LEROY MERLIN",
    accountNumber: "0036654",
    __typename: "APBankTransfer",
  },
  {
    id: "bktr-cf5ca9b6-f9c4-443a-a736-ce7d548f1aa9",
    amount: 164890,
    date: "2023-10-09T12:55:19.680Z",
    iban: "6384",
    wasInstant: null,
    logoUrl:
      "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/LEROY%20MERLIN",
    accountLabel: "LEROY MERLIN",
    accountNumber: "0036654",
    __typename: "APBankTransfer",
  },
  {
    id: "bktr-fa3f7ce7-81cc-467e-a6fe-1c6b653c1305",
    amount: 10774,
    date: "2023-10-06T11:51:32.019Z",
    iban: "6384",
    wasInstant: null,
    logoUrl:
      "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/LEROY%20MERLIN",
    accountLabel: "LEROY MERLIN",
    accountNumber: "0036654",
    __typename: "APBankTransfer",
  },
  {
    id: "bktr-11c7161d-a742-4196-b545-2620d1ee5411",
    amount: 5299522,
    date: "2023-10-05T13:15:07.943Z",
    iban: "6384",
    wasInstant: null,
    logoUrl:
      "https://prod-hero-merchant-assets-staging-2da7095475674.s3.eu-west-3.amazonaws.com/marketplaceLogos/LEROY%20MERLIN",
    accountLabel: "LEROY MERLIN",
    accountNumber: "0036654",
    __typename: "APBankTransfer",
  },
]
