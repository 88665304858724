export const demoApBarChartDataBase = {
  apCaByMarketplaceByDay: [
    {
      createdDate: -11,
      dayTotal: 0,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 0,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 0,
        },
      ],
      initialCreatedDate: "2024-02-10T00:00:00.000Z",
    },
    {
      createdDate: -12,
      dayTotal: 324256,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 324256,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 0,
        },
      ],
      initialCreatedDate: "2024-02-09T00:00:00.000Z",
    },
    {
      createdDate: -13,
      dayTotal: 332000,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 323065,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 8935,
        },
      ],
      initialCreatedDate: "2024-02-08T00:00:00.000Z",
    },
    {
      createdDate: -14,
      dayTotal: 538156,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 345104,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 193052,
        },
      ],
      initialCreatedDate: "2024-02-07T00:00:00.000Z",
    },
    {
      createdDate: -15,
      dayTotal: 893182,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 467078,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 426104,
        },
      ],
      initialCreatedDate: "2024-02-06T00:00:00.000Z",
    },
    {
      createdDate: -16,
      dayTotal: null,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: null,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 292075,
        },
      ],
      initialCreatedDate: "2024-02-05T00:00:00.000Z",
    },
    {
      createdDate: -17,
      dayTotal: 0,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 0,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 0,
        },
      ],
      initialCreatedDate: "2024-02-04T00:00:00.000Z",
    },
    {
      createdDate: -18,
      dayTotal: 0,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 0,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 0,
        },
      ],
      initialCreatedDate: "2024-02-03T00:00:00.000Z",
    },
    {
      createdDate: -19,
      dayTotal: 623267,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 394464,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 228803,
        },
      ],
      initialCreatedDate: "2024-02-02T00:00:00.000Z",
    },
    {
      createdDate: -20,
      dayTotal: 703237,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 418824,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 284413,
        },
      ],
      initialCreatedDate: "2024-02-01T00:00:00.000Z",
    },
    {
      createdDate: -21,
      dayTotal: 590986,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 397619,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 193367,
        },
      ],
      initialCreatedDate: "2024-01-31T00:00:00.000Z",
    },
    {
      createdDate: -22,
      dayTotal: 1377554,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 970206,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 407348,
        },
      ],
      initialCreatedDate: "2024-01-30T00:00:00.000Z",
    },
    {
      createdDate: -23,
      dayTotal: 517810,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 368621,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 149189,
        },
      ],
      initialCreatedDate: "2024-01-29T00:00:00.000Z",
    },
    {
      createdDate: -24,
      dayTotal: 0,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 0,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 0,
        },
      ],
      initialCreatedDate: "2024-01-28T00:00:00.000Z",
    },
    {
      createdDate: -25,
      dayTotal: 0,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 0,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 0,
        },
      ],
      initialCreatedDate: "2024-01-27T00:00:00.000Z",
    },
    {
      createdDate: -26,
      dayTotal: 694085,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 409711,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 284374,
        },
      ],
      initialCreatedDate: "2024-01-26T00:00:00.000Z",
    },
    {
      createdDate: -27,
      dayTotal: 573769,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 367872,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 205897,
        },
      ],
      initialCreatedDate: "2024-01-25T00:00:00.000Z",
    },
    {
      createdDate: -28,
      dayTotal: 689440,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 377751,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 311689,
        },
      ],
      initialCreatedDate: "2024-01-24T00:00:00.000Z",
    },
    {
      createdDate: -29,
      dayTotal: 1126607,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 912896,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 213711,
        },
      ],
      initialCreatedDate: "2024-01-23T00:00:00.000Z",
    },
    {
      createdDate: -30,
      dayTotal: 580102,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 354460,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 225642,
        },
      ],
      initialCreatedDate: "2024-01-22T00:00:00.000Z",
    },
    {
      createdDate: -31,
      dayTotal: 0,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 0,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 0,
        },
      ],
      initialCreatedDate: "2024-01-21T00:00:00.000Z",
    },
    {
      createdDate: -32,
      dayTotal: 0,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 0,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 0,
        },
      ],
      initialCreatedDate: "2024-01-20T00:00:00.000Z",
    },
    {
      createdDate: -33,
      dayTotal: 680334,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 340888,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 339446,
        },
      ],
      initialCreatedDate: "2024-01-19T00:00:00.000Z",
    },
    {
      createdDate: -34,
      dayTotal: 590714,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 364302,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 226412,
        },
      ],
      initialCreatedDate: "2024-01-18T00:00:00.000Z",
    },
    {
      createdDate: -35,
      dayTotal: 894645,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 429383,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 465262,
        },
      ],
      initialCreatedDate: "2024-01-17T00:00:00.000Z",
    },
    {
      createdDate: -36,
      dayTotal: 1081686,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 906439,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 175247,
        },
      ],
      initialCreatedDate: "2024-01-16T00:00:00.000Z",
    },
    {
      createdDate: -37,
      dayTotal: 856130,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 397263,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 458867,
        },
      ],
      initialCreatedDate: "2024-01-15T00:00:00.000Z",
    },
    {
      createdDate: -38,
      dayTotal: 0,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 0,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 0,
        },
      ],
      initialCreatedDate: "2024-01-14T00:00:00.000Z",
    },
    {
      createdDate: -39,
      dayTotal: 0,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 0,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 0,
        },
      ],
      initialCreatedDate: "2024-01-13T00:00:00.000Z",
    },
    {
      createdDate: -40,
      dayTotal: 749754,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 464928,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 284826,
        },
      ],
      initialCreatedDate: "2024-01-12T00:00:00.000Z",
    },
    {
      createdDate: -41,
      dayTotal: 710091,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 385713,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 324378,
        },
      ],
      initialCreatedDate: "2024-01-11T00:00:00.000Z",
    },
    {
      createdDate: -42,
      dayTotal: 648072,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 337683,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 310389,
        },
      ],
      initialCreatedDate: "2024-01-10T00:00:00.000Z",
    },
    {
      createdDate: -43,
      dayTotal: 1180345,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 891130,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 289215,
        },
      ],
      initialCreatedDate: "2024-01-09T00:00:00.000Z",
    },
    {
      createdDate: -44,
      dayTotal: 861621,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 465771,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 395850,
        },
      ],
      initialCreatedDate: "2024-01-08T00:00:00.000Z",
    },
    {
      createdDate: -45,
      dayTotal: 0,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 0,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 0,
        },
      ],
      initialCreatedDate: "2024-01-07T00:00:00.000Z",
    },
    {
      createdDate: -46,
      dayTotal: 0,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 0,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 0,
        },
      ],
      initialCreatedDate: "2024-01-06T00:00:00.000Z",
    },
    {
      createdDate: -47,
      dayTotal: 777813,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 410257,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 367556,
        },
      ],
      initialCreatedDate: "2024-01-05T00:00:00.000Z",
    },
    {
      createdDate: -48,
      dayTotal: 730845,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 440055,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 290790,
        },
      ],
      initialCreatedDate: "2024-01-04T00:00:00.000Z",
    },
    {
      createdDate: -49,
      dayTotal: 1405990,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 987926,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 418064,
        },
      ],
      initialCreatedDate: "2024-01-03T00:00:00.000Z",
    },
    {
      createdDate: -50,
      dayTotal: 1037586,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 676175,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 361411,
        },
      ],
      initialCreatedDate: "2024-01-02T00:00:00.000Z",
    },
    {
      createdDate: -51,
      dayTotal: 0,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 0,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 0,
        },
      ],
      initialCreatedDate: "2024-01-01T00:00:00.000Z",
    },
    {
      createdDate: -52,
      dayTotal: 0,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 0,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 0,
        },
      ],
      initialCreatedDate: "2023-12-31T00:00:00.000Z",
    },
    {
      createdDate: -53,
      dayTotal: 0,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 0,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 0,
        },
      ],
      initialCreatedDate: "2023-12-30T00:00:00.000Z",
    },
    {
      createdDate: -54,
      dayTotal: 462852,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 408478,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 54374,
        },
      ],
      initialCreatedDate: "2023-12-29T00:00:00.000Z",
    },
    {
      createdDate: -55,
      dayTotal: 744525,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 402292,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 342233,
        },
      ],
      initialCreatedDate: "2023-12-28T00:00:00.000Z",
    },
    {
      createdDate: -56,
      dayTotal: 1227944,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 793826,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 434118,
        },
      ],
      initialCreatedDate: "2023-12-27T00:00:00.000Z",
    },
    {
      createdDate: -57,
      dayTotal: 1024898,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 573470,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 451428,
        },
      ],
      initialCreatedDate: "2023-12-26T00:00:00.000Z",
    },
    {
      createdDate: -58,
      dayTotal: 0,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 0,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 0,
        },
      ],
      initialCreatedDate: "2023-12-25T00:00:00.000Z",
    },
    {
      createdDate: -59,
      dayTotal: 0,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 0,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 0,
        },
      ],
      initialCreatedDate: "2023-12-24T00:00:00.000Z",
    },
    {
      createdDate: -60,
      dayTotal: 0,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 0,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 0,
        },
      ],
      initialCreatedDate: "2023-12-23T00:00:00.000Z",
    },
    {
      createdDate: -61,
      dayTotal: 843890,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 381642,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 462248,
        },
      ],
      initialCreatedDate: "2023-12-22T00:00:00.000Z",
    },
    {
      createdDate: -62,
      dayTotal: 821833,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 492260,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 329573,
        },
      ],
      initialCreatedDate: "2023-12-21T00:00:00.000Z",
    },
    {
      createdDate: -63,
      dayTotal: 1146954,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 413066,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 733888,
        },
      ],
      initialCreatedDate: "2023-12-20T00:00:00.000Z",
    },
    {
      createdDate: -64,
      dayTotal: 1036875,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 518234,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 518641,
        },
      ],
      initialCreatedDate: "2023-12-19T00:00:00.000Z",
    },
    {
      createdDate: -65,
      dayTotal: null,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: null,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: null,
        },
      ],
      initialCreatedDate: "2023-12-18T00:00:00.000Z",
    },
    {
      createdDate: -66,
      dayTotal: 0,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 0,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 0,
        },
      ],
      initialCreatedDate: "2023-12-17T00:00:00.000Z",
    },
    {
      createdDate: -67,
      dayTotal: 0,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 0,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 0,
        },
      ],
      initialCreatedDate: "2023-12-16T00:00:00.000Z",
    },
    {
      createdDate: -68,
      dayTotal: 1147392,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 367885,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 779507,
        },
      ],
      initialCreatedDate: "2023-12-15T00:00:00.000Z",
    },
    {
      createdDate: -69,
      dayTotal: 1048026,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 447994,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 600032,
        },
      ],
      initialCreatedDate: "2023-12-14T00:00:00.000Z",
    },
    {
      createdDate: -70,
      dayTotal: 1010493,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 478864,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 531629,
        },
      ],
      initialCreatedDate: "2023-12-13T00:00:00.000Z",
    },
    {
      createdDate: -71,
      dayTotal: 1136156,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 560136,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 576020,
        },
      ],
      initialCreatedDate: "2023-12-12T00:00:00.000Z",
    },
    {
      createdDate: -72,
      dayTotal: null,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: null,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 736437,
        },
      ],
      initialCreatedDate: "2023-12-11T00:00:00.000Z",
    },
    {
      createdDate: -73,
      dayTotal: 0,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 0,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 0,
        },
      ],
      initialCreatedDate: "2023-12-10T00:00:00.000Z",
    },
    {
      createdDate: -74,
      dayTotal: 0,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 0,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 0,
        },
      ],
      initialCreatedDate: "2023-12-09T00:00:00.000Z",
    },
    {
      createdDate: -75,
      dayTotal: 900026,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 462134,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 437892,
        },
      ],
      initialCreatedDate: "2023-12-08T00:00:00.000Z",
    },
    {
      createdDate: -76,
      dayTotal: 1092561,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 427334,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 665227,
        },
      ],
      initialCreatedDate: "2023-12-07T00:00:00.000Z",
    },
    {
      createdDate: -77,
      dayTotal: 1064346,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 403517,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 660829,
        },
      ],
      initialCreatedDate: "2023-12-06T00:00:00.000Z",
    },
    {
      createdDate: -78,
      dayTotal: 1150724,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 521257,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 629467,
        },
      ],
      initialCreatedDate: "2023-12-05T00:00:00.000Z",
    },
    {
      createdDate: -79,
      dayTotal: null,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: null,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 568607,
        },
      ],
      initialCreatedDate: "2023-12-04T00:00:00.000Z",
    },
    {
      createdDate: -80,
      dayTotal: 0,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 0,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 0,
        },
      ],
      initialCreatedDate: "2023-12-03T00:00:00.000Z",
    },
    {
      createdDate: -81,
      dayTotal: 0,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 0,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 0,
        },
      ],
      initialCreatedDate: "2023-12-02T00:00:00.000Z",
    },
    {
      createdDate: -82,
      dayTotal: 1001335,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 409716,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 591619,
        },
      ],
      initialCreatedDate: "2023-12-01T00:00:00.000Z",
    },
    {
      createdDate: -83,
      dayTotal: 782891,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 322123,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 460768,
        },
      ],
      initialCreatedDate: "2023-11-30T00:00:00.000Z",
    },
    {
      createdDate: -84,
      dayTotal: 912058,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 426601,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 485457,
        },
      ],
      initialCreatedDate: "2023-11-29T00:00:00.000Z",
    },
    {
      createdDate: -85,
      dayTotal: 1601725,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 799575,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 802150,
        },
      ],
      initialCreatedDate: "2023-11-28T00:00:00.000Z",
    },
    {
      createdDate: -86,
      dayTotal: 1140238,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 356961,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 783277,
        },
      ],
      initialCreatedDate: "2023-11-27T00:00:00.000Z",
    },
    {
      createdDate: -87,
      dayTotal: 0,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 0,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 0,
        },
      ],
      initialCreatedDate: "2023-11-26T00:00:00.000Z",
    },
    {
      createdDate: -88,
      dayTotal: 0,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 0,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 0,
        },
      ],
      initialCreatedDate: "2023-11-25T00:00:00.000Z",
    },
    {
      createdDate: -89,
      dayTotal: 744003,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 303865,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 440138,
        },
      ],
      initialCreatedDate: "2023-11-24T00:00:00.000Z",
    },
    {
      createdDate: -90,
      dayTotal: 646043,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 332780,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 313263,
        },
      ],
      initialCreatedDate: "2023-11-23T00:00:00.000Z",
    },
    {
      createdDate: -91,
      dayTotal: 438645,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 438645,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 0,
        },
      ],
      initialCreatedDate: "2023-11-22T00:00:00.000Z",
    },
    {
      createdDate: -92,
      dayTotal: 834422,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 834422,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 0,
        },
      ],
      initialCreatedDate: "2023-11-21T00:00:00.000Z",
    },
    {
      createdDate: -93,
      dayTotal: null,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 370366,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: null,
        },
      ],
      initialCreatedDate: "2023-11-20T00:00:00.000Z",
    },
    {
      createdDate: -94,
      dayTotal: 0,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 0,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 0,
        },
      ],
      initialCreatedDate: "2023-11-19T00:00:00.000Z",
    },
    {
      createdDate: -95,
      dayTotal: 0,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 0,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 0,
        },
      ],
      initialCreatedDate: "2023-11-18T00:00:00.000Z",
    },
    {
      createdDate: -96,
      dayTotal: 519046,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 309212,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 209834,
        },
      ],
      initialCreatedDate: "2023-11-17T00:00:00.000Z",
    },
    {
      createdDate: -97,
      dayTotal: 524545,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 347673,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 176872,
        },
      ],
      initialCreatedDate: "2023-11-16T00:00:00.000Z",
    },
    {
      createdDate: -98,
      dayTotal: 558680,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 340119,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 218561,
        },
      ],
      initialCreatedDate: "2023-11-15T00:00:00.000Z",
    },
    {
      createdDate: -99,
      dayTotal: 1044324,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 802839,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 241485,
        },
      ],
      initialCreatedDate: "2023-11-14T00:00:00.000Z",
    },
    {
      createdDate: -100,
      dayTotal: 517598,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 381389,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 136209,
        },
      ],
      initialCreatedDate: "2023-11-13T00:00:00.000Z",
    },
    {
      createdDate: -101,
      dayTotal: 0,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 0,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 0,
        },
      ],
      initialCreatedDate: "2023-11-12T00:00:00.000Z",
    },
    {
      createdDate: -102,
      dayTotal: 0,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 0,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 0,
        },
      ],
      initialCreatedDate: "2023-11-11T00:00:00.000Z",
    },
    {
      createdDate: -103,
      dayTotal: 604385,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 374216,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 230169,
        },
      ],
      initialCreatedDate: "2023-11-10T00:00:00.000Z",
    },
    {
      createdDate: -104,
      dayTotal: 924388,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 689503,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 234885,
        },
      ],
      initialCreatedDate: "2023-11-09T00:00:00.000Z",
    },
    {
      createdDate: -105,
      dayTotal: 333055,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 36667,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 296388,
        },
      ],
      initialCreatedDate: "2023-11-08T00:00:00.000Z",
    },
    {
      createdDate: -106,
      dayTotal: 1178746,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 844411,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 334335,
        },
      ],
      initialCreatedDate: "2023-11-07T00:00:00.000Z",
    },
    {
      createdDate: -107,
      dayTotal: 458107,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 0,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 458107,
        },
      ],
      initialCreatedDate: "2023-11-06T00:00:00.000Z",
    },
    {
      createdDate: -108,
      dayTotal: 0,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 0,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 0,
        },
      ],
      initialCreatedDate: "2023-11-05T00:00:00.000Z",
    },
    {
      createdDate: -109,
      dayTotal: 0,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 0,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 0,
        },
      ],
      initialCreatedDate: "2023-11-04T00:00:00.000Z",
    },
    {
      createdDate: -110,
      dayTotal: 274255,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 0,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 274255,
        },
      ],
      initialCreatedDate: "2023-11-03T00:00:00.000Z",
    },
    {
      createdDate: -111,
      dayTotal: 271289,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 0,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 271289,
        },
      ],
      initialCreatedDate: "2023-11-02T00:00:00.000Z",
    },
    {
      createdDate: -112,
      dayTotal: 0,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 0,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 0,
        },
      ],
      initialCreatedDate: "2023-11-01T00:00:00.000Z",
    },
    {
      createdDate: -113,
      dayTotal: 306765,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 0,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 306765,
        },
      ],
      initialCreatedDate: "2023-10-31T00:00:00.000Z",
    },
    {
      createdDate: -114,
      dayTotal: 158538,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 0,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 158538,
        },
      ],
      initialCreatedDate: "2023-10-30T00:00:00.000Z",
    },
    {
      createdDate: -115,
      dayTotal: 0,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 0,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 0,
        },
      ],
      initialCreatedDate: "2023-10-29T00:00:00.000Z",
    },
    {
      createdDate: -116,
      dayTotal: 0,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 0,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 0,
        },
      ],
      initialCreatedDate: "2023-10-28T00:00:00.000Z",
    },
    {
      createdDate: -117,
      dayTotal: 163718,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 0,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 163718,
        },
      ],
      initialCreatedDate: "2023-10-27T00:00:00.000Z",
    },
    {
      createdDate: -118,
      dayTotal: 58658,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 0,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 58658,
        },
      ],
      initialCreatedDate: "2023-10-26T00:00:00.000Z",
    },
    {
      createdDate: -119,
      dayTotal: 0,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 0,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 0,
        },
      ],
      initialCreatedDate: "2023-10-25T00:00:00.000Z",
    },
    {
      createdDate: -120,
      dayTotal: 66965,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 0,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 66965,
        },
      ],
      initialCreatedDate: "2023-10-24T00:00:00.000Z",
    },
    {
      createdDate: -121,
      dayTotal: 335396,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 0,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 335396,
        },
      ],
      initialCreatedDate: "2023-10-23T00:00:00.000Z",
    },
    {
      createdDate: -122,
      dayTotal: 0,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 0,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 0,
        },
      ],
      initialCreatedDate: "2023-10-22T00:00:00.000Z",
    },
    {
      createdDate: -123,
      dayTotal: 0,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 0,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 0,
        },
      ],
      initialCreatedDate: "2023-10-21T00:00:00.000Z",
    },
    {
      createdDate: -124,
      dayTotal: 194796,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 0,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 194796,
        },
      ],
      initialCreatedDate: "2023-10-20T00:00:00.000Z",
    },
    {
      createdDate: -125,
      dayTotal: 276752,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 0,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 276752,
        },
      ],
      initialCreatedDate: "2023-10-19T00:00:00.000Z",
    },
    {
      createdDate: -126,
      dayTotal: 270526,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 0,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 270526,
        },
      ],
      initialCreatedDate: "2023-10-18T00:00:00.000Z",
    },
    {
      createdDate: -127,
      dayTotal: 337379,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 0,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 337379,
        },
      ],
      initialCreatedDate: "2023-10-17T00:00:00.000Z",
    },
    {
      createdDate: -128,
      dayTotal: 334412,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 0,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 334412,
        },
      ],
      initialCreatedDate: "2023-10-16T00:00:00.000Z",
    },
    {
      createdDate: -129,
      dayTotal: 0,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 0,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 0,
        },
      ],
      initialCreatedDate: "2023-10-15T00:00:00.000Z",
    },
    {
      createdDate: -130,
      dayTotal: 0,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 0,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 0,
        },
      ],
      initialCreatedDate: "2023-10-14T00:00:00.000Z",
    },
    {
      createdDate: -131,
      dayTotal: 382475,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 0,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 382475,
        },
      ],
      initialCreatedDate: "2023-10-13T00:00:00.000Z",
    },
    {
      createdDate: -132,
      dayTotal: 203089,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 0,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 203089,
        },
      ],
      initialCreatedDate: "2023-10-12T00:00:00.000Z",
    },
    {
      createdDate: -133,
      dayTotal: 150789,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 0,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 150789,
        },
      ],
      initialCreatedDate: "2023-10-11T00:00:00.000Z",
    },
    {
      createdDate: -134,
      dayTotal: 422049,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 0,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 422049,
        },
      ],
      initialCreatedDate: "2023-10-10T00:00:00.000Z",
    },
    {
      createdDate: -135,
      dayTotal: 209905,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 0,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 209905,
        },
      ],
      initialCreatedDate: "2023-10-09T00:00:00.000Z",
    },
    {
      createdDate: -136,
      dayTotal: 0,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 0,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 0,
        },
      ],
      initialCreatedDate: "2023-10-08T00:00:00.000Z",
    },
    {
      createdDate: -137,
      dayTotal: 0,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 0,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 0,
        },
      ],
      initialCreatedDate: "2023-10-07T00:00:00.000Z",
    },
    {
      createdDate: -138,
      dayTotal: 208326,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 0,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 208326,
        },
      ],
      initialCreatedDate: "2023-10-06T00:00:00.000Z",
    },
    {
      createdDate: -139,
      dayTotal: 0,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 0,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 0,
        },
      ],
      initialCreatedDate: "2023-10-05T00:00:00.000Z",
    },
    {
      createdDate: -140,
      dayTotal: null,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 0,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: null,
        },
      ],
      initialCreatedDate: "2023-10-04T00:00:00.000Z",
    },
    {
      createdDate: -141,
      dayTotal: 0,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 0,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 0,
        },
      ],
      initialCreatedDate: "2023-10-03T00:00:00.000Z",
    },
  ],

  apFundedByMarketplaceByDay: [
    {
      createdDate: -11,
      dayTotal: 0,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 0,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 0,
        },
      ],
      initialCreatedDate: "2024-02-10T00:00:00.000Z",
    },
    {
      createdDate: -12,
      dayTotal: 259405,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 259405,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 0,
        },
      ],
      initialCreatedDate: "2024-02-09T00:00:00.000Z",
    },
    {
      createdDate: -13,
      dayTotal: 265600,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 258452,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 7148,
        },
      ],
      initialCreatedDate: "2024-02-08T00:00:00.000Z",
    },
    {
      createdDate: -14,
      dayTotal: 430525,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 276083,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 154442,
        },
      ],
      initialCreatedDate: "2024-02-07T00:00:00.000Z",
    },
    {
      createdDate: -15,
      dayTotal: 714545,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 373662,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 340883,
        },
      ],
      initialCreatedDate: "2024-02-06T00:00:00.000Z",
    },
    {
      createdDate: -16,
      dayTotal: 1122570,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 888910,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 233660,
        },
      ],
      initialCreatedDate: "2024-02-05T00:00:00.000Z",
    },
    {
      createdDate: -17,
      dayTotal: 0,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 0,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 0,
        },
      ],
      initialCreatedDate: "2024-02-04T00:00:00.000Z",
    },
    {
      createdDate: -18,
      dayTotal: 0,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 0,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 0,
        },
      ],
      initialCreatedDate: "2024-02-03T00:00:00.000Z",
    },
    {
      createdDate: -19,
      dayTotal: 498613,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 315571,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 183042,
        },
      ],
      initialCreatedDate: "2024-02-02T00:00:00.000Z",
    },
    {
      createdDate: -20,
      dayTotal: 562589,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 335059,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 227530,
        },
      ],
      initialCreatedDate: "2024-02-01T00:00:00.000Z",
    },
    {
      createdDate: -21,
      dayTotal: 472789,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 318095,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 154694,
        },
      ],
      initialCreatedDate: "2024-01-31T00:00:00.000Z",
    },
    {
      createdDate: -22,
      dayTotal: 1102043,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 776165,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 325878,
        },
      ],
      initialCreatedDate: "2024-01-30T00:00:00.000Z",
    },
    {
      createdDate: -23,
      dayTotal: 414247,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 294896,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 119351,
        },
      ],
      initialCreatedDate: "2024-01-29T00:00:00.000Z",
    },
    {
      createdDate: -24,
      dayTotal: 0,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 0,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 0,
        },
      ],
      initialCreatedDate: "2024-01-28T00:00:00.000Z",
    },
    {
      createdDate: -25,
      dayTotal: 0,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 0,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 0,
        },
      ],
      initialCreatedDate: "2024-01-27T00:00:00.000Z",
    },
    {
      createdDate: -26,
      dayTotal: 555268,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 327769,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 227499,
        },
      ],
      initialCreatedDate: "2024-01-26T00:00:00.000Z",
    },
    {
      createdDate: -27,
      dayTotal: 459016,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 294298,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 164718,
        },
      ],
      initialCreatedDate: "2024-01-25T00:00:00.000Z",
    },
    {
      createdDate: -28,
      dayTotal: 551552,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 302201,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 249351,
        },
      ],
      initialCreatedDate: "2024-01-24T00:00:00.000Z",
    },
    {
      createdDate: -29,
      dayTotal: 901286,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 730317,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 170969,
        },
      ],
      initialCreatedDate: "2024-01-23T00:00:00.000Z",
    },
    {
      createdDate: -30,
      dayTotal: 464082,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 283568,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 180514,
        },
      ],
      initialCreatedDate: "2024-01-22T00:00:00.000Z",
    },
    {
      createdDate: -31,
      dayTotal: 0,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 0,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 0,
        },
      ],
      initialCreatedDate: "2024-01-21T00:00:00.000Z",
    },
    {
      createdDate: -32,
      dayTotal: 0,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 0,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 0,
        },
      ],
      initialCreatedDate: "2024-01-20T00:00:00.000Z",
    },
    {
      createdDate: -33,
      dayTotal: 544267,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 272710,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 271557,
        },
      ],
      initialCreatedDate: "2024-01-19T00:00:00.000Z",
    },
    {
      createdDate: -34,
      dayTotal: 472572,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 291442,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 181130,
        },
      ],
      initialCreatedDate: "2024-01-18T00:00:00.000Z",
    },
    {
      createdDate: -35,
      dayTotal: 715716,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 343506,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 372210,
        },
      ],
      initialCreatedDate: "2024-01-17T00:00:00.000Z",
    },
    {
      createdDate: -36,
      dayTotal: 865349,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 725151,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 140198,
        },
      ],
      initialCreatedDate: "2024-01-16T00:00:00.000Z",
    },
    {
      createdDate: -37,
      dayTotal: 684905,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 317811,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 367094,
        },
      ],
      initialCreatedDate: "2024-01-15T00:00:00.000Z",
    },
    {
      createdDate: -38,
      dayTotal: 0,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 0,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 0,
        },
      ],
      initialCreatedDate: "2024-01-14T00:00:00.000Z",
    },
    {
      createdDate: -39,
      dayTotal: 0,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 0,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 0,
        },
      ],
      initialCreatedDate: "2024-01-13T00:00:00.000Z",
    },
    {
      createdDate: -40,
      dayTotal: 599803,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 371942,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 227861,
        },
      ],
      initialCreatedDate: "2024-01-12T00:00:00.000Z",
    },
    {
      createdDate: -41,
      dayTotal: 568072,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 308570,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 259502,
        },
      ],
      initialCreatedDate: "2024-01-11T00:00:00.000Z",
    },
    {
      createdDate: -42,
      dayTotal: 518457,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 270146,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 248311,
        },
      ],
      initialCreatedDate: "2024-01-10T00:00:00.000Z",
    },
    {
      createdDate: -43,
      dayTotal: 944276,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 712904,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 231372,
        },
      ],
      initialCreatedDate: "2024-01-09T00:00:00.000Z",
    },
    {
      createdDate: -44,
      dayTotal: 689297,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 372617,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 316680,
        },
      ],
      initialCreatedDate: "2024-01-08T00:00:00.000Z",
    },
    {
      createdDate: -45,
      dayTotal: 0,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 0,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 0,
        },
      ],
      initialCreatedDate: "2024-01-07T00:00:00.000Z",
    },
    {
      createdDate: -46,
      dayTotal: 0,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 0,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 0,
        },
      ],
      initialCreatedDate: "2024-01-06T00:00:00.000Z",
    },
    {
      createdDate: -47,
      dayTotal: 622251,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 328206,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 294045,
        },
      ],
      initialCreatedDate: "2024-01-05T00:00:00.000Z",
    },
    {
      createdDate: -48,
      dayTotal: 584676,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 352044,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 232632,
        },
      ],
      initialCreatedDate: "2024-01-04T00:00:00.000Z",
    },
    {
      createdDate: -49,
      dayTotal: 1124792,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 790341,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 334451,
        },
      ],
      initialCreatedDate: "2024-01-03T00:00:00.000Z",
    },
    {
      createdDate: -50,
      dayTotal: 830068,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 540939,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 289129,
        },
      ],
      initialCreatedDate: "2024-01-02T00:00:00.000Z",
    },
    {
      createdDate: -51,
      dayTotal: 0,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 0,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 0,
        },
      ],
      initialCreatedDate: "2024-01-01T00:00:00.000Z",
    },
    {
      createdDate: -52,
      dayTotal: 0,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 0,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 0,
        },
      ],
      initialCreatedDate: "2023-12-31T00:00:00.000Z",
    },
    {
      createdDate: -53,
      dayTotal: 0,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 0,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 0,
        },
      ],
      initialCreatedDate: "2023-12-30T00:00:00.000Z",
    },
    {
      createdDate: -54,
      dayTotal: 370281,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 326782,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 43499,
        },
      ],
      initialCreatedDate: "2023-12-29T00:00:00.000Z",
    },
    {
      createdDate: -55,
      dayTotal: 595620,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 321834,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 273786,
        },
      ],
      initialCreatedDate: "2023-12-28T00:00:00.000Z",
    },
    {
      createdDate: -56,
      dayTotal: 982355,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 635061,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 347294,
        },
      ],
      initialCreatedDate: "2023-12-27T00:00:00.000Z",
    },
    {
      createdDate: -57,
      dayTotal: 819918,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 458776,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 361142,
        },
      ],
      initialCreatedDate: "2023-12-26T00:00:00.000Z",
    },
    {
      createdDate: -58,
      dayTotal: 0,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 0,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 0,
        },
      ],
      initialCreatedDate: "2023-12-25T00:00:00.000Z",
    },
    {
      createdDate: -59,
      dayTotal: 0,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 0,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 0,
        },
      ],
      initialCreatedDate: "2023-12-24T00:00:00.000Z",
    },
    {
      createdDate: -60,
      dayTotal: 0,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 0,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 0,
        },
      ],
      initialCreatedDate: "2023-12-23T00:00:00.000Z",
    },
    {
      createdDate: -61,
      dayTotal: 675112,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 305314,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 369798,
        },
      ],
      initialCreatedDate: "2023-12-22T00:00:00.000Z",
    },
    {
      createdDate: -62,
      dayTotal: 657466,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 393808,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 263658,
        },
      ],
      initialCreatedDate: "2023-12-21T00:00:00.000Z",
    },
    {
      createdDate: -63,
      dayTotal: 917563,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 330453,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 587110,
        },
      ],
      initialCreatedDate: "2023-12-20T00:00:00.000Z",
    },
    {
      createdDate: -64,
      dayTotal: 829500,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 414587,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 414913,
        },
      ],
      initialCreatedDate: "2023-12-19T00:00:00.000Z",
    },
    {
      createdDate: -65,
      dayTotal: null,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: null,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 863191,
        },
      ],
      initialCreatedDate: "2023-12-18T00:00:00.000Z",
    },
    {
      createdDate: -66,
      dayTotal: 0,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 0,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 0,
        },
      ],
      initialCreatedDate: "2023-12-17T00:00:00.000Z",
    },
    {
      createdDate: -67,
      dayTotal: 0,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 0,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 0,
        },
      ],
      initialCreatedDate: "2023-12-16T00:00:00.000Z",
    },
    {
      createdDate: -68,
      dayTotal: 917914,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 294308,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 623606,
        },
      ],
      initialCreatedDate: "2023-12-15T00:00:00.000Z",
    },
    {
      createdDate: -69,
      dayTotal: 838421,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 358395,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 480026,
        },
      ],
      initialCreatedDate: "2023-12-14T00:00:00.000Z",
    },
    {
      createdDate: -70,
      dayTotal: 808394,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 383091,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 425303,
        },
      ],
      initialCreatedDate: "2023-12-13T00:00:00.000Z",
    },
    {
      createdDate: -71,
      dayTotal: 908925,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 448109,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 460816,
        },
      ],
      initialCreatedDate: "2023-12-12T00:00:00.000Z",
    },
    {
      createdDate: -72,
      dayTotal: 1469035,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 879885,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 589150,
        },
      ],
      initialCreatedDate: "2023-12-11T00:00:00.000Z",
    },
    {
      createdDate: -73,
      dayTotal: 0,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 0,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 0,
        },
      ],
      initialCreatedDate: "2023-12-10T00:00:00.000Z",
    },
    {
      createdDate: -74,
      dayTotal: 0,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 0,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 0,
        },
      ],
      initialCreatedDate: "2023-12-09T00:00:00.000Z",
    },
    {
      createdDate: -75,
      dayTotal: 720021,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 369707,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 350314,
        },
      ],
      initialCreatedDate: "2023-12-08T00:00:00.000Z",
    },
    {
      createdDate: -76,
      dayTotal: 874049,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 341867,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 532182,
        },
      ],
      initialCreatedDate: "2023-12-07T00:00:00.000Z",
    },
    {
      createdDate: -77,
      dayTotal: 851477,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 322814,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 528663,
        },
      ],
      initialCreatedDate: "2023-12-06T00:00:00.000Z",
    },
    {
      createdDate: -78,
      dayTotal: 920580,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 417006,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 503574,
        },
      ],
      initialCreatedDate: "2023-12-05T00:00:00.000Z",
    },
    {
      createdDate: -79,
      dayTotal: 1328781,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 873895,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 454886,
        },
      ],
      initialCreatedDate: "2023-12-04T00:00:00.000Z",
    },
    {
      createdDate: -80,
      dayTotal: 0,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 0,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 0,
        },
      ],
      initialCreatedDate: "2023-12-03T00:00:00.000Z",
    },
    {
      createdDate: -81,
      dayTotal: 0,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 0,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 0,
        },
      ],
      initialCreatedDate: "2023-12-02T00:00:00.000Z",
    },
    {
      createdDate: -82,
      dayTotal: 801068,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 327773,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 473295,
        },
      ],
      initialCreatedDate: "2023-12-01T00:00:00.000Z",
    },
    {
      createdDate: -83,
      dayTotal: 626312,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 257698,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 368614,
        },
      ],
      initialCreatedDate: "2023-11-30T00:00:00.000Z",
    },
    {
      createdDate: -84,
      dayTotal: 729647,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 341281,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 388366,
        },
      ],
      initialCreatedDate: "2023-11-29T00:00:00.000Z",
    },
    {
      createdDate: -85,
      dayTotal: 1281380,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 639660,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 641720,
        },
      ],
      initialCreatedDate: "2023-11-28T00:00:00.000Z",
    },
    {
      createdDate: -86,
      dayTotal: 912190,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 285568,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 626622,
        },
      ],
      initialCreatedDate: "2023-11-27T00:00:00.000Z",
    },
    {
      createdDate: -87,
      dayTotal: 0,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 0,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 0,
        },
      ],
      initialCreatedDate: "2023-11-26T00:00:00.000Z",
    },
    {
      createdDate: -88,
      dayTotal: 0,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 0,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 0,
        },
      ],
      initialCreatedDate: "2023-11-25T00:00:00.000Z",
    },
    {
      createdDate: -89,
      dayTotal: 595202,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 243092,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 352110,
        },
      ],
      initialCreatedDate: "2023-11-24T00:00:00.000Z",
    },
    {
      createdDate: -90,
      dayTotal: 516834,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 266224,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 250610,
        },
      ],
      initialCreatedDate: "2023-11-23T00:00:00.000Z",
    },
    {
      createdDate: -91,
      dayTotal: 350916,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 350916,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 0,
        },
      ],
      initialCreatedDate: "2023-11-22T00:00:00.000Z",
    },
    {
      createdDate: -92,
      dayTotal: 667538,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 667538,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 0,
        },
      ],
      initialCreatedDate: "2023-11-21T00:00:00.000Z",
    },
    {
      createdDate: -93,
      dayTotal: null,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 296292,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: null,
        },
      ],
      initialCreatedDate: "2023-11-20T00:00:00.000Z",
    },
    {
      createdDate: -94,
      dayTotal: 0,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 0,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 0,
        },
      ],
      initialCreatedDate: "2023-11-19T00:00:00.000Z",
    },
    {
      createdDate: -95,
      dayTotal: 0,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 0,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 0,
        },
      ],
      initialCreatedDate: "2023-11-18T00:00:00.000Z",
    },
    {
      createdDate: -96,
      dayTotal: 415237,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 247370,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 167867,
        },
      ],
      initialCreatedDate: "2023-11-17T00:00:00.000Z",
    },
    {
      createdDate: -97,
      dayTotal: 419636,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 278138,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 141498,
        },
      ],
      initialCreatedDate: "2023-11-16T00:00:00.000Z",
    },
    {
      createdDate: -98,
      dayTotal: 446944,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 272095,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 174849,
        },
      ],
      initialCreatedDate: "2023-11-15T00:00:00.000Z",
    },
    {
      createdDate: -99,
      dayTotal: 835460,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 642272,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 193188,
        },
      ],
      initialCreatedDate: "2023-11-14T00:00:00.000Z",
    },
    {
      createdDate: -100,
      dayTotal: 414078,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 305111,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 108967,
        },
      ],
      initialCreatedDate: "2023-11-13T00:00:00.000Z",
    },
    {
      createdDate: -101,
      dayTotal: 0,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 0,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 0,
        },
      ],
      initialCreatedDate: "2023-11-12T00:00:00.000Z",
    },
    {
      createdDate: -102,
      dayTotal: 0,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 0,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 0,
        },
      ],
      initialCreatedDate: "2023-11-11T00:00:00.000Z",
    },
    {
      createdDate: -103,
      dayTotal: 483508,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 299373,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 184135,
        },
      ],
      initialCreatedDate: "2023-11-10T00:00:00.000Z",
    },
    {
      createdDate: -104,
      dayTotal: 739510,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 551602,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 187908,
        },
      ],
      initialCreatedDate: "2023-11-09T00:00:00.000Z",
    },
    {
      createdDate: -105,
      dayTotal: 266444,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 29334,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 237110,
        },
      ],
      initialCreatedDate: "2023-11-08T00:00:00.000Z",
    },
    {
      createdDate: -106,
      dayTotal: 942997,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 675529,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 267468,
        },
      ],
      initialCreatedDate: "2023-11-07T00:00:00.000Z",
    },
    {
      createdDate: -107,
      dayTotal: 366486,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 0,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 366486,
        },
      ],
      initialCreatedDate: "2023-11-06T00:00:00.000Z",
    },
    {
      createdDate: -108,
      dayTotal: 0,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 0,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 0,
        },
      ],
      initialCreatedDate: "2023-11-05T00:00:00.000Z",
    },
    {
      createdDate: -109,
      dayTotal: 0,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 0,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 0,
        },
      ],
      initialCreatedDate: "2023-11-04T00:00:00.000Z",
    },
    {
      createdDate: -110,
      dayTotal: 219404,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 0,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 219404,
        },
      ],
      initialCreatedDate: "2023-11-03T00:00:00.000Z",
    },
    {
      createdDate: -111,
      dayTotal: 217031,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 0,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 217031,
        },
      ],
      initialCreatedDate: "2023-11-02T00:00:00.000Z",
    },
    {
      createdDate: -112,
      dayTotal: 0,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 0,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 0,
        },
      ],
      initialCreatedDate: "2023-11-01T00:00:00.000Z",
    },
    {
      createdDate: -113,
      dayTotal: 245412,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 0,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 245412,
        },
      ],
      initialCreatedDate: "2023-10-31T00:00:00.000Z",
    },
    {
      createdDate: -114,
      dayTotal: 126830,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 0,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 126830,
        },
      ],
      initialCreatedDate: "2023-10-30T00:00:00.000Z",
    },
    {
      createdDate: -115,
      dayTotal: 0,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 0,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 0,
        },
      ],
      initialCreatedDate: "2023-10-29T00:00:00.000Z",
    },
    {
      createdDate: -116,
      dayTotal: 0,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 0,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 0,
        },
      ],
      initialCreatedDate: "2023-10-28T00:00:00.000Z",
    },
    {
      createdDate: -117,
      dayTotal: 130974,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 0,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 130974,
        },
      ],
      initialCreatedDate: "2023-10-27T00:00:00.000Z",
    },
    {
      createdDate: -118,
      dayTotal: 46926,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 0,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 46926,
        },
      ],
      initialCreatedDate: "2023-10-26T00:00:00.000Z",
    },
    {
      createdDate: -119,
      dayTotal: 0,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 0,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 0,
        },
      ],
      initialCreatedDate: "2023-10-25T00:00:00.000Z",
    },
    {
      createdDate: -120,
      dayTotal: 53572,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 0,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 53572,
        },
      ],
      initialCreatedDate: "2023-10-24T00:00:00.000Z",
    },
    {
      createdDate: -121,
      dayTotal: 268317,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 0,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 268317,
        },
      ],
      initialCreatedDate: "2023-10-23T00:00:00.000Z",
    },
    {
      createdDate: -122,
      dayTotal: 0,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 0,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 0,
        },
      ],
      initialCreatedDate: "2023-10-22T00:00:00.000Z",
    },
    {
      createdDate: -123,
      dayTotal: 0,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 0,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 0,
        },
      ],
      initialCreatedDate: "2023-10-21T00:00:00.000Z",
    },
    {
      createdDate: -124,
      dayTotal: 155837,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 0,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 155837,
        },
      ],
      initialCreatedDate: "2023-10-20T00:00:00.000Z",
    },
    {
      createdDate: -125,
      dayTotal: 221402,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 0,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 221402,
        },
      ],
      initialCreatedDate: "2023-10-19T00:00:00.000Z",
    },
    {
      createdDate: -126,
      dayTotal: 216421,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 0,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 216421,
        },
      ],
      initialCreatedDate: "2023-10-18T00:00:00.000Z",
    },
    {
      createdDate: -127,
      dayTotal: 269903,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 0,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 269903,
        },
      ],
      initialCreatedDate: "2023-10-17T00:00:00.000Z",
    },
    {
      createdDate: -128,
      dayTotal: 267530,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 0,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 267530,
        },
      ],
      initialCreatedDate: "2023-10-16T00:00:00.000Z",
    },
    {
      createdDate: -129,
      dayTotal: 0,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 0,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 0,
        },
      ],
      initialCreatedDate: "2023-10-15T00:00:00.000Z",
    },
    {
      createdDate: -130,
      dayTotal: 0,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 0,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 0,
        },
      ],
      initialCreatedDate: "2023-10-14T00:00:00.000Z",
    },
    {
      createdDate: -131,
      dayTotal: 305980,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 0,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 305980,
        },
      ],
      initialCreatedDate: "2023-10-13T00:00:00.000Z",
    },
    {
      createdDate: -132,
      dayTotal: 162471,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 0,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 162471,
        },
      ],
      initialCreatedDate: "2023-10-12T00:00:00.000Z",
    },
    {
      createdDate: -133,
      dayTotal: 120631,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 0,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 120631,
        },
      ],
      initialCreatedDate: "2023-10-11T00:00:00.000Z",
    },
    {
      createdDate: -134,
      dayTotal: 337639,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 0,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 337639,
        },
      ],
      initialCreatedDate: "2023-10-10T00:00:00.000Z",
    },
    {
      createdDate: -135,
      dayTotal: 167924,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 0,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 167924,
        },
      ],
      initialCreatedDate: "2023-10-09T00:00:00.000Z",
    },
    {
      createdDate: -136,
      dayTotal: 0,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 0,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 0,
        },
      ],
      initialCreatedDate: "2023-10-08T00:00:00.000Z",
    },
    {
      createdDate: -137,
      dayTotal: 0,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 0,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 0,
        },
      ],
      initialCreatedDate: "2023-10-07T00:00:00.000Z",
    },
    {
      createdDate: -138,
      dayTotal: 166661,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 0,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 166661,
        },
      ],
      initialCreatedDate: "2023-10-06T00:00:00.000Z",
    },
    {
      createdDate: -139,
      dayTotal: 0,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 0,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 0,
        },
      ],
      initialCreatedDate: "2023-10-05T00:00:00.000Z",
    },
    {
      createdDate: -140,
      dayTotal: null,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 0,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: null,
        },
      ],
      initialCreatedDate: "2023-10-04T00:00:00.000Z",
    },
    {
      createdDate: -141,
      dayTotal: 0,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 0,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 0,
        },
      ],
      initialCreatedDate: "2023-10-03T00:00:00.000Z",
    },
  ],

  financingRateByMarketplaceByDay: [
    {
      createdDate: -11,
      dayTotal: 0,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 0,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 0,
        },
      ],
      initialCreatedDate: "2024-02-10T00:00:00.000Z",
    },
    {
      createdDate: -12,
      dayTotal: 0.8,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 0.8,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 0,
        },
      ],
      initialCreatedDate: "2024-02-09T00:00:00.000Z",
    },
    {
      createdDate: -13,
      dayTotal: 0.8,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 0.8,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 0.8,
        },
      ],
      initialCreatedDate: "2024-02-08T00:00:00.000Z",
    },
    {
      createdDate: -14,
      dayTotal: 0.8,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 0.8,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 0.8,
        },
      ],
      initialCreatedDate: "2024-02-07T00:00:00.000Z",
    },
    {
      createdDate: -15,
      dayTotal: 0.8,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 0.8,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 0.8,
        },
      ],
      initialCreatedDate: "2024-02-06T00:00:00.000Z",
    },
    {
      createdDate: -16,
      dayTotal: null,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: null,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 0.8,
        },
      ],
      initialCreatedDate: "2024-02-05T00:00:00.000Z",
    },
    {
      createdDate: -17,
      dayTotal: 0,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 0,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 0,
        },
      ],
      initialCreatedDate: "2024-02-04T00:00:00.000Z",
    },
    {
      createdDate: -18,
      dayTotal: 0,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 0,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 0,
        },
      ],
      initialCreatedDate: "2024-02-03T00:00:00.000Z",
    },
    {
      createdDate: -19,
      dayTotal: 0.8,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 0.8,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 0.8,
        },
      ],
      initialCreatedDate: "2024-02-02T00:00:00.000Z",
    },
    {
      createdDate: -20,
      dayTotal: 0.8,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 0.8,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 0.8,
        },
      ],
      initialCreatedDate: "2024-02-01T00:00:00.000Z",
    },
    {
      createdDate: -21,
      dayTotal: 0.8,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 0.8,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 0.8,
        },
      ],
      initialCreatedDate: "2024-01-31T00:00:00.000Z",
    },
    {
      createdDate: -22,
      dayTotal: 0.8,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 0.8,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 0.8,
        },
      ],
      initialCreatedDate: "2024-01-30T00:00:00.000Z",
    },
    {
      createdDate: -23,
      dayTotal: 0.8,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 0.8,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 0.8,
        },
      ],
      initialCreatedDate: "2024-01-29T00:00:00.000Z",
    },
    {
      createdDate: -24,
      dayTotal: 0,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 0,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 0,
        },
      ],
      initialCreatedDate: "2024-01-28T00:00:00.000Z",
    },
    {
      createdDate: -25,
      dayTotal: 0,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 0,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 0,
        },
      ],
      initialCreatedDate: "2024-01-27T00:00:00.000Z",
    },
    {
      createdDate: -26,
      dayTotal: 0.8,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 0.8,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 0.8,
        },
      ],
      initialCreatedDate: "2024-01-26T00:00:00.000Z",
    },
    {
      createdDate: -27,
      dayTotal: 0.8,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 0.8,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 0.8,
        },
      ],
      initialCreatedDate: "2024-01-25T00:00:00.000Z",
    },
    {
      createdDate: -28,
      dayTotal: 0.8,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 0.8,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 0.8,
        },
      ],
      initialCreatedDate: "2024-01-24T00:00:00.000Z",
    },
    {
      createdDate: -29,
      dayTotal: 0.8,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 0.8,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 0.8,
        },
      ],
      initialCreatedDate: "2024-01-23T00:00:00.000Z",
    },
    {
      createdDate: -30,
      dayTotal: 0.8,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 0.8,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 0.8,
        },
      ],
      initialCreatedDate: "2024-01-22T00:00:00.000Z",
    },
    {
      createdDate: -31,
      dayTotal: 0,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 0,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 0,
        },
      ],
      initialCreatedDate: "2024-01-21T00:00:00.000Z",
    },
    {
      createdDate: -32,
      dayTotal: 0,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 0,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 0,
        },
      ],
      initialCreatedDate: "2024-01-20T00:00:00.000Z",
    },
    {
      createdDate: -33,
      dayTotal: 0.8,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 0.8,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 0.8,
        },
      ],
      initialCreatedDate: "2024-01-19T00:00:00.000Z",
    },
    {
      createdDate: -34,
      dayTotal: 0.8,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 0.8,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 0.8,
        },
      ],
      initialCreatedDate: "2024-01-18T00:00:00.000Z",
    },
    {
      createdDate: -35,
      dayTotal: 0.8,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 0.8,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 0.8,
        },
      ],
      initialCreatedDate: "2024-01-17T00:00:00.000Z",
    },
    {
      createdDate: -36,
      dayTotal: 0.8,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 0.8,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 0.8,
        },
      ],
      initialCreatedDate: "2024-01-16T00:00:00.000Z",
    },
    {
      createdDate: -37,
      dayTotal: 0.8,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 0.8,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 0.8,
        },
      ],
      initialCreatedDate: "2024-01-15T00:00:00.000Z",
    },
    {
      createdDate: -38,
      dayTotal: 0,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 0,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 0,
        },
      ],
      initialCreatedDate: "2024-01-14T00:00:00.000Z",
    },
    {
      createdDate: -39,
      dayTotal: 0,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 0,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 0,
        },
      ],
      initialCreatedDate: "2024-01-13T00:00:00.000Z",
    },
    {
      createdDate: -40,
      dayTotal: 0.8,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 0.8,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 0.8,
        },
      ],
      initialCreatedDate: "2024-01-12T00:00:00.000Z",
    },
    {
      createdDate: -41,
      dayTotal: 0.8,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 0.8,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 0.8,
        },
      ],
      initialCreatedDate: "2024-01-11T00:00:00.000Z",
    },
    {
      createdDate: -42,
      dayTotal: 0.8,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 0.8,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 0.8,
        },
      ],
      initialCreatedDate: "2024-01-10T00:00:00.000Z",
    },
    {
      createdDate: -43,
      dayTotal: 0.8,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 0.8,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 0.8,
        },
      ],
      initialCreatedDate: "2024-01-09T00:00:00.000Z",
    },
    {
      createdDate: -44,
      dayTotal: 0.8,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 0.8,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 0.8,
        },
      ],
      initialCreatedDate: "2024-01-08T00:00:00.000Z",
    },
    {
      createdDate: -45,
      dayTotal: 0,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 0,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 0,
        },
      ],
      initialCreatedDate: "2024-01-07T00:00:00.000Z",
    },
    {
      createdDate: -46,
      dayTotal: 0,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 0,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 0,
        },
      ],
      initialCreatedDate: "2024-01-06T00:00:00.000Z",
    },
    {
      createdDate: -47,
      dayTotal: 0.8,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 0.8,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 0.8,
        },
      ],
      initialCreatedDate: "2024-01-05T00:00:00.000Z",
    },
    {
      createdDate: -48,
      dayTotal: 0.8,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 0.8,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 0.8,
        },
      ],
      initialCreatedDate: "2024-01-04T00:00:00.000Z",
    },
    {
      createdDate: -49,
      dayTotal: 0.8,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 0.8,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 0.8,
        },
      ],
      initialCreatedDate: "2024-01-03T00:00:00.000Z",
    },
    {
      createdDate: -50,
      dayTotal: 0.8,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 0.8,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 0.8,
        },
      ],
      initialCreatedDate: "2024-01-02T00:00:00.000Z",
    },
    {
      createdDate: -51,
      dayTotal: 0,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 0,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 0,
        },
      ],
      initialCreatedDate: "2024-01-01T00:00:00.000Z",
    },
    {
      createdDate: -52,
      dayTotal: 0,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 0,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 0,
        },
      ],
      initialCreatedDate: "2023-12-31T00:00:00.000Z",
    },
    {
      createdDate: -53,
      dayTotal: 0,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 0,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 0,
        },
      ],
      initialCreatedDate: "2023-12-30T00:00:00.000Z",
    },
    {
      createdDate: -54,
      dayTotal: 0.8,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 0.8,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 0.8,
        },
      ],
      initialCreatedDate: "2023-12-29T00:00:00.000Z",
    },
    {
      createdDate: -55,
      dayTotal: 0.8,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 0.8,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 0.8,
        },
      ],
      initialCreatedDate: "2023-12-28T00:00:00.000Z",
    },
    {
      createdDate: -56,
      dayTotal: 0.8,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 0.8,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 0.8,
        },
      ],
      initialCreatedDate: "2023-12-27T00:00:00.000Z",
    },
    {
      createdDate: -57,
      dayTotal: 0.8,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 0.8,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 0.8,
        },
      ],
      initialCreatedDate: "2023-12-26T00:00:00.000Z",
    },
    {
      createdDate: -58,
      dayTotal: 0,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 0,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 0,
        },
      ],
      initialCreatedDate: "2023-12-25T00:00:00.000Z",
    },
    {
      createdDate: -59,
      dayTotal: 0,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 0,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 0,
        },
      ],
      initialCreatedDate: "2023-12-24T00:00:00.000Z",
    },
    {
      createdDate: -60,
      dayTotal: 0,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 0,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 0,
        },
      ],
      initialCreatedDate: "2023-12-23T00:00:00.000Z",
    },
    {
      createdDate: -61,
      dayTotal: 0.8,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 0.8,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 0.8,
        },
      ],
      initialCreatedDate: "2023-12-22T00:00:00.000Z",
    },
    {
      createdDate: -62,
      dayTotal: 0.8,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 0.8,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 0.8,
        },
      ],
      initialCreatedDate: "2023-12-21T00:00:00.000Z",
    },
    {
      createdDate: -63,
      dayTotal: 0.8,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 0.8,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 0.8,
        },
      ],
      initialCreatedDate: "2023-12-20T00:00:00.000Z",
    },
    {
      createdDate: -64,
      dayTotal: 0.8,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 0.8,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 0.8,
        },
      ],
      initialCreatedDate: "2023-12-19T00:00:00.000Z",
    },
    {
      createdDate: -65,
      dayTotal: 0,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: null,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: null,
        },
      ],
      initialCreatedDate: "2023-12-18T00:00:00.000Z",
    },
    {
      createdDate: -66,
      dayTotal: 0,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 0,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 0,
        },
      ],
      initialCreatedDate: "2023-12-17T00:00:00.000Z",
    },
    {
      createdDate: -67,
      dayTotal: 0,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 0,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 0,
        },
      ],
      initialCreatedDate: "2023-12-16T00:00:00.000Z",
    },
    {
      createdDate: -68,
      dayTotal: 0.8,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 0.8,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 0.8,
        },
      ],
      initialCreatedDate: "2023-12-15T00:00:00.000Z",
    },
    {
      createdDate: -69,
      dayTotal: 0.8,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 0.8,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 0.8,
        },
      ],
      initialCreatedDate: "2023-12-14T00:00:00.000Z",
    },
    {
      createdDate: -70,
      dayTotal: 0.8,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 0.8,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 0.8,
        },
      ],
      initialCreatedDate: "2023-12-13T00:00:00.000Z",
    },
    {
      createdDate: -71,
      dayTotal: 0.8,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 0.8,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 0.8,
        },
      ],
      initialCreatedDate: "2023-12-12T00:00:00.000Z",
    },
    {
      createdDate: -72,
      dayTotal: null,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: null,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 0.8,
        },
      ],
      initialCreatedDate: "2023-12-11T00:00:00.000Z",
    },
    {
      createdDate: -73,
      dayTotal: 0,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 0,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 0,
        },
      ],
      initialCreatedDate: "2023-12-10T00:00:00.000Z",
    },
    {
      createdDate: -74,
      dayTotal: 0,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 0,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 0,
        },
      ],
      initialCreatedDate: "2023-12-09T00:00:00.000Z",
    },
    {
      createdDate: -75,
      dayTotal: 0.8,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 0.8,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 0.8,
        },
      ],
      initialCreatedDate: "2023-12-08T00:00:00.000Z",
    },
    {
      createdDate: -76,
      dayTotal: 0.8,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 0.8,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 0.8,
        },
      ],
      initialCreatedDate: "2023-12-07T00:00:00.000Z",
    },
    {
      createdDate: -77,
      dayTotal: 0.8,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 0.8,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 0.8,
        },
      ],
      initialCreatedDate: "2023-12-06T00:00:00.000Z",
    },
    {
      createdDate: -78,
      dayTotal: 0.8,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 0.8,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 0.8,
        },
      ],
      initialCreatedDate: "2023-12-05T00:00:00.000Z",
    },
    {
      createdDate: -79,
      dayTotal: null,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: null,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 0.8,
        },
      ],
      initialCreatedDate: "2023-12-04T00:00:00.000Z",
    },
    {
      createdDate: -80,
      dayTotal: 0,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 0,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 0,
        },
      ],
      initialCreatedDate: "2023-12-03T00:00:00.000Z",
    },
    {
      createdDate: -81,
      dayTotal: 0,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 0,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 0,
        },
      ],
      initialCreatedDate: "2023-12-02T00:00:00.000Z",
    },
    {
      createdDate: -82,
      dayTotal: 0.8,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 0.8,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 0.8,
        },
      ],
      initialCreatedDate: "2023-12-01T00:00:00.000Z",
    },
    {
      createdDate: -83,
      dayTotal: 0.8,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 0.8,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 0.8,
        },
      ],
      initialCreatedDate: "2023-11-30T00:00:00.000Z",
    },
    {
      createdDate: -84,
      dayTotal: 0.8,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 0.8,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 0.8,
        },
      ],
      initialCreatedDate: "2023-11-29T00:00:00.000Z",
    },
    {
      createdDate: -85,
      dayTotal: 0.8,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 0.8,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 0.8,
        },
      ],
      initialCreatedDate: "2023-11-28T00:00:00.000Z",
    },
    {
      createdDate: -86,
      dayTotal: 0.8,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 0.8,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 0.8,
        },
      ],
      initialCreatedDate: "2023-11-27T00:00:00.000Z",
    },
    {
      createdDate: -87,
      dayTotal: 0,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 0,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 0,
        },
      ],
      initialCreatedDate: "2023-11-26T00:00:00.000Z",
    },
    {
      createdDate: -88,
      dayTotal: 0,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 0,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 0,
        },
      ],
      initialCreatedDate: "2023-11-25T00:00:00.000Z",
    },
    {
      createdDate: -89,
      dayTotal: 0.8,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 0.8,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 0.8,
        },
      ],
      initialCreatedDate: "2023-11-24T00:00:00.000Z",
    },
    {
      createdDate: -90,
      dayTotal: 0.8,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 0.8,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 0.8,
        },
      ],
      initialCreatedDate: "2023-11-23T00:00:00.000Z",
    },
    {
      createdDate: -91,
      dayTotal: 0.8,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 0.8,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 0,
        },
      ],
      initialCreatedDate: "2023-11-22T00:00:00.000Z",
    },
    {
      createdDate: -92,
      dayTotal: 0.8,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 0.8,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 0,
        },
      ],
      initialCreatedDate: "2023-11-21T00:00:00.000Z",
    },
    {
      createdDate: -93,
      dayTotal: null,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 0.8,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: null,
        },
      ],
      initialCreatedDate: "2023-11-20T00:00:00.000Z",
    },
    {
      createdDate: -94,
      dayTotal: 0,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 0,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 0,
        },
      ],
      initialCreatedDate: "2023-11-19T00:00:00.000Z",
    },
    {
      createdDate: -95,
      dayTotal: 0,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 0,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 0,
        },
      ],
      initialCreatedDate: "2023-11-18T00:00:00.000Z",
    },
    {
      createdDate: -96,
      dayTotal: 0.8,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 0.8,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 0.8,
        },
      ],
      initialCreatedDate: "2023-11-17T00:00:00.000Z",
    },
    {
      createdDate: -97,
      dayTotal: 0.8,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 0.8,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 0.8,
        },
      ],
      initialCreatedDate: "2023-11-16T00:00:00.000Z",
    },
    {
      createdDate: -98,
      dayTotal: 0.8,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 0.8,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 0.8,
        },
      ],
      initialCreatedDate: "2023-11-15T00:00:00.000Z",
    },
    {
      createdDate: -99,
      dayTotal: 0.8,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 0.8,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 0.8,
        },
      ],
      initialCreatedDate: "2023-11-14T00:00:00.000Z",
    },
    {
      createdDate: -100,
      dayTotal: 0.8,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 0.8,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 0.8,
        },
      ],
      initialCreatedDate: "2023-11-13T00:00:00.000Z",
    },
    {
      createdDate: -101,
      dayTotal: 0,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 0,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 0,
        },
      ],
      initialCreatedDate: "2023-11-12T00:00:00.000Z",
    },
    {
      createdDate: -102,
      dayTotal: 0,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 0,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 0,
        },
      ],
      initialCreatedDate: "2023-11-11T00:00:00.000Z",
    },
    {
      createdDate: -103,
      dayTotal: 0.8,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 0.8,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 0.8,
        },
      ],
      initialCreatedDate: "2023-11-10T00:00:00.000Z",
    },
    {
      createdDate: -104,
      dayTotal: 0.8,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 0.8,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 0.8,
        },
      ],
      initialCreatedDate: "2023-11-09T00:00:00.000Z",
    },
    {
      createdDate: -105,
      dayTotal: 0.8,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 0.8,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 0.8,
        },
      ],
      initialCreatedDate: "2023-11-08T00:00:00.000Z",
    },
    {
      createdDate: -106,
      dayTotal: 0.8,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 0.8,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 0.8,
        },
      ],
      initialCreatedDate: "2023-11-07T00:00:00.000Z",
    },
    {
      createdDate: -107,
      dayTotal: 0.8,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 0,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 0.8,
        },
      ],
      initialCreatedDate: "2023-11-06T00:00:00.000Z",
    },
    {
      createdDate: -108,
      dayTotal: 0,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 0,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 0,
        },
      ],
      initialCreatedDate: "2023-11-05T00:00:00.000Z",
    },
    {
      createdDate: -109,
      dayTotal: 0,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 0,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 0,
        },
      ],
      initialCreatedDate: "2023-11-04T00:00:00.000Z",
    },
    {
      createdDate: -110,
      dayTotal: 0.8,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 0,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 0.8,
        },
      ],
      initialCreatedDate: "2023-11-03T00:00:00.000Z",
    },
    {
      createdDate: -111,
      dayTotal: 0.8,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 0,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 0.8,
        },
      ],
      initialCreatedDate: "2023-11-02T00:00:00.000Z",
    },
    {
      createdDate: -112,
      dayTotal: 0,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 0,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 0,
        },
      ],
      initialCreatedDate: "2023-11-01T00:00:00.000Z",
    },
    {
      createdDate: -113,
      dayTotal: 0.8,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 0,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 0.8,
        },
      ],
      initialCreatedDate: "2023-10-31T00:00:00.000Z",
    },
    {
      createdDate: -114,
      dayTotal: 0.8,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 0,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 0.8,
        },
      ],
      initialCreatedDate: "2023-10-30T00:00:00.000Z",
    },
    {
      createdDate: -115,
      dayTotal: 0,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 0,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 0,
        },
      ],
      initialCreatedDate: "2023-10-29T00:00:00.000Z",
    },
    {
      createdDate: -116,
      dayTotal: 0,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 0,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 0,
        },
      ],
      initialCreatedDate: "2023-10-28T00:00:00.000Z",
    },
    {
      createdDate: -117,
      dayTotal: 0.8,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 0,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 0.8,
        },
      ],
      initialCreatedDate: "2023-10-27T00:00:00.000Z",
    },
    {
      createdDate: -118,
      dayTotal: 0.8,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 0,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 0.8,
        },
      ],
      initialCreatedDate: "2023-10-26T00:00:00.000Z",
    },
    {
      createdDate: -119,
      dayTotal: 0,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 0,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 0,
        },
      ],
      initialCreatedDate: "2023-10-25T00:00:00.000Z",
    },
    {
      createdDate: -120,
      dayTotal: 0.8,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 0,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 0.8,
        },
      ],
      initialCreatedDate: "2023-10-24T00:00:00.000Z",
    },
    {
      createdDate: -121,
      dayTotal: 0.8,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 0,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 0.8,
        },
      ],
      initialCreatedDate: "2023-10-23T00:00:00.000Z",
    },
    {
      createdDate: -122,
      dayTotal: 0,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 0,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 0,
        },
      ],
      initialCreatedDate: "2023-10-22T00:00:00.000Z",
    },
    {
      createdDate: -123,
      dayTotal: 0,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 0,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 0,
        },
      ],
      initialCreatedDate: "2023-10-21T00:00:00.000Z",
    },
    {
      createdDate: -124,
      dayTotal: 0.8,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 0,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 0.8,
        },
      ],
      initialCreatedDate: "2023-10-20T00:00:00.000Z",
    },
    {
      createdDate: -125,
      dayTotal: 0.8,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 0,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 0.8,
        },
      ],
      initialCreatedDate: "2023-10-19T00:00:00.000Z",
    },
    {
      createdDate: -126,
      dayTotal: 0.8,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 0,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 0.8,
        },
      ],
      initialCreatedDate: "2023-10-18T00:00:00.000Z",
    },
    {
      createdDate: -127,
      dayTotal: 0.8,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 0,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 0.8,
        },
      ],
      initialCreatedDate: "2023-10-17T00:00:00.000Z",
    },
    {
      createdDate: -128,
      dayTotal: 0.8,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 0,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 0.8,
        },
      ],
      initialCreatedDate: "2023-10-16T00:00:00.000Z",
    },
    {
      createdDate: -129,
      dayTotal: 0,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 0,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 0,
        },
      ],
      initialCreatedDate: "2023-10-15T00:00:00.000Z",
    },
    {
      createdDate: -130,
      dayTotal: 0,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 0,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 0,
        },
      ],
      initialCreatedDate: "2023-10-14T00:00:00.000Z",
    },
    {
      createdDate: -131,
      dayTotal: 0.8,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 0,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 0.8,
        },
      ],
      initialCreatedDate: "2023-10-13T00:00:00.000Z",
    },
    {
      createdDate: -132,
      dayTotal: 0.8,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 0,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 0.8,
        },
      ],
      initialCreatedDate: "2023-10-12T00:00:00.000Z",
    },
    {
      createdDate: -133,
      dayTotal: 0.8,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 0,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 0.8,
        },
      ],
      initialCreatedDate: "2023-10-11T00:00:00.000Z",
    },
    {
      createdDate: -134,
      dayTotal: 0.8,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 0,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 0.8,
        },
      ],
      initialCreatedDate: "2023-10-10T00:00:00.000Z",
    },
    {
      createdDate: -135,
      dayTotal: 0.8,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 0,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 0.8,
        },
      ],
      initialCreatedDate: "2023-10-09T00:00:00.000Z",
    },
    {
      createdDate: -136,
      dayTotal: 0,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 0,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 0,
        },
      ],
      initialCreatedDate: "2023-10-08T00:00:00.000Z",
    },
    {
      createdDate: -137,
      dayTotal: 0,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 0,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 0,
        },
      ],
      initialCreatedDate: "2023-10-07T00:00:00.000Z",
    },
    {
      createdDate: -138,
      dayTotal: 0.8,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 0,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 0.8,
        },
      ],
      initialCreatedDate: "2023-10-06T00:00:00.000Z",
    },
    {
      createdDate: -139,
      dayTotal: 0,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 0,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 0,
        },
      ],
      initialCreatedDate: "2023-10-05T00:00:00.000Z",
    },
    {
      createdDate: -140,
      dayTotal: 0,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 0,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: null,
        },
      ],
      initialCreatedDate: "2023-10-04T00:00:00.000Z",
    },
    {
      createdDate: -141,
      dayTotal: 0,
      amountByMarketplace: [
        {
          marketplace: "AMAZON",
          amount: 0,
        },
        {
          marketplace: "LEROY MERLIN",
          amount: 0,
        },
      ],
      initialCreatedDate: "2023-10-03T00:00:00.000Z",
    },
  ],
}
