export function getPaginatedData<T>(data: T[], pagination: { pageSize?: number; pageNumber?: number }): T[] {
  const { pageSize = 10, pageNumber = 1 } = pagination

  const startIndex = pageSize * (pageNumber - 1)
  const endIndex = startIndex + pageSize
  return data.slice(startIndex, endIndex)
}

export function getPaginationState(
  totalCount: number,
  pagination: { pageSize?: number; pageNumber?: number },
): {
  totalPage: number
  currentPage: number
  totalCount: number
} {
  const { pageSize = 10, pageNumber = 1 } = pagination
  const totalPage = Math.ceil(totalCount / pageSize)
  return {
    totalPage,
    currentPage: pageNumber,
    totalCount,
  }
}
