import { Button, Modal, Typography } from "@hero/krypton"
import { useDashboardTranslation } from "../../01_technical/translations"
import accessDeniedImage from "../../assets/access_denied.svg"

import { useState } from "react"
import styled from "styled-components"
import { SupportContact } from "../../00_shared/components/SupportContact"
import { useCountdown } from "../../00_shared/hooks/useCountdown.hook"
import { ChallengeType, useResend } from "../Resend/Resend.requests"

const Title = styled(Typography)`
  margin-bottom: 0.25rem;
`

const ImageWrapper = styled.div`
  height: 6.25rem;
  width: 6.25rem;
  margin-bottom: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
`

const Wrapper = styled.div`
  padding: 18px;
`

interface Check2faSetupModalProps {
  isOpen: boolean
  onClose: () => void
  phone: string
  challengeId: string
}

const RESEND_CHALLENGE_RETRIES_LIMIT = 4

export const Setup2faModal = ({ isOpen, onClose, phone, challengeId }: Check2faSetupModalProps) => {
  const { t } = useDashboardTranslation()
  const { timeLeft_s, resetCounting } = useCountdown()
  const [resend, { loading }] = useResend()
  const [retriedNumber, setRetriedNumber] = useState(0)

  const sendEnrollChallenge = async () => {
    await resend({
      variables: {
        details: {
          challengeId,
          type: ChallengeType.FOR_ENROLL_2FA,
        },
      },
    })
  }

  const isCounting = timeLeft_s > 0

  return (
    <Modal canCloseOutside={false} isOpen={isOpen} onClose={onClose}>
      <Wrapper>
        <ImageWrapper>{<img src={accessDeniedImage} alt="" />}</ImageWrapper>
        <Title $variant="title-3-semibold">{t("auth.2fa.enrollment.modal.title")}</Title>
        {phone && <Title $variant="title-3-inter-bold">{phone}</Title>}
        <br />
        <Typography>{t("auth.2fa.enrollment.modal.description")}</Typography>
        {retriedNumber > RESEND_CHALLENGE_RETRIES_LIMIT ? (
          <>
            <br />
            <Typography $variant="caption-1">{t("auth.2fa.enrollment.modal.resend.retriedTooManyTimes")}</Typography>
          </>
        ) : (
          <>
            <Button
              data-test-id="retry-2fa-button"
              disabled={isCounting}
              isLoading={loading}
              size="medium"
              style={{ marginTop: "1rem", marginBottom: "1rem", alignSelf: "center" }}
              onClick={async () => {
                setRetriedNumber((prevNumber) => prevNumber + 1)
                await sendEnrollChallenge()
                resetCounting()
              }}
            >
              {isCounting
                ? t("auth.2fa.enrollment.modal.resend.smsWithCountdown", { x: timeLeft_s })
                : t("auth.2fa.enrollment.modal.resend.sms")}
            </Button>
            <>
              <br />
              <Typography $variant="caption-1">{t("auth.2fa.enrollment.modal.redirect")}</Typography>
            </>
          </>
        )}
        <SupportContact />
      </Wrapper>
    </Modal>
  )
}
