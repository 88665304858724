import { getPaginatedData, getPaginationState } from "../../../../00_shared/utils/pagination"
import { GetIncomingTransfersSuccessResponse } from "../Balance.requests"
import { balanceIncomingFakeData } from "./balanceIncomingFakeData"

export const getBalanceIncomingFakeData = (
  filters: {
    marketplaceNames?: string[]
    amountMin?: number
    amountMax?: number
    amountEqual?: number
    createdBefore?: Date
    createdAfter?: Date
  },
  pagination: { pageNumber: number; pageSize: number },
): GetIncomingTransfersSuccessResponse => {
  const filteredTransfers = balanceIncomingFakeData.filter((transfer) => {
    const isMarketplaceMatch = filters.marketplaceNames
      ? filters.marketplaceNames.includes(transfer.marketplaceName)
      : true
    const isAmountMinMatch = filters.amountMin ? transfer.amount >= filters.amountMin : true
    const isAmountMaxMatch = filters.amountMax ? transfer.amount <= filters.amountMax : true
    const isAmountEqualMatch = filters.amountEqual ? transfer.amount === filters.amountEqual : true
    const isCreatedBeforeMatch = filters.createdBefore ? new Date(transfer.date) <= filters.createdBefore : true
    const isCreatedAfterMatch = filters.createdAfter ? new Date(transfer.date) >= filters.createdAfter : true

    return (
      isMarketplaceMatch &&
      isAmountMinMatch &&
      isAmountMaxMatch &&
      isAmountEqualMatch &&
      isCreatedBeforeMatch &&
      isCreatedAfterMatch
    )
  })

  return {
    transfers: getPaginatedData(filteredTransfers, pagination),
    pagination: getPaginationState(filteredTransfers.length, pagination),
    marketplaces: ["LEROY MERLIN", "AMAZON"],
  }
}
