import { useQuery } from "@apollo/client"
import { Button, Spinner, Table, TBody, THead, toaster, Typography } from "@hero/krypton"
import { DateTime } from "luxon"
import { useEffect } from "react"
import styled from "styled-components"
import { useSignedUrlFileDownload } from "../../../../00_shared/hooks/useSignedUrlFileDownload.hook"
import { useDashboardTranslation } from "../../../../01_technical/translations"
import { useAuthContext } from "../../../../Auth/auth.context"
import { MerchantFeesDocumentType } from "../../../../business/enums/Merchant.enum"
import { API_URI } from "../../../../env_variables"
import { GET_MERCHANT_FEES_INVOICES_AND_VOUCHERS_QUERY, MerchantFeesInvoiceQuery } from "./billing.queries"

const Description = styled(Typography)`
  color: ${({ theme }) => theme.colors.grey.$500};
  margin-bottom: 1.5rem;
`

const GeneratedDocument = styled(Typography)`
  margin-bottom: 0.5rem;
`

const TableDescription = styled.div`
  margin-left: 1.5rem;
`

const TH = styled.th`
  padding-left: 1.5rem !important;
`

const TD = styled.td`
  padding-left: 1.5rem !important;
  &:before {
    content: unset !important;
  }
`

const CapitalizeTD = styled(TD)`
  text-transform: capitalize;
`

export const BillingScreen: React.FC = () => {
  const { t, i18n } = useDashboardTranslation()
  const { currentUser } = useAuthContext()

  const query = useQuery<MerchantFeesInvoiceQuery>(GET_MERCHANT_FEES_INVOICES_AND_VOUCHERS_QUERY, {
    skip: currentUser.isDemoMerchant,
  })

  const { loading, error } = query
  let { data } = query

  if (currentUser.isDemoMerchant) {
    data = {
      getMerchantFeesInvoices: [
        {
          id: "mfeesinv-57d4757b-c63b-4cc9-a99d-922edc6148b0",
          number: "49",
          cashedInPeriodStart: "2024-01-01T00:00:00.000Z",
          cashedInPeriodEnd: "2024-01-31T23:59:59.999Z",
          documentType: MerchantFeesDocumentType.INVOICE,
        },
      ],
    }
  }

  const getDocumentTypeWording = (documentType: MerchantFeesDocumentType) => {
    if (documentType === MerchantFeesDocumentType.INVOICE) {
      return t("accounting.billing.documentType.invoice")
    }
    return t("accounting.billing.documentType.voucher")
  }

  useEffect(() => {
    if (error) {
      toaster.error("Une erreur est survenue. Veuillez réessayer ultérieurement.", { position: "top-right" })
      console.error(error)
    }
  }, [error])

  const [fileDownload] = useSignedUrlFileDownload()

  if (error) {
    return <div>{error.message}</div>
  }

  if (loading) {
    return <Spinner />
  }

  return (
    <>
      <TableDescription>
        <Typography as="h2" $variant="title-3-inter-bold" data-test-id="accounting-billing-title">
          {t("accounting.billing.title")}
        </Typography>

        <Description $variant="body-4-regular" data-test-id="accounting-billing-description">
          {t("accounting.billing.description")}
        </Description>

        <GeneratedDocument as="h3" $variant="body-4-regular">
          {t("accounting.billing.generatedDocument")}
        </GeneratedDocument>
      </TableDescription>

      <Table>
        <THead>
          <tr>
            <TH>{t("accounting.billing.tableHeader.documentType")}</TH>
            <TH>{t("accounting.billing.tableHeader.invoiceNumber")}</TH>
            <TH>{t("accounting.billing.tableHeader.period")}</TH>
            <TH>{t("accounting.billing.tableHeader.options")}</TH>
          </tr>
        </THead>
        {data?.getMerchantFeesInvoices.length ? (
          <TBody>
            {data.getMerchantFeesInvoices.map((document) => {
              const DOWNLOAD_MERCHANT_FEES_DOCUMENT_URL =
                document.documentType === MerchantFeesDocumentType.INVOICE
                  ? "merchantFeesInvoices"
                  : "merchantFeesVouchers"
              return (
                <tr key={document.id}>
                  <TD>{getDocumentTypeWording(document.documentType)}</TD>
                  <TD>
                    {document.documentType === MerchantFeesDocumentType.VOUCHER
                      ? `A${document.number}`
                      : document.number}
                  </TD>
                  <CapitalizeTD>
                    {DateTime.fromISO(document.cashedInPeriodStart)
                      .setLocale(i18n.language)
                      // eslint-disable-next-line i18next/no-literal-string
                      .toFormat("MMMM yyyy")}
                  </CapitalizeTD>
                  <TD>
                    {currentUser.isDemoMerchant && (
                      <a href="/demo/demo-invoice.pdf" target="_blank">
                        <Button type="button" $variant="tertiary" isLoading={false}>
                          {t("accounting.billing.table.downloadButton")}
                        </Button>
                      </a>
                    )}

                    {!currentUser.isDemoMerchant && (
                      <Button<"a">
                        as="a"
                        isLoading={false}
                        $variant="tertiary"
                        target="_blank"
                        onClick={() => fileDownload(`${API_URI}/${DOWNLOAD_MERCHANT_FEES_DOCUMENT_URL}/${document.id}`)}
                      >
                        {t("accounting.billing.table.downloadButton")}
                      </Button>
                    )}
                  </TD>
                </tr>
              )
            })}
          </TBody>
        ) : (
          <TBody>
            <tr>
              <TD colSpan={3}>{t("accounting.billing.table.noDocuments")}</TD>
            </tr>
          </TBody>
        )}
      </Table>
    </>
  )
}
