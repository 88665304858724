import { gql } from "@apollo/client"
import { useHeroMutation } from "../../01_technical/requesting/useHeroMutation/useHeroMutation"
import { useHeroQuery } from "../../01_technical/requesting/useHeroQuery/useHeroQuery"
import { PaymentConfigurationScope } from "./00_shared/enums/PaymentCore.enum"

const GET_PAYMENT_CHECKOUT_CONFIGURATION = gql`
  query UserGetPaymentConfiguration {
    userGetPaymentCheckoutConfiguration {
      ... on GetPaymentCheckoutConfigurationPermissionsOutput {
        enabled
        scope
      }
    }
  }
`

const GET_PAYMENT_LINK_CONFIGURATION = gql`
  query UserGetPaymentConfiguration {
    userGetPaymentLinkConfiguration {
      ... on GetPaymentLinkConfigurationPermissionsOutput {
        enabled
        scope
      }
    }
  }
`

type GetPaymentCheckoutConfigurationResponse = {
  enabled: boolean
  scope: PaymentConfigurationScope
}

type GetPaymentLinkConfigurationResponse = {
  enabled: boolean
  scope: PaymentConfigurationScope
}

export const useGetPaymentConfiguration = () => {
  const { data: dataLink, ...resLink } = useHeroQuery<GetPaymentLinkConfigurationResponse>({
    gqlQuerySchema: GET_PAYMENT_LINK_CONFIGURATION,
  })

  const { data: dataCheckout, ...resCheckout } = useHeroQuery<GetPaymentCheckoutConfigurationResponse>({
    gqlQuerySchema: GET_PAYMENT_CHECKOUT_CONFIGURATION,
  })

  return {
    data: {
      paymentLink: dataLink,
      paymentCheckout: dataCheckout,
    },
    loading: resLink.loading || resCheckout.loading,
  }
}

const ASK_PRODUCT_ACTIVATION = gql`
  mutation askProductActivation {
    askProductActivation {
      ... on AskProductActivationOutput {
        success
      }
      ... on GqlHeroError {
        errorCode
      }
      ... on ValidationErrors {
        errorCode
      }
    }
  }
`

export const useAskProductActivation = () => {
  return useHeroMutation({
    gqlQuerySchema: ASK_PRODUCT_ACTIVATION,
  })
}
