import { gql } from "@apollo/client"
import { MerchantFeesDocumentType } from "../../../../business/enums/Merchant.enum"

type MerchantFeesDocument = {
  id: string
  number: string
  cashedInPeriodStart: string
  cashedInPeriodEnd: string
  documentType: MerchantFeesDocumentType
}

export type MerchantFeesInvoiceQuery = { getMerchantFeesInvoices: MerchantFeesDocument[] }

export const GET_MERCHANT_FEES_INVOICES_AND_VOUCHERS_QUERY = gql`
  query {
    getMerchantFeesInvoices {
      id
      number
      cashedInPeriodStart
      cashedInPeriodEnd
      documentType
    }
  }
`
