import { theme } from "@hero/krypton"
import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  ChartData,
  ChartOptions,
  Legend,
  LinearScale,
  LineController,
  LineElement,
  PointElement,
  Title,
  Tooltip,
} from "chart.js"
import { Bar } from "react-chartjs-2"
import { currencyFormatter } from "../../../../00_shared/utils/currency.converter"
import { useDashboardTranslation } from "../../../../01_technical/translations"
import { PRIMARY_Y_AXIS_ID, SECONDARY_Y_AXIS_ID } from "./CockpitBarChartWithNewKPI"

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  PointElement,
  LineElement,
  LineController,
)

type BarChartData = ChartData<"bar", number[], string> & { tooltipFooters: string[] | null }

export const BarChartWithNewKPI = ({ data }: { data: BarChartData }) => {
  const { t } = useDashboardTranslation()
  const options: ChartOptions<"bar"> = {
    responsive: true,
    plugins: {
      legend: {
        position: "top" as const,
        align: "start" as const,
        labels: {
          boxWidth: 8,
          boxHeight: 8,
          sort(a) {
            return a.datasetIndex === 0 ? 1 : -1 // to put "financing rate" as the last legend (it's the first dataset to display the line on top of the bars)
          },
          useBorderRadius: true,
          borderRadius: 1,
        },
      },
      tooltip: {
        mode: "index" as const,
        intersect: false,
        backgroundColor: "#FFF",
        titleColor: theme.colors.black.$900,
        displayColors: false,
        footerColor: theme.colors.black.$500,
        callbacks: {
          label: (tooltipItem: { datasetIndex: number; parsed: { y: number } }) => {
            if (tooltipItem.datasetIndex !== 0) {
              return `${t("ap.cockpit.barChart.legend.total")}: ${currencyFormatter(tooltipItem.parsed.y)}`
            } else {
              return `${t("ap.cockpit.barChart.legend.financingRate")}: ${tooltipItem.parsed.y}%`
            }
          },
          footer: (tooltipItems: { dataIndex: number }[]) => data.tooltipFooters?.[tooltipItems[0].dataIndex] || "",
          labelTextColor: function (context: { datasetIndex: number }) {
            switch (context.datasetIndex) {
              case 0:
                return theme.colors.warning.$200
              case 1:
                return theme.colors.primary.$200
              case 2:
                return theme.colors.secondary.$200
              default:
                return theme.colors.black.$900
            }
          },
        },
      },
    },
    scales: {
      [PRIMARY_Y_AXIS_ID]: {
        ticks: {
          callback: (value: string | number) => `${value} €`,
          z: 0,
        },
        border: {
          z: 0,
        },
        grace: "5%",
      },
      [SECONDARY_Y_AXIS_ID]: {
        type: "linear",
        display: true,
        position: "right",
        suggestedMax: 100,
        min: 0,
        // grid line settings
        grace: "5%",
        grid: {
          drawOnChartArea: false, // only want the grid lines for one axis to show up
        },
        border: {
          z: 9999,
        },
      },
    },
  }
  return <Bar options={options} data={data} />
}
