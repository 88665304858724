import { AddIcon, Badge, Button, SettingsIcon, toaster, Toggle, Tooltip, Typography } from "@hero/krypton"
import { useState } from "react"
import { useNavigate } from "react-router-dom"
import styled from "styled-components"
import useLocalStorage from "../../../00_shared/hooks/useLocalStorage.hook"
import { useDashboardTranslation } from "../../../01_technical/translations"
import MoneyCalendar from "../../../assets/collection/money_calendar.png"
import MoneyLoading from "../../../assets/collection/money_loading.png"
import { HERO_PRODUCT_ONBOARDING_URL } from "../../../env_variables"
import { useAskProductActivation } from "../Collection.request"

type ProductScopeItemProps = {
  iconSrc: string
  enabled: boolean
  redirectUrl: string
  titleKey: string
  descriptionKey: string
  disabled: boolean
  kind: "bnpl" | "pay1x"
}

const ActionButton = styled(Button)`
  width: fit-content;
  min-width: unset;
  padding: 0.25rem;
`

const ContainerProductScope = styled.div`
  display: flex;
  flex-direction: row;
  padding: 1rem;
  border-radius: 0.5rem;
  background-color: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.grey.$200};
  box-shadow: ${({ theme }) => theme.shadows.light};
  margin-bottom: 1rem;
`

const LeftSection = styled.div`
  width: 3.125rem;
  margin-right: 1rem;
  display: flex;
  align-items: center;
`

const RightSection = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`

const ActionSection = styled.div`
  width: 6.5rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`

const SwitchButton = styled(Toggle)`
  width: 2.75rem;
  height: 1.5rem;
`

const ProductScopeItem = ({ iconSrc, enabled, titleKey, descriptionKey, kind, disabled }: ProductScopeItemProps) => {
  const { t } = useDashboardTranslation()
  const [loadingOnboarding, setLoadingOnboarding] = useState(false)
  const [heroOnboardingState, setHeroOnboardingState] = useLocalStorage(`heroOnboardingState-${kind}`, "not_started")
  const [askProductActivation] = useAskProductActivation()

  return (
    <ContainerProductScope>
      <LeftSection>
        <img src={iconSrc} height="35px" alt="icon" />
      </LeftSection>
      <RightSection>
        <Typography $variant="body-4-medium" $color="grey.$600">
          {t(titleKey)}
        </Typography>
        <Typography $variant="body-4-regular" $color="grey.$500">
          {t(descriptionKey)}
        </Typography>
      </RightSection>
      <ActionSection>
        {enabled && (
          <Tooltip
            content={t(!disabled ? "collection.settings.activated" : "collection.settings.disabled")}
            id="product-scope-switch"
            position="top-left"
          >
            <SwitchButton
              id="product-scope-switch"
              role="switch"
              checked={!disabled}
              aria-labelledby="product scope switch"
            />
          </Tooltip>
        )}
        {!enabled && (
          <ActionButton
            disabled={heroOnboardingState === "started"}
            isLoading={loadingOnboarding}
            leftIcon={AddIcon}
            size="small"
            onClick={async () => {
              if (loadingOnboarding) return

              setHeroOnboardingState("started")

              setLoadingOnboarding(true)

              await askProductActivation({})

              toaster.success(t("collection.cockpit.askForProduct.toastSuccess"))

              setTimeout(() => {
                // todo: uncomment this when the new onboarding is ready
                // window.location.href = redirectUrl

                setLoadingOnboarding(false)
              }, 100)
            }}
          >
            <Typography $variant="body-4-semibold" $color="white">
              {t(heroOnboardingState === "not_started" ? "collection.activate" : "collection.continue")}
            </Typography>
          </ActionButton>
        )}
      </ActionSection>
    </ContainerProductScope>
  )
}

type SettingsProductScopeProps = {
  titleKey: string
  enablePluginsNavigation: boolean
  scopeBNPLEnabled: boolean
  scopePAY1xEnabled: boolean
  productDisabled: boolean
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 0.5rem;
  background-color: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.grey.$200};
  box-shadow: ${({ theme }) => theme.shadows.light};
  padding: 1rem;
  height: fit-content;
`

const Title = styled(Typography)`
  margin-bottom: 1rem;
`

const AlertDisabled = styled.div`
  margin-bottom: 1rem;
`

const PluginNavContainer = styled.div``

export const SettingsProductScope = ({
  titleKey,
  enablePluginsNavigation,
  scopeBNPLEnabled,
  scopePAY1xEnabled,
  productDisabled,
}: SettingsProductScopeProps) => {
  const { t, i18n } = useDashboardTranslation()
  const navigate = useNavigate()

  return (
    <Container>
      {productDisabled && (
        <AlertDisabled>
          <Badge $variant="secondary">{t("collection.settings.product.disabled")}</Badge>
        </AlertDisabled>
      )}
      <Title $variant="body-2-semibold">{t(titleKey)}</Title>
      <ProductScopeItem
        kind="pay1x"
        iconSrc={MoneyLoading}
        enabled={scopePAY1xEnabled || scopeBNPLEnabled}
        disabled={productDisabled}
        redirectUrl={`${HERO_PRODUCT_ONBOARDING_URL}?flow=pay1x&country=${i18n.language.toUpperCase()}`}
        titleKey="collection.settings.pay1x.title"
        descriptionKey="collection.settings.pay1x.description"
      />
      <ProductScopeItem
        kind="bnpl"
        iconSrc={MoneyCalendar}
        enabled={scopeBNPLEnabled}
        disabled={productDisabled}
        redirectUrl={`${HERO_PRODUCT_ONBOARDING_URL}?flow=bnpl&country=${i18n.language.toUpperCase()}`}
        titleKey="collection.settings.bnpl.title"
        descriptionKey="collection.settings.bnpl.description"
      />
      {enablePluginsNavigation && (
        <PluginNavContainer>
          <Button
            $variant="underline"
            leftIcon={SettingsIcon}
            onClick={() => {
              navigate("/collection/settings/plugins")
            }}
          >
            <Typography $variant="body-4-semibold">{t("collection.settings.plugins.configure")}</Typography>
          </Button>
        </PluginNavContainer>
      )}
    </Container>
  )
}
