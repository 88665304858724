import { gql } from "@apollo/client"

export type APIKEYRespone = {
  apiKey: string
}

export const GET_API_KEY = gql`
  query {
    apiKey
  }
`
