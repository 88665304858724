import { ValidationErrors } from "./errors.type"

export const FormatError = ({
  extensions,
  errorMapping,
}: {
  extensions: { [key: string]: unknown } | undefined
  errorMapping: Record<string, string>
}) => {
  const validationErrors = extensions?.validationErrors as ValidationErrors
  return (
    <div>
      {Object.keys(validationErrors)
        .map((field) => errorMapping[field])
        .filter(Boolean)
        .map((message: string, index) => (
          <div key={index}>{message}</div>
        ))}
    </div>
  )
}
