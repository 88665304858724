import { Typography } from "@hero/krypton"
import styled from "styled-components"
import { useDashboardTranslation } from "../../01_technical/translations"

const SupportWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.4rem;
  padding-top: 2rem;
  text-align: start;
`

const SupportText = styled(Typography)``

const SupportLink = styled(Typography)`
  text-decoration: underline;
`

export const SupportContact = () => {
  const { t } = useDashboardTranslation()
  return (
    <SupportWrapper>
      <SupportText $variant="caption-2">{t("auth.contactSupport.text")}</SupportText>
      <SupportLink $variant="caption-1" as="a" href="mailto:support@hero.fr">
        {t("auth.contactSupport.link")}
      </SupportLink>
    </SupportWrapper>
  )
}
