import { useState } from "react"

export interface UseDateFilterConfig {
  applyImmediately: boolean
}

export interface DateState {
  dateFrom?: string
  dateTo?: string
  setDateFrom: (date: string) => void
  setDateTo: (date: string) => void
  reset: (localStateOnly?: boolean) => void
  apply: () => void
}

export const useDateFilter = (
  config: UseDateFilterConfig,
  updateUrlParams: (params: Partial<DateState>) => void,
): DateState => {
  const [dateFrom, setDateFromState] = useState<string | undefined>()
  const [dateTo, setDateToState] = useState<string | undefined>()

  const setDateFrom = (date: string) => {
    setDateFromState(date)
    if (config.applyImmediately) {
      updateUrlParams({ dateFrom: date, dateTo })
    }
  }

  const setDateTo = (date: string) => {
    setDateToState(date)
    if (config.applyImmediately) {
      updateUrlParams({ dateFrom, dateTo: date })
    }
  }

  const reset = (localStateOnly = false) => {
    setDateFromState(undefined)
    setDateToState(undefined)
    if (!localStateOnly) {
      updateUrlParams({ dateFrom: undefined, dateTo: undefined })
    }
  }

  const apply = () => {
    updateUrlParams({ dateFrom, dateTo })
  }

  return {
    dateFrom,
    dateTo,
    setDateFrom,
    setDateTo,
    reset,
    apply,
  }
}
