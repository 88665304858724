import { useState } from "react"
import { toEuros } from "../../../../00_shared/utils/currency.converter"
import { DateStyle, toInternationalDate } from "../../../../00_shared/utils/date.converter"
import { useDashboardTranslation } from "../../../../01_technical/translations"
import { MrfInstallment } from "./ActiveMRF.request"
import ActiveMRFInstallmentsTableDetailsModal from "./ActiveMRFInstallmentsTableDetailsModal"

interface ActiveMRFInstallmentsTableRowProps {
  installment: MrfInstallment
}
export const ActiveMRFInstallmentsTableRow = ({ installment }: ActiveMRFInstallmentsTableRowProps) => {
  const { i18n } = useDashboardTranslation()

  const [isDetailsDisplayed, setIsDetailsDisplayed] = useState(false)

  const totalAmount = installment.amount + installment.feesAmount
  const totalToPay = installment.amountToDebit + installment.feesAmountToDebit
  const totalPaid = totalAmount - totalToPay

  return (
    <>
      <tr onClick={() => setIsDetailsDisplayed(true)}>
        <td>
          {toInternationalDate({
            date: installment.dueDate,
            language: i18n.language,
            style: DateStyle.TEXTUAL,
          })}
        </td>
        <td>{toEuros(totalAmount)}</td>
        <td>{toEuros(totalPaid)}</td>
      </tr>

      <ActiveMRFInstallmentsTableDetailsModal
        installment={installment}
        isOpen={isDetailsDisplayed}
        onClose={() => setIsDetailsDisplayed(false)}
      />
    </>
  )
}
