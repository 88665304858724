export enum APStatus {
  IN_PROGRESS = "IN_PROGRESS",
  PARTIALLY_REPAID = "PARTIALLY_REPAID",
  FINISHED = "FINISHED",
  CANCELED = "CANCELED",
}

/**
 * @public
 */
export enum APMovementCategory {
  CREDIT_GRANTED = "CREDIT_GRANTED",
  CREDIT_CANCELED = "CREDIT_CANCELED",
  CREDIT_FEES = "CREDIT_FEES",
  CREDIT_FEES_CANCELED = "CREDIT_FEES_CANCELED",
  CREDIT_REPAYMENT = "CREDIT_REPAYMENT",
  CREDIT_REPAYMENT_CANCELED = "CREDIT_REPAYMENT_CANCELED",
}
