import { gql } from "@apollo/client"
import { useHeroQuery } from "../../../01_technical/requesting/useHeroQuery/useHeroQuery"
import { IssuingCard } from "../00_shared/business/IssuingCard"

const GET_ISSUING_CARD = gql`
  query listIssuingCards($id: String!) {
    listCards(pagination: { limit: 1, page: 1 }, filters: { ids: [$id] }) {
      ... on ListIssuedCardsResult {
        cards {
          id
          merchantId
          businessAccountId
          issuanceType
          status
          expiration
          lastCardDigits
          displayLastCardDigits
          allowContactless
          allowEcommerce
          allowOtherCurrency
          cardholderName
          monthlySpendingLimitAmount
          consumedMonthlySpendingLimitAmount
          monthlySpendingLimitAmountEuros
          consumedMonthlySpendingLimitAmountEuros
          paymentAccountName
          pending3dsChallengeId
          formattedOrderedDate
          formattedProductionDate
          formattedReceptionDate
          isDeliveryLate
          notReceivedDeclarationDate
        }
        total
      }
      ... on SimpleApiError {
        errorCode
      }
    }
  }
`

export const useIssuingCardInformationHook = (id: string | undefined) => {
  const { data, loading, error, refetch } = useHeroQuery<{ cards: IssuingCard[] }>({
    gqlQuerySchema: GET_ISSUING_CARD,
    fetchPolicy: "cache-and-network",
    variables: {
      id,
    },
    skip: !!!id,
  })

  return {
    card: data?.cards?.[0] || undefined,
    loading,
    error,
    refetch,
  }
}
