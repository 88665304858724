import { gql } from "@apollo/client"
import { useHeroMutation } from "../../../../../01_technical/requesting/useHeroMutation/useHeroMutation"

const REFUND_PAYMENT_CHECKOUT = gql`
  mutation refundPaymentCheckout($input: RefundPaymentCheckoutInput!) {
    refundPaymentCheckout(input: $input) {
      ... on RefundPaymentCheckoutOutput {
        success
      }

      ... on ValidationErrors {
        validationErrors {
          path
          validationError
        }
      }

      ... on GqlHeroError {
        errorCode
        message
      }
    }
  }
`

type RefundPaymentCheckoutInput = {
  input: {
    amount: number
    paymentCheckoutId: string
  }
}

type RefundPaymentCheckoutResponse = {
  success: boolean
}

export const useRefundPaymentCheckout = () => {
  return useHeroMutation<RefundPaymentCheckoutResponse, RefundPaymentCheckoutInput>({
    gqlQuerySchema: REFUND_PAYMENT_CHECKOUT,
  })
}
