import { Navigate } from "react-router-dom"

import { AddBeneficiaryInTransferCreation } from "../transfers/AddBeneficiary/AddBeneficiary.screen"
import { ConfirmTransfer } from "../transfers/CreateTransfer/ConfirmTransfer/ConfirmTransfer.screen"
import { SelectAccountToCredit } from "../transfers/CreateTransfer/SelectAccountToCredit/SelectAccountToCredit.screen"
import { SelectTransferType } from "../transfers/CreateTransfer/SelectTransferType/SelectTransferType.screen"
import { TransferInformations } from "../transfers/CreateTransfer/TransferInformations/TransferInformations.screen"

export const createTransferRoutes = [
  {
    index: true,
    element: <Navigate to="select-transfer-type" replace={true} />,
  },
  {
    path: "select-transfer-type",
    element: <SelectTransferType />,
  },
  {
    path: "select-account-to-credit",
    element: <SelectAccountToCredit />,
  },
  {
    path: "transfer-informations",
    element: <TransferInformations />,
  },
  {
    path: "confirm-transfer",
    element: <ConfirmTransfer />,
  },

  { path: "create-beneficiary", element: <AddBeneficiaryInTransferCreation /> },
]
