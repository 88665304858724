import { Separator, Typography } from "@hero/krypton"
import { ReactNode } from "react"
import styled from "styled-components"
import { toEuros } from "../../../../00_shared/utils/currency.converter"
import { DateStyle, toInternationalDate } from "../../../../00_shared/utils/date.converter"
import { useDashboardTranslation } from "../../../../01_technical/translations"
import { GetMrfDetailsResponseData } from "./ActiveMRF.request"

interface ActiveMRFDetailsDetailsCardProps {
  details: GetMrfDetailsResponseData
}

const Card = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 0.75rem;
  border: 1px solid ${({ theme }) => theme.colors.grey.$200};
  padding: 1.5rem;
  width: 100%;
`

const CardItemWrapper = styled(Typography)`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
  margin: 0.25rem 0;
  align-items: center;
`

const CardItemRight = styled(Typography)`
  text-align: right;
  font-weight: 500;
`

const Divider = styled(Separator)`
  margin: 1.25rem 0;
`

const ActiveMRFDetailsDetailsCard = ({ details }: ActiveMRFDetailsDetailsCardProps) => {
  const { t, i18n } = useDashboardTranslation()
  return (
    <>
      <Card>
        <CardSectionTitle $variant="body-4-medium">{t("mrf.details.informations")}</CardSectionTitle>

        <CardItem label={t("mrf.details.acceptationDate")}>
          {details.acceptanceDate
            ? toInternationalDate({ date: details.acceptanceDate, language: i18n.language, style: DateStyle.TEXTUAL })
            : "N/A"}
        </CardItem>
        <CardItem label={t("mrf.details.linkedAccount")}>{details.accountName}</CardItem>

        <Divider />

        <CardSectionTitle $variant="body-4-medium">{t("mrf.details.installments.label")}</CardSectionTitle>

        <CardItem label={t("mrf.details.date")}>
          {details.installmentDebitDay
            ? t("mrf.details.debitDay", {
                day: details.installmentDebitDay,
              })
            : "N/A"}
        </CardItem>
        <CardItem label={t("mrf.details.installmentAmount")}>
          {toEuros(details.installmentAmount + details.installmentAmountFees)}
        </CardItem>
        <CardItem label={t("mrf.details.installmentAmountFees")}>{toEuros(details.installmentAmountFees)}</CardItem>
        <Typography $variant="caption-2">
          <span
            style={{
              fontStyle: "italic",
            }}
          >
            {t("mrf.details.installmentAmountFeesDescription")}
          </span>
        </Typography>
      </Card>
    </>
  )
}

const CardSectionTitle = styled(Typography)`
  text-transform: uppercase;
  margin-bottom: 0.25rem;
`

const CardItem = ({ label, children }: { label: string; children?: ReactNode }) => (
  <CardItemWrapper>
    {label} : <CardItemRight>{children}</CardItemRight>
  </CardItemWrapper>
)

export default ActiveMRFDetailsDetailsCard
