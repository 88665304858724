import { useEffect } from "react"
import { useQuerySearchParams } from "../../../../00_shared/hooks/useQuerySearchParams.hook"
import { debounce } from "../../../../00_shared/utils/debounce"
import { SearchParams, FormType } from "./FilledCockpit"

export const useHandleSearchParams = () => {
  const { getSearchParam, setSearchParam } = useQuerySearchParams<SearchParams>()

  const searchParams: SearchParams = {
    pageNumber: getSearchParam("pageNumber") || "1",
    search: getSearchParam("search") || undefined,
  }
  const { pageNumber: pageNumberString, search } = searchParams

  useEffect(() => {
    if (!pageNumberString) {
      setSearchParam({ pageNumber: "1" })
    }
  }, [pageNumberString, setSearchParam])

  const pageNumber = isNaN(Number(pageNumberString)) ? 1 : Number(pageNumberString)

  const onSearch = debounce(({ search }: FormType) => {
    setSearchParam({ pageNumber: "1", search: search || undefined })
  })

  const onPageChange = (pageNumber: number) => {
    setSearchParam({ ...searchParams, pageNumber: pageNumber.toString() })
  }

  return { onPageChange, pageNumber, onSearch, search }
}
