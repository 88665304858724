import { Typography } from "@hero/krypton"
import styled from "styled-components"

export const DetailItem = styled.div`
  display: flex;
  justify-content: space-between;
`

export const DetailLabel = styled(Typography).attrs(() => ({
  $variant: "body-4-regular",
}))`
  color: ${({ theme }) => theme.colors.grey.$500};
`

export const DetailValue = styled(Typography).attrs(() => ({
  $variant: "body-4-medium",
}))`
  color: ${({ theme }) => theme.colors.grey.$600};
  text-align: right;
`
