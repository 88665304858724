const units = ["Octets", "Ko", "Mo", "Go"]

export function formatBytes(bytes: string) {
  let unitSizeRank = 0
  let n = +bytes || 0
  while (n >= 1024 && ++unitSizeRank) {
    n = n / 1024
  }
  const formattedSize = n.toFixed(n < 10 && unitSizeRank > 0 ? 1 : 0)
  return `${formattedSize} ${units[unitSizeRank]}`
}
