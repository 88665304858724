import { AddIcon, Typography } from "@hero/krypton"
import React from "react"
import styled from "styled-components"
import { Card } from "../../../../../00_shared/components/Card"
import { useDashboardTranslation } from "../../../../../01_technical/translations"

const CardContainer = styled(Card)`
  display: flex;
  gap: 1rem;
  justify-content: start;
  align-items: center;
  padding: 1rem;
  border: 1px dashed ${({ theme }) => theme.colors.grey.$300};
  cursor: pointer;
  transition: border-color 0.3s ease;
`

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
`

const Title = styled(Typography).attrs(() => ({
  $variant: "body-4-medium",
}))`
  color: ${({ theme }) => theme.colors.grey.$600};
`

const Description = styled(Typography).attrs(() => ({
  $variant: "caption-2",
}))`
  color: ${({ theme }) => theme.colors.grey.$500};
`

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 50%;
  color: ${({ theme }) => theme.colors.grey.$600};
`

interface AddAddressCardProps {
  onNewAddress: () => void
}

export const AddAddressCard: React.FC<AddAddressCardProps> = ({ onNewAddress }) => {
  const { t } = useDashboardTranslation()
  return (
    <CardContainer onClick={onNewAddress}>
      <IconWrapper>
        <AddIcon height={24} />
      </IconWrapper>
      <TextContainer>
        <Title>{t("issuing.addAddress.button.title")}</Title>
        <Description>{t("issuing.addAddress.button.description")}</Description>
      </TextContainer>
    </CardContainer>
  )
}
