import { gql } from "@apollo/client"
import { useHeroMutation } from "../../01_technical/requesting/useHeroMutation/useHeroMutation"

type SendChallengeDetailsSmsInput = {
  challengeId: string
  type: ChallengeType
}

export enum ChallengeType {
  FOR_CHALLENGE_2FA = "FOR_CHALLENGE_2FA",
  FOR_ENROLL_2FA = "FOR_ENROLL_2FA",
}

type RESEND_SMS_PARAMS = {
  details: SendChallengeDetailsSmsInput
}

type ResendSmsSuccessType = {
  success: boolean
}

const RESEND_SMS = gql`
  mutation SendChallengeSms($details: SendChallengeDetailsSmsInput!) {
    sendChallengeSms(details: $details) {
      ... on SendChallengeDetailsSmsSuccess {
        success
      }

      ... on SimpleApiError {
        errorCode
        message
      }
    }
  }
`
export function useResend() {
  return useHeroMutation<ResendSmsSuccessType, RESEND_SMS_PARAMS>({
    gqlQuerySchema: RESEND_SMS,
  })
}
