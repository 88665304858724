import { ApolloError } from "@apollo/client"
import { GraphQLError } from "graphql"

export enum CommonApiErrorCodes {
  UNKNOWN_ERROR = "UNKNOWN_ERROR",
  SERVER_OR_NETWORK_ERROR = "SERVER_OR_NETWORK_ERROR",
  VALIDATION_ERRORS = "VALIDATION_ERRORS",
  NOT_FOUND = "NOT_FOUND",
  UNAUTHORIZED = "UNAUTHORIZED",
  FORBIDDEN = "FORBIDDEN",
  INTERNAL_SERVER_ERROR = "INTERNAL_SERVER_ERROR",
  FUNCTIONAL_ERROR = "FUNCTIONAL_ERROR",
  INVALID_ARGUMENTS = "INVALID_ARGUMENTS",
}

/**
 * (i) This error is the Error object returned by the hooks that handle
 * querying & mutations (useHeroQuery & useHeroMutation).
 * The main goal of this object is to simplify the usage of the error
 * in the UI.
 */
export class HeroApiError extends Error {
  public code: string
  public translatedMessage: string

  constructor({ code, message, translatedMessage }: { code: string; message: string; translatedMessage?: string }) {
    super(message)
    this.code = code
    this.translatedMessage = translatedMessage || "Error" // the fallback will never be displayed since useHeroQuery always provides a translation
  }
}

/**
 * The base object of every error in the API, named the same way in the backend.
 */
interface SimpleApiError {
  errorCode: string
  message?: string
  details?: string
}

/**
 * Can be GqlHeroError, ValidationGraphQLError or any error extending SimpleApiError
 */
export type ApiError = SimpleApiError & Record<string, unknown>

export type RequestErrors = ApolloError | ApiError | GraphQLError

export interface GqlHeroError extends SimpleApiError {
  message: string
}

export interface ValidationGraphQLError extends SimpleApiError {
  validationErrors: {
    path: (string | number)[]
    validationError: string
  }[]
}
