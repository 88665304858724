import { gql } from "@apollo/client"
import { use2faChallenge } from "../../../../Auth/Challenge2fa/use2faChallenge.hooks"

const UNFREEZE_CARD = gql`
  mutation UnfreezeCard($cardId: String!) {
    unfreezeIssuingCard(cardId: $cardId) {
      ... on UnfreezeIssuingCardOutput {
        success
      }
      ... on GqlHeroError {
        errorCode
        message
      }
    }
  }
`

interface UnfreezeCardInput {
  cardId: string
}

interface UnfreezeCardOutput {
  success: boolean
}

export const useUnfreezeCard = () => {
  const {
    mutationWith2fa: mutate,
    mutationState: { data, error, loading },
  } = use2faChallenge<{ unfreezeIssuingCard: UnfreezeCardOutput }, UnfreezeCardInput>(UNFREEZE_CARD)

  const unfreezeCard = async (cardId: string) => {
    const result = await mutate({ cardId })
    return result.data?.unfreezeIssuingCard
  }

  return {
    unfreezeCard,
    data,
    loading,
    error,
  }
}
