import { Button, Header, InfoIcon, Tooltip, Typography } from "@hero/krypton"
import { useParams } from "react-router-dom"
import styled from "styled-components"
import { Skeleton } from "../../../../../00_shared/components/Skeleton"
import { useCommonTranslation, useDashboardTranslation } from "../../../../../01_technical/translations"
import { useBusinessAccountContext } from "../../../businessAccount.context"
import { useBusinessAccount } from "../hooks/useBusinessAccount"
import { useBusinessAccounts } from "../hooks/useBusinessAccounts"
import { currencyFormatter } from "../utils"
import { AccountDrawerContent } from "./AccountDrawer/AccountDrawerContent"
import { AccountHeaderTabs } from "./AccountHeaderTabs"

const DesktopHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 768px) {
    display: none;
  }
`

const HeaderWithoutGradient = styled(Header)`
  &::before {
    background: none;
  }

  [role="tooltip"] {
    z-index: 10;
  }

  @media (max-width: 768px) {
    min-height: unset;
    padding: 1rem 1rem 0 1rem;

    &:before {
      display: none;
    }
  }
`

const DesktopTotalAmountWrapper = styled.div`
  display: flex;
  gap: 0.2rem;
  align-items: center;
`

const MobileTotalAmountWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.2rem;
`

const TotalAmount = styled(Typography).attrs(() => ({
  $variant: "title-3-inter-bold",
}))`
  color: ${({ theme }) => theme.colors.black.$900};
  margin: 0;
`

const MobileHeader = styled.div`
  display: none;
  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: start;
  }
`

const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`

const DrawerContainer = styled.div`
  display: flex;
  gap: 0.5rem;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  width: 100%;
`

const ButtonWrapper = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;
  button {
    min-width: unset !important;
  }
`

const CustomFlexContainer = styled.div<{ $justify: string }>`
  display: flex;
  justify-content: ${({ $justify }) => $justify};
  width: 100%;
`

export const AccountHeader = () => {
  const { t: tc } = useCommonTranslation()
  const { t } = useDashboardTranslation()
  const { isBaActive, navigateToCreateTransfer } = useBusinessAccountContext()
  const { totalBalance: aggregatedTotalBalance, loading: loadingBusinessAccounts } = useBusinessAccounts()
  const { businessAccount, loading: loadingBusinessAccount } = useBusinessAccount()
  const { accountNb } = useParams<{ accountNb: string }>()

  const isAllBusinessAccount = accountNb === undefined

  const isLoading = isAllBusinessAccount ? loadingBusinessAccounts : loadingBusinessAccount

  const totalBalance = isAllBusinessAccount
    ? aggregatedTotalBalance
    : businessAccount !== undefined
      ? businessAccount.balance
      : 0

  return (
    <HeaderWithoutGradient>
      <MobileHeader>
        <TitleContainer>
          <Typography $variant="title-3-semibold">{t("accounts.mobileHeader.title")}</Typography>
          <Button
            onClick={() => navigateToCreateTransfer({ updateTransferOrigin: true })}
            size="small"
            isLoading={false}
            disabled={!isBaActive}
          >
            {t("accounts.buttonTransfer.text")}
          </Button>
        </TitleContainer>
        <DrawerContainer>
          <AccountDrawerContent />
          <CustomFlexContainer $justify="space-between">
            <MobileTotalAmountWrapper>
              {isLoading && <Skeleton height="2.25rem" width="6.5rem" />}
              {!isLoading && (
                <>
                  <TotalAmount>{currencyFormatter(totalBalance)}</TotalAmount>
                  <Tooltip id="total-balance-info-mobile" content={tc("accounts.fundsSafeNotice")}>
                    <InfoIcon width={24} />
                  </Tooltip>
                </>
              )}
            </MobileTotalAmountWrapper>
          </CustomFlexContainer>
        </DrawerContainer>
      </MobileHeader>
      <DesktopHeader>
        <DesktopTotalAmountWrapper>
          {isLoading && <Skeleton height="2.25rem" width="6.5rem" />}
          {!isLoading && (
            <>
              <TotalAmount>{currencyFormatter(totalBalance)}</TotalAmount>
              <Tooltip id="total-balance-info-desktop" content={tc("accounts.fundsSafeNotice")}>
                <InfoIcon width={24} />
              </Tooltip>
            </>
          )}
        </DesktopTotalAmountWrapper>
        <ButtonWrapper>
          <Button
            onClick={() => navigateToCreateTransfer({ updateTransferOrigin: true })}
            size="small"
            isLoading={false}
            disabled={!isBaActive}
          >
            {t("accounts.buttonTransfer.text")}
          </Button>
        </ButtonWrapper>
      </DesktopHeader>
      <AccountHeaderTabs />
    </HeaderWithoutGradient>
  )
}
