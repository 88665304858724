import { Typography } from "@hero/krypton"
import styled from "styled-components"
import { useDashboardTranslation } from "../../../../01_technical/translations"
import IconCheck from "../../00_shared/icons/icon-check.png"
import IconRefresh from "../../00_shared/icons/icon-refresh.png"
import { IssuanceStatus, IssuanceType, IssuingCard } from "../business/IssuingCard"
import SpendingLimit from "./IssuingCardSpendingLimits"

interface IssuingCardUsageProps {
  card: IssuingCard
}

const ModalSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 24px;
  width: 100%;
`

const Step = styled.div`
  display: flex;
  align-items: start;
  gap: 12px;
`

const Dot = styled.div<{ $marginLeft: number }>`
  width: 28px;
  height: 28px;
  border-radius: 24px;
  margin-left: ${({ $marginLeft }) => $marginLeft}px;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.3) 0%, #ffffff 100%);
  border: 1px solid #ffffff;
  box-shadow: 0 1px 2px 0 #0000000a;
  display: flex;
  justify-content: center;
  align-items: center;
`

const Labels = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
`

export const IssuingCardUsage = ({ card }: IssuingCardUsageProps) => {
  const { t } = useDashboardTranslation()

  return (
    <>
      {[IssuanceStatus.ACTIVE, IssuanceStatus.PARTIALLY_ACTIVE].includes(card.status) && <SpendingLimit card={card} />}
      {[IssuanceStatus.TERMINATED].includes(card.status) && (
        <Typography $variant="body-4-regular" style={{ textAlign: "start" }}>
          {t("issuing.card.deactivated.info")}
        </Typography>
      )}
      {[IssuanceStatus.FROZEN].includes(card.status) && (
        <Typography $variant="body-4-regular" style={{ textAlign: "start" }}>
          {t("issuing.card.frozen.info")}
        </Typography>
      )}
      {[IssuanceStatus.PRODUCTION, IssuanceStatus.ORDERED].includes(card.status) &&
        IssuanceType.PHYSICAL === card.issuanceType && (
          <ModalSection>
            <Step>
              <Dot $marginLeft={4}>
                <img src={IconCheck} alt="Check icon" style={{ height: 20 }} />
              </Dot>
              <Labels>
                <Typography $variant="body-3-medium">{t("issuing.card.tracking.ordered")}</Typography>
                <Typography $variant="body-4-regular">
                  {t("issuing.card.tracking.orderedDate")} {card.formattedOrderedDate}
                </Typography>
              </Labels>
            </Step>

            <Step>
              <Dot $marginLeft={4}>
                {card.status === "ORDERED" ? (
                  <img src={IconRefresh} alt="Refresh icon" style={{ height: 20 }} />
                ) : (
                  <img src={IconCheck} alt="Check icon" style={{ height: 20 }} />
                )}
              </Dot>
              <Labels>
                <Typography $variant="body-3-medium">{t("issuing.card.tracking.production")}</Typography>
                <Typography $variant="body-4-regular">
                  {t("issuing.card.tracking.productionDate")} {card.formattedProductionDate}
                </Typography>
              </Labels>
            </Step>

            <Step>
              <Dot $marginLeft={4}>
                <img src={IconRefresh} alt="Refresh icon" style={{ height: 20 }} />
              </Dot>
              <Labels>
                <Typography $variant="body-3-medium">{t("issuing.card.tracking.reception")}</Typography>
                <Typography $variant="body-4-regular">
                  {t("issuing.card.tracking.receptionDate")} {card.formattedReceptionDate}
                </Typography>
              </Labels>
            </Step>
          </ModalSection>
        )}
    </>
  )
}
