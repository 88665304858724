import { ErrorBlock, Spinner, Table, TBody, THead, Typography } from "@hero/krypton"
import { DateTime } from "luxon"
import styled from "styled-components"
import { useQuerySearchParams } from "../../../../00_shared/hooks/useQuerySearchParams.hook"
import { eurosToCents, toEuros } from "../../../../00_shared/utils/currency.converter"
import { toInternationalDate } from "../../../../00_shared/utils/date.converter"
import { checkIfIsDemoMerchant } from "../../../../00_shared/utils/demo"
import { titleCase } from "../../../../00_shared/utils/wording.converter"
import { TableCellLink } from "../../../../01_technical/tableCellLink"
import { useDashboardTranslation } from "../../../../01_technical/translations"
import { useAuthContext } from "../../../../Auth/auth.context"
import {
  AccountBox,
  CustomDiv,
  CustomPagination,
  CustomTdImg,
  EmptyTable,
  NoLogoUrl,
} from "../BalanceTables.components"
import { AllTransfersFilters } from "./AllTransfersFilters"
import { useGetApTransfers } from "./useGetApTransfers"

const Capitalize = styled(Typography)`
  text-transform: capitalize;
`

export type SearchParams = {
  transaction: "all"
  page: string
  accounts: string[]
  amountUnder?: string
  amountEqual?: string
  amountOver?: string
  dateFrom?: string
  dateTo?: string
}

const PAGE_SIZE_LIMIT = 20

const Th = styled.th`
  text-transform: uppercase;
`

export const BalanceAllTable = () => {
  const { getSearchParam, setSearchParam } = useQuerySearchParams<SearchParams>()
  const { t, i18n } = useDashboardTranslation()
  const { currentUser } = useAuthContext()
  const isDemoMerchant = checkIfIsDemoMerchant(currentUser.merchantId)

  const searchParams: SearchParams = {
    transaction: "all",
    page: getSearchParam("page"),
    accounts: getSearchParam("accounts") ? getSearchParam("accounts").split(",") : [],
    amountUnder: getSearchParam("amountUnder") || undefined,
    amountEqual: getSearchParam("amountEqual") || undefined,
    amountOver: getSearchParam("amountOver") || undefined,
    dateFrom: getSearchParam("dateFrom") || undefined,
    dateTo: getSearchParam("dateTo") || undefined,
  }

  const { page, accounts, amountUnder, amountEqual, amountOver, dateFrom, dateTo } = searchParams
  const pageNumber = page ? Number(page) : 1

  const { data, error, loading } = useGetApTransfers(
    {
      accounts: accounts.length === 0 ? undefined : accounts,
      amountMax: isNaN(Number(amountUnder)) ? undefined : eurosToCents(Number(amountUnder)),
      amountEqual: isNaN(Number(amountEqual)) ? undefined : eurosToCents(Number(amountEqual)),
      amountMin: isNaN(Number(amountOver)) ? undefined : eurosToCents(Number(amountOver)),
      createdAfter: dateFrom && dateTo ? DateTime.fromISO(dateFrom).toJSDate() : undefined,
      createdBefore: dateFrom && dateTo ? DateTime.fromISO(dateTo).toJSDate() : undefined,
    },
    { pageNumber, pageSize: PAGE_SIZE_LIMIT },
    { useDemoData: isDemoMerchant },
  )

  const totalPages = data?.pagination ? data.pagination.totalPage : 1
  const transfers = data?.transfers ?? []
  const accountLabels = data?.accounts ?? []

  const columns = [
    t("ap.balance.columns.name"),
    t("ap.balance.columns.amount"),
    t("ap.balance.columns.date"),
    t("ap.balance.columns.type"),
  ]

  return (
    <>
      <AllTransfersFilters setSearchParam={setSearchParam} searchParams={searchParams} accountLabels={accountLabels} />

      {error && <ErrorBlock $margin="1.5rem 3rem">{error?.translatedMessage}</ErrorBlock>}

      {loading && (
        <LoadingWrapper>
          <Spinner />
          <Title>{t("loading")}...</Title>
        </LoadingWrapper>
      )}

      {data && (
        <>
          <Table data-test-id="merchant-ap-balance-all-table">
            <THead>
              <tr>
                {columns.map((label) => (
                  <Th key={label}>{label}</Th>
                ))}
              </tr>
            </THead>

            <TBody $clickable={!!transfers.length}>
              {!transfers.length && (
                <tr>
                  <EmptyTable colSpan={5}>{t("ap.balance.noTransfer")}</EmptyTable>
                </tr>
              )}
              {transfers.map(({ id, amount, date, logoUrl, type, account }) => {
                const transactionDetailsUrl = `/ap/transfers/${type === "INCOMING" ? "incoming" : "outgoing"}/${id}`
                return (
                  <tr key={id}>
                    <td>
                      <TableCellLink to={transactionDetailsUrl} />
                      {type === "INCOMING" ? (
                        <CustomDiv>
                          {logoUrl ? <CustomTdImg src={logoUrl} alt={t("ap.balance.logoAlt")} /> : <NoLogoUrl />}
                          <Capitalize>{account.name}</Capitalize>
                        </CustomDiv>
                      ) : (
                        <CustomDiv>
                          {logoUrl ? <CustomTdImg src={logoUrl} alt={t("ap.balance.logoAlt")} /> : <NoLogoUrl />}
                          <AccountBox>
                            {/* eslint-disable-next-line i18next/no-literal-string */}
                            <Typography>Account - {titleCase(account.name)}</Typography>
                            <Typography $variant="caption-2">{account.number}</Typography>
                          </AccountBox>
                        </CustomDiv>
                      )}
                    </td>
                    <td>
                      <TableCellLink to={transactionDetailsUrl} />
                      {toEuros(amount)}
                    </td>

                    <td>
                      <TableCellLink to={transactionDetailsUrl} />
                      {toInternationalDate({ date: date, language: i18n.language })}
                    </td>

                    <td>
                      <TableCellLink to={transactionDetailsUrl} />
                      {type === "INCOMING" ? t("ap.balance.type.incoming") : t("ap.balance.type.outgoing")}
                    </td>
                  </tr>
                )
              })}
            </TBody>
          </Table>

          <CustomPagination
            currentPage={pageNumber}
            totalPages={totalPages}
            onPageChange={(pageNumber) => setSearchParam({ ...searchParams, page: pageNumber.toString() })}
          />
        </>
      )}
    </>
  )
}

const Title = styled.div`
  margin-left: 1rem;
  font-weight: 500;
`

const LoadingWrapper = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  padding: 1.5rem 3rem;
`
