import { gql } from "@apollo/client"
import { useHeroQuery } from "../../../../../01_technical/requesting/useHeroQuery/useHeroQuery"
import { useHeroMutation } from "../../../../../01_technical/requesting/useHeroMutation/useHeroMutation"

const GET_PAYMENT_CUSTOMERS = gql`
  query getPaymentCustomers {
    getPaymentCustomers {
      ... on GetPaymentCustomersOutput {
        customerInfos {
          id
          name
          email
          siret
          isIndividual
          phone
          billingAddress {
            line1
            line2
            city
            zipCode
            country
          }
          shippingAddress {
            line1
            line2
            city
            zipCode
            country
          }
        }
      }

      ... on GqlHeroError {
        errorCode
        message
      }

      ... on ValidationErrors {
        errorCode
        validationErrors {
          path
          validationError
        }
      }
    }
  }
`

type PaymentCustomerInfos = {
  id: string
  name: string
  email: string
  siret: string | null
  isIndividual: boolean
  phone: string
  billingAddress: {
    line1: string
    line2: string | null
    city: string
    zipCode: string
    country: string
  }
  shippingAddress: {
    line1: string
    line2: string | null
    city: string
    zipCode: string
    country: string
  } | null
}

type GetPaymentCustomersResponse = {
  customerInfos: PaymentCustomerInfos[]
}

export function usePaymentCustomers(isIndividual: boolean) {
  const { data, loading, error } = useHeroQuery<GetPaymentCustomersResponse>({
    gqlQuerySchema: GET_PAYMENT_CUSTOMERS,
  })

  return {
    customerInfos: data?.customerInfos.filter((customer) => customer.isIndividual === isIndividual) ?? [],
    loading,
    error,
  }
}

export function useCreateDraftPaymentLink() {
  return useHeroMutation<CreateDraftPaymentLinkResponse, CreateDraftPaymentLinkInput>({
    gqlQuerySchema: CREATE_DRAFT_PAYMENT_LINK,
  })
}

type CreateDraftPaymentLinkInput = {
  input: {
    customer: {
      name: string
      isIndividual: boolean
      email: string
      phone: string
      siret: string | null
      address: {
        line1: string
        city: string
        zipCode: string
      }
    }
  }
}

type CreateDraftPaymentLinkResponse = {
  paymentLinkId: string
}

const CREATE_DRAFT_PAYMENT_LINK = gql`
  mutation createDraftPaymentLinkV2($input: CreateDraftPaymentLinkInput!) {
    createDraftPaymentLinkV2(input: $input) {
      ... on CreateDraftPaymentLinkOutput {
        paymentLinkId
      }

      ... on GqlHeroError {
        errorCode
      }

      ... on ValidationErrors {
        validationErrors {
          path
          validationError
        }
      }
    }
  }
`
