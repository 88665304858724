import { PaymentConfigurationScope } from "../00_shared/enums/PaymentCore.enum"
import { useProductContext } from "../ProductScopeContext"
import { SettingsProductScope } from "./SettingsProductScope"

export const SettingsLink = () => {
  const productContext = useProductContext()

  return (
    <SettingsProductScope
      titleKey="collection.settings.link"
      enablePluginsNavigation={false}
      scopeBNPLEnabled={productContext?.linkScope === PaymentConfigurationScope.BNPL}
      scopePAY1xEnabled={productContext?.linkScope === PaymentConfigurationScope.PAY1X}
      productDisabled={!!!productContext?.linkEnabled}
    />
  )
}
