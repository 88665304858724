import { MerchantMenuEntry } from "../../merchant.menu.type"

export const AccountingMenuEntry: MerchantMenuEntry = {
  to: "/tools/accounting",
  key: "menu.list.tools.accounting",
  visible: true,
  selected: (location) => {
    return location.pathname.startsWith("/tools/accounting")
  },
  "data-test-id": "menu-entry-tools-accounting",
}
