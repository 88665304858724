import { ArrowLeftIcon, Button, Field, Label, Typography } from "@hero/krypton"
import { useEffect, useMemo } from "react"
import { useNavigate } from "react-router-dom"
import { Card, CardContent, CardFooter, CardHeader } from "../../../../../../00_shared/components/Card"
import { FlexContainer, FlexItem } from "../../../../../../00_shared/components/Flex"
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../../../../../00_shared/components/Select"
import { useCommonTranslation } from "../../../../../../01_technical/translations"
import { useBusinessAccountContext } from "../../../../businessAccount.context"
import { useCreateTransferContext } from "../../CreateTransferContext"
import { useBeneficiariesForTransfer } from "../hooks/useBeneficiariesForTransfer"

export const Beneficiary = () => {
  const { isBaActive } = useBusinessAccountContext()
  const navigate = useNavigate()
  const { state, setState, handleNextStep, goToPreviousStep, validateAllPreviousSteps, errors, clearState } =
    useCreateTransferContext()
  const { beneficiaries, fetchMoreBeneficiaries, loading } = useBeneficiariesForTransfer()
  const { t } = useCommonTranslation()

  const handleSelectChange = (value: string) => {
    if (value === "add-new-beneficiary") {
      navigate("/transfers/create/create-beneficiary")
    } else {
      const selectedBeneficiary = beneficiaries.find((b) => b.id === value)
      if (selectedBeneficiary) {
        setState((prevState) => ({
          ...prevState,
          beneficiaryId: selectedBeneficiary.id,
          beneficiaryName: selectedBeneficiary.fullName,
          beneficiaryIban: selectedBeneficiary.iban,
        }))
      }
    }
  }

  useEffect(() => {
    validateAllPreviousSteps()
  }, [validateAllPreviousSteps])

  const selectedBeneficiary = useMemo(() => {
    return beneficiaries.find((b) => b.id === state.beneficiaryId)
  }, [state.beneficiaryId, beneficiaries])

  const handleScroll = (e: React.UIEvent<HTMLDivElement>) => {
    const bottom = e.currentTarget.scrollHeight - e.currentTarget.scrollTop === e.currentTarget.clientHeight
    if (bottom && !loading) {
      fetchMoreBeneficiaries()
    }
  }

  return (
    <Card $variant="plain">
      <CardHeader>
        <Typography $variant="title-2-bold">{t("transfer.set.beneficiary.pageTitle")}</Typography>
      </CardHeader>
      <CardContent>
        <Select
          onValueChange={handleSelectChange}
          value={state.beneficiaryId}
          error={!!errors.beneficiaryId}
          errorMessage={errors.beneficiaryId}
        >
          <Label>{t("transfer.set.beneficiary.label")}</Label>
          <SelectTrigger disabled={!isBaActive}>
            {state.beneficiaryName ? (
              state.beneficiaryName
            ) : (
              <SelectValue placeholder={t("transfer.set.beneficiary.create.label")} />
            )}
          </SelectTrigger>
          <SelectContent onScroll={handleScroll}>
            <SelectItem value="add-new-beneficiary">
              <Typography as="span" $variant="body-4-semibold">
                {t("transfer.set.beneficiary.create.text")}
              </Typography>
            </SelectItem>
            {beneficiaries.map((beneficiary) => (
              <SelectItem key={beneficiary.id} value={beneficiary.id}>
                {beneficiary.fullName}
              </SelectItem>
            ))}
          </SelectContent>
        </Select>
        <Field
          type="text"
          $fullWidth
          fieldLabel={t("transfer.set.beneficiary.label")}
          placeholder={t("transfer.set.beneficiary.placeholder")}
          value={selectedBeneficiary?.fullName || state.beneficiaryName || ""}
          disabled
          readOnly
        />
        <Field
          $fullWidth
          fieldLabel={t("transfer.set.beneficiary.iban.label")}
          placeholder={t("transfer.set.beneficiary.iban.placeholder")}
          value={selectedBeneficiary?.iban || state.beneficiaryIban || ""}
          disabled
          readOnly
        />
      </CardContent>
      <CardFooter>
        <FlexItem $grow={1}>
          <FlexContainer $direction="column" $gap="2rem">
            <Button $fullWidth $variant="primary" size="medium" onClick={handleNextStep} disabled={!isBaActive}>
              {t("transfer.wizard.label.next")}
            </Button>
            <FlexItem $alignSelf="center">
              <Button
                type="button"
                size="medium"
                $variant="underline"
                onClick={() => {
                  goToPreviousStep()
                  clearState()
                }}
              >
                <ArrowLeftIcon />
                {t("transfer.wizard.label.back")}
              </Button>
            </FlexItem>
          </FlexContainer>
        </FlexItem>
      </CardFooter>
    </Card>
  )
}
