import { useHeroQuery } from "../../../../../01_technical/requesting/useHeroQuery/useHeroQuery"
import { balanceOutgoingFakeData } from "../../BalanceOutgoingTable/balanceOutgoingfakeData"
import {
  APHistoryMovementType,
  GET_AP_BANK_TRANSFER_DETAILS_QUERY,
  GetAPBankTransferDetailsSuccessResponse,
} from "./getMerchantBankTransfer.requests"

export const useGetMerchantBankTransfer = (filters: { bankTransferId: string }, config: { useDemoData: boolean }) => {
  const { data, error, loading } = useHeroQuery<GetAPBankTransferDetailsSuccessResponse>({
    gqlQuerySchema: GET_AP_BANK_TRANSFER_DETAILS_QUERY,
    variables: { id: filters.bankTransferId },
    fetchPolicy: "no-cache",
    skip: config.useDemoData,
  })

  if (config.useDemoData) {
    return {
      data: getMerchantBankTransferDemoData(filters),
      error: undefined,
      loading: false,
    }
  }

  return {
    data,
    error,
    loading,
  }
}

const getMerchantBankTransferDemoData = (filters: {
  bankTransferId: string
}): GetAPBankTransferDetailsSuccessResponse => {
  const data = balanceOutgoingFakeData.find((item) => item.id === filters.bankTransferId)

  if (!data) {
    throw new Error("Bank transfer not found")
  }

  const date = new Date(data.date).toISOString()

  const isSimpleTransfer = data.amount < 5000_00
  const FEE_PERCENTAGE = 1.5 / 100

  if (isSimpleTransfer) {
    const apAmount = data.amount / (1 - FEE_PERCENTAGE)
    const apFee = -(apAmount - data.amount)

    return {
      ...data,
      marketplaceName: data.accountLabel,
      iban: "FR6817569000505247353375F66",
      createdAt: date,
      account: {
        name: `Hero AP - ${data.accountLabel}`,
        number: data.accountNumber,
      },
      historyMovements: [
        {
          id: undefined,
          label: undefined,
          type: APHistoryMovementType.BALANCE_BEFORE,
          amount: 0,
          balanceAfter: undefined,
          createdAt: date,
          isMovement: false,
        },
        {
          id: "ap-69816dfe-ef98-4187-8592-d5c99bb32fcb",
          label: undefined,
          type: APHistoryMovementType.AP_CREDIT_GRANTED,
          amount: apAmount,
          balanceAfter: 0 + apAmount,
          createdAt: date,
          isMovement: true,
        },
        {
          id: "ap-69816dfe-ef98-4187-8592-d5c99bb32fcd",
          label: undefined,
          type: APHistoryMovementType.AP_CREDIT_FEES,
          amount: apFee,
          balanceAfter: 0 + apAmount + apFee,
          createdAt: date,
          isMovement: true,
        },
        {
          id: data.id,
          label: undefined,
          type: APHistoryMovementType.BANK_TRANSFER,
          amount: -(apAmount + apFee),
          balanceAfter: 0,
          createdAt: date,
          isMovement: true,
        },
        {
          id: undefined,
          label: undefined,
          type: APHistoryMovementType.BALANCE_AFTER,
          amount: 0,
          balanceAfter: undefined,
          createdAt: date,
          isMovement: false,
        },
      ],
    }
  }

  const BALANCE_BEFORE = 0

  const amountMarketplace = data.amount * 3 * 1.2
  const amountAp1 = -(data.amount * 1.1)
  const amountAp2 = -(data.amount * 0.92)
  const amountAp3 = -(data.amount * 1.18)

  const amountApWithoutFee = data.amount - (amountMarketplace + amountAp1 + amountAp2 + amountAp3)
  const amountAp = amountApWithoutFee / (1 - FEE_PERCENTAGE)
  const amountApFee = -(amountAp - amountApWithoutFee)

  const finalAmount = -(BALANCE_BEFORE + amountAp + amountApFee + amountMarketplace + amountAp1 + amountAp2 + amountAp3)

  const fakeData = {
    ...data,
    marketplaceName: data.accountLabel,
    iban: "FR6817569000505247353375F66",
    createdAt: date,
    account: {
      name: `Hero AP - ${data.accountLabel}`,
      number: data.accountNumber,
    },
    historyMovements: [
      {
        id: undefined,
        label: undefined,
        type: APHistoryMovementType.BALANCE_BEFORE,
        amount: BALANCE_BEFORE,
        balanceAfter: undefined,
        createdAt: date,
        isMovement: false,
      },
      {
        id: "ap-69816dfe-ef98-4187-8592-d5c99bb32fcb",
        label: undefined,
        type: APHistoryMovementType.AP_CREDIT_GRANTED,
        amount: amountAp,
        balanceAfter: BALANCE_BEFORE + amountAp,
        createdAt: date,
        isMovement: true,
      },
      {
        id: "ap-69816dfe-ef98-4187-8592-d5c99bb32fcd",
        label: undefined,
        type: APHistoryMovementType.AP_CREDIT_FEES,
        amount: amountApFee,
        balanceAfter: BALANCE_BEFORE + amountAp + amountApFee,
        createdAt: date,
        isMovement: true,
      },
      {
        id: "psptran-af00333e-6cfd-4e91-980d-cb60dca64bf8",
        label: undefined,
        type: APHistoryMovementType.SWAN_MOVEMENT_ALLOCATED,
        amount: amountMarketplace,
        balanceAfter: BALANCE_BEFORE + amountAp + amountApFee + amountMarketplace,
        createdAt: date,
        isMovement: false,
      },
      {
        id: "ap-d01b6612-a57c-4c9d-8308-2dc83d4f8c2a",
        label: undefined,
        type: APHistoryMovementType.AP_CREDIT_REPAYMENT,
        amount: amountAp1,
        isMovement: true,
        balanceAfter: BALANCE_BEFORE + amountAp + amountApFee + amountMarketplace + amountAp1,
        createdAt: date,
      },
      {
        id: "ap-e30e132e-45fb-43bf-8126-694afc540936",
        label: undefined,
        type: APHistoryMovementType.AP_CREDIT_REPAYMENT,
        amount: amountAp2,
        isMovement: true,
        balanceAfter: BALANCE_BEFORE + amountAp + amountApFee + amountMarketplace + amountAp1 + amountAp2,
        createdAt: date,
      },
      {
        id: "ap-d7af50b5-3cdf-491f-b4be-2d8bea6799d6",
        label: undefined,
        type: APHistoryMovementType.AP_CREDIT_REPAYMENT,
        amount: amountAp3,
        isMovement: true,
        balanceAfter: BALANCE_BEFORE + amountAp + amountApFee + amountMarketplace + amountAp1 + amountAp2 + amountAp3,
        createdAt: date,
      },
      {
        id: data.id,
        label: undefined,
        type: APHistoryMovementType.BANK_TRANSFER,
        amount: finalAmount,
        balanceAfter:
          BALANCE_BEFORE + amountAp + amountApFee + amountMarketplace + amountAp1 + amountAp2 + amountAp3 + finalAmount,
        createdAt: date,
        isMovement: true,
      },
      {
        id: undefined,
        label: undefined,
        type: APHistoryMovementType.BALANCE_AFTER,
        amount:
          BALANCE_BEFORE + amountAp + amountApFee + amountMarketplace + amountAp1 + amountAp2 + amountAp3 + finalAmount,
        balanceAfter: undefined,
        createdAt: date,
        isMovement: false,
      },
    ],
  }

  return fakeData
}
