import { ApolloError, useQuery } from "@apollo/client"
import { unwrapGraphQLResponse } from "../../../../01_technical/requesting/DEPRECATED_graphql.errors"
import { GqlHeroError, ValidationGraphQLError } from "../../../../01_technical/requesting/request-error.type"
import { getDatesFromPeriod } from "../../00_shared/utils/getDatesFromPeriod"
import {
  GET_BAR_CHART_ARGS,
  GET_BAR_CHART_DATA,
  GET_BAR_CHART_RESPONSE,
  GET_BAR_CHART_RESPONSE_SUCCESS,
} from "../cockpitWithNewKPI.requests"
import { getDemoApBarChartData } from "../utils/demoApBarChartData.utils"

export const useGetBarChartData = (
  filters: { period: string },
  config: { useDemoData: boolean },
): {
  barChartData: GET_BAR_CHART_RESPONSE_SUCCESS | undefined
  barChartLoading: boolean
  barChartTechnicalError: ApolloError | undefined
  barChartFunctionalError: GqlHeroError | ValidationGraphQLError | undefined
} => {
  const { startDate, endDate } = getDatesFromPeriod(filters.period)

  const { data, loading, error } = useQuery<GET_BAR_CHART_RESPONSE, GET_BAR_CHART_ARGS>(GET_BAR_CHART_DATA, {
    variables: {
      startDate,
      endDate,
    },
  })

  if (config.useDemoData) {
    return {
      barChartData: getDemoApBarChartData(startDate, endDate),
      barChartLoading: false,
      barChartTechnicalError: undefined,
      barChartFunctionalError: undefined,
    }
  }

  const { data: barChartData, error: barChartFunctionalError } = unwrapGraphQLResponse<GET_BAR_CHART_RESPONSE_SUCCESS>(
    data?.merchantGetApBarChartDataV2,
  )
  return {
    barChartData,
    barChartLoading: loading,
    barChartTechnicalError: error,
    barChartFunctionalError,
  }
}
