import { gql } from "@apollo/client"
import { useHeroQuery } from "../../../../../01_technical/requesting/useHeroQuery/useHeroQuery"

const GET_RIB_SIGNED_URL_QUERY = gql`
  query GetDownloadableBARib($businessAccountId: String!) {
    downloadableBusinessAccountRibSignedUrl(businessAccountId: $businessAccountId) {
      ... on GetDownloadableBARibOutput {
        signedUrl
      }
      ... on SimpleApiError {
        errorCode
        message
      }
    }
  }
`

export const useRibSignedUrl = (businessAccountId?: string) => {
  const { data, loading, error } = useHeroQuery<{ signedUrl?: string }>({
    gqlQuerySchema: GET_RIB_SIGNED_URL_QUERY,
    variables: {
      businessAccountId,
    },
    skip: !businessAccountId,
    fetchPolicy: "cache-and-network",
  })

  return {
    ribSignedUrl: data?.signedUrl,
    loading,
    error,
  }
}
