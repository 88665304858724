import { gql } from "@apollo/client"

export type ForgotPasswordArgs = {
  email: string
  locale: string
}

export type ForgotPasswordResponse = {
  sendPasswordResetLink: boolean
}

export const FORGOT_PASSWORD = gql`
  mutation sendPasswordResetLink($email: String!, $locale: UserLocale) {
    sendPasswordResetLink(email: $email, locale: $locale)
  }
`
