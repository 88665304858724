import { gql } from "@apollo/client"

export const INVITE_USER_MERCHANT_MUTATION = gql`
  mutation merchantOwnerInviteUser($firstname: String!, $lastname: String!, $email: String!, $phone: String!) {
    merchantOwnerInviteUser(firstname: $firstname, lastname: $lastname, email: $email, phone: $phone) {
      id
      firstname
      lastname
      email
      phone
    }
  }
`
