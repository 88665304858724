import { Button, ButtonProps, Typography } from "@hero/krypton"
import * as DropdownMenuPrimitive from "@radix-ui/react-dropdown-menu"
import React, { createContext, ElementType, useContext, useLayoutEffect, useRef, useState } from "react"
import styled, { css, keyframes } from "styled-components"

// Context for managing trigger width
const DropdownMenuContext = createContext<{
  triggerWidth: number | undefined
  setTriggerWidth: React.Dispatch<React.SetStateAction<number | undefined>>
} | null>(null)

const DropdownMenuProvider = ({ children }: { children: React.ReactNode }) => {
  const [triggerWidth, setTriggerWidth] = useState<number | undefined>(undefined)
  return (
    <DropdownMenuContext.Provider value={{ triggerWidth, setTriggerWidth }}>{children}</DropdownMenuContext.Provider>
  )
}

const useDropdownMenuContext = () => {
  const context = useContext(DropdownMenuContext)
  if (!context) throw new Error("useDropdownMenuContext must be used within DropdownMenuProvider")
  return context
}

// Keyframes for animations
const fadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`

const fadeOut = keyframes`
  from { opacity: 1; }
  to { opacity: 0; }
`

const zoomIn = keyframes`
  from { transform: scale(0.95); opacity: 0; }
  to { transform: scale(1); opacity: 1; }
`

const zoomOut = keyframes`
  from { transform: scale(1); opacity: 1; }
  to { transform: scale(0.95); opacity: 0; }
`

const slideInFromTop = keyframes`
  from { transform: translateY(-0.5rem); }
  to { transform: translateY(0); }
`

const slideInFromBottom = keyframes`
  from { transform: translateY(0.5rem); }
  to { transform: translateY(0); }
`

const slideInFromLeft = keyframes`
  from { transform: translateX(-0.5rem); }
  to { transform: translateX(0); }
`

const slideInFromRight = keyframes`
  from { transform: translateX(0.5rem); }
  to { transform: translateX(0); }
`

const DropdownMenu = ({ children }: { children: React.ReactNode }) => (
  <DropdownMenuProvider>
    <DropdownMenuPrimitive.Root>{children}</DropdownMenuPrimitive.Root>
  </DropdownMenuProvider>
)

const DropdownMenuTrigger = React.forwardRef<HTMLButtonElement, ButtonProps<ElementType>>(
  ({ children, asChild = false, ...props }, ref) => {
    const { setTriggerWidth } = useDropdownMenuContext()
    const triggerRef = useRef<HTMLButtonElement>(null)

    useLayoutEffect(() => {
      if (triggerRef.current) {
        setTriggerWidth(triggerRef.current.offsetWidth)
      }
    }, [setTriggerWidth])

    if (asChild) {
      return (
        <DropdownMenuPrimitive.Trigger asChild>
          {React.cloneElement(children as React.ReactElement, { ref: triggerRef })}
        </DropdownMenuPrimitive.Trigger>
      )
    }

    return (
      <DropdownMenuPrimitive.Trigger asChild>
        <Button ref={triggerRef} {...props}>
          {children}
        </Button>
      </DropdownMenuPrimitive.Trigger>
    )
  },
)
DropdownMenuTrigger.displayName = "DropdownMenuTrigger"

// Styled dropdown menu content with dynamic min-width
const StyledDropdownMenuContent = styled(DropdownMenuPrimitive.Content)<{ $triggerWidth?: number }>`
  z-index: 50;
  overflow: hidden;
  background-color: ${({ theme }) => theme.colors.grey.$100};
  border: 1px solid ${({ theme }) => theme.colors.grey.$200};
  border-radius: 0.75rem;
  box-shadow: ${({ theme }) => theme.shadows.mid};
  padding: 0.25rem;
  color: ${({ theme }) => theme.colors.black};
  min-width: ${({ $triggerWidth }) => ($triggerWidth ? `${$triggerWidth}px` : "auto")};

  ${() => css`
    &[data-side="top"] {
      animation: ${slideInFromBottom} 0.15s ease-out;
    }
    &[data-side="bottom"] {
      animation: ${slideInFromTop} 0.15s ease-out;
    }
    &[data-side="left"] {
      animation: ${slideInFromRight} 0.15s ease-out;
    }
    &[data-side="right"] {
      animation: ${slideInFromLeft} 0.15s ease-out;
    }
    &[data-state="open"] {
      animation:
        ${fadeIn} 0.15s ease-out,
        ${zoomIn} 0.15s ease-out;
    }
    &[data-state="closed"] {
      animation:
        ${fadeOut} 0.15s ease-in,
        ${zoomOut} 0.15s ease-in;
    }
  `}
`

const DropdownMenuContent = React.forwardRef<
  React.ElementRef<typeof DropdownMenuPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof DropdownMenuPrimitive.Content>
>(({ sideOffset = 4, ...props }, ref) => {
  const { triggerWidth } = useDropdownMenuContext()

  return (
    <DropdownMenuPrimitive.Portal>
      <StyledDropdownMenuContent ref={ref} sideOffset={sideOffset} forceMount {...props} $triggerWidth={triggerWidth} />
    </DropdownMenuPrimitive.Portal>
  )
})
DropdownMenuContent.displayName = "DropdownMenuContent"

const StyledDropdownMenuItem = styled(DropdownMenuPrimitive.Item)<{ inset?: boolean }>`
  display: flex;
  align-items: center;
  padding: 0.2rem 0.5rem;
  cursor: pointer;
  gap: 0.5rem;
  border-radius: 0.5rem;
  transition:
    background-color 0.15s ease,
    color 0.15s ease;

  &:hover {
    background-color: ${({ theme }) => theme.colors.grey.$200};
    color: ${({ theme }) => theme.colors.grey.$600};
  }

  &[data-disabled] {
    pointer-events: none;
    opacity: 0.5;
  }

  ${({ inset }) =>
    inset &&
    css`
      padding-left: 2rem;
    `}
`

const DropdownMenuItem = React.forwardRef<
  React.ElementRef<typeof DropdownMenuPrimitive.Item>,
  React.ComponentPropsWithoutRef<typeof DropdownMenuPrimitive.Item> & { inset?: boolean }
>(({ inset, children, ...props }, ref) => (
  <StyledDropdownMenuItem ref={ref} inset={inset} {...props}>
    <Typography $variant="body-4-regular" $color="grey.$600">
      {children}
    </Typography>
  </StyledDropdownMenuItem>
))
DropdownMenuItem.displayName = "DropdownMenuItem"

export { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger }
