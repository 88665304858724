import { gql } from "@apollo/client"
import { useHeroQuery } from "../../../../01_technical/requesting/useHeroQuery/useHeroQuery"

type ListBeneficiariesPaginationInput = {
  pageSize: number
  pageNumber: number
}

type ListBeneficiariesFilters = {
  merchantId: string
  status?: BENEFICIARY_STATUS
  isDefault?: boolean
  search?: string
}

export type Beneficiary = {
  id: string
  isDefault: boolean
  fullName: string
  status: BENEFICIARY_STATUS
  iban: string
  linkedMerchantAccounts: { id: string; label: string; accountId: string }[]
  bic: string | null
  label: string | null
  bankName?: string
}

enum BENEFICIARY_STATUS {
  WAITING_FOR_VERIFICATION = "WAITING_FOR_VERIFICATION",
}

export function useGetBeneficiaries(pagination: ListBeneficiariesPaginationInput, filters?: ListBeneficiariesFilters) {
  return useHeroQuery<GET_MERCHANT_BENEFICIARIES_LIST_RESPONSE>({
    gqlQuerySchema: GET_MERCHANT_BENEFICIARIES_LIST,
    variables: { pagination, filters },
  })
}
type GET_MERCHANT_BENEFICIARIES_LIST_RESPONSE = {
  beneficiaries: Beneficiary[]
  pagination: {
    totalCount: number
    totalPage: number
    currentPage: number
  }
}
const GET_MERCHANT_BENEFICIARIES_LIST = gql`
  query listBeneficiaries($filters: ListBeneficiariesFilters, $pagination: ListBeneficiariesPaginationInput!) {
    listBeneficiaries(filters: $filters, pagination: $pagination) {
      ... on ListBeneficiariesOutput {
        beneficiaries {
          id
          label
          isDefault
          fullName
          status
          iban
          bic
          linkedMerchantAccounts {
            id
            label
            accountId
          }
          bankName
        }
        pagination {
          totalCount
          totalPage
          currentPage
        }
      }

      ... on SimpleApiError {
        errorCode
      }

      ... on ValidationErrors {
        errorCode
      }
    }
  }
`
