import { ApolloError, ApolloQueryResult, FetchMoreQueryOptions, OperationVariables } from "@apollo/client"
import { ApiError, CommonApiErrorCodes, HeroApiError } from "../request-error.type"
import { getErrorWithCode, isApiError } from "../request-error.utils"
import { UseHeroQueryReturnType } from "./useHeroQuery.type"

// ! In case of edit, make sure to update the copy in the admin app as well !

export function getSimplifiedQueryResult<SuccessData>(query: {
  data?: Record<string, ApiError | SuccessData>
  loading?: boolean
  error?: ApolloError
  refetch: (
    variables?: Partial<OperationVariables> | undefined,
  ) => Promise<ApolloQueryResult<Record<string, SuccessData | ApiError>>>
  fetchMore: <TFetchData = SuccessData, TFetchVars extends OperationVariables = OperationVariables>(
    fetchMoreOptions: FetchMoreQueryOptions<TFetchVars, TFetchData> & {
      updateQuery?: (
        previousQueryResult: Record<string, SuccessData | ApiError>,
        options: {
          fetchMoreResult: TFetchData
          variables: TFetchVars
        },
      ) => Record<string, SuccessData | ApiError>
    },
  ) => Promise<ApolloQueryResult<TFetchData>>
  skip?: boolean
}): UseHeroQueryReturnType<SuccessData> {
  const baseReturn = {
    data: null,
    error: null,
    initialError: null,
    loading: false as const,
    refetch: query.refetch,
    fetchMore: query.fetchMore,
  }

  if (query.data) {
    const dataResponse = query.data[Object.keys(query.data)[0]]

    if (isApiError(dataResponse)) {
      return {
        ...baseReturn,
        error: getErrorWithCode(dataResponse),
        initialError: dataResponse,
      }
    }

    return {
      ...baseReturn,
      data: dataResponse,
    }
  }

  if (query.loading) {
    return {
      ...baseReturn,
      loading: true,
    }
  }

  if (query.error) {
    return {
      ...baseReturn,
      error: new HeroApiError({
        code: CommonApiErrorCodes.SERVER_OR_NETWORK_ERROR,
        message: query.error.message,
      }),
      initialError: query.error,
    }
  }

  if (query.skip) {
    return baseReturn
  }

  return {
    ...baseReturn,
    error: new HeroApiError({ code: CommonApiErrorCodes.UNKNOWN_ERROR, message: "An error occured." }),
  }
}
