import { useState } from "react"

export interface UseTypeFilterConfig {
  applyImmediately: boolean
}

export interface TypeState {
  types: string[]
  toggleType: (type: string) => void
  reset: (localStateOnly?: boolean) => void
  apply: () => void
}

export const useTypeFilter = (
  config: UseTypeFilterConfig,
  updateUrlParams: (params: { transactionType: string[] }) => void,
): TypeState => {
  const [types, setTypes] = useState<string[]>([])

  const toggleType = (type: string) => {
    const newTypes = types.includes(type) ? types.filter((t) => t !== type) : [...types, type]
    setTypes(newTypes)
    if (config.applyImmediately) {
      updateUrlParams({ transactionType: newTypes })
    }
  }

  const reset = (localStateOnly = false) => {
    setTypes([])
    if (!localStateOnly) {
      updateUrlParams({ transactionType: [] })
    }
  }

  const apply = () => {
    updateUrlParams({ transactionType: types })
  }

  return {
    types,
    toggleType,
    reset,
    apply,
  }
}
