import { Typography } from "@hero/krypton"
import styled from "styled-components"
import { Card } from "../../../../../../../00_shared/components/Card"
import { FlexContainer } from "../../../../../../../00_shared/components/Flex"
import { TransferIndicator } from "./TransferIndicator"

const StepContainer = styled(Card)`
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  border-radius: 0.5rem;
`

const Step = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  &:last-child {
    margin-bottom: 0;
  }
`

const StepText = styled(Typography).attrs(() => ({
  $variant: "body-4-medium",
}))`
  color: ${({ theme }) => theme.colors.grey.$600};
`

const StepTime = styled(Typography).attrs(() => ({
  $variant: "caption-2",
}))`
  color: ${({ theme }) => theme.colors.grey.$500};
`

interface TransactionStepsProps {
  statuses: {
    label: string
    value: string | undefined
  }[]
}

export const TransactionSteps = ({ statuses }: TransactionStepsProps) => {
  return (
    <StepContainer>
      {statuses.map((status, index) => (
        <Step key={index}>
          <FlexContainer $align="center" $gap=".5rem">
            <TransferIndicator $isActive={status.value !== undefined} />
            <StepText>{status.label}</StepText>
          </FlexContainer>
          <StepTime>{status.value || "-"}</StepTime>
        </Step>
      ))}
    </StepContainer>
  )
}
