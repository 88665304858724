import { gql } from "@apollo/client"
import { PaymentType } from "../../../../business/enums/Payment.enum"
import { TransactionFlowType, TransactionStatus } from "../../../../business/enums/Transaction.enum"

export type Transaction = {
  id: string
  orderId?: string
  createdAt: string
  paymentType: PaymentType | undefined
  amount: number
  status: TransactionStatus
  flowType: TransactionFlowType
  customerEmail: string
}

type PaginationInput = {
  pageSize: number
  pageNumber: number
}

export type GetMerchantCustomerTransactionsQueryArgsType = {
  pagination: PaginationInput
  referenceOrSiren: string
}

type PaginationProps = {
  currentPage: number
  totalCount: number
  totalPage: number
}

export type GetMerchantCustomerTransactionsQueryResult = {
  merchantCustomerTransactions: {
    reference?: string
    outstandingAmount: number
    maxOutstandingLimit: number
    companyInfo: {
      lastEmailUsed?: string
      lastNameUsed?: string
      lastSirenOrSiretUsed?: string
    }
    transactions: Transaction[]
    transactionsSum: number
    paginationInfo: PaginationProps
  }
}

export const GET_MERCHANT_CUSTOMER_TRANSACTIONS = gql`
  query merchantCustomerTransactions($pagination: PaginationInput!, $referenceOrSiren: String!) {
    merchantCustomerTransactions(pagination: $pagination, customerReferenceOrSiren: $referenceOrSiren) {
      reference
      outstandingAmount
      maxOutstandingLimit
      companyInfo {
        lastEmailUsed
        lastNameUsed
        lastSirenOrSiretUsed
      }
      transactions {
        id
        orderId
        createdAt
        paymentType
        amount
        status
        flowType
      }
      paginationInfo {
        totalCount
        totalPage
        currentPage
      }
      transactionsSum
    }
  }
`
