import { useMutation, useQuery } from "@apollo/client"
import { Button, Typography, toaster } from "@hero/krypton"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import styled from "styled-components"
import { InviteMerchantUserModal } from "./inviteMerchantUser/inviteMerchantUser.modal"
import {
  CHANGE_MERCHANT_USER_STATUS,
  LIST_MERCHANT_USERS,
  MerchantUser,
  RESEND_MERCHANT_USER_INVITATION,
  UserStatus,
} from "./listMerchantUser.requests"

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const Message = styled(Typography)`
  color: ${({ theme }) => theme.colors.grey.$500};
`

const Table = styled.table`
  width: calc(100% - 2rem);
  margin: 0 1rem;
  margin-top: 1rem;
  border-collapse: collapse;
  border-radius: 0.5rem;
  overflow: hidden;

  & thead {
    text-align: left;
    background-color: ${({ theme }) => theme.colors.grey.$200};
    color: ${({ theme }) => theme.colors.grey.$600};
  }

  & td,
  & th {
    padding-left: 1rem;
    vertical-align: middle;
  }

  & tr {
    height: 3rem;
  }

  & tbody tr:not(:last-child) td {
    border-bottom: 1px solid ${({ theme }) => theme.colors.grey.$200};
  }
`

const OptionCell = styled(Typography)`
  width: 15rem;
`

const ActionButton = styled(Typography).attrs((props) => ({
  as: props.as ?? "button",
}))`
  border: none;
  background: none;
  padding: 1rem 0;
  text-decoration: underline;
  color: ${({ theme }) => theme.colors.grey.$600};
  cursor: pointer;

  &:hover {
    text-decoration: none;
  }
`

export const ListMerchantUserScreen = () => {
  const { t } = useTranslation()
  const [displayInviteMerchantUser, setDisplayInviteMerchantUser] = useState(false)
  const { data, loading, error, refetch } = useQuery<{ listMerchantUsers: MerchantUser[] }>(LIST_MERCHANT_USERS)

  const [resendInvitation] = useMutation(RESEND_MERCHANT_USER_INVITATION)
  const [changeMerchantUserStatus] = useMutation(CHANGE_MERCHANT_USER_STATUS)
  const activateUser = (user: MerchantUser) => () => {
    toaster.promise(
      changeMerchantUserStatus({ variables: { userId: user.id, status: UserStatus.ACTIVE } }),
      {
        pending: t("merchant.tools.setting.merchantUser.activateUserStatus.pending"),
        success: t("merchant.tools.setting.merchantUser.activateUserStatus.success"),
        error: t("merchant.tools.setting.merchantUser.activateUserStatus.error"),
      },
      { position: "top-right" },
    )
  }
  const desactivateUser = (user: MerchantUser) => () => {
    toaster.promise(
      changeMerchantUserStatus({ variables: { userId: user.id, status: UserStatus.INACTIVE } }),
      {
        pending: t("merchant.tools.setting.merchantUser.disableUserStatus.pending"),
        success: t("merchant.tools.setting.merchantUser.disableUserStatus.success"),
        error: t("merchant.tools.setting.merchantUser.disableUserStatus.error"),
      },
      { position: "top-right" },
    )
  }
  const resendInviteToUser = (user: MerchantUser) => () => {
    toaster.promise(
      resendInvitation({ variables: { userId: user.id } }),
      {
        pending: t("merchant.tools.setting.merchantUser.invited.pending"),
        success: t("merchant.tools.setting.merchantUser.invited.success"),
        error: t("merchant.tools.setting.merchantUser.invited.error"),
      },
      { position: "top-right" },
    )
  }

  useEffect(() => {
    if (error) {
      toaster.error(t("merchant.tools.setting.merchantUser.error"), { position: "top-right" })
      console.error(error)
    }
  }, [error, t])

  const message = loading
    ? t("merchant.tools.setting.merchantUser.loading")
    : !data?.listMerchantUsers?.length
      ? t("merchant.tools.setting.merchantUser.noUsers")
      : null

  return (
    <section>
      <Header>
        <Typography as="h1" $variant="title-2-bold">
          {t("merchant.tools.setting.merchantUser.title")}
        </Typography>
        <Button isLoading={false} data-test-id="invite" onClick={() => setDisplayInviteMerchantUser(true)}>
          {t("merchant.tools.setting.merchantUser.inviteUser")}
        </Button>
      </Header>

      {message ? (
        <Message $variant="caption-2">{message}</Message>
      ) : (
        <Table>
          <thead>
            <tr>
              <Typography as="th" $variant="label-1">
                {t("merchant.tools.setting.merchantUser.table.email")}
              </Typography>
              <OptionCell as="th" $variant="label-1">
                {t("merchant.tools.setting.merchantUser.table.options")}
              </OptionCell>
            </tr>
          </thead>
          <tbody>
            {/* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */}
            {data!.listMerchantUsers.map((user) => (
              <tr key={user.id} data-test-id="user-row-">
                <Typography as="td">{user.email}</Typography>
                <OptionCell as="td">
                  {
                    {
                      [UserStatus.INACTIVE]: (
                        <ActionButton data-test-id={`activate-button-${user.email}`} onClick={activateUser(user)}>
                          {t("merchant.tools.setting.merchantUser.enable")}
                        </ActionButton>
                      ),
                      [UserStatus.ACTIVE]: (
                        <ActionButton data-test-id={`deactivate-button-${user.email}`} onClick={desactivateUser(user)}>
                          {t("merchant.tools.setting.merchantUser.disable")}
                        </ActionButton>
                      ),
                      [UserStatus.INVITED]: (
                        <ActionButton onClick={resendInviteToUser(user)}>
                          {t("merchant.tools.setting.merchantUser.sendMailAgain")}
                        </ActionButton>
                      ),
                    }[user.status]
                  }
                </OptionCell>
              </tr>
            ))}
          </tbody>
        </Table>
      )}

      {displayInviteMerchantUser && (
        <InviteMerchantUserModal
          isOpen
          onNewUserCreated={() => {
            refetch()
          }}
          onClose={() => {
            setDisplayInviteMerchantUser(false)
          }}
        />
      )}
    </section>
  )
}
