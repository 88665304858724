import { BOV2RouteProtector } from "../../../App.router"
import { Settings } from "./Settings"

export const settingsRoute = {
  path: "tools/settings/*",
  element: (
    <BOV2RouteProtector>
      <Settings />
    </BOV2RouteProtector>
  ),
}
