import { ApolloQueryResult, gql, useQuery } from "@apollo/client"
import { Typography } from "@hero/krypton"
import * as Sentry from "@sentry/react"
import { createContext, useContext, useEffect, useRef } from "react"
import { useTranslation } from "react-i18next"
import CenteredLoading from "../00_shared/components/CenteredLoading"
import { useAmplitude } from "../00_shared/hooks/useAmplitude.hook"
import { useRouteListener } from "../00_shared/hooks/useRouteListener"
import { checkIfIsDemoMerchant } from "../00_shared/utils/demo"
import { TranslationNamespaces } from "../01_technical/translations"
import IntercomProvider, { useIntercomContext } from "../IntercomProvider"
import { MerchantOnboardingFlowStatus, MerchantStatus } from "../business/enums/Merchant.enum"
import { UserLocale } from "../business/enums/User.enum"

export interface CurrentUser {
  id: string
  intercomUserHash: string
  email: string
  merchantId: string
  firstname: string
  lastname: string
  phone: string
  merchantTradingName: string
  payOfflineAvailable: boolean
  merchantStatus: MerchantStatus
  canShowAPPages: boolean
  canShowCockpit: boolean
  isDemoMerchant: boolean
  onboardingFlowStatuses: {
    BA: MerchantOnboardingFlowStatus
    P1X: MerchantOnboardingFlowStatus
    CWT: MerchantOnboardingFlowStatus
    AP: MerchantOnboardingFlowStatus
    BNPL: MerchantOnboardingFlowStatus
  }
}

type UserTypeBackend = {
  getmerchant: {
    id: string
    tradingName: string
    status: MerchantStatus
    canShowAPCockpit: boolean
    canShowCockpit: boolean
    onboardingFlowStatuses: {
      BA: MerchantOnboardingFlowStatus
      P1X: MerchantOnboardingFlowStatus
      CWT: MerchantOnboardingFlowStatus
      AP: MerchantOnboardingFlowStatus
      BNPL: MerchantOnboardingFlowStatus
    }
  }
  user: {
    id: string
    intercomUserHash: string
    phone: string
    firstname: string
    lastname: string
    email: string
    locale: UserLocale
  }
  me: {
    merchant: {
      payOfflineAvailable: boolean
    }
  }
}

const ME_QUERY = gql`
  query {
    getmerchant {
      id
      tradingName
      status
      canShowAPCockpit
      canShowCockpit
      onboardingFlowStatuses {
        BA
        P1X
        CWT
        AP
        BNPL
      }
    }

    user {
      id
      intercomUserHash
      firstname
      lastname
      phone
      email
      locale
    }

    me {
      merchant {
        payOfflineAvailable
      }
    }
  }
`

/**
 * @public
 */
export type AuthContextType = { currentUser: CurrentUser; refetch: () => Promise<ApolloQueryResult<UserTypeBackend>> }

const AuthContext = createContext<AuthContextType>({} as AuthContextType)

export const useAuthContext = () => useContext(AuthContext)

export const AuthProvider: React.FC<{ children: JSX.Element | JSX.Element[] }> = ({ children }) => {
  const { data, loading, error, refetch } = useQuery<UserTypeBackend>(ME_QUERY)
  const { t, i18n } = useTranslation(TranslationNamespaces.DASHBOARD)
  const isLocaleLoaded = useRef(false)
  const { bootIntercom } = useIntercomContext()
  const amplitude = useAmplitude()

  const handleRouteChange = (pathname: string) => {
    if (pathname === "/") {
      localStorage.removeItem("BO_V3")
    }
  }

  useRouteListener(handleRouteChange)

  useEffect(() => {
    bootIntercom()
  }, [bootIntercom])

  useEffect(() => {
    if (data) {
      Sentry.addBreadcrumb({
        category: "auth",
        message: `merchant: ${data.user.id}`,
        level: "info",
      })
    }

    if (data?.user.locale && !isLocaleLoaded.current) {
      i18n.changeLanguage(data.user.locale.toLocaleLowerCase())
      isLocaleLoaded.current = true
    }
  }, [data, i18n])

  if (loading) {
    return <CenteredLoading />
  }

  if (error) {
    return (
      <div
        style={{
          height: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
          textAlign: "center",
          lineHeight: "1.5",
        }}
      >
        {/* eslint-disable-next-line i18next/no-literal-string */}
        <Typography $variant="body-3-medium">Auth provider error</Typography>
        {error.message}
      </div>
    )
  }

  if (!data) {
    console.error("AuthProvider - data is empty")
    return <>{t("auth.cannotAccessToUser")}</>
  }

  const currentUser: CurrentUser = {
    id: data.user.id,
    merchantId: data.getmerchant.id,
    firstname: data.user.firstname,
    lastname: data.user.lastname,
    payOfflineAvailable: data.me.merchant.payOfflineAvailable,
    merchantTradingName: data.getmerchant.tradingName,
    merchantStatus: data.getmerchant.status,
    canShowAPPages: data.getmerchant.canShowAPCockpit,
    canShowCockpit: data.getmerchant.canShowCockpit,
    isDemoMerchant: checkIfIsDemoMerchant(data.getmerchant.id),
    intercomUserHash: data.user.intercomUserHash,
    email: data.user.email,
    phone: data.user.phone,
    onboardingFlowStatuses: data.getmerchant.onboardingFlowStatuses,
  }

  amplitude.setUserId(currentUser.id)
  amplitude.setUserProperties([
    { email: currentUser.email },
    { merchantId: currentUser.merchantId },
    { merchantTradingName: currentUser.merchantTradingName },
    { merchantStatus: currentUser.merchantStatus },
  ])

  return (
    <AuthContext.Provider value={{ currentUser, refetch }}>
      <IntercomProvider>{children}</IntercomProvider>
    </AuthContext.Provider>
  )
}
