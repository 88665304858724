import { gql } from "@apollo/client"
import { GqlHeroError } from "../../../../../01_technical/requesting/request-error.type"
import { useHeroMutation } from "../../../../../01_technical/requesting/useHeroMutation/useHeroMutation"

const SEND_PAYMENT_LINK_EMAIL = gql`
  mutation sendPaymentLinkByEmail($input: SendPaymentLinkByEmailInput!) {
    sendPaymentLinkByEmail(input: $input) {
      ... on SendPaymentLinkByEmailOutput {
        success
      }

      ... on GqlHeroError {
        errorCode
      }
    }
  }
`

type SendPaymentLinkByEmailResponse = { success: boolean } | GqlHeroError

type SendPaymentLinkByEmailInput = {
  input: {
    paymentLinkId: string
  }
}

export const useSendPaymentLinkByEmail = () => {
  return useHeroMutation<SendPaymentLinkByEmailResponse, SendPaymentLinkByEmailInput>({
    gqlQuerySchema: SEND_PAYMENT_LINK_EMAIL,
  })
}
