import { getPaginatedData } from "../../../../00_shared/utils/pagination"
import { APStatus } from "../../../../business/enums/AP.enum"
import { ListMerchantAPsSuccessResponse } from "../Payments.requests"
import { paymentsDemoData } from "./paymentsDemoData"

export const getPaymentsDemoData = (
  filters: {
    status: APStatus[]
    marketplaceNames?: string[]
    fundedAmountMin?: number
    fundedAmountMax?: number
    fundedAmountEqual?: number
    createdBefore?: Date
    createdAfter?: Date
  },
  pagination: { pageSize: number; pageNumber: number },
): ListMerchantAPsSuccessResponse => {
  const filteredAcceleratedPayouts = paymentsDemoData.acceleratedPayouts.filter((ap) => {
    const isStatusMatch = filters.status.length > 0 ? filters.status.includes(ap.status) : true
    const isMarketplaceMatch = filters.marketplaceNames ? filters.marketplaceNames.includes(ap.marketplace) : true
    const isFundedAmountMinMatch = filters.fundedAmountMin ? ap.fundedAmount >= filters.fundedAmountMin : true
    const isFundedAmountMaxMatch = filters.fundedAmountMax ? ap.fundedAmount <= filters.fundedAmountMax : true
    const isFundedAmountEqualMatch = filters.fundedAmountEqual ? ap.fundedAmount === filters.fundedAmountEqual : true
    const isCreatedBeforeMatch = filters.createdBefore ? new Date(ap.createdAt) <= filters.createdBefore : true
    const isCreatedAfterMatch = filters.createdAfter ? new Date(ap.createdAt) >= filters.createdAfter : true

    return (
      isStatusMatch &&
      isMarketplaceMatch &&
      isFundedAmountMinMatch &&
      isFundedAmountMaxMatch &&
      isFundedAmountEqualMatch &&
      isCreatedBeforeMatch &&
      isCreatedAfterMatch
    )
  })

  return {
    acceleratedPayouts: getPaginatedData(filteredAcceleratedPayouts, pagination),
    listMarketplaces: paymentsDemoData.listMarketplaces,
    totalCount: filteredAcceleratedPayouts.length,
  }
}
