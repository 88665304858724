import { Typography } from "@hero/krypton"
import { useCallback, useEffect, useMemo } from "react"
import { useLocation } from "react-router-dom"
import styled from "styled-components"
import { SelectableCard } from "../../../../../00_shared/components/SelectableCard"
import { useDashboardTranslation } from "../../../../../01_technical/translations"
import company from "../../../../../assets/clients/company.svg"
import customer from "../../../../../assets/clients/customer.svg"
import { PaymentType } from "../../../00_shared/enums/PaymentCore.enum"
import { useCreateLinkFormContext, useNavigateWithScrolling } from "../CreateLink.utils"
import { useCreateLinkContext } from "../CreateLinkContext"
import { ClientForm } from "./components/ClientForm"

const ClientContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding: 2rem;
  width: 40rem;
  margin: auto;
`

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80vh;
`

export const LinkCreateClientStep = () => {
  const { setValue, watch } = useCreateLinkFormContext()
  const { feeConfigurations } = useCreateLinkContext()
  const { t } = useDashboardTranslation()
  const { pathname } = useLocation()
  const navigate = useNavigateWithScrolling()

  const isIndividual = watch("customer.isIndividual")

  useEffect(() => {
    if (pathname !== "/collection/new-link/client") {
      navigate("/collection/new-link/client")
    }
  }, [navigate, pathname])

  const chooseClientType = useCallback(
    (type: "individual" | "company") => {
      setValue("customer.isIndividual", type === "individual")
    },
    [setValue],
  )

  const isIndividualEnabled = useMemo(() => {
    const p1xConf = feeConfigurations.find((conf) => conf.type === PaymentType.PAY_1X)

    return !!p1xConf
  }, [feeConfigurations])

  return (
    <Container>
      {isIndividual === undefined && (
        <ClientContainer>
          <Typography as="h1" $variant="title-2-bold">
            {t("bnpl.link.wizard.steps.client.chooseType")}
          </Typography>
          <SelectableCard
            onClick={() => chooseClientType("individual")}
            imageSrc={customer}
            title={t("bnpl.link.wizard.steps.client.types.individual")}
            variant={isIndividualEnabled ? "default" : "disabled"}
          />
          <SelectableCard
            onClick={() => chooseClientType("company")}
            imageSrc={company}
            title={t("bnpl.link.wizard.steps.client.types.company")}
            variant={feeConfigurations.length > 0 ? "default" : "disabled"}
          />
        </ClientContainer>
      )}

      {isIndividual !== undefined && <ClientForm isIndividual={isIndividual} />}
    </Container>
  )
}
