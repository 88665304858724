import { useMatches } from "react-router-dom"
import { BreadCrumbDefinition, RouterHandleBreadcrumbsType } from "../utils/router.breadcrumbs"

export const useBreadcrumbs = (): [BreadCrumbDefinition, BreadCrumbDefinition] => {
  const matches = useMatches()

  const breadcrumbs = matches.reduce<Omit<BreadCrumbDefinition, "active">[]>((breadcrumbs, match) => {
    const params = match.params
    const handle = match.handle as RouterHandleBreadcrumbsType

    if (handle && handle.crumb) {
      const crumb = handle.crumb(params)
      return [...breadcrumbs, crumb]
    }

    return breadcrumbs
  }, [])
  const allBreadcrumb = breadcrumbs.map((crumb, index) => ({ ...crumb, active: index === breadcrumbs.length - 1 }))

  const lastBreadcrumb = allBreadcrumb.at(-1)
  const previousBreadcrumb = allBreadcrumb.at(-2)
  if (!lastBreadcrumb || !previousBreadcrumb) {
    throw new Error("Breadcrumb is too small")
  }

  return [previousBreadcrumb, lastBreadcrumb]
}
