import { Button, Separator, Typography } from "@hero/krypton"
import { useCallback, useState } from "react"
import { useNavigate, useNavigationType, useParams } from "react-router-dom"
import styled from "styled-components"
import CenteredLoading from "../../../00_shared/components/CenteredLoading"
import { FlexContainer, FlexItem } from "../../../00_shared/components/Flex"
import { ScrollArea } from "../../../00_shared/components/ScrollArea"
import { Sheet, SheetContent, SheetHeader, SheetTitle } from "../../../00_shared/components/Sheet"
import { useAmplitude } from "../../../00_shared/hooks/useAmplitude.hook"
import { useDashboardTranslation } from "../../../01_technical/translations"
import { TransactionDetails } from "./components/IssuingCardTransactionInformations/TransactionDetails"
import { TransactionSteps } from "./components/IssuingCardTransactionInformations/TransactionSteps"
import { GetHelpForTransaction } from "./GetHelpForTransaction"
import { GetHelpModal } from "./GetHelpModal"
import { useIssuingCardTransaction } from "./hooks/useIssuingCardTransaction.hook"

const ScrollAreaContainer = styled(ScrollArea)`
  height: calc(100% - 4.5rem);
  padding-right: 0.5rem;
`

const Amount = styled(Typography).attrs(() => ({
  $variant: "title-3-semibold",
}))`
  color: ${({ theme }) => theme.colors.grey.$600};
`

const Date = styled(Typography).attrs(() => ({
  $variant: "caption-2",
}))`
  color: ${({ theme }) => theme.colors.grey.$500};
`

export const IssuingCardDetailsTransactionInformations = () => {
  const { t } = useDashboardTranslation()
  const [isOpen, setIsOpen] = useState(true)
  const navigate = useNavigate()
  const navigationType = useNavigationType()
  const { cardId, transactionId } = useParams<{ cardId: string; transactionId: string }>()
  const { track } = useAmplitude()
  const [helpModalOpen, setHelpModalOpen] = useState(false)

  const closeHelpModal = () => setHelpModalOpen(false)

  const navigateBack = useCallback(() => {
    if (!isOpen) {
      if (navigationType === "PUSH") {
        navigate(-1)
      } else {
        navigate("..")
      }
    }
  }, [isOpen, navigationType, navigate])

  const { card, transaction, loadingTransaction, loadingCard } = useIssuingCardTransaction(cardId, transactionId)
  const loading = loadingTransaction || loadingCard

  if (!cardId || !transactionId) {
    navigateBack()
    return
  }

  const handleAnimationEnd = () => {
    navigateBack()
  }

  const handleOpenChange = (open: boolean) => {
    if (!open) {
      track("card_previous_from_transaction_clicked")
    }
    setIsOpen(open)
  }

  return (
    <Sheet open={isOpen} onOpenChange={handleOpenChange}>
      <SheetContent side="right" onAnimationEndCapture={handleAnimationEnd}>
        {transaction && (
          <GetHelpModal isOpen={helpModalOpen} onClose={closeHelpModal}>
            <GetHelpForTransaction
              transactionId={transaction.id}
              label={transaction.label}
              date={transaction.createdAtFormatted}
              amount={transaction.amountEuros}
              onDone={() => setTimeout(() => setHelpModalOpen(false), 50)}
            />
          </GetHelpModal>
        )}
        <SheetHeader>
          <SheetTitle>{t("issuing.operation.details.title")}</SheetTitle>
        </SheetHeader>
        <ScrollAreaContainer height="calc(100vh - 5rem)" width="100%">
          {(!card || !transaction || loading) && <CenteredLoading />}
          {card && transaction && !loading && (
            <FlexContainer $direction="column" $gap="1.2rem">
              <FlexItem>
                <FlexContainer $justify="space-between" $align="center">
                  <Typography $variant="title-3-semibold">{transaction.label}</Typography>
                  <Amount>{transaction.amountEuros}</Amount>
                </FlexContainer>
                <Date>{transaction.createdAtFormatted}</Date>
              </FlexItem>
              <Separator />
              <FlexItem>
                <TransactionSteps
                  statuses={[
                    { label: t("issuing.operation.details.debitDate"), value: transaction.createdAtFormatted },
                    { label: t("issuing.operation.details.waitingForClearing"), value: undefined },
                  ]}
                />
              </FlexItem>
              <FlexItem>
                <TransactionDetails
                  items={[
                    {
                      label: t("issuing.operation.details.date"),
                      value: transaction.createdAtFormatted,
                    },
                    {
                      label: t("issuing.operation.details.paymentAccount"),
                      value: card.paymentAccountName,
                    },
                    {
                      label: t("issuing.operation.details.method"),
                      value: t("issuing.operation.details.byCard"),
                    },
                    {
                      label: t("issuing.operation.details.card"),
                      value: card.displayLastCardDigits,
                    },
                    {
                      label: t("issuing.operation.details.cardholder"),
                      value: card.cardholderName,
                    },
                  ]}
                />
              </FlexItem>
            </FlexContainer>
          )}
          {transaction && (
            <div style={{ position: "absolute", bottom: 0, width: "100%" }}>
              <Separator />
              <FlexItem style={{ textAlign: "center", paddingTop: ".75rem" }}>
                <Button $variant="underline" onClick={() => setHelpModalOpen(true)}>
                  {t("issuing.operation.details.getHelp")}
                </Button>
              </FlexItem>
            </div>
          )}
        </ScrollAreaContainer>
      </SheetContent>
    </Sheet>
  )
}
