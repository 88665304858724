import { Typography } from "@hero/krypton"
import React from "react"
import styled from "styled-components"
import { Card } from "../../../../00_shared/components/Card"

interface RadioCardProps {
  label: string
  description?: string
  checked: boolean
  onChange: () => void
}

const RadioCardWrapper = styled(Card)<{ checked: boolean }>`
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 1rem;
  border: 1px solid ${({ theme, checked }) => (checked ? theme.colors.primary.$300 : theme.colors.grey.$200)};
  border-radius: 0.5rem;
  cursor: pointer;
  transition: border 0.3s;
  background-color: ${({ checked, theme }) => (checked ? theme.colors.grey.$100 : "transparent")};

  &:hover {
    border-color: ${({ theme }) => theme.colors.primary.$300};
  }
`

const RadioInput = styled.input`
  appearance: none;
  display: inline-block;
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  border: 1px solid ${({ theme }) => theme.colors.grey.$400};
  cursor: pointer;
  position: relative;

  &:checked::before {
    content: "";
    display: inline-block;
    width: 0.5rem;
    height: 0.5rem;
    background-color: ${({ theme }) => theme.colors.black.$700};
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`

const CardDetails = styled.div`
  display: flex;
  flex-direction: column;
`

export const RadioCard: React.FC<RadioCardProps> = ({ label, description, checked, onChange }) => {
  return (
    <RadioCardWrapper checked={checked} onClick={onChange}>
      <RadioInput type="radio" checked={checked} onChange={onChange} />
      <CardDetails>
        <Typography $variant="body-4-medium" style={{ visibility: `${label ? "visible" : "hidden"}` }}>
          {label || "No card holder selected"}
        </Typography>
        {description && (
          <Typography $variant="caption-2" $color="grey.$500">
            {description}
          </Typography>
        )}
      </CardDetails>
    </RadioCardWrapper>
  )
}
