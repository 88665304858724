import { Outlet } from "react-router-dom"
import { TransfersLayout } from "./TransfersLayout"

export const TransfersRoot = () => {
  return (
    <TransfersLayout>
      <Outlet />
    </TransfersLayout>
  )
}
