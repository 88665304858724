import { EditIcon, Typography } from "@hero/krypton"
import styled from "styled-components"
import { FlexContainer } from "../../../../00_shared/components/Flex"
import { toEuros } from "../../../../00_shared/utils/currency.converter"
import { useDashboardTranslation } from "../../../../01_technical/translations"
import { IssuingCard } from "../business/IssuingCard"
import { IssuingCardEditMonthlySpendingLimitModal } from "./IssuingCardEditMonthlySpendingLimitModal"
import { useState } from "react"
import { useIssuingCardDetailsHook } from "../../issuingCardDetails/IssuingCardDetails.hook"
import { track } from "@amplitude/analytics-browser"

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  height: fit-content;
  gap: 12px;
  width: 100%;
`

const ProgressSection = styled.div`
  position: relative;
  width: 100%;
`

const Progress = styled.div`
  display: flex;
  flex-direction: column;
  align-items: end;
  width: 100%;
  gap: 4px;
`

const ProgressLine = styled.div`
  width: 100%;
  height: 4px;
  border-radius: 4px;
  background-color: #e9eaf0;
  display: flex;
  align-items: center;
`

const ProgressLineProgression = styled.div<{ consumed: number; total: number }>`
  width: ${(props) => `${(props.consumed / props.total) * 100}%`};
  height: 4px;
  border-radius: 4px;
  background-color: #1d1e21;
`

const Balance = styled(Typography)`
  position: absolute;
  top: -0.7rem;
`

const HeaderBlock = styled.div`
  display: flex;
  width: 100%;
  flex-grow: 1;
  flex-direction: row;
  justify-content: space-between;
`

interface SpendingLimitProps {
  card: IssuingCard
}

const SpendingLimit = ({ card }: SpendingLimitProps) => {
  const { t } = useDashboardTranslation()

  const { refetch } = useIssuingCardDetailsHook(card.id)

  const [editSpendingLimit, setEditSpendingLimit] = useState(false)

  const refreshCard = () => {
    setEditSpendingLimit(false)
    refetch()
  }

  const openSetSpendingLimitModal = () => {
    track("card_set_monthly_limit_clicked")
    setEditSpendingLimit(true)
  }

  return (
    <Content>
      <HeaderBlock>
        <Typography $variant="label-1" $color="grey.$500">
          {(t("issuing.card.cardType.debit") as string).toUpperCase()}
        </Typography>
        <EditIcon onClick={openSetSpendingLimitModal} />
      </HeaderBlock>
      <ProgressSection>
        <Balance $variant="title-3-semibold" $color="grey.$600">
          {toEuros(card.consumedMonthlySpendingLimitAmount)}
        </Balance>

        <Progress>
          <FlexContainer $gap=".3rem">
            <Typography $variant="caption-2" $color="grey.$500">
              {t("issuing.card.limits.spendingLimits.available")}
            </Typography>
            <Typography $variant="caption-2" $color="grey.$500">
              {toEuros(Math.max(0, card.monthlySpendingLimitAmount - card.consumedMonthlySpendingLimitAmount))}
            </Typography>
          </FlexContainer>
          <ProgressLine>
            <ProgressLineProgression
              consumed={card.consumedMonthlySpendingLimitAmount}
              total={card.monthlySpendingLimitAmount}
            />
          </ProgressLine>
          <FlexContainer $gap=".3rem">
            <Typography $variant="caption-2" $color="grey.$500">
              {t("issuing.card.limits.spendingLimits.maxLimit")}
            </Typography>
            <Typography $variant="caption-2" $color="grey.$500">
              {toEuros(card.monthlySpendingLimitAmount)}
            </Typography>
          </FlexContainer>
        </Progress>
      </ProgressSection>
      {editSpendingLimit && <IssuingCardEditMonthlySpendingLimitModal card={card} onClose={refreshCard} />}
    </Content>
  )
}

export default SpendingLimit
