import { MerchantMenuEntry } from "../../merchant.menu.type"

export const ProAccountMenuEntry: MerchantMenuEntry = {
  to: "/accounts",
  key: "menu.list.proAccount",
  visible: true,
  selected: (location) => location.pathname.startsWith("/accounts"),
  "data-test-id": "menu-entry-pro-account",
}

export const TransfersMenuEntry: MerchantMenuEntry = {
  to: "/transfers",
  key: "menu.list.transfers",
  visible: true,
  selected: (location) => location.pathname.startsWith("/transfers"),
  "data-test-id": "menu-entry-transfers",
}
