import { Button, Checkbox, Chip, Field, FieldSelect, FloatingBox, Typography } from "@hero/krypton"
import { useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import styled from "styled-components"
import { centsToEuros, eurosToCents, toEuros } from "../../../../00_shared/utils/currency.converter"
import { titleCase } from "../../../../00_shared/utils/wording.converter"
import { useDashboardTranslation } from "../../../../01_technical/translations"
import { SearchParams, statusParam } from "./FilledPayments"

const FieldContainer = styled.div`
  padding: 1rem 3rem;
  display: flex;
  background-color: ${({ theme }) => theme.colors.white};
`

const StyledButton = styled(Button)`
  width: 100%;
  min-width: 22rem;
`
const ChipContainer = styled.span`
  margin-right: 0.5rem;
`

type MarketplaceFormData = {
  marketplaces: string[]
}

type AmountFormData = {
  amountFilter: "under" | "over" | "equal"
  amount?: number
}

type DateFormData = {
  startDate?: string
  endDate?: string
}

const getAmountFilterType = (amountUnder?: number, amountOver?: number, amountEqual?: number) => {
  return amountUnder ? "under" : amountOver ? "over" : amountEqual ? "equal" : "under"
}

const getAmountToEuros = (amount?: number) => {
  return amount ? centsToEuros(amount) : undefined
}

export const Filters = ({
  listMarketplaces,
  setSearchParam,
  pageNumber,
  status,
  marketplaces,
  amountUnder,
  amountOver,
  amountEqual,
  startDate,
  endDate,
}: {
  listMarketplaces: string[]
  setSearchParam: (filters: Partial<SearchParams>) => void
  pageNumber: number
  status: statusParam
  marketplaces?: string[]
  amountUnder?: number
  amountOver?: number
  amountEqual?: number
  startDate?: string
  endDate?: string
}) => {
  const marketplaceChipValue =
    !marketplaces || !marketplaces.length
      ? ""
      : marketplaces.length === 1
        ? titleCase(marketplaces[0])
        : `${titleCase(marketplaces[0])}, +${marketplaces.length - 1}`

  const amountChipValue = amountUnder
    ? `<= ${toEuros(amountUnder)}`
    : amountOver
      ? `=> ${toEuros(amountOver)}`
      : amountEqual
        ? `= ${toEuros(amountEqual)}`
        : ""

  const getDateChipValue = () => {
    if (startDate && !endDate) return `> ${startDate}`
    else if (!startDate && endDate) return `< ${endDate}`
    else if (startDate && endDate) return `> ${startDate} et < ${endDate}`
    return ""
  }

  const { t } = useDashboardTranslation()

  const [anchorMarketplaceEl, setAnchorMarketplaceEl] = useState<HTMLElement | undefined>(undefined)
  const [anchorAmountEl, setAnchorAmountEl] = useState<HTMLElement | undefined>(undefined)
  const [anchorDateEl, setAnchorDateEl] = useState<HTMLElement | undefined>(undefined)

  const handleClick = (event: React.MouseEvent<HTMLElement>, filterType: "marketplace" | "amount" | "date") => {
    if (filterType === "marketplace") {
      setAnchorAmountEl(undefined)
      setAnchorDateEl(undefined)
      setAnchorMarketplaceEl(anchorMarketplaceEl ? undefined : event.currentTarget)
    }
    if (filterType === "amount") {
      setAnchorMarketplaceEl(undefined)
      setAnchorDateEl(undefined)
      setAnchorAmountEl(anchorAmountEl ? undefined : event.currentTarget)
    }
    if (filterType === "date") {
      setAnchorMarketplaceEl(undefined)
      setAnchorAmountEl(undefined)
      setAnchorDateEl(anchorDateEl ? undefined : event.currentTarget)
    }
  }
  const isMarketplaceOpen = Boolean(anchorMarketplaceEl)
  const isAmountOpen = Boolean(anchorAmountEl)
  const isDateOpen = Boolean(anchorDateEl)

  const { handleSubmit, register, setValue } = useForm<MarketplaceFormData>({
    defaultValues: {
      marketplaces: [],
    },
  })

  const {
    handleSubmit: handleAmountFormSubmit,
    register: registerAmountForm,
    setValue: setAmountValue,
  } = useForm<AmountFormData>({
    defaultValues: {
      amountFilter: getAmountFilterType(amountUnder, amountOver, amountEqual),
      amount: getAmountToEuros(amountUnder ?? amountOver ?? amountEqual),
    },
  })

  const {
    handleSubmit: handleDateFormSubmit,
    register: registerDateForm,
    setValue: setDateValue,
  } = useForm<DateFormData>({
    defaultValues: {
      startDate,
      endDate,
    },
  })

  useEffect(() => {
    setValue("marketplaces", marketplaces ?? [])
    setAmountValue("amount", getAmountToEuros(amountUnder ?? amountOver ?? amountEqual))
    setAmountValue("amountFilter", getAmountFilterType(amountUnder, amountOver, amountEqual))
    setDateValue("startDate", startDate)
    setDateValue("endDate", endDate)
  }, [marketplaces, setValue, setAmountValue, setDateValue, amountUnder, amountOver, amountEqual, startDate, endDate])

  const submit = async ({ marketplaces }: MarketplaceFormData) => {
    setSearchParam({ status, marketplaces, page: pageNumber, amountUnder, amountOver, amountEqual, startDate, endDate })
    setAnchorMarketplaceEl(undefined)
  }

  const submitAmount = async ({ amountFilter, amount }: AmountFormData) => {
    setSearchParam({
      status,
      marketplaces,
      page: pageNumber,
      amountUnder: amount && amountFilter === "under" ? eurosToCents(amount) : undefined,
      amountOver: amount && amountFilter === "over" ? eurosToCents(amount) : undefined,
      amountEqual: amount && amountFilter === "equal" ? eurosToCents(amount) : undefined,
      startDate,
      endDate,
    })
    setAnchorAmountEl(undefined)
  }

  const submitDate = async ({ startDate, endDate }: DateFormData) => {
    setSearchParam({
      status,
      marketplaces,
      page: pageNumber,
      amountUnder,
      amountOver,
      amountEqual,
      startDate: startDate ? startDate : undefined,
      endDate: endDate ? endDate : undefined,
    })
    setAnchorDateEl(undefined)
  }
  return (
    <FieldContainer>
      {/* Marketplace filter */}
      <ChipContainer>
        <Chip
          label={t("ap.payments.filters.chip.marketplace")}
          onClear={() => {
            submit({ marketplaces: [] })
          }}
          onClick={(e: React.MouseEvent<HTMLElement>) => handleClick(e, "marketplace")}
          value={marketplaceChipValue}
        />
      </ChipContainer>
      <FloatingBox
        anchorElement={anchorMarketplaceEl}
        onClose={() => setAnchorMarketplaceEl(undefined)}
        isOpen={isMarketplaceOpen}
      >
        <form method="dialog" onSubmit={handleSubmit(submit)}>
          <Typography as="h3" $variant="body-4-semibold">
            {t("ap.payments.filters.marketplaceLabel")}
          </Typography>
          {listMarketplaces.map((marketplace) => (
            <Checkbox
              {...register("marketplaces")}
              key={marketplace}
              id={marketplace}
              text={titleCase(marketplace)}
              value={marketplace}
            />
          ))}
          <StyledButton>{t("ap.payments.filters.apply")}</StyledButton>
        </form>
      </FloatingBox>

      {/* Amount filter */}
      <ChipContainer>
        <Chip
          label={t("ap.payments.filters.chip.amount")}
          onClick={(e: React.MouseEvent<HTMLElement>) => handleClick(e, "amount")}
          onClear={() => {
            submitAmount({ amountFilter: "equal" })
          }}
          value={amountChipValue}
        />
      </ChipContainer>
      <FloatingBox anchorElement={anchorAmountEl} onClose={() => setAnchorAmountEl(undefined)} isOpen={isAmountOpen}>
        <form method="dialog" onSubmit={handleAmountFormSubmit(submitAmount)}>
          <Typography as="h3" $variant="body-4-semibold">
            {t("ap.payments.filters.amountLabel")}
          </Typography>
          <FieldSelect {...registerAmountForm("amountFilter")}>
            <option value="under">{t("ap.payments.filters.amount.lessThan")}</option>
            <option value="equal">{t("ap.payments.filters.amount.equal")}</option>
            <option value="over">{t("ap.payments.filters.amount.greaterThan")}</option>
          </FieldSelect>
          <Field step="0.01" type="number" {...registerAmountForm("amount")} aria-invalid placeholder="10" />
          <StyledButton>{t("ap.payments.filters.apply")}</StyledButton>
        </form>
      </FloatingBox>

      {/* Date filter */}
      <ChipContainer>
        <Chip
          label={t("ap.payments.filters.chip.date")}
          onClick={(e: React.MouseEvent<HTMLElement>) => handleClick(e, "date")}
          onClear={() => {
            submitDate({})
          }}
          value={getDateChipValue()}
        />
      </ChipContainer>
      <FloatingBox anchorElement={anchorDateEl} onClose={() => setAnchorDateEl(undefined)} isOpen={isDateOpen}>
        <form method="dialog" onSubmit={handleDateFormSubmit(submitDate)}>
          <Typography as="h3" $variant="body-4-semibold">
            {t("ap.payments.filters.dateLabel")}
          </Typography>
          <Field fieldLabel={t("ap.payments.filters.date.startDate")} {...registerDateForm("startDate")} type="date" />
          <Field fieldLabel={t("ap.payments.filters.date.endDate")} {...registerDateForm("endDate")} type="date" />
          <StyledButton>{t("ap.payments.filters.apply")}</StyledButton>
        </form>
      </FloatingBox>
    </FieldContainer>
  )
}
