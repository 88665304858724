import { useMutation } from "@apollo/client"
import { Button, Field, Typography, toaster } from "@hero/krypton"
import { useEffect } from "react"
import { useForm } from "react-hook-form"
import { Navigate, useNavigate, useSearchParams } from "react-router-dom"
import styled from "styled-components"
import { useCommonTranslation } from "../../01_technical/translations"
import { ApiErrors } from "../../Legacy/components/ApiErrors"
import {
  ONBOARD_USER_MERCHANT_MUTATION,
  OnboardUserMerchantArgs,
  OnboardUserMerchantResponse,
} from "./onboardMerchantUser.requests"

const Container = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 26rem;
`

const LoginBox = styled.form`
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  display: flex;
`

type OnboardMerchantUserForm = {
  password: string
  confirmPassword: string
  pin: string
}

export const OnboardMerchantUserScreen = () => {
  const [query] = useSearchParams()
  const navigate = useNavigate()
  const token = query.get("token")

  const [onboardMerchantUser, { loading, error }] = useMutation<OnboardUserMerchantResponse, OnboardUserMerchantArgs>(
    ONBOARD_USER_MERCHANT_MUTATION,
  )

  const {
    watch,
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<OnboardMerchantUserForm>({})

  const { t } = useCommonTranslation()

  const authToken = localStorage.getItem("token")
  useEffect(() => {
    if (!token) {
      toaster.error(t("auth.createPassword.invalidLink"))
    }
    if (authToken) {
      toaster.error(t("auth.createPassword.alreadyConnected"))
    }
  }, [token, t, authToken])

  if (!token || authToken) {
    return <Navigate to="/" />
  }

  const onSubmit = handleSubmit((data) => {
    onboardMerchantUser({ variables: { password: data.password, invitationToken: token, pin: data.pin } })
      .then(() => {
        toaster.success(t("auth.createPassword.success"))
        navigate("/")
      })
      .catch((e) => console.error(e))
  })

  const passwordValue = watch("password")
  return (
    <Container>
      <LoginBox noValidate onSubmit={onSubmit}>
        <Typography $variant="title-2-bold" as="h1">
          {t("auth.createPassword.title")}
        </Typography>

        <Field
          type="password"
          fieldLabel={t("auth.createPassword.passwordField")}
          placeholder={t("auth.createPassword.passwordFieldPlaceholder")}
          // eslint-disable-next-line i18next/no-literal-string
          {...register("password", { required: t("requiredField") })}
          aria-invalid={!!errors.password}
          errorMessage={errors.password?.message}
        />

        <Field
          type="password"
          fieldLabel={t("auth.createPassword.confirmPasswordField")}
          placeholder={t("auth.createPassword.confirmPasswordPlaceholder")}
          // eslint-disable-next-line i18next/no-literal-string
          {...register("confirmPassword", {
            required: t("requiredField"),
            // eslint-disable-next-line i18next/no-literal-string
            deps: ["password"],
            validate: {
              samePassword: (confirmPasswordValue) => {
                return passwordValue !== confirmPasswordValue ? t("auth.createPassword.notSamePassword") : undefined
              },
            },
          })}
          errorMessage={errors.confirmPassword?.message}
        />

        <Field
          type="password"
          fieldLabel={t("auth.createPassword.pinField")}
          placeholder={t("auth.createPassword.pinFieldPlaceholder")}
          {...register("pin", { required: t("requiredField") })}
          aria-invalid={!!errors.pin}
          errorMessage={errors.pin?.message}
          pattern="[0-9]*"
          inputMode="numeric"
        />

        {error && (
          <ApiErrors
            err={error}
            strategies={{
              EXPIRED_TOKEN: () => <>{t("auth.createPassword.expiredToken")}</>,
              INVALID_TOKEN: () => {
                useEffect(() => {
                  toaster.error(t("auth.createPassword.invalidLink"))
                  navigate("/")
                }, [])
                return <></>
              },
              INVALID_IDENTITY: () => {
                useEffect(() => {
                  toaster.error(t("auth.createPassword.invalidLink"))
                  navigate("/")
                }, [])
                return <></>
              },
              PASSWORD_TOO_SHORT: () => <>{t("auth.error.passwordTooShort")}</>,
              PASSWORD_EMPTY: () => <>{t("auth.error.passwordEmpty")}</>,
              PASSWORD_MISSING_UPPERCASE: () => <>{t("auth.error.passwordMissingUppercase")}</>,
              PASSWORD_MISSING_LOWERCASE: () => <>{t("auth.error.passwordMissingLowercase")}</>,
              PASSWORD_MISSING_DIGIT: () => <>{t("auth.error.passwordMissingDigit")}</>,
              PASSWORD_MISSING_SPECIAL_CHAR: () => <>{t("auth.error.passwordMissingSpecialChar")}</>,
              PASSWORD_INVALID_CHARACTERS: () => <>{t("auth.error.passwordInvalidCharacters")}</>,

              PIN_EMPTY: () => <>{t("auth.error.pinEmpty")}</>,
              PIN_WEAK: () => <>{t("auth.error.pinWeak")}</>,
              PIN_WRONG_FORMAT: () => <>{t("auth.error.pinWrongFormat")}</>,
            }}
          />
        )}

        <Button $fullWidth isLoading={isSubmitting} type="submit" size="medium" disabled={loading}>
          {t("auth.createPassword.submit")}
        </Button>
      </LoginBox>
    </Container>
  )
}
