export enum TransactionStatus {
  LATE = "LATE",
  NOT_PAID = "NOT_PAID",
  REFUNDED = "REFUNDED",
  PARTIALLY_REFUNDED = "PARTIALLY_REFUNDED",
  REFUSED = "REFUSED",
  PAID = "PAID",
  WAITING_FOR_VALIDATION = "WAITING_FOR_VALIDATION",
  WAITING_FOR_CUSTOMER_CONFIRMATION = "WAITING_FOR_CUSTOMER_CONFIRMATION",
  BLOCKED_MISSING_CUSTOMER_DOCS = "BLOCKED_MISSING_CUSTOMER_DOCS",
}

export enum TransactionFlowType {
  ONLINE = "ONLINE",
  OFFLINE = "OFFLINE",
  RECEIVABLE = "RECEIVABLE",
}

/**
 * @public
 */
export enum TransactionType {
  PAYMENT = "PAYMENT",
  PAYMENT_GENERATOR = "PAYMENT_GENERATOR",
  RECEIVABLE_GENERATOR = "RECEIVABLE_GENERATOR",
}
