import { RouteObject } from "react-router-dom"
import All2faProviders from "../00_shared/providers/Providers2fa"
import { Connect } from "../01_technical/client"
import { AuthLayout } from "./auth.layout"
import { OnboardMerchantUserScreen } from "./CreatePassword/onboardMerchantUser.screen"
import { ForgotPassword } from "./ForgotPassword/ForgotPassword"
import { Login } from "./Login/Login.screen"
import { Autologin } from "./LoginAs/Autologin"
import { LoginAs } from "./LoginAs/LoginAs"
import { Logout } from "./Logout/Logout"
import { ResetPassword } from "./ResetPassword/ResetPassword"
import { SignupScreen } from "./Signup/Signup.screen"

export const authRoutes: RouteObject[] = [
  {
    path: "login_as",
    element: (
      <Connect>
        <AuthLayout>
          <All2faProviders>
            <LoginAs />
          </All2faProviders>
        </AuthLayout>
      </Connect>
    ),
  },
  {
    path: "autologin",
    element: (
      <Connect>
        <AuthLayout>
          <All2faProviders>
            <Autologin />
          </All2faProviders>
        </AuthLayout>
      </Connect>
    ),
  },
  {
    path: "signup",
    element: (
      <Connect>
        <AuthLayout>
          <All2faProviders>
            <SignupScreen />
          </All2faProviders>
        </AuthLayout>
      </Connect>
    ),
  },
  {
    path: "login",
    element: (
      <Connect>
        <AuthLayout>
          <All2faProviders>
            <Login />
          </All2faProviders>
        </AuthLayout>
      </Connect>
    ),
  },
  {
    path: "forgot_password",
    element: (
      <Connect>
        <AuthLayout>
          <All2faProviders>
            <ForgotPassword />
          </All2faProviders>
        </AuthLayout>
      </Connect>
    ),
  },
  {
    path: "reset_password",
    element: (
      <Connect>
        <AuthLayout>
          <All2faProviders>
            <ResetPassword />
          </All2faProviders>
        </AuthLayout>
      </Connect>
    ),
  },
  {
    path: "create_password",
    element: (
      <Connect>
        <AuthLayout>
          <All2faProviders>
            <OnboardMerchantUserScreen />
          </All2faProviders>
        </AuthLayout>
      </Connect>
    ),
  },
  {
    path: "logout",
    element: (
      <Connect>
        <Logout />
      </Connect>
    ),
  },
]
