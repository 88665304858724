import { useQuery } from "@apollo/client"
import { Separator, Spinner, toaster } from "@hero/krypton"
import { useEffect } from "react"
import styled from "styled-components"
import { unwrapGraphQLResponse } from "../../../../01_technical/requesting/DEPRECATED_graphql.errors"
import { useDashboardTranslation } from "../../../../01_technical/translations"
import { useAuthContext } from "../../../../Auth/auth.context"
import { AccountingSpreadsheetExport } from "./components/AccountingSpreadsheetExport"
import { AccountingSummaryExport } from "./components/AccountingSummaryExport"
import { GET_MERCHANT_ACCOUNTS_QUERY, GetMerchantAccountsResponse } from "./export.requests"

const ExportsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
`

const Divider = styled(Separator)`
  height: 1px;
`

export const ExportScreen: React.FC = () => {
  const { data, loading } = useQuery<GetMerchantAccountsResponse>(GET_MERCHANT_ACCOUNTS_QUERY)
  const { t } = useDashboardTranslation()
  const { currentUser } = useAuthContext()

  const { error: graphQLError, data: merchantGetAccounts } = unwrapGraphQLResponse(data?.merchantGetAccounts)

  useEffect(() => {
    if (graphQLError) {
      toaster.error(t("accounting.export.summary.toasterError"))
    }
  }, [t, graphQLError])

  if (loading || !merchantGetAccounts) {
    return <Spinner />
  }

  const { accounts, hasBNPLMovements, hasAPMovements } = merchantGetAccounts

  return (
    <ExportsContainer>
      <AccountingSummaryExport merchantAccounts={accounts} />

      {(currentUser.isDemoMerchant || hasBNPLMovements || hasAPMovements) && (
        <>
          <Divider />
          <AccountingSpreadsheetExport hasAPMovements={hasAPMovements} hasBNPLMovements={hasBNPLMovements} />
        </>
      )}
    </ExportsContainer>
  )
}
