import { DocumentNode, MutationFunctionOptions, useMutation } from "@apollo/client"
import { ApiError } from "../request-error.type"
import { useAddTranslatedMessageToHeroApiError } from "../request-error.utils"
import { getSimplifiedMutationFunctionResult } from "./getSimplifiedMutationFunctionResult"
import { getSimplifiedMutationResult } from "./getSimplifiedMutationResult"
import { UseHeroMutationReturnType } from "./useHeroMutation.type"

// ! In case of edit, make sure to update the copy in the admin app as well !

/**
 * @param gqlQuerySchema The GraphQL query schema
 * @param variables The variables of the query
 * @param translations An object with error codes as keys and translations as values
 */
export function useHeroMutation<SuccessData, Args>({
  gqlQuerySchema,
  translations,
}: {
  gqlQuerySchema: DocumentNode
  variables?: Args
  translations?: Record<string, string>
}): UseHeroMutationReturnType<SuccessData, Args> {
  const addTranslatedMessageToHeroQueryError = useAddTranslatedMessageToHeroApiError()
  const [mutation, mutationResult] = useMutation<Record<string, SuccessData | ApiError>, Args>(gqlQuerySchema)

  const simplifiedMutationFn = async (
    options: MutationFunctionOptions<Record<string, SuccessData | ApiError>, Args>,
  ): Promise<SuccessData> => {
    const result = await mutation(options)

    const simplifiedResult = getSimplifiedMutationFunctionResult<SuccessData>({
      data: result.data,
      error: result.errors?.[0] || undefined,
    })

    if (simplifiedResult.error) {
      throw addTranslatedMessageToHeroQueryError(simplifiedResult.error, translations)
    }

    return simplifiedResult.data
  }

  const simplifiedMutationResult = getSimplifiedMutationResult<SuccessData>({
    data: mutationResult.data,
    loading: mutationResult.loading,
    error: mutationResult.error,
    called: mutationResult.called,
  })

  if (simplifiedMutationResult.error) {
    return [
      simplifiedMutationFn,
      {
        ...simplifiedMutationResult,
        error: addTranslatedMessageToHeroQueryError(simplifiedMutationResult.error, translations),
      },
    ]
  }

  return [simplifiedMutationFn, simplifiedMutationResult]
}
