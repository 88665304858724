import { add, Identify, identify, init, reset, setUserId, track } from "@amplitude/analytics-browser"
import { sessionReplayPlugin } from "@amplitude/plugin-session-replay-browser"
import { useEffect, useRef } from "react"
import { AMPLITUDE_API_KEY } from "../../env_variables"

interface UserProperty {
  [key: string]: string
}

export const useAmplitude = () => {
  const isInitialized = useRef(false)

  useEffect(() => {
    if (AMPLITUDE_API_KEY && !isInitialized.current) {
      const sessionReplayTracking = sessionReplayPlugin()
      init(AMPLITUDE_API_KEY, {
        autocapture: false,
      })
      add(sessionReplayTracking)

      isInitialized.current = true
    }
  }, [])

  const setUserProperties = (userProperties: UserProperty[]) => {
    const identifyEvent = new Identify()
    for (const key in userProperties) {
      identifyEvent.set(key, userProperties[key])
    }
    identify(identifyEvent)
  }

  const trackFn = AMPLITUDE_API_KEY ? track : () => undefined
  const resetFn = AMPLITUDE_API_KEY ? reset : () => undefined
  const setUserIdFn = AMPLITUDE_API_KEY ? setUserId : () => undefined
  const setUserPropertiesFn = AMPLITUDE_API_KEY ? setUserProperties : () => undefined

  return {
    track: trackFn,
    reset: resetFn,
    setUserId: setUserIdFn,
    setUserProperties: setUserPropertiesFn,
  }
}
