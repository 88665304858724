import { gql, useLazyQuery } from "@apollo/client"
import { GqlHeroError } from "../../../../01_technical/requesting/request-error.type"
import { useHeroMutation } from "../../../../01_technical/requesting/useHeroMutation/useHeroMutation"
import { useHeroQuery } from "../../../../01_technical/requesting/useHeroQuery/useHeroQuery"
import { PaymentType } from "../../00_shared/enums/PaymentCore.enum"
import { FeeConfiguration } from "./CreateLink.business"

const GET_PAYMENT_LINK_FEE_CONFIGURATIONS = gql`
  query getPaymentLinkFeeConfigurations {
    getPaymentLinkFeeConfigurations {
      ... on GetPaymentLinkFeeConfigurationsOutput {
        feeConfigurations {
          id
          type
          installmentCount
          daysBeforeDueDate
        }
      }

      ... on ValidationErrors {
        validationErrors {
          path
          validationError
        }
      }

      ... on GqlHeroError {
        errorCode
      }
    }
  }
`

type GetPaymentLinkFeeConfigurationsResponse = {
  feeConfigurations: FeeConfiguration[]
}

const typesPriority: { [key: string]: number } = {
  [PaymentType.PAY_1X]: 1,
  [PaymentType.PAY_NX]: 2,
  [PaymentType.PAY_ND]: 3,
}

const sortFeeConfigurations = (feeConfigurations: GetPaymentLinkFeeConfigurationsResponse["feeConfigurations"]) => {
  return [...feeConfigurations].sort((a, b) => {
    // primary sort on type
    const typeA = typesPriority[a.type]
    const typeB = typesPriority[b.type]
    if (typeA !== typeB) {
      return typeA - typeB
    }

    // secondary sort on installmentCount
    if (a.installmentCount !== b.installmentCount) {
      return a.installmentCount - b.installmentCount
    }

    // tertiary sort on daysBeforeDueDate
    return a.daysBeforeDueDate - b.daysBeforeDueDate
  })
}

export function useGetPaymentLinkFeeConfigurations() {
  const { data, loading, error } = useHeroQuery<GetPaymentLinkFeeConfigurationsResponse>({
    gqlQuerySchema: GET_PAYMENT_LINK_FEE_CONFIGURATIONS,
  })

  return {
    feeConfigurations: data?.feeConfigurations ? sortFeeConfigurations(data.feeConfigurations) : [],
    loading,
    error,
  }
}

const GET_PAYMENT_LINK = gql`
  query getPaymentLink($paymentLinkId: String!) {
    getPaymentLink(paymentLinkId: $paymentLinkId) {
      ... on GetPaymentLinkPaymentOutput {
        id
        isDraft
        createdAt
        documentPath
        customer {
          id
          name
          email
          isIndividual
        }
      }

      ... on ValidationErrors {
        validationErrors {
          path
          validationError
        }
      }

      ... on GqlHeroError {
        errorCode
      }
    }
  }
`

type GetPaymentLinkResponse = {
  getPaymentLink:
    | {
        id: string
        isDraft: boolean
        documentPath: string | null
        customer: {
          id: string
          name: string
          email: string
          isIndividual: boolean
        }
        createdAt: Date
      }
    | GqlHeroError
}

export const useGetPaymentLink = () => {
  return useLazyQuery<GetPaymentLinkResponse>(GET_PAYMENT_LINK)
}

const SEND_PAYMENT_LINK = gql`
  mutation sendPaymentLink($input: SendPaymentLinkInput!) {
    sendPaymentLink(input: $input) {
      ... on SendPaymentLinkOutput {
        success
      }

      ... on GqlHeroError {
        errorCode
      }
    }
  }
`

type SendPaymentLinkResponse = { success: boolean } | GqlHeroError

type SendPaymentLinkInput = {
  input: {
    paymentLinkId: string
    method: "SMS" | "WHATSAPP"
  }
}

export const useSendPaymentLink = () => {
  return useHeroMutation<SendPaymentLinkResponse, SendPaymentLinkInput>({
    gqlQuerySchema: SEND_PAYMENT_LINK,
  })
}
