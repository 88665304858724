import { useState } from "react"

export interface UseMethodFilterConfig {
  applyImmediately: boolean
}

export interface MethodState {
  methods: string[]
  toggleMethod: (method: string) => void
  reset: (localStateOnly?: boolean) => void
  apply: () => void
}

export const useMethodFilter = (
  config: UseMethodFilterConfig,
  updateUrlParams: (params: { method: string[] }) => void,
): MethodState => {
  const [methods, setMethods] = useState<string[]>([])

  const toggleMethod = (method: string) => {
    const newMethods = methods.includes(method) ? methods.filter((m) => m !== method) : [...methods, method]
    setMethods(newMethods)
    if (config.applyImmediately) {
      updateUrlParams({ method: newMethods })
    }
  }

  const reset = (localStateOnly = false) => {
    setMethods([])
    if (!localStateOnly) {
      updateUrlParams({ method: [] })
    }
  }

  const apply = () => {
    updateUrlParams({ method: methods })
  }

  return {
    methods,
    toggleMethod,
    reset,
    apply,
  }
}
