import { track } from "@amplitude/analytics-browser"
import { Button, EyeOnIcon, LockIcon, SettingsIcon, toaster } from "@hero/krypton"
import { useEffect, useState } from "react"
import { useLocation } from "react-router-dom"
import styled from "styled-components"
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "../../../../00_shared/components/Dropdown"
import { FlexContainer } from "../../../../00_shared/components/Flex"
import { useDashboardTranslation } from "../../../../01_technical/translations"
import { IssuanceStatus, IssuanceType, IssuingCard } from "../business/IssuingCard"
import { useCardDetailsIframeHook } from "../hooks/useCardDetailsIframe.hook"
import CardPhysicalIllustration from "../icons/card-physical-front.png"
import CardVirtualIllustration from "../icons/card-virtual-front.png"
import IconBlock from "../icons/icon-block.png"
import { ActionCardModal, ModalCardState } from "./ActionCardModal"
import IssuingCardOverlay from "./IssuingCardOverlay"
import { IssuingCardViewDetailsIframeModal } from "./IssuingCardViewDetailsIframeModal"

const CardContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: fit-content;
  min-width: 0;
  width: auto;
`

const CardActions = styled.div`
  display: flex;
  max-width: 100%;
  overflow-x: auto;
  gap: 8px;

  & > button div {
    display: flex;
    align-items: center;
    text-wrap: nowrap;
  }
`

const ActionButton = styled(Button)`
  display: flex;
  align-items: center;
  gap: 8px;
  background: ${({ theme }) => theme.colors.grey.$200};
  color: ${({ theme }) => theme.colors.grey.$600};

  &:hover,
  &:active,
  &:active:enabled,
  &:focus {
    background: ${({ theme }) => theme.colors.grey.$300};
    color: ${({ theme }) => theme.colors.grey.$600};
    outline: none;
  }
`

const Illustration = styled.div`
  position: relative;
`
interface IssuingCardDisplayWithActionsProps {
  card: IssuingCard
  closeModal?: () => void
}

export const IssuingCardWithActions = ({ card }: IssuingCardDisplayWithActionsProps) => {
  const { t } = useDashboardTranslation()
  const [modalCardState, setModalCardState] = useState<ModalCardState>()
  const location = useLocation()

  const [cardDetailsIframe, setCardDetailsIframe] = useState<string | null>(null)
  const [cardDetailsIframeToken, setCardDetailsIframeToken] = useState<string | null>(null)
  const [isPanModalOpen, setIsPanModalOpen] = useState(false)
  const { getCardDetailsIframes } = useCardDetailsIframeHook()

  const isOnCardDetailsPage = location.pathname.startsWith("/cards/details/")

  const handleOpenCardActionModal = async (action: ModalCardState["action"]) => {
    setModalCardState({ isOpen: true, action })
  }

  const fetchCardDetailsIframe = async () => {
    track(isOnCardDetailsPage ? "card_view_details_from_card_page_clicked" : "card_view_details_from_preview_clicked")

    await getCardDetailsIframes({
      variables: {
        cardId: card.id,
      },
      onCompleted: (data) => {
        if ("errorCode" in data.getCardDetailsIframes) {
          toaster.error(t("issuing.cards.viewPan.error"))
          return
        }

        setCardDetailsIframe(data.getCardDetailsIframes.desktopIframe)
        setCardDetailsIframeToken(data.getCardDetailsIframes.token)
      },
      onError: (error) => {
        toaster.error(t("issuing.cards.viewPan.error"))
      },
    })
  }

  const handleClickDisplayPan = async () => {
    setIsPanModalOpen(true)
    fetchCardDetailsIframe()
  }

  const handleCloseModalPan = async () => {
    setIsPanModalOpen(false)
  }

  const cleanIframeData = () => {
    setCardDetailsIframe(null)
    setCardDetailsIframeToken(null)
  }

  useEffect(() => {
    if (!isPanModalOpen) {
      cleanIframeData()
    }
  }, [isPanModalOpen])

  const shouldDisplayPan = [IssuanceStatus.ACTIVE, IssuanceStatus.PARTIALLY_ACTIVE].includes(card.status)

  return (
    <CardContent>
      <Illustration>
        <img
          src={card.issuanceType === IssuanceType.PHYSICAL ? CardPhysicalIllustration : CardVirtualIllustration}
          alt="card"
          width={320}
          style={[IssuanceStatus.TERMINATED, IssuanceStatus.FROZEN].includes(card.status) ? { opacity: 0.5 } : {}}
        />
        <IssuingCardOverlay card={card} />
      </Illustration>

      <CardActions>
        {shouldDisplayPan && (
          <ActionButton size="small" onClick={handleClickDisplayPan}>
            <EyeOnIcon width={20} />
            {t("issuing.card.actions.viewPan")}
          </ActionButton>
        )}
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <ActionButton>
              <SettingsIcon width={20} />
              {t("issuing.card.actions.moreOptions")}
            </ActionButton>
          </DropdownMenuTrigger>
          <DropdownMenuContent side="bottom">
            <>
              {[IssuanceStatus.ACTIVE, IssuanceStatus.PARTIALLY_ACTIVE].includes(card.status) && (
                <DropdownMenuItem onClick={() => handleOpenCardActionModal("freeze")}>
                  <FlexContainer $align="center" $justify="center" $gap=".5rem">
                    <LockIcon width={20} />
                    {t("issuing.card.actions.freezeCard")}
                  </FlexContainer>
                </DropdownMenuItem>
              )}
              {card.status === IssuanceStatus.FROZEN && (
                <DropdownMenuItem onClick={() => handleOpenCardActionModal("unfreeze")}>
                  <FlexContainer $align="center" $justify="center" $gap=".5rem">
                    <LockIcon width={20} />
                    {t("issuing.card.actions.unfreezeCard")}
                  </FlexContainer>
                </DropdownMenuItem>
              )}
              {[IssuanceStatus.ACTIVE, IssuanceStatus.PARTIALLY_ACTIVE, IssuanceStatus.FROZEN].includes(
                card.status,
              ) && (
                <DropdownMenuItem onClick={() => handleOpenCardActionModal("terminate")}>
                  <FlexContainer $align="center" $justify="center" $gap=".5rem">
                    <img width={20} height={20} src={IconBlock} alt="block" />
                    {t("issuing.card.action.terminateCard")}
                  </FlexContainer>
                </DropdownMenuItem>
              )}
            </>
          </DropdownMenuContent>
        </DropdownMenu>
      </CardActions>

      {shouldDisplayPan && (
        <IssuingCardViewDetailsIframeModal
          url={cardDetailsIframe}
          token={cardDetailsIframeToken}
          isOpen={isPanModalOpen}
          onClose={handleCloseModalPan}
        />
      )}
      {modalCardState && (
        <ActionCardModal
          action={modalCardState.action}
          cardId={card.id}
          isOpen={modalCardState.isOpen}
          onClose={() =>
            setModalCardState((prevState) => {
              if (prevState) {
                return { ...prevState, isOpen: false }
              }
            })
          }
        />
      )}
    </CardContent>
  )
}
