import { Navigate, Outlet, Params } from "react-router-dom"
import { OutgoingDetails } from "./OutgoingDetails.screen"

export const outgoingDetailsRoute = {
  path: "outgoing",
  element: <Outlet />,
  handle: { crumb: () => ({ label: "ap.balance.breadcrumb.outgoing", to: "/ap/balance?transaction=outgoing" }) },
  children: [
    { index: true, element: <Navigate to="/ap/balance?transaction=outgoing" /> },
    {
      path: ":id",
      element: <OutgoingDetails />,
      handle: { crumb: (params: Params<string>) => ({ label: params.id ?? "" }) },
    },
  ],
}
