import { gql } from "@apollo/client"

export type OnboardUserMerchantArgs = {
  password: string
  invitationToken: string
  pin: string
}

export type OnboardUserMerchantResponse = {
  merchantUserOnboard: boolean
}

export const ONBOARD_USER_MERCHANT_MUTATION = gql`
  mutation onboardMerchantUser($password: String!, $invitationToken: String!, $pin: String!) {
    merchantUserOnboard(password: $password, invitationToken: $invitationToken, pin: $pin)
  }
`
