import { gql } from "@apollo/client"
import { APStatus } from "../../../business/enums/AP.enum"

type AP = {
  id: string
  status: APStatus
  createdAt: string
  fundedAmount: number
  marketplace: string
  marketplaceLogoUrl: string | null
}

export type ListMerchantAPsSuccessResponse = {
  acceleratedPayouts: AP[]
  totalCount: number
  listMarketplaces: string[]
}

export const LIST_MERCHANT_AP_QUERY = gql`
  query listMerchantAPs($filters: MerchantGetAPListFilters!, $pagination: MerchantGetAPListPagination!) {
    listMerchantAPs(filters: $filters, pagination: $pagination) {
      ... on MerchantGetAPListOutput {
        acceleratedPayouts {
          id
          status
          createdAt
          fundedAmount
          marketplace
          marketplaceLogoUrl
        }
        totalCount
        listMarketplaces
      }

      ... on ValidationErrors {
        validationErrors {
          path
          validationError
        }
      }
      ... on GqlHeroError {
        errorCode
        message
      }
    }
  }
`
