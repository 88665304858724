import { BOV2RouteProtector } from "../../../../App.router"
import { ClientTransactionsDetailsScreen } from "./clientTransactionsDetails.screen"

export const clientTransactionsDetailsRoute = {
  path: ":referenceOrSiren/transactions",
  element: (
    <BOV2RouteProtector>
      <ClientTransactionsDetailsScreen />
    </BOV2RouteProtector>
  ),
}
