import { FloatingBox, LinkIcon, MenuButtonEntry, MenuProfileButton, Typography } from "@hero/krypton"
import { useState } from "react"
import styled from "styled-components"
import { useDashboardTranslation } from "../../../../../../01_technical/translations"
import { Payment } from "../../../../List/List.requests"
import { LinkModal } from "../../LinkModal"
import { RefundModal } from "../../Refund/RefundModal"
import { BNPLProductType } from "../../../enums/PaymentCore.enum"

const StyledMenuProfileButton = styled(MenuProfileButton)`
  height: unset;
  padding: 0.25rem 0.25rem;

  span {
    margin-left: unset;
  }

  ${({ theme }) => `background-color: ${theme.colors.white};`}
`

export const OptionsButton = ({ payment, refetch }: { payment: Payment; refetch: () => void }) => {
  const { t } = useDashboardTranslation()
  const [anchorElement, setAnchorEl] = useState<HTMLButtonElement>()
  const isOpen = Boolean(anchorElement)
  const [isLinkModalOpen, setIsLinkModalOpen] = useState(false)
  const [isRefundModalOpen, setIsRefundModalOpen] = useState(false)

  const handleClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setAnchorEl(anchorElement ? undefined : e.currentTarget)
  }

  return (
    <>
      <StyledMenuProfileButton onClick={handleClick} isOpen={isOpen} mode="light">
        <Typography $variant="caption-1">{t("collection.optionButton.title")}</Typography>
      </StyledMenuProfileButton>

      <FloatingBox
        anchorElement={anchorElement}
        onClose={() => setAnchorEl(undefined)}
        isOpen={isOpen}
        // eslint-disable-next-line i18next/no-literal-string
        style={{ width: "14rem", zIndex: 9999 }}
      >
        {payment.productType === BNPLProductType.LINK && (
          <MenuButtonEntry mode="light" onClick={() => setIsLinkModalOpen(true)}>
            <Typography $variant="body-4-regular">{t("collection.list.actions.link")}</Typography>
            <LinkIcon width="20px" />
          </MenuButtonEntry>
        )}
        <MenuButtonEntry mode="light" onClick={() => setIsRefundModalOpen(true)}>
          <Typography $variant="body-4-regular" $color="danger.$200">
            {t("collection.list.actions.refund")}
          </Typography>
        </MenuButtonEntry>
      </FloatingBox>

      {payment.productType === BNPLProductType.LINK && (
        <LinkModal isOpen={isLinkModalOpen} paymentLinkId={payment.id} onClose={() => setIsLinkModalOpen(false)} />
      )}

      <RefundModal
        isOpen={isRefundModalOpen}
        payment={payment}
        onClose={() => setIsRefundModalOpen(false)}
        refetch={refetch}
      />
    </>
  )
}
