import { Link } from "react-router-dom"
import styled from "styled-components"

export const TableCellLink = styled(Link)`
  height: 100%;
  width: inherit;
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
`
