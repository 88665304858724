import { Button, FieldTextarea, toaster, Typography } from "@hero/krypton"
import { zodResolver } from "@hookform/resolvers/zod"
import * as Sentry from "@sentry/react"
import { useForm } from "react-hook-form"
import { z } from "zod"
import { Card } from "../../../../../00_shared/components/Card"
import { FlexContainer, FlexItem } from "../../../../../00_shared/components/Flex"
import { DashboardTFunction, useDashboardTranslation } from "../../../../../01_technical/translations"
import { useDisputeIssuingTransaction } from "../hooks/useDisputeIssuingCardTransaction.hook"

const getDisputeTransactionValidator = (t: DashboardTFunction) =>
  z.object({
    transactionId: z.string().min(1, t("issuing.disputeTransaction.validation.transactionId.required")),
    disputeReason: z.string().min(1, t("issuing.disputeTransaction.validation.disputeReason.required")),
  })

export const GetHelpForTransaction = ({
  transactionId,
  label,
  date,
  amount,
  onDone,
}: {
  transactionId: string
  label: string
  date: string
  amount: string
  onDone: () => void
}) => {
  const { t } = useDashboardTranslation()

  const disputeTransactionValidator = getDisputeTransactionValidator(t)
  type DisputeTransactionFormValues = z.infer<typeof disputeTransactionValidator>

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<DisputeTransactionFormValues>({
    resolver: zodResolver(disputeTransactionValidator),
  })

  const { mutate, loading } = useDisputeIssuingTransaction()

  const onSubmit = async (formData: DisputeTransactionFormValues) => {
    try {
      const isTransactionDisputeCreated = await mutate({
        variables: { transactionId: formData.transactionId, disputeReason: formData.disputeReason },
      })
      if (!isTransactionDisputeCreated) {
        toaster.error(t("issuing.disputeTransaction.error"))
        return
      }
      toaster.success(t("issuing.disputeTransaction.success"))
    } catch (error) {
      Sentry.captureException(error)
      toaster.error(t("issuing.disputeTransaction.error"))
    }
    onDone()
  }

  return (
    <FlexContainer as="form" $direction="column" $gap="0.75rem" onSubmit={handleSubmit(onSubmit)}>
      <Card style={{ padding: "1rem" }}>
        <FlexContainer $justify="space-between">
          <FlexItem>
            <Typography $variant="title-3-semibold">{label}</Typography>
            <Typography $variant="caption-2" $color="grey.$500">
              {date}
            </Typography>
          </FlexItem>
          <FlexItem>
            <Typography $variant="title-3-semibold">{amount}</Typography>
          </FlexItem>
        </FlexContainer>
      </Card>
      <input type="hidden" value={transactionId} {...register("transactionId")} />
      <FieldTextarea
        style={{ resize: "vertical" }}
        fullWidth
        fieldLabel="Expliquez le problème"
        placeholder="Veuillez décrire en détail la raison de votre contestation (ex. : transaction non autorisée, montant incorrect, etc.)"
        {...register("disputeReason")}
        errorMessage={errors.disputeReason?.message}
      />
      <Button type="submit" isLoading={loading}>
        {t("issuing.disputeTransaction.sendButton")}
      </Button>
    </FlexContainer>
  )
}
