import { useApolloClient } from "@apollo/client"
import { useCallback, useState } from "react"
import { MERCHANT_GET_UPLOAD_ATTACHMENT_URL, UploadInfo } from "./CreatePaymentLink.requests"

type TUploadStatus = "LOADING" | "SUCCESS" | Error

export const useUploadFile = () => {
  const client = useApolloClient()
  const [uploadStatus, setUploadStatus] = useState<TUploadStatus>("SUCCESS")

  const startUploadFile: (file: File, paymentLinkId: string) => Promise<{ path: UploadInfo["path"]; file: File }> =
    useCallback(
      async (file: File, paymentLinkId) => {
        setUploadStatus(() => "LOADING")

        try {
          const { data } = await client.query<{
            uploadablePaymentLinkResourceSignedUrl: UploadInfo
          }>({
            query: MERCHANT_GET_UPLOAD_ATTACHMENT_URL,
            variables: { paymentLinkId },
            fetchPolicy: "network-only",
          })

          const uploadResult = await fetch(data.uploadablePaymentLinkResourceSignedUrl.signedUrl, {
            method: "PUT",
            headers: { "Content-Type": file.type, "Content-Length": file.size.toString() },
            body: file,
            mode: "cors",
          })

          if (!uploadResult.ok) {
            throw new Error("Impossible de charger la pièce jointe")
          }

          setUploadStatus(() => "SUCCESS")
          return {
            path: data.uploadablePaymentLinkResourceSignedUrl.path,
            file,
          }
        } catch (err: unknown) {
          setUploadStatus(() => err as Error)
          throw err
        }
      },
      [client],
    )

  return [
    startUploadFile,
    {
      loading: uploadStatus === "LOADING",
      error: uploadStatus instanceof Error ? uploadStatus : undefined,
    },
  ] as const
}
