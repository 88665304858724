import { AvailableLanguage, FallbackLanguage, useCommonTranslation } from "./translations"

import apSideImageDE from "../assets/accelerated_payouts/getAccessSide.de.png"
import apSideImageEN from "../assets/accelerated_payouts/getAccessSide.en.png"
import apSideImageES from "../assets/accelerated_payouts/getAccessSide.es.png"
import apSideImageFR from "../assets/accelerated_payouts/getAccessSide.fr.png"
import apSideImageIT from "../assets/accelerated_payouts/getAccessSide.it.png"

import checkoutAndCashSideImageDE from "../assets/checkout_and_cash/getAccessSide.de.png"
import checkoutAndCashSideImageEN from "../assets/checkout_and_cash/getAccessSide.en.png"
import checkoutAndCashSideImageES from "../assets/checkout_and_cash/getAccessSide.es.png"
import checkoutAndCashSideImageFR from "../assets/checkout_and_cash/getAccessSide.fr.png"
import checkoutAndCashSideImageIT from "../assets/checkout_and_cash/getAccessSide.it.png"

import clientsSideImageDE from "../assets/clients/getAccessSide.de.png"
import clientsSideImageEN from "../assets/clients/getAccessSide.en.png"
import clientsSideImageES from "../assets/clients/getAccessSide.es.png"
import clientsSideImageFR from "../assets/clients/getAccessSide.fr.png"
import clientsSideImageIT from "../assets/clients/getAccessSide.it.png"

const sourceMap = {
  acceleratedPayouts: {
    [AvailableLanguage.EN]: apSideImageEN,
    [AvailableLanguage.ES]: apSideImageES,
    [AvailableLanguage.FR]: apSideImageFR,
    [AvailableLanguage.DE]: apSideImageDE,
    [AvailableLanguage.IT]: apSideImageIT,
  },
  checkoutAndCash: {
    [AvailableLanguage.EN]: checkoutAndCashSideImageEN,
    [AvailableLanguage.ES]: checkoutAndCashSideImageES,
    [AvailableLanguage.FR]: checkoutAndCashSideImageFR,
    [AvailableLanguage.DE]: checkoutAndCashSideImageDE,
    [AvailableLanguage.IT]: checkoutAndCashSideImageIT,
  },
  clients: {
    [AvailableLanguage.EN]: clientsSideImageEN,
    [AvailableLanguage.ES]: clientsSideImageES,
    [AvailableLanguage.FR]: clientsSideImageFR,
    [AvailableLanguage.DE]: clientsSideImageDE,
    [AvailableLanguage.IT]: clientsSideImageIT,
  },
}

export const useLocalizedImage = (target: keyof typeof sourceMap) => {
  const { i18n } = useCommonTranslation()

  const resourceByLanguage = sourceMap[target]

  return i18n.language in resourceByLanguage
    ? resourceByLanguage[i18n.language as keyof typeof resourceByLanguage]
    : resourceByLanguage[FallbackLanguage]
}
