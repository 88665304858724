import { Button, Modal as DefaultModal, toaster, Typography } from "@hero/krypton"
import { useEffect } from "react"
import styled from "styled-components"
import { useDashboardTranslation } from "../../../../01_technical/translations"
import { useIssuingCardInformationHook } from "../../IssuingCardList/IssuingCardInformations.hook"
import { useFreezeCard } from "../hooks/useFreezeCard.hook"

const Modal = styled(DefaultModal)`
  width: unset;
  max-height: unset;
  padding: 3rem:
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 2rem;
  padding: 0 1.5rem 1.5rem 1.5rem;
`

interface FreezeCardModalProps {
  cardId: string
  isModalOpen: boolean
  onClose: () => void
}

export const FreezeCardModal = ({ cardId, isModalOpen, onClose }: FreezeCardModalProps) => {
  const { t } = useDashboardTranslation()
  const { freezeCard, loading: freezing, error: freezeError } = useFreezeCard()
  const { refetch } = useIssuingCardInformationHook(cardId)

  const handleFreezeCard = async () => {
    if (cardId) {
      const freezed = await freezeCard(cardId)
      refetch()
      if (freezed?.success) {
        toaster.success(t("issuing.cards.freezed.success"))
      }
      onClose()
    }
  }

  useEffect(() => {
    if (freezeError) {
      toaster.error(freezeError.message)
    }
  }, [freezeError])

  return (
    <Modal isOpen={isModalOpen} onClose={() => onClose()}>
      <Container>
        <div>
          <Typography $variant="title-2-bold">{t("issuing.cards.freezedCardModal.title")}</Typography>
          <Typography $variant="body-4-regular">{t("issuing.cards.freezedCardModal.description")}</Typography>
        </div>
        <Button size="medium" onClick={handleFreezeCard} $variant="danger" isLoading={freezing}>
          {t("issuing.cards.freezedCardModal.confirm")}
        </Button>
      </Container>
    </Modal>
  )
}
