import { useMutation } from "@apollo/client"
import { useEffect } from "react"
import { MERCHANT_CREATE_DRAFT_PAYMENT_LINK } from "./CreatePaymentLink.requests"

export const useDraftPaymentLink = () => {
  const [createDraftPaymentLink, { data, loading, error, called }] = useMutation<{
    createDraftPaymentLink: { id: string }
  }>(MERCHANT_CREATE_DRAFT_PAYMENT_LINK)

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    createDraftPaymentLink().catch(() => {})
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return {
    paymentId: data?.createDraftPaymentLink.id,
    loading: loading || !called,
    error,
  }
}
