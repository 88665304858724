import { Badge, ScheduleIcon, CloseStrokeIcon, ValidIcon } from "@hero/krypton"
import { useDashboardTranslation } from "../../../../../01_technical/translations"
import { TransferStatus } from "../../../00_shared/business/enum/IncomingTransferStatus.enum"

export const TransferStatusBadge = ({ status }: { status: TransferStatus }) => {
  const { t } = useDashboardTranslation()

  const translateStatusKeyMap: Record<TransferStatus, "pending" | "refunded" | "received" | "sent"> = {
    [TransferStatus.WAITING]: "pending",
    [TransferStatus.REFUNDED]: "refunded",
    [TransferStatus.ALLOCATED]: "received",
    [TransferStatus.SENT]: "sent",
  }

  const statusText = t(`ap.balance.status.${translateStatusKeyMap[status]}`)

  switch (status) {
    case TransferStatus.WAITING:
      return (
        <Badge $variant="warning" icon={<ScheduleIcon />}>
          {statusText}
        </Badge>
      )
    case TransferStatus.REFUNDED:
      return (
        <Badge $variant="danger" icon={<CloseStrokeIcon />}>
          {statusText}
        </Badge>
      )
    case TransferStatus.ALLOCATED:
      return (
        <Badge $variant="primary" icon={<ValidIcon />}>
          {statusText}
        </Badge>
      )
    case TransferStatus.SENT:
      return (
        <Badge $variant="primary" icon={<ValidIcon />}>
          {statusText}
        </Badge>
      )
  }
}
