/**
 * The demo account is a special account that is used to demonstrate the features of the application.
 * Most of the time is used by sales to show the application to potential customers.
 */

import { DEMO_MERCHANT_ID } from "../../env_variables"

export const checkIfIsDemoMerchant = (merchantId: string) => {
  return DEMO_MERCHANT_ID === merchantId
}
