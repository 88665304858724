import { BOV2RouteProtector } from "../../../App.router"
import { MarketplaceReserveFinancing } from "./MarketplaceReserveFinancing.screen"

export const mrfRoute = {
  index: true,
  path: "marketplace-reserve-financing",
  element: (
    <BOV2RouteProtector>
      <MarketplaceReserveFinancing />
    </BOV2RouteProtector>
  ),
}
