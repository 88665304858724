import { MerchantMenuEntry } from "../../merchant.menu.type"

export const SettingsMenuEntry: MerchantMenuEntry = {
  to: "/tools/settings",
  key: "menu.list.tools.settings",
  visible: true,
  selected: (location) => {
    return location.pathname.startsWith("/tools/settings")
  },
  "data-test-id": "menu-entry-tools-settings",
}
