import { Attachment, Typography } from "@hero/krypton"
import styled from "styled-components"
import { useSignedUrlFileDownload } from "../../../../../00_shared/hooks/useSignedUrlFileDownload.hook"
import { useDashboardTranslation } from "../../../../../01_technical/translations"
import { TransactionFlowType } from "../../../../../business/enums/Transaction.enum"
import { API_URI } from "../../../../../env_variables"
import { Transaction } from "../transaction.hooks"
import { useDownloadAttachment } from "./downloadAttachment.hook"

const AttachmentTitle = styled(Typography)`
  margin-bottom: 0.25rem;
`

const Container = styled.div`
  margin-bottom: 2rem;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 0.75rem;
  padding: 2rem;
`

export const TransactionAttachments: React.FC<{
  attachments: Transaction["attachments"]
  flowType: Transaction["flowType"]
}> = ({ attachments, flowType }) => {
  const { t } = useDashboardTranslation()

  const [fileDownload] = useSignedUrlFileDownload()
  const downloadAttachment = useDownloadAttachment()

  if (attachments.length === 0) {
    return null
  }

  return (
    <Container>
      <AttachmentTitle $variant="body-4-medium">
        {t("checkoutAndCash.cockpit.transaction.attachment.title")}
      </AttachmentTitle>
      <Typography as="ul">
        {attachments.map((attachment) => (
          <li key={attachment.id}>
            <Attachment
              name={attachment.name}
              canDelete={false}
              onView={() =>
                flowType === TransactionFlowType.RECEIVABLE
                  ? fileDownload(`${API_URI}/merchant/receivableAttachments/${attachment.id}`)
                  : downloadAttachment(`${attachment.id}`)
              }
            />
          </li>
        ))}
      </Typography>
    </Container>
  )
}
