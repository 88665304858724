import { Button, Typography } from "@hero/krypton"
import { Card, CardContent, CardFooter, CardHeader } from "../../../../00_shared/components/Card"
import { FlexItem } from "../../../../00_shared/components/Flex"
import { useAmplitude } from "../../../../00_shared/hooks/useAmplitude.hook"
import { useDashboardTranslation } from "../../../../01_technical/translations"
import { IssuanceType } from "../../00_shared/business/IssuingCard"
import CardDebitIllustration from "../../00_shared/icons/card-physical-give-small.png"
import CardVirtualIllustration from "../../00_shared/icons/card-virtual-give.png"
import { useIssuingCardCreationContext } from "../IssuingCardCreationContext"
import { IssuingRadioCardSelect } from "./components/IssuingRadioCardSelect"
import { useIsIssuingAllowingPhysicalCards } from "../../../../00_shared/hooks/useFeatureFlag.hook"

export const SelectIssuanceType = () => {
  const { state, setState, handleNextStep } = useIssuingCardCreationContext()
  const { track } = useAmplitude()
  const { t } = useDashboardTranslation()
  const physicalCardAllowed = useIsIssuingAllowingPhysicalCards()

  const cardTypes = [
    {
      title: t("issuing.card.creation.issuanceType.physical.title"), //"Physique"
      description: t("issuing.card.creation.issuanceType.physical.description"), // "Tous types de paiement et retraits.",
      image: <img src={CardDebitIllustration} alt="Physique" style={{ height: 60 }} />,
      value: IssuanceType.PHYSICAL,
      comingSoon: !physicalCardAllowed,
    },
    {
      title: t("issuing.card.creation.issuanceType.virtual.title"), // "Virtuelle",
      description: t("issuing.card.creation.issuanceType.virtual.description"), //"Paiements en ligne.",
      image: <img src={CardVirtualIllustration} alt="Virtuelle" style={{ height: 60 }} />,
      value: IssuanceType.VIRTUAL,
    },
  ]

  return (
    <Card $variant="plain">
      <CardHeader>
        <Typography $variant="title-2-bold">{t("issuing.card.creation.issuanceType.title")}</Typography>
      </CardHeader>
      <CardContent>
        <IssuingRadioCardSelect
          cards={cardTypes}
          selectedCard={state.cardDetails.cardType}
          reverseOrdering={!physicalCardAllowed}
          onSelectCard={(id) => {
            const selectedCardType = cardTypes[id].value
            setState((prev) => ({
              ...prev,
              cardDetails: {
                cardType: selectedCardType,
                ...(selectedCardType === IssuanceType.PHYSICAL ? { address: prev.cardDetails.address } : {}),
              },
            }))
            track(`card_${selectedCardType.toLowerCase()}_card_selected`)
          }}
        />
      </CardContent>
      <CardFooter>
        <FlexItem $grow={1}>
          <Button $fullWidth $variant="primary" size="medium" onClick={handleNextStep}>
            {t("issuing.card.creation.issuanceType.next")}
          </Button>
        </FlexItem>
      </CardFooter>
    </Card>
  )
}
