import { ChevronDownIcon, Typography } from "@hero/krypton"
import * as AccordionPrimitive from "@radix-ui/react-accordion"
import * as React from "react"
import styled, { keyframes } from "styled-components"

// Keyframes for animations
const accordionDown = keyframes`
  from {
    height: 0;
  }
  to {
    height: var(--radix-accordion-content-height);
  }
`

const accordionUp = keyframes`
  from {
    height: var(--radix-accordion-content-height);
  }
  to {
    height: 0;
  }
`

// Styled Components
const AccordionRoot = styled(AccordionPrimitive.Root)`
  background: ${({ theme }) => theme.colors.white};
`

const AccordionHeader = styled(AccordionPrimitive.Header)`
  display: flex;
`

const AccordionTriggerButton = styled(AccordionPrimitive.Trigger)`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 0;
  transition: all 0.3s;
  cursor: pointer;
  min-height: 4rem;

  background: ${({ theme }) => theme.colors.white};
  border: none;

  &[data-state="open"] > svg {
    transform: rotate(180deg);
  }
`

const ChevronIcon = styled(ChevronDownIcon)`
  width: 1.5rem;
  height: 1.5rem;
  transition: transform 0.3s ease-in-out;
`

const AccordionContentContainer = styled(AccordionPrimitive.Content)`
  overflow: hidden;
  transition: max-height 0.3s ease-in-out;
  &[data-state="closed"] {
    animation: ${accordionUp} 0.2s ease-out;
  }
  &[data-state="open"] {
    animation: ${accordionDown} 0.2s ease-out;
  }
`

const AccordionContentInner = styled.div``

const AccordionItemContainer = styled(AccordionPrimitive.Item)`
  border-bottom: 1px solid ${({ theme }) => theme.colors.grey.$200};
`

const AccordionItem = React.forwardRef<
  React.ElementRef<typeof AccordionPrimitive.Item>,
  React.ComponentPropsWithoutRef<typeof AccordionPrimitive.Item>
>(({ ...props }, ref) => <AccordionItemContainer ref={ref} {...props} />)

AccordionItem.displayName = "AccordionItem"

const AccordionTrigger = React.forwardRef<
  React.ElementRef<typeof AccordionPrimitive.Trigger>,
  React.ComponentPropsWithoutRef<typeof AccordionPrimitive.Trigger>
>(({ children, ...props }, ref) => (
  <AccordionHeader>
    <AccordionTriggerButton ref={ref} {...props}>
      <Typography $variant="body-3-regular">{children}</Typography>
      <ChevronIcon />
    </AccordionTriggerButton>
  </AccordionHeader>
))

AccordionTrigger.displayName = AccordionPrimitive.Trigger.displayName

const AccordionContent = React.forwardRef<
  React.ElementRef<typeof AccordionPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof AccordionPrimitive.Content>
>(({ className, children, ...props }, ref) => (
  <AccordionContentContainer ref={ref} {...props}>
    <AccordionContentInner className={className}>{children}</AccordionContentInner>
  </AccordionContentContainer>
))

AccordionContent.displayName = AccordionPrimitive.Content.displayName

export { AccordionRoot as Accordion, AccordionContent, AccordionItem, AccordionTrigger }
