import { CwtItem, CwtItemStatus } from "./useGetCwtList"
import { DateTime } from "luxon"

const randomId = () => Math.random().toString(16).slice(2)

const generateEntityId = (entity: string) => `${entity}-${randomId()}`

const generateCwtInstallments = (args: {
  statuses: string[]
  totalAmount: number
  feeAmount: number
  dateOffset: number
}): CwtItem["installments"] =>
  Array.from({ length: args.statuses.length }, (_, index) => ({
    amount: {
      totalAmount: args.totalAmount / args.statuses.length,
      feeAmount: {
        totalAmount: args.feeAmount,
        vatAmount: 0,
        amountWithoutVAT: args.feeAmount,
      },
    },
    dueDate: DateTime.now().minus({ days: args.dateOffset, month: index }).toJSDate(),
    position: index + 1,
    status: args.statuses[index],
  }))

const generateCwtItem = (args: {
  recipient: string
  totalAmount: number
  feeAmount: number
  installmentsStatuses: string[] | null
  status: CwtItemStatus
  dateOffset?: number
}): CwtItem => {
  const installments =
    args.installmentsStatuses && args.status !== CwtItemStatus.REJECTED
      ? generateCwtInstallments({
          totalAmount: args.totalAmount,
          feeAmount: args.feeAmount,
          statuses: args.installmentsStatuses,
          dateOffset: args.dateOffset ?? 0,
        })
      : null

  return {
    id: generateEntityId("cwt"),
    amount: args.totalAmount,
    feeRate: args.feeAmount,
    feeAmount: {
      totalAmount: args.feeAmount,
      vatAmount: 0,
      amountWithoutVAT: args.feeAmount,
    },
    appliedAt: new Date(),
    document: new URL("https://www.google.com"),
    installmentCount: args.installmentsStatuses ? args.installmentsStatuses.length : 0,
    installments,
    label: `CWT ${randomId()}`,
    merchantId: generateEntityId("merchant"),
    recipient: {
      beneficiaryId: generateEntityId("beneficiary"),
      name: args.recipient,
      email: "",
      siret: "123456789",
      status: "verified",
    },
    status: args.status,
  }
}

export const getDemoData = (filters: { merchantId: string; status: string | null }): CwtItem[] => {
  const cwtList: CwtItem[] = [
    // Ongoing
    generateCwtItem({
      recipient: "Apple Inc.",
      totalAmount: 399900,
      feeAmount: 10797,
      installmentsStatuses: null,
      status: CwtItemStatus.WAITING_FOR_REVIEW,
    }),
    generateCwtItem({
      recipient: "FNAC SA",
      totalAmount: 27344,
      feeAmount: 73828,
      installmentsStatuses: null,
      status: CwtItemStatus.WAITING_FOR_REVIEW,
    }),
    generateCwtItem({
      recipient: "ORANGE Reconditionnement SAS",
      totalAmount: 1134,
      feeAmount: 4061,
      installmentsStatuses: null,
      status: CwtItemStatus.WAITING_FOR_REVIEW,
    }),
    generateCwtItem({
      recipient: "ORANGE Reconditionnement SAS",
      totalAmount: 2381,
      feeAmount: 7857,
      installmentsStatuses: null,
      status: CwtItemStatus.WAITING_FOR_REVIEW,
    }),
    generateCwtItem({
      recipient: "DECATHLON FRANCE",
      totalAmount: 299023,
      feeAmount: 8073,
      installmentsStatuses: ["PAID", "PENDING", "PENDING"],
      status: CwtItemStatus.APPROVED,
      dateOffset: 1,
    }),
    generateCwtItem({
      recipient: "DECATHLON FRANCE",
      totalAmount: 120011,
      feeAmount: 3960,
      installmentsStatuses: ["PAID", "PENDING", "PENDING", "PENDING"],
      status: CwtItemStatus.APPROVED,
      dateOffset: 10,
    }),
    generateCwtItem({
      recipient: "WEBEDIA",
      totalAmount: 110001,
      feeAmount: 2970,
      installmentsStatuses: ["PAID", "PENDING", "PENDING"],
      status: CwtItemStatus.APPROVED,
      dateOffset: 20,
    }),
    generateCwtItem({
      recipient: "FNAC DARTY SA",
      totalAmount: 2732093,
      feeAmount: 90159,
      installmentsStatuses: ["PAID", "PAID", "PENDING", "PENDING"],
      status: CwtItemStatus.APPROVED,
      dateOffset: 30,
    }),
    generateCwtItem({
      recipient: "SFR Fibre SAS",
      totalAmount: 810001,
      feeAmount: 26730,
      installmentsStatuses: ["PAID", "PAID", "PENDING", "PENDING"],
      status: CwtItemStatus.APPROVED,
      dateOffset: 43,
    }),
    generateCwtItem({
      recipient: "Mistral AI SAS",
      totalAmount: 135034,
      feeAmount: 4456,
      installmentsStatuses: ["PAID", "PAID", "PENDING", "PENDING"],
      status: CwtItemStatus.APPROVED,
      dateOffset: 43,
    }),
    generateCwtItem({
      recipient: "SFR Fibre SAS",
      totalAmount: 9900,
      feeAmount: 26730,
      installmentsStatuses: ["PAID", "PAID", "PENDING"],
      status: CwtItemStatus.APPROVED,
      dateOffset: 45,
    }),
    // Completed
    generateCwtItem({
      recipient: "SHIFTECH ENGINEERING TOURS INC.",
      totalAmount: 1220023,
      feeAmount: 32941,
      installmentsStatuses: ["PAID", "PAID", "PAID"],
      status: CwtItemStatus.COMPLETED,
      dateOffset: 120,
    }),
    generateCwtItem({
      recipient: "KPMG",
      totalAmount: 190045,
      feeAmount: 5131,
      installmentsStatuses: ["PAID", "PAID", "PAID"],
      status: CwtItemStatus.COMPLETED,
      dateOffset: 110,
    }),
    generateCwtItem({
      recipient: "LVMH MOET HENNESSY LOUIS VUITTON SAS",
      totalAmount: 7520022,
      feeAmount: 203041,
      installmentsStatuses: ["PAID", "PAID", "PAID"],
      status: CwtItemStatus.COMPLETED,
      dateOffset: 110,
    }),
    generateCwtItem({
      recipient: "LVMH MOET HENNESSY LOUIS VUITTON SAS",
      totalAmount: 9235056,
      feeAmount: 304757,
      installmentsStatuses: ["PAID", "PAID", "PAID", "PAID"],
      status: CwtItemStatus.COMPLETED,
      dateOffset: 100,
    }),
    generateCwtItem({
      recipient: "LVMH MOET HENNESSY LOUIS VUITTON SAS",
      totalAmount: 54000,
      feeAmount: 178200,
      installmentsStatuses: ["PAID", "PAID", "PAID", "PAID"],
      status: CwtItemStatus.COMPLETED,
      dateOffset: 99,
    }),
    generateCwtItem({
      recipient: "Anagram SAS",
      totalAmount: 750045,
      feeAmount: 20251,
      installmentsStatuses: ["PAID", "PAID", "PAID"],
      status: CwtItemStatus.COMPLETED,
      dateOffset: 90,
    }),
    generateCwtItem({
      recipient: "Anagram SAS",
      totalAmount: 950078,
      feeAmount: 25652,
      installmentsStatuses: ["PAID", "PAID", "PAID"],
      status: CwtItemStatus.COMPLETED,
      dateOffset: 70,
    }),
    generateCwtItem({
      recipient: "LA CASA LOUNGE SAR",
      totalAmount: 112012,
      feeAmount: 3696,
      installmentsStatuses: ["PAID", "PAID", "PAID", "PAID"],
      status: CwtItemStatus.COMPLETED,
      dateOffset: 60,
    }),
    // Rejected
    generateCwtItem({
      recipient: "ORANGE SAS",
      totalAmount: 11900023,
      feeAmount: 329712,
      installmentsStatuses: ["FAILED", "FAILED", "FAILED"],
      status: CwtItemStatus.REJECTED,
    }),
    generateCwtItem({
      recipient: "Coruscant SAS",
      totalAmount: 810023,
      feeAmount: 24323,
      installmentsStatuses: ["FAILED", "FAILED", "FAILED"],
      status: CwtItemStatus.REJECTED,
    }),
    generateCwtItem({
      recipient: "Tatooine SAS",
      totalAmount: 350002,
      feeAmount: 10500,
      installmentsStatuses: ["FAILED", "FAILED", "FAILED"],
      status: CwtItemStatus.REJECTED,
    }),
    generateCwtItem({
      recipient: "Anagram SAS",
      totalAmount: 750023,
      feeAmount: 22501,
      installmentsStatuses: ["FAILED", "FAILED", "FAILED"],
      status: CwtItemStatus.REJECTED,
    }),
    generateCwtItem({
      recipient: "Anagram SAS",
      totalAmount: 950011,
      feeAmount: 28500,
      installmentsStatuses: ["FAILED", "FAILED", "FAILED"],
      status: CwtItemStatus.REJECTED,
    }),
  ]

  return cwtList.filter((cwt) => {
    if (filters.status === "ONGOING") {
      return [CwtItemStatus.APPROVED, CwtItemStatus.WAITING_FOR_REVIEW].includes(cwt.status)
    }
    return cwt.status.toLowerCase() === filters.status?.toLowerCase() || !filters.status
  })
}
