export enum OperationType {
  SEPA_CREDIT_TRANSFER_IN = "SEPA_CREDIT_TRANSFER_IN",
  SEPA_CREDIT_TRANSFER_OUT = "SEPA_CREDIT_TRANSFER_OUT",
  ACCOUNT_TO_ACCOUNT_IN = "ACCOUNT_TO_ACCOUNT_IN",
  ACCOUNT_TO_ACCOUNT_OUT = "ACCOUNT_TO_ACCOUNT_OUT",
}

export enum LedgerMovementFlow {
  ISSUING_RESERVATION = "ISSUING_RESERVATION",
  ISSUING_REFUND = "ISSUING_REFUND",
  // BUSINESS_ACCOUNT_INCOMING_WIRE = "BUSINESS_ACCOUNT_INCOMING_WIRE",
  BUSINESS_ACCOUNT_EXTERNAL_PAYOUT = "BUSINESS_ACCOUNT_EXTERNAL_PAYOUT",
  // BUSINESS_ACCOUNT_REFUND = "BUSINESS_ACCOUNT_REFUND",
  // BUSINESS_ACCOUNT_CENTRALIZE_PRODUCT_FUNDS = "BUSINESS_ACCOUNT_CENTRALIZE_PRODUCT_FUNDS",
  // BUSINESS_ACCOUNT_EXTERNAL_PAYOUT_FOR_PRODUCT = "BUSINESS_ACCOUNT_EXTERNAL_PAYOUT_FOR_PRODUCT",
  PRODUCT_LEDGER_TO_PRODUCT_LEDGER = "PRODUCT_LEDGER_TO_PRODUCT_LEDGER",
}

export enum LedgerRegistryType {
  // AP = "AP",
  // CHECKOUT = "CHECKOUT",
  // LINK = "LINK",
  // CASH = "CASH",
  // RESERVE = "RESERVE",
  // CASH_HOLD = "CASH_HOLD",
  // CHECKOUT_HOLD = "CHECKOUT_HOLD",
  // LINK_HOLD = "LINK_HOLD",
  BUSINESS_ACCOUNT = "BUSINESS_ACCOUNT",
}

export interface MerchantOperation {
  id: string
  ledgerId: string
  ledgerType: LedgerRegistryType
  amount: number
  creditorId: string
  creditorName?: string
  debtorId?: string
  debtorName?: string
  reference?: string
  flow: LedgerMovementFlow
  operationType: OperationType
  createdAt: string
  isExternalTransfer: boolean
  justificativePath: string | null
  ledgerMovementId: string
}

export enum ProductType {
  BUSINESS_ACCOUNT = "BUSINESS_ACCOUNT",
  ISSUING = "ISSUING",
}

export const getProductTypeFromFlow = (flow: LedgerMovementFlow): ProductType => {
  if (flow === LedgerMovementFlow.ISSUING_RESERVATION || flow === LedgerMovementFlow.ISSUING_REFUND) {
    return ProductType.ISSUING
  }
  return ProductType.BUSINESS_ACCOUNT
}
