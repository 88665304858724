import { useEffect, useState } from "react"

const useLocalStorage = (key: string, defaultValue: string | boolean) => {
  const [value, setValue] = useState(() => {
    let currentValue

    try {
      currentValue = JSON.parse(localStorage.getItem(key) || String(defaultValue))
    } catch (error) {
      currentValue = defaultValue
    }

    return currentValue
  })

  useEffect(() => {
    if (value !== null) {
      localStorage.setItem(key, JSON.stringify(value))
    } else {
      localStorage.removeItem(key)
    }
  }, [value, key])

  const remove = () => {
    setValue(null)
  }

  return [value, setValue, remove]
}

export default useLocalStorage
