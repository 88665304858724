/* eslint-disable i18next/no-literal-string */
import { Field, FieldSelect, Typography } from "@hero/krypton"
import { ChangeEvent } from "react"
import { Controller } from "react-hook-form"
import styled from "styled-components"
import { useDashboardTranslation } from "../../../../01_technical/translations"
import { Beneficiary } from "../requests/useGetBeneficiaries"
import { useCwtApplicationFormContext } from "./ApplicationForm.utils"

interface BeneficiaryFormSectionProps {
  setCreateBeneficiaryModalIsOpen: (isOpen: boolean) => void
  beneficiaries: Beneficiary[]
}

export const BeneficiaryFormSection = ({
  setCreateBeneficiaryModalIsOpen,
  beneficiaries,
}: BeneficiaryFormSectionProps) => {
  const { t } = useDashboardTranslation()
  const { register, formState, control } = useCwtApplicationFormContext()

  const defaultMerchantBeneficiary = {
    id: "",
    label: t("creditWireTransfer.applicationForm.chooseBeneficiary"),
  }

  return (
    <>
      <Typography $variant="title-2-bold">{t("creditWireTransfer.applicationForm.beneficiarySectionTitle")}</Typography>

      <Spacer $h={"1rem"} />

      <Controller
        control={control}
        name="beneficiary.id"
        render={({ field: { value, onBlur, onChange }, fieldState }) => {
          return (
            <FieldSelect
              $fullWidth
              fieldLabel={t("creditWireTransfer.applicationForm.beneficiaryLabel")}
              value={value}
              errorMessage={fieldState.error?.message}
              onBlur={onBlur}
              onChange={(e: ChangeEvent<HTMLSelectElement>) => {
                if (e.target.value === "CREATE_NEW") {
                  setCreateBeneficiaryModalIsOpen(true)
                  return
                }
                onChange(e)
              }}
            >
              <option value="">{defaultMerchantBeneficiary.label}</option>
              <option value="CREATE_NEW">{t("creditWireTransfer.applicationForm.addBeneficiaryOption")}</option>
              {beneficiaries.map(({ id, label }) => (
                <option key={id} value={id}>
                  {label}
                </option>
              ))}
            </FieldSelect>
          )
        }}
      />

      <Spacer $h={"1rem"} />

      <Typography $variant="body-3-semibold">
        {t("creditWireTransfer.applicationForm.complementaryBeneficiaryInformation")}
      </Typography>

      <Spacer $h={"1rem"} />

      <Row>
        <Field
          $fullWidth
          fieldLabel={t("creditWireTransfer.applicationForm.recipientEmailLabel")}
          type="text"
          {...register("beneficiary.email")}
          errorMessage={formState.errors?.beneficiary?.email?.message}
        />

        <Controller
          control={control}
          name="beneficiary.phone"
          render={({ field, fieldState: { error } }) => (
            <Field
              {...field}
              $fullWidth
              aria-invalid={Boolean(error?.message)}
              type="tel"
              fieldLabel={t("creditWireTransfer.applicationForm.phone")}
              defaultCountry="FR"
              errorMessage={error?.message}
            />
          )}
        />
      </Row>

      <Field
        $fullWidth
        fieldLabel={t("creditWireTransfer.applicationForm.siret")}
        type="text"
        {...register("beneficiary.siret")}
        errorMessage={formState.errors?.beneficiary?.siret?.message}
      />
      <Field
        $fullWidth
        fieldLabel={t("creditWireTransfer.applicationForm.headquartersCountry")}
        type="text"
        {...register("beneficiary.headquartersCountry")}
        errorMessage={formState.errors?.beneficiary?.headquartersCountry?.message}
      />
    </>
  )
}

const Spacer = styled.div<{ $h: string }>`
  height: ${({ $h }) => $h};
`

const Row = styled.div`
  display: flex;
  gap: 1rem;

  & > * {
    flex: 1;
  }
`
