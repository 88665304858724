import { gql } from "@apollo/client"
import { IssuingCard } from "../00_shared/business/IssuingCard"
import { useHeroQuery } from "../../../01_technical/requesting/useHeroQuery/useHeroQuery"

const GET_MERCHANT_OPERATIONS = gql`
  query listIssuingCards($limitPerPage: Int = 60) {
    listCards(pagination: { limit: $limitPerPage, page: 1 }) {
      ... on ListIssuedCardsResult {
        cards {
          id
          merchantId
          businessAccountId
          issuanceType
          status
          expiration
          lastCardDigits
          displayLastCardDigits
          allowContactless
          allowEcommerce
          allowOtherCurrency
          cardholderName
          monthlySpendingLimitAmount
          consumedMonthlySpendingLimitAmount
          monthlySpendingLimitAmountEuros
          consumedMonthlySpendingLimitAmountEuros
          paymentAccountName
          pending3dsChallengeId
          formattedOrderedDate
          formattedProductionDate
          formattedReceptionDate
          isDeliveryLate
          notReceivedDeclarationDate
        }
        total
      }
      ... on SimpleApiError {
        errorCode
      }
    }
  }
`

export const useIssuingCardListHook = () => {
  const { data, loading, error, refetch } = useHeroQuery<{ cards: IssuingCard[] }>({
    gqlQuerySchema: GET_MERCHANT_OPERATIONS,
    fetchPolicy: "cache-and-network",
  })

  return {
    cards: data?.cards || [],
    loading,
    error,
    refetch,
  }
}
