import { useEffect, useState } from "react"

/**
 * Create a hook that will redirect to a given url after a given delay and will return a redirecting state and a function to start the redirect
 */

export const useDelayRedirect = (url: string, delay: number) => {
  const [redirecting, setRedirecting] = useState(false)
  const [redirect, setRedirect] = useState(false)

  useEffect(() => {
    if (!redirect) {
      return
    }

    setRedirecting(true)

    const timeout = setTimeout(() => {
      window.location.href = url
    }, delay)

    return () => {
      clearTimeout(timeout)
    }
  }, [redirect, url, delay])

  const startRedirect = () => {
    setRedirect(true)
  }

  return {
    redirecting,
    startRedirect,
  }
}
