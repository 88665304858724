import { gql } from "@apollo/client"
import { useHeroMutation } from "../../../../01_technical/requesting/useHeroMutation/useHeroMutation"

const DEACTIVATE_CARD = gql`
  mutation DeactivateCard($cardId: String!) {
    deactivateIssuingCard(cardId: $cardId) {
      ... on DeactivateIssuingCardOutput {
        success
      }
      ... on GqlHeroError {
        errorCode
        message
      }
      ... on ValidationErrors {
        validationErrors {
          path
          validationError
        }
      }
    }
  }
`

interface DeactivateCardMutationResult {
  success?: boolean
  message?: string
}

interface DeactivateCardMutationVariables {
  cardId: string
}

export const useTerminateCard = () => {
  const [mutate, { data, loading, error }] = useHeroMutation<
    DeactivateCardMutationResult,
    DeactivateCardMutationVariables
  >({
    gqlQuerySchema: DEACTIVATE_CARD,
  })

  const terminateCard = async (cardId: string): Promise<DeactivateCardMutationResult | undefined> => {
    try {
      const response = await mutate({
        variables: {
          cardId,
        },
      })
      return response
    } catch (err) {}
  }

  return {
    terminateCard,
    data,
    loading,
    error,
  }
}
