import { gql } from "@apollo/client"

type OutstandingByReference = {
  email: string
  siren?: string
  reference: string
  maxOutstandingLimit: number
  paymentsCount: number
  outstandingAmount: number
}

type OutstandingBySiren = {
  email: string
  siren: string
  reference?: string
  maxOutstandingLimit: number
  paymentsCount: number
  outstandingAmount: number
}

export type CustomersOutstandingOutput = {
  merchantCustomersOutstandingByMerchant: {
    customersOutstandingLimit: OutstandingByReference[] | OutstandingBySiren[]
    paginationInfo: {
      totalPages: number
      pageNumber: number
    }
  }
}

export type CustomersOutstandingInput = {
  search: string | null
  pageNumber: number | undefined
}

export const GET_CUSTOMERS_OUTSTANDING = gql`
  query merchantCustomersOutstandingByMerchant($pageNumber: Int, $search: String) {
    merchantCustomersOutstandingByMerchant(search: $search, pageNumber: $pageNumber) {
      customersOutstandingLimit {
        email
        siren
        reference
        maxOutstandingLimit
        paymentsCount
        outstandingAmount
      }
      paginationInfo {
        totalPages
        pageNumber
      }
    }
  }
`
