import { useEffect } from "react"
import { useLocation } from "react-router-dom"

export const useRouteListener = (onRouteChange: (path: string) => void) => {
  const location = useLocation()

  useEffect(() => {
    onRouteChange(location.pathname)
  }, [location.pathname, onRouteChange])
}
