import styled from "styled-components"
import { AccountVerificationBanner } from "../../../../00_shared/components/AccountVerificationBanner"
import { TransfersHeader } from "./TransfersHeader"

const LayoutContainer = styled.div`
  display: flex;
  flex-direction: column;
  @media (min-width: 768px) {
    height: calc(100vh - 0.125rem);
  }
  @media (max-width: 768px) {
    flex: 1;
    display: flex;
    flex-direction: column;
  }
  background-color: ${({ theme }) => theme.colors.white};
`

const MainContent = styled.div`
  padding: 1.5rem 3rem;
  min-height: 0;

  @media (max-width: 768px) {
    padding: 0;
  }

  @media (max-width: 768px) {
    padding: 0;
    display: flex;
    flex-direction: column;
    flex: 1;
  }
`

export const TransfersLayout = ({ children }: { children: React.ReactNode }) => {
  return (
    <LayoutContainer>
      <AccountVerificationBanner />
      <TransfersHeader />
      <MainContent id="mainContent">{children}</MainContent>
    </LayoutContainer>
  )
}
