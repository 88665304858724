import { Typography } from "@hero/krypton"
import styled from "styled-components"
import { IssuingCard } from "../../00_shared/business/IssuingCard"

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  height: fit-content;
  gap: 4px;
`

const ProgressBar = styled.div`
  width: 180px;
  height: 4px;
  border-radius: 4px;
  background-color: #e9eaf0;
`

const Progress = styled.div<{ $percent: number }>`
  width: ${(props) => Math.max(0, Math.min(100, props.$percent)) + "%"};
  max-width: 180px;
  height: 4px;
  border-radius: 4px;
  background-color: #1d1e21;
`

interface IssuingCardSmallSpendingProps {
  card: IssuingCard
}

export const IssuingCardSmallSpending = ({ card }: IssuingCardSmallSpendingProps) => {
  return (
    <Content>
      <Typography $variant="caption-2">
        {card.consumedMonthlySpendingLimitAmountEuros} / {card.monthlySpendingLimitAmountEuros}
      </Typography>
      <ProgressBar>
        <Progress $percent={(card.consumedMonthlySpendingLimitAmount / card.monthlySpendingLimitAmount) * 100} />
      </ProgressBar>
    </Content>
  )
}
