import { useHeroQuery } from "../../../../../01_technical/requesting/useHeroQuery/useHeroQuery"
import { balanceIncomingFakeData } from "../../BalanceIncomingTable/balanceIncomingFakeData"
import {
  GET_INCOMING_TRANSFER_DETAILS,
  GetIncomingTransferDetailsSuccessResponse,
} from "./IncomingTransferDetails.requests"

export const useGetIncomingTransferDetails = (
  filters: { swanTransactionId?: string },
  config: {
    useDemoData: boolean
  } = { useDemoData: false },
) => {
  const { data, error, loading } = useHeroQuery<GetIncomingTransferDetailsSuccessResponse>({
    gqlQuerySchema: GET_INCOMING_TRANSFER_DETAILS,
    variables: filters,
    skip: config.useDemoData || !filters.swanTransactionId,
    fetchPolicy: "network-only",
  })

  if (config.useDemoData) {
    return {
      data: getIncomingTransferDetailsFakeData(filters.swanTransactionId),
      error: undefined,
      loading: false,
    }
  }

  return { data, error, loading }
}

const getIncomingTransferDetailsFakeData = (swanTransactionId?: string): GetIncomingTransferDetailsSuccessResponse => {
  const incomingTransfer = balanceIncomingFakeData.find((transfer) => transfer.swanTransactionId === swanTransactionId)

  if (!incomingTransfer) {
    throw new Error("Incoming transfer not found")
  }

  return {
    ...incomingTransfer,
    reference: "",
    label: `Transfer from ${incomingTransfer.marketplaceName.toLowerCase()}`,
    executionDate: new Date().toISOString(),
    allocationDate: new Date().toISOString(),
    creditorIban: "FR8712739000502473441511N13",
    balanceBeforeAndAfterMovements: [
      {
        isRefund: false,
        balanceBefore: 1000_00,
        balanceAfter: 1000_00 + incomingTransfer.amount,
        movement: {
          id: "acctmov-69816dfe-ef98-4187-8592-d5c99bb32fcb",
          label: "label",
          amount: incomingTransfer.amount,
          createdAt: new Date().toISOString(),
        },
      },
    ],
  }
}
