import { useQuery } from "@apollo/client"
import { unwrapGraphQLResponse } from "../../../../01_technical/requesting/DEPRECATED_graphql.errors"
import { GET_KPIS, GET_KPIS_ARGS, GET_KPIS_RESPONSE, GET_KPIS_RESPONSE_SUCCESS } from "../cockpitWithNewKPI.requests"

export const useGetKpis = (args: { accountId?: string }, config: { useDemoData: boolean }) => {
  const { data, loading, error } = useQuery<GET_KPIS_RESPONSE, GET_KPIS_ARGS>(GET_KPIS, {
    variables: {
      accountId: args.accountId,
    },
    skip: config.useDemoData,
  })

  if (config.useDemoData) {
    return {
      kpis: getKpis(args.accountId),
      getKpisLoading: false,
      getKpisTechnicalError: undefined,
      getKpisFunctionalError: undefined,
    }
  }

  const { error: getKpisFunctionalError, data: kpis } = unwrapGraphQLResponse<GET_KPIS_RESPONSE_SUCCESS>(
    data?.merchantGetAcceleratedPayoutKPIs,
  )

  return {
    kpis,
    getKpisLoading: loading,
    getKpisTechnicalError: error,
    getKpisFunctionalError,
  }
}

function getKpis(accountId?: string) {
  const AMAZON_ID = "account-41988044-aefe-49f5-b1dc-338eba376589"
  const LEROY_MERLIN_ID = "account-2be825a0-a9dd-4d1e-b86d-da20c83c580e"

  if (accountId === AMAZON_ID) {
    return {
      totalPendingApAmount: 20200_00,
      totalApFundedAmount: 296600_00,
      totalApBankTransferAmount: 355400_00,
    }
  }

  if (accountId === LEROY_MERLIN_ID) {
    return {
      totalPendingApAmount: 66368_84,
      totalApFundedAmount: 312247_63,
      totalApBankTransferAmount: 361813_05,
    }
  }

  return {
    totalPendingApAmount: 86568_84,
    totalApFundedAmount: 608847_63,
    totalApBankTransferAmount: 717213_05,
  }
}
