import { gql, useQuery } from "@apollo/client"
import { TransactionFlowType, TransactionStatus } from "../../../business/enums/Transaction.enum"
import { fakeMerchantTransactions } from "./demo/fakeMerchantTransaction"

export type Transaction = {
  id: string
  flowType: TransactionFlowType
  orderID?: string
  customerEmail: string
  createdAt: string
  amount: number
  status: TransactionStatus
}

type PaginationInput = {
  pageSize: number
  pageNumber: number
}

type SearchFilterInput = {
  search: string
}

type GetMerchantTransactionsQueryType = {
  merchantTransactions: {
    transactions: Transaction[]
    totalCountTransactions: number
  }
}

type GetMerchantTransactionsQueryArgsType = {
  pagination: PaginationInput
  filter: SearchFilterInput
}

export const GET_MERCHANT_TRANSACTIONS = gql`
  query merchantTransactions($pagination: PaginationInput!, $filter: SearchFilterInput!) {
    merchantTransactions(pagination: $pagination, filter: $filter) {
      transactions {
        id
        flowType
        orderID
        customerEmail
        createdAt
        amount
        status
      }
      totalCountTransactions
    }
  }
`

export type GetMerchantResponse = {
  getmerchant: {
    canShowCockpit: boolean
  }
}

export const GET_MERCHANT_QUERY = gql`
  query {
    getmerchant {
      canShowCockpit
    }
  }
`

export const useGetMerchantTransactions = (
  pagination: { pageSize: number; pageNumber: number; search?: string },
  filter: { search: string },
  config: { useDemoData: boolean },
) => {
  const { data, error, loading, previousData } = useQuery<
    GetMerchantTransactionsQueryType,
    GetMerchantTransactionsQueryArgsType
  >(GET_MERCHANT_TRANSACTIONS, {
    variables: {
      pagination,
      filter,
    },
    fetchPolicy: "network-only",
    skip: config.useDemoData,
  })

  if (config.useDemoData) {
    const getDateFromToday = (days: string) => {
      return new Date(Date.now() + Number(days) * 24 * 60 * 60 * 1000).toISOString()
    }

    return {
      merchantTransactions: fakeMerchantTransactions.map((transaction) => ({
        ...transaction,
        createdAt: getDateFromToday(transaction.createdAt),
      })),
      totalMerchantTransactions: fakeMerchantTransactions.length,
      totalPages: 1,
      previousData: null,
      loading: false,
      error: undefined,
    }
  }

  // prettier-ignore
  const { transactions, totalCountTransactions } =
    data?.merchantTransactions
    ?? previousData?.merchantTransactions
    ?? { transactions: [], totalCountTransactions: 0 }

  return {
    merchantTransactions: transactions,
    totalMerchantTransactions: totalCountTransactions,
    totalPages: Math.ceil(totalCountTransactions / pagination.pageSize),
    previousData,
    loading,
    error,
  }
}
