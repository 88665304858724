import { createContext, useContext, useEffect, useState } from "react"
import { useAuthContext } from "../../Auth/auth.context"
import { useBusinessAccountContext } from "../../Merchant/BusinessAccount/businessAccount.context"
import { useIsFFBOV3Active } from "../hooks/useFeatureFlag.hook"
import useLocalStorage from "../hooks/useLocalStorage.hook"

type BOV3ContextType = {
  isBOV3Active: boolean
  isToggleButtonActive: boolean
  toggleBOVersion: () => void
  clearBOV3State: () => void
}

const BOV3Context = createContext<BOV3ContextType>({} as BOV3ContextType)

export const useBOV3Context = () => useContext(BOV3Context)

export const BOV3Provider: React.FC<{ children: JSX.Element | JSX.Element[] }> = ({ children }) => {
  const { isBaActive: isNewEraMerchant } = useBusinessAccountContext()
  const { canShowAPPages, canShowCockpit } = useAuthContext().currentUser
  const legacyMerchant = canShowAPPages || canShowCockpit
  const BOV3FeatureFlagActive = useIsFFBOV3Active()

  const getBOV3DefaultValue = () => {
    if (!BOV3FeatureFlagActive) {
      return false
    }

    if (legacyMerchant) {
      return false
    }

    return true
  }

  const [isBOV3LocalStorActive, setIsBOV3InLocalStorage, removeBOV3FromLocalStorage] = useLocalStorage(
    "BO_V3",
    getBOV3DefaultValue(),
  )
  const [isBOV3Active, setIsBOV3] = useState(Boolean(isBOV3LocalStorActive))

  useEffect(() => {
    setIsBOV3(Boolean(isBOV3LocalStorActive))
  }, [isBOV3LocalStorActive])

  const toggleBOVersion = () => {
    setIsBOV3InLocalStorage(!Boolean(isBOV3LocalStorActive))
  }

  const isToggleButtonActive = () => {
    if (!BOV3FeatureFlagActive) {
      return false
    }

    if (!legacyMerchant) {
      return false
    }

    if (!isNewEraMerchant) {
      return false
    }

    return true
  }

  return (
    <BOV3Context.Provider
      value={{
        isBOV3Active: isBOV3Active,
        isToggleButtonActive: isToggleButtonActive(),
        toggleBOVersion,
        clearBOV3State: removeBOV3FromLocalStorage,
      }}
    >
      {children}
    </BOV3Context.Provider>
  )
}
