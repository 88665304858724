import { useQuery } from "@apollo/client"
import {
  FieldInput,
  Header,
  InfoIcon,
  Pagination,
  Spinner,
  Table,
  TableSectionHeader,
  TBody,
  THead,
  Typography,
  TypographyVariant,
} from "@hero/krypton"
import { ChangeEvent, useEffect } from "react"
import { useForm } from "react-hook-form"
import styled from "styled-components"
import { useQuerySearchParams } from "../../../00_shared/hooks/useQuerySearchParams.hook"
import { toKEuros } from "../../../00_shared/utils/currency.converter"
import { debounce } from "../../../00_shared/utils/debounce"
import { TableCellLink } from "../../../01_technical/tableCellLink"
import { useDashboardTranslation } from "../../../01_technical/translations"
import { HEADER_COLORS } from "../../../env_variables"
import { ApiErrors } from "../../../Legacy/components/ApiErrors"
import { CustomersOutstandingInput, CustomersOutstandingOutput, GET_CUSTOMERS_OUTSTANDING } from "./clients.requests"
import { NoClientsPage } from "./components/NoClientsPage"
import { ProgressBar } from "./components/progressBar"

const CaptionAvailableOutstandingTypography = styled(Typography)<{ $variant?: TypographyVariant }>`
  color: ${(props) => props.theme.colors.grey.$500};
`

const THWithTooltip = styled.th`
  display: flex;
  align-items: center;
`

const NoClientsRow = styled.td`
  text-align: center;
`

const TableSection = styled.section`
  border: 1px solid ${({ theme }) => theme.colors.grey.$200};
  border-bottom: none;
  background-color: ${({ theme }) => theme.colors.white};
  width: 100%;
`

const MainContent = styled(TableSection)`
  margin: 1.5rem 0;
  overflow-x: auto;
`

const StyledTypography = styled(Typography)`
  display: flex;
  align-items: center;
`

const ProgressContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const CustomPagination = styled(Pagination)`
  margin-top: 1.5rem;
`

type SearchParams = {
  pageNumber: string
  search?: string
}

export const ClientsScreen: React.FC = () => {
  const { getSearchParam, setSearchParam } = useQuerySearchParams<SearchParams>()
  const { t } = useDashboardTranslation()

  const searchParams: SearchParams = {
    pageNumber: getSearchParam("pageNumber"),
    search: getSearchParam("search") || undefined,
  }
  const { pageNumber: pageNumberString, search } = searchParams

  useEffect(() => {
    if (!pageNumberString) {
      setSearchParam({ pageNumber: "1" })
    }
  }, [pageNumberString, setSearchParam])

  const pageNumber = isNaN(Number(pageNumberString)) ? 1 : Number(pageNumberString)

  const { loading, error, data } = useQuery<CustomersOutstandingOutput, CustomersOutstandingInput>(
    GET_CUSTOMERS_OUTSTANDING,
    { variables: { search: search ?? "", pageNumber } },
  )

  const { register, handleSubmit } = useForm<{ search: string }>({
    mode: "onChange",
    defaultValues: {
      search,
    },
  })

  const searchRegister = register("search")

  const onSubmit = debounce(({ search }: { search: string }) => {
    setSearchParam({ pageNumber: "1", search: search || undefined })
  })

  const customers = data?.merchantCustomersOutstandingByMerchant?.customersOutstandingLimit ?? []
  const totalPages = data?.merchantCustomersOutstandingByMerchant?.paginationInfo.totalPages ?? 0

  if (loading) {
    return <Spinner />
  }

  return (
    <div data-test-id="clients-screen">
      {!customers.length && !search ? (
        <NoClientsPage />
      ) : (
        <>
          <Header $colors={HEADER_COLORS}>
            <Typography as="h1" $variant="title-2-bold">
              {t("checkoutAndCash.clients.title")}
            </Typography>
            <Typography $variant="body-3-medium">{t("checkoutAndCash.clients.subtitle")}</Typography>
          </Header>
          <div>
            <MainContent>
              <TableSectionHeader>
                <StyledTypography $variant="body-2-semibold">
                  {t("checkoutAndCash.clients.table.count", { count: customers.length })}
                </StyledTypography>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <FieldInput
                    data-test-id="client-search"
                    type="search"
                    placeholder={t("checkoutAndCash.clients.table.searchPlaceholder")}
                    {...searchRegister}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                      searchRegister.onChange(e)
                      onSubmit({ search: e.target.value })
                    }}
                  />
                </form>
              </TableSectionHeader>

              <Table>
                <THead>
                  <tr>
                    <th key="0">{t("checkoutAndCash.clients.table.header.reference")}</th>
                    <th key="1">{t("checkoutAndCash.clients.table.header.siret")}</th>
                    <th key="2">{t("checkoutAndCash.clients.table.header.email")}</th>
                    <THWithTooltip key="3" title={t("checkoutAndCash.clients.table.header.consumedOutstandingTooltip")}>
                      <CaptionAvailableOutstandingTypography $variant="label-1">
                        {t("checkoutAndCash.clients.table.header.consumedOutstanding")}
                      </CaptionAvailableOutstandingTypography>
                      <InfoIcon width="1rem" height="1rem" />
                    </THWithTooltip>
                    <th key="4">{t("checkoutAndCash.clients.table.header.totalTransactions")}</th>
                  </tr>
                </THead>
                <TBody $clickable={!!customers.length}>
                  {customers.length ? (
                    customers.map(
                      ({ email, maxOutstandingLimit, outstandingAmount, paymentsCount, reference, siren }) => {
                        const referenceOrSiren = reference ?? siren

                        if (!referenceOrSiren) {
                          return <tr></tr>
                        }

                        const transactionUrl = `/checkout_cash/clients/${encodeURIComponent(
                          referenceOrSiren,
                        )}/transactions`

                        return (
                          <tr key={referenceOrSiren}>
                            <td>
                              <TableCellLink to={transactionUrl} />
                              {reference}
                            </td>
                            <td>
                              <TableCellLink to={transactionUrl} />
                              {siren}
                            </td>
                            <td>
                              <TableCellLink to={transactionUrl} />
                              {email}
                            </td>
                            <td>
                              <TableCellLink to={transactionUrl} />
                              <ProgressContainer>
                                {toKEuros(outstandingAmount)} / {toKEuros(maxOutstandingLimit)}
                                <ProgressBar current={outstandingAmount} max={maxOutstandingLimit} />
                              </ProgressContainer>
                            </td>
                            <td>
                              <TableCellLink to={transactionUrl} />
                              {t("checkoutAndCash.clients.table.paymentsCount", { count: paymentsCount })}
                            </td>
                          </tr>
                        )
                      },
                    )
                  ) : (
                    <tr>
                      <NoClientsRow colSpan={5}>{t("checkoutAndCash.clients.table.noResults")}</NoClientsRow>
                    </tr>
                  )}
                </TBody>
              </Table>
              <ApiErrors err={error} />
            </MainContent>
            <CustomPagination
              currentPage={pageNumber}
              totalPages={totalPages || 1}
              onPageChange={(pageNumber) => setSearchParam({ ...searchParams, pageNumber: pageNumber.toString() })}
            />
          </div>
        </>
      )}
    </div>
  )
}
