import { gql } from "@apollo/client"
import { useHeroMutation } from "../../../../../01_technical/requesting/useHeroMutation/useHeroMutation"

const UPDATE_OPERATION_METADATA = gql`
  mutation UpdateMerchantOperationMetadata($operationId: String!, $metadata: UpdateMerchantOperationMetadataInput!) {
    UpdateMerchantOperationMetadata(operationId: $operationId, metadata: $metadata) {
      ... on UpdateMerchantOperationMetadataOutput {
        success
      }
      ... on GqlHeroError {
        errorCode
        message
      }
      ... on ValidationErrors {
        validationErrors {
          path
          validationError
        }
      }
    }
  }
`

type UpdateMerchantOperationMetadataOutput = {
  success: boolean
}

type UpdateMerchantOperationMetadataInput = {
  operationId: string
  metadata: {
    note?: string | null
    justificativeId?: string | null
  }
}

export const useUpdateOperationMetadata = () => {
  const [mutate, { data, loading, error }] = useHeroMutation<
    UpdateMerchantOperationMetadataOutput,
    UpdateMerchantOperationMetadataInput
  >({
    gqlQuerySchema: UPDATE_OPERATION_METADATA,
  })

  const updateOperationMetadata = (input: UpdateMerchantOperationMetadataInput) => {
    return mutate({ variables: input })
  }

  return { updateOperationMetadata, loading, error, data }
}
