import { useGetBnplKpis } from "../List.requests"
import styled from "styled-components"
import { Typography } from "@hero/krypton"
import { Skeleton } from "../../../../00_shared/components/Skeleton"
import { toKEuros } from "../../../../00_shared/utils/currency.converter"
import { useDashboardTranslation } from "../../../../01_technical/translations"

const KPI = styled.div`
  display: flex;
  flex-direction: column;
`

const Container = styled.div`
  width: 40rem;
`

const KPISContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  gap: 1.25rem;
  flex-wrap: wrap;

  & > div {
    position: relative;
    flex: 1;
    background-color: ${({ theme }) => theme.colors.white};
    padding: 1rem 1.5rem;
    border-radius: 1rem;
    border: 1px solid ${({ theme }) => theme.colors.grey.$300};
    box-shadow: ${({ theme }) => theme.shadows.mid};
  }
`

export const CollectionKPIs = () => {
  const { loading, data } = useGetBnplKpis()
  const { t } = useDashboardTranslation()

  return (
    <Container>
      <KPISContainer>
        <KPI>
          <Typography $variant="caption-2">{t("collection.kpis.available")}</Typography>
          {loading || !data ? (
            <Skeleton height="1.75rem" width="10rem" />
          ) : (
            <Typography $variant="body-2-semibold">{toKEuros(data.total)}</Typography>
          )}
        </KPI>
        <KPI>
          <Typography $variant="caption-2">{t("collection.kpis.total")}</Typography>
          {loading || !data ? (
            <Skeleton height="1.75rem" width="10rem" />
          ) : (
            <Typography $variant="body-2-semibold">{toKEuros(data.available)}</Typography>
          )}
        </KPI>
      </KPISContainer>
    </Container>
  )
}
