import { RouteObject } from "react-router-dom"

import { BOV2RouteProtector } from "../../../App.router"
import { CockpitScreen } from "./cockpit.screen"

export const cockpitRoute: RouteObject = {
  path: "cockpit/*",
  element: (
    <BOV2RouteProtector>
      <CockpitScreen />
    </BOV2RouteProtector>
  ),
}
