import { gql } from "@apollo/client"
import { useMutationWith2fa } from "../../../../Auth/Challenge2fa/useMutationWith2fa"

type IssuingCardSetMonthlySpendingLimitsArgs = {
  cardId: string
  monthlySpendingLimitAmount: number
}

type IssuingCardSetMonthlySpendingLimitsSuccess = {
  setIssuingCardMonthlySpendingLimits: {
    success: boolean
  }
}

type IssuingCardSetMonthlySpendingLimitsFunctionalError = {
  setIssuingCardMonthlySpendingLimits: {
    errorCode: string
    errorValue: string
  }
}

type IssuingCardSetMonthlySpendingLimits2faFunctionalError = {
  setIssuingCardMonthlySpendingLimits: {
    errorCode: string
    message: string
    detail: string
  }
}

type IssuingCardSetMonthlySpendingLimitsResponse =
  | IssuingCardSetMonthlySpendingLimitsSuccess
  | IssuingCardSetMonthlySpendingLimitsFunctionalError
  | IssuingCardSetMonthlySpendingLimits2faFunctionalError

const ISSUING_CARD_SET_MONTHLY_SPENDING_LIMITS = gql`
  mutation setMonthlySpendingLimits($monthlySpendingLimitAmount: Int!, $cardId: String!) {
    setIssuingCardMonthlySpendingLimits(monthlySpendingLimitAmount: $monthlySpendingLimitAmount, cardId: $cardId) {
      ... on SetIssuingCardMonthlySpendingLimitsOutput {
        success
        __typename
      }
      ... on ValidationErrors {
        errorCode
      }
      ... on GqlHeroError {
        errorCode
        message
      }
    }
  }
`

export const useCardSetMonthlySpendingAmountLimitHook = () => {
  const [setMonthlySpendingLimits, { loading }] = useMutationWith2fa<
    IssuingCardSetMonthlySpendingLimitsResponse,
    IssuingCardSetMonthlySpendingLimitsArgs
  >(ISSUING_CARD_SET_MONTHLY_SPENDING_LIMITS)

  return {
    setMonthlySpendingLimits,
    loading,
  }
}
