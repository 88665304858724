import { zodResolver } from "@hookform/resolvers/zod"
import { useForm, useFormContext } from "react-hook-form"
import * as z from "zod"
import { EmailSchema, PhoneSchema, StringSchema } from "../../../../00_shared/utils/commonZodSchemas.utils"
import { CommonTFunction, useCommonTranslation } from "../../../../01_technical/translations"
import { useCallback } from "react"
import { NavigateOptions, useNavigate } from "react-router-dom"

const getApplicationFormSchema = (t: CommonTFunction) => {
  return z.object({
    amount: z
      .number({ message: t("formValidation.common.invalid") })
      .min(0.1, { message: t("formValidation.number.valueTooSmall", { min: 0.1 }) })
      .max(10000, { message: t("formValidation.number.valueTooLarge", { max: 10000 }) }),
    reference: StringSchema(t),
    paymentFeeConfigurationsIds: z
      .array(z.string())
      .min(1, { message: "bnpl.link.wizard.steps.demand.missingPaymentType" }),
    customer: z
      .object({
        isIndividual: z.boolean().optional(),
        id: StringSchema(t).optional(),
        siret: StringSchema(t, 0).optional(),
        name: StringSchema(t),
        email: EmailSchema(t),
        phone: PhoneSchema(t),
      })
      .refine(
        (data) => {
          if (data.isIndividual) {
            return true
          }
          return data.siret?.length === 14
        },
        {
          message: t("formValidation.string.notExactLength", { length: 14 }),
          path: ["siret"],
        },
      ),
    address: z.object({
      line1: StringSchema(t),
      city: StringSchema(t),
      zipCode: StringSchema(t),
    }),
    note: StringSchema(t).nullable(),
    informClientEmail: EmailSchema(t).nullable(),
    documentPath: StringSchema(t, 0).nullable(),
  })
}

export const useCreateLinkForm = ({ paymentFeeConfigurationsIds }: { paymentFeeConfigurationsIds: string[] }) => {
  const { t } = useCommonTranslation()
  const applicationFormSchema = getApplicationFormSchema(t)
  type SchemaType = z.infer<typeof applicationFormSchema>
  return useForm<SchemaType>({
    resolver: zodResolver(applicationFormSchema),
    defaultValues: {
      amount: 0,
      paymentFeeConfigurationsIds,
      documentPath: undefined,
      customer: {
        name: "",
        isIndividual: undefined,
        email: "",
        phone: "",
        siret: "",
      },
      address: {
        line1: "",
        city: "",
        zipCode: "",
      },
    },
  })
}

export const useCreateLinkFormContext = () => {
  const { t } = useCommonTranslation()
  const applicationFormSchema = getApplicationFormSchema(t)
  type SchemaType = z.infer<typeof applicationFormSchema>
  return useFormContext<SchemaType>()
}

export const useNavigateWithScrolling = () => {
  const navigate = useNavigate()

  return useCallback(
    (to: string, options: NavigateOptions = {}) => {
      window.scrollTo(0, 0)
      navigate(to, options)
    },
    [navigate],
  )
}
