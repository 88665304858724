import { Separator, Typography } from "@hero/krypton"
import { ReactNode } from "react"
import styled from "styled-components"

const Section = styled.div`
  margin-top: 2.5rem;
`

const Divider = styled(Separator)`
  height: 1px;
`

const SpacedDivider = styled(Divider)`
  margin: 1rem 0;
`

export const TransferDetailsSection: React.FC<{
  title: string
  children: ReactNode
  testId?: string
}> = ({ title, testId, children }) => (
  <Section data-test-id={testId}>
    <Typography $variant="body-2-semibold">{title}</Typography>
    <SpacedDivider />
    {children}
  </Section>
)
