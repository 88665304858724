import { gql } from "@apollo/client"

import { APMovementCategory, APStatus } from "../../../../business/enums/AP.enum"

export type GetAcceleratedPayoutResult = {
  id: string
  createdAt: string
  closingDate?: string
  fundedAmount: number
  financingRate?: number
  status: APStatus
  feesAmount: number
  CA?: number
  periodeDuCAStart: string
  periodeDuCAEnd: string
  marketplace: {
    logoUrl: string | null
    name: string
  }
  movements: {
    id: string
    category: APMovementCategory
    amount: number
    balanceAfter?: number
    createdAt: string
  }[]
  orders: { orderNumber: string }[]
  account?: {
    name: string
    number: string
  }
}

export const GET_ACCELERATED_PAYOUT = gql`
  query getAcceleratedPayout($acceleratedPayoutId: String!) {
    getAcceleratedPayout(id: $acceleratedPayoutId) {
      ... on GetAcceleratedPayoutDetailsOutput {
        id
        createdAt
        closingDate
        fundedAmount
        financingRate
        status
        feesAmount
        CA
        periodeDuCAStart
        periodeDuCAEnd
        marketplace {
          name
          logoUrl
        }
        account {
          name
          number
        }
        movements {
          id
          category
          amount
          balanceAfter
          createdAt
        }
        orders {
          orderNumber
        }
      }
      ... on GqlHeroError {
        errorCode
        message
      }
      ... on ValidationErrors {
        validationErrors {
          path
          validationError
        }
      }
    }
  }
`
