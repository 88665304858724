import { AmountByMarketplace, BarChartDataType, GET_BAR_CHART_RESPONSE_SUCCESS } from "../cockpitWithNewKPI.requests"
import { demoApBarChartDataBase } from "./demoApBarChart.data"

const addDayToDate = (date: Date, days: number) => {
  return new Date(date.getTime() + days * 1000 * 60 * 60 * 24)
}

const relativeToDate = (object: {
  createdDate: number
  dayTotal: number | null
  amountByMarketplace: AmountByMarketplace[]
}): BarChartDataType => {
  const COUNT_START_AT = -11
  const today = new Date()
  const shift = object.createdDate - COUNT_START_AT

  return {
    ...object,
    createdDate: addDayToDate(today, shift).toISOString(),
  }
}

const sortAsc = <T extends { createdDate: string }>(object: T, objectB: T): number => {
  return new Date(object.createdDate).getTime() - new Date(objectB.createdDate).getTime()
}

const demoApBarChartData: GET_BAR_CHART_RESPONSE_SUCCESS = {
  apCaByMarketplaceByDay: demoApBarChartDataBase.apCaByMarketplaceByDay.map((object) => relativeToDate(object)),
  apFundedByMarketplaceByDay: demoApBarChartDataBase.apFundedByMarketplaceByDay.map((object) => relativeToDate(object)),
  financingRateByMarketplaceByDay: demoApBarChartDataBase.financingRateByMarketplaceByDay.map((object) =>
    relativeToDate(object),
  ),
}

export const getDemoApBarChartData = (startDate: Date, endDate: Date): GET_BAR_CHART_RESPONSE_SUCCESS => {
  endDate = endDate > new Date() ? new Date() : endDate

  const filterByDate = (data: BarChartDataType) => {
    return new Date(data.createdDate) >= startDate && new Date(data.createdDate) <= endDate
  }

  return {
    apCaByMarketplaceByDay: demoApBarChartData.apCaByMarketplaceByDay //
      .filter(filterByDate)
      .sort(sortAsc),
    apFundedByMarketplaceByDay: demoApBarChartData.apFundedByMarketplaceByDay //
      .filter(filterByDate)
      .sort(sortAsc),
    financingRateByMarketplaceByDay: demoApBarChartData.financingRateByMarketplaceByDay
      .filter(filterByDate)
      .sort(sortAsc),
  }
}
