import { gql } from "@apollo/client"
import { GqlHeroError } from "../../../01_technical/requesting/request-error.type"
import { TransferStatus } from "../00_shared/business/enum/IncomingTransferStatus.enum"

export type GetIncomingTransfersSuccessResponse = {
  pagination: {
    totalCount: number
    totalPage: number
    currentPage: number
  }
  marketplaces: string[]
  transfers: {
    swanTransactionId: string
    amount: number
    date: string
    logoUrl?: string
    marketplaceName: string
    status: TransferStatus
    account: {
      number: string
      name: string
    }
  }[]
}

export const GET_INCOMING_TRANSFERS_QUERY = gql`
  query ($pagination: PaginationInput!, $filters: MerchantGetIncomingTransfersInputFilters!) {
    getIncomingTransfers(pagination: $pagination, filters: $filters) {
      ... on IncomingTransfersResultOutput {
        pagination {
          totalCount
          totalPage
          currentPage
        }
        marketplaces
        transfers {
          swanTransactionId
          amount
          date
          logoUrl
          marketplaceName
          status
          account {
            number
            name
          }
        }
      }

      ... on ValidationErrors {
        validationErrors {
          path
          validationError
        }
      }

      ... on GqlHeroError {
        errorCode
        message
      }
    }
  }
`

export type GetMarketplacesSuccessResponse = {
  marketplaces: {
    name: string
    accountBalance: number
    logoUrl: string | null
    accountId: string
  }[]
  totalAccountsBalance: number
}

type GetMarketplacesResponseType = GetMarketplacesSuccessResponse | GqlHeroError

export type GetMarketplacesResponse = {
  merchantGetMarketplaces: GetMarketplacesResponseType
}

export const GET_MARKETPLACES_QUERY = gql`
  query {
    merchantGetMarketplaces {
      ... on MerchantGetMarketplacesOutput {
        marketplaces {
          name
          accountBalance
          logoUrl
          accountId
        }
        totalAccountsBalance
      }

      ... on GqlHeroError {
        errorCode
        message
      }
    }
  }
`

export type GetAPBankTransfersSuccessResponse = {
  pagination: {
    totalCount: number
    totalPage: number
    currentPage: number
  }
  apBankTransfers: {
    id: string
    amount: number
    date: string
    iban: string
    wasInstant: boolean | null
    logoUrl?: string
    accountLabel: string
    accountNumber: string
  }[]
  accountLabels: string[]
}

export const GET_AP_BANK_TRANSFERS_QUERY = gql`
  query ($pagination: GetAPBankTransfersPaginationInput!, $filters: GetAPBankTransfersFiltersInput!) {
    getAPBankTransfers(pagination: $pagination, filters: $filters) {
      ... on GetAPBankTransfersResult {
        pagination {
          totalCount
          totalPage
          currentPage
        }
        apBankTransfers {
          id
          amount
          date
          iban
          wasInstant
          logoUrl
          accountLabel
          accountNumber
        }
        accountLabels
      }

      ... on ValidationErrors {
        validationErrors {
          path
          validationError
        }
      }

      ... on GqlHeroError {
        errorCode
        message
      }
    }
  }
`

export type GetAPTransfersSuccessResponse = {
  pagination: {
    totalCount: number
    totalPage: number
    currentPage: number
  }
  transfers: {
    id: string
    amount: number
    date: string
    logoUrl?: string
    type: string
    account: {
      name: string
      number: string
    }
  }[]
  accounts: string[]
}

export const GET_AP_TRANSFERS_QUERY = gql`
  query ($pagination: GetAPTransfersInput!, $filters: GetTransfersInputFilters!) {
    getAPTransfers(pagination: $pagination, filters: $filters) {
      ... on GetAPTransfersResult {
        pagination {
          totalCount
          totalPage
          currentPage
        }
        transfers {
          id
          amount
          date
          logoUrl
          type
          account {
            name
            number
          }
        }
        accounts
      }

      ... on ValidationErrors {
        validationErrors {
          path
          validationError
        }
      }

      ... on GqlHeroError {
        errorCode
        message
      }
    }
  }
`
