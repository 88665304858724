import { gql } from "@apollo/client"
import { PaymentType } from "../../../../business/enums/Payment.enum"
import { TransactionFlowType, TransactionStatus, TransactionType } from "../../../../business/enums/Transaction.enum"
import { GqlHeroError } from "../../../../01_technical/requesting/request-error.type"

export type TransactionParams = {
  id: string
}

export type TransactionResponse = {
  merchantTransaction: {
    id: string
    paymentId?: string
    amount: number
    initiatedAt?: string
    status: TransactionStatus
    type?: PaymentType
    flowType: TransactionFlowType
    orderId?: string
    merchantFee?: number
    merchantVAT?: number
    paidAmount: number
    url: string
    transactionType: TransactionType
    reserve?: number
    amountAddedToBalance?: number
    attachments: {
      id: string
      name: string
      path: string
    }[]
    installments: {
      id: string
      dueDate: string
    }[]
    customer?: {
      id: string
      siret: string
      contactEmail: string
      name: string
    }
  }
  transactionRefund:
    | {
        total: number
        totalProcessing: number
        maxRefundableAmount: number
        feesHTRefunded: number
        feesVatRefunded: number
        refunds: {
          id: string
          amount: number
          createdAt: string
        }[]
      }
    | GqlHeroError
}

export const GET_TRANSACTION = gql`
  query merchantTransaction($id: String!) {
    merchantTransaction(id: $id) {
      id
      paymentId
      amount
      initiatedAt
      status
      type
      flowType
      orderId
      merchantFee
      merchantVAT
      paidAmount
      url
      transactionType
      reserve
      amountAddedToBalance
      attachments {
        id
        name
        path
      }
      installments {
        id
        dueDate
      }
      customer {
        id
        name
        contactEmail
        siret
      }
    }
    transactionRefund(transactionId: $id) {
      ... on TransactionRefundOutput {
        total
        totalProcessing
        maxRefundableAmount
        feesHTRefunded
        feesVatRefunded
        refunds {
          id
          amount
          createdAt
        }
      }
      ... on GqlHeroError {
        errorCode
        message
      }
    }
  }
`
