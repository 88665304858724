import { Modal, Typography } from "@hero/krypton"
import { Card, CardContent, CardHeader, CardTitle } from "../../../../../00_shared/components/Card"
import { useDashboardTranslation } from "../../../../../01_technical/translations"

export const GetHelpModal = ({
  isOpen,
  onClose,
  children,
}: {
  isOpen: boolean
  onClose: () => void
  children: React.ReactNode
}) => {
  const { t } = useDashboardTranslation()
  return (
    <Modal isOpen={isOpen} onClose={onClose} style={{ width: "34rem" }}>
      <Card $variant="plain">
        <CardHeader>
          <CardTitle size="title-2-bold">{t("issuing.disputeTransaction.title")}</CardTitle>
          <Typography $variant="body-4-regular" $color="grey.$500">
            {t("issuing.disputeTransaction.subtitle")}
          </Typography>
        </CardHeader>
        <CardContent>{children}</CardContent>
      </Card>
    </Modal>
  )
}
