import { Button, Header, Typography } from "@hero/krypton"
import { Link, useNavigate } from "react-router-dom"
import { useAmplitude } from "../../../../00_shared/hooks/useAmplitude.hook"
import { useDashboardTranslation } from "../../../../01_technical/translations"
import { useBusinessAccountContext } from "../../../BusinessAccount/businessAccount.context"

export const IssuingCardHeader = () => {
  const { t } = useDashboardTranslation()

  return (
    <Header>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Link to="/cards/list">
          <Typography $variant="title-3-semibold">{t("issuing.header.title")}</Typography>
        </Link>
        <IssuingCardCreateButton />
      </div>
    </Header>
  )
}

const IssuingCardCreateButton = () => {
  const { isBaActive } = useBusinessAccountContext()
  const navigate = useNavigate()
  const { track } = useAmplitude()
  const { t } = useDashboardTranslation()

  const handleClickCreateCard = () => {
    track("card_create_card_from_card_page_clicked")
    navigate("/cards/create")
  }

  return (
    <Button disabled={!isBaActive} onClick={handleClickCreateCard} size="small" isLoading={false} $variant="primary">
      {t("issuing.card.action.create")}
    </Button>
  )
}
