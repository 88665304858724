import { Button, Field, Typography, toaster, ErrorBlock } from "@hero/krypton"
import { useEffect } from "react"
import { useForm } from "react-hook-form"
import { useSearchParams, useNavigate } from "react-router-dom"
import styled from "styled-components"
import { useCommonTranslation } from "../../01_technical/translations"
import { RESET_PASSWORD, ResetPasswordArgs, ResetPasswordResponse } from "./ResetPassword.request"
import { useHeroMutation } from "../../01_technical/requesting/useHeroMutation/useHeroMutation"

const Container = styled.div`
  display: flex;
  height: 100%;
  width: 80%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const LoginBox = styled.form`
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  display: flex;
`

type ResetPasswordForm = {
  password: string
  confirmPassword: string
}

export const ResetPassword = () => {
  const [params] = useSearchParams()
  const resetToken = params.get("token")
  const navigate = useNavigate()
  const { t } = useCommonTranslation()

  const [passwordReset, { loading, error }] = useHeroMutation<ResetPasswordResponse, ResetPasswordArgs>({
    gqlQuerySchema: RESET_PASSWORD,
  })

  const errorsTranslations: Record<string, string | undefined> = {
    NOT_FOUND: t("auth.resetPassword.errors.notFound"),
    EXPIRED: t("auth.resetPassword.errors.expired"),
    INVALID_PASSWORD: t("auth.resetPassword.errors.invalidPassword"),
  }

  useEffect(() => {
    if (!resetToken) {
      navigate("/login")
      toaster.error(t("auth.resetPassword.invalidLink"))
    }
  }, [t, resetToken, navigate])

  const {
    watch,
    register,
    handleSubmit,
    formState: { errors, isDirty },
  } = useForm<ResetPasswordForm>({})
  const onSubmit = handleSubmit(async (formData) => {
    await passwordReset({
      variables: {
        password: formData.password,
        resetToken: resetToken as string,
      },
    })

    navigate("/login", { replace: true })
    toaster.success(t("auth.resetPassword.success"))
  })

  const passwordValue = watch("password")

  return (
    <Container>
      <LoginBox onSubmit={onSubmit}>
        <Typography $variant="title-2-bold" as="h1">
          {t("auth.resetPassword.title")}
        </Typography>

        <Field
          type="password"
          fieldLabel={t("auth.resetPassword.passwordField")}
          placeholder={t("auth.resetPassword.passwordPlaceholder")}
          // eslint-disable-next-line i18next/no-literal-string
          {...register("password", { required: t("requiredField"), deps: ["confirmPassword"] })}
          errorMessage={errors.password?.message}
        />

        <Field
          type="password"
          fieldLabel={t("auth.resetPassword.confirmPasswordField")}
          placeholder={t("auth.resetPassword.confirmPasswordPlaceholder")}
          // eslint-disable-next-line i18next/no-literal-string
          {...register("confirmPassword", {
            required: t("requiredField"),
            // eslint-disable-next-line i18next/no-literal-string
            deps: ["password"],
            validate: {
              samePassword: (confirmPasswordValue) => {
                return passwordValue !== confirmPasswordValue ? t("auth.resetPassword.notSamePassword") : undefined
              },
            },
          })}
          errorMessage={errors.confirmPassword?.message}
        />

        <Button $fullWidth type="submit" disabled={!isDirty || loading} isLoading={loading}>
          {t("auth.resetPassword.submit")}
        </Button>
      </LoginBox>
      {error && <ErrorBlock>{errorsTranslations[error.message] ?? error.message}</ErrorBlock>}
    </Container>
  )
}
