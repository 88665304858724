import { BOV2RouteProtector } from "../../../App.router"
import { Cockpit } from "./cockpit.screen"

export const cockpitRoute = {
  path: "cockpit",
  element: (
    <BOV2RouteProtector>
      <Cockpit />
    </BOV2RouteProtector>
  ),
}
