import { GraphQLError } from "graphql"
import { ApiError, CommonApiErrorCodes, HeroApiError } from "../request-error.type"
import { getErrorWithCode, isApiError } from "../request-error.utils"
import { SimlifiedMutationFunctionResult } from "./useHeroMutation.type"

// ! In case of edit, make sure to update the copy in the admin app as well !

export function getSimplifiedMutationFunctionResult<SuccessData>(query: {
  data?: Record<string, ApiError | SuccessData> | null
  error?: GraphQLError
}): SimlifiedMutationFunctionResult<SuccessData> {
  const baseReturn = { data: null, error: null }

  if (query.error) {
    return {
      ...baseReturn,
      error: new HeroApiError({
        code: CommonApiErrorCodes.SERVER_OR_NETWORK_ERROR,
        message: query.error.message,
      }),
    }
  }

  if (query.data) {
    const dataResponse = query.data[Object.keys(query.data)[0]]

    if (isApiError(dataResponse)) {
      return {
        ...baseReturn,
        error: getErrorWithCode(dataResponse),
      }
    }

    return {
      ...baseReturn,
      data: dataResponse,
    }
  }

  return {
    ...baseReturn,
    error: new HeroApiError({ code: CommonApiErrorCodes.UNKNOWN_ERROR, message: "An error occured." }),
  }
}
