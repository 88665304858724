import { gql } from "@apollo/client"
import { GqlHeroError } from "../../01_technical/requesting/request-error.type"

export const GET_SWAN_ACCOUNT = gql`
  query merchantGetMerchantSwanAccount {
    merchantGetMerchantSwanAccount {
      ... on MerchantSwanAccount {
        id
        merchantId
        hasSwanStandingOrderEnabled
        standingOrderConsentUrl
        hasSwanAcccountFullyOnboarded
      }

      ... on GqlHeroError {
        errorCode
        message
      }
    }
  }
`

export type GET_SWAN_ACCOUNT_RESPONSE_SUCCESS = {
  id: string
  merchantId: string
  hasSwanStandingOrderEnabled: boolean
  standingOrderConsentUrl: string | null
  hasSwanAcccountFullyOnboarded: boolean
}

export type GET_SWAN_ACCOUNT_RESPONSE = {
  merchantGetMerchantSwanAccount: GET_SWAN_ACCOUNT_RESPONSE_SUCCESS | GqlHeroError
}

export const SWAN_AUTH_MUTATION = gql`
  mutation merchantSetupSwanAuth($code: String!) {
    merchantSetupSwanAuth(code: $code) {
      ... on GqlHeroError {
        errorCode
        message
      }

      ... on SwanAuthOutput {
        success
      }
    }
  }
`

export type SWAN_AUTH_MUTATION_RESPONSE = {
  merchantSetupSwanAuth: SWAN_AUTH_MUTATION_RESPONSE_SUCCESS | GqlHeroError
}

export type SWAN_AUTH_MUTATION_RESPONSE_SUCCESS = {
  success: boolean
}

export type SWAN_AUTH_ARGS = {
  code: string
}

export const SWAN_COMPLETE_SETUP_LINK_MUTATION = gql`
  mutation merchantCompleteSwanSetupStandingOrderLink {
    merchantCompleteSwanSetupStandingOrderLink {
      ... on GqlHeroError {
        errorCode
        message
      }

      ... on CompleteSwanSetupStandingOrderLink {
        success
      }
    }
  }
`

export type SWAN_COMPLETE_SETUP_LINK_MUTATION_RESPONSE = {
  merchantCompleteSwanSetupStandingOrderLink: SWAN_COMPLETE_SETUP_LINK_MUTATION_RESPONSE_SUCCESS | GqlHeroError
}

export type SWAN_COMPLETE_SETUP_LINK_MUTATION_RESPONSE_SUCCESS = {
  success: boolean
}

export const SCHEDULE_STANDING_ORDER = gql`
  mutation merchantScheduleStandingOrder($merchantId: String!) {
    merchantScheduleStandingOrder(merchantId: $merchantId) {
      ... on StandingOrder {
        consentUrl
      }

      ... on StandingOrderError {
        reason
      }

      ... on GqlHeroError {
        errorCode
      }
    }
  }
`

export type SCHEDULE_STANDING_ORDER_ARGS = {
  merchantId: string
}

export type SCHEDULE_STANDING_ORDER_ERROR_RESPONSE = {
  reason: string
}

export type SCHEDULE_STANDING_ORDER_RESPONSE_SUCCESS = {
  consentUrl: string
}

export type SCHEDULE_STANDING_ORDER_RESPONSE = {
  merchantScheduleStandingOrder:
    | SCHEDULE_STANDING_ORDER_RESPONSE_SUCCESS
    | GqlHeroError
    | SCHEDULE_STANDING_ORDER_ERROR_RESPONSE
}

export const isScheduleStandingOrderSuccess = (
  standingOrderResult: SCHEDULE_STANDING_ORDER_RESPONSE_SUCCESS | SCHEDULE_STANDING_ORDER_ERROR_RESPONSE,
): standingOrderResult is SCHEDULE_STANDING_ORDER_RESPONSE_SUCCESS => {
  return !!(standingOrderResult as SCHEDULE_STANDING_ORDER_RESPONSE_SUCCESS).consentUrl
}

export const RECEIVE_STANDING_ORDER_CONFIRMATION = gql`
  mutation merchantReceiveStandingOrderConfirmation($standingOrderId: String!) {
    merchantReceiveStandingOrderConfirmation(standingOrderId: $standingOrderId) {
      ... on StandingOrderUpdated {
        id
        status
      }

      ... on GqlHeroError {
        errorCode
        message
      }
    }
  }
`

export type RECEIVE_STANDING_ORDER_CONFIRMATION_ARGS = {
  standingOrderId: string
}

export type RECEIVE_STANDING_ORDER_CONFIRMATION_RESPONSE_SUCCESS = {
  id: string
  status: "Canceled" | "Enabled" | "ConsentPending"
}

export type RECEIVE_STANDING_ORDER_CONFIRMATION_RESPONSE = {
  merchantReceiveStandingOrderConfirmation: RECEIVE_STANDING_ORDER_CONFIRMATION_RESPONSE_SUCCESS | GqlHeroError
}

export const isReceiveStandingOrderConfirmationSuccess = (
  standingOrderResult: RECEIVE_STANDING_ORDER_CONFIRMATION_RESPONSE_SUCCESS | GqlHeroError,
): standingOrderResult is RECEIVE_STANDING_ORDER_CONFIRMATION_RESPONSE_SUCCESS => {
  return !!(standingOrderResult as RECEIVE_STANDING_ORDER_CONFIRMATION_RESPONSE_SUCCESS).id
}

export const GET_GET_SETUP_STANDING_ORDER_LINK = gql`
  query getSwanSetupStandingOrderLink {
    getSwanSetupStandingOrderLink {
      ... on SwanSetupStandingOrderLink {
        id
        status
      }

      ... on GqlHeroError {
        errorCode
        message
      }
    }
  }
`

export type GET_GET_SETUP_STANDING_ORDER_LINK_RESPONSE_SUCCESS = {
  id: string
  status: "PENDING" | "COMPLETED"
}

export type GET_GET_SETUP_STANDING_ORDER_LINK_RESPONSE = {
  getSwanSetupStandingOrderLink: GET_GET_SETUP_STANDING_ORDER_LINK_RESPONSE_SUCCESS | GqlHeroError
}
