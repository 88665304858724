import { useApolloClient } from "@apollo/client"
import { Navigate, useSearchParams } from "react-router-dom"

export const LoginAs = () => {
  const [queryParams] = useSearchParams()
  const client = useApolloClient()

  const token = queryParams.get("token")

  localStorage.setItem("token", token ?? "")

  client.clearStore()

  return <Navigate to="/" />
}
