import { Button, Chip, FloatingBox } from "@hero/krypton"
import React, { Children, cloneElement, useEffect, useMemo, useState } from "react"
import { DeepPartial, useForm } from "react-hook-form"
import styled from "styled-components"

const ChipBox = styled.span`
  margin-right: 0.5rem;
`

const Form = styled.form`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
`

type FilterProps<T> = {
  label: string
  searchText?: string
  onSubmit: (data: Record<string, T>) => void
  defaultValues: { [key: string]: DeepPartial<T> | undefined }
  getChipValue: (data: { [key: string]: T }) => string | undefined
}

export const Filter = <T extends string | number | boolean | string[] | number[] | boolean[] | Date | undefined>({
  label,
  searchText,
  onSubmit,
  defaultValues,
  getChipValue,
  children,
}: React.PropsWithChildren<FilterProps<T>>) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | undefined>(undefined)
  const isOpen = Boolean(anchorEl)

  const {
    handleSubmit,
    register,
    setValue,
    watch,
    getValues,
    formState: { isSubmitting },
  } = useForm<{
    [key: string]: T
  }>({
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    defaultValues,
  })

  const [chipValue, setChipValue] = useState<string | undefined>(getChipValue(getValues()))

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? undefined : event.currentTarget)
  }

  useEffect(() => {
    Object.keys(defaultValues).forEach((key) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore TODO: understand why react-hook-form type key as <PathImpl<string, T, { [key: string]: T; }>> instead of string
      setValue(key, defaultValues[key])
      setChipValue(getChipValue(watch()))
    })
  }, [setValue, defaultValues, watch, getChipValue])

  const registeredChildren = useMemo(
    () =>
      Children.map(children, (child, idx) => {
        if (React.isValidElement(child)) {
          return cloneElement(child, {
            ...child.props,
            ...register(child.props.name),
            key: idx,
          })
        }
        return child
      }),
    [children, register],
  )

  const submitCallback = useMemo(() => {
    return handleSubmit((data) => {
      onSubmit(data)
      setAnchorEl(undefined)
    })
  }, [handleSubmit, onSubmit])

  return (
    <>
      <ChipBox>
        <Chip
          label={label}
          onClick={handleClick}
          onClear={() => {
            onSubmit({})
          }}
          value={chipValue}
        />
      </ChipBox>
      <FloatingBox isOpen={isOpen} onClose={() => setAnchorEl(undefined)} anchorElement={anchorEl}>
        <Form method="dialog" onSubmit={submitCallback}>
          {registeredChildren}
          <Button type="submit" size="small" isLoading={isSubmitting}>
            {searchText ?? "Chercher"}
          </Button>
        </Form>
      </FloatingBox>
    </>
  )
}
