import { MerchantStatus } from "../../../business/enums/Merchant.enum"
import { MerchantMenuEntry } from "../../merchant.menu.type"

export const ClientsMenuEntry: MerchantMenuEntry = {
  to: "/checkout_cash/clients",
  key: "menu.list.checkoutAndCash.clients",
  visible: ({ merchantStatus }) => merchantStatus === MerchantStatus.VERIFIED,
  selected: (location) =>
    location.pathname.startsWith("/checkout_cash/clients") ||
    location.search.includes(`origin=${encodeURIComponent("/checkout_cash/clients")}`),
  "data-test-id": "menu-entry-clients",
}
