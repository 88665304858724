import { gql } from "@apollo/client"
import { TransferStatus } from "../../../00_shared/business/enum/IncomingTransferStatus.enum"

export type GetIncomingTransferDetailsSuccessResponse = {
  status: TransferStatus
  swanTransactionId: string
  reference: string | null
  label: string
  marketplaceName: string
  amount: number
  executionDate: string
  allocationDate: string | null
  creditorIban: string
  account: {
    name: string
    number: string
  }
  balanceBeforeAndAfterMovements: {
    isRefund: boolean
    balanceBefore: number
    balanceAfter: number
    movement: {
      id: string
      label: string
      amount: number
      createdAt: string
    }
  }[]
}

export const GET_INCOMING_TRANSFER_DETAILS = gql`
  query getSwanTransactionDetails($swanTransactionId: String!) {
    getSwanTransactionDetails(swanTransactionId: $swanTransactionId) {
      ... on SwanTransactionDetails {
        status
        swanTransactionId
        reference
        label
        marketplaceName
        amount
        executionDate
        allocationDate
        creditorIban
        account {
          name
          number
        }
        balanceBeforeAndAfterMovements {
          isRefund
          balanceBefore
          balanceAfter
          movement {
            id
            label
            amount
            createdAt
          }
        }
      }
      ... on GqlHeroError {
        errorCode
        message
      }
    }
  }
`
