import { ChevronDownIcon, Typography } from "@hero/krypton"
import React from "react"
import styled from "styled-components"

const StyledButton = styled.button`
  display: inline-flex;
  align-items: center;
  gap: 0.2rem;
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.black.$900};
  font: inherit;
  text-decoration: none;
`

const Text = styled(Typography).attrs(() => ({
  $variant: "body-3-medium",
}))``

const ChevronDown = styled(ChevronDownIcon)`
  width: 1rem;
  height: 1rem;
`

interface TypographyButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  children: React.ReactNode
}

export const TypographyButton = React.forwardRef<HTMLButtonElement, TypographyButtonProps>(
  ({ children, ...props }, ref) => (
    <StyledButton ref={ref} {...props}>
      <Text>{children}</Text>
      <ChevronDown />
    </StyledButton>
  ),
)

TypographyButton.displayName = "TypographyButton"
