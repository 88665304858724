import { Checkbox, Typography } from "@hero/krypton"
import React from "react"
import { useDashboardTranslation } from "../../../../../../../01_technical/translations"
import { FilterSection } from "./FilterSection"

interface TypeFilterProps {
  selectedTypes: string[]
  onTypeChange: (type: string) => void
  hideHeader?: boolean
}

export const TypeFilter: React.FC<TypeFilterProps> = ({ selectedTypes, onTypeChange, hideHeader = false }) => {
  const { t } = useDashboardTranslation()

  const handleCheckboxChange = (type: string) => {
    onTypeChange(type)
  }

  return (
    <FilterSection>
      {!hideHeader && (
        <Typography $variant="body-4-semibold">{t("accounts.transaction.list.filters.chip.type")}</Typography>
      )}
      <Checkbox
        id="type-entrant"
        text={t("accounts.transaction.filter.incoming")}
        label={t("accounts.transaction.filter.incoming")}
        checked={selectedTypes.includes("incoming")}
        onChange={() => handleCheckboxChange("incoming")}
      />
      <Checkbox
        id="type-sortant"
        text={t("accounts.transaction.filter.outgoing")}
        label={t("accounts.transaction.filter.outgoing")}
        checked={selectedTypes.includes("outgoing")}
        onChange={() => handleCheckboxChange("outgoing")}
      />
      <Checkbox
        id="type-annule"
        text={t("accounts.transaction.filter.cancelled")}
        label={t("accounts.transaction.filter.cancelled")}
        checked={selectedTypes.includes("cancelled")}
        onChange={() => handleCheckboxChange("cancelled")}
      />
    </FilterSection>
  )
}
