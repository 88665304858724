import { DateTime } from "luxon"

export const getDatesFromPeriod = (period: string) => {
  const today = DateTime.now()
  switch (period) {
    case "thisWeek":
      return {
        startDate: today.startOf("week").toJSDate(),
        endDate: today.endOf("week").toJSDate(),
      }
    case "lastWeek":
      return {
        startDate: today.startOf("week").minus({ week: 1 }).toJSDate(),
        endDate: today.startOf("week").minus({ week: 1 }).endOf("week").toJSDate(),
      }
    case "thisMonth":
      return {
        startDate: today.startOf("month").toJSDate(),
        endDate: today.endOf("month").toJSDate(),
      }
    case "lastMonth":
      return {
        startDate: today.startOf("month").minus({ month: 1 }).toJSDate(),
        endDate: today.startOf("month").minus({ month: 1 }).endOf("month").toJSDate(),
      }
    default:
      return {
        startDate: today.startOf("week").toJSDate(),
        endDate: today.toJSDate(),
      }
  }
}
