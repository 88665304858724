import { BadgeVariant } from "@hero/krypton"
import { TFunction } from "i18next"
import { TranslationNamespaces } from "../../01_technical/translations"
import { TransactionFlowType, TransactionStatus, TransactionType } from "../enums/Transaction.enum"

export const isTransactionReceivableGenerator = ({ id }: { id: string }) => id.startsWith("receivgen-")

export const isPayGenerator = (type?: TransactionType): boolean => {
  return type === TransactionType.PAYMENT_GENERATOR
}

export const getTransactionWordingByStatus = (
  t: TFunction<TranslationNamespaces.COMMON>,
  transaction: { id: string; status: TransactionStatus },
): { text: string; color: BadgeVariant; title?: string } => {
  const isReceivableGenerator = isTransactionReceivableGenerator(transaction)

  switch (transaction.status) {
    case TransactionStatus.LATE:
      return {
        text: t("business.transactionStatus.late"),
        color: "danger",
        title: t("business.transactionStatus.lateTooltip"),
      }
    case TransactionStatus.NOT_PAID:
      return { text: t("business.transactionStatus.notPaid"), color: "warning" }
    case TransactionStatus.REFUNDED:
      return { text: t("business.transactionStatus.refunded"), color: "secondary" }
    case TransactionStatus.BLOCKED_MISSING_CUSTOMER_DOCS:
      return { text: t("business.transactionStatus.blockedMissingCustomerDocs"), color: "warning" }
    case TransactionStatus.PARTIALLY_REFUNDED:
      return { text: t("business.transactionStatus.partiallyRefunded"), color: "secondary" }
    case TransactionStatus.PAID:
      return { text: t("business.transactionStatus.paid"), color: "primary" }
    case TransactionStatus.REFUSED:
      return {
        text: isReceivableGenerator
          ? t("business.transactionStatus.refusedByHero")
          : t("business.transactionStatus.refused"),
        color: "danger",
      }
    case TransactionStatus.WAITING_FOR_VALIDATION:
      return {
        text: isReceivableGenerator
          ? t("business.transactionStatus.waitingForValidationByHero")
          : t("business.transactionStatus.waitingForValidation"),
        color: "warning",
      }
    case TransactionStatus.WAITING_FOR_CUSTOMER_CONFIRMATION:
      return { text: t("business.transactionStatus.waitingForCustomerConfirmation"), color: "warning" }
    default:
      return { text: "", color: "primary" }
  }
}

export const getTransactionWordingByFlowType = (
  t: TFunction<TranslationNamespaces.COMMON>,
  transaction: { flowType: TransactionFlowType },
): string => {
  return transaction.flowType === TransactionFlowType.ONLINE
    ? `${t("business.transactionType.online")}`
    : transaction.flowType === TransactionFlowType.OFFLINE
      ? `${t("business.transactionType.offline")}`
      : `${t("business.transactionType.receivable")}`
}
