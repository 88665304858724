import { Typography } from "@hero/krypton"
import { DateTime } from "luxon"
import styled from "styled-components"
import { toEuros } from "../../../../00_shared/utils/currency.converter"
import { useDashboardTranslation } from "../../../../01_technical/translations"
import { TransferStatus } from "../../00_shared/business/enum/IncomingTransferStatus.enum"
import { TransferDetailsHistory, TransferDetailsHistoryItem } from "./components/TransferDetailsHistory.component"
import { PaymentInfo } from "./components/TransferDetailsPaymentInfo.component"
import { TransferDetailsSection } from "./components/TransferDetailsSection.component"
import { TransferStatusBadge } from "./components/TransferDetailsStatusBadge.component"

const Container = styled.div`
  border-left: 1px solid ${({ theme }) => theme.colors.grey.$300};
  min-height: 100vh;
`

const TransferDetailsCard = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  margin: 2rem 3rem;
  width: 42.5rem;
  border-radius: 0.75rem;
  border: 1px solid ${({ theme }) => theme.colors.grey.$200};
  box-shadow: ${({ theme }) => theme.shadows.light};
`

const TransferDetailsCardContent = styled.div`
  padding: 1.5rem;
`

const TransferDetailsTitle = styled(Typography)`
  font-size: 1.5rem;
  font-weight: 600;
`

type TransferDetailsItem = {
  id?: string
  createdAt?: string
  label?: string
  amount?: number
  isMovement?: boolean
  balanceAfter?: number
  isHighlight?: boolean
  highlightColor?: "blue" | "red"
}

export const humanFriendlyDate = (createdAt: string, language: string) =>
  `${DateTime.fromISO(createdAt).setLocale(language).toLocaleString()} - ${DateTime.fromISO(createdAt)
    .setLocale(language)
    .toLocaleString(DateTime.TIME_24_SIMPLE)}`

export const TransferDetails: React.FC<{
  referenceId: string
  status: TransferStatus
  marketplaceName: string
  amount: number
  createdAt: string
  iban: string
  accountName: string
  accountNumber: string
  label?: string
  items: TransferDetailsItem[]
}> = ({ referenceId, status, marketplaceName, amount, createdAt, iban, accountNumber, accountName, label, items }) => {
  const { t } = useDashboardTranslation()

  return (
    <Container>
      <TransferDetailsCard>
        <TransferDetailsCardContent>
          <TransferDetailsTitle $variant="title-1-bold">
            Account - {accountName} - n°{accountNumber}
          </TransferDetailsTitle>
          <TransferStatusBadge status={status} />
          <TransferDetailsSection title={t("ap.balance.transfer.details.title")}>
            <PaymentInfo label={t("ap.balance.transfer.details.marketplace")} value={marketplaceName} />
            <PaymentInfo label={t("ap.balance.transfer.details.reference")} value={referenceId} />
            {label && <PaymentInfo label={t("ap.balance.transfer.details.label")} value={label} />}
            <PaymentInfo label={t("ap.balance.transfer.details.amount")} value={toEuros(amount)} />
            <PaymentInfo label={t("ap.balance.transfer.details.date")} value={createdAt} />
            <PaymentInfo label={t("ap.balance.transfer.details.iban")} value={iban} />
            <PaymentInfo
              label={t("ap.balance.transfer.details.account")}
              value={`Account - ${accountName} - n°${accountNumber}`}
            />
          </TransferDetailsSection>

          {!!items.length && (
            <TransferDetailsSection title={t("ap.balance.transfer.history")}>
              <TransferDetailsHistory>
                {items.map((item, index) => (
                  <TransferDetailsHistoryItem key={index} {...item} />
                ))}
              </TransferDetailsHistory>
            </TransferDetailsSection>
          )}
        </TransferDetailsCardContent>
      </TransferDetailsCard>
    </Container>
  )
}
