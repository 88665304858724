import { Navigate } from "react-router-dom"
import { BOV2RouteProtector } from "../../../App.router"
import { AccountingScreen } from "./accounting.screen"
import { billingRoute } from "./billing/billing.route"
import { exportRoute } from "./export/export.route"

export const accountingRoute = {
  path: "tools/accounting/*",
  element: (
    <BOV2RouteProtector>
      <AccountingScreen />
    </BOV2RouteProtector>
  ),
  children: [exportRoute, billingRoute, { path: "*", element: <Navigate to={billingRoute.path} /> }],
}
