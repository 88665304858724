import { gql } from "@apollo/client"
import { use2faChallenge } from "../../../../Auth/Challenge2fa/use2faChallenge.hooks"

export function useCreateBeneficiary() {
  const { mutationWith2fa, mutationState } = use2faChallenge<
    { createBeneficiary: CREATE_BENEFICIARY_SUCCESS_RESPONSE },
    CREATE_BENEFICIARY_PARAMS
  >(CREATE_BENEFICIARY_REQ)

  const mutate = async (input: CreateBeneficiaryInput) => {
    const result = await mutationWith2fa({ input })
    return result.data?.createBeneficiary
  }

  return {
    mutate,
    loading: mutationState.loading,
    error: mutationState.error,
    data: mutationState.data,
  }
}

type CreateBeneficiaryInput = {
  fullName: string
  iban: string
  label: string | null
  bankName?: string
}

type CREATE_BENEFICIARY_PARAMS = {
  input: CreateBeneficiaryInput
}

type CREATE_BENEFICIARY_SUCCESS_RESPONSE = {
  success: boolean
  beneficiaryId: string
}

const CREATE_BENEFICIARY_REQ = gql`
  mutation createBeneficiary($input: CreateBeneficiaryInput!) {
    createBeneficiary(input: $input) {
      ... on CreateBeneficiaryOutput {
        success
        beneficiaryId
      }

      ... on GqlHeroError {
        errorCode
        message
      }

      ... on ValidationErrors {
        errorCode
        validationErrors {
          path
          validationError
        }
      }
    }
  }
`
