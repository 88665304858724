import React from "react"
import { TransferType, useCreateTransferContext } from "../CreateTransferContext"
import { AccountToAccount } from "./components/AccountToAccount"
import { Beneficiary } from "./components/Beneficiary"

export const SelectAccountToCredit: React.FC = () => {
  const { state } = useCreateTransferContext()

  return (
    <>
      {state.selectedTransferType === TransferType.BENEFICIARY && <Beneficiary />}
      {state.selectedTransferType === TransferType.ACCOUNT_TO_ACCOUNT && <AccountToAccount />}
    </>
  )
}
