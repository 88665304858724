import { gql } from "@apollo/client"
import { useEffect, useMemo, useState } from "react"
import { useLocation } from "react-router-dom"
import { HeroApiError } from "../../../../../01_technical/requesting/request-error.type"
import { useHeroQuery } from "../../../../../01_technical/requesting/useHeroQuery/useHeroQuery"

const GET_BENEFICIARIES = gql`
  query listBeneficiaries($filters: ListBeneficiariesFilters, $pagination: ListBeneficiariesPaginationInput!) {
    listBeneficiaries(filters: $filters, pagination: $pagination) {
      ... on ListBeneficiariesOutput {
        beneficiaries {
          id
          label
          isDefault
          fullName
          status
          iban
          bic
          linkedMerchantAccounts {
            id
            label
            accountId
          }
          bankName
        }
        pagination {
          totalCount
          totalPage
          currentPage
        }
      }

      ... on SimpleApiError {
        errorCode
      }

      ... on ValidationErrors {
        errorCode
      }
    }
  }
`

export interface Beneficiary {
  id: string
  merchantId: string
  label: string | null
  isDefault: boolean
  fullName: string
  status: string
  iban: string
  bankName?: string
  last4Iban: string
  bic: string | null
}

export interface PaginationResult {
  totalCount: number
  totalPage: number
  currentPage: number
}

interface BeneficiariesQueryResult {
  beneficiaries: Beneficiary[]
  pagination: PaginationResult
}

interface BeneficiariesVariables {
  pagination: {
    pageSize: number
    pageNumber: number
  }
}

interface UseBeneficiariesResult {
  beneficiaries: Beneficiary[]
  pagination: PaginationResult
  loading: boolean
  error: HeroApiError | null
  refetch: () => void
  setVariables: React.Dispatch<React.SetStateAction<BeneficiariesVariables>>
}

const initPagination = { limit: 10, page: 1 }

export const useBeneficiaries = (
  initialPagination: { limit: number; page: number } = initPagination,
): UseBeneficiariesResult => {
  const location = useLocation()
  const searchParams = useMemo(() => new URLSearchParams(location.search), [location.search])
  const [variables, setVariables] = useState<BeneficiariesVariables>({
    pagination: { pageNumber: initialPagination.page, pageSize: initialPagination.limit },
  })

  const { data, loading, error, refetch } = useHeroQuery<BeneficiariesQueryResult>({
    gqlQuerySchema: GET_BENEFICIARIES,
    variables: variables,
    fetchPolicy: "cache-and-network",
  })

  useEffect(() => {
    setVariables({
      pagination: {
        pageNumber: Number(searchParams.get("page")) || initPagination.page,
        pageSize: initPagination.limit,
      },
    })
  }, [searchParams])

  const beneficiaries = data?.beneficiaries || []
  const pagination = data?.pagination || {
    totalCount: 0,
    totalPage: 1,
    currentPage: 1,
  }

  return {
    beneficiaries,
    pagination,
    loading,
    error,
    refetch,
    setVariables,
  }
}
