import styled from "styled-components"
import { PaymentConfigurationScope } from "../00_shared/enums/PaymentCore.enum"
import { useProductContext } from "../ProductScopeContext"
import { ApiKey } from "./Integration/Integration"
import { SettingsProductScope } from "./SettingsProductScope"

const Container = styled.div`
  display: flex;
  flex-direction: column;
`

const ApiKeyContainer = styled.div`
  margin-top: 2rem;
`

export const SettingsCheckout = () => {
  const productContext = useProductContext()

  return (
    <Container>
      <SettingsProductScope
        titleKey="collection.settings.checkout"
        enablePluginsNavigation
        scopeBNPLEnabled={productContext?.checkoutScope === PaymentConfigurationScope.BNPL}
        scopePAY1xEnabled={productContext?.checkoutScope === PaymentConfigurationScope.PAY1X}
        productDisabled={!!!productContext?.checkoutEnabled}
      />
      {productContext?.checkoutEnabled && productContext.checkoutScope !== PaymentConfigurationScope.NONE && (
        <ApiKeyContainer>
          <ApiKey />
        </ApiKeyContainer>
      )}
    </Container>
  )
}
