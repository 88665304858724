import { gql } from "@apollo/client"

export const UPDATE_PASSWORD = gql`
  mutation updateMyPassword($oldPassword: String!, $newPassword: String!) {
    updateMyPassword(oldPassword: $oldPassword, newPassword: $newPassword)
  }
`
export type UpdatePasswordType = {
  oldPassword: string
  newPassword: string
}

export const UPDATE_PASSWORD_ERROR_MAPPINGS = {
  WRONG_PASSWORD: () => "Le mot de passe est incorrect",
  TOO_SHORT: () => "Le mot de passe est trop court",
  EMPTY: () => "Le mot de passe est vide",
  MISSING_UPPERCASE: () => "Le mot de passe doit inclure une majuscule",
  MISSING_LOWERCASE: () => "Le mot de passe doit inclure une minuscule",
  MISSING_DIGIT: () => "Le mot de passe doit inclure un chiffre",
  MISSING_SPECIAL_CHAR: () => "Le mot de passe doit inclure un caracter spécial",
}
