import { Typography } from "@hero/krypton"
import styled from "styled-components"
import { IssuingCard } from "../business/IssuingCard"

const OverlayContent = styled.div`
  position: absolute;
  top: 138px;
  left: 28px;
  z-index: 10;
  padding: 4px;
  padding-left: 8px;
`

interface IssuingCardOverlayProps {
  card: IssuingCard
}

const IssuingCardOverlay = ({ card }: IssuingCardOverlayProps) => {
  return (
    <OverlayContent>
      <Typography
        $variant="body-4-regular"
        style={{
          background:
            "linear-gradient(92.48deg, rgba(255, 255, 255, 0.6) -10.55%, #FFFFFF 37.49%, rgba(255, 255, 255, 0.4) 71.37%, #FFFFFF 110.82%)",
          WebkitBackgroundClip: "text",
          WebkitTextFillColor: "transparent",
        }}
      >
        {"Débit"} {card.issuanceType === "VIRTUAL" ? "virtuelle" : "physique"}
      </Typography>

      <Typography
        $variant="body-4-regular"
        style={{
          background:
            "linear-gradient(92.48deg, rgba(255, 255, 255, 0.4) -10.55%, #FFFFFF 37.49%, rgba(255, 255, 255, 0.4) 71.37%, #FFFFFF 110.82%)",
          WebkitBackgroundClip: "text",
          WebkitTextFillColor: "transparent",
        }}
      >
        {card.displayLastCardDigits}
      </Typography>
    </OverlayContent>
  )
}

export default IssuingCardOverlay
