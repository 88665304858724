import React from "react"
import styled, { keyframes } from "styled-components"

const slideDown = keyframes`
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`

const slideUp = keyframes`
  from {
    opacity: 1;
    transform: translateY(0);
  }
  to {
    opacity: 0;
    transform: translateY(-10px);
  }
`

const StyledList = styled.div`
  position: relative;
  width: 100%;
`

const StyledContent = styled.div`
  position: absolute;
  top: 0.2rem;
  z-index: 50;
  width: 100%;
  max-height: 24rem;
  background-color: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.grey.$200};
  border-radius: 0.75rem;
  box-shadow: ${({ theme }) => theme.shadows.mid};
  overflow-y: auto;
  animation: ${slideDown} 0.2s ease-out;

  &[data-state="closed"] {
    animation: ${slideUp} 0.2s ease-in;
  }
`

const StyledItem = styled.div`
  display: flex;
  align-items: center;
  padding: 0.5rem 1rem;
  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) => theme.colors.grey.$200};
    color: ${({ theme }) => theme.colors.grey.$600};
  }
`

/**
 * ListSelector
 *
 * A reusable component designed to display a list of selectable items.
 * This component is fully generic and can render any type of item, allowing users to define
 * how each item should be displayed using a custom render function.
 *
 * Props:
 * - `items`: The array of items to be rendered in the list.
 * - `onSelect`: A function that is triggered when an item is selected. It receives the selected item as an argument.
 * - `isOpen`: A boolean that controls whether the dropdown list is visible or not.
 * - `renderItem`: A function that defines how each item should be rendered. It receives an item of type `T` and returns a React node.
 *
 * Example Usage:
 * ```tsx
 * const items = [{ id: 1, description: 'Item 1' }, { id: 2, description: 'Item 2' }];
 * const renderItem = (item) => <div>{item.description}</div>;
 *
 * <ListSelector
 *   items={items}
 *   onSelect={(item) => console.log(item)}
 *   isOpen={true}
 *   renderItem={renderItem}
 * />
 * ```
 */
export const ListSelector = <T,>(props: {
  items: T[]
  onSelect?: (item: T) => void
  isOpen: boolean
  renderItem: (item: T) => React.ReactNode
}) => {
  const { items, onSelect, isOpen, renderItem } = props

  return (
    <StyledList>
      {isOpen && (
        <StyledContent>
          {items.map((item, index) => (
            <StyledItem
              key={index}
              onClick={() => {
                if (onSelect) {
                  onSelect(item)
                }
              }}
            >
              {renderItem(item)}
            </StyledItem>
          ))}
        </StyledContent>
      )}
    </StyledList>
  )
}
