import { MerchantMenuEntry } from "../../merchant.menu.type"

export const ApCockpitMenuEntry: MerchantMenuEntry = {
  to: "/ap/cockpit",
  key: "menu.list.ap.cockpit",
  visible: true,
  selected: (location) => {
    return location.pathname.startsWith("/ap/cockpit")
  },
  "data-test-id": "menu-entry-ap-cockpit",
}
