import { Button, Separator } from "@hero/krypton"
import { useState } from "react"
import { FlexItem } from "../../../../../00_shared/components/Flex"
import { useDashboardTranslation } from "../../../../../01_technical/translations"
import { GetHelpForTransaction } from "./GetHelpForTransaction"
import { GetHelpModal } from "./GetHelpModal"

export const DisputeIssuingTransaction = ({
  transactionId,
  label,
  date,
  amount,
}: {
  transactionId: string
  label: string
  date: string
  amount: string
}) => {
  const { t } = useDashboardTranslation()
  const [helpModalOpen, setHelpModalOpen] = useState(false)

  const closeHelpModal = () => setHelpModalOpen(false)
  return (
    <div>
      <GetHelpModal isOpen={helpModalOpen} onClose={closeHelpModal}>
        <GetHelpForTransaction
          transactionId={transactionId}
          label={label}
          date={date}
          amount={amount}
          onDone={() => setTimeout(() => setHelpModalOpen(false), 50)}
        />
      </GetHelpModal>
      <div style={{ position: "absolute", bottom: 15, width: "100%" }}>
        <Separator />
        <FlexItem style={{ textAlign: "center", paddingTop: ".75rem" }}>
          <Button $variant="underline" onClick={() => setHelpModalOpen(true)}>
            {t("issuing.operation.details.getHelp")}
          </Button>
        </FlexItem>
      </div>
    </div>
  )
}
