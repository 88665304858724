import { Spinner } from "@hero/krypton"
import styled from "styled-components"
import { useDashboardTranslation } from "../../01_technical/translations"

const Wrapper = styled.div<{ $screenHeight?: boolean }>`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: ${(props) => (props.$screenHeight ? "100vh" : "auto")};
`

const Title = styled.div`
  margin-left: 1rem;
  font-weight: 500;
`

interface CenteredLoadingProps {
  height?: "auto" | "screen"
}

const CenteredLoading = ({ height }: CenteredLoadingProps) => {
  const { t } = useDashboardTranslation()

  return (
    <Wrapper $screenHeight={height === "screen"}>
      <Spinner />
      <Title>{t("loading")}...</Title>
    </Wrapper>
  )
}

const CenteredBasicError = ({ height }: CenteredLoadingProps) => {
  const { t } = useDashboardTranslation()

  return (
    <Wrapper $screenHeight={height === "screen"}>
      {/* eslint-disable-next-line i18next/no-literal-string */}
      <Title>⚠️ {t("error")}</Title>
    </Wrapper>
  )
}

export default CenteredLoading
export { CenteredBasicError }
