import { gql } from "@apollo/client"
import { useHeroMutation } from "../../../../../01_technical/requesting/useHeroMutation/useHeroMutation"

const DISPUTE_ISSUING_TRANSACTION = gql`
  mutation DisputeIssuingTransaction($transactionId: String!, $disputeReason: String!) {
    disputeIssuingTransaction(transactionId: $transactionId, disputeReason: $disputeReason) {
      ... on DisputeIssuingTransactionOutput {
        success
      }
      ... on GqlHeroError {
        errorCode
        message
      }
    }
  }
`

type DisputeIssuingTransactionOutput = {
  success: boolean
}

type DisputeIssuingTransactionInput = {
  transactionId: string
  disputeReason: string
}

export const useDisputeIssuingTransaction = () => {
  const [mutate, { data, loading, error }] = useHeroMutation<
    DisputeIssuingTransactionOutput,
    DisputeIssuingTransactionInput
  >({
    gqlQuerySchema: DISPUTE_ISSUING_TRANSACTION,
  })

  return { mutate, loading, error, data }
}
