import { Button, Field, FieldSelect, Typography, toaster } from "@hero/krypton"
import { TFunction } from "i18next"
import { useEffect, useMemo } from "react"
import { useForm } from "react-hook-form"
import styled from "styled-components"
import { HeroErrorDisplayer } from "../../../../../01_technical/requesting/DEPRECATED_graphql.errors"
import { useCommonTranslation, useDashboardTranslation } from "../../../../../01_technical/translations"
import { useAuthContext } from "../../../../../Auth/auth.context"
import {
  useAPScheduleAccountingSpreadsheetHook,
  useBNPLScheduleAccountingSpreadsheetHook,
} from "../hooks/GenerateApAccountingSpreadsheet.hook"
import { DateFormatted, formatDateToInputDate } from "./date-input.utils"

const AccountingSection = styled.section`
  padding-left: 1.5rem;
`

const Description = styled(Typography)`
  padding-right: 1rem;
  color: ${({ theme }) => theme.colors.grey.$500};
  margin-bottom: 1.5rem;
`

const Option = styled.option``

const Form = styled.form`
  display: flex;
  gap: 1rem;
  margin-top: 0.5rem;
`

const FieldStyled = styled(Field)`
  margin-top: 0.25rem;
  margin-left: 0;
  width: 100%;
`

const ButtonContainer = styled.div`
  margin-top: 1.75rem;
`

const ExportErrorMapper = (t: TFunction): Record<string, string> => ({
  MERCHANT_ACCOUNT_NOT_FOUND: t("accounting.export.errors.merchantAccountNotFound"),
  MERCHANT_ACCOUNT_INVALID: t("accounting.export.errors.merchantAccountInvalid"),
  FROM_DATE_HIGHER_THAN_TO_DATE: t("accounting.export.errors.fromDateHigherThanToDate"),
  INVALID_DATE_RANGE: t("accounting.export.errors.fromDateHigherThanToDate"),
  DATE_IN_THE_FUTURE: t("accounting.export.errors.dateInTheFuture"),
  RANGE_TOO_LONG: t("accounting.export.errors.rangeTooLong"),
})

type ProductType = "ap" | "bnpl"

type TimingFilter = {
  from: DateFormatted
  to: DateFormatted
  type: ProductType
}

export const AccountingSpreadsheetExport: React.FC<{
  hasBNPLMovements: boolean
  hasAPMovements: boolean
}> = ({ hasBNPLMovements, hasAPMovements }) => {
  const { t, unsafeT } = useDashboardTranslation()
  const { t: tcommon } = useCommonTranslation()
  const { currentUser } = useAuthContext()

  const now = new Date()
  const firstDayOfPreviousMonth = new Date(now.getFullYear(), now.getMonth() - 1, 1)
  const lastDayOfPreviousMonth = new Date(now.getFullYear(), now.getMonth(), 0)
  const { handleSubmit, formState, register } = useForm<TimingFilter>({
    defaultValues: {
      from: formatDateToInputDate(firstDayOfPreviousMonth),
      to: formatDateToInputDate(lastDayOfPreviousMonth),
    },
  })

  const {
    scheduleAccountingSpreadsheetGeneration: scheduleAPAccountingSpreadsheetGeneration,
    successData: scheduleAPAccountingSpreadsheetGenerationSuccess,
    functionalError: scheduleAPAccountingSpreadsheetGenerationFunctionalError,
    technicalError: scheduleAPAccountingSpreadsheetGenerationTechnicalError,
    loading: scheduleAPAccountingSpreadsheetGenerationLoading,
  } = useAPScheduleAccountingSpreadsheetHook()

  const {
    scheduleAccountingSpreadsheetGeneration: scheduleBNPLAccountingSpreadsheetGeneration,
    successData: scheduleBNPLAccountingSpreadsheetGenerationSuccess,
    functionalError: scheduleBNPLAccountingSpreadsheetGenerationFunctionalError,
    technicalError: scheduleBNPLAccountingSpreadsheetGenerationTechnicalError,
    loading: scheduleBNPLAccountingSpreadsheetGenerationLoading,
  } = useBNPLScheduleAccountingSpreadsheetHook()

  useEffect(() => {
    if (scheduleAPAccountingSpreadsheetGenerationSuccess || scheduleBNPLAccountingSpreadsheetGenerationSuccess) {
      toaster.success(t("accounting.export.spreadsheet.form.successMessage"))
    }
  }, [scheduleBNPLAccountingSpreadsheetGenerationSuccess, scheduleAPAccountingSpreadsheetGenerationSuccess, t])

  const isGenerateDisabled =
    scheduleBNPLAccountingSpreadsheetGenerationLoading ||
    scheduleAPAccountingSpreadsheetGenerationLoading ||
    !formState.isValid

  const handleSpreadsheetExport = handleSubmit(({ from, to, type }) => {
    const fromIsoString = new Date(from).toISOString()
    const toIsoString = new Date(to).toISOString()
    const apiToCall =
      type === "ap" ? scheduleAPAccountingSpreadsheetGeneration : scheduleBNPLAccountingSpreadsheetGeneration
    apiToCall({
      variables: {
        options: {
          from: fromIsoString,
          to: toIsoString,
        },
      },
    })
  })

  const defaultType = useMemo(() => {
    if (hasBNPLMovements) {
      return "bnpl"
    }
    if (hasAPMovements) {
      return "ap"
    }
  }, [hasAPMovements, hasBNPLMovements])

  if (!hasAPMovements && !hasBNPLMovements && !currentUser.isDemoMerchant) {
    return null
  }

  return (
    <AccountingSection>
      <Typography as="h2" $variant="title-3-inter-bold" data-test-id="accounting-export-title">
        {t("accounting.export.spreadsheet.title")}
      </Typography>

      <Description $variant="body-4-regular">{t("accounting.export.spreadsheet.subtitle")}</Description>

      <Form onSubmit={handleSpreadsheetExport}>
        <FieldStyled
          fieldLabel={t("accounting.export.spreadsheet.form.from.label")}
          type="date"
          aria-invalid={!!formState.errors.from}
          // eslint-disable-next-line i18next/no-literal-string
          {...register("from", { required: tcommon("requiredField") })}
          errorMessage={formState.errors.from?.message}
          $inline
        />

        <FieldStyled
          fieldLabel={t("accounting.export.spreadsheet.form.to.label")}
          type="date"
          aria-invalid={!!formState.errors.to}
          // eslint-disable-next-line i18next/no-literal-string
          {...register("to", { required: tcommon("requiredField") })}
          errorMessage={formState.errors.to?.message}
          $inline
        />

        <FieldSelect
          fieldLabel={t("accounting.export.spreadsheet.form.type")}
          // eslint-disable-next-line i18next/no-literal-string
          {...register("type", { value: defaultType })}
        >
          <Option value="" disabled>
            {t("accounting.export.spreadsheet.form.selectDefault")}
          </Option>
          {hasBNPLMovements && <Option value="bnpl">{t("accounting.export.spreadsheet.form.types.bnpl")}</Option>}
          {(hasAPMovements || currentUser.isDemoMerchant) && (
            <Option value="ap">{t("accounting.export.spreadsheet.form.types.ap")}</Option>
          )}
        </FieldSelect>

        {!currentUser.isDemoMerchant && (
          <ButtonContainer>
            <Button
              type="submit"
              size="medium"
              disabled={isGenerateDisabled}
              isLoading={
                scheduleBNPLAccountingSpreadsheetGenerationLoading || scheduleAPAccountingSpreadsheetGenerationLoading
              }
            >
              {t("accounting.export.spreadsheet.form.generateButton")}
            </Button>
          </ButtonContainer>
        )}

        {currentUser.isDemoMerchant && (
          <a href="/demo/demo-accounting-export.xlsx">
            <ButtonContainer>
              <Button type="button" size="medium" isLoading={false}>
                {t("accounting.export.spreadsheet.form.generateButton")}
              </Button>
            </ButtonContainer>
          </a>
        )}
      </Form>
      <HeroErrorDisplayer
        data={
          scheduleBNPLAccountingSpreadsheetGenerationFunctionalError ||
          scheduleAPAccountingSpreadsheetGenerationFunctionalError
        }
        err={
          scheduleBNPLAccountingSpreadsheetGenerationTechnicalError ||
          scheduleAPAccountingSpreadsheetGenerationTechnicalError
        }
        messages={{
          INTERNAL_SERVER_ERROR: t("accounting.export.spreadsheet.form.genericErrorMessage"),
          FUNCTIONAL_ERROR: ({ error }) =>
            ExportErrorMapper(unsafeT)[error.message] || t("accounting.export.spreadsheet.form.genericErrorMessage"),
        }}
      />
    </AccountingSection>
  )
}
