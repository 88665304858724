export function debounce<F extends (...args: Parameters<F>) => ReturnType<F>>(
  func: F,
  delayMs = 200,
): (...args: Parameters<F>) => void {
  let timeout: number | NodeJS.Timeout
  return (...args: Parameters<F>) => {
    clearTimeout(timeout as number)
    timeout = setTimeout(() => func(...args), delayMs)
  }
}
