import { gql } from "@apollo/client"
import { useHeroQuery } from "../../../../01_technical/requesting/useHeroQuery/useHeroQuery"
import { MarketplaceReserveFundingInstallmentStatus } from "../../../../business/enums/MarketplaceReserveFundingInstallmentStatus.enum"
import { MarketplaceReserveFundingStatus } from "../../../../business/enums/MarketplaceReserveFundingStatus.enum"

type MrfInstallmentMovement = {
  accountMovementId: string
  amount: number
  isFeesRepayment: boolean
  createdAt: string
}

export type MrfInstallment = {
  id: string
  amount: number
  amountToDebit: number
  feesAmountToDebit: number
  feesAmount: number
  dueDate: string
  status: MarketplaceReserveFundingInstallmentStatus
  movements: MrfInstallmentMovement[]
}

export type GetMrfDetailsResponseData = {
  id: string
  merchantTradingName: string
  periodStart?: string
  periodEnd?: string
  amount: number
  feesAmount: number
  dueAmount: number
  feesDueAmount: number
  acceptanceDate?: string
  feesRate: number
  accountName: string
  accountBalance: number
  status: MarketplaceReserveFundingStatus
  installmentDebitDay: number
  installmentAmount: number
  installmentAmountFees: number
  installments: MrfInstallment[]
}

const GET_MARKETPLACE_RESERVE_FUNDING_DETAILS = gql`
  query getMarketplaceReserveFunding($mrfId: String!) {
    getMarketplaceReserveFunding(mrfId: $mrfId) {
      ... on GetMarketplaceReserveFundingDetails {
        id
        merchantTradingName
        periodStart
        periodEnd
        amount
        feesAmount
        dueAmount
        feesDueAmount
        feesRate
        accountName
        accountBalance
        acceptanceDate
        status
        installmentDebitDay
        installmentAmount
        installmentAmountFees
        installments {
          id
          amount
          amountToDebit
          feesAmount
          feesAmountToDebit
          dueDate
          status
          movements {
            accountMovementId
            amount
            isFeesRepayment
            createdAt
          }
        }
      }

      ... on SimpleApiError {
        errorCode
      }
    }
  }
`

export function useGetActiveMRFData(mrfId: string) {
  return useHeroQuery<GetMrfDetailsResponseData>({
    gqlQuerySchema: GET_MARKETPLACE_RESERVE_FUNDING_DETAILS,
    variables: {
      mrfId,
    },
  })
}
