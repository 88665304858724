import { gql, useQuery } from "@apollo/client"
import { unwrapGraphQLResponse } from "../../../01_technical/requesting/DEPRECATED_graphql.errors"
import { GqlHeroError } from "../../../01_technical/requesting/request-error.type"

const GET_ACTIVE_MRFS = gql`
  query {
    getActiveMarketplaceReserveFundings {
      ... on GetActiveMarketplaceReserveFundingsSuccess {
        mrfs {
          id
          name
          logoUrl
          acceptationDate
        }
      }
      ... on GqlHeroError {
        errorCode
        message
      }
    }
  }
`

type MarketplaceReserveFundingFields = {
  id: string
  name: string
  logoUrl?: string
  acceptationDate: string
}

type GetActiveMarketplaceReserveFundingsSuccess = {
  mrfs?: MarketplaceReserveFundingFields[]
}

type GetActiveMarketplaceReserveFundingsResponse = {
  getActiveMarketplaceReserveFundings: GetActiveMarketplaceReserveFundingsSuccess | GqlHeroError
}

type UseGetActiveMarketplaceReserveFundingsReturnType =
  | {
      data: null
      loading: true
      error: null
    }
  | {
      data: MarketplaceReserveFundingFields[]
      loading: false
      error: null
    }
  | {
      data: null
      loading: false
      error: string
    }

export function useGetActiveMarketplaceReserveFundings(): UseGetActiveMarketplaceReserveFundingsReturnType {
  const {
    data: queryData,
    loading: queryLoading,
    error: queryError,
  } = useQuery<GetActiveMarketplaceReserveFundingsResponse>(GET_ACTIVE_MRFS)

  const { data: unwrappedData, error: unwrappedError } =
    unwrapGraphQLResponse<GetActiveMarketplaceReserveFundingsSuccess>(queryData?.getActiveMarketplaceReserveFundings)

  if (queryLoading) {
    return {
      data: null,
      loading: true,
      error: null,
    }
  }

  if (queryError || unwrappedError || !unwrappedData) {
    return {
      data: null,
      loading: false,
      error: "An error occurred. Please contact support.",
    }
  }

  return {
    data: unwrappedData.mrfs || [],
    loading: false,
    error: null,
  }
}
