/* eslint-disable i18next/no-literal-string */
import * as Sentry from "@sentry/react"
import { StrictMode } from "react"
import { createRoot } from "react-dom/client"
import { hotjar } from "react-hotjar"
import Modal from "react-modal"
import "react-phone-number-input/style.css"
import "react-toastify/dist/ReactToastify.css"
import styled from "styled-components"
import { initI18next } from "./01_technical/translations"
import { App } from "./App"
import { HOTJAR_HJID, HOTJAR_HJSV, SENTRY_ENABLED, SENTRY_ENV } from "./env_variables"
import "./index-reset.css"
import "./index.css"

Sentry.init({
  dsn: "https://6756b5517b13cc832dfa847903ed92b1@o1086518.ingest.us.sentry.io/4507623076397056",
  integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["https://staging.dashboard.heropay.eu/", "https://dashboard.heropay.eu/"],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.

  maxValueLength: 5000,
  environment: SENTRY_ENV,
  enabled: SENTRY_ENABLED,
  ignoreErrors: ["HERO_FUNCTIONAL", "INVALID_IDENTITY"],
})

hotjar.initialize(HOTJAR_HJID, HOTJAR_HJSV)

initI18next()

Modal.setAppElement("#root")

const FatalErrorContainer = styled.main`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 0.5rem;
  height: 100%;
  width: 100%;
  font-family: Inter;
`

const container = document.getElementById("root")

if (!container) {
  throw new Error("No root element found")
}

createRoot(container).render(
  <StrictMode>
    <Sentry.ErrorBoundary
      fallback={
        <FatalErrorContainer>
          <div lang="en">Please Refresh the page.</div>
          <div lang="fr">Veuillez rafraichir la page.</div>
          <div lang="es">Actualice la página.</div>
        </FatalErrorContainer>
      }
    >
      <App />
    </Sentry.ErrorBoundary>
  </StrictMode>,
)
