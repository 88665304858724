import { ArrowLeftIcon, Button, Label, Typography } from "@hero/krypton"
import React, { useEffect } from "react"
import styled from "styled-components"
import { Card, CardContent, CardFooter, CardHeader } from "../../../../../../00_shared/components/Card"
import { FlexContainer, FlexItem } from "../../../../../../00_shared/components/Flex"
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../../../../../00_shared/components/Select"
import { toEuros } from "../../../../../../00_shared/utils/currency.converter"
import { useCommonTranslation } from "../../../../../../01_technical/translations"
import HeroIcon from "../../../../00_shared/icons/hero-icon.png"
import { useBusinessAccountContext } from "../../../../businessAccount.context"
import { useBusinessAccounts } from "../../00_shared/hooks/useBusinessAccounts"
import { useCreateTransferContext } from "../../CreateTransferContext"

const CreditAccountSelectTrigger = styled(SelectTrigger)`
  position: relative;
  padding-left: 2rem;
  > span {
    text-align: start;
    width: 100%;
    > p {
      display: flex;
      justify-content: space-between;
    }
  }

  &::before {
    content: "";
    display: inline-block;
    width: 1.25rem;
    height: 1.25rem;
    background-image: url(${HeroIcon});
    background-size: contain;
    background-repeat: no-repeat;
    position: absolute;
    left: 0.5rem;
  }
`

const CreditAccountSelectItem = styled(SelectItem)`
  width: 100%;

  > span {
    width: 100%;

    > p {
      display: flex;
      justify-content: space-between;
    }
  }
`

const AccountAmount = styled(Typography).attrs(() => ({
  $variant: "body-4-regular",
}))`
  color: ${({ theme }) => theme.colors.grey.$500};
  padding-right: 0.25rem;
`

export const AccountToAccount: React.FC = () => {
  const { isBaActive } = useBusinessAccountContext()
  const { state, setState, handleNextStep, goToPreviousStep, validateAllPreviousSteps, errors } =
    useCreateTransferContext()
  const { businessAccounts } = useBusinessAccounts()
  const { t } = useCommonTranslation()

  const handleSelectChange = (value: string) => {
    const selectedAccount = businessAccounts.find((account) => account.ledgerId === value)
    if (selectedAccount) {
      setState((prevState) => ({
        ...prevState,
        accountToCreditId: selectedAccount.ledgerId,
        accountToCreditName: selectedAccount.name,
        accountToCreditIban: selectedAccount.virtualIban,
      }))
    }
  }

  useEffect(() => {
    validateAllPreviousSteps()
  }, [validateAllPreviousSteps])

  return (
    <Card $variant="plain">
      <CardHeader>
        <Typography $variant="title-2-bold">{t("transfer.set.accountToAccount.account.title")}</Typography>
      </CardHeader>
      <CardContent>
        <Select
          error={!!errors.accountToCreditId}
          errorMessage={errors.accountToCreditId}
          onValueChange={handleSelectChange}
          value={state.accountToCreditId}
        >
          <Label>{t("transfer.set.accountToAccount.account.label")}</Label>
          <CreditAccountSelectTrigger>
            <SelectValue placeholder="Sélectionner un compte" />
          </CreditAccountSelectTrigger>
          <SelectContent>
            {businessAccounts.map((businessAccount) => (
              <CreditAccountSelectItem key={businessAccount.ledgerId} value={businessAccount.ledgerId}>
                <Typography>{businessAccount.name}</Typography>
                <AccountAmount>{toEuros(businessAccount.balance)}</AccountAmount>
              </CreditAccountSelectItem>
            ))}
          </SelectContent>
        </Select>
      </CardContent>
      <CardFooter>
        <FlexItem $grow={1}>
          <FlexContainer $direction="column" $gap="2rem">
            <Button $fullWidth $variant="primary" size="medium" onClick={handleNextStep}>
              {t("transfer.wizard.label.next")}
            </Button>
            <FlexItem $alignSelf="center">
              <Button
                type="button"
                size="medium"
                $variant="underline"
                onClick={goToPreviousStep}
                disabled={!isBaActive}
              >
                <ArrowLeftIcon />
                {t("transfer.wizard.label.back")}
              </Button>
            </FlexItem>
          </FlexContainer>
        </FlexItem>
      </CardFooter>
    </Card>
  )
}
