import { Header, Tab, Tabs, Typography } from "@hero/krypton"
import { Link, Navigate, Route, Routes, useLocation } from "react-router-dom"
import styled from "styled-components"
import { useIsFFPINUPDATEActive } from "../../../00_shared/hooks/useFeatureFlag.hook"
import { useDashboardTranslation } from "../../../01_technical/translations"
import { HEADER_COLORS } from "../../../env_variables"
import { apiKeyRoute } from "./Integration/Integration.route"
import { listMerchantUserRoute } from "./MerchantUser/listMerchantUser.route"
import { updateBankAccountRoute } from "./UpdateBankAccount/updateBankAccount.route"
import { updateMerchantRoute } from "./UpdateMerchant/updateMerchant.route"
import { updateUserRoute } from "./UpdateUser/updateUser.route"
import { updateUserPasswordRoute } from "./UpdateUserPassword/updateUserPassword.route"
import { updateUserPinRoute } from "./UpdateUserPin/updateUserPin.route"
import { ProtectedHeroPage } from "./page.type"

const Container = styled.section`
  width: calc(100% - 6rem);
  margin: 1.5rem 3rem;
  border-radius: 1rem;
  border: 1px solid ${({ theme }) => theme.colors.grey.$200};
  box-shadow: ${({ theme }) => theme.shadows.light};
`

const Nav = styled.nav`
  margin-top: 0.75rem;
  background-color: ${({ theme }) => theme.colors.white};
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
`

const RouteContainer = styled.section`
  padding: 2rem 1.5rem;
  background-color: rgba(255, 255, 255, 0.72);
  border-radius: 0.75rem;
`

type TabsType = ({
  key: string
  "data-test-id": string
} & ProtectedHeroPage)[]

const tabs: TabsType = [
  {
    key: "merchant.tools.setting.tabs.user",
    "data-test-id": "tab-utilisateur",
    ...updateUserRoute,
  },
  {
    key: "merchant.tools.setting.tabs.password",
    "data-test-id": "tab-mot-de-passe",
    ...updateUserPasswordRoute,
  },
  {
    key: "merchant.tools.setting.tabs.company",
    "data-test-id": "tab-company",
    ...updateMerchantRoute,
  },
  {
    key: "merchant.tools.setting.tabs.bankAccount",
    "data-test-id": "tab-compte-en-banque",
    ...updateBankAccountRoute,
  },
  {
    key: "merchant.tools.setting.tabs.integration",
    "data-test-id": "tab-integration",
    ...apiKeyRoute,
  },
  {
    key: "merchant.tools.setting.tabs.team",
    "data-test-id": "tab-equipe",
    ...listMerchantUserRoute,
  },
  {
    key: "merchant.tools.setting.tabs.pin",
    "data-test-id": "tab-pin",
    ...updateUserPinRoute,
  },
]

const getFeaturedTabs = (keys: string[], tabs: TabsType) => {
  return tabs.filter((tab) => !keys.includes(tab.key))
}

export const Settings = () => {
  const location = useLocation()
  const isPinFeatureEnabled = useIsFFPINUPDATEActive()

  const ffKeys = (props: { pinFeatureState: boolean | undefined }) => {
    return props.pinFeatureState ? [] : ["merchant.tools.setting.tabs.pin"]
  }
  const featuredTabs = getFeaturedTabs(ffKeys({ pinFeatureState: isPinFeatureEnabled }), tabs)

  const permittedTabs = featuredTabs

  const { t, unsafeT } = useDashboardTranslation()

  return (
    <>
      <Header $colors={HEADER_COLORS}>
        <Typography as="h1" $variant="title-2-bold">
          {t("merchant.tools.setting.title")}
        </Typography>
        <Typography as="strong" $variant="body-3-medium">
          {t("merchant.tools.setting.subtitle")}
        </Typography>
        <Nav>
          <Tabs>
            {permittedTabs.map((tab) => (
              <Tab<typeof Link>
                data-test-id={tab["data-test-id"]}
                key={tab.path}
                as={Link}
                $isSelected={location.pathname.includes(tab.path)}
                to={tab.path}
              >
                {unsafeT(tab.key)}
              </Tab>
            ))}
          </Tabs>
        </Nav>
      </Header>
      <Container>
        <RouteContainer>
          <Routes>
            {permittedTabs.map((tab) => (
              <Route key={tab.path} path={tab.path} element={<tab.element data-test-id={tab["data-test-id"]} />} />
            ))}
            <Route path="/" element={<Navigate to={permittedTabs[0]?.path || "/cockpit"} />} />
          </Routes>
        </RouteContainer>
      </Container>
    </>
  )
}
