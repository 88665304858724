import { Typography } from "@hero/krypton"
import styled from "styled-components"
import logo from "./illu.svg"
import rightArrow from "./RightArrow.svg"

const PhotoInputBox = styled.label`
  background: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.grey.$200};
  box-sizing: border-box;
  border-radius: 0.5rem;
  text-align: center;
  cursor: pointer;
  margin-top: 1rem;
  display: flex;
  justify-content: space-between;
`

const ImageContainer = styled.div`
  padding: 0 1.25rem;
  display: flex;
  align-items: center;
`

const Title = styled(Typography).attrs(() => ({
  as: "h1",
  $variant: "body-4-medium",
}))``

const TextAligner = styled.div`
  text-align: start;
  padding: 1rem 0;
`

export const PhotoInput = ({
  onChange,
  title = "Prendre en photo le document",
  subtitle = "Cliquez sur ce lien pour photographier le document",
  imgAlt = "Logo",
  iconImgAlt = "Flèche droite",
}: {
  onChange: (arg: File) => void
  title?: string
  subtitle?: string
  imgAlt?: string
  iconImgAlt?: string
}) => {
  return (
    <PhotoInputBox>
      <input
        onChange={(e) => {
          const files = e.target.files
          files && onChange(files[0])
        }}
        type="file"
        accept="image/*"
        capture
        hidden
      />
      <ImageContainer>
        <img src={logo} alt={imgAlt} />
      </ImageContainer>
      <TextAligner>
        <Title>{title}</Title>
        <Typography as="h2">{subtitle}</Typography>
      </TextAligner>
      <ImageContainer>
        <img src={rightArrow} alt={iconImgAlt} />
      </ImageContainer>
    </PhotoInputBox>
  )
}
