export enum IssuanceType {
  VIRTUAL = "VIRTUAL",
  PHYSICAL = "PHYSICAL",
}

export enum IssuanceStatus {
  ORDERED = "ORDERED",
  PRODUCTION = "PRODUCTION",
  PARTIALLY_ACTIVE = "PARTIALLY_ACTIVE",
  ACTIVE = "ACTIVE",
  FROZEN = "FROZEN",
  TERMINATED = "TERMINATED",
}

export interface IssuingCard {
  id: string
  merchantId: string
  businessAccountId: string
  issuanceType: IssuanceType
  status: IssuanceStatus
  expiration: string
  lastCardDigits: string
  displayLastCardDigits: string
  allowContactless: boolean
  allowEcommerce: boolean
  allowOtherCurrency: boolean
  cardholderName: string
  monthlySpendingLimitAmount: number
  consumedMonthlySpendingLimitAmount: number
  monthlySpendingLimitAmountEuros: string
  consumedMonthlySpendingLimitAmountEuros: string
  paymentAccountName: string
  pending3dsChallengeId: string | null
  formattedOrderedDate: string
  formattedProductionDate: string
  formattedReceptionDate: string
  isDeliveryLate: boolean
  notReceivedDeclarationDate: Date
}
