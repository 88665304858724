import { Button, Modal, Typography } from "@hero/krypton"
import { useCommonTranslation } from "../../01_technical/translations"
import accessDeniedImage from "../../assets/access_denied.svg"

import styled from "styled-components"
import { SupportContact } from "../../00_shared/components/SupportContact"

const Title = styled(Typography)`
  margin-bottom: 0.25rem;
`

const ImageWrapper = styled.div`
  height: 6.25rem;
  width: 6.25rem;
  margin-bottom: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
`

const Wrapper = styled.div`
  padding: 18px;
`

interface DeviceNotFoundModalProps {
  isOpen: boolean
  close: () => void
}

export const DeviceNotFoundModal = ({ isOpen, close }: DeviceNotFoundModalProps) => {
  const { t } = useCommonTranslation()

  return (
    <Modal canCloseOutside={false} isOpen={isOpen} onClose={close}>
      <Wrapper>
        <ImageWrapper>
          <img src={accessDeniedImage} alt="" />
        </ImageWrapper>
        <Title $variant="title-3-semibold">{t("auth.2fa.DeviceError.modal.title")}</Title>

        <Typography>{t("auth.2fa.DeviceError.modal.description")}</Typography>

        <Button
          data-test-id="auth-2fa-device-not-found-button"
          size="medium"
          style={{ marginTop: "1rem", marginBottom: "1rem", alignSelf: "center" }}
          onClick={close}
        >
          {t("auth.2fa.DeviceError.modal.button")}
        </Button>

        <SupportContact />
      </Wrapper>
    </Modal>
  )
}
