import * as ScrollAreaPrimitive from "@radix-ui/react-scroll-area"
import * as React from "react"
import styled from "styled-components"

const ScrollAreaViewport = styled(ScrollAreaPrimitive.Viewport)`
  height: 100%;
  width: 100%;
  border-radius: inherit;

  > div[style] {
    display: block !important;
    height: 100%;
  }
`

const StyledScrollBar = styled(ScrollAreaPrimitive.ScrollAreaScrollbar)<{ orientation: "vertical" | "horizontal" }>`
  display: flex;
  touch-action: none;
  user-select: none;
  transition: background-color 0.3s;
  ${({ orientation }) =>
    orientation === "vertical" &&
    `
    height: 100%;
    width: .5rem;
    border-left: 1px solid transparent;
    padding: 1px;
  `}
  ${({ orientation }) =>
    orientation === "horizontal" &&
    `
    height: 2.5rem;
    flex-direction: column;
    border-top: 1px solid transparent;
    padding: 1px;
  `}
`

const ScrollAreaThumb = styled(ScrollAreaPrimitive.ScrollAreaThumb)`
  flex: 1;
  border-radius: 9999px;
  background-color: ${({ theme }) => theme.colors.grey.$300};
`

const ScrollAreaCorner = styled(ScrollAreaPrimitive.Corner)``

const ScrollAreaRoot = styled(ScrollAreaPrimitive.Root)<{ height?: string; width?: string }>`
  position: relative;
  overflow: hidden;
  height: ${({ height }) => height || "auto"};
  width: ${({ width }) => width || "100%"};
`

interface ScrollAreaProps extends React.ComponentPropsWithoutRef<typeof ScrollAreaRoot> {
  height?: string
  width?: string
}

const ScrollArea = React.forwardRef<React.ElementRef<typeof ScrollAreaRoot>, ScrollAreaProps>(
  ({ className, children, height, width, ...props }, ref) => (
    <ScrollAreaRoot ref={ref} className={className} height={height} width={width} {...props}>
      <ScrollAreaViewport>{children}</ScrollAreaViewport>
      <ScrollBar orientation="vertical" />
      <ScrollAreaCorner />
    </ScrollAreaRoot>
  ),
)
ScrollArea.displayName = ScrollAreaPrimitive.Root.displayName

const ScrollBar = React.forwardRef<
  React.ElementRef<typeof StyledScrollBar>,
  React.ComponentPropsWithoutRef<typeof StyledScrollBar>
>(({ className, orientation = "vertical", ...props }, ref) => (
  <StyledScrollBar ref={ref} orientation={orientation} className={className} {...props}>
    <ScrollAreaThumb />
  </StyledScrollBar>
))
ScrollBar.displayName = ScrollAreaPrimitive.ScrollAreaScrollbar.displayName

export { ScrollArea }
