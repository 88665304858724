import { Outlet } from "react-router-dom"

import { BOV2RouteProtector } from "../../../App.router"
import { clientTransactionsDetailsRoute } from "./ClientTransactionsDetails/clientTransactionsDetails.route"
import { ClientsScreen } from "./clients.screen"

export const clientsRoute = {
  path: "clients/*",
  element: <Outlet />,
  children: [
    {
      index: true,
      element: (
        <BOV2RouteProtector>
          <ClientsScreen />
        </BOV2RouteProtector>
      ),
    },
    clientTransactionsDetailsRoute,
  ],
}
