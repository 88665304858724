import { createContext, useContext, useMemo } from "react"
import { Outlet } from "react-router-dom"
import { PaymentConfigurationScope } from "./00_shared/enums/PaymentCore.enum"
import { useGetPaymentConfiguration } from "./Collection.request"

const ProductScopeContext = createContext<{
  isAtLeastOneProductEnabled: boolean
  checkoutScope: PaymentConfigurationScope
  linkScope: PaymentConfigurationScope
  checkoutEnabled: boolean
  linkEnabled: boolean
} | null>(null)

export const useProductContext = () => useContext(ProductScopeContext)

export const ProductScope = () => {
  const { data: paymentProductConf, loading: getPaymentProductConfLoading } = useGetPaymentConfiguration()

  const isAtLeastOneProductEnabled = useMemo(() => {
    if (!paymentProductConf.paymentCheckout || !paymentProductConf.paymentLink) {
      return false
    }

    return (
      (paymentProductConf.paymentLink.enabled &&
        paymentProductConf.paymentLink.scope !== PaymentConfigurationScope.NONE) ||
      (paymentProductConf.paymentCheckout.enabled &&
        paymentProductConf.paymentCheckout.scope !== PaymentConfigurationScope.NONE)
    )
  }, [paymentProductConf])

  const productContext = useMemo(() => {
    if (!paymentProductConf.paymentCheckout?.scope || !paymentProductConf.paymentLink?.scope) {
      return null
    }

    return {
      isAtLeastOneProductEnabled,
      checkoutScope: paymentProductConf.paymentCheckout.scope,
      linkScope: paymentProductConf.paymentLink.scope,
      checkoutEnabled: paymentProductConf.paymentCheckout.enabled,
      linkEnabled: paymentProductConf.paymentLink.enabled,
    }
  }, [isAtLeastOneProductEnabled, paymentProductConf])

  if (!productContext || getPaymentProductConfLoading) {
    return null
  }

  return (
    <ProductScopeContext.Provider value={productContext}>
      <Outlet />
    </ProductScopeContext.Provider>
  )
}
