import { gql } from "@apollo/client"
import { useHeroQuery } from "../../../../01_technical/requesting/useHeroQuery/useHeroQuery"

const GET_USER = gql`
  query {
    user {
      id
      email
      firstname
      lastname
      phone
    }
  }
`

export interface User {
  id: string
  email: string
  firstname: string
  lastname: string
  phone: string
}

export const useGetMerchantUserQuery = () => {
  return useHeroQuery<User>({
    gqlQuerySchema: GET_USER,
  })
}
