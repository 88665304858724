import { ErrorBlock } from "@hero/krypton"
import { useRouteError } from "react-router-dom"
import { CwtApplication } from "./Application/Application.screen"
import { CwtListScreen } from "./List/List.screen"

export const creditWireTransfersRoute = {
  path: "cwt",
  children: [
    {
      index: true,
      element: <CwtListScreen />,
    },
    {
      path: "application",
      element: <CwtApplication />,
    },
  ],
  errorElement: <ErrorBoundary />,
}

function ErrorBoundary() {
  const error = useRouteError()
  console.error(error)
  return (
    // eslint-disable-next-line i18next/no-literal-string
    <ErrorBlock $margin="2rem">
      Error when rendering your page. You can try to refresh the page. If the error persists, please contact us.
    </ErrorBlock>
  )
}
