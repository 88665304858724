import { Button, toaster } from "@hero/krypton"
import { useMemo } from "react"
import { useNavigate, useParams } from "react-router-dom"
import styled from "styled-components"
import { Card, CardContent, CardFooter, CardHeader, CardTitle } from "../../../../00_shared/components/Card"
import { Dialog, DialogContent } from "../../../../00_shared/components/Dialog"
import { useDownloadFile } from "../../../../00_shared/hooks/useDownloadFile"
import useMediaQuery from "../../../../00_shared/hooks/useMediaQuery.hook"
import { useCommonTranslation, useDashboardTranslation } from "../../../../01_technical/translations"
import { AccountInformation } from "../00_shared/components/AccountInformation"
import { BusinessAccount, MerchantInfo, useBusinessAccount } from "../00_shared/hooks/useBusinessAccount"
import { useRibSignedUrl } from "../00_shared/hooks/useRibSignedUrl"

const FlexContainer = styled.div`
  display: flex;
  flex-direction: column;

  @media (max-width: 768px) {
    gap: 1rem;
  }
`

const BankInformationContainer = styled.div`
  display: flex;
  gap: 1rem;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`

const CardContainer = styled(Card)`
  @media (max-width: 768px) {
    padding: 0;
    border: none !important;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
`

const CardContentContainer = styled(CardContent)`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`

const CardFooterContainer = styled(CardFooter)`
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: stretch;
  }
`

interface BankDetailsProps {
  isMobile?: boolean
  businessAccount?: BusinessAccount
  merchantInfo?: MerchantInfo
  title: string
  buttonDownload: string
  holder: string
  ribSignedUrl: string | undefined
}

const BankDetails = (props: BankDetailsProps) => {
  const { t } = useCommonTranslation()
  const { downloadFile, loading, error } = useDownloadFile()

  const filename = useMemo(
    () => `${props.businessAccount?.name} ${t("accountDetails.ribLink.filename")}`,
    [props.businessAccount?.name, t],
  )

  const handleDownload = () => {
    if (props.ribSignedUrl) {
      downloadFile(props.ribSignedUrl, filename)
    }
  }

  if (error) {
    toaster.error(t("accountDetails.ribLink.error"))
  }

  return (
    <CardContainer>
      <FlexContainer>
        <CardHeader>
          <CardTitle size={props.isMobile ? "title-3-semibold" : "body-2-semibold"}>{props.title}</CardTitle>
        </CardHeader>
        <CardContentContainer>
          <BankInformationContainer>
            <AccountInformation label="IBAN" value={props.businessAccount?.VIban || ""} canCopy={true} />
            <AccountInformation label="BIC/SWIFT" value={props.businessAccount?.BIC || ""} canCopy={true} />
          </BankInformationContainer>
          <AccountInformation
            label={props.holder}
            value={
              <dl>
                <dt>{props.merchantInfo?.juridicalName}</dt>
                <dt>{props.merchantInfo?.address?.line1}</dt>
                <dt>{props.merchantInfo?.address?.line2}</dt>
                <dt>
                  {props.merchantInfo?.address?.zipCode} {props.merchantInfo?.address?.city}
                </dt>
              </dl>
            }
          />
        </CardContentContainer>
      </FlexContainer>
      <CardFooterContainer>
        <Button size="medium" isLoading={loading} onClick={handleDownload} disabled={!props.ribSignedUrl || loading}>
          {props.buttonDownload}
        </Button>
      </CardFooterContainer>
    </CardContainer>
  )
}

export const AccountDetailsBankDetails = () => {
  const { t } = useDashboardTranslation()
  const { accountNb } = useParams<{ accountNb: string }>()
  const navigate = useNavigate()
  const isMobile = useMediaQuery("(max-width: 768px)")
  const { businessAccount, merchantInfo } = useBusinessAccount(accountNb)
  const { ribSignedUrl } = useRibSignedUrl(accountNb)

  const preventAutoFocus = (event: Event) => {
    event.preventDefault()
  }

  const handleCloseOverlay = () => {
    navigate("..")
  }

  return (
    <>
      {!isMobile && (
        <BankDetails
          businessAccount={businessAccount}
          merchantInfo={merchantInfo}
          title={t("accounts.details.bankDetails.title")}
          holder={t("accounts.details.bankDetails.accountInformation.holder")}
          buttonDownload={t("accounts.details.bankDetails.buttonDownload")}
          ribSignedUrl={ribSignedUrl}
        />
      )}
      {isMobile && (
        <Dialog open={true} onOpenChange={handleCloseOverlay}>
          <DialogContent onOpenAutoFocus={preventAutoFocus} $disableAnimations={true} width="100%" height="100%">
            <BankDetails
              isMobile={isMobile}
              businessAccount={businessAccount}
              merchantInfo={merchantInfo}
              title={t("accounts.details.bankDetails.title")}
              holder={t("accounts.details.bankDetails.accountInformation.holder")}
              buttonDownload={t("accounts.details.bankDetails.buttonDownload")}
              ribSignedUrl={ribSignedUrl}
            />
          </DialogContent>
        </Dialog>
      )}
    </>
  )
}
