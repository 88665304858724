import { gql } from "@apollo/client"
import { useHeroMutation } from "../../../../../01_technical/requesting/useHeroMutation/useHeroMutation"

const REFUND_PAYMENT_LINK = gql`
  mutation refundPaymentLink($input: RefundPaymentLinkInput!) {
    refundPaymentLink(input: $input) {
      ... on RefundPaymentLinkOutput {
        success
      }

      ... on ValidationErrors {
        validationErrors {
          path
          validationError
        }
      }

      ... on GqlHeroError {
        errorCode
        message
      }
    }
  }
`

type RefundPaymentLinkInput = {
  input: {
    amount: number
    paymentLinkId: string
  }
}

type RefundPaymentLinkResponse = {
  success: boolean
}

export const useRefundPaymentLink = () => {
  return useHeroMutation<RefundPaymentLinkResponse, RefundPaymentLinkInput>({
    gqlQuerySchema: REFUND_PAYMENT_LINK,
  })
}
