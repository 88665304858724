import { getPaginatedData, getPaginationState } from "../../../../00_shared/utils/pagination"
import { GetAPTransfersSuccessResponse } from "../Balance.requests"
import { balanceAllFakeData } from "./balanceAllFakeData"

export const getBalanceAllFakeData = (
  filters: {
    accounts?: string[]
    amountMax?: number
    amountEqual?: number
    amountMin?: number
    createdAfter?: Date
    createdBefore?: Date
  },
  pagination: { pageNumber: number; pageSize: number },
): GetAPTransfersSuccessResponse => {
  const filteredTransfers = balanceAllFakeData.filter((transfer) => {
    const isAccountMatch =
      filters.accounts && filters.accounts.length > 0
        ? filters.accounts
            .map((item) => item.toLowerCase())
            .includes(transfer.account.name.toLowerCase().replace("hero ap - ", ""))
        : true
    const isAmountMaxMatch = filters.amountMax ? transfer.amount <= filters.amountMax : true
    const isAmountEqualMatch = filters.amountEqual ? transfer.amount === filters.amountEqual : true
    const isAmountMinMatch = filters.amountMin ? transfer.amount >= filters.amountMin : true
    const isCreatedAfterMatch = filters.createdAfter ? new Date(transfer.date) >= filters.createdAfter : true
    const isCreatedBeforeMatch = filters.createdBefore ? new Date(transfer.date) <= filters.createdBefore : true

    return (
      isAccountMatch &&
      isAmountMaxMatch &&
      isAmountEqualMatch &&
      isAmountMinMatch &&
      isCreatedAfterMatch &&
      isCreatedBeforeMatch
    )
  })

  return {
    transfers: getPaginatedData(filteredTransfers, pagination),
    pagination: getPaginationState(filteredTransfers.length, pagination),
    accounts: ["LEROY MERLIN", "AMAZON"],
  }
}
