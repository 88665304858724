import { gql } from "@apollo/client"
import { DateTime } from "luxon"
import { useEffect, useMemo, useState } from "react"
import { useHeroQuery } from "../../../../../01_technical/requesting/useHeroQuery/useHeroQuery"
import { CommonTFunction, useCommonTranslation } from "../../../../../01_technical/translations"
import { UserLocale } from "../../../../../business/enums/User.enum"
import { OperationType } from "../../../00_shared/business"
import { formatOperationStatus } from "../../../00_shared/utils"

const GET_OPERATION_DETAILS = gql`
  query GetOperationDetails($operationId: String!) {
    getOperationDetails(operationId: $operationId) {
      ... on GetOperationDetailsOutput {
        id
        label
        debtorName
        creditorName
        amount
        createdAt
        reference
        operationType
        note
        justificativePath
        status {
          initiateAt
          processedAt
          payoutAt
        }
      }
      ... on SimpleApiError {
        errorCode
      }
    }
  }
`

interface OperationDetails {
  id: string
  label: string
  debtorName: string
  creditorName: string
  amount: number
  createdAt: string
  reference?: string
  operationType: OperationType
  note?: string | null
  justificativePath?: string | null
  status: {
    initiateAt?: Date
    processedAt?: Date
    payoutAt?: Date
  }
}

interface ExternalTransferInformation {
  id: string
  creditor: string
  debtor: string
  amount: string
  status: {
    label: string
    value: string | undefined
  }[]
  reference?: string
  note?: string | null
  justificativePath?: string | null
  createdAt: string
}

export const useExternalTransferInformation = (operationId: string) => {
  const { t, i18n } = useCommonTranslation()
  const [externalTransferInformation, setExternalTransferInformation] = useState<ExternalTransferInformation>()

  const { data, loading, error } = useHeroQuery<OperationDetails>({
    gqlQuerySchema: GET_OPERATION_DETAILS,
    variables: { operationId },
    fetchPolicy: "cache-and-network",
    skip: !operationId,
  })

  const local = useMemo(() => UserLocale[i18n.language as keyof typeof UserLocale], [i18n.language])

  useEffect(() => {
    if (data) {
      setExternalTransferInformation(formatExternalTransfer(data, t, local, i18n.language as keyof typeof UserLocale))
    }
  }, [data, local, t, i18n.language]) // Only rely on `data` and memoized `local` to avoid unnecessary updates.

  return {
    externalTransferInformation,
    loading: loading || (data && !externalTransferInformation),
    error,
  }
}

const formatExternalTransfer = (
  data: OperationDetails,
  t: CommonTFunction,
  local: UserLocale,
  language: keyof typeof UserLocale,
) => {
  return {
    id: data.id,
    creditor: data.creditorName,
    createdAt: DateTime.fromJSDate(new Date(data.createdAt)).setLocale(local).toLocaleString(DateTime.DATE_MED),
    amount: new Intl.NumberFormat("fr-FR", { style: "currency", currency: "EUR" }).format(data.amount / 100),
    reference: data.reference || "-",
    debtor: data.debtorName,
    note: data.note || null,
    justificativePath: data.justificativePath || null,
    status: formatOperationStatus(data.status, t, UserLocale[language]),
  }
}
