import { i18n, t } from "i18next"
import { DateTime } from "luxon"
import { centsToEuros } from "../../../../00_shared/utils/currency.converter"
import { BarChartDataType, GET_BAR_CHART_RESPONSE_SUCCESS } from "../cockpitWithNewKPI.requests"

export const getLabelsForChart = (chartData: GET_BAR_CHART_RESPONSE_SUCCESS | undefined, i18n: i18n) =>
  chartData?.apCaByMarketplaceByDay.map((dayData) =>
    DateTime.fromISO(dayData.createdDate).setLocale(i18n.language).toLocaleString({ day: "2-digit", month: "short" }),
  )

export const getCaForChart = (
  chartData: GET_BAR_CHART_RESPONSE_SUCCESS | undefined,
  selectedMarketplace: string | null,
): number[] =>
  chartData?.apCaByMarketplaceByDay.map((dayData) =>
    selectMarketplaceData(dayData, selectedMarketplace, centsToEuros),
  ) || []

export const getApFundedForChart = (
  chartData: GET_BAR_CHART_RESPONSE_SUCCESS | undefined,
  selectedMarketplace: string | null,
) =>
  chartData?.apFundedByMarketplaceByDay.map((dayData) =>
    selectMarketplaceData(dayData, selectedMarketplace, centsToEuros),
  ) || []

export const getFinancingRateForChart = (
  chartData: GET_BAR_CHART_RESPONSE_SUCCESS | undefined,
  selectedMarketplace: string | null,
) =>
  chartData?.financingRateByMarketplaceByDay.map((dayData) =>
    selectMarketplaceData(dayData, selectedMarketplace, (n) => n * 100),
  ) || []

const selectMarketplaceData = (
  dayData: BarChartDataType,
  selectedMarketplace: string | null,
  formatAmount: (amount: number) => number,
) => {
  if (selectedMarketplace === "all") {
    return formatAmount(dayData.dayTotal || 0)
  }

  return formatAmount(dayData.amountByMarketplace.find((data) => data.marketplace === selectedMarketplace)?.amount || 0)
}

export function getFooterTooltips(
  chartData: GET_BAR_CHART_RESPONSE_SUCCESS | undefined,
  selectedMarketplace: string | null,
): string[] {
  const noData = !chartData
  const aSpecificMarketplaceIsSelected = selectedMarketplace !== "all"
  const dataDontMakeSense = chartData?.apCaByMarketplaceByDay.length !== chartData?.apFundedByMarketplaceByDay.length

  if (dataDontMakeSense) {
    console.error("[AP Barchart] Data don't make sense")
    return []
  }

  if (noData || aSpecificMarketplaceIsSelected) {
    return []
  }

  const tooltipsFooterList: string[] = []

  for (let i = 0; i < chartData.apCaByMarketplaceByDay.length; i++) {
    const currentCa = chartData.apCaByMarketplaceByDay[i]
    const currentFinancingRate = chartData.financingRateByMarketplaceByDay[i]

    const tooltipFooterOfTheDay = [
      ...getTooltipsForCa(currentCa), //
      ...getTooltipsForFinancingRate(currentFinancingRate),
    ]

    tooltipsFooterList.push(tooltipFooterOfTheDay.join("\n"))
  }

  return tooltipsFooterList
}

function getTooltipsForCa(ca: BarChartDataType) {
  return ca.amountByMarketplace.map((amountByMarketplace) =>
    t("ap.cockpit.barChart.legend.tooltipMarketplaceVolume", {
      marketplace: amountByMarketplace.marketplace.toLowerCase(),
      amount: centsToEuros(amountByMarketplace.amount || 0),
    }),
  )
}

function getTooltipsForFinancingRate(rate: BarChartDataType) {
  return rate.amountByMarketplace.map((rateByMarketplace) =>
    t("ap.cockpit.barChart.legend.tooltipFinancingRate", {
      marketplace: rateByMarketplace.marketplace.toLowerCase(),
      amount: (rateByMarketplace.amount || 0) * 100 + "%",
    }),
  )
}
