import { FeatureGuard } from "../../00_shared/utils/featureGuard"
import { BOV2RouteProtector } from "../../App.router"
import { CreatePaymentLink } from "./CreatePaymentLink.screen"

export const createPaymentRoute = {
  path: "create_payment",
  element: (
    <BOV2RouteProtector>
      <FeatureGuard check={(user) => user.payOfflineAvailable} element={<CreatePaymentLink />} />
    </BOV2RouteProtector>
  ),
}
