import { useMutation, useQuery } from "@apollo/client"
import {
  Button,
  ChevronDownIcon,
  Field,
  Header,
  FieldError as KryptonFieldError,
  Label,
  Spinner,
  toaster,
  Typography,
} from "@hero/krypton"
import { ElementType } from "react"
import { Controller, FieldError as FieldErrorType, useForm } from "react-hook-form"
import { isValidPhoneNumber } from "react-phone-number-input"
import { Link, Navigate, useNavigate } from "react-router-dom"
import Select from "react-select"
import styled from "styled-components"
import { HeroErrorDisplayer, unwrapGraphQLResponse } from "../../01_technical/requesting/DEPRECATED_graphql.errors"
import { useDashboardTranslation } from "../../01_technical/translations"
import { PaymentType } from "../../business/enums/Payment.enum"
import { HEADER_COLORS } from "../../env_variables"
import { ApiErrors } from "../../Legacy/components/ApiErrors"
import { FormatError } from "../../Legacy/components/errors/Format.error"
import {
  GET_AVAILABLE_OFFLINE_PAYMENT_TYPES,
  GET_AVAILABLE_OFFLINE_PAYMENT_TYPES_RESPONSE,
  GET_AVAILABLE_OFFLINE_PAYMENT_TYPES_RESPONSE_SUCCESS,
  GET_OWN_MERCHANT,
  GET_OWN_MERCHANT_RESPONSE,
  MERCHANT_PUBLISH_PAYMENT_LINK,
  PublishPaymentLinkInput,
} from "./CreatePaymentLink.requests"
import { PhotoInput } from "./TakePhoto.component"
import { UploadFileComponent } from "./UploadFile.component"
import { useDraftPaymentLink } from "./UseDraftPaymentLink"
import { useUploadFile } from "./useUploadFile.hook"

const Container = styled.main`
  display: flex;
  flex-direction: column;
  flex: 1;
`

const LinkContainer = styled(Link)`
  display: flex;
  align-items: center;
  height: fit-content;
`

const MainContent = styled.div`
  margin: 1.5rem 20%;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 0.75rem;
  padding: 2rem;
`

const StyledTypography = styled(Typography)`
  color: ${(props) => props.theme.colors.grey.$500};
  margin: 0.25rem auto 2rem auto;
`

const Form = styled.form`
  display: flex;
  flex-direction: column;
  background-color: white;
  margin: 0 auto;
  color: ${({ theme }) => theme.colors.grey.$600};
  & > label {
    margin: 0.5rem 0;
  }
  & .krypton_phoneInputContainer {
    width: unset;
  }
`

const StyledField = styled(Field)`
  width: unset;
`
const FieldError = styled(Typography).attrs(() => ({ as: "strong" }))`
  height: 1.5rem;
  color: ${({ theme }) => theme.colors.danger.$200};
  overflow: hidden;
`

const BackButton = styled<ElementType>(Button)`
  min-width: 1.75rem;
  width: 1.75rem;
  height: 1.75rem;
  background-color: ${({ theme }) => theme.colors.grey.$600};
  border-radius: 0.25rem;
  color: ${({ theme }) => theme.colors.white};
  padding: 0;
  margin-right: 0.5rem;

  & svg {
    transform: rotate(90deg);
  }
`

const SubmitButton = styled<ElementType>(Button)`
  margin-top: 0.5rem;
  width: 13rem;
`

const HorizontalLines = styled.div`
  display: flex;
  align-items: center;
  text-align: center;
  padding-top: 1.75rem;
  padding-bottom: 1.75rem;

  &::before,
  &::after {
    content: "";
    flex: 1;
    border-bottom: 1px solid ${({ theme }) => theme.colors.grey.$300};
  }

  &::before {
    margin-right: 0.75rem;
  }

  &::after {
    margin-left: 0.75rem;
  }
`

const CustomSpinner = styled(Spinner)`
  margin: auto;
`

const isCaptureSupported = () => {
  return (document.createElement("input") as unknown as { capture: string }).capture !== undefined
}

type CreatePaymentLinkForm = {
  amount: string
  phone: string
  email: string
  attachment: {
    path: string
    file: File
  }
  merchantComment?: string
  siret?: string
  paymentTypes: { value: PaymentType; label: PaymentType }[]
}

const useGetAvailableOfflinePaymentTypes = (skip: boolean) => {
  const { data, loading, error } = useQuery<GET_AVAILABLE_OFFLINE_PAYMENT_TYPES_RESPONSE>(
    GET_AVAILABLE_OFFLINE_PAYMENT_TYPES,
    { skip },
  )
  const { error: getAvailableOfflinePaymentTypesError, data: availableOfflinePaymentTypesData } =
    unwrapGraphQLResponse<GET_AVAILABLE_OFFLINE_PAYMENT_TYPES_RESPONSE_SUCCESS>(
      data?.merchantGetAvailableOfflinePaymentTypes,
    )

  return {
    functionalError: getAvailableOfflinePaymentTypesError,
    technicalError: error,
    availableOfflinePaymentTypes: availableOfflinePaymentTypesData?.offlineAvailablePaymentTypes,
    loading,
  }
}

const makePaymentTypesOptions = (paymentTypes: PaymentType[]) => {
  return paymentTypes.map((paymentType) => ({ value: paymentType, label: paymentType }))
}

export const CreatePaymentLink = () => {
  const { t } = useDashboardTranslation()

  const {
    register,
    getValues,
    control,
    handleSubmit,
    formState: { errors, isDirty, isSubmitting },
  } = useForm<CreatePaymentLinkForm>()
  const navigate = useNavigate()
  const { paymentId, loading, error } = useDraftPaymentLink()

  const {
    data,
    loading: getOwnMerchantLoading,
    error: getOwnMerchantError,
  } = useQuery<GET_OWN_MERCHANT_RESPONSE>(GET_OWN_MERCHANT)

  const {
    availableOfflinePaymentTypes,
    functionalError,
    loading: getAvailableOfflinePaymentTypesLoading,
    technicalError,
  } = useGetAvailableOfflinePaymentTypes(!data?.getmerchant.canChoosePaymentGeneratorType)

  const [uploadFile, uploadFileStatus] = useUploadFile()

  const [publishPaymentLink, publishPaymentLinkStatus] = useMutation<unknown, PublishPaymentLinkInput>(
    MERCHANT_PUBLISH_PAYMENT_LINK,
  )

  const onSubmit = handleSubmit(({ paymentTypes, attachment, amount, email, phone, ...otherData }) => {
    publishPaymentLink({
      variables: {
        email: email,
        phone: phone,
        paymentLinkId: paymentId as string,
        amount: Math.round(+amount * 100),
        attachmentPath: attachment?.path,
        attachmentName: attachment?.file.name,
        paymentTypes: (paymentTypes || []).map(({ value }) => value),
        ...otherData,
      },
    })
      .then(() => {
        toaster.success(t("checkoutAndCash.cockpit.createPaymentLink.toasterSuccess"), { position: "top-right" })
        navigate(`/transactions/${paymentId}`)
      })
      .catch((err) => {
        console.error(err)
        toaster.error(t("checkoutAndCash.cockpit.createPaymentLink.toasterError"), { position: "top-right" })
      })
  })

  const uploadDocument = ({
    file,
    onChange,
    paymentId,
  }: {
    paymentId: string
    file: File
    onChange: ({ file, path }: { file: File; path: string }) => void
  }) =>
    uploadFile(file, paymentId as string)
      .then((data) => {
        onChange({ file, path: data.path })
      })
      .catch((err: Error) => {
        console.error(err)
        toaster.error(t("checkoutAndCash.cockpit.createPaymentLink.toasterError"), {
          position: "top-right",
        })
      })

  if (loading || getOwnMerchantLoading || getAvailableOfflinePaymentTypesLoading) {
    return <CustomSpinner></CustomSpinner>
  }

  if (error || getOwnMerchantError) {
    error && console.error(error)
    getOwnMerchantError && console.error(getOwnMerchantError)
    toaster.error(t("checkoutAndCash.cockpit.createPaymentLink.toasterError"), { position: "top-right" })
    return <Navigate to="/" />
  }

  return (
    <Container data-test-id="create-payment-link-container">
      <Header $colors={HEADER_COLORS}>
        <LinkContainer to={"/cockpit"}>
          <BackButton isLoading={false} leftIcon={ChevronDownIcon} />
          <Typography $variant="body-3-medium">{t("checkoutAndCash.cockpit.createPaymentLink.backButton")}</Typography>
        </LinkContainer>
      </Header>
      <MainContent>
        <Typography as="h2" $variant="title-2-bold">
          {t("checkoutAndCash.cockpit.createPaymentLink.title")}
        </Typography>
        <StyledTypography $variant="body-4-regular">
          {t("checkoutAndCash.cockpit.createPaymentLink.subtitle")}
        </StyledTypography>
        <Form onSubmit={onSubmit}>
          <StyledField
            data-test-id="create-payment-link-field-amount"
            {...register("amount", { required: t("checkoutAndCash.cockpit.createPaymentLink.fieldRequired") })}
            aria-invalid={!!errors.amount}
            type="number"
            step="0.01"
            fieldLabel={t("checkoutAndCash.cockpit.createPaymentLink.fieldAmountLabel")}
            placeholder={t("checkoutAndCash.cockpit.createPaymentLink.fieldAmountPlaceholder")}
            errorMessage={errors.amount ? errors.amount.message : ""}
          />
          {data?.getmerchant.canChoosePaymentGeneratorType && (
            <Controller
              name="paymentTypes"
              control={control}
              render={({ field: { onChange, ...rest } }) => (
                <Label>
                  <div>{t("checkoutAndCash.cockpit.createPaymentLink.fieldPaymentTypesLabel")}</div>
                  <Select
                    {...rest}
                    styles={{}}
                    placeholder={t("checkoutAndCash.cockpit.createPaymentLink.fieldPaymentTypesPlaceholder")}
                    options={makePaymentTypesOptions(availableOfflinePaymentTypes ?? [])}
                    isMulti
                    onChange={(option) => {
                      onChange(option)
                    }}
                  />
                  <KryptonFieldError />
                </Label>
              )}
            />
          )}

          <Controller
            name="phone"
            control={control}
            rules={{
              validate: (value = "") => {
                return (
                  isValidPhoneNumber(value.toString()) ||
                  t("checkoutAndCash.cockpit.createPaymentLink.fieldPhoneInvalid")
                )
              },
              required: t("checkoutAndCash.cockpit.createPaymentLink.fieldRequired"),
            }}
            render={({ field }) => (
              <StyledField
                {...field}
                fieldLabel={t("checkoutAndCash.cockpit.createPaymentLink.fieldPhoneLabel")}
                type="tel"
                defaultCountry="FR"
                data-test-id="create-payment-link-field-phone"
                aria-invalid={isDirty ? !!errors.phone : undefined}
                errorMessage={errors.phone ? errors.phone.message : ""}
              />
            )}
          />
          <StyledField
            data-test-id="create-payment-link-field-email"
            {...register("email", { required: t("checkoutAndCash.cockpit.createPaymentLink.fieldRequired") })}
            aria-invalid={!!errors.email}
            type="email"
            fieldLabel={t("checkoutAndCash.cockpit.createPaymentLink.fieldEmailLabel")}
            placeholder={t("checkoutAndCash.cockpit.createPaymentLink.fieldEmailPlaceholder")}
            errorMessage={errors.email ? errors.email.message : ""}
          />
          <StyledField
            data-test-id="create-payment-link-field-siret"
            {...register("siret")}
            aria-invalid={!!errors.siret}
            type="text"
            fieldLabel={t("checkoutAndCash.cockpit.createPaymentLink.fieldSiretLabel")}
            placeholder={t("checkoutAndCash.cockpit.createPaymentLink.fieldSiretPlaceholder")}
          />
          <StyledField
            data-test-id="create-payment-link-field-merchantComment"
            {...register("merchantComment")}
            aria-invalid={!!errors.merchantComment}
            type="text"
            fieldLabel={t("checkoutAndCash.cockpit.createPaymentLink.fieldMerchantCommentLabel")}
            placeholder={t("checkoutAndCash.cockpit.createPaymentLink.fieldMerchantCommentPlaceholder")}
          />
          <Controller
            name="attachment"
            control={control}
            render={({ field: { onChange, name } }) => (
              <>
                <UploadFileComponent
                  onChange={(file) => {
                    if (file) {
                      paymentId && uploadDocument({ file, paymentId, onChange })
                    } else {
                      onChange(null)
                    }
                  }}
                  loading={uploadFileStatus.loading}
                  value={getValues("attachment.file")}
                  name={name}
                  title={t("checkoutAndCash.cockpit.createPaymentLink.fieldAttachmentTitle")}
                  cancel={t("checkoutAndCash.cockpit.createPaymentLink.fieldAttachmentCancel")}
                >
                  <>
                    {t("checkoutAndCash.cockpit.createPaymentLink.fieldAttachmentInfo1")}
                    <br />
                    <em>{t("checkoutAndCash.cockpit.createPaymentLink.fieldAttachmentInfo2")}</em>
                  </>
                </UploadFileComponent>
                {isCaptureSupported() && (
                  <>
                    <HorizontalLines>
                      {t("checkoutAndCash.cockpit.createPaymentLink.fieldAttachmentChoice")}
                    </HorizontalLines>
                    <PhotoInput
                      onChange={(file) => paymentId && uploadDocument({ file, paymentId, onChange })}
                      title={t("checkoutAndCash.cockpit.createPaymentLink.fieldAttachmentPhotoTitle")}
                      subtitle={t("checkoutAndCash.cockpit.createPaymentLink.fieldAttachmentPhotoSubtitle")}
                      imgAlt={t("checkoutAndCash.cockpit.createPaymentLink.fieldAttachmentPhotoImgAlt")}
                      iconImgAlt={t("checkoutAndCash.cockpit.createPaymentLink.fieldAttachmentPhotoIconImgAlt")}
                    />
                  </>
                )}
              </>
            )}
          />

          <FieldError>
            {uploadFileStatus.error
              ? t("checkoutAndCash.cockpit.createPaymentLink.fieldAttachmentError")
              : (errors.attachment as unknown as FieldErrorType)?.message ||
                errors.attachment?.path?.message ||
                errors.attachment?.file?.message}
          </FieldError>
          {publishPaymentLinkStatus.error && (
            <ApiErrors
              err={publishPaymentLinkStatus.error}
              strategies={{
                NOT_AUTHORIZED: () => <>{t("checkoutAndCash.cockpit.createPaymentLink.apiErrorNotAuthorized")}</>,
                WRONG_FORMAT: (extensions) => (
                  <FormatError
                    extensions={extensions}
                    errorMapping={{
                      amount: t("checkoutAndCash.cockpit.createPaymentLink.apiErrorWrongFormatAmount"),
                      email: t("checkoutAndCash.cockpit.createPaymentLink.apiErrorWrongFormatEmail"),
                      phone: t("checkoutAndCash.cockpit.createPaymentLink.apiErrorWrongFormatPhone"),
                      attachments: t("checkoutAndCash.cockpit.createPaymentLink.apiErrorWrongFormatAttachments"),
                    }}
                  />
                ),
                NO_ATTACHMENT: () => <>{t("checkoutAndCash.cockpit.createPaymentLink.apiErrorNoAttachment")}</>,
                PAYMENT_LINK_NOT_FOUND: () => (
                  <>{t("checkoutAndCash.cockpit.createPaymentLink.apiErrorPaymentLinkNotFound")}</>
                ),
                PRODUCT_DISABLED: () => <>{t("checkoutAndCash.cockpit.createPaymentLink.apiErrorProductDisabled")}</>,
                INVALID_AMOUNT: () => <>{t("checkoutAndCash.cockpit.createPaymentLink.apiErrorInvalidAmount")}</>,
              }}
            />
          )}
          <HeroErrorDisplayer
            data={functionalError}
            err={technicalError}
            messages={{
              UNAUTHORIZED: t("checkoutAndCash.cockpit.createPaymentLink.heroErrorDisplayerUnauthorized"),
            }}
          />
          <SubmitButton
            isLoading={isSubmitting || publishPaymentLinkStatus.loading}
            data-test-id="create-payment-link-submit-button"
            size="medium"
            type="submit"
          >
            {t("checkoutAndCash.cockpit.createPaymentLink.submitButton")}
          </SubmitButton>
        </Form>
      </MainContent>
    </Container>
  )
}
