import { createContext, FC, ReactNode, useContext, useEffect, useState } from "react"

type MenuLayoutContextType = {
  isMenuOpen: boolean
  setIsMenuOpen: (isOpen: boolean | ((prev: boolean) => boolean)) => void
  isMenuReduced: boolean
  setIsMenuReduced: (isReduced: boolean | ((prev: boolean) => boolean)) => void
  isMobileMenuOpen: boolean
  setIsMobileMenuOpen: (isOpen: boolean | ((prev: boolean) => boolean)) => void
}

const MenuLayoutContext = createContext<MenuLayoutContextType>({} as MenuLayoutContextType)

export const useMenuLayoutContext = () => useContext(MenuLayoutContext)

export const MenuLayoutProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(true)
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false)
  const [isMenuReduced, setIsMenuReduced] = useState(false)

  return (
    <MenuLayoutContext.Provider
      value={{ isMenuOpen, setIsMenuOpen, isMenuReduced, setIsMenuReduced, isMobileMenuOpen, setIsMobileMenuOpen }}
    >
      {children}
    </MenuLayoutContext.Provider>
  )
}

export const useHideMenu = () => {
  const { setIsMenuOpen } = useMenuLayoutContext()

  useEffect(() => {
    setIsMenuOpen(false)
    return () => {
      setIsMenuOpen(true)
    }
  }, [setIsMenuOpen])
}
