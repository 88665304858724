import { Navigate, Outlet, Params } from "react-router-dom"
import { IncomingTransactionDetails } from "./IncomingTransferDetails.screen"

export const incomingTransactionDetailsRoute = {
  path: "incoming/*",
  element: <Outlet />,
  handle: { crumb: () => ({ label: "ap.balance.breadcrumb.incoming", to: "/ap/balance?transaction=income" }) },
  children: [
    { index: true, element: <Navigate to="/ap/balance?transaction=income" /> },
    {
      path: ":swanTransactionId",
      element: <IncomingTransactionDetails />,
      handle: { crumb: (params: Params<string>) => ({ label: params.swanTransactionId ?? "" }) },
    },
  ],
}
