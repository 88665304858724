import { gql } from "@apollo/client"
import { PaymentType } from "../../business/enums/Payment.enum"

export const MERCHANT_CREATE_DRAFT_PAYMENT_LINK = gql`
  mutation {
    createDraftPaymentLink {
      id
    }
  }
`

export type UploadInfo = {
  signedUrl: string
  path: string
}
export const MERCHANT_GET_UPLOAD_ATTACHMENT_URL = gql`
  query merchantGetUploadAttachment($paymentLinkId: String!) {
    uploadablePaymentLinkResourceSignedUrl(paymentLinkId: $paymentLinkId) {
      signedUrl
      path
    }
  }
`

export type PublishPaymentLinkInput = {
  paymentLinkId: string
  amount: number
  merchantComment?: string
  siret?: string
  email: string
  phone: string
  attachmentPath?: string
  attachmentName?: string
  paymentTypes: PaymentType[]
}

export const MERCHANT_PUBLISH_PAYMENT_LINK = gql`
  mutation merchantPublishPaymentLink(
    $paymentLinkId: String!
    $amount: Int!
    $merchantComment: String!
    $siret: String!
    $email: String!
    $phone: String!
    $attachmentPath: String
    $attachmentName: String
    $paymentTypes: [PaymentType!]
  ) {
    publishDraftPaymentLink(
      paymentLink: {
        paymentLinkId: $paymentLinkId
        amount: $amount
        merchantComment: $merchantComment
        siret: $siret
        email: $email
        phone: $phone
        attachments: [{ path: $attachmentPath, name: $attachmentName }]
        paymentTypes: $paymentTypes
      }
    ) {
      id
      url
      createdAt
      amount
      merchantComment
      status
      customer {
        email
        siret
        phone
      }
      attachments {
        id
        path
        name
      }
    }
  }
`

export type GET_OWN_MERCHANT_RESPONSE = {
  getmerchant: {
    canChoosePaymentGeneratorType: boolean
  }
}

export const GET_OWN_MERCHANT = gql`
  query {
    getmerchant {
      canChoosePaymentGeneratorType
    }
  }
`

export type GET_AVAILABLE_OFFLINE_PAYMENT_TYPES_RESPONSE_SUCCESS = { offlineAvailablePaymentTypes: PaymentType[] }
type GraphQLError = { errorCode: "UNAUTHORIZED" | "NOT_FOUND"; message: string }

export type GET_AVAILABLE_OFFLINE_PAYMENT_TYPES_RESPONSE = {
  merchantGetAvailableOfflinePaymentTypes: GET_AVAILABLE_OFFLINE_PAYMENT_TYPES_RESPONSE_SUCCESS | GraphQLError
}

export const GET_AVAILABLE_OFFLINE_PAYMENT_TYPES = gql`
  query merchantGetAvailableOfflinePaymentTypes {
    merchantGetAvailableOfflinePaymentTypes {
      ... on MerchantOfflineAvailablePaymentTypes {
        offlineAvailablePaymentTypes
      }
      ... on GqlHeroError {
        errorCode
        message
      }
    }
  }
`
