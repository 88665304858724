import { useEffect } from "react"
import { useQuerySearchParams } from "../../../../00_shared/hooks/useQuerySearchParams.hook"
import { SearchParams, statusParam } from "./FilledPayments"

export const useHandleFilledPaymentsSearchParams = () => {
  const { getSearchParam, setSearchParam } = useQuerySearchParams<SearchParams>()

  const getAPPaymentsSearchParam = <T extends keyof SearchParams>(param: T) => {
    if (!getSearchParam(param)) {
      return undefined
    } else if (param === "page" || param === "amountUnder" || param === "amountOver" || param === "amountEqual") {
      return Number(getSearchParam(param))
    } else if (param === "marketplaces") {
      return getSearchParam(param).split(",")
    }
    return getSearchParam(param)
  }

  const pageNumber = getAPPaymentsSearchParam("page") as number
  const status = getAPPaymentsSearchParam("status") as statusParam
  const marketplaces = getAPPaymentsSearchParam("marketplaces") as string[] | undefined
  const amountUnder = getAPPaymentsSearchParam("amountUnder") as number | undefined
  const amountOver = getAPPaymentsSearchParam("amountOver") as number | undefined
  const amountEqual = getAPPaymentsSearchParam("amountEqual") as number | undefined
  const startDate = getAPPaymentsSearchParam("startDate") as string | undefined
  const endDate = getAPPaymentsSearchParam("endDate") as string | undefined

  useEffect(() => {
    const defaultSearchParams = {
      status: status ?? "all",
      page: pageNumber ?? 1,
    }
    const shouldUpdateSearchParams = !status || !pageNumber
    if (shouldUpdateSearchParams) {
      setSearchParam(defaultSearchParams)
    }
  }, [setSearchParam, status, pageNumber])

  return {
    pageNumber,
    status,
    marketplaces,
    amountUnder,
    amountOver,
    amountEqual,
    startDate,
    endDate,
    setSearchParam,
  }
}
