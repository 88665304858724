import { useHeroQuery } from "../../../../01_technical/requesting/useHeroQuery/useHeroQuery"
import { GET_AP_TRANSFERS_QUERY, GetAPTransfersSuccessResponse } from "../Balance.requests"
import { getBalanceAllFakeData } from "./balanceAllFakeData.utils"

export const useGetApTransfers = (
  filters: {
    accounts?: string[]
    amountMax?: number
    amountEqual?: number
    amountMin?: number
    createdAfter?: Date
    createdBefore?: Date
  },
  pagination: { pageNumber: number; pageSize: number },
  config: { useDemoData: boolean } = { useDemoData: false },
) => {
  const { data, loading, error } = useHeroQuery<GetAPTransfersSuccessResponse>({
    gqlQuerySchema: GET_AP_TRANSFERS_QUERY,
    variables: {
      pagination,
      filters,
    },
    skip: config.useDemoData,
  })

  if (config.useDemoData) {
    return {
      data: getBalanceAllFakeData(filters, pagination),
      error: undefined,
      loading: false,
    }
  }

  return { data, loading, error }
}
