import { useMutation } from "@apollo/client"
import { unwrapGraphQLResponse } from "../../../../../01_technical/requesting/DEPRECATED_graphql.errors"
import {
  SCHEDULE_AP_ACCOUNTING_SPREADSHEET_MUTATION,
  SCHEDULE_BNPL_ACCOUNTING_SPREADSHEET_MUTATION,
} from "../export.requests"

const useScheduleAccountingSpreadsheetHook = ({ type }: { type: "AP" | "BNPL" }) => {
  const [scheduleAccountingSpreadsheetGeneration, { loading, error: technicalError, data }] = useMutation(
    type === "AP" ? SCHEDULE_AP_ACCOUNTING_SPREADSHEET_MUTATION : SCHEDULE_BNPL_ACCOUNTING_SPREADSHEET_MUTATION,
  )

  const { data: successData, error: functionalError } = unwrapGraphQLResponse(
    data?.scheduleAPAccountingSpreadsheetGeneration ?? data?.scheduleBNPLAccountingSpreadsheetGeneration,
  )

  return { scheduleAccountingSpreadsheetGeneration, loading, technicalError, functionalError, successData }
}

export const useAPScheduleAccountingSpreadsheetHook = () => useScheduleAccountingSpreadsheetHook({ type: "AP" })
export const useBNPLScheduleAccountingSpreadsheetHook = () => useScheduleAccountingSpreadsheetHook({ type: "BNPL" })
