/* eslint-disable @typescript-eslint/no-explicit-any */
// from https://github.com/xnimorz/use-debounce/tree/master/src

import { useCallback, useEffect, useMemo, useReducer, useRef, useState } from "react"

function valueEquality<T>(left: T, right: T): boolean {
  return left === right
}

function reducer<T>(_: T, action: T) {
  return action
}

export function useDebounce<T>(
  value: T,
  delay: number,
  options?: {
    maxWait?: number
    leading?: boolean
    trailing?: boolean
    equalityFn?: (left: T, right: T) => boolean
  },
): [T, boolean, DebouncedState<(value: T) => void>] {
  const eq = (options && options.equalityFn) || valueEquality

  const [state, dispatch] = useReducer(reducer, value)
  const debounced = useDebouncedCallback(
    useCallback((value: T) => dispatch(value), [dispatch]),
    delay,
    options,
  )
  const previousValue = useRef(value)

  if (!eq(previousValue.current, value)) {
    debounced(value)
    previousValue.current = value
  }

  const [isDebouncing, setIsDebouncing] = useState(false)

  useEffect(() => {
    setIsDebouncing(true)
  }, [value])

  useEffect(() => {
    setIsDebouncing(false)
  }, [state])

  return [state as T, isDebouncing, debounced]
}

interface CallOptions {
  /**
   * Controls if the function should be invoked on the leading edge of the timeout.
   */
  leading?: boolean
  /**
   * Controls if the function should be invoked on the trailing edge of the timeout.
   */
  trailing?: boolean
}

interface Options extends CallOptions {
  /**
   * The maximum time the given function is allowed to be delayed before it's invoked.
   */
  maxWait?: number
  /**
   * If the setting is set to true, all debouncing and timers will happen on the server side as well
   */
  debounceOnServer?: boolean
}

interface ControlFunctions {
  /**
   * Cancel pending function invocations
   */
  cancel: () => void
  /**
   * Immediately invoke pending function invocations
   */
  flush: () => void
  /**
   * Returns `true` if there are any pending function invocations
   */
  isPending: () => boolean
}

/**
 * Subsequent calls to the debounced function return the result of the last func invocation.
 * Note, that if there are no previous invocations you will get undefined. You should check it in your code properly.
 */
interface DebouncedState<T extends (...args: any) => ReturnType<T>> extends ControlFunctions {
  (...args: Parameters<T>): ReturnType<T> | undefined
}

/**
 * Creates a debounced function that delays invoking `func` until after `wait`
 * milliseconds have elapsed since the last time the debounced function was
 * invoked, or until the next browser frame is drawn.
 *
 * The debounced function comes with a `cancel` method to cancel delayed `func`
 * invocations and a `flush` method to immediately invoke them.
 *
 * Provide `options` to indicate whether `func` should be invoked on the leading
 * and/or trailing edge of the `wait` timeout. The `func` is invoked with the
 * last arguments provided to the debounced function.
 *
 * Subsequent calls to the debounced function return the result of the last
 * `func` invocation.
 *
 * **Note:** If `leading` and `trailing` options are `true`, `func` is
 * invoked on the trailing edge of the timeout only if the debounced function
 * is invoked more than once during the `wait` timeout.
 *
 * If `wait` is `0` and `leading` is `false`, `func` invocation is deferred
 * until the next tick, similar to `setTimeout` with a timeout of `0`.
 *
 * If `wait` is omitted in an environment with `requestAnimationFrame`, `func`
 * invocation will be deferred until the next frame is drawn (typically about
 * 16ms).
 *
 * See [David Corbacho's article](https://css-tricks.com/debouncing-throttling-explained-examples/)
 * for details over the differences between `debounce` and `throttle`.
 *
 * @category Function
 * @param {Function} func The function to debounce.
 * @param {number} [wait=0]
 *  The number of milliseconds to delay; if omitted, `requestAnimationFrame` is
 *  used (if available, otherwise it will be setTimeout(...,0)).
 * @param {Object} [options={}] The options object.
 *  Controls if `func` should be invoked on the leading edge of the timeout.
 * @param {boolean} [options.leading=false]
 *  The maximum time `func` is allowed to be delayed before it's invoked.
 * @param {number} [options.maxWait]
 *  Controls if `func` should be invoked the trailing edge of the timeout.
 * @param {boolean} [options.trailing=true]
 * @returns {Function} Returns the new debounced function.
 * @example
 *
 * // Avoid costly calculations while the window size is in flux.
 * const resizeHandler = useDebouncedCallback(calculateLayout, 150);
 * window.addEventListener('resize', resizeHandler)
 *
 * // Invoke `sendMail` when clicked, debouncing subsequent calls.
 * const clickHandler = useDebouncedCallback(sendMail, 300, {
 *   leading: true,
 *   trailing: false,
 * })
 * <button onClick={clickHandler}>click me</button>
 *
 * // Ensure `batchLog` is invoked once after 1 second of debounced calls.
 * const debounced = useDebouncedCallback(batchLog, 250, { 'maxWait': 1000 })
 * const source = new EventSource('/stream')
 * source.addEventListener('message', debounced)
 *
 * // Cancel the trailing debounced invocation.
 * window.addEventListener('popstate', debounced.cancel)
 *
 * // Check for pending invocations.
 * const status = debounced.pending() ? "Pending..." : "Ready"
 */
function useDebouncedCallback<T extends (...args: any) => ReturnType<T>>(
  func: T,
  wait?: number,
  options?: Options,
): DebouncedState<T> {
  const lastCallTime = useRef<any>(null)
  const lastInvokeTime = useRef<any>(0)
  const timerId = useRef<any>(null)
  const lastArgs = useRef<any>([])
  const lastThis = useRef<any>()
  const result = useRef<ReturnType<T>>()
  const funcRef = useRef(func)
  const mounted = useRef(true)
  // Always keep the latest version of debounce callback, with no wait time.
  funcRef.current = func

  const isClientSize = typeof window !== "undefined"
  // Bypass `requestAnimationFrame` by explicitly setting `wait=0`.
  const useRAF = !wait && wait !== 0 && isClientSize

  if (typeof func !== "function") {
    throw new TypeError("Expected a function")
  }

  wait = typeof wait === "number" ? +wait : 0
  options = options || {}

  const leading = !!options.leading
  const trailing = "trailing" in options ? !!options.trailing : true // `true` by default
  const maxing = "maxWait" in options
  const debounceOnServer = "debounceOnServer" in options ? !!options.debounceOnServer : false // `false` by default
  const maxWait = maxing ? Math.max(options.maxWait ? +options.maxWait : 0, wait) : null

  useEffect(() => {
    mounted.current = true
    return () => {
      mounted.current = false
    }
  }, [])

  // You may have a question, why we have so many code under the useMemo definition.
  //
  // This was made as we want to escape from useCallback hell and
  // not to initialize a number of functions each time useDebouncedCallback is called.
  //
  // It means that we have less garbage for our GC calls which improves performance.
  // Also, it makes this library smaller.
  //
  // And the last reason, that the code without lots of useCallback with deps is easier to read.
  // You have only one place for that.
  const debounced = useMemo(() => {
    const invokeFunc = (time: number) => {
      const args = lastArgs.current
      const thisArg = lastThis.current

      lastArgs.current = lastThis.current = null
      lastInvokeTime.current = time
      return (result.current = funcRef.current.apply(thisArg, args))
    }

    const startTimer = (pendingFunc: () => void, wait: number) => {
      if (useRAF) cancelAnimationFrame(timerId.current)
      timerId.current = useRAF ? requestAnimationFrame(pendingFunc) : setTimeout(pendingFunc, wait)
    }

    const shouldInvoke = (time: number) => {
      if (!mounted.current) return false

      const timeSinceLastCall = time - lastCallTime.current
      const timeSinceLastInvoke = time - lastInvokeTime.current

      // Either this is the first call, activity has stopped and we're at the
      // trailing edge, the system time has gone backwards and we're treating
      // it as the trailing edge, or we've hit the `maxWait` limit.
      return (
        !lastCallTime.current ||
        timeSinceLastCall >= wait ||
        timeSinceLastCall < 0 ||
        (maxWait !== null && maxing && timeSinceLastInvoke >= maxWait)
      )
    }

    const trailingEdge = (time: number) => {
      timerId.current = null

      // Only invoke if we have `lastArgs` which means `func` has been
      // debounced at least once.
      if (trailing && lastArgs.current) {
        return invokeFunc(time)
      }
      lastArgs.current = lastThis.current = null
      return result.current
    }

    const timerExpired = () => {
      const time = Date.now()
      if (shouldInvoke(time)) {
        return trailingEdge(time)
      }
      // https://github.com/xnimorz/use-debounce/issues/97
      if (!mounted.current) {
        return
      }
      // Remaining wait calculation
      const timeSinceLastCall = time - lastCallTime.current
      const timeSinceLastInvoke = time - lastInvokeTime.current
      const timeWaiting = wait - timeSinceLastCall
      const remainingWait = maxing && maxWait ? Math.min(timeWaiting, maxWait - timeSinceLastInvoke) : timeWaiting

      // Restart the timer
      startTimer(timerExpired, remainingWait)
    }

    const func: DebouncedState<T> = (...args: Parameters<T>): ReturnType<T> | undefined => {
      if (!isClientSize && !debounceOnServer) {
        return
      }
      const time = Date.now()
      const isInvoking = shouldInvoke(time)

      lastArgs.current = args
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      lastThis.current = this
      lastCallTime.current = time

      if (isInvoking) {
        if (!timerId.current && mounted.current) {
          // Reset any `maxWait` timer.
          lastInvokeTime.current = lastCallTime.current
          // Start the timer for the trailing edge.
          startTimer(timerExpired, wait)
          // Invoke the leading edge.
          return leading ? invokeFunc(lastCallTime.current) : result.current
        }
        if (maxing) {
          // Handle invocations in a tight loop.
          startTimer(timerExpired, wait)
          return invokeFunc(lastCallTime.current)
        }
      }
      if (!timerId.current) {
        startTimer(timerExpired, wait)
      }
      return result.current
    }

    func.cancel = () => {
      if (timerId.current) {
        useRAF ? cancelAnimationFrame(timerId.current) : clearTimeout(timerId.current)
      }
      lastInvokeTime.current = 0
      lastArgs.current = lastCallTime.current = lastThis.current = timerId.current = null
    }

    func.isPending = () => {
      return !!timerId.current
    }

    func.flush = () => {
      return !timerId.current ? result.current : trailingEdge(Date.now())
    }

    return func
  }, [leading, maxing, wait, maxWait, trailing, useRAF, isClientSize, debounceOnServer])

  return debounced
}
